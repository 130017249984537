import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ServiceType } from "types/Models/services";

export type ServiceListState = {
  taskCounts: {
    ongoing_count: number;
    count: number;
    completed_count: number;
  };
  serviceActionType: ServiceType;
};

const initialState: ServiceListState = {
  taskCounts: {
    ongoing_count: 0,
    count: 0,
    completed_count: 0,
  },
  serviceActionType: "AUDIT",
};

export const serviceList = createSlice({
  name: "serviceList",
  initialState,
  reducers: {
    setTaskCount: (
      state,
      action: PayloadAction<ServiceListState["taskCounts"]>
    ) => {
      state.taskCounts = action.payload;
    },
    setServiceActionType: (state, action: PayloadAction<ServiceType>) => {
      state.serviceActionType = action.payload;
    },
  },
});

export const { setTaskCount, setServiceActionType } = serviceList.actions;

export default serviceList.reducer;
