import { Button } from "components/DesignSystem/Button/Button";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import { CaretDown } from "components/icons/CaretDown";
import { useModal } from "hooks/useModal";
import { ImportCOA } from "./ImportCOA";
import { ParentSelector } from "./ParentSelector";

export const AddNewCategory = () => {
  const addNewCategory = useModal();
  const importCOA = useModal();

  return (
    <>
      <Dropdown.Root>
        <Dropdown.Trigger asChild className="t-group">
          <div>
            <Button size="small" customType="primary">
              Add
              <div className="t-ml-1.5 group-data-state-open:t-rotate-180">
                <CaretDown />
              </div>
            </Button>
          </div>
        </Dropdown.Trigger>
        <Dropdown.Portal>
          <Dropdown.Content sideOffset={4} align="end" side="bottom">
            <Dropdown.Item onSelect={addNewCategory.open}>
              Add category
            </Dropdown.Item>
            <Dropdown.Item onSelect={importCOA.open}>Import COA</Dropdown.Item>
          </Dropdown.Content>
        </Dropdown.Portal>
      </Dropdown.Root>
      <ParentSelector
        isOpen={addNewCategory.isOpen}
        close={addNewCategory.close}
        open={addNewCategory.open}
      />
      <ImportCOA isOpen={importCOA.isOpen} close={importCOA.close} />
    </>
  );
};
