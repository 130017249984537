import {
  Combobox,
  ComboboxProps,
} from "components/DesignSystem/Combobox/Combobox";
import { LoadingIcon } from "components/icons/LoadingIcon";
import { usePaginatedQuery } from "hooks/usePaginatedQuery";
import { ReactNode, useState } from "react";
import { useLazyGetAllGroupsQuery } from "store/apis/crm";
import { CompanyGroup } from "types/Models/groups";
import { debounce } from "utils/debouncing";

const Loader = () => (
  <div className="t-flex t-justify-center t-items-center t-w-full t-gap-2 t-p-2">
    <span className="t-flex t-origin-center t-animate-spin">
      <LoadingIcon />
    </span>
    Loading...
  </div>
);

export const GroupSelector = ({
  children,
  ...props
}: {
  children?: ({ firstGroupId }: { firstGroupId: string }) => ReactNode;
} & ComboboxProps) => {
  const [groupSearchValue, setGroupSearchValue] = useState("");

  const {
    data: groups,
    loadNext,
    isFetching,
    isLoading,
  } = usePaginatedQuery<{
    company_groups?: CompanyGroup[];
  }>(useLazyGetAllGroupsQuery, "company_groups", {
    search_term: groupSearchValue,
  });

  const onSearch = debounce((value) => setGroupSearchValue(value));

  const { company_groups = [] } = groups || {};

  return (
    <>
      <Combobox
        {...props}
        isDisabled={isLoading || props.isDisabled}
        onInputChange={onSearch}
        onMenuScrollToBottom={loadNext}
        creatable={false}
        options={
          company_groups.map(({ name, uuid }) => ({
            label: name,
            value: uuid,
          })) || []
        }
        isLoading={isLoading || props.isLoading}
        actions={isFetching ? <Loader /> : null}
      />
      {children?.({ firstGroupId: company_groups[0]?.uuid })}
    </>
  );
};
