import classNames from "classnames";
import ConditionalToolTip from "components/design/conditionalToolTip";
import Async from "components/DesignSystem/AsyncComponents/Async";
import Breadcrumb from "components/DesignSystem/Breadcrumb/Breadcrumb";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { ArrowRight } from "components/icons/ArrowRight";
import { FILE_FOLDER_MOVED } from "constants/analyticsEvents";
import { NEW_FOLDER_MESSAGE } from "constants/documents";
import { useAnalytics } from "hooks/useAnalytics";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { useEffect, useState } from "react";
import EmptyFolder from "static/images/EmptyFolder.svg";
import {
  useCreateFolderMutation,
  useGetFolderContentQuery,
  useMoveDocItemsMutation,
} from "store/apis/documents";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";
import { FileIcon } from "utils/fileTypeIcon";
import { AddNewFolder } from "./AddNewFolder";

type MoveDocModalProps = {
  itemIds: string[];
  onMoveSuccess?: () => void;
} & ModalProps;

const Loader = () => {
  return (
    <div className="t-flex t-w-full t-items-center t-gap-2 t-px-2 t-py-1 t-group">
      <div className="t-bg-neutral-10 t-h-10 t-w-10 t-animate-pulse-fast" />
      <div className="t-bg-neutral-10 t-h-10 t-w-full t-animate-pulse-fast" />
    </div>
  );
};

export const MoveDocModal = ({
  isOpen,
  close,
  itemIds,
  onMoveSuccess,
}: MoveDocModalProps) => {
  const { alertToast, successToast, infoToast } = useToast();
  const { trackEvent } = useAnalytics();
  const { uuid: groupId, documents_group_id } = useCurrentGroupContext();
  const [folderId, setFolderId] = useState(documents_group_id);
  const [selectedFolder, setSelectedFolder] = useState({
    uuid: "",
    name: "",
  });
  const [createFolder] = useCreateFolderMutation();
  const [moveDocItems, { isLoading: isMoving }] = useMoveDocItemsMutation();

  const { data, isLoading, isSuccess, isFetching } = useGetFolderContentQuery(
    {
      folderId,
      groupId,
    },
    {
      skip: !folderId || !groupId,
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (documents_group_id && !folderId) {
      setFolderId(documents_group_id);
    }
  }, [documents_group_id, folderId]);

  const { parents_list = [], subfolders = [], files = [] } = data || {};
  const folderItems = [...subfolders, ...files];
  const currentFolder = parents_list?.at(-1);
  const addNewFolder = useModal();

  const onCreateFolder = async ({ folder_name }: { folder_name: string }) => {
    if (!currentFolder?.uuid) {
      return;
    }
    try {
      await createFolder({
        folder_name,
        groupId,
        parentFolderId: currentFolder?.uuid,
      }).unwrap();
      successToast({ message: NEW_FOLDER_MESSAGE });
      addNewFolder.close();
    } catch (error) {
      alertToast(
        { message: (error as BackendError).data?.error?.message },
        error as Error
      );
    }
  };

  const onFolderClick = (folderId: string) => {
    setFolderId(folderId);
    setSelectedFolder({
      uuid: "",
      name: "",
    });
  };

  const moveItems = async () => {
    const destination_folder_id = selectedFolder.uuid || folderId;

    try {
      await moveDocItems({
        destination_folder_id,
        file_uuids: itemIds,
        groupId,
      }).unwrap();
      onMoveSuccess?.();
      trackEvent(FILE_FOLDER_MOVED, {
        file_or_folder_ids: itemIds,
        destination_folder_id: destination_folder_id,
      });
      successToast({
        message: `File moved to ${selectedFolder.name || currentFolder?.name}`,
      });
      close();
    } catch (error) {
      alertToast(
        { message: (error as BackendError).data?.error?.message },
        error as Error
      );
    }
  };

  const onClose = () => {
    infoToast({ message: "Move cancelled" });
    close();
  };

  return (
    <>
      <Modal.Root open={isOpen} onOpenChange={onClose}>
        <Modal.Content>
          <Modal.Header>
            <div>
              <Modal.Title>
                <div className="t-line-clamp-1">
                  {isLoading ? "Loading..." : currentFolder?.name}
                </div>
              </Modal.Title>
              {isSuccess && (
                <Modal.Subtitle>
                  <Breadcrumb.Root itemsToDisplay={3}>
                    {parents_list.map(({ name, uuid }) => (
                      <Breadcrumb.Item
                        key={uuid}
                        as="button"
                        onClick={() => onFolderClick(uuid)}
                      >
                        <div className="t-line-clamp-1">{name}</div>
                      </Breadcrumb.Item>
                    ))}
                  </Breadcrumb.Root>
                </Modal.Subtitle>
              )}
            </div>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body className="t-h-[50dvh]">
            <Button size="small" onClick={addNewFolder.open}>
              New Folder
            </Button>
            <Async.Root
              isLoading={isFetching}
              isEmpty={folderItems.length === 0}
              isSuccess={isSuccess}
              customLoader={
                <div className="t-flex t-flex-col t-gap-1 t-mt-2">
                  {Array.from({ length: 6 }).map((_, index) => (
                    <Loader key={index} />
                  ))}
                </div>
              }
            >
              <Async.Empty>
                <div className="t-flex t-justify-center t-items-center t-flex-col t-gap-4 t-h-full">
                  <img
                    src={EmptyFolder}
                    alt="empty folder"
                    className="t-w-44 t-h-36"
                  />
                  <h4 className="t-text-body t-text-text-30">
                    This folder is empty
                  </h4>
                </div>
              </Async.Empty>
              <Async.Success>
                <div className="t-flex t-flex-col t-gap-1 t-mt-2">
                  {folderItems.map(({ uuid, name, file_type, type }) => (
                    <button
                      disabled={type === "FILE"}
                      key={uuid}
                      onClick={(e) =>
                        setSelectedFolder({
                          uuid,
                          name,
                        })
                      }
                      onDoubleClick={(e) => onFolderClick(uuid)}
                      className={classNames(
                        "all:unset t-flex t-w-full t-items-center t-gap-2 t-px-2 t-py-1 t-rounded disabled:t-text-neutral-50 t-text-text-100 t-text-body-sm disabled:t-opacity-50 disabled:t-pointer-events-none t-group",
                        {
                          "t-bg-surface-purple ": selectedFolder.uuid === uuid,
                          "hover:t-bg-surface-lighter-grey focus-visible:t-bg-surface-lighter-grey focus-visible:t-outline focus-visible:t-outline-surface-grey":
                            selectedFolder.uuid !== uuid,
                        }
                      )}
                    >
                      <FileIcon
                        fileType={file_type}
                        width="32px"
                        height="32px"
                      />
                      <div className="t-line-clamp-1">{name}</div>
                      {type === "FOLDER" && (
                        <button
                          className="t-ml-auto t-text-purple-70 t-hidden group-hover:t-block t-rounded-full hover:t-bg-purple-20 all:unset t-items-center"
                          onClick={(e) => {
                            e.stopPropagation();
                            onFolderClick(uuid);
                          }}
                          onDoubleClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <ArrowRight
                            size="32"
                            stroke="1.4"
                            color="currentColor"
                          />
                        </button>
                      )}
                    </button>
                  ))}
                </div>
              </Async.Success>
            </Async.Root>
          </Modal.Body>
          <Modal.FooterButtonGroup>
            <ConditionalToolTip
              condition={
                selectedFolder.uuid
                  ? `Move to ${selectedFolder.name}`
                  : `Move to ${currentFolder?.name}`
              }
            >
              <Button
                customType="primary"
                disabled={isMoving}
                onClick={moveItems}
                isLoading={isMoving}
              >
                Move Here
              </Button>
            </ConditionalToolTip>
          </Modal.FooterButtonGroup>
        </Modal.Content>
      </Modal.Root>
      {addNewFolder.isOpen && (
        <AddNewFolder
          isOpen={addNewFolder.isOpen}
          close={addNewFolder.close}
          createFolder={onCreateFolder}
        />
      )}
    </>
  );
};
