import { EntitySelector } from "components/EntitySelector/EntitySelector";
import { AddManualBank } from "components/Connections/AddManualBank";
import { Button } from "components/DesignSystem/Button/Button";
import { Tag } from "components/DesignSystem/Tag/Tag";
import { LinkBankAccount } from "components/LinkBankAccount";
import { useBankConnect } from "hooks/useBankConnect";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { AccountCard as AddManualBankAccountCard } from "pages/Books/DataSources/DataSourcesList";
import BankCircular from "static/images/BankCircular.svg";
import CheckCircle from "static/images/CheckCircle.svg";
import Plaid from "static/images/Plaid.svg";
import { useGetAllConnectionsQuery } from "store/apis/booksConnections";
import { useGetEntityBanksQuery } from "store/apis/bankConnections";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useModal } from "hooks/useModal";

export const AccountCard = ({ logo, bankName, accountName, mask }: any) => {
  return (
    <div className="t-border t-shadow-entity-card t-rounded-lg t-border-solid t-border-[#0000001a] t-flex t-items-center t-justify-between t-gap-2.5 t-p-5">
      <div className="t-flex t-items-center t-gap-3">
        <img src={logo} className="t-w-8 t-rounded-full" alt="" />
        <div className="t-max-w-[126px] t-text-body t-text-i-primary-100 t-truncate">
          <span>{accountName}</span>
        </div>
        <div className="t-flex t-items-center t-gap-0.5">
          <div className="t-w-2 t-h-2 t-rounded-full t-bg-i-primary-100"></div>
          <div className="t-w-2 t-h-2 t-rounded-full t-bg-i-primary-100"></div>
          <span className="t-text-body-sm">{mask}</span>
        </div>
      </div>
      <img src={CheckCircle} alt="connected" className="t-h-6 t-w-6" />
    </div>
  );
};

export const ConnectBank = ({
  setIsBankConnected,
  isCompleted,
  isCurrentStepDisabled,
}: {
  setIsBankConnected: (v: boolean) => void;
  isCompleted: boolean;
  isCurrentStepDisabled: boolean;
}) => {
  const group = useCurrentGroupContext();
  const groupId = group?.uuid || "";
  const entityId = useCurrentEntityId();
  const { isCpa, isForeignCA } = useRoleBasedView();

  const { data: connections } = useGetAllConnectionsQuery(
    {
      groupId: groupId!,
      entityId: entityId!,
    },
    { skip: !groupId || !entityId }
  );

  const { data: ledger } = useGetEntityBanksQuery(
    { entityId, groupId },
    { skip: !entityId || !groupId }
  );

  const { accounts = [] } = ledger || {};

  const {
    isLoading: getAuthUrlLoading,
    originalArgs: getAuthUrlArgs,
    onConnect,
  } = useBankConnect();

  const addManualBankModal = useModal();

  return (
    <>
      <div className="t-max-w-[464px] t-flex t-flex-col t-items-center t-gap-6 t-mx-auto t-p-3">
        <div className="t-flex t-flex-col t-w-full t-gap-5">
          <div className="t-flex t-flex-col t-gap-2">
            <div className="t-text-h5 t-text-text-100">
              Connect data sources
            </div>
            <div className="t-text-body-sm t-text-text-30">
              Inkle Books natively integrates with Brex & Mercury. We use Plaid
              to connect with all other banking institutions.
            </div>
          </div>
          <div className="t-w-2/5">
            <EntitySelector showOnlyUSEntities />
          </div>
          <div className="t-flex t-flex-col t-gap-3">
            <div className="t-text-subtitle-sm">Banking Institutions</div>
            {connections?.map((connection) => (
              <div
                className="t-border t-border-solid t-border-purple-10 t-rounded t-p-3 t-flex t-justify-between t-items-center t-w-full"
                key={connection.uuid}
              >
                <div>
                  <div className="t-flex t-gap-3 t-items-center">
                    <img
                      className="t-w-8 t-h-8 t-rounded-full"
                      src={connection.logo_url}
                      alt=""
                    />

                    <div>
                      <p className="t-mb-1 t-text-body-lg">{connection.name}</p>
                      <p className="t-m-0 t-text-body-sm t-text-text-30 t-pr-2">
                        Direct API integration for fetching transactions data
                      </p>
                    </div>
                  </div>
                </div>
                {connection.is_connection_available ? (
                  <div className="t-flex-shrink">
                    <Tag tagType="green">Connected</Tag>
                  </div>
                ) : (
                  <Button
                    size="small"
                    onClick={() =>
                      onConnect({
                        connectionId: connection.uuid,
                        invokedFrom:
                          isCpa || isForeignCA
                            ? `/books?company=${groupId}&entity=${entityId}&books_onboarding=true`
                            : `/books?entity=${entityId}&books_onboarding=true`,
                      })
                    }
                  >
                    Connect
                  </Button>
                )}
              </div>
            ))}
            <div className="t-border t-border-solid t-border-purple-10 t-rounded t-p-3 t-flex t-justify-between t-items-center t-w-full">
              <div>
                <div className="t-flex t-gap-3 t-items-center">
                  <img
                    className="t-w-8 t-h-8 t-rounded-full"
                    src={Plaid}
                    alt=""
                  />

                  <div>
                    <p className="t-mb-1 t-text-body-lg">
                      Other banking institutions
                    </p>
                    <p className="t-m-0 t-text-body-sm t-text-text-30">
                      Connect other banks securely via Plaid
                    </p>
                  </div>
                </div>
              </div>
              <LinkBankAccount entityId={entityId} groupId={groupId}>
                {({ ready, connectPlaid }) => (
                  <Button
                    size="small"
                    onClick={(e) => {
                      if (document.body) {
                        document.body.style.pointerEvents = "auto";
                      }
                      connectPlaid(e);
                    }}
                    disabled={!ready}
                  >
                    Connect
                  </Button>
                )}
              </LinkBankAccount>
            </div>
            <AddManualBankAccountCard
              logo={
                <img
                  src={BankCircular}
                  className="t-rounded-full t-w-8 t-h-8 "
                  alt=""
                />
              }
              title="Add bank/card"
              description={
                <>
                  Manually enter bank account or
                  <br />
                  credit card details.
                </>
              }
              CTA={
                groupId && (
                  <Button size="small" onClick={addManualBankModal.open}>
                    Add details
                  </Button>
                )
              }
            />
          </div>
          {accounts.length > 0 && (
            <div className="t-flex t-flex-col">
              <div className="t-text-subtitle-sm">Connected Accounts</div>
              <div className="t-mt-4 t-mb-3 t-flex t-flex-col t-gap-3 t-max-h-72 t-overflow-y-scroll">
                {accounts.map((account) => (
                  <AccountCard
                    key={account.account.uuid}
                    mask={account.account.mask}
                    bankName={account.bank_brand.name}
                    accountName={account.account.nickname}
                    logo={account.bank_brand.logo}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <AddManualBank
        isOpen={addManualBankModal.isOpen}
        close={addManualBankModal.close}
      />
    </>
  );
};
