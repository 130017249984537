import { useAppSelector } from "hooks/useAppSelector";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import {
  TransactionFilterSet,
  useGetTransactionsFilterSetQuery,
} from "store/apis/transactions";

export const useGetTransactionsFilterSet = ({
  filter_operation,
  unSelectedRowsIds,
  skip,
}: {
  filter_operation: TransactionFilterSet["filter_operation"];
  unSelectedRowsIds: string[];
  skip: boolean;
}) => {
  const entityId = useCurrentEntityId();
  const { uuid: groupId } = useCurrentGroupContext();

  const {
    filters: {
      uncategorised,
      minAmount,
      maxAmount,
      excludedTransactions,
      ...restFilter
    },
  } = useAppSelector((state) => state.transactionFilter);

  return useGetTransactionsFilterSetQuery(
    {
      groupId,
      entityId,
      uncategorized: uncategorised,
      txnStatus: excludedTransactions?.value
        ? ("EXCLUDED" as const)
        : undefined,
      minAmount: ((minAmount?.value || "") as string)
        ?.replaceAll("$", "")
        .replaceAll(",", ""),
      maxAmount: ((maxAmount?.value || "") as string)
        ?.replaceAll("$", "")
        .replaceAll(",", ""),
      filter_operation,
      excluded_txns_list: unSelectedRowsIds,
      ...restFilter,
    },
    {
      skip: !groupId || !entityId || skip,
      refetchOnMountOrArgChange: true,
    }
  );
};
