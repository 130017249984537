import classNames from "classnames";
import Loader from "components/design/loader";
import { Button } from "components/DesignSystem/Button/Button";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import { SelectDropDown } from "components/DesignSystem/SelectDropDown/SelectDropDown";
import Slider from "components/DesignSystem/Slider/Slider";
import { PlusIcon } from "components/icons/PlusIcon";
import { Badge } from "components/Task/Badge";
import { CREATE, EDIT } from "constants/autofill";
import dayjs from "dayjs";
import { Field, FieldProps, Form, Formik } from "formik";
import { directorSchema } from "formValidations/directorofficers";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetAutofillQuery } from "store/apis/autofill";
import {
  useAddAPAutoFillMutation,
  useGetAPAutofillsQuery,
  useUpdateAPAutoFillMutation,
} from "store/apis/directorsAndOfficers";
import { APAutofills } from "types/Models/apAutofills";
import { DirectorsOfficersSlider } from "./DirectorsOfficersSlider";
import { DD_MMM_YYYY } from "constants/date";
import Modal from "components/DesignSystem/Modal/Modal";
import {
  createColumnHelper,
  getCoreRowModel,
  Row,
  useReactTable,
} from "react-table-8.10.7";
import { TableUI } from "components/design/TableUI";
import { formatDate } from "utils/formatDate";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import { SelectAutofillCombobox } from "components/SelectAutofillCombobox";

const createColumn = createColumnHelper<APAutofills>();

const statusOptions = [
  {
    label: "Active",
    value: "ACTIVE",
  },
  {
    label: "Former",
    value: "FORMER",
  },
];

const columns = [
  createColumn.accessor("persona_autofill", {
    header: "Name",
    size: 34,
    cell: (info) =>
      info.getValue()?.first_name + " " + info.getValue()?.last_name,
  }),
  createColumn.accessor("appointed_on", {
    header: "Date appointed",
    size: 46,
    cell: (info) => (
      <div>
        {formatDate(info.getValue())}
        {info.row.original.resigned_on
          ? ` to ${formatDate(info.row.original.resigned_on)}`
          : ""}
      </div>
    ),
  }),
  createColumn.accessor("ap_status", {
    header: "Status",
    size: 20,
    cell: (info) => (
      <div className="t-flex t-text-center t-text-body-sm">
        <Badge
          type={info.getValue()}
          children={
            info.getValue().charAt(0).toUpperCase() +
            info.getValue().slice(1).toLowerCase()
          }
        />
      </div>
    ),
  }),
];

export const DirectorsTable = () => {
  const { alertToast } = useToast();
  const { entityId } = useParams<{ entityId: string }>();
  const { uuid: groupId } = useCurrentGroupContext();
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [openSlider, setOpenSlider] = useState<boolean>(false);
  const [selectedPersonaId, setSelectedPersonaId] = useState<string | null>(
    null
  );
  const [pageNum, setPageNum] = useState<number>(1);
  const [typeAddEdit, setTypeAddEdit] = useState<string>(CREATE);
  const {
    data: directors,
    isLoading,
    isSuccess,
  } = useGetAPAutofillsQuery(
    {
      entityId,
      personaType: "DIRECTOR",
      isSort: true,
      pageNum: pageNum,
    },
    { skip: !entityId }
  );

  const [updateAPAutofill, { isLoading: updatingAPAutofill }] =
    useUpdateAPAutoFillMutation();

  const [createDirectorAP, { isLoading: isCreatingDirectorAP }] =
    useAddAPAutoFillMutation();

  const { data: personaAutofill = [] } = useGetAutofillQuery(
    {
      groupId,
      autofillKey: "authorized_persons",
      entityId,
    },
    { skip: !groupId }
  );

  const isAddEditBtnLoading = isCreatingDirectorAP || updatingAPAutofill;

  const {
    authorized_personas = [],
    total_pages = 0,
    total_count = 0,
    current_page = 1,
    per_page = 25,
  } = directors || {};

  const paginationData = {
    totalPage: total_pages,
    currentPage: current_page,
    itemsPerPage: per_page,
    totalItemCount: total_count,
  };

  const goToFirstPage = () => {
    setPageNum(1);
  };

  const goToPrevPage = () => {
    setPageNum((prev) => (prev < total_pages ? prev - 1 : total_pages - 1));
  };

  const goToNextPage = () => {
    if (pageNum < total_pages) {
      setPageNum((prev) => prev + 1);
    }
  };
  const goToLastPage = () => {
    setPageNum(total_pages);
  };

  const data = useMemo(() => authorized_personas, [authorized_personas]);

  const table = useReactTable({
    columns,
    getCoreRowModel: getCoreRowModel(),
    data,
  });

  const onRowClick = (row: Row<APAutofills>) => {
    setSelectedPersonaId(row.original.uuid);
    setOpenSlider(true);
  };

  const handleCreateDirector = async (values: any) => {
    let payload = {
      ap_status: values?.ap_status?.toUpperCase(),
      persona_autofill_id: values?.persona_autofill_id,
      role_id: null,
      appointed_on: values?.appointed_on
        ? dayjs(values?.appointed_on).format("YYYY-MM-DD")
        : null,
      resigned_on: values?.resigned_on
        ? dayjs(values?.resigned_on).format("YYYY-MM-DD")
        : null,
    };

    try {
      if (typeAddEdit === CREATE) {
        await createDirectorAP({
          entityId,
          personaType: "DIRECTOR",
          payload,
        }).unwrap();
      } else if (typeAddEdit === EDIT) {
        await updateAPAutofill({
          entityId,
          personaType: "DIRECTOR",
          personaId: values?.uuid,
          payload,
        });
        setTypeAddEdit(CREATE);
      }
    } catch (err: any) {
      alertToast({ message: err?.response?.data?.error?.message });
    }
    setShowAddModal(false);
  };

  const selectedRowData = authorized_personas.find(
    ({ uuid }) => uuid === selectedPersonaId
  );

  const getInitializedValue = () => {
    if (typeAddEdit === EDIT) {
      return {
        ap_status: selectedRowData?.ap_status || "ACTIVE",
        persona_autofill_id: selectedRowData?.persona_autofill?.uuid || "",
        appointed_on: selectedRowData?.appointed_on
          ? new Date(selectedRowData?.appointed_on)
          : "",
        resigned_on: selectedRowData?.resigned_on
          ? new Date(selectedRowData?.resigned_on)
          : "",
        uuid: selectedRowData?.uuid || "",
      };
    }

    return {
      ap_status: "ACTIVE",
      persona_autofill_id: "",
      appointed_on: "",
      resigned_on: "",
      uuid: selectedRowData?.uuid || "",
    };
  };

  const onAddClick = () => {
    setTypeAddEdit(CREATE);
    setShowAddModal(true);
    setOpenSlider(false);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="t-flex t-flex-col">
      <div className="t-mb-5 t-flex t-items-center t-justify-between">
        <div className="t-text-h5">Directors</div>
        <Button size="small" customType="primary" onClick={onAddClick}>
          <div className="t-flex t-items-center t-gap-2">
            <PlusIcon color="currentColor" />
            <div>Add</div>
          </div>
        </Button>
      </div>

      <>
        <div className="t-flex t-h-full t-gap-2">
          <TableUI size="regular" table={table} onRowClick={onRowClick} />
          <Slider.Root open={openSlider} onOpenChange={setOpenSlider}>
            <Slider.Content open={openSlider}>
              <DirectorsOfficersSlider
                title="Director"
                setTypeAddEdit={setTypeAddEdit}
                selectedRowData={selectedRowData}
                setShowAddModal={setShowAddModal}
              />
            </Slider.Content>
          </Slider.Root>
        </div>
        <div className="t-flex t-justify-end">
          <Pagination
            {...paginationData}
            goToFirstPage={goToFirstPage}
            goToPrevPage={goToPrevPage}
            goToNextPage={goToNextPage}
            goToLastPage={goToLastPage}
          />
        </div>
      </>

      <Modal.Root
        open={showAddModal}
        onOpenChange={setShowAddModal}
        modal={false}
      >
        <Modal.Content useCustomOverlay>
          <Formik
            initialValues={getInitializedValue()}
            onSubmit={handleCreateDirector}
            validateOnChange
            validationSchema={directorSchema}
            enableReinitialize
          >
            {({ submitForm, isSubmitting, values, setFieldValue }) => {
              let showFormerDateInput = false;
              if (values?.ap_status === "FORMER") {
                showFormerDateInput = true;
              }
              const selectedAutofill = personaAutofill?.find(
                ({ uuid }: { uuid: string }) =>
                  values.persona_autofill_id === uuid
              );
              return (
                <>
                  <Modal.Header>
                    <Modal.Title>
                      {typeAddEdit === CREATE ? "Add" : "Edit"} Director
                    </Modal.Title>
                    <Modal.Close onClick={() => setTypeAddEdit(CREATE)} />
                  </Modal.Header>
                  <Modal.Body>
                    <Form className="t-m-0 t-flex t-flex-col gap-3">
                      <Combobox
                        label="Status"
                        options={statusOptions}
                        withForm
                        name="ap_status"
                        value={
                          values.ap_status
                            ? {
                                label: statusOptions.find(
                                  (type) => type.value === values.ap_status
                                )?.label,
                                value: values.ap_status,
                              }
                            : null
                        }
                      />

                      <SelectAutofillCombobox
                        placeholder="Select Persona"
                        entityId={entityId}
                        type="authorized_persons"
                        withForm
                        name="persona_autofill_id"
                        selected={selectedAutofill?.uuid}
                        label="Name"
                        onSelect={(autofill) =>
                          setFieldValue("persona_autofill_id", autofill.uuid)
                        }
                      />

                      <Field name="appointed_on">
                        {({ field }: FieldProps) => {
                          return (
                            <DateInput
                              {...field}
                              label="Appointed on"
                              placeholder={DD_MMM_YYYY}
                              portalId="officer-appointed-on"
                            />
                          );
                        }}
                      </Field>
                      {showFormerDateInput && (
                        <Field name="resigned_on">
                          {({ field }: FieldProps) => {
                            return (
                              <DateInput
                                minDate={
                                  values.appointed_on
                                    ? new Date(values.appointed_on)
                                    : null
                                }
                                {...field}
                                label="Removed on"
                                placeholder={DD_MMM_YYYY}
                                portalId="officer-resigned-on"
                              />
                            );
                          }}
                        </Field>
                      )}
                    </Form>
                  </Modal.Body>
                  <Modal.FooterButtonGroup>
                    <Button
                      type="button"
                      onClick={() => setShowAddModal(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={submitForm}
                      customType="primary"
                      type="submit"
                      isLoading={isAddEditBtnLoading || isSubmitting}
                    >
                      {typeAddEdit === CREATE ? "Confirm" : "Save"}
                    </Button>
                  </Modal.FooterButtonGroup>
                </>
              );
            }}
          </Formik>
        </Modal.Content>
      </Modal.Root>
    </div>
  );
};
