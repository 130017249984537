import { useEffect, useState } from "react";
import { Pagination } from "types/Models/pagination";

type QueryArgs = {
  [key: string]: any;
};

/**  Uses RTQ's use query but paginate it based on calling the loadNext function 
 @param useQueryFunction - Should be a query from RTQ
 @param dataKey - Key that backend returns in data eg: { total_count: 12, company_groups: CompanyGroup }, here company_groups as a string is required
 @param args -  args that the query function takes
*/
export const usePaginatedQuery = <
  S = { [key: string]: unknown[] },
  T extends (...args: any) => [
    (...args: any) => Promise<{
      data?: Pagination & S;
      isLoading: boolean;
      isSuccess: boolean;
      isFetching: boolean;
    }>,
    {
      data?: Pagination & S;
      isLoading: boolean;
      isSuccess: boolean;
      isFetching: boolean;
    },
    any
  ] = (...args: any) => [
    (...args: any) => Promise<any>,
    {
      data?: Pagination & S;
      isLoading: boolean;
      isSuccess: boolean;
      isFetching: boolean;
    },
    any
  ]
>(
  useQueryFunction: T,
  dataKey: keyof S,
  // TODO: Add useQueryFunction args type
  args: QueryArgs,
  requiredArgs: string[] = []
) => {
  const [state, setState] = useState<Pagination & S>();
  const [pageNum, setPageNum] = useState(1);
  const [trigger, { data, isLoading, isSuccess, isFetching }] =
    useQueryFunction(args);

  const triggerApi = async (
    newPageNumber: number,
    type: "UPDATE" | "RESET" = "UPDATE"
  ) => {
    if (requiredArgs.some((arg) => !args[arg as keyof typeof args])) {
      return;
    }

    try {
      const currentData = (state?.[dataKey] || []) as any[];
      const { data } = await trigger({
        ...args,
        page_num: type === "RESET" ? 1 : newPageNumber,
      });

      if (data) {
        setState({
          ...data,
          [dataKey]:
            type === "RESET"
              ? data[dataKey]
              : [...currentData, ...(data[dataKey] as any[])],
        });
      }
    } catch (error) {}
  };

  const goToNextPage = () => {
    if (data?.total_pages && pageNum < data?.total_pages) {
      triggerApi(pageNum + 1);
      setPageNum((prev) => prev + 1);
    }
  };

  const argString = JSON.stringify(args) || "";

  useEffect(() => {
    setPageNum(1);
    triggerApi(1, "RESET");
  }, [argString]);

  return {
    pageNum,
    loadNext: goToNextPage,
    data: state,
    hasMore: pageNum >= (data?.total_pages || 0),
    isLoading,
    isSuccess,
    isFetching,
  };
};
