import React, { ReactNode } from "react";
import * as Tooltip from "@radix-ui/react-tooltip";

const ToolTip = ({
  text,
  children,
  propClass = "t-max-w-[500px]",
  delayDuration = 0,
  disableHoverableContent = false,
  keepOpenOnActivation = false,
  ...props
}: Tooltip.TooltipContentProps & {
  children: ReactNode;
  text?: string | ReactNode;
  delayDuration?: number;
  propClass?: string;
  disableHoverableContent?: boolean;
  keepOpenOnActivation?: boolean;
}) => {
  const triggerRef = React.useRef<HTMLButtonElement | null>(null);

  return (
    <Tooltip.Provider>
      <Tooltip.Root
        delayDuration={delayDuration}
        disableHoverableContent={disableHoverableContent}
      >
        <Tooltip.Trigger
          onClick={(e) => {
            if (keepOpenOnActivation) {
              e.preventDefault();
            }
          }}
          ref={triggerRef}
          asChild
        >
          {children}
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            {...props}
            className={`t-bg-neutral-100 t-text-surface t-p-3 t-rounded t-text-body-sm t-z-tooltip t-text-center ${propClass} t-break-all t-hyphen-auto t-shadow-dark-30`}
            onPointerDownOutside={(e) => {
              if (
                keepOpenOnActivation &&
                e.target &&
                triggerRef.current?.contains?.(e.target as Node)
              ) {
                e.preventDefault();
              }
            }}
          >
            {text}
            <Tooltip.Arrow />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};

export default ToolTip;
