import Radio from "components/DesignSystem/RadioGroup/RadioGroup";
import { useFormikContext } from "formik";
import { FiltersValues } from "store/slices/transactionFilter";

export const CashFlow = ({
  updateFilter,
  cashFlow,
}: {
  updateFilter: (v: any, a: any) => void;
  cashFlow?: FiltersValues;
}) => {
  const { setValues } = useFormikContext();

  const handleRadioCheck = (value: string) => {
    if (value === "ALL") {
      setValues({ minAmount: "", maxAmount: "" });
      updateFilter("minAmount", "");
      updateFilter("maxAmount", "");
      updateFilter("cashFlow", "");
      return;
    }
    updateFilter("cashFlow", value);
  };

  return (
    <div className="t-flex t-flex-col t-gap-3">
      <div className="t-text-caption t-text-neutral-80 t-w-full">Type</div>
      <Radio.Root
        onValueChange={handleRadioCheck}
        defaultValue={(cashFlow?.value as string) || "ALL"}
      >
        <Radio.Content>
          <Radio.Item asChild value="ALL">
            All
          </Radio.Item>
          <Radio.Item asChild value="CREDIT">
            Credit only
          </Radio.Item>
          <Radio.Item asChild value="DEBIT">
            Debit only
          </Radio.Item>
        </Radio.Content>
      </Radio.Root>
    </div>
  );
};
