import ToolTip from "components/design/toolTip";
import { Button } from "components/DesignSystem/Button/Button";
import DropDown from "components/DesignSystem/Dropdown/Dropdown";
import { CaretDown } from "components/icons/CaretDown";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import {
  COLDLEAD,
  COLD_LEAD,
  HOTLEAD,
  HOT_LEAD,
  LOSTLEAD,
  LOST_LEAD,
  NEWLEAD,
  NEW_LEAD,
  SPAMLEAD,
  SPAM_LEAD,
  WARMLEAD,
  WARM_LEAD,
} from "types/contants/crm";

const SalesStages = [
  [NEW_LEAD, NEWLEAD],
  [COLD_LEAD, COLDLEAD],
  [WARM_LEAD, WARMLEAD],
  [HOT_LEAD, HOTLEAD],
  [LOST_LEAD, LOSTLEAD],
  [SPAM_LEAD, SPAMLEAD],
];

const SalesStagesColor = {
  NEW_LEAD: [NEWLEAD, "t-bg-neutral-0"],
  COLD_LEAD: [COLDLEAD, "t-bg-red-20"],
  WARM_LEAD: [WARMLEAD, "t-bg-red-50"],
  HOT_LEAD: [HOTLEAD, "t-bg-red-70"],
  LOST_LEAD: [LOSTLEAD, "t-bg-yellow-80"],
  SPAM_LEAD: [SPAMLEAD, "t-bg-neutral"],
};

const SalesCrmStage = ({
  currentStage,
  groupId,
  updateSalesStage,
}: {
  currentStage: string;
  groupId: string;
  localUpdate?: boolean;
  updateSalesStage: ({
    groupId,
    payLoad,
  }: {
    groupId: string;
    payLoad: {};
  }) => any;
}) => {
  const { alertToast } = useToast();
  const [dropdownOpen, setdropdownOpen] = useState(false);
  const updateCompanyStage = async (stage: string) => {
    try {
      await updateSalesStage({
        groupId: groupId,
        payLoad: { sales_stage: stage },
      }).unwrap();
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
    }
  };

  return (
    <DropDown.Root
      open={dropdownOpen}
      onOpenChange={(open) => setdropdownOpen(open)}
    >
      <DropDown.Trigger className="all:unset t-group t-flex t-justify-center t-w-full">
        <Button
          customType="ghost"
          onClick={() => {
            setdropdownOpen(true);
          }}
        >
          <ToolTip
            side="top"
            //  @ts-ignore
            text={SalesStagesColor[currentStage][0]}
          >
            <>
              <div
                //  @ts-ignore
                className={`t-inline-block t-h-[18px] t-w-[18px] t-rounded-[50%] ${SalesStagesColor[currentStage][1]}`}
              ></div>
              <div className="group-data-state-open:t-rotate-180 t-ml-2">
                <CaretDown />
              </div>
            </>
          </ToolTip>
        </Button>
      </DropDown.Trigger>

      <DropDown.Portal>
        <DropDown.Content className="t-max-h-96 t-overflow-auto t-py-2">
          {SalesStages.map((stage: any) => {
            const [stageName, stage_text] = stage;
            return (
              <DropDown.Item
                className="t-flex t-cursor-pointer t-items-center t-gap-2 t-px-4 t-py-2"
                onClick={() => {
                  updateCompanyStage(stageName);
                }}
                key={stage_text}
              >
                {stage_text}
              </DropDown.Item>
            );
          })}
        </DropDown.Content>
      </DropDown.Portal>
    </DropDown.Root>
  );
};

export default SalesCrmStage;
