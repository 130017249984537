import { useEffect, useState } from "react";

//components
import WhatsappIcon from "static/images/whatsappIcon.png";
import GoogleBtn from "../../../signInSignUp/googleBtn";
import { AuthFooter } from "../../design/authFooter";
import AuthHeader from "../authHeader";

//style
import "../../../static/styles/components/magicLinkSignin.css";

//hooks
import { Button } from "components/DesignSystem/Button/Button";
import { useQuery } from "../../../hooks/useQuery";
import { openLink } from "utils/openLink";
import { PHONE } from "constants/help";
import { emailSchema } from "formValidations/emailSchema";
import { Form, Formik } from "formik";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { checkValidEmail } from "utils/checkValidEmail";
import { ConditionalLink } from "components/conditionalLink";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { PRIVACY_POLICY, TERMS_AND_CONDITIONS } from "constants/onBoarding";

// constants
const clientId = process.env.REACT_APP_CURRENT_GOOGLE_CLIENT_ID;

const SignIn = ({ updatePage, isLoading }) => {
  let query = useQuery();
  const [isTermsPrivacySelected, setIsTermsPrivacySelected] = useState(false);
  const [showError, setShowError] = useState(false);
  const couponCode = query.get("couponCode");

  const onToSPPSelection = (e) => {
    setIsTermsPrivacySelected(e.target.checked);
  };

  useEffect(() => {
    document.title = "Sign In | Inkle";
    if (couponCode) localStorage.setItem("couponCode", couponCode);
  }, [couponCode]);

  const sendMagicLink = ({ email }) => {
    if (isTermsPrivacySelected) {
      updatePage({ email, accept_tos: isTermsPrivacySelected });
    } else {
      setShowError(true);
    }
  };

  const getAccessToken = () => {
    if (isTermsPrivacySelected) {
      window.location.href = `https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?redirect_uri=${window.location.href}&prompt=consent&response_type=token&client_id=${clientId}&scope=openid%20email%20profile&flowName=GeneralOAuthFlow`;
    } else {
      setShowError(true);
    }
  };

  return (
    <div className="t-flex t-w-3/5 t-flex-col sm:t-w-full">
      <ConditionalLink to={`https://wa.me/${PHONE}`}>
        <button className="t-absolute t-bottom-8 t-right-8 t-z-50 t-flex t-h-10 t-w-10 t-items-center t-justify-center t-rounded-full t-border-0 t-bg-[transparent] t-bg-dark_green-30 t-shadow-hover-card-shadow t-drop-shadow-bubble">
          <img className="t-h-full" src={WhatsappIcon} alt="WhatsappIcon" />
        </button>
      </ConditionalLink>
      <div className="rightBox landingForm signinContainer t-px-[20%] t-text-center sm:t-px-[10%]">
        <AuthHeader signin />
        <Formik
          initialValues={{
            email: "",
          }}
          onSubmit={sendMagicLink}
          validationSchema={emailSchema}
        >
          <Form className="t-m-0 t-mt-4 t-flex t-w-full t-flex-col t-justify-between t-gap-6 t-text-left">
            <TextInput
              block
              name="email"
              type="email"
              placeholder="Email address"
              customSize="large"
            />
            <AuthFooter
              onChange={onToSPPSelection}
              isChecked={isTermsPrivacySelected}
              showError={!isTermsPrivacySelected && showError}
            />
            <div className="t-flex t-items-center t-justify-between t-gap-2 t-mt-2">
              <Button
                block
                size="large"
                customType="primary"
                type="submit"
                disabled={isLoading}
                isLoading={isLoading}
              >
                Sign in with magic link
              </Button>
            </div>
          </Form>
        </Formik>
        <div className="btnOrOption !t-mt-4">
          <div className="btnOrRow" />
          <span>or</span>
          <div className="btnOrRow" />
        </div>
        <GoogleBtn onClick={getAccessToken} />
      </div>
    </div>
  );
};

export default SignIn;
