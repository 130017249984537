import { default as classNames, default as cx } from "classnames";
import { CheckoutModal } from "components/CheckoutModal/CheckoutModal";
import ToolTip from "components/design/toolTip";
import { Button } from "components/DesignSystem/Button/Button";
import AddTaskEntityModal from "components/tasks/modal/AddTaskEntityModal";
import { TaskAlarmSlider } from "components/TaskSlider/TaskAlarmSlider";
import dayjs from "dayjs";
import { AnimatePresence, motion } from "framer-motion";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { ConfirmFiledExternally } from "pages/TaskAlarm/ConfirmFiledExternally";
import React, { MouseEvent, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { useDispatch } from "react-redux";
import { useAddProductsToCartAndCheckoutMutation } from "store/apis/billing";
import { useUpdateDeadlineFilingMutation } from "store/apis/calendar";
import { taskAlarmApis } from "store/apis/taskList";
import { BillingInvoice } from "types/Models/billing";
import {
  ComplianceDetail,
  ComplianceFilingStatus,
} from "types/Models/calendar";
import { BackendError } from "types/utils/error";
import { formatDate } from "utils/formatDate";
import { openLink } from "utils/openLink";
import { DeadlineBadge } from "./DeadlineBadge";

type DeadlineCompProps = {
  date?: string;
  title?: string;
  deadlineStatus?: {
    displayName: string;
    type: ComplianceFilingStatus;
  };
  description?: string;
  extension?: string | null;
  plan?: string;
  taskId?: string;
  taskTemplateId?: string;
  deadlinePassed: boolean;
  uuid: string;
  deadline: ComplianceDetail;
  isAlarmTask?: boolean;
};

export const NewDeadlineCard = ({
  date,
  title,
  deadlineStatus,
  extension,
  taskId,
  description,
  taskTemplateId,
  deadlinePassed,
  uuid,
  deadline,
  isAlarmTask,
}: DeadlineCompProps) => {
  const addTaskModal = useModal();
  const [updateDeadline, { isLoading: isUpdating }] =
    useUpdateDeadlineFilingMutation();
  const { alertToast, successToast } = useToast();
  const isFiledOutsideInkle = deadlineStatus?.type === "OUTSIDE_INKLE";
  const isTaskLaunchInProgress =
    deadlineStatus?.type === "TASK_CREATION_IN_PROGRESS";
  const entityId = useCurrentEntityId();
  const [alarmInvoice, setAlarmInvoice] = useState<BillingInvoice | null>();
  const alarmInvoiceModal = useModal();
  const { product_content_type_id, approx_price } =
    deadline.base_task_template_info || {};
  const group = useCurrentGroupContext();
  const { uuid: groupId, entities } = group;
  const [createProductInvoice, { isLoading: addingProduct }] =
    useAddProductsToCartAndCheckoutMutation();
  const confirmModal = useModal();
  const alarmEntity = entities.find(
    (entity) => entity.uuid === deadline.entity_id
  );
  const sliderModal = useModal();
  const dispatch = useDispatch();

  const updateDeadlineFile = async (e: MouseEvent<HTMLButtonElement>) => {
    try {
      e.stopPropagation();
      await updateDeadline({ alarmId: uuid }).unwrap();
      successToast({ title: "Filing marked successfully" });
    } catch (error) {
      alertToast({ message: (error as BackendError)?.data?.error?.message });
    }
    confirmModal.close();
  };

  const payInkleToLaunchFiling = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (groupId && uuid && product_content_type_id && taskTemplateId) {
      try {
        const payload = {
          entity_id: deadline.entity_id,
          product_content_type_id,
          product_id: taskTemplateId,
          quantity: 1,
          payment_amount:
            !approx_price || isNaN(Number(approx_price))
              ? 0
              : Number(approx_price),
          season: deadline.season,
          alarm_id: uuid,
        };

        const invoiceData = await createProductInvoice({
          groupId: groupId,
          entityId: deadline.entity_id,
          productData: [payload],
          from_alarms: true,
        }).unwrap();

        setAlarmInvoice(invoiceData?.invoice);
        alarmInvoiceModal.open();
      } catch (error) {
        alertToast({ message: (error as BackendError).data?.error?.message });
      }
    }
  };

  const closeAlarmInvoiceModal = () => {
    setAlarmInvoice(null);
    alarmInvoiceModal.close();
  };

  const onPaymentSuccess = () => {
    closeAlarmInvoiceModal();
    dispatch(taskAlarmApis.util.invalidateTags([{ type: "TASK_ALARM" }]));
  };

  const openConfirmModal = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    confirmModal.open();
  };

  return (
    <>
      <div
        className={classNames(
          "t-border t-border-solid t-border-neutral-10 t-rounded-lg",
          {
            "t-cursor-pointer": isAlarmTask,
          }
        )}
        onClick={() => isAlarmTask && sliderModal.open()}
      >
        <div className="t-group t-relative t-flex t-items-start t-gap-3 t-bg-white t-p-4 md:t-items-center md:t-gap-5 t-rounded-t-lg">
          {date && (
            <>
              <div className="t-hidden t-h-[70px] t-shrink-0 t-grow-0 t-basis-[67px] md:t-block" />
              <AnimatePresence>
                <motion.div
                  key={date}
                  transition={{ duration: 0.45, delay: -0.1 }}
                  initial={{ x: 30, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  exit={{ x: -30, opacity: 0 }}
                  className={cx(
                    "t-absolute t-hidden t-h-[70px] t-w-[67px] t-shrink-0 t-grow-0 t-flex-col t-items-center t-justify-center t-rounded-lg t-bg-neutral-0 md:t-flex"
                  )}
                >
                  <span className="t-block t-whitespace-nowrap t-text-body t-font-bold">
                    {dayjs(date).format("DD MMM")}
                  </span>
                  <span className="t-text-body-sm t-uppercase">
                    {dayjs(date).format("YYYY")}
                  </span>
                </motion.div>
              </AnimatePresence>
            </>
          )}

          <div className="t-grow t-overflow-hidden">
            <div className="t-flex md:t-gap-5 t-justify-between">
              <div className="t-w-2/3 t-flex-col t-gap-1 t-space-y-2 md:t-flex md:t-space-y-0">
                <div className="t-flex t-space-x-2 t-line-clamp-1">
                  <span className="t-text-subtitle t-text-text-60 t-min-w-fit t-line-clamp-1">
                    {title}
                  </span>
                  {alarmEntity?.country_code && (
                    <ToolTip text={alarmEntity?.name}>
                      <span className="t-border t-border-solid t-border-b-0 t-border-r-0 t-border-t-0 t-border-neutral-20 t-flex t-items-center t-pl-2 t-overflow-hidden">
                        <ReactCountryFlag
                          svg
                          countryCode={alarmEntity?.country_code}
                          height={12}
                          width={18}
                        />
                        <span className="t-text-text-30 t-text-body-sm t-ml-1 t-overflow-hidden t-truncate t-flex-shrink t-lineclamp-1 t-min-w-0">
                          {alarmEntity?.name}
                        </span>
                      </span>
                    </ToolTip>
                  )}
                </div>

                {date && (
                  <div className="t-flex t-items-start t-gap-2">
                    <div className="t-inline-flex t-hidden t-rounded t-bg-purple-0 t-px-2 t-py-0.5 t-text-body-sm t-text-purple-50 sm:t-block ">
                      {formatDate(date)}
                    </div>
                  </div>
                )}
                <div className="t-flex t-items-baseline t-gap-3">
                  {description && (
                    <p
                      className="t-m-0 t-w-full t-overflow-hidden t-text-ellipsis t-whitespace-nowrap t-line-clamp-1 t-text-body-sm t-tracking-normal md:t-text-body"
                      dangerouslySetInnerHTML={{ __html: description }}
                    ></p>
                  )}
                </div>
              </div>
              <div className="t-block t-text-body sm:t-hidden">
                {deadlineStatus &&
                  (deadlineStatus?.type === "TASK_CREATION_IN_PROGRESS" ||
                    deadlineStatus?.type === "OUTSIDE_INKLE") && (
                    <DeadlineBadge type={deadlineStatus?.type}>
                      {deadlineStatus?.displayName}
                    </DeadlineBadge>
                  )}
              </div>
            </div>
          </div>
        </div>
        <div className="t-p-4 t-rounded-b-lg t-bg-surface-lighter-grey t-flex t-justify-between t-items-center">
          {isFiledOutsideInkle && (
            <div className="t-flex t-gap-1 t-items-center">
              <div className="t-text-body-sm t-text-text-30">Done with:</div>
              <div className="t-text-subtext-sm t-text-text-30">
                External CPA
              </div>
            </div>
          )}
          <div className="t-flex t-gap-2 t-ml-auto">
            {taskId || isTaskLaunchInProgress ? (
              <Button
                size="small"
                onClick={() => openLink(`/filings/${taskId}`)}
                disabled={isTaskLaunchInProgress || isFiledOutsideInkle}
              >
                Go to filing
              </Button>
            ) : (
              <>
                {!deadlinePassed && (
                  <Button
                    size="small"
                    onClick={openConfirmModal}
                    disabled={isFiledOutsideInkle}
                  >
                    Filed externally
                  </Button>
                )}
                {taskTemplateId && !isFiledOutsideInkle && (
                  <Button
                    disabled={deadlinePassed || addingProduct}
                    size="small"
                    customType="primary"
                    onClick={payInkleToLaunchFiling}
                    isLoading={addingProduct}
                  >
                    Start filing
                  </Button>
                )}
                <ConfirmFiledExternally
                  isOpen={confirmModal.isOpen}
                  close={confirmModal.close}
                  onClick={updateDeadlineFile}
                  isLoading={isUpdating}
                />
              </>
            )}
          </div>
        </div>
        {addTaskModal.isOpen && (
          <AddTaskEntityModal
            to="/new-deadlines"
            taskTemplateId={taskTemplateId}
            handleClose={addTaskModal.close}
            disableLaunch={deadlinePassed}
          />
        )}
      </div>
      {sliderModal.isOpen && uuid && (
        <TaskAlarmSlider
          taskId={uuid}
          onClose={sliderModal.close}
          isOpen={sliderModal.isOpen}
          defaultTab="DETAILS"
          dataType="ALARM"
        />
      )}
      {alarmInvoiceModal.isOpen && Boolean(alarmInvoice) && (
        <CheckoutModal
          entityId={entityId}
          type="invoices"
          onInvoicePaid={onPaymentSuccess}
          open={alarmInvoiceModal.isOpen}
          onClose={closeAlarmInvoiceModal}
          invoices={[alarmInvoice as BillingInvoice]}
        />
      )}
    </>
  );
};
