import { NumericInput } from "components/NumericInput/NumericInput";
import { Form, Formik } from "formik";
import { ChangeEvent } from "react";
import { FiltersValues } from "store/slices/transactionFilter";
import { debounce } from "utils/debouncing";
import { CashFlow } from "./CashFlow";

export const Amount = ({
  updateFilter,
  minAmount,
  maxAmount,
  cashFlow,
}: {
  updateFilter: (v: any, a: any) => void;
  minAmount: FiltersValues;
  maxAmount: FiltersValues;
  cashFlow?: FiltersValues;
}) => {
  const handleAmountChange = debounce((e: ChangeEvent<HTMLFormElement>) => {
    const { value, name } = e.target;
    updateFilter(name, value);
  });

  return (
    <Formik
      initialValues={{
        minAmount: minAmount.value || "",
        maxAmount: maxAmount.value || "",
      }}
      onSubmit={() => {}}
    >
      {({ setValues }) => {
        return (
          <div className="t-flex t-flex-col t-gap-5">
            <div className="t-flex t-flex-col t-gap-3">
              <CashFlow updateFilter={updateFilter} cashFlow={cashFlow} />
              <Form
                className="all:unset t-flex t-flex-col t-gap-4"
                onChange={handleAmountChange}
              >
                <NumericInput
                  label="Min. Amount"
                  storeNumeric
                  fieldProps={{ name: "minAmount" }}
                  numericProps={{
                    thousandSeparator: true,
                    prefix: "$",
                    disabled: Boolean(!cashFlow?.value),
                  }}
                />
                <NumericInput
                  label="Max. Amount"
                  storeNumeric
                  fieldProps={{ name: "maxAmount" }}
                  numericProps={{
                    thousandSeparator: true,
                    prefix: "$",
                    disabled: Boolean(!cashFlow?.value),
                  }}
                />
              </Form>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};
