import ToolTip from "components/design/toolTip";
import { Button } from "components/DesignSystem/Button/Button";
import { DeleteIcon } from "components/icons/delete";
import { DownloadIcon } from "components/icons/Download";
import { Goto } from "components/icons/Goto";
import { Link } from "components/icons/Link";
import { MoveFile } from "components/icons/moveFile";
import { RenameIcon } from "components/icons/rename";
import {
  FILE_TYPE,
  FOLDER_TYPE,
  ICON_DEFAULT_COLOR,
} from "constants/documents";
import { useQuery } from "hooks/useQuery";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useContext } from "react";
import { DocumentContext } from "./documentContext";

type ActionsProps = {
  onRename: () => void;
  onCopy: () => void;
  goToFolder: () => void;
  onDownload: () => void;
  onDelete: () => void;
  onMove: () => void;
};

const Rename = ({ onRename }: { onRename: () => void }) => {
  return (
    <ToolTip text="Rename">
      <span>
        <Button
          size="small"
          customType="ghost_icon"
          onClick={onRename}
          title="Rename"
        >
          <RenameIcon color={ICON_DEFAULT_COLOR} size={16} />
        </Button>
      </span>
    </ToolTip>
  );
};

const CopyLink = ({ onCopy }: { onCopy: () => void }) => {
  return (
    <ToolTip text="Copy link to share with teammates.">
      <span>
        <Button
          size="small"
          customType="ghost_icon"
          onClick={onCopy}
          title="Copy link to share with teammates."
        >
          <Link size="16" />
        </Button>
      </span>
    </ToolTip>
  );
};

const GoToFolder = ({ goToFolder }: { goToFolder: () => void }) => {
  return (
    <ToolTip text="Go to folder">
      <span>
        <Button
          size="small"
          customType="ghost_icon"
          onClick={goToFolder}
          title="Go to folder"
        >
          <Goto color={ICON_DEFAULT_COLOR} size="16" />
        </Button>
      </span>
    </ToolTip>
  );
};

const Download = ({ onDownload }: { onDownload: () => void }) => {
  return (
    <ToolTip text="Download selected">
      <span>
        <Button
          size="small"
          customType="ghost_icon"
          onClick={onDownload}
          title="Download selected"
        >
          <DownloadIcon color={ICON_DEFAULT_COLOR} size={16} />
        </Button>
      </span>
    </ToolTip>
  );
};

const Delete = ({ onDelete }: { onDelete: () => void }) => {
  return (
    <ToolTip text="Delete">
      <span>
        <Button
          size="small"
          customType="ghost_icon"
          onClick={onDelete}
          title="Delete"
        >
          <DeleteIcon color={ICON_DEFAULT_COLOR} size="16" />
        </Button>
      </span>
    </ToolTip>
  );
};

const Move = ({ onMove }: { onMove: () => void }) => {
  return (
    <ToolTip text="Move">
      <span>
        <Button
          size="small"
          customType="ghost_icon"
          onClick={onMove}
          title="Move"
        >
          <MoveFile color={ICON_DEFAULT_COLOR} size="16" />
        </Button>
      </span>
    </ToolTip>
  );
};

const Actions = ({
  goToFolder,
  onRename,
  onCopy,
  onDownload,
  onDelete,
  onMove,
}: ActionsProps) => {
  const { isAdmin } = useRoleBasedView();
  const query = useQuery();
  const searchTerm = query.get("search");

  const { selectedFiles, entityFileAndFolder, selectedRecentDocs } =
    useContext(DocumentContext);

  const selectedFileData = [...selectedFiles, ...selectedRecentDocs];

  const isAllSelected = entityFileAndFolder.every(
    ({ isChecked }: { isChecked: boolean }) => isChecked
  );

  const isOnlyFolderSelected = selectedFileData.every(
    ({ type }) => type === FOLDER_TYPE
  );

  const isOnlyFilesSelected = selectedFileData.every(
    ({ type }) => type === FILE_TYPE
  );

  const selectedItemLength = selectedFileData.length;
  const isAnyFileLocked = selectedFileData.some(({ is_locked }) => is_locked);

  const singlefolderSelcted = isOnlyFolderSelected && selectedItemLength === 1;
  const singleFileSelected = isOnlyFilesSelected && selectedItemLength === 1;
  const muilpleFoldersSelected = isOnlyFolderSelected && selectedItemLength > 1;
  const muilpleFilesSelected = isOnlyFilesSelected && selectedItemLength > 1;
  const fileAndFolderSelected = !isOnlyFilesSelected && !isOnlyFolderSelected;
  const protectedFolderSelected = isAnyFileLocked;

  switch (true) {
    case protectedFolderSelected:
      return (
        <>
          <Download onDownload={onDownload} />
          <CopyLink onCopy={onCopy} />
          {isOnlyFilesSelected && <Move onMove={onMove} />}
        </>
      );
    case singlefolderSelcted:
      return (
        <>
          <Rename onRename={onRename} />
          <Download onDownload={onDownload} />
          <CopyLink onCopy={onCopy} />
          {isAdmin && <Delete onDelete={onDelete} />}
        </>
      );
    case singleFileSelected:
      return (
        <>
          <Rename onRename={onRename} />
          <Download onDownload={onDownload} />
          <CopyLink onCopy={onCopy} />
          <Delete onDelete={onDelete} />
          <Move onMove={onMove} />
          {searchTerm && <GoToFolder goToFolder={goToFolder} />}
        </>
      );
    case muilpleFoldersSelected:
      return (
        <>
          <Download onDownload={onDownload} />
          {isAdmin && <Delete onDelete={onDelete} />}
        </>
      );
    case muilpleFilesSelected:
      return (
        <>
          <Download onDownload={onDownload} />
          <Delete onDelete={onDelete} />
          <Move onMove={onMove} />
        </>
      );
    case fileAndFolderSelected:
      return (
        <>
          <Download onDownload={onDownload} />
          <CopyLink onCopy={onCopy} />
        </>
      );

    case isAllSelected && isOnlyFilesSelected:
      return (
        <>
          <Download onDownload={onDownload} />
          <Delete onDelete={onDelete} />
        </>
      );

    case isAllSelected:
      return (
        <>
          <Download onDownload={onDownload} />
          {isAdmin && <Delete onDelete={onDelete} />}
        </>
      );
    default:
      return null;
  }
};

export default Actions;
