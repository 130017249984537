export const ShieldCheck = ({ size = "24" }: { size?: string }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5 3.75H4.5C4.10218 3.75 3.72064 3.90804 3.43934 4.18934C3.15804 4.47064 3 4.85218 3 5.25V10.7531C3 19.1344 10.1062 21.9188 11.5312 22.3875C11.8357 22.4877 12.1643 22.4877 12.4688 22.3875C13.8938 21.9188 21 19.1344 21 10.7531V5.25C21 4.85218 20.842 4.47064 20.5607 4.18934C20.2794 3.90804 19.8978 3.75 19.5 3.75ZM16.6406 10.2938L11.1469 15.5438C11.0048 15.6774 10.8169 15.7512 10.6219 15.75C10.4298 15.7507 10.2449 15.6768 10.1063 15.5438L7.35938 12.9188C7.28319 12.8523 7.22123 12.7711 7.17722 12.6801C7.1332 12.589 7.10805 12.49 7.10328 12.389C7.0985 12.2881 7.11419 12.1871 7.14941 12.0924C7.18463 11.9976 7.23865 11.9109 7.30822 11.8375C7.37779 11.7642 7.46148 11.7056 7.55426 11.6654C7.64703 11.6252 7.74697 11.6042 7.84808 11.6036C7.94919 11.603 8.04937 11.6229 8.14261 11.662C8.23584 11.7011 8.32021 11.7587 8.39062 11.8312L10.6219 13.9594L15.6094 9.20625C15.7552 9.07902 15.9446 9.01309 16.1379 9.02223C16.3312 9.03138 16.5135 9.1149 16.6467 9.25533C16.7798 9.39576 16.8535 9.58222 16.8524 9.77575C16.8513 9.96928 16.7754 10.1549 16.6406 10.2938Z"
        fill="currentColor"
      />
    </svg>
  );
};
