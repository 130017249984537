export const FileArrowDown = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8405 4.64625L10.0223 1.14625C9.97158 1.09983 9.91141 1.06303 9.84519 1.03793C9.77898 1.01284 9.70801 0.999949 9.63636 1H3.09091C2.80158 1 2.5241 1.10536 2.31952 1.29289C2.11493 1.48043 2 1.73478 2 2V13C2 13.2652 2.11493 13.5196 2.31952 13.7071C2.5241 13.8946 2.80158 14 3.09091 14H12.9091C13.1984 14 13.4759 13.8946 13.6805 13.7071C13.8851 13.5196 14 13.2652 14 13V5C14.0001 4.93432 13.986 4.86927 13.9586 4.80858C13.9312 4.74788 13.8911 4.69272 13.8405 4.64625ZM10.1818 2.70688L12.138 4.5H10.1818V2.70688ZM12.9091 13H3.09091V2H9.09091V5C9.09091 5.13261 9.14838 5.25979 9.25067 5.35355C9.35296 5.44732 9.4917 5.5 9.63636 5.5H12.9091V13ZM10.0223 9.14625C10.073 9.19269 10.1132 9.24783 10.1407 9.30853C10.1681 9.36923 10.1822 9.43429 10.1822 9.5C10.1822 9.56571 10.1681 9.63077 10.1407 9.69147C10.1132 9.75217 10.073 9.80731 10.0223 9.85375L8.38591 11.3538C8.33525 11.4002 8.27509 11.4371 8.20888 11.4623C8.14266 11.4874 8.07168 11.5004 8 11.5004C7.92832 11.5004 7.85734 11.4874 7.79112 11.4623C7.72491 11.4371 7.66475 11.4002 7.61409 11.3538L5.97773 9.85375C5.87538 9.75993 5.81788 9.63268 5.81788 9.5C5.81788 9.36732 5.87538 9.24007 5.97773 9.14625C6.08008 9.05243 6.21889 8.99972 6.36364 8.99972C6.50838 8.99972 6.6472 9.05243 6.74955 9.14625L7.45455 9.79313V7C7.45455 6.86739 7.51201 6.74021 7.61431 6.64645C7.7166 6.55268 7.85534 6.5 8 6.5C8.14466 6.5 8.2834 6.55268 8.38569 6.64645C8.48799 6.74021 8.54545 6.86739 8.54545 7V9.79313L9.25045 9.14625C9.30111 9.09976 9.36127 9.06288 9.42749 9.03772C9.4937 9.01256 9.56468 8.99961 9.63636 8.99961C9.70804 8.99961 9.77902 9.01256 9.84524 9.03772C9.91146 9.06288 9.97161 9.09976 10.0223 9.14625Z"
        fill="#706A85"
      />
    </svg>
  );
};
