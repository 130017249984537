import React from "react";

export const NoCustomerAdded = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="201"
      height="134"
      viewBox="0 0 201 134"
      fill="none"
    >
      <g filter="url(#filter0_d_3395_9893)">
        <path
          d="M54.5 46.459H189.5C190.826 46.459 192.098 46.9858 193.036 47.9235C193.973 48.8611 194.5 50.1329 194.5 51.459V76.459C194.5 77.7851 193.973 79.0568 193.036 79.9945C192.098 80.9322 190.826 81.459 189.5 81.459H54.5C53.1739 81.459 51.9021 80.9322 50.9645 79.9945C50.0268 79.0568 49.5 77.7851 49.5 76.459V51.459C49.5 50.1329 50.0268 48.8611 50.9645 47.9235C51.9021 46.9858 53.1739 46.459 54.5 46.459Z"
          fill="white"
        />
      </g>
      <path
        d="M128.5 54.459H102.5C100.843 54.459 99.5 55.8021 99.5 57.459C99.5 59.1158 100.843 60.459 102.5 60.459H128.5C130.157 60.459 131.5 59.1158 131.5 57.459C131.5 55.8021 130.157 54.459 128.5 54.459Z"
        fill="#E7E6EB"
      />
      <path
        d="M146.5 67.459H102.5C100.843 67.459 99.5 68.8021 99.5 70.459C99.5 72.1158 100.843 73.459 102.5 73.459H146.5C148.157 73.459 149.5 72.1158 149.5 70.459C149.5 68.8021 148.157 67.459 146.5 67.459Z"
        fill="#F3F3F5"
      />
      <path
        d="M80.5 74.459C86.0228 74.459 90.5 69.9818 90.5 64.459C90.5 58.9361 86.0228 54.459 80.5 54.459C74.9772 54.459 70.5 58.9361 70.5 64.459C70.5 69.9818 74.9772 74.459 80.5 74.459Z"
        fill="#A09CAD"
      />
      <g clip-path="url(#clip0_3395_9893)">
        <path
          d="M84.8248 68.3127C84.7919 68.3697 84.7446 68.417 84.6875 68.45C84.6305 68.4829 84.5658 68.5002 84.5 68.5002H75.5C75.4342 68.5001 75.3696 68.4827 75.3126 68.4498C75.2557 68.4169 75.2084 68.3695 75.1755 68.3125C75.1426 68.2555 75.1253 68.1909 75.1254 68.1251C75.1254 68.0593 75.1427 67.9947 75.1756 67.9377C75.8895 66.7035 76.9897 65.8185 78.2736 65.3989C77.6385 65.0209 77.1451 64.4448 76.8691 63.7591C76.5931 63.0735 76.5498 62.3162 76.7459 61.6036C76.9419 60.891 77.3665 60.2625 77.9544 59.8145C78.5422 59.3665 79.2609 59.1239 80 59.1239C80.7391 59.1239 81.4577 59.3665 82.0456 59.8145C82.6335 60.2625 83.058 60.891 83.2541 61.6036C83.4501 62.3162 83.4068 63.0735 83.1309 63.7591C82.8549 64.4448 82.3615 65.0209 81.7264 65.3989C83.0103 65.8185 84.1105 66.7035 84.8244 67.9377C84.8573 67.9946 84.8747 68.0593 84.8748 68.1251C84.8749 68.1909 84.8577 68.2556 84.8248 68.3127Z"
          fill="#E7E6EB"
        />
      </g>
      <g filter="url(#filter1_d_3395_9893)">
        <path
          d="M11.5 89.459H146.5C147.826 89.459 149.098 89.9858 150.036 90.9235C150.973 91.8611 151.5 93.1329 151.5 94.459V119.459C151.5 120.785 150.973 122.057 150.036 122.995C149.098 123.932 147.826 124.459 146.5 124.459H11.5C10.1739 124.459 8.90215 123.932 7.96447 122.995C7.02678 122.057 6.5 120.785 6.5 119.459V94.459C6.5 93.1329 7.02678 91.8611 7.96447 90.9235C8.90215 89.9858 10.1739 89.459 11.5 89.459V89.459Z"
          fill="white"
        />
      </g>
      <path
        d="M85.5 97.459H59.5C57.8431 97.459 56.5 98.8021 56.5 100.459C56.5 102.116 57.8431 103.459 59.5 103.459H85.5C87.1569 103.459 88.5 102.116 88.5 100.459C88.5 98.8021 87.1569 97.459 85.5 97.459Z"
        fill="#E7E6EB"
      />
      <path
        d="M103.5 110.459H59.5C57.8431 110.459 56.5 111.802 56.5 113.459C56.5 115.116 57.8431 116.459 59.5 116.459H103.5C105.157 116.459 106.5 115.116 106.5 113.459C106.5 111.802 105.157 110.459 103.5 110.459Z"
        fill="#F3F3F5"
      />
      <path
        d="M37 117C42.5228 117 47 112.523 47 107C47 101.477 42.5228 97 37 97C31.4772 97 27 101.477 27 107C27 112.523 31.4772 117 37 117Z"
        fill="#A09CAD"
      />
      <g clip-path="url(#clip1_3395_9893)">
        <path
          d="M41.8248 111.313C41.7919 111.37 41.7446 111.417 41.6875 111.45C41.6305 111.483 41.5658 111.5 41.5 111.5H32.5C32.4342 111.5 32.3696 111.483 32.3126 111.45C32.2557 111.417 32.2084 111.37 32.1755 111.313C32.1426 111.256 32.1253 111.191 32.1254 111.125C32.1254 111.059 32.1427 110.995 32.1756 110.938C32.8895 109.703 33.9897 108.818 35.2736 108.399C34.6385 108.021 34.1451 107.445 33.8691 106.759C33.5931 106.074 33.5498 105.316 33.7459 104.604C33.9419 103.891 34.3665 103.262 34.9544 102.814C35.5422 102.367 36.2609 102.124 37 102.124C37.7391 102.124 38.4577 102.367 39.0456 102.814C39.6335 103.262 40.058 103.891 40.2541 104.604C40.4501 105.316 40.4068 106.074 40.1309 106.759C39.8549 107.445 39.3615 108.021 38.7264 108.399C40.0103 108.818 41.1105 109.703 41.8244 110.938C41.8573 110.995 41.8747 111.059 41.8748 111.125C41.8749 111.191 41.8577 111.256 41.8248 111.313Z"
          fill="#E7E6EB"
        />
      </g>
      <g filter="url(#filter2_d_3395_9893)">
        <path
          d="M146.5 3.45898H11.5C8.73858 3.45898 6.5 5.69756 6.5 8.45898V33.459C6.5 36.2204 8.73858 38.459 11.5 38.459H146.5C149.261 38.459 151.5 36.2204 151.5 33.459V8.45898C151.5 5.69756 149.261 3.45898 146.5 3.45898Z"
          fill="white"
        />
      </g>
      <path
        d="M83.5 11.459H57.5C55.8431 11.459 54.5 12.8021 54.5 14.459C54.5 16.1158 55.8431 17.459 57.5 17.459H83.5C85.1569 17.459 86.5 16.1158 86.5 14.459C86.5 12.8021 85.1569 11.459 83.5 11.459Z"
        fill="#E7E6EB"
      />
      <path
        d="M101.5 24.459H57.5C55.8431 24.459 54.5 25.8021 54.5 27.459C54.5 29.1158 55.8431 30.459 57.5 30.459H101.5C103.157 30.459 104.5 29.1158 104.5 27.459C104.5 25.8021 103.157 24.459 101.5 24.459Z"
        fill="#F3F3F5"
      />
      <path
        d="M37.5 31.9639C43.0228 31.9639 47.5 27.4867 47.5 21.9639C47.5 16.441 43.0228 11.9639 37.5 11.9639C31.9772 11.9639 27.5 16.441 27.5 21.9639C27.5 27.4867 31.9772 31.9639 37.5 31.9639Z"
        fill="#A09CAD"
      />
      <g clip-path="url(#clip2_3395_9893)">
        <path
          d="M41.8248 26.3124C41.7919 26.3695 41.7446 26.4168 41.6875 26.4497C41.6305 26.4826 41.5658 26.4999 41.5 26.4999H32.5C32.4342 26.4999 32.3696 26.4825 32.3126 26.4495C32.2557 26.4166 32.2084 26.3693 32.1755 26.3123C32.1426 26.2553 32.1253 26.1906 32.1254 26.1248C32.1254 26.059 32.1427 25.9944 32.1756 25.9374C32.8895 24.7032 33.9897 23.8182 35.2736 23.3987C34.6385 23.0206 34.1451 22.4445 33.8691 21.7589C33.5931 21.0733 33.5498 20.316 33.7459 19.6034C33.9419 18.8908 34.3665 18.2622 34.9544 17.8142C35.5422 17.3663 36.2609 17.1237 37 17.1237C37.7391 17.1237 38.4577 17.3663 39.0456 17.8142C39.6335 18.2622 40.058 18.8908 40.2541 19.6034C40.4501 20.316 40.4068 21.0733 40.1309 21.7589C39.8549 22.4445 39.3615 23.0206 38.7264 23.3987C40.0103 23.8182 41.1105 24.7032 41.8244 25.9374C41.8573 25.9944 41.8747 26.059 41.8748 26.1249C41.8749 26.1907 41.8577 26.2554 41.8248 26.3124Z"
          fill="#E7E6EB"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_3395_9893"
          x="43.5"
          y="43.459"
          width="157"
          height="47"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3395_9893"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3395_9893"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_3395_9893"
          x="0.5"
          y="86.459"
          width="157"
          height="47"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3395_9893"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3395_9893"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_3395_9893"
          x="0.5"
          y="0.458984"
          width="157"
          height="47"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3395_9893"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3395_9893"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_3395_9893">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(74 58.0001)"
          />
        </clipPath>
        <clipPath id="clip1_3395_9893">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(31 101)"
          />
        </clipPath>
        <clipPath id="clip2_3395_9893">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(31 15.9999)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
