import classNames from "classnames";
import DashboardContainer from "components/dashboard/DashboardContainer";
import { Avatar } from "components/DesignSystem/AvatarGroup/Avatar";
import { Button } from "components/DesignSystem/Button/Button";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import { Slider } from "components/DesignSystem/Slider/Slider";
import Tab from "components/DesignSystem/Tab/Tab";
import { ArrowLeft } from "components/icons/ArrowLeft";
import { Cross } from "components/icons/Cross";
import { FilledArrow } from "components/icons/FilledArrow";
import {
  MessageBody,
  MessageDate,
  MessageLayout,
} from "components/MessageBubble/MessageBubble";
import { useDispatch, useSelector } from "react-redux";
import { useGetAllSharedMessageQuery } from "store/apis/chat";
import { useGetTaskFromChannelIdQuery } from "store/apis/task";
import {
  closeShareViaWhatsappSlider,
  openMessageInfoSlider,
} from "store/slices/channelInfoSlider";
import { setScrollToMessageId } from "store/slices/messageToScrollTo";
import { RootState } from "store/store";
import {
  MessageUIComponentProps,
  useChannelActionContext,
  useChannelStateContext,
  useChatContext,
} from "stream-chat-react";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";

export const WhatsappForwardedSlider = ({
  block = false,
}: {
  block?: boolean;
}) => {
  const dispatch = useDispatch();
  const { channel } = useChannelStateContext();
  const { showMessageInfo } = useSelector(
    (store: RootState) => store.channelInfoSlider
  );
  const { client } = useChatContext();

  const { jumpToMessage } =
    useChannelActionContext<DefaultStreamChatGenerics>("MessageActionsBox");

  const { data: currentChannelData } = useGetTaskFromChannelIdQuery(
    {
      channelId: channel.id!,
    },
    {
      skip: !channel.id,
    }
  );
  const groupId = currentChannelData?.company_group?.uuid;

  const { data: allSharedMessage } = useGetAllSharedMessageQuery(
    { groupId: groupId!, channelId: channel.id! },
    { skip: !channel.id || !groupId || showMessageInfo }
  );
  const sliderOpen = useSelector(
    (store: RootState) => store.channelInfoSlider.showShareViaWhatsapp
  );

  const closeSlider = () => {
    if (sliderOpen) {
      dispatch(closeShareViaWhatsappSlider());
    }
  };

  const jumpToMsg = (messageId: string) => {
    dispatch(setScrollToMessageId(messageId));
    jumpToMessage(messageId);
  };

  const openMessageInfo = (
    message: MessageUIComponentProps["message"] & {
      forwarded_message?: MessageUIComponentProps["message"];
    }
  ) => {
    dispatch(openMessageInfoSlider(message.id));
  };

  return (
    <Slider
      open={sliderOpen}
      className={classNames("t-absolute !t-p-0 t-shadow-slider !t-h-full", {
        "t-w-full": block,
        "t-w-[360px]": !block,
      })}
      position="right"
      width={360}
    >
      <Tab.Root defaultValue="MEDIA" className="t-h-full">
        <DashboardContainer className="t-h-full">
          <DashboardContainer.Header>
            <div className="t-py-3 t-pr-4 t-pl-5 t-flex t-justify-between t-items-center t-border-b t-border-0 t-border-solid t-border-i-neutral-10">
              <div className="t-flex t-gap-2 t-items-center">
                <Button
                  customType="ghost_icon"
                  size="small"
                  onClick={closeSlider}
                >
                  <span className="t-text-text-30">
                    <ArrowLeft size="16" />
                  </span>
                </Button>
                <span className="t-text-subtitle">Share via WhatsApp</span>
              </div>
              <Button
                customType="ghost_icon"
                size="small"
                onClick={closeSlider}
              >
                <Cross />
              </Button>
            </div>
          </DashboardContainer.Header>
          <DashboardContainer.Content
            className="t-p-1 t-pt-4 t-bg-surface-lighter-grey"
            id="media-scroll"
          >
            <div className="t-flex t-flex-col t-gap-4 t-p-5">
              {allSharedMessage?.forwarded_messages_count &&
                allSharedMessage?.forwarded_messages_count > 0 &&
                allSharedMessage?.messages_forwarded_on_whatsapp.map(
                  (message) => {
                    const sentByTheUser = message?.user?.id === client.userID;
                    const props = {
                      message: message as MessageUIComponentProps["message"] & {
                        forwarded_message?: MessageUIComponentProps["message"];
                      },
                      otherData: { sentByTheUser },
                      hideOptions: true,
                    };

                    return (
                      <div
                        key={message?.id}
                        role="button"
                        className="t-flex t-gap-1 t-group"
                        onClick={() => jumpToMsg(message?.id!)}
                      >
                        <Avatar
                          src={message?.user?.image}
                          alt={message?.user?.name || ""}
                          size="regular"
                        />
                        <MessageLayout {...props}>
                          <MessageBody
                            {...props}
                            Date={
                              <MessageDate
                                sentByTheUser={sentByTheUser}
                                createdAt={message?.created_at}
                              />
                            }
                          >
                            <Dropdown.Root modal={false}>
                              <Dropdown.Trigger
                                asChild
                                onClick={(e) => e.stopPropagation()}
                              >
                                <div className="t-absolute t-right-1 t-top-0.5 t-translate-x-1 t-transform t-opacity-0 t-transition-transform group-hover:t-translate-x-0 group-hover:t-opacity-100 data-state-open:t-translate-x-0 data-state-open:t-opacity-100">
                                  <button className="all:unset t-text-surface-grey group-data-[sent=true]:t-text-purple-20">
                                    <FilledArrow />
                                  </button>
                                </div>
                              </Dropdown.Trigger>
                              <Dropdown.Portal>
                                <Dropdown.Content
                                  align="end"
                                  className="t-w-40"
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  <Dropdown.Item
                                    onSelect={() => openMessageInfo(message!)}
                                  >
                                    <span className="t-flex t-items-center t-gap-2 t-text-body">
                                      Message info
                                    </span>
                                  </Dropdown.Item>
                                </Dropdown.Content>
                              </Dropdown.Portal>
                            </Dropdown.Root>
                          </MessageBody>
                        </MessageLayout>
                      </div>
                    );
                  }
                )}
            </div>
          </DashboardContainer.Content>
        </DashboardContainer>
      </Tab.Root>
    </Slider>
  );
};
