import classNames from "classnames";
import Loader from "components/design/loader";
import { Button } from "components/DesignSystem/Button/Button";
import { Cross } from "components/icons/Cross";
import { MessageBubbleUI } from "components/MessageBubble/MessageBubble";
import { usePaginatedMessages } from "hooks/usePaginatedMessages";
import { FC } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { setChannelPinnedItemsActive } from "store/slices/channelPinnedItems";
import { RootState } from "store/store";
import {
  useChannelActionContext,
  useChannelStateContext,
  useChatContext,
} from "stream-chat-react";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";

const SliderBody: FC = () => {
  const { client } = useChatContext();
  const { channel } = useChannelStateContext();

  const {
    messages: pinnedMessage,
    loadNextPage,
    next,
    loading,
  } = usePaginatedMessages(
    client,
    {
      id: channel.id,
    },
    {},
    { limit: 5 },
    {
      pinned: { $eq: true },
    }
  );

  const { jumpToMessage } =
    useChannelActionContext<DefaultStreamChatGenerics>("MessageActionsBox");

  const onJump = (id: string) => {
    jumpToMessage(id);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <InfiniteScroll
      dataLength={pinnedMessage.length}
      next={loadNextPage}
      hasMore={Boolean(next)}
      scrollThreshold={0.6}
      scrollableTarget="pinned-message"
      loader={
        <div className="t-w-full t-pb-2 t-text-center t-text-subtitle-sm">
          Loading...
        </div>
      }
    >
      <div className="t-flex t-flex-col t-px-5 t-pb-5 t-pt-2">
        {pinnedMessage.map(({ message }) => (
          <button
            className="all:unset"
            onClick={() => onJump(message.id)}
            key={message.id}
          >
            <MessageBubbleUI
              hideOptions
              message={message}
              otherData={{ sentByTheUser: message.user?.id === client.userID }}
              jumpToMessage={onJump}
            />
          </button>
        ))}
      </div>
    </InfiniteScroll>
  );
};

export const ChannelPinnedItem = ({ block = false }: { block?: boolean }) => {
  const isActive = useSelector(
    (state: RootState) => state.channelPinnedItems.isActive
  );

  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(
      setChannelPinnedItemsActive({
        isActive: false,
      })
    );
  };

  return (
    <div
      className={classNames(
        "t-transition-all t-grow t-shrink-0 t-bg-surface t-overflow-x-hidden t-z-10",
        {
          "t-border-0 t-border-l t-border-solid t-border-neutral-10": isActive,
          "!t-w-0": !isActive,
          "t-w-full": block && isActive,
          "!t-w-[360px]": !block && isActive,
        }
      )}
      id="pinned-message"
    >
      <div className="t-py-3 t-pr-4 t-pl-5 t-flex t-justify-between t-items-center">
        <span className="t-text-subtitle">Pinned Messages</span>
        <Button customType="ghost_icon" size="small" onClick={onClose}>
          <Cross />
        </Button>
      </div>
      {isActive && <SliderBody />}
    </div>
  );
};
