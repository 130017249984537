import classNames from "classnames";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import ToolTip from "components/design/toolTip";
import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import Table from "components/DesignSystem/Table/V2/Table";
import { Tag } from "components/DesignSystem/Tag/Tag";
import { Cross } from "components/icons/Cross";
import { NoVendors } from "components/icons/NoVendors";
import { DocumentPreviewModal } from "components/PreviewModal";
import { FileRequestOrUpload } from "components/WformFileRequestOrUpload";
import { WSeriesFormUpload } from "components/WSeriesFormUpload";
import { AWAITING } from "constants/merchantFormStatuses";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useDocPreview } from "hooks/useDocPreview";
import { useModal } from "hooks/useModal";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { EmptyScreen } from "pages/Books/EmptyScreen";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import CloseIcon from "static/images/Close.svg";
import GreenTick from "static/images/GreenTick.svg";
import HourGlassTimer from "static/images/PinkHourglass.svg";
import {
  useEditVendorsMutation,
  useUploadForm1099FileMutation,
  vendorsApi,
} from "store/apis/vendors";
import {
  closeAddOrEditModal,
  openAddOrEditModal,
  setMerchantToMerge,
} from "store/slices/vendors";
import {
  CPA_REVIEWED,
  NOT_REQUIRED,
  SYSTEM_REVIEWED,
} from "types/Models/vendors";
import { FileIcon } from "utils/fileTypeIcon";
import { openLink } from "utils/openLink";
import { AddOrEditMerchant } from "./addOrEditMerchant";
import { MarkMerchantAsVendor } from "./MarkMerchantAsVendorModal";
import { MerchantTableDropdown } from "./MerchantTableDropdown";
import { MergeMerchants } from "./MergeMerchants";
import VendorColumn from "./VendorColumn";
import { VendorsFilter } from "./VendorsFilter";
import { FileInput } from "components/FileInput/FileInput";
import { VendorTransactions } from "./VendorTransactions";
import { Info } from "components/icons/Info";
import { VendorAssign } from "./AssignVendors";
import { Badge } from "components/design/badge";

export const MerchantTable = ({
  merchants,
  groupId,
  showWform,
  totalPages,
  paginationData,
  noMerchant,
  sorting,
  setSorting,
  transactionsWithoutVendorsCount,
}) => {
  const { alertToast, successToast } = useToast();
  const { update } = useUpdateQuery();
  const query = useQuery();
  const page = Number(query.get("page")) || 1;
  const selectedSeason = query.get("season") || "2023";
  const assignVendors = useModal();
  const [showAssignVendor, setShowAssignVendor] = useState(
    transactionsWithoutVendorsCount > 0
  );

  let entityId = useCurrentEntityId();

  const [editVendors] = useEditVendorsMutation();

  const showAddOrEditModal = useSelector(
    (state) => state.vendors.showAddOrEditModal
  );

  useEffect(() => {
    setShowAssignVendor(transactionsWithoutVendorsCount > 0);
  }, [transactionsWithoutVendorsCount]);

  const {
    open: openFormUploadModal,
    close: closeFormUploadModal,
    isOpen: showFormUploadModal,
  } = useModal();

  const {
    open: openMarkAsVendorModal,
    close: closeMarkAsVendorModal,
    isOpen: showMarkAsVendorModal,
  } = useModal();
  const {
    open: openMergeMerchantsModal,
    close: closeMergeMerchantsModal,
    isOpen: showMergeMerchantsModal,
  } = useModal();
  const openPreview = useDocPreview();
  const { isCustomer } = useRoleBasedView();
  const [upload1099File, { isLoading: isUploading, originalArgs }] =
    useUploadForm1099FileMutation();
  const dispatch = useDispatch();
  const [editMerchantId, setEditMerchantId] = useState("");
  const [expandedRow, setExpandedRow] = useState(null);

  const handleRowClick = (rowId) => {
    setExpandedRow((prevRowId) => (prevRowId === rowId ? null : rowId));
  };

  const updateMerchantType = async (merchant_type, uuid) => {
    try {
      await editVendors({
        groupId,
        uuid,
        payload: { merchant_type, season: selectedSeason },
      }).unwrap();
      successToast({ message: "Vendor has been updated" });
    } catch (e) {
      alertToast({ message: e?.data?.error?.message });
    }
  };

  const onDrop = async ({ newFiles, form1099DoucmentType, editMerchantId }) => {
    try {
      const file = newFiles[0];
      const fileToSign = file?.uuid ? { fileId: file.uuid } : { file: file };
      await upload1099File({
        groupId: groupId,
        merchantId: editMerchantId,
        entityId,
        form1099Document: form1099DoucmentType,
        ...fileToSign,
      }).unwrap();
      successToast({ message: "File uploaded successfully" });
    } catch (error) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  const openMarkAsVendor = (merchant) => {
    setEditMerchantId(merchant.uuid);
    openMarkAsVendorModal();
  };

  const editMerchant = (merchant) => {
    setEditMerchantId(merchant.uuid);
    dispatch(openAddOrEditModal());
  };

  const openWFormUpload = (merchantId) => {
    openFormUploadModal();
    setEditMerchantId(merchantId);
  };

  const openFilePreview = (fileId) => {
    if (fileId) {
      openPreview(fileId);
    }
  };

  const goToFirstPage = () => {
    update({ query: "page", value: 1 });
  };

  const goToPrevPage = () => {
    const localCurrentPage = page < totalPages ? page : totalPages;
    update({ query: "page", value: localCurrentPage - 1 });
  };

  const goToNextPage = () => {
    if (page < totalPages) {
      update({ query: "page", value: page + 1 });
    }
  };

  const goToLastPage = () => {
    update({ query: "page", value: totalPages });
  };

  const getAllMerchants = () => {
    dispatch(vendorsApi.util.invalidateTags(["VENDORS"]));
  };

  const closeAddOrEditMerchantModal = () => {
    setEditMerchantId();
    dispatch(closeAddOrEditModal());
  };

  const closeWseriesFormUpload = () => {
    closeFormUploadModal();
    setEditMerchantId();
  };

  const mergeMerchants = () => {
    const merchantsToMerge = table
      .getSelectedRowModel()
      .flatRows.map((merchant) => {
        return merchant.original.uuid;
      });
    dispatch(setMerchantToMerge(merchantsToMerge));
    openMergeMerchantsModal();
  };

  const columnHelper = createColumnHelper();

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "select",
        size: 3,
        enableSorting: false,
        header: ({ table }) => (
          <Checkbox
            checked={table.getIsAllRowsSelected()}
            indeterminate={table.getIsSomeRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            checked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            indeterminate={row.getIsSomeSelected()}
            onChange={row.getToggleSelectedHandler()}
            key={row.id}
            onClick={(e) => e.stopPropagation()}
          />
        ),
      }),
      columnHelper.accessor("logo", {
        enableSorting: false,
        cell: (info) => {
          const merchantName = info.row.original.name;
          const merchantInitialLetter = merchantName[0].toUpperCase();
          const canRedirectToTransaction =
            info.row.original.source !== "MANUAL";
          let transactionRedirectUrl = `/books/transactions?entity=${entityId}&vendors%5B0%5D=${merchantName}`;
          if (!isCustomer) {
            transactionRedirectUrl += `&company=${groupId}`;
          }

          return (
            <div className="t-flex t-gap-2 t-items-center">
              <div className="t-min-h-8 t-min-w-8 t-flex t-items-center t-justify-center t-rounded-full t-bg-purple-40">
                {info.getValue() ? (
                  <img
                    src={info.getValue()}
                    className="t-h-8 t-w-8 t-rounded-full"
                    alt="logo"
                  />
                ) : (
                  <div className="t-text-subtitle-sm t-text-surface">
                    {merchantInitialLetter}
                  </div>
                )}
              </div>
              <div>
                <div
                  className={classNames(
                    "t-text-subtext t-text-text-60 hover:t-underline hover:t-text-purple-50",
                    {
                      "t-group t-cursor-pointer": canRedirectToTransaction,
                    }
                  )}
                  onClick={(e) =>
                    canRedirectToTransaction && openLink(transactionRedirectUrl)
                  }
                >
                  {merchantName}
                </div>
                <div>
                  <VendorColumn.Email
                    info={info}
                    editMerchantId={editMerchantId}
                    setEditMerchantId={setEditMerchantId}
                  />
                </div>
              </div>
            </div>
          );
        },
        header: "VENDOR",
        size: 24,
      }),
      columnHelper.accessor("transaction_amount", {
        cell: (info) => {
          const showTransactions = info.row.original.name === expandedRow;
          return (
            <div
              className="t-w-full t-flex t-justify-end t-text-subtext t-text-text-60 t-pr-5 t-group"
              onClick={() => handleRowClick(info.row.original.name)}
            >
              <button
                className={classNames("all:unset t-text-body t-ml-auto", {
                  "hover:!t-underline group-hover:t-text-purple":
                    !showTransactions,
                  "!t-underline t-text-purple": showTransactions,
                })}
              >
                <AmountSuperScript amount={info.getValue() * -1} />
              </button>
            </div>
          );
        },
        header: (
          <div className="t-w-full t-flex t-justify-end">AMOUNT PAID</div>
        ),
        size: 18,
      }),
      columnHelper.accessor("w_form_document", {
        enableSorting: false,
        cell: (info) => {
          const rowData = info.row.original;

          if (rowData.is_w_form_required === SYSTEM_REVIEWED && isCustomer) {
            return <div className="t-w-4/5 t-text-center">-</div>;
          }

          if (rowData.is_w_form_required === SYSTEM_REVIEWED && !isCustomer) {
            return (
              <div className="t-flex t-items-center t-gap-2.5 t-justify-center t-w-4/5">
                <ToolTip text="W Form is required">
                  <span>
                    <Button
                      customType="icon"
                      size="small"
                      onClick={() =>
                        updateMerchantType(CPA_REVIEWED, rowData.uuid)
                      }
                    >
                      <img
                        className="t-h-4 t-w-4"
                        src={GreenTick}
                        alt="Green Tick"
                      />
                    </Button>
                  </span>
                </ToolTip>
                <ToolTip text="W Form not required">
                  <span>
                    <Button
                      customType="icon"
                      size="small"
                      onClick={() =>
                        updateMerchantType(NOT_REQUIRED, rowData.uuid)
                      }
                    >
                      <img
                        className="t-h-4 t-w-4"
                        src={CloseIcon}
                        alt="Close Icon"
                      />
                    </Button>
                  </span>
                </ToolTip>
              </div>
            );
          }

          if (rowData.is_w_form_required === NOT_REQUIRED) {
            return (
              <div className="t-w-4/5 t-rounded t-border t-border-solid t-border-neutral-0 t-bg-surface-lighter-grey t-text-center t-py-1 t-text-body t-text-text-30">
                Not required
              </div>
            );
          }

          if (!rowData.w_form_document) {
            return (
              <div className="t-w-4/5">
                <FileRequestOrUpload
                  merchant={rowData}
                  groupId={groupId}
                  updateMerchant={getAllMerchants}
                  editable
                  wFormStatus={rowData.form_status}
                  season={selectedSeason}
                  isSuccessPage={false}
                />
              </div>
            );
          }

          if (rowData.form_status === AWAITING) {
            return (
              <div className="t-w-4/5">
                <ToolTip text="Awaiting response">
                  <span className="t-w-full">
                    <Button disabled size="small" block>
                      Requested
                      <img
                        height="16px"
                        src={HourGlassTimer}
                        alt="HourGlassTimer"
                        className="t-ml-1"
                      />
                    </Button>
                  </span>
                </ToolTip>
              </div>
            );
          }

          if (rowData.w_form_document) {
            return (
              <div className="t-flex t-items-center t-gap-2">
                <ToolTip text="Click to preview">
                  <div className="t-w-4/5">
                    <Button
                      onClick={() => openWFormUpload(rowData.uuid)}
                      size="small"
                      block
                    >
                      <div className="t-flex t-items-center t-gap-1 t-text-text-30">
                        <FileIcon
                          fileType={rowData?.w_form_document?.file_type}
                          width="16px"
                          height="16px"
                        />
                        <div className="t-text-subtext">
                          {rowData?.w_form_choice}
                        </div>
                      </div>
                    </Button>
                  </div>
                </ToolTip>
              </div>
            );
          }
        },
        header: "W FORM",
        id: "wFormDocument",
        size: 28,
      }),
      columnHelper.display({
        id: "form_1099_document?.recipients_document",
        header: "PAYER 1099",
        enableSorting: false,
        size: 10,
        cell: (info) => {
          const rowData = info.row.original;
          const { form_1099_document, is_w_form_required } = info.row.original;
          const isWFormNotRequired = is_w_form_required === NOT_REQUIRED;
          const isWFormRequired = is_w_form_required === CPA_REVIEWED;
          const isSystemReviewed = is_w_form_required === SYSTEM_REVIEWED;

          if (isSystemReviewed && isCustomer) {
            return <div className="t-text-center">-</div>;
          }

          if (isWFormNotRequired) {
            return (
              <div className="t-text-center">
                <ToolTip text="Form not required">
                  <div>-</div>
                </ToolTip>
              </div>
            );
          }

          if (
            isWFormRequired &&
            !form_1099_document?.payers_document &&
            isCustomer
          ) {
            return (
              <div className="t-text-center">
                <ToolTip text="Form isn’t uploaded yet">
                  <div>-</div>
                </ToolTip>
              </div>
            );
          }

          return (
            <div className="t-flex t-justify-center">
              <ToolTip text="Payer 1099 Form">
                <span>
                  {!form_1099_document?.payers_document ? (
                    <FileInput
                      onDrop={(files) =>
                        onDrop({
                          newFiles: files,
                          editMerchantId: rowData.uuid,
                          form1099DoucmentType: "PAYERS_DOCUMENT",
                        })
                      }
                      isUploading={
                        isUploading &&
                        originalArgs?.merchantId === rowData.uuid &&
                        originalArgs?.form1099Document === "PAYERS_DOCUMENT"
                      }
                      variant="icon"
                      withInkleDocs
                    />
                  ) : (
                    <FileInput
                      file={form_1099_document?.payers_document}
                      variant="icon"
                      onFileClick={({ uuid: fileId }) =>
                        openFilePreview(fileId)
                      }
                    />
                  )}
                </span>
              </ToolTip>
            </div>
          );
        },
      }),

      columnHelper.display({
        id: "form_1099_document?.payers_document",
        header: "Recipient 1099",
        enableSorting: false,
        size: 10,
        cell: (info) => {
          const rowData = info.row.original;
          const { form_1099_document, is_w_form_required } = info.row.original;
          const isWFormNotRequired = is_w_form_required === NOT_REQUIRED;
          const isWFormRequired = is_w_form_required === CPA_REVIEWED;
          const isSystemReviewed = is_w_form_required === SYSTEM_REVIEWED;

          if (isSystemReviewed && isCustomer) {
            return <div className="t-text-center">-</div>;
          }

          if (isWFormNotRequired) {
            return (
              <div className="t-text-center">
                <ToolTip text="Form not required">
                  <div>-</div>
                </ToolTip>
              </div>
            );
          }

          if (
            isWFormRequired &&
            !form_1099_document?.recipients_document &&
            isCustomer
          ) {
            return (
              <div className="t-text-center">
                <ToolTip text="Form isn’t uploaded yet">
                  <div>-</div>
                </ToolTip>
              </div>
            );
          }

          return (
            <div className="t-flex t-justify-center">
              <ToolTip text="Recipient 1099 Form">
                <span>
                  {!form_1099_document?.recipients_document ? (
                    <FileInput
                      onDrop={(files) =>
                        onDrop({
                          newFiles: files,
                          editMerchantId: rowData.uuid,
                          form1099DoucmentType: "RECIPIENTS_DOCUMENT",
                        })
                      }
                      isUploading={
                        isUploading &&
                        originalArgs?.merchantId === rowData.uuid &&
                        originalArgs?.form1099Document === "RECIPIENTS_DOCUMENT"
                      }
                      variant="icon"
                      withInkleDocs
                    />
                  ) : (
                    <FileInput
                      file={form_1099_document?.recipients_document}
                      variant="icon"
                      onFileClick={({ uuid: fileId }) =>
                        openFilePreview(fileId)
                      }
                    />
                  )}
                </span>
              </ToolTip>
            </div>
          );
        },
      }),

      columnHelper.display({
        header: "",
        id: "actions",
        enableSorting: false,
        cell: (info) => {
          const isManualMerchant = info.row.original.source === "MANUAL";
          const isVendor =
            info.row.original.is_w_form_required !== "NOT_REQUIRED";
          const showOption = showWform
            ? info.row.original?.is_w_form_required === CPA_REVIEWED
            : true;

          if (!showOption) {
            return;
          }

          return (
            <div className="t-flex t-justify-end t-w-full t-bg-surface">
              <MerchantTableDropdown
                editMerchant={() => editMerchant(info.row.original)}
                openMarkAsVendor={() => openMarkAsVendor(info.row.original)}
                merchantId={info.row.original.uuid}
                groupId={groupId}
                isManualMerchant={isManualMerchant}
                isVendor={isVendor}
                showTransactions={() => setExpandedRow(info.row.original.name)}
              />
            </div>
          );
        },
        size: showWform ? 10 : 25,
      }),
    ],
    [entityId, groupId, isCustomer, isUploading, selectedSeason, showWform]
  );

  const table = useReactTable({
    data: merchants,
    columns,
    getCoreRowModel: getCoreRowModel(),
    manualSorting: true,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    enableMultiSort: false,
    enableSortingRemoval: false,
    defaultColumn: {
      minSize: 1,
    },
  });

  return (
    <>
      <div className="t-flex">
        {table?.getSelectedRowModel?.()?.flatRows.length === 0 ? (
          <VendorsFilter />
        ) : (
          <>
            <div className="t-flex t-gap-3 t-items-center t-pt-2">
              <div className="t-flex t-text-body-sm t-text-text-30 t-items-center t-gap-2">
                <Button
                  customType="ghost_icon"
                  size="small"
                  onClick={() => {
                    table.resetRowSelection();
                  }}
                >
                  <Cross color="currentColor" />
                </Button>
                {table.getSelectedRowModel().flatRows.length} &nbsp; selected
              </div>
              {table?.getSelectedRowModel?.()?.flatRows.length > 1 && (
                <div className="t-flex t-gap-2">
                  <Button size="small" onClick={mergeMerchants}>
                    Merge
                  </Button>
                </div>
              )}
            </div>
          </>
        )}

        <div className="t-ml-auto">
          <Pagination
            {...paginationData}
            goToFirstPage={goToFirstPage}
            goToPrevPage={goToPrevPage}
            goToNextPage={goToNextPage}
            goToLastPage={goToLastPage}
          />
        </div>
      </div>
      {showAssignVendor && (
        <div className="t-p-3 t-rounded-lg t-border t-border-solid t-border-purple-10 t-bg-purple-0 t-flex t-items-center t-justify-between">
          <div className="t-flex t-gap-2 t-items-baseline">
            <span className="t-text-purple">
              <Info stroke="1.4" />
            </span>
            <div>
              <div className="t-text-text-100 t-text-subtitle-sm">
                Vendors not assigned to {transactionsWithoutVendorsCount}{" "}
                transactions{" "}
              </div>
              <div className="t-text-body-sm t-text-text-60">
                Assign vendors to accurately calculate amount paid
              </div>
            </div>
          </div>
          <div className="t-flex t-gap-3 t-items-center">
            <Button customType="link" onClick={() => assignVendors.open()}>
              Assign Vendors
            </Button>
            <Button
              customType="transparent"
              onClick={() => setShowAssignVendor(false)}
            >
              <Cross />
            </Button>
          </div>
        </div>
      )}

      {noMerchant ? (
        <>
          <div className="t-pt-6">
            <EmptyScreen text="No vendors found">
              <NoVendors />
            </EmptyScreen>
          </div>
          <AddOrEditMerchant
            show={showAddOrEditModal}
            groupId={groupId}
            closeModal={closeAddOrEditMerchantModal}
          />
        </>
      ) : (
        <>
          <Table.Container className={classNames("t-overflow-auto")}>
            <Table.Content className="t-w-full">
              <Table.Head>
                {table.getHeaderGroups().map((headerGroup) => (
                  <Table.HeadRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <Table.HeadCell
                        className={classNames(
                          "t-text-subtext-sm t-uppercase t-px-2 t-py-4 t-group t-border-0 t-border-solid t-border-neutral-30 t-border-b",
                          { "!t-border-none": header.id === "select" }
                        )}
                        onClick={header.column.getToggleSortingHandler()}
                        key={header.id}
                        style={{ width: `${header.getSize()}%` }}
                        {...(header.column.getCanSort()
                          ? { role: "button" }
                          : {})}
                      >
                        <span className="t-flex t-gap-1 t-items-center">
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                          {header.column.getCanSort() && (
                            <Table.HeadCellSort
                              nextSortOrder={header.column.getNextSortingOrder()}
                              isSorted={header.column.getIsSorted()}
                            />
                          )}
                        </span>
                      </Table.HeadCell>
                    ))}
                  </Table.HeadRow>
                ))}
              </Table.Head>
              <Table.Body>
                {table.getRowModel().rows.map((row) => (
                  <React.Fragment key={row.id}>
                    <Table.Row>
                      {row.getVisibleCells().map((cell) => (
                        <td
                          key={cell.id}
                          style={{ width: `${cell.column.getSize()}%` }}
                          className="t-p-2"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </Table.Row>
                    {expandedRow === row.original.name && (
                      <Table.Row className="!t-border-b-0">
                        <Table.Cell
                          colSpan={row.getVisibleCells().length}
                          className="t-pl-[3%] !t-pb-0"
                        >
                          <VendorTransactions
                            vendorName={expandedRow}
                            season={selectedSeason}
                          />
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </React.Fragment>
                ))}
              </Table.Body>
            </Table.Content>
          </Table.Container>
          <div className="t-mt-7 t-flex t-justify-end">
            <Pagination
              {...paginationData}
              goToFirstPage={goToFirstPage}
              goToPrevPage={goToPrevPage}
              goToNextPage={goToNextPage}
              goToLastPage={goToLastPage}
            />
          </div>
        </>
      )}
      <DocumentPreviewModal />
      <WSeriesFormUpload
        show={showFormUploadModal}
        closeModal={closeWseriesFormUpload}
        groupId={groupId}
        merchant={merchants.find(({ uuid }) => uuid === editMerchantId)}
        updateMerchant={getAllMerchants}
        editable
        openModal={openFormUploadModal}
        season={selectedSeason}
      />

      <MarkMerchantAsVendor
        groupId={groupId}
        show={showMarkAsVendorModal}
        closeModal={closeMarkAsVendorModal}
        merchantId={editMerchantId}
        season={selectedSeason}
      />
      <AddOrEditMerchant
        show={showAddOrEditModal}
        groupId={groupId}
        editMerchantData={merchants.find(({ uuid }) => uuid === editMerchantId)}
        closeModal={closeAddOrEditMerchantModal}
      />
      <MergeMerchants
        show={showMergeMerchantsModal}
        closeModal={closeMergeMerchantsModal}
        groupId={groupId}
        resetSelectedMerchant={() => table.resetRowSelection()}
        merchants={merchants}
      />
      {assignVendors.isOpen && (
        <VendorAssign {...assignVendors} season={selectedSeason} />
      )}
    </>
  );
};
