import classNames from "classnames";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import ConditionalToolTip from "components/design/conditionalToolTip";
import { Divider } from "components/design/Divider";
import { TableUI } from "components/design/TableUI";
import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { Chip } from "components/DesignSystem/Chips/Chips";
import {
  Filter,
  MultiSelectFilter,
} from "components/DesignSystem/Filter/Filter";
import { Loader } from "components/DesignSystem/Loader/Loader";
import Modal from "components/DesignSystem/Modal/Modal";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import { Search } from "components/DesignSystem/Search/Search";
import { Label } from "components/DesignSystem/TextInput/TextInput";
import { DateFilter } from "components/Filters/DateFilter";
import {
  InvoiceAmountColumn,
  InvoiceDateColumn,
  InvoiceNumberColumn,
  InvoiceTitleColumn,
  SentToColumn,
} from "components/InvoiceList/InvoiceList";
import {
  Delete,
  Description,
  Vendor,
} from "components/JournalEntry/AddJournalEntryTable";
import { PriceInput } from "components/PriceInput/PriceInput";
import { DD_MMM_YYYY, YYYY_MM_DD } from "constants/date";
import dayjs from "dayjs";
import { Form, Formik, useFormikContext } from "formik";
import { linkInkleSchema } from "formValidations/linkInvoiceSchema";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useFilters } from "hooks/useFilter";
import { usePaginatedQuery } from "hooks/usePaginatedQuery";
import { useToast } from "hooks/useToast";
import randomBytes from "randombytes";
import { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  CellContext,
  createColumnHelper,
  getCoreRowModel,
  Row,
  RowSelectionState,
  useReactTable,
} from "react-table-8.10.7";
import EmptyInvoices from "static/images/EmptyInvoices.svg";
import {
  GroupInvoiceCustomer,
  Invoice,
  useGetAllInvoicesQuery,
  useLazyGetAllInvoiceCustomersListQuery,
} from "store/apis/invoices";
import {
  useGetMappedInvoiceQuery,
  useMapInkleTransactionToInvoiceMutation,
} from "store/apis/transactions";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";
import { currency } from "utils/Currency";
import { debounce } from "utils/debouncing";
import { formatDate } from "utils/formatDate";
import { getDateRange } from "utils/getDateRange";
import { roundToFixedDecimal } from "utils/roundtoFixedDecimal";
import { Transaction } from "./AddTransaction/AddTransactionManuallyModal";
import { CategorySelector } from "components/CategorySelector/CategorySelector";

type LinkedInvoicesFormikValues = {
  transactions: [];
  max_amount?: string;
  min_amount?: string;
  search?: string;
  transaction_amount?: number;
  remaining_amount?: number;
  resolve_difference?: boolean;
  linked_transactions?: Transaction[];
  linked_invoices?: Invoice[];
  is_edit_modal?: boolean;
  show_all_filters?: boolean;
};

const AmountColumn = (info: CellContext<Transaction, number>) => {
  const id = info.row.id;

  return (
    <PriceInput
      aria-label={`amount input`}
      name={`transactions[${id}].amount`}
      hideError
      placeholder="Enter amount"
    />
  );
};

const CustomerFilter = ({
  updateFilter,
  filterValues,
}: {
  updateFilter: <S extends "CUSTOMERS">(
    name: S,
    newValue: {
      CUSTOMERS: string[];
    }[S]
  ) => void;
  filterValues: {
    CUSTOMERS: string[];
  };
}) => {
  const group = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const [customerSearchValue, setCustomerSearchValue] = useState("");

  const {
    data: invoiceCustomers,
    loadNext,
    isLoading,
    isSuccess,
  } = usePaginatedQuery<{
    client_customers?: GroupInvoiceCustomer[];
  }>(useLazyGetAllInvoiceCustomersListQuery, "client_customers", {
    groupId: group.uuid,
    entityId: entityId,
    searchTerm: customerSearchValue,
  });

  const updateFilterCallback =
    (name: Parameters<typeof updateFilter>[0]) =>
    (newValues: Parameters<typeof updateFilter>[1]) => {
      updateFilter(name, newValues);
    };

  const onCustomerSearch = debounce((value) => setCustomerSearchValue(value));

  if (isLoading && !isSuccess) {
    return (
      <div className="t-w-full t-h-full t-flex t-justify-center t-align-middle">
        <Loader />
      </div>
    );
  }

  if (invoiceCustomers?.client_customers) {
    return (
      <MultiSelectFilter
        onSearchValueChange={onCustomerSearch}
        hasMore={true}
        totalCount={invoiceCustomers?.client_customers.length}
        loadNext={loadNext}
        onChange={updateFilterCallback("CUSTOMERS")}
        options={invoiceCustomers?.client_customers.map(({ name, uuid }) => ({
          value: uuid,
          label: name,
        }))}
        selected={filterValues.CUSTOMERS}
      />
    );
  }

  return null;
};

export const InvoiceFilter = ({
  currentPage,
  setCurrentPage,
  filterValues,
  updateFilter,
  totalPages,
  paginationData,
}: {
  currentPage: number;
  setCurrentPage: (v: number) => void;
  filterValues: {
    START_DATE: string;
    END_DATE: string;
    SELECT_PERIOD: string;
    CUSTOMERS: string[];
  };
  updateFilter: <
    S extends "START_DATE" | "END_DATE" | "SELECT_PERIOD" | "CUSTOMERS"
  >(
    name: S,
    newValue: {
      START_DATE: string;
      END_DATE: string;
      SELECT_PERIOD: string;
      CUSTOMERS: string[];
    }[S]
  ) => void;
  paginationData: {
    totalPage: number;
    currentPage: number;
    itemsPerPage: number;
    totalItemCount: number;
  };
  totalPages: number;
}) => {
  const {
    values: { max_amount, min_amount, show_all_filters, is_edit_modal },
    setFieldValue,
  } = useFormikContext<LinkedInvoicesFormikValues>();

  const goToFirstPage = () => {
    setCurrentPage(1);
  };

  const goToPrevPage = () => {
    const localCurrentPage =
      currentPage < totalPages! ? currentPage : totalPages!;
    setCurrentPage(localCurrentPage - 1);
  };

  const goToNextPage = () => {
    if (currentPage < totalPages!) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToLastPage = () => {
    setCurrentPage(totalPages);
  };

  return (
    <div className="t-flex t-justify-between">
      <Filter.Root
        defaultValue="DATE"
        capsule={
          <>
            {filterValues.START_DATE && (
              <Chip
                onClose={() => {}}
                isRemovable={show_all_filters ? false : true}
                isActive={show_all_filters}
                filterType="DATE"
              >
                From: {formatDate(filterValues.START_DATE)} To:{" "}
                {formatDate(filterValues.END_DATE)}
              </Chip>
            )}
            {filterValues.CUSTOMERS.length > 0 && (
              <Chip
                onClose={() => {
                  updateFilter("CUSTOMERS", []);
                }}
                isActive={show_all_filters}
                filterType="CUSTOMERS"
              >
                Customers ({filterValues.CUSTOMERS.length})
              </Chip>
            )}
            {max_amount && (
              <Chip
                onClose={() => {
                  setFieldValue("min_amount", null);
                }}
                isActive={show_all_filters}
                filterType="AMOUNT"
              >
                Max. Amount {currency({ amount: max_amount })}
              </Chip>
            )}
            {min_amount && (
              <Chip
                onClose={() => {
                  setFieldValue("min_amount", null);
                }}
                isActive={show_all_filters}
                filterType="AMOUNT"
              >
                Min. Amount {currency({ amount: min_amount })}
              </Chip>
            )}
            {is_edit_modal && (
              <Chip
                onClose={() => {
                  setFieldValue("show_all_filters", true);
                }}
                isActive={!show_all_filters}
                filterType="SELECTED"
                onClick={() => {
                  setFieldValue("show_all_filters", false);
                  updateFilter("CUSTOMERS", []);
                  setFieldValue("max_amount", null);
                  setFieldValue("min_amount", null);
                }}
                isFixedFilter={is_edit_modal}
              >
                Selected
              </Chip>
            )}
          </>
        }
      >
        <Filter.Portal>
          <Filter.List>
            <Filter.ListItem value="DATE">Date</Filter.ListItem>
            <Filter.ListItem value="CUSTOMER">Customer</Filter.ListItem>
            <Filter.ListItem value="AMOUNT">Amount</Filter.ListItem>
          </Filter.List>

          <Filter.Body value="DATE" block>
            <DateFilter values={filterValues} updateFilter={updateFilter} />
          </Filter.Body>
          <Filter.Body value="CUSTOMER" block>
            <CustomerFilter
              updateFilter={updateFilter}
              filterValues={filterValues}
            />
          </Filter.Body>
          <Filter.Body value="AMOUNT" block>
            <div
              className="t-flex t-flex-col t-gap-3"
              onClick={(e) => e.stopPropagation()}
            >
              <PriceInput name="min_amount" label="Min. Amount" type="number" />
              <PriceInput name="max_amount" label="Max. Amount" type="number" />
            </div>
          </Filter.Body>
        </Filter.Portal>
      </Filter.Root>
      <Pagination
        {...paginationData}
        goToFirstPage={goToFirstPage}
        goToPrevPage={goToPrevPage}
        goToNextPage={goToNextPage}
        goToLastPage={goToLastPage}
      />
    </div>
  );
};

export const DiscountTransactions = () => {
  const { values, setFieldValue, isSubmitting } =
    useFormikContext<LinkedInvoicesFormikValues>();

  const addAnotherTransaction = () => {
    const newValues = [
      ...values.transactions,
      {
        id: randomBytes(10).toString("hex"),
        ...initialTransaction,
      },
    ];
    setFieldValue("transactions", newValues);
  };

  const createColumn = createColumnHelper<Transaction>();

  const columns = useMemo(
    () => [
      createColumn.accessor("merchant", {
        size: 15,
        header: () => <Label>VENDOR</Label>,
        cell: (info) => {
          const id = info.row.id;
          const { merchant = "" } = info.row.original || {};

          return <Vendor id={id} merchant={merchant} />;
        },
      }),

      createColumn.accessor("description", {
        size: 15,
        header: () => <Label required>DESCRIPTION</Label>,
        //@ts-ignore
        cell: Description,
      }),

      createColumn.accessor("category", {
        size: 20,
        header: () => <Label required>Category</Label>,
        cell: (info) => {
          const id = info.row.id;

          return (
            <CategorySelector
              name={`transactions[${id}].category`}
              //@ts-ignore
              category={values.transactions?.[Number(id)]?.category}
              hiddenCategories={[]}
            />
          );
        },
      }),

      createColumn.accessor("amount", {
        size: 10,
        header: () => (
          <div className="t-flex t-justify-end">
            <Label required>Amount</Label>
          </div>
        ),
        cell: AmountColumn,
      }),

      createColumn.accessor("id", {
        size: 5,
        header: "",
        cell: (info) => {
          return <Delete id={info.getValue().toString()} />;
        },
      }),
    ],
    [values]
  );

  const table = useReactTable({
    data: values.transactions || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 1,
      maxSize: 100,
    },
  });

  const addedTransactionsAmount = values?.transactions.reduce(
    (acc, { amount }) => {
      return (acc = acc + Number(amount));
    },
    0
  );

  const finalRemainingAmount =
    roundToFixedDecimal({ numberToRound: values?.remaining_amount! }) -
    roundToFixedDecimal({ numberToRound: addedTransactionsAmount });

  return (
    <div className="t-text-body t-text-text-60 t-flex t-flex-col t-gap-4">
      <div className="t-flex t-justify-between">
        <Checkbox
          label="Resolve difference in amount by adding discount/expended"
          name="resolve_difference"
          onChange={(e) => {
            setFieldValue("resolve_difference", e.target.checked);
          }}
          defaultChecked={values.resolve_difference}
        />
        <div
          className={classNames("t-text-subtitle-sm t-flex t-gap-8", {
            "t-text-text-30": finalRemainingAmount === 0,
            "t-text-red": !isSubmitting && finalRemainingAmount !== 0,
          })}
        >
          <div>Difference</div>
          <AmountSuperScript amount={finalRemainingAmount} />
        </div>
      </div>

      {values?.resolve_difference && (
        <>
          {values?.transactions?.length > 0 && <TableUI table={table} />}
          <div className="t-bg-white t-bottom-0 t-flex t-items-center t-gap-2 t-pb-4">
            <Button type="button" onClick={addAnotherTransaction}>
              Add more
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

const LinkInkleInvoiceBody = () => {
  const history = useHistory();
  const { search } = useLocation();
  const group = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const { values: formikValues, setFieldValue } =
    useFormikContext<LinkedInvoicesFormikValues>();

  const defaultDateRange = getDateRange("currentYear");
  const { values: filterValues, updateFilter } = useFilters({
    initialValue: {
      START_DATE: dayjs(defaultDateRange.startDate).format(DD_MMM_YYYY),
      END_DATE: dayjs(defaultDateRange.endDate).format(DD_MMM_YYYY),
      SELECT_PERIOD: "currentYear",
      CUSTOMERS: [] as string[],
    },
  });

  const [sorting, setSorting] = useState<
    {
      desc: boolean;
      id: "CREATED_AT" | "SENT_TO" | "AMOUNT";
    }[]
  >([]);

  const sortCol = sorting[0]?.id;
  const sortOrder = sorting[0]?.desc;

  const {
    data: allInvoices,
    isLoading,
    isSuccess,
  } = useGetAllInvoicesQuery(
    {
      currentPage: currentPage,
      groupId: group?.uuid!,
      entityId: entityId,
      searchTerm: formikValues?.search || null,
      startDate: dayjs(filterValues.START_DATE).format(YYYY_MM_DD),
      endDate: dayjs(filterValues.END_DATE).format(YYYY_MM_DD),
      customerIds: filterValues.CUSTOMERS.join(","),
      minAmount: formikValues?.min_amount,
      maxAmount: formikValues?.max_amount,
      sortCol: sortCol,
      sortOrder: sortOrder ? ("DSC" as const) : ("ASC" as const),
      limit: 5,
      linkedStatus: "UNLINKED",
    },
    {
      skip: !group?.uuid || !entityId,
      refetchOnMountOrArgChange: true,
    }
  );

  const { total_pages = 0, invoices = [] } = allInvoices || {};

  const paginationData = {
    totalPage: allInvoices?.total_pages!,
    currentPage: allInvoices?.current_page!,
    itemsPerPage: allInvoices?.per_page!,
    totalItemCount: allInvoices?.total_count!,
  };

  const data = useMemo(
    () =>
      [
        ...(formikValues.linked_invoices ? formikValues.linked_invoices : []),
        ...(formikValues.show_all_filters ? invoices : []),
      ] || [],
    [invoices, formikValues.linked_invoices, formikValues.show_all_filters]
  );

  const createColumn = createColumnHelper<Invoice>();

  const invoiceColumns = [
    createColumn.display({
      id: "select",
      size: 1,
      enableSorting: false,
      header: ({ table }) => (
        <Checkbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),

      cell: ({ row }) => (
        <Checkbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
            key: row.id,
          }}
          onChange={(e) => {
            const currentRowAmount = !e.target.checked
              ? Number(row.original.due_balance) * -1
              : Number(row.original.due_balance);

            const remainingAmount = roundToFixedDecimal({
              numberToRound: formikValues?.remaining_amount!,
            });

            setFieldValue(
              "remaining_amount",
              remainingAmount +
                roundToFixedDecimal({
                  numberToRound: currentRowAmount,
                })
            );
            e.stopPropagation();
          }}
        />
      ),
    }),

    InvoiceDateColumn,
    InvoiceNumberColumn,
    InvoiceTitleColumn,
    SentToColumn,
    InvoiceAmountColumn,
  ];

  const table = useReactTable({
    data,
    columns: invoiceColumns,
    state: {
      rowSelection,
      sorting,
    },
    enableRowSelection: true,
    onRowSelectionChange: (updaterOrValue) => {
      if (typeof updaterOrValue === "function") {
        setRowSelection((prevState) => updaterOrValue(prevState ?? {}));
      } else {
        setRowSelection(updaterOrValue);
      }
    },
    getRowId: (row) => row.uuid,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 5,
    },
    manualSorting: true,
    enableMultiSort: false,
    enableSortingRemoval: false,
    //@ts-ignore
    onSortingChange: setSorting,
    enableRowPinning: true,
  });

  useEffect(() => {
    if (rowSelection) {
      const ids = Object.keys(rowSelection);
      setFieldValue("invoice_ids", ids);
    }
  }, [rowSelection]);

  const onRowClick = (row: Row<Invoice>) => {
    row.toggleSelected();
    const currentRowAmount = row.getIsSelected()
      ? Number(row.original.due_balance) * -1
      : Number(row.original.due_balance);

    const remainingAmount = roundToFixedDecimal({
      numberToRound: formikValues?.remaining_amount!,
    });

    setFieldValue(
      "remaining_amount",
      remainingAmount +
        roundToFixedDecimal({
          numberToRound: currentRowAmount,
        })
    );
  };

  const handleSearch = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFieldValue("search", value);
  });

  useEffect(() => {
    if (
      formikValues?.linked_invoices &&
      formikValues?.linked_invoices?.length > 0
    ) {
      const selectedTxns = formikValues?.linked_invoices?.reduce(
        (accumulator, { uuid }, i) => {
          return { ...accumulator, [uuid]: true };
        },
        {}
      );
      setRowSelection(selectedTxns);

      const pinnedRowUuids = formikValues.linked_invoices.map(
        (invoice) => invoice.uuid
      );

      table.setState((prevState) => ({
        ...prevState,
        rowPinning: {
          top: pinnedRowUuids,
        },
      }));
    }
  }, [formikValues.linked_invoices]);

  const addedTransactionsAmount = formikValues?.transactions.reduce(
    (acc, { amount }) => {
      return (acc = acc + Number(amount));
    },
    0
  );

  const finalRemainingAmount =
    roundToFixedDecimal({ numberToRound: formikValues?.remaining_amount! }) -
    addedTransactionsAmount;

  if (isLoading && !isSuccess) {
    return (
      <div className="t-w-full t-h-full t-flex t-justify-center t-align-middle">
        <Loader />
      </div>
    );
  }

  return (
    <div className="t-flex t-flex-col t-gap-4">
      <div className="t-w-full t-h-full t-flex t-gap-4">
        <Search block placeholder="Search..." onChange={handleSearch} />
        <div className="t-w-1/2">
          <div className="t-flex t-justify-end t-gap-3">
            <div className="t-flex t-flex-col">
              <div className="t-text-body-sm t-text-text-30">
                Transaction Amount
              </div>
              <div className="t-text-subtitle-sm t-flex t-justify-end">
                <AmountSuperScript amount={formikValues?.transaction_amount!} />
              </div>
            </div>
            <div className="t-h-[40px] t-border t-border-solid t-border-top-0 t-border-l t-border-r-0 t-border-bottom-0 t-border-neutral-0"></div>

            <div className="t-flex t-flex-col">
              <div className="t-text-body-sm t-text-text-30">
                Remaining Amount
              </div>
              <div className="t-text-subtitle-sm t-flex t-justify-end">
                <AmountSuperScript amount={finalRemainingAmount!} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <InvoiceFilter
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={total_pages}
        filterValues={filterValues}
        updateFilter={updateFilter}
        paginationData={paginationData}
      />
      <Divider />
      {invoices.length === 0 && !formikValues.is_edit_modal ? (
        <div className="t-w-full t-h-full t-flex t-flex-col t-justify-center t-items-center t-py-12 t-gap-2">
          <img src={EmptyInvoices} alt="EmptyInvoices" />
          <div className="t-w-full t-h-full t-flex t-flex-col t-justify-center t-items-center t-gap-3">
            <div className="t-text-body-sm t-text-text-30">
              There are no invoices that are sent and not paid yet
            </div>
            <Button
              customType="primary"
              onClick={(e) => {
                e.stopPropagation();
                history.push(`/books/invoicing${search}`);
              }}
            >
              Create Invoice
            </Button>
          </div>
        </div>
      ) : (
        <TableUI table={table} enableSort onRowClick={onRowClick} />
      )}
      <DiscountTransactions />
    </div>
  );
};

export const initialTransaction: Omit<Transaction, "id" | "date" | "invoice"> =
  {
    merchant: "",
    category: "",
    description: "",
    amount: 0,
  };

export const LinkInkleInvoiceModal = ({
  isEditModal = false,
  transactionId,
  transactionAmount,
  isOpen,
  close,
}: {
  isEditModal?: boolean;
  transactionId: string;
  transactionAmount: number;
} & ModalProps) => {
  const entityId = useCurrentEntityId();
  const { alertToast, successToast } = useToast();
  const [markInvoicePaid, { isLoading }] =
    useMapInkleTransactionToInvoiceMutation();

  const { data: linkedTxnData } = useGetMappedInvoiceQuery(
    {
      entityId,
      transactionId,
    },
    { skip: !isEditModal }
  );

  const {
    transactions: linkedTransactions = [],
    invoices: linkedInvoices = [],
  } = linkedTxnData || {};

  const initialTransactions = useMemo(
    () =>
      linkedTransactions.length > 0
        ? linkedTransactions?.map(
            ({
              transaction: { merchant, category, description, amount, uuid },
            }) => ({
              id: uuid,
              merchant,
              category: category?.uuid,
              description,
              amount,
            })
          )
        : Array.from({ length: 1 }).map((arr, i) => ({
            id: randomBytes(10).toString("hex"),
            ...initialTransaction,
          })),
    [linkedTransactions]
  );

  const invoicesTotalAmount = isEditModal
    ? linkedInvoices.reduce((amountTotal, { due_balance }) => {
        amountTotal = amountTotal + Number(due_balance);
        return amountTotal;
      }, 0)
    : 0;

  const initialValues = {
    invoice_ids: [""],
    transactions: initialTransactions,
    transaction_amount: transactionAmount,
    remaining_amount: invoicesTotalAmount - transactionAmount,
    ...(isEditModal
      ? {
          linked_transactions: linkedTransactions,
          linked_invoices: linkedInvoices,
          is_edit_modal: isEditModal,
          show_all_filters: false,
          resolve_difference: linkedTransactions.length > 0 ? true : false,
        }
      : { resolve_difference: false, show_all_filters: true }),
  };

  const onSubmit = async (values: {
    invoice_ids: string[];
    transactions: typeof initialTransactions;
    resolve_difference: boolean;
  }) => {
    try {
      const entityInvoiceIds = values.invoice_ids.join(",");
      const transactions = values.resolve_difference
        ? values.transactions.map(
            ({ amount, description, category, merchant }) => {
              return {
                amount: amount,
                description: description,
                category_id: category,
                merchant_id: merchant,
              };
            }
          )
        : null;
      await markInvoicePaid({
        entityId,
        entityInvoiceIds,
        transactionId,
        ...(transactions && { transactions }),
      }).unwrap();
      successToast({ message: "Linked Successsfully" });
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      enableReinitialize={isEditModal ? true : false}
      validationSchema={linkInkleSchema}
    >
      {({ isSubmitting, submitForm, values }) => {
        const addedTransactionsAmount = values?.transactions.reduce(
          (acc, { amount }) => {
            return (acc = acc + Number(amount));
          },
          0
        );

        const finalRemainingAmount =
          roundToFixedDecimal({ numberToRound: values.remaining_amount }) -
          addedTransactionsAmount;

        const isDisabled = finalRemainingAmount !== 0;

        return (
          <Form>
            <Modal.Root open={isOpen} onOpenChange={close} modal={false}>
              <Modal.Content size="xxl" useCustomOverlay>
                <Modal.Header>
                  <Modal.Title>
                    {isEditModal ? "Edit Invoice Linking" : "Link to invoices"}
                  </Modal.Title>
                  <Modal.Close disabled={isLoading} />
                </Modal.Header>
                <Modal.Body>
                  <LinkInkleInvoiceBody />
                </Modal.Body>
                <Modal.FooterButtonGroup>
                  <Modal.RawClose asChild disabled={isLoading}>
                    <Button>Cancel</Button>
                  </Modal.RawClose>
                  <ConditionalToolTip
                    condition={
                      isDisabled &&
                      "Mismatch in transaction and invoice amounts"
                    }
                  >
                    <span>
                      <Button
                        customType="primary"
                        isLoading={isSubmitting || isLoading}
                        disabled={isSubmitting || isLoading || isDisabled}
                        onClick={submitForm}
                      >
                        Link
                      </Button>
                    </span>
                  </ConditionalToolTip>
                </Modal.FooterButtonGroup>
              </Modal.Content>
            </Modal.Root>
          </Form>
        );
      }}
    </Formik>
  );
};
