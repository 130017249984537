import Loader from "components/design/loader";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { Form, Formik } from "formik";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RowSelectionState } from "react-table-8.10.7";
import {
  useCategorizeGroupTransactionsMutation,
  useGetAllTransactionsDataQuery,
} from "store/apis/transactions";
import { setSelectedTransactionIds } from "store/slices/transactions";
import { RootState } from "store/store";
import { Transactions } from "types/Models/books";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";
import { TransactionsView } from "components/TransactionsView/TransactionsView";
import { useGetTransactionsFilterSet } from "hooks/useGetTransactionsFilterSet";

type UncategoriseModalProps = ModalProps & {
  deselectRows: () => void;
  unSelectedRowsIds: string[];
  isAllTxnSelected: boolean;
};

const DisclaimerUI = ({
  txnCount,
  previouslyLinkedTxnsCount,
}: {
  txnCount: number;
  previouslyLinkedTxnsCount: number;
}) => {
  return (
    <div className="t-text-body">
      We found{" "}
      <b className="t-text-red t-text-subtitle-sm">
        {txnCount} categorized transactions{" "}
      </b>
      {previouslyLinkedTxnsCount > 0 && (
        <>
          {" "}
          that includes
          <b> {previouslyLinkedTxnsCount} linked transactions </b>
        </>
      )}
      . Are you sure you want to uncategorize them all?
    </div>
  );
};

const Disclaimer = ({ transactions }: { transactions: Transactions[] }) => {
  const allCategoriseTransactions = transactions.filter(
    ({ transaction: { category } }) => Boolean(category?.uuid)
  );

  const allLinkedTransactions = transactions.filter(
    ({ transaction: { linked_transaction } }) => Boolean(linked_transaction)
  );

  return (
    <DisclaimerUI
      previouslyLinkedTxnsCount={allLinkedTransactions.length}
      txnCount={allCategoriseTransactions.length}
    />
  );
};

export const UncategoriseModal = ({
  close,
  isOpen,
  deselectRows,
  isAllTxnSelected,
  unSelectedRowsIds,
}: UncategoriseModalProps) => {
  const dispatch = useDispatch();
  const { successToast, alertToast } = useToast();
  const entityId = useCurrentEntityId();
  const { uuid: groupId } = useCurrentGroupContext();
  const [groupCategorize] = useCategorizeGroupTransactionsMutation();
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const { selectedTransactionIds } = useSelector(
    (store: RootState) => store.transactions
  );

  const { data, isLoading: isFilterSetLoading } = useGetTransactionsFilterSet({
    filter_operation: "UNCATEGORIZE",
    unSelectedRowsIds,
    skip: !isAllTxnSelected || !isOpen,
  });

  const {
    txns_count = 0,
    txns_hash_key = "",
    categorized_txns_with_linked_transaction_count = 0,
  } = data || {};

  const { data: transactionData, isLoading } = useGetAllTransactionsDataQuery(
    {
      groupId,
      entityId,
      transactionIds: selectedTransactionIds.join(","),
    },
    {
      skip: !groupId || !entityId || isAllTxnSelected,
      refetchOnMountOrArgChange: true,
    }
  );

  const { transactions = [] } = transactionData || {};

  const allCategoriseTransactions = transactionData?.transactions
    .filter(({ transaction: { category } }) => Boolean(category?.uuid))
    .map(({ transaction: { uuid } }) => uuid);

  useEffect(() => {
    const selectedTxns = transactions?.reduce((accumulator, transaction, i) => {
      return {
        ...accumulator,
        [i]: allCategoriseTransactions?.includes(transaction?.transaction?.uuid)
          ? true
          : false,
      };
    }, {});
    setRowSelection(selectedTxns || {});
  }, [transactionData?.transactions]);

  const onClose = () => {
    close();
    dispatch(setSelectedTransactionIds([]));
  };

  const onSubmit = async ({ txns_hash_key }: { txns_hash_key: string }) => {
    try {
      const transactionIds = transactions?.reduce((acc, transaction, i) => {
        if (
          rowSelection[i] &&
          allCategoriseTransactions?.includes(transaction?.transaction?.uuid)
        ) {
          if (acc) {
            acc += `, ${transaction?.transaction?.uuid}`;
          } else {
            acc = transaction?.transaction?.uuid;
          }
        }
        return acc;
      }, "");

      await groupCategorize({
        groupId,
        entityId,
        payload: {
          transaction_category_uuid: null,
          transaction_ids: txns_hash_key ? undefined : transactionIds,
          transactions_hash_key: txns_hash_key,
        },
      }).unwrap();
      onClose();
      deselectRows();
      successToast({
        message: "Transactions Uncategorised",
      });
    } catch (error) {
      alertToast({ message: (error as BackendError)?.data?.error?.message });
    }
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={onClose}>
      <Formik
        initialValues={{ txns_hash_key }}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Modal.Content size="xl">
              <Modal.Header>
                <Modal.Title>Uncategorise</Modal.Title>
                <Modal.Close />
              </Modal.Header>
              <Modal.Body className="t-flex t-gap-6 t-flex-col t-text-body">
                {isFilterSetLoading ? (
                  <Loader size="small" />
                ) : (
                  <>
                    {isLoading ? (
                      <Loader />
                    ) : (
                      <>
                        {isAllTxnSelected ? (
                          <DisclaimerUI
                            previouslyLinkedTxnsCount={
                              categorized_txns_with_linked_transaction_count
                            }
                            txnCount={txns_count}
                          />
                        ) : (
                          <>
                            {
                              <>
                                <Disclaimer
                                  transactions={transactionData?.transactions!}
                                />
                                <TransactionsView
                                  transactions={transactions.map(
                                    (t) => t.transaction
                                  )}
                                  showCheckbox
                                  rowSelection={rowSelection}
                                  setRowSelection={setRowSelection}
                                  disableUncategoriseTransaction
                                />
                              </>
                            }
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </Modal.Body>
              <Modal.Footer>
                <div className="t-flex t-justify-end t-gap-3">
                  <Button
                    type="reset"
                    onClick={onClose}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </Button>
                  <Button
                    customType="primary"
                    onClick={submitForm}
                    isLoading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    Uncategorise
                  </Button>
                </div>
              </Modal.Footer>
            </Modal.Content>
          </Form>
        )}
      </Formik>
    </Modal.Root>
  );
};
