import { Button } from "components/DesignSystem/Button/Button";
import DropDown from "components/DesignSystem/Dropdown/Dropdown";
import { OptionDropdown } from "components/icons/optionDropdown";
import React, { useContext, useState } from "react";
import { MakeAdminModal } from "./Modals/MakeAdminModal";
import {
  TeamMember,
  useChangeAdminMutation,
  useGetTeamManagementMembersQuery,
} from "store/apis/teamSettingCPA";
import authContext from "jwt_context&axios/authContext";
import Modal from "components/DesignSystem/Modal/Modal";
import { useModal } from "hooks/useModal";
import { Form, Formik } from "formik";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import { useParams } from "react-router-dom";
import { object, string } from "yup";

export const MakeAdmin = ({ data }: { data: TeamMember }) => {
  const [showMakeAdmin, setShowMakeAdmin] = useState(false);
  const {
    authtoken: { email },
  } = useContext(authContext);
  const { authtoken } = useContext(authContext);
  const { practicecrmId } = useParams<{ practicecrmId: string }>();

  const { data: members = [] } = useGetTeamManagementMembersQuery({
    serviceTeamId: practicecrmId || authtoken.service_team_id,
  });

  const [archiveUser, { isLoading: archivingUser }] = useChangeAdminMutation();

  const archiveModal = useModal();
  const confirmationModal = useModal();

  const onSubmit = async ({
    reassign_invite_id,
  }: {
    reassign_invite_id: string;
  }) => {
    try {
      await archiveUser({
        memberId: data.uuid,
        payload: {
          action: "ARCHIVE_TEAM_MEMBER",
          reassign_invite_id,
        },
      }).unwrap();
      archiveModal.close();
      confirmationModal.close();
    } catch (error: any) {}
  };

  if (data.status === "ARCHIVED") {
    return (
      <Button customType="ghost_icon" disabled size="small">
        <OptionDropdown color="currentColor" />
      </Button>
    );
  }

  return (
    <>
      <DropDown.Root modal={false}>
        <DropDown.Trigger className="all:unset">
          <Button customType="ghost_icon" size="small">
            <OptionDropdown color="currentColor" />
          </Button>
        </DropDown.Trigger>
        <DropDown.Portal>
          <DropDown.Content
            sideOffset={8}
            className="t-rounded-lg t-bg-surface t-p-2 t-drop-shadow-dropdown sm:t-w-[94vw]"
            align="end"
          >
            <DropDown.Item
              onSelect={() => setShowMakeAdmin(true)}
              disabled={data?.status === "INVITED" || data?.email === email}
            >
              Make admin
            </DropDown.Item>
            <DropDown.Item onSelect={archiveModal.open}>Archive</DropDown.Item>
          </DropDown.Content>
        </DropDown.Portal>
      </DropDown.Root>

      <MakeAdminModal
        data={data}
        showMakeAdmin={showMakeAdmin}
        setShowMakeAdmin={setShowMakeAdmin}
      />

      <Modal.Root open={archiveModal.isOpen} onOpenChange={archiveModal.close}>
        <Modal.Content useCustomOverlay>
          <Formik
            initialValues={{
              reassign_invite_id: "",
            }}
            onSubmit={onSubmit}
            validationSchema={object({
              reassign_invite_id: string().required("Required"),
            })}
          >
            {({ submitForm, validateForm, setTouched, values }) => {
              const selectedMember = members.find(
                (m) => m.uuid === values.reassign_invite_id
              );

              return (
                <>
                  <Modal.Header>
                    <Modal.Title>Archive User</Modal.Title>
                    <Modal.Close />
                  </Modal.Header>

                  <Modal.Body>
                    <Form>
                      <Combobox
                        menuPortalTarget={document.body}
                        label="Reassign tasks, tickets and companies to"
                        withForm
                        name="reassign_invite_id"
                        options={members
                          .filter((m) => m.uuid !== data.uuid)
                          .map((member) => ({
                            label: member.name,
                            value: member.uuid,
                          }))}
                      />
                    </Form>
                  </Modal.Body>
                  <Modal.FooterButtonGroup>
                    <Modal.RawClose asChild>
                      <Button>Cancel</Button>
                    </Modal.RawClose>
                    <Button
                      onClick={async () => {
                        try {
                          const validated = await validateForm();
                          setTouched({ reassign_invite_id: true });
                          Object.values(validated).length === 0 &&
                            confirmationModal.open();
                        } catch (error) {}
                      }}
                      customType="danger"
                    >
                      Archive and reassign
                    </Button>
                  </Modal.FooterButtonGroup>

                  <Modal.Root
                    onOpenChange={confirmationModal.close}
                    open={confirmationModal.isOpen}
                  >
                    <Modal.Content>
                      <Modal.Header>
                        <Modal.Title>Are you sure?</Modal.Title>
                        <Modal.Close />
                      </Modal.Header>
                      <Modal.Body className="t-flex t-flex-col t-gap-3">
                        <p className="t-text-body t-m-0">
                          Are you sure you want to archive{" "}
                          <strong>{data.name}</strong>?
                          <br />
                          This action{" "}
                          <span className="t-text-red">cannot be undone.</span>
                          <br />
                        </p>

                        <p className="t-m-0 t-text-body">
                          All the taks, tickes and companies assigned to{" "}
                          <strong>{data.name}</strong> will be reassigned to{" "}
                          <strong>{selectedMember?.name}</strong>.
                        </p>
                      </Modal.Body>

                      <Modal.FooterButtonGroup>
                        <Modal.RawClose asChild>
                          <Button>Cancel</Button>
                        </Modal.RawClose>
                        <Button
                          onClick={submitForm}
                          customType="danger"
                          isLoading={archivingUser}
                          disabled={archivingUser}
                        >
                          Confirm
                        </Button>
                      </Modal.FooterButtonGroup>
                    </Modal.Content>
                  </Modal.Root>
                </>
              );
            }}
          </Formik>
        </Modal.Content>
      </Modal.Root>
    </>
  );
};
