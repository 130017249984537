import classNames from "classnames";
import DashboardContainer from "components/dashboard/DashboardContainer";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import { TableUI } from "components/design/TableUI";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { AvatarGroup } from "components/DesignSystem/AvatarGroup/AvatarGroup";
import { Button } from "components/DesignSystem/Button/Button";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import { Search } from "components/DesignSystem/Search/Search";
import Table from "components/DesignSystem/Table/V2/Table";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { usePageTitle } from "hooks/usePageTitle";
import { usePagination } from "hooks/usePagination";
import { EmptyScreen } from "pages/Books/EmptyScreen";
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CellContext,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import EmptyRuleEngine from "static/images/EmptyRuleEngine.svg";
import ThreeDots from "static/images/ThreeDots.svg";
import { useGetAllRulesQuery } from "store/apis/ruleEngine";
import {
  openDeleteModal,
  openEditModal,
  openSlider,
  setAllRuleIds,
} from "store/slices/ruleEngine";
import { RootState } from "store/store";
import { RulesData, ValueSupported } from "types/Models/ruleEngine";
import { debounce } from "utils/debouncing";
import { CreateRule } from "./CreateRule";
import { DeleteRule } from "./DeleteRule";
import { EditRuleModal } from "./EditRuleModal";
import { RuleSlider } from "./RuleSlider";
import { useGetAllTransactionMerchantsQuery } from "store/apis/merchant";
import { useGetAllAccountQuery } from "store/apis/transactions";
import { Tag } from "components/DesignSystem/Tag/Tag";
import { Loader } from "components/DesignSystem/Loader/Loader";
import ToolTip from "components/design/toolTip";

export const VendorInfo = ({
  value,
  tag = false,
}: {
  value: string[];
  tag?: boolean;
}) => {
  const groupId = useCurrentGroupContext().uuid;
  const entityId = useCurrentEntityId();

  const merchantIds = value.join(",");
  const { data: merchants, isLoading } = useGetAllTransactionMerchantsQuery(
    {
      merchantIds,
      groupId,
      entityId,
    },
    { skip: !groupId || !entityId || !value.length }
  );

  if (isLoading) {
    return <Loader size="small" />;
  }

  if (!merchants) {
    return null;
  }

  if (tag) {
    return (
      <div className="t-inline-flex t-gap-1 t-flex-wrap">
        {merchants.merchants.map(({ name }) => (
          <Tag icon={false} key={name} tagType="gray">
            {name}
          </Tag>
        ))}
      </div>
    );
  }

  return merchants.merchants.map(({ name }) => name).join(" or ");
};

export const SourceInfo = ({
  value,
  tag = false,
}: {
  value: string[];
  tag?: boolean;
}) => {
  const groupId = useCurrentGroupContext().uuid;
  const entityId = useCurrentEntityId();
  const { data: banks = [], isLoading } = useGetAllAccountQuery(
    {
      groupId,
      entityId,
      account_type: "EXCLUDE_STRIPE",
    },
    { skip: !groupId || !entityId }
  );

  if (isLoading) {
    return <Loader size="small" />;
  }

  if (tag) {
    return (
      <div className="t-inline-flex t-gap-1 t-flex-wrap">
        {value
          .map((v) => banks.find((b) => b.uuid === v))
          .map((v) => (
            <Tag icon={false} key={v?.uuid} tagType="gray">
              {v?.nickname}
            </Tag>
          ))}
      </div>
    );
  }

  return value
    .map((v) => banks.find((b) => b.uuid === v))
    .map((v) => v?.nickname)
    .join(" or ");
};

export const ValueType = ({
  value_type,
  value,
  tag = false,
}: {
  value_type: ValueSupported;
  value: string | number | string[];
  tag?: boolean;
}) => {
  switch (value_type) {
    case "CHOICE": {
      return <> {value}</>;
    }

    case "DECIMAL": {
      return (
        <>
          {" "}
          <AmountSuperScript amount={Number(value)} />
        </>
      );
    }

    case "TEXT": {
      return <> {value}</>;
    }

    case "VENDOR": {
      return value instanceof Array ? (
        <VendorInfo tag={tag} value={value} />
      ) : null;
    }

    case "SOURCE": {
      return value instanceof Array ? (
        <SourceInfo tag={tag} value={value} />
      ) : null;
    }

    default:
      return <> {value}</>;
  }
};

const ConditionsColumn = (
  info: CellContext<RulesData, RulesData["conditions"]>
) => {
  const conditions = info.getValue();
  const { condition_operand, condition_operator, value, value_type, uuid } =
    conditions[0];

  return (
    <div className="t-flex t-gap-1.5">
      <div className="t-gap-1 t-flex t-bg-neutral-0 t-rounded t-py-1 t-px-2 t-text-subtitle-sm t-text-text-60">
        <span className="t-text-nowrap">{condition_operand.name}</span>
        <span className="t-text-nowrap t-text-body t-text-text-30">
          {condition_operator.name}
        </span>
        <span className="t-line-clamp-1">
          {Array.isArray(value) ? (
            <ToolTip
              text={<ValueType tag value={value} value_type={value_type} />}
            >
              <span>
                <ValueType value={value} value_type={value_type} />
              </span>
            </ToolTip>
          ) : (
            <ValueType value={value} value_type={value_type} />
          )}
        </span>
      </div>
      {conditions.length > 1 && (
        <ToolTip text={`${conditions.length - 1} more condition`}>
          <div className="t-gap-1 t-text-nowrap t-flex t-bg-neutral-0 t-rounded t-py-1 t-px-2 t-text-text-60">
            + {conditions.length - 1}
          </div>
        </ToolTip>
      )}
    </div>
  );
};

export const Actions = ({ ruleId }: { ruleId: string }) => {
  const dispatch = useDispatch();

  const onEdit = () => {
    dispatch(openEditModal(ruleId));
  };

  const onDelete = () => {
    dispatch(openDeleteModal(ruleId));
  };

  return (
    <Dropdown.Root>
      <Dropdown.Trigger asChild>
        <span>
          <Button size="small" customType="ghost_icon">
            <img src={ThreeDots} alt="Action" className="t-select-none" />
          </Button>
        </span>
      </Dropdown.Trigger>
      <Dropdown.Portal>
        <Dropdown.Content sideOffset={8} side="bottom" align="end">
          <Dropdown.Item
            onClick={(e) => {
              e.stopPropagation();
              onEdit();
            }}
          >
            Edit
          </Dropdown.Item>
          <Dropdown.Item
            onClick={(e) => {
              e.stopPropagation();
              onDelete();
            }}
          >
            Delete
          </Dropdown.Item>
        </Dropdown.Content>
      </Dropdown.Portal>
    </Dropdown.Root>
  );
};

export const RuleEngine = () => {
  usePageTitle("Rules");
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();
  const createColumn = createColumnHelper<RulesData>();

  const { previewRuleId } = useSelector((store: RootState) => store.ruleEngine);

  const {
    goToFirstPage,
    goToLastPage,
    goToNextPage,
    goToPrevPage,
    pageNum,
    setTotalPage,
  } = usePagination();

  const { data, isLoading, isSuccess } = useGetAllRulesQuery(
    { entityId, groupId, pageNum, searchTerm },
    { skip: !groupId || !entityId }
  );

  const {
    rules_data = [],
    current_page = 1,
    per_page = 1,
    total_count = 1,
    total_pages = 1,
  } = data || {};

  const isEmpty = rules_data.length === 0;

  useEffect(() => {
    if (total_pages) {
      setTotalPage(total_pages);
    }
  }, [total_pages]);

  const paginationData = {
    totalPage: total_pages,
    currentPage: current_page,
    itemsPerPage: per_page,
    totalItemCount: total_count,
  };

  const { close, isOpen, open } = useModal();

  const handleSearch = debounce((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchTerm(value);
  });

  const columns = [
    createColumn.accessor("name", {
      size: 30,
      header: "title",
      cell: (info) => {
        const name = info.getValue();
        return <>{name}</>;
      },
    }),

    createColumn.accessor("conditions", {
      size: 30,
      header: "conditions",
      cell: ConditionsColumn,
    }),

    createColumn.accessor("category", {
      size: 30,
      header: "category",
      cell: (info) => {
        const { name } = info.getValue();
        return <>{name}</>;
      },
    }),

    createColumn.accessor("uuid", {
      size: 5,
      header: " ",
      cell: (info) => {
        const ruleId = info.getValue();
        return <Actions ruleId={ruleId} />;
      },
    }),
  ];

  const table = useReactTable({
    data: rules_data || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      size: 10,
      minSize: 1,
      maxSize: 100,
    },
  });

  const openRuleSlider = (ruelId: string) => {
    const allRuleIds = rules_data.map(({ uuid }) => uuid);

    dispatch(openSlider(ruelId));
    dispatch(setAllRuleIds(allRuleIds));
  };

  return (
    <DashboardContainer className="t-relative t-gap-5">
      <DashboardContainer.Header className="t-sticky t-top-0 t-z-header t-flex t-flex-col t-gap-3 t-pr-6">
        <div className="t-flex t-justify-between">
          <div className="t-w-1/2">
            <Search block placeholder="Search" onChange={handleSearch} />
          </div>
          <Button customType="primary" size="small" onClick={open}>
            Create rule
          </Button>
        </div>
        <div className="t-flex t-justify-end t-w-full">
          <Pagination
            {...paginationData}
            goToFirstPage={goToFirstPage}
            goToPrevPage={goToPrevPage}
            goToNextPage={goToNextPage}
            goToLastPage={goToLastPage}
          />
        </div>
      </DashboardContainer.Header>
      <DashboardContainer.Content>
        <Async.Root {...{ isLoading, isSuccess, isEmpty }}>
          <Async.Empty>
            <EmptyScreen
              text="Start creating rules"
              cta={
                <Button customType="primary" size="small" onClick={open}>
                  Create rule
                </Button>
              }
            >
              <img src={EmptyRuleEngine} alt="No rules found" />
            </EmptyScreen>
          </Async.Empty>
          <Async.Success>
            <TableUI table={table}>
              {({ row }) => (
                <Table.Row
                  key={row.id}
                  onClick={() => openRuleSlider(row.original.uuid)}
                  className={classNames(
                    "hover:t-bg-surface-lighter-grey t-cursor-pointer",
                    {
                      "t-bg-surface-lighter-grey":
                        previewRuleId === row.original.uuid,
                    }
                  )}
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <Table.Cell
                        key={cell.id}
                        style={{ width: `${cell.column.getSize()}%` }}
                        className="t-py-4 t-px-2"
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Table.Cell>
                    );
                  })}
                </Table.Row>
              )}
            </TableUI>
            <RuleSlider />
          </Async.Success>
        </Async.Root>
      </DashboardContainer.Content>
      {isOpen && <CreateRule isOpen={isOpen} close={close} />}
      <EditRuleModal />
      <DeleteRule />
    </DashboardContainer>
  );
};
