import { AmountSuperScript } from "components/design/AmountSuperScript";
import ConditionalToolTip from "components/design/conditionalToolTip";
import ToolTip from "components/design/toolTip";
import { Button } from "components/DesignSystem/Button/Button";
import { DownloadIcon } from "components/icons/Download";
import dayjs from "dayjs";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import Lottie from "lottie-react";
import { ReactNode } from "react";
import { useHistory } from "react-router-dom";
import BookkeepingCalendar from "static/images/BookkeepingCalendar.svg";
import infoIcon from "static/images/InfoGray.svg";
import NotApplicableContent from "static/images/NotApplicableContent.svg";
import Pencil from "static/images/Pencil.svg";
import processingAnimation from "./processing.json";

const Processing = ({
  onStartProcessing,
}: {
  onStartProcessing: () => void;
}) => {
  const { isAdmin, isCpa, isForeignCA } = useRoleBasedView();

  if (isAdmin || isCpa || isForeignCA) {
    return (
      <div className="t-flex t-flex-col t-items-center t-justify-center t-gap-3 t-py-14">
        <img src={Pencil} alt="start" />
        <Button customType="primary" size="small" onClick={onStartProcessing}>
          Start monthly closing
        </Button>
      </div>
    );
  }

  return (
    <div className="t-flex t-flex-col t-items-center t-justify-center t-py-14">
      <Lottie className="t-w-52" animationData={processingAnimation} loop />
      <div className="t-text-center t-text-body t-text-neutral">
        <p className="t-mb-0 t-mt-3">We're processing your financials.</p>
        <p className="t-m-0">
          We'll notify you once it's ready for your review.
        </p>
      </div>
    </div>
  );
};

const Upcoming = ({
  date,
  onMonthStart,
  isBYOA,
}: {
  date: string;
  onMonthStart?: () => void;
  isBYOA?: boolean;
}) => {
  const { isCustomer, isCpa, isAdmin, isForeignCA } = useRoleBasedView();
  const history = useHistory();

  if (isBYOA) {
    return (
      <div className="t-flex t-flex-col t-items-center t-justify-center t-py-14 t-gap-2">
        <img src={BookkeepingCalendar} alt="Calendar icon" />
        {isCpa || isAdmin || isForeignCA ? (
          <div className="t-flex t-flex-col t-justify-center t-items-center t-gap-4">
            <p className="t-m-0 t-mt-3 t-text-center t-text-body t-text-neutral ">
              The accountant, added by the user, will upload the Data & Reports
              under the
              <br />
              'BYOA: Bring Your Own Accountant' plan
            </p>
            <Button size="small" onClick={() => history.push("/chat")}>
              Chat
            </Button>
          </div>
        ) : (
          <Button
            customType="primary-outlined"
            size="small"
            onClick={onMonthStart}
          >
            Add Data & Reports
          </Button>
        )}
      </div>
    );
  }

  return (
    <div className="t-flex t-flex-col t-items-center t-justify-center t-py-14 t-gap-2">
      <img src={BookkeepingCalendar} alt="Calendar icon" />
      {isCustomer ? (
        <div className="t-text-center t-text-body t-text-neutral">
          <p className="t-m-0 t-mt-3">
            Bookkeeping for this month will start on 1st{" "}
            {dayjs(date).format("MMMM YYYY")}
          </p>
        </div>
      ) : (
        <Button
          customType="primary-outlined"
          size="small"
          onClick={onMonthStart}
        >
          Add Data & Reports
        </Button>
      )}
    </div>
  );
};

const NotApplicable = () => {
  return (
    <div className="t-flex t-flex-col t-items-center t-justify-center t-py-14">
      <img src={NotApplicableContent} alt="Calendar icon" />
      <div className="t-text-center t-text-body t-text-neutral">
        <p className="t-m-0 t-mt-3">
          Bookkeeping for this month was not done by InkleBooks. If you would
          <br />
          like us to work on books for this month, please chat with us.
        </p>
      </div>
    </div>
  );
};

export const SummaryCard = ({
  amount,
  text,
  description,
  toolTip = false,
}: {
  amount: null | number | string | ReactNode;
  text: string;
  description: string;
  toolTip?: boolean;
}) => {
  return (
    <div className="t-flex-1 t-rounded-md t-border t-border-solid t-border-neutral-10 t-bg-surface t-p-3">
      <span className="t-mr-1 t-inline-block t-text-body t-text-neutral">
        {text}
      </span>
      {toolTip && (
        <ToolTip text={description}>
          <img src={infoIcon} alt="info" />
        </ToolTip>
      )}
      <ConditionalToolTip condition={amount}>
        <div className="t-max-w-56 t-truncate t-text-end t-text-h4">
          {amount || "-"}
        </div>
      </ConditionalToolTip>
    </div>
  );
};

const SummaryReport = ({
  icon,
  label,
  onDownload,
  onPreview,
}: {
  icon: ReactNode;
  label: string;
  onDownload: () => void;
  onPreview: () => void;
}) => {
  return (
    <div
      className="primary-border t-flex t-cursor-pointer t-items-center t-justify-between t-bg-surface t-px-4 t-py-3 hover:t-bg-purple-0"
      onClick={onPreview}
    >
      <span className="t-flex t-gap-3 t-items-center">
        {icon}
        <span className="t-text-subtitle-sm t-font-medium t-text-neutral">
          {label}
        </span>
      </span>
      <Button
        customType="ghost_icon"
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          onDownload();
        }}
      >
        <span className="t-text-neutral">
          <DownloadIcon color="currentColor" size={28} />
        </span>
      </Button>
    </div>
  );
};

type CompletedSummaryProps = {
  expenseVolume: null | string | number;
  transferPricingVolume: null | number | string;
  noOfTransactions: null | number;
};

const SummaryCards = ({
  expenseVolume,
  transferPricingVolume,
  noOfTransactions,
}: CompletedSummaryProps) => {
  const expense =
    expenseVolume === null ? (
      expenseVolume
    ) : (
      <AmountSuperScript amount={+expenseVolume!} />
    );
  const noOfTxn = noOfTransactions === 0 ? "0" : noOfTransactions;

  return (
    <div className="t-mt-4 t-flex t-gap-2">
      <SummaryCard
        amount={expense}
        text="Expense Excluding TP Volume"
        description="Aggregate amount of all debits for this month"
        toolTip
      />
      <SummaryCard
        amount={noOfTxn}
        text="Number of Transactions"
        description="This is no of txns"
      />
    </div>
  );
};

const BookkeepingAccordionContent = {
  Processing: Processing,
  Upcoming: Upcoming,
  SummaryCards,
  SummaryReport,
  NotApplicable,
};

export default BookkeepingAccordionContent;
