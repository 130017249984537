import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { FieldBuilder } from "components/Entity/EntityFormEngine/FieldBuilder";
import { Form, Formik, FormikValues } from "formik";
import { buildValidationSchema } from "formValidations/EINRegistrationSchema";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useQuery } from "hooks/useQuery";
import { useToast } from "hooks/useToast";
import React from "react";
import { EntityDataAttributeWithFieldKey } from "store/apis/registrations";
import {
  StateRegistrationNameType,
  useUpdateStateRegistrationMutation,
} from "store/apis/StateRegistrations";
import { BackendError } from "types/utils/error";

export const StateRegistrationForm = ({
  isOpen,
  close,
  fields,
  stateId,
  stateType,
}: {
  isOpen: boolean;
  close: () => void;
  fields: EntityDataAttributeWithFieldKey[];
  stateId: string;
  stateType: StateRegistrationNameType;
}) => {
  const { alertToast, successToast } = useToast();
  const [updateStateRegistrationForm, { isLoading: isUpdatingForm }] =
    useUpdateStateRegistrationMutation();
  const { uuid: groupId } = useCurrentGroupContext();
  const query = useQuery();
  let entityId = query.get("entity");

  const handleSubmit = async (values: FormikValues) => {
    try {
      const dataAttributes = fields.map((field) => ({
        ...field,
        value: values[field.field_key],
      }));

      const payload = {
        data_attributes: dataAttributes,
        type: stateType,
      };
      if (payload) {
        await updateStateRegistrationForm({
          groupId: groupId!,
          entityId: entityId!,
          stateId,
          payload,
        }).unwrap();
        successToast({ message: "Status updated successfully" });
      }
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
    close();
  };

  const initialValues = fields.reduce(
    (ac, cv) => (ac = { ...ac, [cv.field_key]: cv.value }),
    {}
  );

  const validationSchema = buildValidationSchema(fields);

  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Modal.Content useCustomOverlay>
        <Formik
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validateOnMount={false}
        >
          {({ submitForm }) => {
            return (
              <>
                <Modal.Header>
                  <Modal.Title>Registration Details</Modal.Title>
                  <Modal.Close />
                </Modal.Header>
                <Modal.Body>
                  <Form className="t-m-0 t-space-y-4">
                    {fields
                      .toSorted((a, b) => a.order - b.order)
                      .map((field, i) => (
                        <div key={i}>
                          <FieldBuilder field={field} />
                        </div>
                      ))}
                  </Form>
                </Modal.Body>
                <Modal.FooterButtonGroup>
                  <Modal.RawClose asChild>
                    <Button type="button">Cancel</Button>
                  </Modal.RawClose>
                  <Button
                    customType="primary"
                    onClick={submitForm}
                    isLoading={isUpdatingForm}
                    disabled={isUpdatingForm}
                  >
                    Save
                  </Button>
                </Modal.FooterButtonGroup>
              </>
            );
          }}
        </Formik>
      </Modal.Content>
    </Modal.Root>
  );
};
