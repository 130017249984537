import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import { Form, Formik, FormikValues } from "formik";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import ReactCountryFlag from "react-country-flag";
import { US } from "constants/countryCodes";
import Modal from "components/DesignSystem/Modal/Modal";
import { Button } from "components/DesignSystem/Button/Button";
import { object, string } from "yup";

export const EntitySelectForWform = ({
  isOpen,
  close,
  onSubmit,
}: {
  isOpen: boolean;
  close: () => void;
  onSubmit: (entityId: FormikValues) => void;
}) => {
  const { entities } = useCurrentGroupContext();
  const entityOptions = entities
    .filter(({ country_code }) => country_code === US)
    .map(({ name, uuid, country, country_code }) => ({
      label: (
        <div className="t-flex t-items-center t-gap-2 group-[[data-disabled]]:t-text-neutral-30">
          <ReactCountryFlag countryCode={country_code} svg title={country} />
          <span className="t-truncate t-max-w-36">{name}</span>
        </div>
      ),
      value: uuid,
    }));

  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Modal.Content useCustomOverlay>
        <Formik
          initialValues={{ entity: "" }}
          onSubmit={onSubmit}
          validateOnChange
          validateOnMount
          validationSchema={() =>
            object({
              entity: string().min(1).required("Please select an entity"),
            })
          }
        >
          {({ submitForm, values, isSubmitting, isValid }) => {
            const selectedEntity = entityOptions.find(
              ({ value }) => value == values.entity
            );

            return (
              <>
                <Modal.Header>
                  <Modal.Title>Generate W-Form</Modal.Title>
                  <Modal.Close onClick={close} />
                </Modal.Header>
                <Modal.Body>
                  <Form className="t-m-0 t-space-y-3">
                    <Combobox
                      label="Entity"
                      menuPlacement="auto"
                      withForm
                      name="entity"
                      menuPortalTarget={document.body}
                      tooltip
                      creatable={false}
                      isClearable={false}
                      options={entityOptions}
                      value={selectedEntity}
                      required
                    />
                  </Form>
                </Modal.Body>
                <Modal.FooterButtonGroup>
                  <Modal.RawClose asChild>
                    <Button type="button">Cancel</Button>
                  </Modal.RawClose>
                  <Button
                    onClick={submitForm}
                    customType="primary"
                    type="submit"
                    isLoading={isSubmitting}
                    disabled={isSubmitting || !isValid}
                  >
                    Continue
                  </Button>
                </Modal.FooterButtonGroup>
              </>
            );
          }}
        </Formik>
      </Modal.Content>
    </Modal.Root>
  );
};
