/* eslint-disable no-unused-vars */
import { Button } from "components/DesignSystem/Button/Button";
import { SelectDropDown } from "components/DesignSystem/SelectDropDown/SelectDropDown";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import Modal from "components/DesignSystem/Modal/Modal";
import { Form, Formik } from "formik";
import { entityDetails } from "formValidations/salesCrm";
import { useToast } from "hooks/useToast";
import { useEffect, useState } from "react";
import { getCountriesData } from "../../utils/countriesAPIResponse";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";

const AddEntity = ({
  addEntityData,
  showAddEntity,
  setShowAddEntity,
  showEntityTypeOption = false,
  isCreating = false,
}) => {
  const [data, setData] = useState({});
  const { alertToast } = useToast();
  const [countries, setCountries] = useState([]);
  const getCounties = async () => {
    try {
      const response = await getCountriesData();
      setData(response);
      setCountries(response.data);
    } catch (e) {
      alertToast({ message: e?.response?.data?.error?.message });
    }
  };

  useEffect(() => {
    getCounties();
  }, []);

  const entityTypeOptions = [
    { label: "Taxed Entity", value: "TAXED_ENTITY" },
    { label: "Shareholder Entity", value: "SHAREHOLDER_ENTITY" },
  ];

  return (
    <div className="addCompanyEntityContent">
      <Modal.Root
        open={showAddEntity}
        onOpenChange={() => setShowAddEntity(false)}
      >
        <Modal.Content useCustomOverlay>
          <Formik
            initialValues={{
              company_name: "",
              formation_type_id: "",
              country: "",
              state_id: "",
              entity_data: [],
              entity_type: "TAXED_ENTITY",
            }}
            validationSchema={entityDetails}
            onSubmit={addEntityData}
          >
            {({ setFieldValue, submitForm, values }) => {
              const selectedCountry = countries?.find(
                ({ uuid }) => uuid === values.country
              );
              const selectedState = selectedCountry?.states.find(
                (state) => state.uuid === values.state_id
              );

              const selectedEntityType = entityTypeOptions.find(
                (option) => option.value === values.entity_type
              );

              const selectedFormation = selectedCountry?.formation_type.find(
                (formationType) =>
                  formationType.uuid === values.formation_type_id
              );

              return (
                <>
                  <Modal.Header>
                    <Modal.Title>Add Entity</Modal.Title>
                    <Modal.Close onClick={() => setShowAddEntity(false)} />
                  </Modal.Header>
                  <Modal.Body>
                    <Form className="t-m-0 t-space-y-4">
                      <TextInput
                        block
                        name="company_name"
                        label="Legal entity name"
                        placeholder="Eg. Acme Inc"
                        required
                      />
                      <Combobox
                        label="Select country"
                        placeholder="Select"
                        withForm
                        components={{ ClearIndicator: () => null }}
                        options={countries?.map((country) => ({
                          value: country.uuid,
                          label: country.name,
                        }))}
                        name="country"
                        required
                        value={
                          selectedCountry && {
                            label: selectedCountry.name,
                            value: selectedCountry.uuid,
                          }
                        }
                        onChange={(values) => {
                          setFieldValue("country", values.value);
                        }}
                        menuPortalTarget={document.body}
                      />
                      <Combobox
                        label="Select state"
                        placeholder="Select"
                        withForm
                        components={{ ClearIndicator: () => null }}
                        options={selectedCountry?.states.map((state) => ({
                          value: state.uuid,
                          label: state.name,
                        }))}
                        name="state_id"
                        required
                        value={
                          selectedState && {
                            label: selectedState.name,
                            value: selectedState.uuid,
                          }
                        }
                        onChange={(values) => {
                          setFieldValue("state_id", values.value);
                        }}
                        menuPortalTarget={document.body}
                      />
                      <Combobox
                        name="formation_type_id"
                        label="Select formation"
                        placeholder="Select"
                        withForm
                        components={{ ClearIndicator: () => null }}
                        options={selectedCountry?.formation_type.map(
                          (formation) => ({
                            value: formation.uuid,
                            label: formation.name,
                          })
                        )}
                        required
                        value={
                          selectedFormation && {
                            label: selectedFormation.name,
                            value: selectedFormation.uuid,
                          }
                        }
                        onChange={(values) => {
                          setFieldValue("formation_type_id", values.value);
                        }}
                        menuPortalTarget={document.body}
                      />
                      {showEntityTypeOption && (
                        <Combobox
                          name="entity_type"
                          label="Select entity type"
                          placeholder="Select"
                          withForm
                          components={{ ClearIndicator: () => null }}
                          options={entityTypeOptions}
                          value={selectedEntityType}
                          onChange={(values) => {
                            setFieldValue("entity_type", values.value);
                          }}
                          menuPortalTarget={document.body}
                        />
                      )}
                    </Form>
                  </Modal.Body>
                  <Modal.FooterButtonGroup>
                    <Modal.RawClose asChild>
                      <Button type="button" disabled={isCreating}>
                        Cancel
                      </Button>
                    </Modal.RawClose>
                    <Button
                      isLoading={isCreating}
                      customType="primary"
                      size="regular"
                      disabled={isCreating}
                      onClick={submitForm}
                    >
                      Create
                    </Button>
                  </Modal.FooterButtonGroup>
                </>
              );
            }}
          </Formik>
        </Modal.Content>
      </Modal.Root>
    </div>
  );
};

export default AddEntity;
