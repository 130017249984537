import * as Popover from "@radix-ui/react-popover";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useCallback, useState } from "react";
import { setOpenComment } from "store/slices/openComment";

export const AddCommentPopoverRoot = ({
  contentId,
  children,
}: {
  contentId: string;
  children: React.ReactNode;
}) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);

  const setActiveComment = useCallback(
    (comment: string | null) => dispatch(setOpenComment(comment)),
    [dispatch]
  );

  const onOpenChange = useCallback(
    (open: boolean) => {
      setOpen(open);
      setActiveComment(open ? contentId : null);
    },
    [contentId, setActiveComment]
  );

  return (
    <Popover.Root open={open} onOpenChange={onOpenChange}>
      {children}
    </Popover.Root>
  );
};
