import { Button } from "components/DesignSystem/Button/Button";
import { HighlightSearchTerm } from "components/DesignSystem/HighlightText";
import { Cart } from "components/icons/Cart";
import { ProductCard } from "components/ProductCard/ProductCard";
import { AddonPricing, GetDescription } from "components/SubscriptionSummary";
import {
  BOOKKEEPING_SOFTWARE,
  REGISTERED_AGENT,
  _MANAGE,
} from "constants/addons";
import { useQuery } from "hooks/useQuery";
import { useDispatch } from "react-redux";
import { AddonData, setAddonData, setAddonType } from "store/slices/addons";
import { toggleCartSubscription } from "store/slices/cartSubscription";
import { Subscription } from "types/Models/subscription";

export const SubscriptionTypeCard = ({
  subscription,
  isPrimarySubscription,
}: {
  subscription: Subscription;
  isPrimarySubscription?: boolean;
}) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const search = query.get("search") || "";

  const onSubscribeHandler = (addon: AddonData, useCart: boolean = false) => {
    dispatch(toggleCartSubscription(useCart));
    dispatch(setAddonData(addon));
    dispatch(setAddonType(addon?.subscription_type!));
  };

  const onManageHandler = (addon: AddonData) => {
    dispatch(setAddonData(addon));
    dispatch(setAddonType(addon?.subscription_type + _MANAGE));
  };

  const isSubscribed =
    subscription.subscription_type !== "REGISTERED_AGENT" &&
    subscription.group_subscription;

  let minAmount =
    subscription.ra_state_amount_maps?.reduce(
      (ac, cv) => Math.min(Number(ac), Number(cv)),
      Infinity
    ) || subscription.amount;

  return (
    <ProductCard
      key={subscription.uuid}
      isPrimarySubscription={isPrimarySubscription}
      title={
        <HighlightSearchTerm
          text={subscription.subscription_name}
          searchTerm={search}
        />
      }
      description={
        subscription.subscription_type === "REGISTERED_AGENT"
          ? "Registered Agent service for all states of the United States of America"
          : !subscription.stripe_coupon && (
              <GetDescription
                type={subscription.subscription_type}
                subscriptions={subscription.group_subscription}
              />
            )
      }
      price={
        <AddonPricing
          addon={
            subscription.subscription_type !== "REGISTERED_AGENT"
              ? subscription
              : { ...subscription, amount: minAmount }
          }
        />
      }
      action={
        isSubscribed ? (
          <div className="t-flex t-gap-1">
            <Button size="small" onClick={() => onManageHandler(subscription)}>
              Manage plan
            </Button>
            <div>
              {[REGISTERED_AGENT, BOOKKEEPING_SOFTWARE].includes(
                subscription.subscription_type
              ) && (
                <Button
                  customType="icon"
                  size="small"
                  onClick={() => onSubscribeHandler(subscription, true)}
                >
                  <Cart />
                </Button>
              )}
            </div>
          </div>
        ) : (
          <div className="t-flex t-gap-1">
            <Button
              size="small"
              onClick={() => onSubscribeHandler(subscription, false)}
            >
              Subscribe
            </Button>
            <Button
              customType="icon"
              size="small"
              onClick={() => onSubscribeHandler(subscription, true)}
            >
              <Cart />
            </Button>
          </div>
        )
      }
      planStatus={Boolean(isSubscribed) ? "ACTIVE" : undefined}
      isSubscription
    />
  );
};
