import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { TransactionsBankSelector } from "components/Transaction/AddTransaction/TransactionsBankSelector";
import { useModal } from "hooks/useModal";
import { ModalProps } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setManualTransactionAdditionInvoice } from "store/slices/linkInkleInvoice";
import { LinkInvoiceToTransactionModal } from "./LinkInvoiceToTransactionModal";

export const LinkTransactionViaModal = ({
  isOpen,
  close,
  invoiceNumber,
  invoiceDueAmount,
  invoiceId,
}: ModalProps & {
  invoiceNumber: string;
  invoiceDueAmount: number;
  invoiceId: string;
}) => {
  const dispatch = useDispatch();
  const addManuallyTxnModal = useModal();
  const linkExistingTransactionModal = useModal();

  const openAddManuallyModal = () => {
    dispatch(
      setManualTransactionAdditionInvoice({ invoiceId, invoiceDueAmount })
    );
    addManuallyTxnModal.open();
    close();
  };

  const openLinkTransactionModal = () => {
    linkExistingTransactionModal.open();
    close();
  };

  return (
    <>
      <Modal.Root open={isOpen} onOpenChange={close}>
        <Modal.Content size="large">
          <Modal.Header>
            <Modal.Title>Mark as paid</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            <div className="t-flex t-flex-col t-align-middle t-gap-6 t-py-8">
              <Button block size="large" onClick={openLinkTransactionModal}>
                Link to an existing transaction
              </Button>
              <span className="t-w-full t-flex t-justify-center t-text-body">
                or
              </span>
              <Button block size="large" onClick={openAddManuallyModal}>
                Add transaction manually
              </Button>
            </div>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>

      <TransactionsBankSelector
        isOpen={addManuallyTxnModal.isOpen}
        close={addManuallyTxnModal.close}
        onBack={addManuallyTxnModal.close}
      />

      {linkExistingTransactionModal.isOpen && (
        <LinkInvoiceToTransactionModal
          invoiceId={invoiceId}
          invoiceDueAmount={invoiceDueAmount}
          invoiceNumber={invoiceNumber}
          isOpen={linkExistingTransactionModal.isOpen}
          close={linkExistingTransactionModal.close}
        />
      )}
    </>
  );
};
