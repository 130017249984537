import { INKLE_TEAM_AND_CPA_TEAM } from "constants/chatType";
import { emptyApi } from "./emptyApi";
import qs from "qs";
import { Task } from "types/Models/task";
import { Pagination } from "types/Models/pagination";
import dayjs from "dayjs";
import { YYYY_MM_DD } from "constants/date";

type ServiceTeam = {
  image_url: string;
  name: string;
  uuid: string;
};

type DomainResponse = {
  name: string;
  uuid: string;
  status: string;
};

const extendedApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getServiceTeam: build.query<
      ServiceTeam[],
      {
        accessible_teams?: typeof INKLE_TEAM_AND_CPA_TEAM | null;
        serviceTeamId?: string | null;
      }
    >({
      query: ({ accessible_teams, serviceTeamId }) => {
        let queryUrl = qs.stringify(
          {
            accessible_teams: accessible_teams,
            service_team_ids: serviceTeamId,
          },
          { skipNulls: true }
        );
        return {
          url: `/api/inkle/serviceteams/get/?${queryUrl}`,
        };
      },
    }),

    createServiceTeam: build.mutation({
      query: ({ payload }) => {
        return {
          url: `/api/inkle/serviceteams/create/`,
          method: "POST",
          body: payload,
        };
      },
    }),

    assignMonthlySummary: build.mutation({
      query: ({ profileId, monthlyTxnSummaryId }) => {
        return {
          url: `/api/inkle/bookkeeping/monthly_txn_summary/${monthlyTxnSummaryId}/assignee/`,
          method: "PUT",
          body: { profile_id: profileId },
        };
      },
      invalidatesTags: (result) => {
        return result ? [{ type: "BOOKS_CRM" }] : [];
      },
    }),

    joinServiceTeam: build.mutation({
      query: ({ groupId }) => {
        return {
          url: `/api/inkle/serviceteams/team/${groupId}/join/`,
          method: "POST",
        };
      },
    }),
    getDomainRecognization: build.query<DomainResponse, void>({
      query: () => "/api/inkle/company/group/service_team/join/",
    }),

    getAllServiceProviderTask: build.query<
      { tasks: Task[] } & Pagination & { download_url?: string | null },
      {
        page_num?: number;
        search_term?: string | null;
        task_state_filter_uuids: string | null;
        task_template_filter_uuids: string | null;
        task_tag_filter_uuids: string | null;
        company_tag_filter_uuids: string | null;
        assignee_uuids: string | null;
        task_sort_by: string | null;
        subscriptionFilter: string | null;
        generate_report: boolean;
        task_list_type?: string | null;
        preparer_ids?: string | null;
        etd_type?: string | null;
        service_team_ids?: string | null;
        completed_start_date?: string | null;
        completed_end_date?: string | null;
        show_unassigned_team?: string | null;
        show_unassigned_preparer?: string | null;
      }
    >({
      query: ({
        page_num,
        search_term,
        task_state_filter_uuids,
        task_template_filter_uuids,
        task_tag_filter_uuids,
        company_tag_filter_uuids,
        assignee_uuids,
        task_sort_by,
        subscriptionFilter,
        generate_report,
        task_list_type,
        service_team_ids,
        preparer_ids,
        etd_type,
        completed_end_date,
        completed_start_date,
        show_unassigned_team,
        show_unassigned_preparer,
      }) => {
        let queryUrl = qs.stringify(
          {
            page_num,
            search_term,
            task_state_filter_uuids,
            task_template_filter_uuids,
            task_tag_filter_uuids,
            company_tag_filter_uuids,
            assignee_uuids,
            task_sort_by,
            subscription_filter: subscriptionFilter,
            generate_report,
            task_list_type,
            service_team_ids,
            preparer_ids,
            etd_type,
            show_unassigned_team,
            show_unassigned_preparer,
            completed_end_date: completed_end_date
              ? dayjs(completed_end_date).format(YYYY_MM_DD)
              : null,
            completed_start_date: completed_start_date
              ? dayjs(completed_start_date).format(YYYY_MM_DD)
              : null,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `api/inkle/tasks/v1/provider/tasks/${queryUrl}`,
        };
      },
      providesTags: (result) => (result ? ["All_SERVICE_PROVIDERS"] : []),
    }),

    updateTaskAssigneeAdmin: build.mutation<
      {},
      {
        taskId: string;
        preparerId?: string | null;
        removePreparer?: boolean;
        serviceTeamId?: string;
        removeServiceTeam?: boolean;
        estimatedDeliveryTime?: string;
        reason?: string;
      }
    >({
      query: ({
        taskId,
        preparerId,
        removePreparer,
        serviceTeamId,
        removeServiceTeam,
        estimatedDeliveryTime,
        reason,
      }) => {
        const payload = {
          preparer_id: preparerId,
          remove_preparer: removePreparer,
          service_team_id: serviceTeamId,
          remove_service_team: removeServiceTeam,
          estimated_delivery_time: estimatedDeliveryTime,
          reason,
        };

        return {
          url: `/api/inkle/tasks/v1/${taskId}/update/admin/`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: (result) =>
        result ? ["All_SERVICE_PROVIDERS", "Tasks", "TaskList"] : [],
    }),

    updateTaskAssigneeProvider: build.mutation<
      {},
      {
        taskId: string;
        preparerId?: string | null;
        removePreparer?: boolean;
        estimatedDeliveryTime?: string;
        reason?: string;
      }
    >({
      query: ({
        taskId,
        preparerId,
        removePreparer,
        estimatedDeliveryTime,
        reason,
      }) => {
        const payload = {
          preparer_id: preparerId,
          remove_preparer: removePreparer,
          estimated_delivery_time: estimatedDeliveryTime,
          reason,
        };
        return {
          url: `/api/inkle/tasks/v1/${taskId}/update/provider/`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: (result) =>
        result ? ["All_SERVICE_PROVIDERS", { type: "Tasks" }] : [],
    }),
    getAllCpaTeams: build.query<ServiceTeam[], {}>({
      query: () => {
        return {
          url: `/api/inkle/serviceteams/all_cpa_team_members/`,
          method: "GET",
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetServiceTeamQuery,
  useCreateServiceTeamMutation,
  useJoinServiceTeamMutation,
  useAssignMonthlySummaryMutation,
  useGetDomainRecognizationQuery,
  useLazyGetServiceTeamQuery,
  useUpdateTaskAssigneeAdminMutation,
  useUpdateTaskAssigneeProviderMutation,
  useGetAllServiceProviderTaskQuery,
  useLazyGetAllServiceProviderTaskQuery,
  useGetAllCpaTeamsQuery,
} = extendedApi;
