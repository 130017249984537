import { Button } from "components/DesignSystem/Button/Button";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import Modal from "components/DesignSystem/Modal/Modal";
import RadioGroup from "components/DesignSystem/RadioGroup/RadioGroup";
import { MigrateToDirectConnection } from "components/Illustrations/MigrateToDirectConnection";
import { YYYY_MM_DD } from "constants/date";
import { Formik, Form, Field, FieldProps } from "formik";
import { dataSourceDirectMigration } from "formValidations/dataSourceDirectMigration";
import { useBankConnect } from "hooks/useBankConnect";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useGetAllConnectionsQuery } from "store/apis/booksConnections";
import { TRANSACTION_PULL_TYPE } from "types/contants/transactionPullTypes";

export const PlaidToDirectMigration = ({
  onClose,
  connectionId,
  entityId,
}: {
  connectionId: string;
  onClose: () => void;
  entityId: string;
}) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const { isCpa, isForeignCA } = useRoleBasedView();

  const { data: connections } = useGetAllConnectionsQuery(
    {
      groupId: groupId!,
      entityId: entityId!,
    },
    { skip: !groupId || !entityId }
  );
  const currentConnection = connections?.find(
    (connection) => connection.uuid === connectionId
  );

  const { onConnect, isLoading } = useBankConnect();

  const onSubmit = async ({
    replaceType,
    customDate,
  }: {
    replaceType: TRANSACTION_PULL_TYPE;
    customDate: string;
  }) => {
    try {
      await onConnect({
        connectionId,
        state: {
          txn_pull_choice: replaceType,
          txn_pull_date: customDate,
        },
        invokedFrom:
          isCpa || isForeignCA
            ? `/books/data-sources?company=${groupId}&entity=${entityId}`
            : `/books/data-sources?entity=${entityId}`,
      });

      onClose();
    } catch (error) {}
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        replaceType: "NA",
        customDate: "",
      }}
      validationSchema={dataSourceDirectMigration}
    >
      {({ values, submitForm, setFieldValue, setFieldTouched, errors }) => (
        <Form>
          <Modal.Header>
            <Modal.Title>
              Connect {currentConnection?.name} Directly
            </Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body className="t-flex t-flex-col t-gap-4">
            <MigrateToDirectConnection />

            <div className="t-text-body">
              <p className="t-mb-6 t-text-subtitle-sm">
                Do you also want to replace your past transactions?
              </p>

              <RadioGroup.Root
                value={values.replaceType}
                onValueChange={(v) => {
                  setFieldValue("replaceType", v);
                  setFieldTouched("replaceType", true);
                }}
              >
                <div className="t-flex t-flex-col t-gap-4">
                  <RadioGroup.Item value="NA">No</RadioGroup.Item>
                  <RadioGroup.Item value="REPLACE_UNCATEGORIZED">
                    Yes, replace only uncategorised transactions
                  </RadioGroup.Item>
                  <RadioGroup.Item value="REPLACE_ALL">
                    Yes, replace all transactions
                  </RadioGroup.Item>
                  <RadioGroup.Item value="CUSTOM">
                    Yes, replace all transactions since a custom date
                  </RadioGroup.Item>
                </div>
              </RadioGroup.Root>
            </div>

            {values.replaceType === "CUSTOM" && (
              <Field name="customDate">
                {({ field }: FieldProps) => {
                  return (
                    <DateInput
                      {...field}
                      portalId={field.name}
                      saveFormat={YYYY_MM_DD}
                      maxDate={new Date()}
                      label="Select custom date"
                    />
                  );
                }}
              </Field>
            )}
          </Modal.Body>
          <Modal.FooterButtonGroup>
            <Modal.RawClose asChild>
              <Button>Cancel</Button>
            </Modal.RawClose>
            <Button
              customType="primary"
              onClick={submitForm}
              isLoading={isLoading}
              disabled={isLoading}
            >
              Connect
            </Button>
          </Modal.FooterButtonGroup>
        </Form>
      )}
    </Formik>
  );
};
