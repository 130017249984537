import Loader from "components/design/loader";
import { TableUI } from "components/design/TableUI";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { Tag as TagComponent } from "components/DesignSystem/Tag/Tag";
import { CallFromChatIcon } from "components/icons/Chat/CallFromChatIcon";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import React from "react";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import { useGetTaskFromChannelIdQuery } from "store/apis/task";
import { GroupUser, useGetTeamMembersQuery } from "store/apis/teamSetting";
import { useChannelStateContext } from "stream-chat-react";
import { CallNotes } from "./CallNotes";
import Tags from "components/crm/teamMemberTags";
import { currentTimeZoneTime } from "utils/currentTimeZoneTime";

export const AdminCall = () => {
  const { channel } = useChannelStateContext();
  const channelId = channel.id || "";
  const callModal = useModal();

  const { uuid: groupUuid } = useCurrentGroupContext();
  const { data: channelTask, isLoading: isChannelLoading } =
    useGetTaskFromChannelIdQuery(
      {
        channelId: channelId,
      },
      {
        skip: !channelId,
        refetchOnMountOrArgChange: true,
      }
    );
  const { company_group: companyGroup } = channelTask || {};
  const groupId = companyGroup?.uuid || groupUuid;

  const { data: teamMembers, isLoading } = useGetTeamMembersQuery(
    { groupId },
    { skip: !groupId, refetchOnMountOrArgChange: true }
  );

  const tableData = React.useMemo(
    () => teamMembers?.group_users.map((user, i) => user) || [],
    [teamMembers?.group_users]
  );

  const columnHelper = createColumnHelper<GroupUser>();

  const columns = [
    columnHelper.accessor("name", {
      header: "USER",
      size: 20,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("role_name", {
      header: "ROLE",
      size: 15,
      cell: (info) => {
        return <div>{info.getValue()}</div>;
      },
    }),
    columnHelper.accessor("tags", {
      header: "TAGS",
      size: 20,
      cell: (info) => {
        return (
          <Tags
            currentTags={info.getValue()}
            refetch={() => {}}
            tags={info.getValue()}
            uuid={""}
          />
        );
      },
    }),
    columnHelper.accessor("current_timezone", {
      header: "CURRENT TIME",
      size: 20,
      cell: (info) => {
        const { time, zone } = currentTimeZoneTime(info.getValue());

        return (
          <span className="t-text-text-100 t-text-body">
            {time} ({zone})
          </span>
        );
      },
    }),
    columnHelper.accessor("mobile", {
      header: "PHONE NUMBER",
      size: 25,
      cell: (info) => {
        const rowData = info.row.original;

        return (
          <div className="t-space-y-3">
            {rowData.mobile && (
              <div className="t-flex t-items-center t-justify-between">
                <div>
                  <div className="t-text-text-30 t-text-body-sm">Primary</div>
                  <div className="t-text-text-100 t-text-body">
                    {rowData.mobile}
                  </div>
                </div>
                <CallNotes
                  mobile={rowData.mobile}
                  groupId={rowData.uuid}
                  close={callModal.close}
                />
              </div>
            )}
            {rowData.secondary_mobile && (
              <div className="t-flex t-items-center t-justify-between">
                <div>
                  <div className="t-text-text-30 t-text-body-sm">Secondary</div>
                  <div className="t-text-text-100 t-text-body">
                    {rowData.secondary_mobile}
                  </div>
                </div>
                <CallNotes
                  mobile={rowData.secondary_mobile}
                  groupId={rowData.uuid}
                  close={callModal.close}
                />
              </div>
            )}
          </div>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 1,
      maxSize: 100,
    },
  });

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Modal.Root open={callModal.isOpen} onOpenChange={callModal.toggle}>
        <Modal.Trigger asChild>
          <div className="-t-mb-1">
            <Button customType="transparent" size="small">
              <CallFromChatIcon />
            </Button>
          </div>
        </Modal.Trigger>
        <Modal.Content size="xl">
          <Modal.Header>
            <Modal.Title>Customer list </Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            {isLoading || isChannelLoading ? (
              <Loader />
            ) : (
              <TableUI table={table} />
            )}
          </Modal.Body>
          <Modal.FooterButtonGroup>
            <Button customType="primary" onClick={callModal.close}>
              Close
            </Button>
          </Modal.FooterButtonGroup>
        </Modal.Content>
      </Modal.Root>
    </div>
  );
};
