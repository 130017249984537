import PastQuotesTable from "components/billing/PastQuotesTable";
import { Calendar } from "components/calendar/Calendar";
import { Button } from "components/DesignSystem/Button/Button";
import Tab from "components/DesignSystem/Tab/Tab";
import { SendQuoteModal } from "components/SendQuoteModal/SendQuoteModal";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { CalendarYearSelector } from "pages/Calender/CalendarYearSelector";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  useLazyGenerateQuoteSheetLinkQuery,
  useSendQuoteMutation,
} from "store/apis/calendar";
import { RootState } from "store/store";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { EntitySelector } from "components/EntitySelector/EntitySelector";

export const CrmQuotes = () => {
  const { uuid: groupId, team } = useCurrentGroupContext();
  const [quoteModalOpen, setQuoteModal] = useState(false);
  const [tab, changeTab] = useState("DEADLINES");
  const entityId = useCurrentEntityId();

  const calendarYear = useSelector((state: RootState) => state.calendar.year);
  const [sendQuote, { isLoading: isSendingQuote }] = useSendQuoteMutation();
  const [
    generateQuoteLink,
    { isLoading: isGeneratingLink, isFetching: isGeneratingLinkAgain },
  ] = useLazyGenerateQuoteSheetLinkQuery();

  const onSubmitSendQuote = async ({
    file,
    recipients,
  }: {
    file: File;
    recipients: string;
  }) => {
    await sendQuote({ groupId, file, recipients, entityId });

    setQuoteModal(false);
  };

  const onGenerateLink = async () => {
    try {
      const { quote_link } = await generateQuoteLink({
        groupId,
        calendarYear,
        entityId,
      }).unwrap();
      if (quote_link) {
        window.open(quote_link);
      }
    } catch (error) {}
  };

  return (
    <>
      <div className="t-flex t-flex-col t-h-full">
        <div className="t-m-0 t-grow">
          <Tab.Root value={tab} onValueChange={(v) => changeTab(v)}>
            <div className="t-flex t-items-center t-justify-between t-pr-8">
              <Tab.List>
                <span className="t-flex t-gap-3 t-px-7 t-pt-5">
                  <Tab.Trigger value="DEADLINES" asChild customType="secondary">
                    <span>Current deadlines</span>
                  </Tab.Trigger>
                  <Tab.Trigger value="QUOTES" asChild customType="secondary">
                    <span>Past Quotes</span>
                  </Tab.Trigger>
                </span>
              </Tab.List>
              {tab === "DEADLINES" && (
                <div className="t-flex gap-4 t-self-end">
                  <EntitySelector showOnlyUSEntities />
                  <CalendarYearSelector />
                </div>
              )}
            </div>
            <Tab.Content value="DEADLINES">
              <div className="t-w-full">
                <Calendar groupId={groupId} bottomBar={<></>} />
              </div>
            </Tab.Content>
            <Tab.Content value="QUOTES">
              <div className="t-p-7">
                <PastQuotesTable />
              </div>
            </Tab.Content>
          </Tab.Root>
        </div>
        <div className="t-mb-auto">
          <div className="t-hidden t-w-full t-items-center t-bg-surface-lighter-grey md:t-flex">
            <div className="t-flex t-w-full t-justify-between t-py-4 t-pl-5 t-pr-7">
              <div />
              <div className="t-mr-20 t-flex t-gap-4">
                <Button size="small" onClick={() => setQuoteModal(true)}>
                  Send quote
                </Button>
                <Button
                  isLoading={isGeneratingLink || isGeneratingLinkAgain}
                  disabled={isGeneratingLink || isGeneratingLinkAgain}
                  customType="primary"
                  size="small"
                  onClick={onGenerateLink}
                >
                  Generate quote
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SendQuoteModal
        isSubmiting={isSendingQuote}
        members={team}
        isOpen={quoteModalOpen}
        setIsOpen={setQuoteModal}
        onSendQuote={onSubmitSendQuote}
      />
    </>
  );
};
