import ToolTip from "components/design/toolTip";
import { Button } from "components/DesignSystem/Button/Button";
import GreyInfo from "static/images/InfoGray.svg";
import { ReactNode } from "react";
import ActiveStateIcon from "static/images/RegisteredStateIcon.svg";
import InactiveStateIcon from "static/images/NotRegisteredStateIcon.svg";
import FilledClockCountDown from "static/images/FilledClockCountDown.svg";
import classNames from "classnames";

const PlanStatusTag = ({
  status,
}: {
  status: "ACTIVE" | "OVERDUE" | "INACTIVE";
}) => {
  switch (status) {
    case "ACTIVE":
      return (
        <div className="t-flex t-gap-1 t-px-2 t-py-1 t-rounded t-bg-white t-h-6 t-items-center">
          <img src={ActiveStateIcon} alt="ActiveStateIcon" className="t-h-4" />
          <div className="t-text-body t-text-text-60">Active</div>
        </div>
      );
    case "INACTIVE":
      return (
        <div className="t-flex t-gap-1 t-px-2 t-py-1 t-rounded t-bg-white t-h-6 t-items-center">
          <img
            src={InactiveStateIcon}
            alt="InactiveStateIcon"
            className="t-h-4"
          />
          <div className="t-text-body t-text-text-60">Inactive</div>
        </div>
      );
    case "OVERDUE":
      return (
        <div className="t-flex t-gap-1 t-px-2 t-py-1 t-rounded t-bg-white t-h-6 t-items-center">
          <img src={FilledClockCountDown} alt="FilledClockCountDown" />
          <div className="t-text-body t-text-text-60">Overdue</div>
        </div>
      );
    default:
      return (
        <div className="t-flex t-gap-1 t-px-2 t-py-1 t-rounded t-bg-white t-h-6 t-items-center">
          <img
            src={InactiveStateIcon}
            alt="InactiveStateIcon"
            className="t-h-4"
          />
          <div className="t-text-body t-text-text-60">Inactive</div>
        </div>
      );
  }
};

export const ProductCard = ({
  title,
  description,
  price,
  action,
  planStatus,
  isPrimarySubscription,
  customAction,
  isSubscription,
}: {
  title: ReactNode;
  description?: ReactNode;
  price: ReactNode;
  action: ReactNode;
  planStatus?: "ACTIVE" | "OVERDUE" | "INACTIVE";
  isPrimarySubscription?: boolean;
  customAction?: boolean;
  isSubscription?: boolean;
}) => (
  <div
    className={classNames(
      "t-rounded-lg t-border t-border-solid t-border-neutral-0 t-pt-3 t-pb-3 t-pl-4 t-pr-3 t-flex t-flex-col t-gap-4 t-justify-between t-text-text-60 t-min-h-40 t-w-[360px]",
      {
        "t-bg-surface-lighter-grey": !isPrimarySubscription,
        "t-bg-purple-0": isPrimarySubscription,
      }
    )}
  >
    <div className="t-flex t-flex-col t-gap-1">
      <div className="t-flex t-justify-between t-items-start">
        <div className="t-text-subtitle t-max-w-[224px]">{title}</div>
        {isSubscription ? (
          planStatus && <PlanStatusTag status={planStatus} />
        ) : (
          <Button customType="ghost_icon" size="small">
            {description && (
              <ToolTip
                text={description}
                propClass="t-text-white t-max-w-80 t-whitespace-normal !t-break-normal"
              >
                <span>
                  <img className="t-cursor-pointer" src={GreyInfo} alt="Info" />
                </span>
              </ToolTip>
            )}
          </Button>
        )}
      </div>
      {price}
    </div>
    {customAction ? (
      action
    ) : (
      <div className="t-flex t-items-end t-gap-3 t-justify-end">
        <div className="t-shrink-0 t-justify-self-endt-grow">{action}</div>
      </div>
    )}
  </div>
);
