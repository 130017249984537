import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import Toast from "components/DesignSystem/Toast/Toast";
import { StartBookkeepingDateEmptyScreen } from "components/GeneralLedger/GeneralLedgerEmptyScreen";
import { ConditionalLink } from "components/conditionalLink";

import { MoneyInTransitBanner } from "components/MoneyTransitBanner/MoneyInTransitBanner";
import { TransactionSlider } from "components/Transaction/Slider/TransactionSlider";
import { Redirect } from "components/icons/Redirect";
import {
  DOWNLOAD_EXCEL,
  DOWNLOAD_PDF,
  SAVED_REPORTS,
} from "constants/bookkeeping";
import { REPORT_TYPE } from "dictionaries";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { usePageTitle } from "hooks/usePageTitle";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { ReportsType } from "pages/Books/Reports/Reports";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useGetMoneyInTransitQuery } from "store/apis/generalLedger";
import { useLazyGetPreviewUrlQuery } from "store/apis/previewUrl";
import {
  useLazyNewInteractiveReportQuery,
  useNewInteractiveReportQuery,
  useSaveInteractiveReportMutation,
  ViewByOption,
} from "store/apis/reports";
import { getFilterStatus } from "store/selector/reportFilter";
import { BackendError } from "types/utils/error";
import { openLink } from "utils/openLink";
import { redirectToCategories } from "utils/redirectToCategories";
import { AddReportToClosingModal } from "./AddReportToClosingModal";
import { ReportFilter } from "./ReportFilter";
import ReportsAccordion from "./ReportsAccordion";

import { ArrowDown } from "components/icons/ArrowDown";
import { useAppSelector } from "hooks/useAppSelector";
import { ComparisonReport } from "./ComparisonReport";
import ConditionalToolTip from "components/design/conditionalToolTip";
import { CommentsSlider } from "components/CommentsSlider/CommentsSlider";
import classNames from "classnames";
import { AnimatePresence } from "framer-motion";
import { SliderAnimation } from "components/SliderAnimation/SliderAnimation";
import { ChatTeardropDots } from "components/icons/ChatTeardropDots";
import * as Popover from "@radix-ui/react-popover";
import ThreeDots from "static/images/ThreeDots.svg";
import { AddCommentPop } from "components/AddComment/AddComment";
import { stopPropagation } from "utils/stopPropagation";
import { useAppDispatch } from "hooks/useAppDispatch";
import { setOpenComment } from "store/slices/openComment";
import { NoteResponse } from "store/apis/notes";
import { AddCommentPopoverRoot } from "components/AddCommentPopoverRoot/AddCommentPopoverRoot";
import { AddCommentAnchor } from "components/AddCommentPopoverAnchor/AddCommentPopoverAnchor";

export const ReportDownload = ({
  reportType,
  isTotalView,
  comparisonReportType,
  documentId,
  excelDocumentId,
}: {
  reportType: ReportsType;
  isTotalView?: boolean;
  comparisonReportType?: ViewByOption;
  documentId?: string | null;
  excelDocumentId?: string | null;
}) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { alertToast, successToast } = useToast();
  const { filters } = useSelector(getFilterStatus);
  const [startDate, endDate] = filters.reportDate;

  const [
    downloadReport,
    {
      data: interactiveReport,
      isLoading: isDownloading,
      isSuccess: isDownloadSuccess,
      isFetching,
    },
  ] = useLazyNewInteractiveReportQuery();

  const [getPreviewUrl] = useLazyGetPreviewUrlQuery();

  const onDownload = async (
    downloadType: "DOWNLOAD_EXCEL" | "DOWNLOAD_PDF"
  ) => {
    try {
      let newPdfDocumentId = documentId;
      let newExcelDocumentId = excelDocumentId;
      if (
        (downloadType === "DOWNLOAD_EXCEL" && !excelDocumentId) ||
        (downloadType === "DOWNLOAD_PDF" && !documentId)
      ) {
        const { document_id, excel_document_id } = await downloadReport({
          groupId,
          entityId,
          reportType: reportType,
          action: downloadType,
          accountingMethod: filters.accountingMethod?.[0].value as string,
          startDate: startDate.value ? (startDate?.value as string) : null,
          endDate: endDate.value ? (endDate?.value as string) : null,
          showNonZeroAccounts: filters?.others?.[0]?.value as string,
          comparison_report_type:
            comparisonReportType || (filters?.view?.[0]?.value as ViewByOption),
        }).unwrap();
        newPdfDocumentId = document_id;
        newExcelDocumentId = excel_document_id;
      }

      const { download_url } = await getPreviewUrl({
        groupId: groupId,
        fileId:
          downloadType === DOWNLOAD_EXCEL
            ? newExcelDocumentId
            : newPdfDocumentId,
      }).unwrap();

      openLink(download_url);

      successToast({ message: "Report downloaded" });
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };
  return (
    <Dropdown.Root>
      <Dropdown.Trigger
        asChild
        className="t-group"
        disabled={isDownloading || isFetching}
      >
        <div>
          <Button
            customType="primary"
            size="small"
            onClick={(e) => e.stopPropagation()}
            isLoading={isDownloading || isFetching}
            disabled={isDownloading || isFetching}
          >
            <div className="t-flex t-items-center t-gap-1.5">
              Export
              <div className="group-data-state-open:-t-rotate-180 t-transform t-transition t-duration-300 t-ease-in-out">
                <ArrowDown color="currentColor" />
              </div>
            </div>
          </Button>
        </div>
      </Dropdown.Trigger>
      <Dropdown.Portal>
        <Dropdown.Content
          className="t-w-24"
          sideOffset={4}
          align="end"
          side="bottom"
        >
          <ConditionalToolTip
            condition={!isTotalView && "Cannot download PDF for this view."}
          >
            <span>
              <Dropdown.Item
                onSelect={() => {
                  onDownload(DOWNLOAD_PDF);
                }}
                disabled={!isTotalView}
              >
                PDF
              </Dropdown.Item>
            </span>
          </ConditionalToolTip>
          <Dropdown.Item onSelect={() => onDownload(DOWNLOAD_EXCEL)}>
            Excel
          </Dropdown.Item>
        </Dropdown.Content>
      </Dropdown.Portal>
    </Dropdown.Root>
  );
};

const ReportTopBar = ({
  reportType,
  openComments,
}: {
  reportType: ReportsType;
  openComments: () => void;
}) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { alertToast } = useToast();
  const { isCpa, isForeignCA, isCustomer } = useRoleBasedView();
  const [showToast, setShowToast] = useState(false);
  const { filters } = useSelector(getFilterStatus);
  const [startDate, endDate] = filters.reportDate;

  const {
    filters: {
      view: { value },
    },
  } = useAppSelector((store) => store.reportFilter);

  const comparisonReportType = value as ViewByOption;

  const isTotalView =
    comparisonReportType === "TOTALS" || !comparisonReportType;

  const { data: moneyInTransit = [] } = useGetMoneyInTransitQuery(
    {
      groupId,
      entityId,
      reportType: reportType,
      startDate: startDate.value as string,
      endDate: endDate.value as string,
    },
    { skip: !groupId || !entityId, refetchOnMountOrArgChange: true }
  );

  const {
    isOpen: isAddToClosingModalOpen,
    close: closeAddToClosingModal,
    open: openAddToClosing,
  } = useModal();

  const [
    saveInteractiveReport,
    { data: savedInteractiveReport, isLoading, isSuccess },
  ] = useSaveInteractiveReportMutation();

  const [
    downloadReport,
    {
      data: interactiveReport,
      isLoading: isDownloading,
      isSuccess: isDownloadSuccess,
    },
  ] = useLazyNewInteractiveReportQuery();

  const viewLink = isCustomer
    ? `/books/live-financial/${savedInteractiveReport?.uuid}/?entity=${entityId}`
    : `/books/live-financial/${savedInteractiveReport?.uuid}/?company=${groupId}&entity=${entityId}`;

  const onSave = async () => {
    try {
      await saveInteractiveReport({
        groupId,
        entityId,
        reportType,
        accountingMethod: filters.accountingMethod?.[0].value as string,
        startDate: startDate.value ? (startDate?.value as string) : null,
        endDate: endDate.value ? (endDate?.value as string) : null,
        showNonZeroAccounts: filters?.others?.[0]?.value as string,
        comparison_report_type: filters?.view?.[0]?.value as ViewByOption,
      }).unwrap();
      setShowToast(true);
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const onAddToMonthlyClosing = async () => {
    openAddToClosing();
    await downloadReport({
      groupId,
      entityId,
      reportType: reportType,
      action: "DOWNLOAD_PDF",
      accountingMethod: filters.accountingMethod?.[0].value as string,
      startDate: startDate.value ? (startDate?.value as string) : null,
      endDate: endDate.value ? (endDate?.value as string) : null,
      showNonZeroAccounts: filters?.others?.[0]?.value as string,
    }).unwrap();
  };

  return (
    <div className="t-flex t-flex-col t-gap-4 t-sticky t-top-0 t-bg-white t-pb-6 t-z-filter-head">
      {moneyInTransit.length > 0 && (
        <MoneyInTransitBanner transactions={moneyInTransit} />
      )}
      <div className="t-flex t-justify-between t-w-full">
        <div className="t-flex t-gap-2">
          <ReportFilter reportType={reportType} />
        </div>

        <div className="t-flex t-gap-3 t-items-start">
          {(isCpa || isForeignCA) && (
            <Button
              size="small"
              onClick={onAddToMonthlyClosing}
              isLoading={isDownloading}
              disabled={isDownloading}
            >
              Add to closing
            </Button>
          )}
          <Button
            size="small"
            isLoading={isLoading}
            disabled={isLoading}
            onClick={onSave}
          >
            {isLoading ? "Saving" : " Save"}
          </Button>

          <ReportDownload reportType={reportType} isTotalView={isTotalView} />

          <Button customType="icon" onClick={openComments} size="small">
            <ChatTeardropDots />
          </Button>
        </div>

        {showToast && (
          <div className="t-fixed t-bottom-20 t-right-0">
            <Toast.Provider>
              <Toast.Root
                open={showToast}
                customType="success"
                size="regular"
                onOpenChange={() => setShowToast(false)}
              >
                <Toast.Title>Report saved</Toast.Title>
                <Toast.Description></Toast.Description>
                <Toast.Close onClose={() => setShowToast(false)} />
                <Toast.Action
                  onAction={() => openLink(viewLink)}
                  altText="View"
                >
                  View
                </Toast.Action>
              </Toast.Root>
            </Toast.Provider>
          </div>
        )}
        {interactiveReport?.document_id && interactiveReport?.type && (
          <AddReportToClosingModal
            open={isAddToClosingModalOpen}
            onClose={closeAddToClosingModal}
            reportType={interactiveReport?.type}
            documentId={interactiveReport?.document_id!}
          />
        )}
      </div>
    </div>
  );
};

const ReportView = ({ reportType }: { reportType: ReportsType }) => {
  const { isAdmin } = useRoleBasedView();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { filters, getFilterName, capsuleFilters } =
    useSelector(getFilterStatus);
  const openComment = useAppSelector((store) => store.openComment.openComment);
  const [startDate, endDate] = filters.reportDate;

  const comparisonReportType = filters.view[0].value as ViewByOption;

  const { data, isLoading, isSuccess, isFetching } =
    useNewInteractiveReportQuery(
      {
        groupId,
        entityId,
        reportType: reportType,
        accountingMethod: filters.accountingMethod?.[0].value as string,
        startDate: startDate.value ? (startDate.value as string) : null,
        endDate: endDate.value ? (endDate.value as string) : null,
        showNonZeroAccounts: filters.others?.[0].value as string,
        comparison_report_type: comparisonReportType,
      },
      {
        refetchOnMountOrArgChange: true,
        skip:
          !groupId || !entityId || !reportType || reportType === SAVED_REPORTS,
      }
    );

  const { report_data, type } = data || {};
  const { accounts = [] } = report_data || {};

  const isEmpty = !report_data || accounts?.length === 0;

  return (
    <Async.Root {...{ isEmpty, isLoading, isSuccess }}>
      <Async.Empty>
        <StartBookkeepingDateEmptyScreen />
      </Async.Empty>
      <Async.Success>
        <ReportsAccordion.Root
          className="t-pb-20 t-relative"
          reportType={type!}
          accountingMethod={filters.accountingMethod?.[0].value as string}
          startDate={startDate.value ? (startDate.value as string) : undefined}
          endDate={endDate.value ? (endDate.value as string) : undefined}
          showNonZeroAccounts={filters.others?.[0].value as boolean}
          isLoading={isFetching}
        >
          {report_data && (
            <ReportsAccordion.Trigger
              openCategory={openComment}
              data={accounts}
              secondaryAction={({ uuid }) => (
                <ConditionalLink
                  to={redirectToCategories({
                    uuid,
                    groupId,
                    entityId,
                    capsuleFilters,
                    getFilterName,
                    isAdmin,
                  })}
                >
                  <Redirect size={16} />
                </ConditionalLink>
              )}
            />
          )}
        </ReportsAccordion.Root>
        <TransactionSlider />
      </Async.Success>
    </Async.Root>
  );
};

export const InteractiveReport = ({
  reportType,
}: {
  reportType: ReportsType;
}) => {
  usePageTitle(REPORT_TYPE[reportType!] || "Report");
  const commentPane = useModal();
  const openComment = useAppSelector((store) => store.openComment.openComment);
  const dispatch = useAppDispatch();

  const setActiveComment = (comment: NoteResponse) => {
    if (comment.category) {
      dispatch(setOpenComment(comment.category.uuid));
    }
  };

  const {
    filters: {
      view: { value },
    },
  } = useAppSelector((store) => store.reportFilter);

  const { filters } = useSelector(getFilterStatus);

  const comparisonReportType = value as ViewByOption;

  const isTotalView =
    comparisonReportType === "TOTALS" || !comparisonReportType;

  const [startDate, endDate] = filters.reportDate;

  const params = {
    reportType: reportType,
    accountingMethod: filters.accountingMethod?.[0].value as string,
    startDate: startDate.value ? (startDate.value as string) : null,
    endDate: endDate.value ? (endDate.value as string) : null,
    showNonZeroAccounts: filters.others?.[0].value as string,
    comparison_report_type: comparisonReportType,
  };

  return (
    <div className="t-flex t-h-full t-pl-10">
      <div
        className={classNames(
          "t-flex t-flex-col t-w-full t-my-5 t-grow-0 t-overflow-x-auto",
          {
            "t-pr-3": commentPane.isOpen,
            "t-pr-10": !commentPane.isOpen,
          }
        )}
      >
        <h1 className="t-sr-only">{reportType}</h1>
        <ReportTopBar
          openComments={commentPane.toggle}
          reportType={reportType}
        />
        {isTotalView ? (
          <ReportView reportType={reportType} />
        ) : (
          <ComparisonReport reportType={reportType} />
        )}
      </div>
      <AnimatePresence>
        {commentPane.isOpen && (
          <SliderAnimation className="t-h-full t-shrink-0 t-grow-0 t-sticky t-top-0 t-border-0 t-border-l t-border-solid t-border-neutral-0 t-overflow-y-auto t-p-4 t-pt-0">
            <CommentsSlider
              setActiveComment={setActiveComment}
              activeComment={openComment}
              onClose={commentPane.toggle}
              commentTypes={["REPORTS_COMMENT"]}
              reportFilters={params}
            />
          </SliderAnimation>
        )}
      </AnimatePresence>
    </div>
  );
};
