import { Ticket } from "components/icons/LeftNav/Ticket";
import { Documents as DocumentsIcon } from "components/icons/LeftNav/Documents";
import { LeftBarItem } from "components/Sidebar/LeftBar";
import { ChatLeftNav } from "components/ChatLeftNav/ChatLeftNav";
import { useAnalytics } from "hooks/useAnalytics";
import {
  ACTION_ITEMS_CLICKED_FROM_SIDEBAR,
  CHAT_CLICKED_FROM_SIDEBAR,
  DOCUMENTS_CLICKED_FROM_SIDEBAR,
} from "constants/analyticsEvents";

export const NavAcrossApps = () => {
  const { trackEvent } = useAnalytics();

  return (
    <>
      <button
        className="all:unset t-w-full"
        onClick={() => trackEvent(CHAT_CLICKED_FROM_SIDEBAR)}
      >
        <ChatLeftNav />
      </button>
      <button
        className="all:unset t-w-full"
        onClick={() => trackEvent(ACTION_ITEMS_CLICKED_FROM_SIDEBAR)}
      >
        <LeftBarItem to="/action-items" icon={Ticket}>
          Action Items
        </LeftBarItem>
      </button>
      <button
        className="all:unset t-w-full"
        onClick={() => trackEvent(DOCUMENTS_CLICKED_FROM_SIDEBAR)}
      >
        <LeftBarItem to="/documents" icon={DocumentsIcon}>
          Documents
        </LeftBarItem>
      </button>
    </>
  );
};
