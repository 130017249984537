import { Chip } from "components/DesignSystem/Chips/Chips";
import {
  Combobox,
  ComboboxProps,
  OptionData,
} from "components/DesignSystem/Combobox/Combobox";
import { Filter } from "components/DesignSystem/Filter/Filter";
import RadioGroup from "components/DesignSystem/RadioGroup/RadioGroup";
import { DateFilter } from "components/Filters/DateFilter";
import { monthPeriod } from "constants/bookkeeping";
import { MMM_YYYY, Q_YYYY, YYYY, YYYY_MM_DD } from "constants/date";
import {
  AccountingMethod,
  lastFiveYears,
  PeriodType,
  RevenueBasis,
} from "constants/revenueMetrics";
import dayjs from "dayjs";
import { Form, Formik, useFormikContext } from "formik";
import { MultiValue, SingleValue } from "react-select";

const YearPicker = ({
  updateFilter,
  ...props
}: ComboboxProps & {
  updateFilter: <S extends "START_DATE" | "END_DATE">(
    name: S,
    newValue: {
      START_DATE: string;
      END_DATE: string;
    }[S]
  ) => void;
}) => {
  const { setFieldValue } = useFormikContext();

  const handleChange = (
    values: MultiValue<OptionData> | SingleValue<OptionData>
  ) => {
    const { value } = (values as SingleValue<OptionData>) || {};

    const formatedDate =
      props.name === "START_DATE"
        ? dayjs(value).startOf("year").format(YYYY_MM_DD)
        : dayjs(value).endOf("year").format(YYYY_MM_DD);

    const date = value ? formatedDate : "";

    if (props.name) {
      setFieldValue(props.name, date);
      updateFilter(
        props.name as "START_DATE" | "END_DATE",
        dayjs(date).format(YYYY_MM_DD)
      );
    }
  };

  return (
    <Combobox
      onChange={handleChange}
      components={{ ClearIndicator: () => null }}
      placeholder="Select year"
      menuPortalTarget={document.body}
      {...props}
    />
  );
};

const YearSelectorForm = ({
  updateFilter,
  values,
}: {
  updateFilter: <S extends "START_DATE" | "END_DATE">(
    name: S,
    newValue: {
      START_DATE: string;
      END_DATE: string;
    }[S]
  ) => void;
  values: {
    START_DATE: string;
    END_DATE: string;
  };
}) => {
  const startFromlastFiveYears = lastFiveYears.map((year) => {
    return {
      label: year,
      value: year,
      isDisabled: year > dayjs(values?.END_DATE).format("YYYY"),
    };
  });

  const endToLastFiveYears = lastFiveYears.map((year) => {
    return {
      label: year,
      value: year,
      isDisabled: year < dayjs(values?.START_DATE).format("YYYY"),
    };
  });

  const initialDates = {
    START_DATE: startFromlastFiveYears.find(
      ({ value }) => value === dayjs(values?.START_DATE).format("YYYY")
    ),
    END_DATE: endToLastFiveYears.find(
      ({ value }) => value === dayjs(values?.END_DATE).format("YYYY")
    ),
  };

  return (
    <Formik initialValues={initialDates} onSubmit={() => {}}>
      <Form className="t-flex t-flex-col t-gap-4">
        <YearPicker
          updateFilter={updateFilter}
          withForm
          name="START_DATE"
          label="From"
          value={initialDates.START_DATE}
          options={startFromlastFiveYears}
        />
        <YearPicker
          updateFilter={updateFilter}
          withForm
          name="END_DATE"
          label="To"
          value={initialDates.END_DATE}
          options={endToLastFiveYears}
        />
      </Form>
    </Formik>
  );
};

export const RecurringRevenueFilter = ({
  filterValues,
  updateFilter,
}: {
  filterValues: {
    START_DATE: string;
    END_DATE: string;
    SELECT_PERIOD: string;
    REVENUE_BASIS: RevenueBasis;
  };
  updateFilter: <
    S extends "START_DATE" | "END_DATE" | "SELECT_PERIOD" | "REVENUE_BASIS"
  >(
    name: S,
    newValue: {
      START_DATE: string;
      END_DATE: string;
      SELECT_PERIOD: string;
      REVENUE_BASIS: RevenueBasis;
    }[S]
  ) => void;
}) => {
  const dateFormat = {
    [PeriodType.MONTHLY]: MMM_YYYY,
    [PeriodType.YEARLY]: YYYY,
    [PeriodType.QUARTERLY]: Q_YYYY,
  };

  return (
    <Filter.Root
      defaultValue="VIEW_BY"
      capsule={
        <>
          {(filterValues?.START_DATE || filterValues?.END_DATE) && (
            <Chip
              onClose={() => {}}
              isRemovable={false}
              isActive
              filterType="VIEW_BY"
            >
              <span>
                {dayjs(filterValues.START_DATE).format(
                  dateFormat[PeriodType.MONTHLY]
                )}{" "}
                to{" "}
                {dayjs(filterValues.END_DATE).format(
                  dateFormat[PeriodType.MONTHLY]
                )}
              </span>
            </Chip>
          )}
        </>
      }
    >
      <Filter.Portal>
        <Filter.List>
          <Filter.ListItem value="VIEW_BY">View by</Filter.ListItem>
        </Filter.List>
        <Filter.Body value="VIEW_BY" block>
          <DateFilter
            values={filterValues}
            updateFilter={updateFilter}
            datePeriod={monthPeriod}
            pickerType="month"
          />
        </Filter.Body>
      </Filter.Portal>
    </Filter.Root>
  );
};

export const TotalRevenueFilter = ({
  filterValues,
  updateFilter,
}: {
  filterValues: {
    START_DATE: string;
    END_DATE: string;
    SELECT_PERIOD: string;
    VIEW_TYPE: PeriodType;
    REVENUE_BASIS: RevenueBasis;
    ACCOUNTING_METHOD: AccountingMethod;
  };
  updateFilter: <
    S extends
      | "START_DATE"
      | "END_DATE"
      | "SELECT_PERIOD"
      | "VIEW_TYPE"
      | "REVENUE_BASIS"
      | "ACCOUNTING_METHOD"
  >(
    name: S,
    newValue: {
      START_DATE: string;
      END_DATE: string;
      SELECT_PERIOD: string;
      VIEW_TYPE: PeriodType;
      REVENUE_BASIS: RevenueBasis;
      ACCOUNTING_METHOD: AccountingMethod;
    }[S]
  ) => void;
}) => {
  const dateFormat = {
    [PeriodType.MONTHLY]: MMM_YYYY,
    [PeriodType.YEARLY]: YYYY,
    [PeriodType.QUARTERLY]: Q_YYYY,
  };

  return (
    <Filter.Root
      defaultValue="VIEW_BY"
      capsule={
        <>
          {(filterValues.START_DATE || filterValues.END_DATE) && (
            <Chip
              onClose={() => {}}
              isRemovable={false}
              isActive
              filterType="VIEW_BY"
            >
              {filterValues.VIEW_TYPE === PeriodType.QUARTERLY ? (
                <span>
                  Q
                  {dayjs(filterValues.START_DATE).format(
                    dateFormat[filterValues.VIEW_TYPE]
                  )}
                  <>
                    {" to "}Q
                    {dayjs(filterValues.END_DATE).format(
                      dateFormat[filterValues.VIEW_TYPE]
                    )}
                  </>
                </span>
              ) : (
                <span>
                  {dayjs(filterValues.START_DATE).format(
                    dateFormat[filterValues.VIEW_TYPE]
                  )}
                  {filterValues.VIEW_TYPE === PeriodType.MONTHLY ? " to " : "-"}
                  {dayjs(filterValues.END_DATE).format(
                    dateFormat[filterValues.VIEW_TYPE]
                  )}
                </span>
              )}
            </Chip>
          )}
          {filterValues.ACCOUNTING_METHOD && (
            <Chip
              onClose={() => {}}
              isRemovable={false}
              isActive
              filterType="ACCOUNTING_METHOD"
            >
              <div className="first-letter:t-uppercase t-lowercase">
                {filterValues.ACCOUNTING_METHOD}
              </div>
            </Chip>
          )}
        </>
      }
    >
      <Filter.Portal>
        <Filter.List>
          <Filter.ListItem value="VIEW_BY">View by</Filter.ListItem>
          <Filter.ListItem value="ACCOUNTING_METHOD">
            Accounting method
          </Filter.ListItem>
        </Filter.List>
        <Filter.Body value="VIEW_BY" block>
          <RadioGroup.Root
            className="t-mb-6"
            onValueChange={(value: typeof filterValues.VIEW_TYPE) => {
              updateFilter("VIEW_TYPE", value);
            }}
            value={filterValues.VIEW_TYPE}
          >
            <RadioGroup.Content>
              <RadioGroup.Item value={PeriodType.MONTHLY}>
                Months
              </RadioGroup.Item>
              {filterValues.VIEW_TYPE === PeriodType.MONTHLY && (
                <DateFilter
                  values={filterValues}
                  updateFilter={updateFilter}
                  datePeriod={monthPeriod}
                  pickerType="month"
                />
              )}

              <RadioGroup.Item value={PeriodType.QUARTERLY}>
                Quarters
              </RadioGroup.Item>
              {filterValues.VIEW_TYPE === PeriodType.QUARTERLY && (
                <DateFilter
                  values={filterValues}
                  updateFilter={updateFilter}
                  datePeriod={monthPeriod}
                  pickerType="quarter"
                  showPeriodSelector={false}
                />
              )}
              <RadioGroup.Item value={PeriodType.YEARLY}>Years</RadioGroup.Item>
              {filterValues.VIEW_TYPE === PeriodType.YEARLY && (
                <YearSelectorForm
                  values={filterValues}
                  updateFilter={updateFilter}
                />
              )}
            </RadioGroup.Content>
          </RadioGroup.Root>
        </Filter.Body>
        <Filter.Body value="ACCOUNTING_METHOD" block>
          <RadioGroup.Root
            className="t-mb-6"
            onValueChange={(value: typeof filterValues.ACCOUNTING_METHOD) => {
              updateFilter("ACCOUNTING_METHOD", value);
            }}
            value={filterValues.ACCOUNTING_METHOD}
          >
            <RadioGroup.Content>
              <RadioGroup.Item value={AccountingMethod.CASH}>
                Cash
              </RadioGroup.Item>
              <RadioGroup.Item value={AccountingMethod.ACCRUAL}>
                Accrual
              </RadioGroup.Item>
            </RadioGroup.Content>
          </RadioGroup.Root>
        </Filter.Body>
      </Filter.Portal>
    </Filter.Root>
  );
};
