import { EntityDataAttribute } from "types/Models/entity";
import { Task } from "types/Models/task";
import { emptyApi } from "./emptyApi";

export type EntityDataAttributeWithFieldKey = EntityDataAttribute & {
  field_key: string;
  order: number;
};

export type RegistrationStatus =
  | "NOT_REGISTERED"
  | "REGISTERED"
  | "REQUIRED"
  | "PROCESSING"
  | "FOREIGN_QUALIFICATION_REQUIRED"
  | "FOREIGN_QUALIFIED"
  | "INCORPORATED";
export type RegistrationActionType =
  | "VISIT_IRS"
  | "LAUNCH_FILING"
  | "GET_AGENT";

export type StateFilingData = {
  base_task_template_id: string;
  task_id: string | null;
  product_content_type_id: number;
  amount: string;
  status: RegistrationStatus;
} | null;

export type RegistrationRes = {
  department_of_registration: string;
  purpose: string;
  relevance: string;
  status: RegistrationStatus;
  data_attributes: EntityDataAttributeWithFieldKey[];
  uuid: string;
  title: string;
  action_type: RegistrationActionType;
  action_label: string;
  filing_data: StateFilingData;
  irs_tax_office: string | null;
};

export type RegistrationStateType = {
  stateName: string;
  isHomeState: boolean;
  secretaryOfState: RegistrationStatus;
  registeredAgent: RegistrationStatus;
  payroll: RegistrationStatus;
  salesTax: RegistrationStatus;
  uuid: string;
};

export const registrationsApis = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getFederalRegistrations: build.query<
      RegistrationRes[],
      { groupId: string; entityId: string }
    >({
      query: ({ groupId, entityId }) =>
        `/api/inkle/tax_compliance/group/${groupId}/entity/${entityId}/federal_registrations/`,
      providesTags: ["FEDERAL_REGISTRATIONS"],
    }),

    updateFederalRegistration: build.mutation<
      RegistrationRes,
      {
        payload: EntityDataAttributeWithFieldKey[];
        groupId: string;
        entityId: string;
        registrationId: string;
      }
    >({
      query: ({ groupId, payload, entityId, registrationId }) => ({
        url: `/api/inkle/tax_compliance/group/${groupId}/entity/${entityId}/federal_registration/${registrationId}/`,
        method: "PUT",
        body: { data_attributes: payload },
      }),
      invalidatesTags: ["FEDERAL_REGISTRATIONS"],
    }),

    getFederalRegistrationById: build.query<
      RegistrationRes,
      { groupId: string; federalRegistrationId: string; entityId: string }
    >({
      query: ({ groupId, federalRegistrationId, entityId }) =>
        `/api/inkle/tax_compliance/group/${groupId}/entity/${entityId}/federal_registration/${federalRegistrationId}/`,
      providesTags: ["FEDERAL_REGISTRATIONS"],
    }),

    getTaskByRequestId: build.query<
      Task[],
      { groupId: string; requestId: string; entityId: string }
    >({
      query: ({ groupId, requestId, entityId }) =>
        `/api/inkle/billing/group/${groupId}/entity/${entityId}/cart_order_item_request/${requestId}/`,
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetFederalRegistrationsQuery,
  useGetFederalRegistrationByIdQuery,
  useUpdateFederalRegistrationMutation,
  useLazyGetFederalRegistrationByIdQuery,
  useLazyGetTaskByRequestIdQuery,
} = registrationsApis;
