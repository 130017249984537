import { lazy, object, string } from "yup";

export const requestInfoValidation = lazy((obj) =>
  object({
    information_requests: object(
      Object.fromEntries(
        Object.entries(obj.information_requests).map(([key, value]) => [
          key,
          string().required(),
        ])
      )
    ),
  })
);
