export const InkleCreditCoin = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
  >
    <path
      d="M6.33439 1.54625L7.66258 1.27823L10.6653 16.4531L9.33708 16.7212C5.83883 17.4271 2.3305 14.6022 1.50135 10.4119C0.672198 6.22159 2.83613 2.25216 6.33439 1.54625Z"
      fill="#DB6402"
    />
    <g style={{ mixBlendMode: "multiply" }}>
      <path
        d="M10.5841 16.0421L10.6655 16.4537L9.33735 16.7218C7.85128 17.0216 6.36383 16.6825 5.08008 15.8741L10.3519 14.871L10.5837 16.0422L10.5841 16.0421Z"
        fill="#DBA97F"
      />
    </g>
    <g style={{ mixBlendMode: "soft-light" }}>
      <path
        d="M5.88348 7.03106L1.35582 7.89922C1.44645 6.78739 1.74453 5.74255 2.21463 4.82925L5.35768 4.22671L5.88348 7.03106Z"
        fill="white"
      />
    </g>
    <g style={{ mixBlendMode: "soft-light" }}>
      <path
        d="M5.25239 3.69303L2.57431 4.2068C2.68332 4.03829 2.79854 3.87588 2.92049 3.71992L5.17662 3.28716L5.25282 3.69294L5.25239 3.69303Z"
        fill="white"
      />
    </g>
    <path
      d="M10.6655 16.4533C14.1639 15.7474 16.3277 11.7781 15.4985 7.58767C14.6694 3.39723 11.1612 0.572489 7.66279 1.27843C4.1644 1.98436 2.00058 5.95366 2.82975 10.1441C3.65892 14.3345 7.1671 17.1593 10.6655 16.4533Z"
      fill="#FEE475"
    />
    <path
      d="M10.6162 16.2042C13.9999 15.5215 16.0928 11.6824 15.2908 7.62957C14.4889 3.5767 11.0958 0.844712 7.7121 1.5275C4.32845 2.21029 2.23556 6.04929 3.03751 10.1022C3.83946 14.155 7.23257 16.887 10.6162 16.2042Z"
      fill="#FED425"
    />
    <g style={{ mixBlendMode: "soft-light" }}>
      <path
        d="M13.4586 14.6278L2.89908 7.63822C2.99338 6.53804 3.29714 5.50583 3.77441 4.61038L15.0174 12.0522C14.6738 13.0465 14.142 13.9282 13.4586 14.6278Z"
        fill="white"
      />
    </g>
    <g style={{ mixBlendMode: "soft-light" }}>
      <path
        d="M3.62909 11.9756L10.127 16.2768C7.4645 16.5591 4.87489 14.7848 3.62909 11.9756Z"
        fill="white"
      />
    </g>
    <g style={{ mixBlendMode: "soft-light" }}>
      <path
        d="M14.5896 5.51653L8.42365 1.43542C9.05829 1.39508 9.6868 1.4708 10.2918 1.64817L13.4078 3.71077C13.8672 4.23992 14.2674 4.8467 14.5897 5.51698L14.5896 5.51653Z"
        fill="white"
      />
    </g>
    <g style={{ mixBlendMode: "soft-light" }}>
      <path
        d="M15.2292 11.3313L4.14372 3.99363C4.23339 3.86072 4.32867 3.7331 4.42673 3.60814L15.3333 10.8276C15.3032 10.9976 15.2688 11.1658 15.2288 11.3314L15.2292 11.3313Z"
        fill="white"
      />
    </g>
    <g style={{ mixBlendMode: "soft-light" }}>
      <path
        d="M7.71753 1.55569C4.25775 2.25384 2.10529 6.14334 2.85967 10.2822C2.84962 10.236 2.83834 10.1905 2.82917 10.1441C2.00046 5.95368 4.16395 1.98434 7.66265 1.27834C11.1613 0.572337 14.6692 3.39726 15.4984 7.58758C15.5076 7.63395 15.5145 7.68032 15.5228 7.72687C14.6446 3.61303 11.1773 0.857545 7.71753 1.55569Z"
        fill="white"
      />
    </g>
    <g style={{ mixBlendMode: "soft-light" }}>
      <path
        d="M10.0964 14.3191C12.503 13.8334 13.9916 11.1028 13.4212 8.21994C12.8507 5.33713 10.4373 3.39383 8.03069 3.87947C5.62404 4.3651 4.13549 7.09577 4.70592 9.97859C5.27635 12.8614 7.68975 14.8047 10.0964 14.3191Z"
        fill="white"
      />
    </g>
    <path
      d="M10.1969 14.0862C12.6035 13.6005 14.0921 10.8698 13.5216 7.98703C12.9512 5.10422 10.5378 3.16092 8.13115 3.64656C5.7245 4.13219 4.23595 6.86286 4.80638 9.74568C5.37681 12.6285 7.79021 14.5718 10.1969 14.0862Z"
      fill="#FEB724"
    />
    <path
      d="M13.3174 8.48775C12.747 5.60513 10.3336 3.66163 7.9269 4.14727C6.62539 4.4099 5.5935 5.32955 5.00929 6.57405C5.53424 5.07834 6.6608 3.94302 8.13113 3.64632C10.5378 3.16068 12.9512 5.10418 13.5216 7.9868C13.7836 9.31085 13.6102 10.602 13.1144 11.6587C13.4486 10.7067 13.5392 9.60864 13.3173 8.4873L13.3174 8.48775Z"
      fill="#FB9D12"
    />
    <g filter="url(#filter0_i_2038_8513)">
      <path
        d="M5.94954 8.56716C5.98486 8.71516 6.10124 8.79123 6.24711 8.75573C6.39033 8.72087 6.45962 8.59961 6.42429 8.45162C6.38897 8.30362 6.27325 8.23029 6.13003 8.26515C5.98416 8.30065 5.91422 8.41917 5.94954 8.56716ZM6.90277 10.5623L6.51031 8.91786L6.08595 9.02115L6.47841 10.6656L6.90277 10.5623Z"
        fill="#DB6402"
      />
      <path
        d="M8.00786 9.42045L8.20474 10.2454L8.6291 10.1421L8.43222 9.31717C8.31186 8.81288 7.9801 8.53984 7.5743 8.6386C7.40987 8.67862 7.26636 8.80635 7.24009 8.94324L7.19431 8.75139L6.76995 8.85467L7.16241 10.4991L7.58677 10.3958L7.39054 9.5736C7.33494 9.34064 7.42434 9.13908 7.60205 9.09583C7.79036 9.05 7.9503 9.17927 8.00786 9.42045Z"
        fill="#DB6402"
      />
      <path
        d="M9.30948 9.97652L9.13353 9.23927L9.82933 9.85L10.3677 9.71896L9.57475 9.04199L9.91958 8.0881L9.45543 8.20106L9.09298 9.06935L8.7535 7.64693L8.32914 7.75021L8.88513 10.0798L9.30948 9.97652Z"
        fill="#DB6402"
      />
      <path
        d="M10.951 9.57701L10.395 7.24742L9.97061 7.3507L10.5266 9.68029L10.951 9.57701Z"
        fill="#DB6402"
      />
      <path
        d="M12.1208 8.75871C12.0907 8.83272 12.0031 8.90045 11.8784 8.93079C11.714 8.97081 11.5585 8.89556 11.4709 8.73999L12.5795 8.47017C12.5757 8.4189 12.5653 8.36344 12.5528 8.31137C12.439 7.83448 12.0158 7.55469 11.5703 7.66314C11.1247 7.77159 10.8742 8.21534 10.988 8.69222C11.1057 9.18555 11.515 9.45422 11.9792 9.34125C12.3028 9.2625 12.5362 8.98239 12.5717 8.64897L12.1208 8.75871ZM11.3956 8.42481C11.4048 8.25149 11.5046 8.11701 11.669 8.07699C11.8335 8.03696 11.983 8.11077 12.0693 8.26085L11.3956 8.42481Z"
        fill="#DB6402"
      />
    </g>
    <defs>
      <filter
        id="filter0_i_2038_8513"
        x="5.94051"
        y="7.24744"
        width="6.63901"
        height="3.95238"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.534292" />
        <feGaussianBlur stdDeviation="0.267146" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2038_8513"
        />
      </filter>
    </defs>
  </svg>
);
