import { date, object, string } from "yup";

export const registerAgentSchema = object({
  agent_name: string().required("Please enter Agent name"),
  start_date: date().required("Please enter Starting date"),
  expiration_date: date().required("Please enter Expiration date"),
  street_address: string().required("Please enter Address"),
  state: string().required("Please select State"),
  city: string().required("Please enter City name"),
  zipcode: string().required("Please enter Zip Code"),
  phone_number: string().test({
    name: "phone_number",
    message: "Please enter valid mobile number",
    test: (value: any) => {
      if (!value) return true;
      return value?.length > 10;
    },
  }),
});
