import Modal from "components/DesignSystem/Modal/Modal";
import React from "react";
import TPInfo from "static/images/TPInfo.svg";

export const TPInfoModal = ({ showTPInfo, setShowTPInfo }: any) => {
  return (
    <Modal.Root open={showTPInfo} onOpenChange={setShowTPInfo}>
      <Modal.Content size="large" className="!t-w-fit">
        <Modal.Header>
          <div></div>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <div className="t-p-16">
            <img src={TPInfo} alt="TPflow" />
          </div>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
