export const InklePracticeLogo = () => {
  return (
    <svg
      width="178"
      height="20"
      viewBox="0 0 178 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_8954_1108)">
        <mask
          id="mask0_8954_1108"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="21"
          height="20"
        >
          <path d="M20.9323 0H0.932312V20H20.9323V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_8954_1108)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.9323 3.36449C10.2097 3.36449 9.6239 3.95028 9.6239 4.6729H6.25941C6.25941 2.09213 8.35154 0 10.9323 0C13.5131 0 15.6052 2.09213 15.6052 4.6729C15.6052 7.25367 13.5131 9.34579 10.9323 9.34579V5.98131C11.6549 5.98131 12.2407 5.39551 12.2407 4.6729C12.2407 3.95028 11.6549 3.36449 10.9323 3.36449ZM17.5678 10C17.5678 9.27738 16.982 8.69159 16.2594 8.69159V5.3271C18.8402 5.3271 20.9323 7.41923 20.9323 10C20.9323 12.5808 18.8402 14.6729 16.2594 14.6729C13.6786 14.6729 11.5865 12.5808 11.5865 10H14.951C14.951 10.7226 15.5368 11.3084 16.2594 11.3084C16.982 11.3084 17.5678 10.7226 17.5678 10ZM5.60521 11.3084C4.88259 11.3084 4.2968 10.7226 4.2968 10C4.2968 9.27738 4.88259 8.69159 5.60521 8.69159C6.32782 8.69159 6.91362 9.27738 6.91362 10H10.2781C10.2781 7.41923 8.18598 5.3271 5.60521 5.3271C3.02444 5.3271 0.932312 7.41923 0.932312 10C0.932312 12.5808 3.02444 14.6729 5.60521 14.6729V11.3084ZM10.9323 16.6355C11.6549 16.6355 12.2407 16.0497 12.2407 15.3271H15.6052C15.6052 17.9079 13.5131 20 10.9323 20C8.35154 20 6.25941 17.9079 6.25941 15.3271C6.25941 12.7463 8.35154 10.6542 10.9323 10.6542V14.0187C10.2097 14.0187 9.6239 14.6045 9.6239 15.3271C9.6239 16.0497 10.2097 16.6355 10.9323 16.6355Z"
            fill="url(#paint0_linear_8954_1108)"
          />
        </g>
      </g>
      <path
        d="M30.9325 1.98349C30.9325 0.780024 31.7125 0 32.9383 0C34.1417 0 34.9218 0.780024 34.9218 1.98349C34.9218 3.18696 34.1417 3.98927 32.9383 3.98927C31.7125 3.98927 30.9325 3.18696 30.9325 1.98349ZM34.7212 19.0995H31.1554V5.72761H34.7212V19.0995Z"
        fill="url(#paint1_linear_8954_1108)"
      />
      <path
        d="M45.6614 12.3912C45.6614 10.43 44.6362 9.13743 43.0539 9.13743C41.5607 9.13743 40.4687 10.5192 40.4687 12.4135V19.0995H36.9029V5.72761H40.4687V7.28766C40.9367 6.28477 42.3185 5.5716 43.7002 5.5716C47.11 5.5716 49.2272 8.29055 49.2272 12.3912V19.0995H45.6614V12.3912Z"
        fill="url(#paint2_linear_8954_1108)"
      />
      <path
        d="M54.9444 19.0995H51.3786V0.156004H54.9444V11.7227L59.4685 5.72761H63.3687L58.8222 12.4135L63.8367 19.0995H59.3125L54.9444 13.1044V19.0995Z"
        fill="url(#paint3_linear_8954_1108)"
      />
      <path
        d="M68.7374 19.0995H65.1716V0.156004H68.7374V19.0995Z"
        fill="url(#paint4_linear_8954_1108)"
      />
      <path
        d="M79.5786 14.9988H83.3673C82.4536 17.4948 80.0689 19.2109 77.35 19.2109C73.4499 19.2109 70.7086 16.3805 70.7086 12.369C70.7086 8.49112 73.539 5.54932 77.2831 5.54932C81.0272 5.54932 83.8576 8.49112 83.8576 12.369C83.8576 12.7924 83.8353 13.2381 83.7685 13.6393H74.4528C74.8539 14.9988 75.9459 15.8679 77.3277 15.8679C78.3752 15.8679 79.1998 15.5113 79.5786 14.9988ZM74.4528 11.0763H80.1135C79.7123 9.76145 78.6649 8.91456 77.2831 8.91456C75.9014 8.91456 74.8539 9.76145 74.4528 11.0763Z"
        fill="url(#paint5_linear_8954_1108)"
      />
      <path
        d="M174.392 15.8729H176.736C175.893 18.1754 173.734 19.7584 171.267 19.7584C167.731 19.7584 165.244 17.1475 165.244 13.447C165.244 9.86993 167.813 7.15625 171.206 7.15625C174.577 7.15625 177.188 9.84937 177.188 13.3443C177.188 13.6526 177.167 13.961 177.106 14.2488H167.505C167.813 16.3046 169.273 17.682 171.247 17.682C172.686 17.682 173.837 16.9419 174.392 15.8729ZM167.526 12.6247H174.927C174.618 10.6511 173.138 9.25318 171.206 9.25318C169.294 9.25318 167.834 10.6306 167.526 12.6247Z"
        fill="#41395C"
      />
      <path
        d="M157.713 9.35597C155.472 9.35597 153.951 10.9801 153.951 13.447C153.951 15.914 155.472 17.5381 157.713 17.5381C159.399 17.5381 160.694 16.613 161.249 14.9889H163.572C162.935 17.9698 160.735 19.7584 157.713 19.7584C154.156 19.7584 151.731 17.2503 151.731 13.447C151.731 9.66434 154.156 7.15625 157.713 7.15625C160.714 7.15625 162.935 8.94481 163.572 11.9052H161.249C160.673 10.2811 159.399 9.35597 157.713 9.35597Z"
        fill="#41395C"
      />
      <path
        d="M147.341 3.62138C147.341 2.84018 147.855 2.30566 148.678 2.30566C149.459 2.30566 149.973 2.84018 149.973 3.62138C149.973 4.42315 149.459 4.95766 148.678 4.95766C147.855 4.95766 147.341 4.42315 147.341 3.62138ZM149.788 19.6567H147.568V7.32185H149.788V19.6567Z"
        fill="#41395C"
      />
      <path
        d="M141.175 4.70996H142.84V7.32085H145.739V9.41778H142.84V15.3796C142.84 16.8393 143.436 17.4766 144.834 17.4766H145.739V19.6557H144.629C141.812 19.6557 140.62 18.4634 140.62 15.503V9.41778H138.708V7.32085H140.62L141.175 4.70996Z"
        fill="#41395C"
      />
      <path
        d="M131.188 9.35597C128.947 9.35597 127.425 10.9801 127.425 13.447C127.425 15.914 128.947 17.5381 131.188 17.5381C132.873 17.5381 134.168 16.613 134.724 14.9889H137.047C136.409 17.9698 134.21 19.7584 131.188 19.7584C127.631 19.7584 125.205 17.2503 125.205 13.447C125.205 9.66434 127.631 7.15625 131.188 7.15625C134.189 7.15625 136.409 8.94481 137.047 11.9052H134.724C134.148 10.2811 132.873 9.35597 131.188 9.35597Z"
        fill="#41395C"
      />
      <path
        d="M120.903 19.6565V17.354C120.348 18.7931 118.58 19.7799 116.73 19.7799C113.194 19.7799 110.85 17.2718 110.85 13.4685C110.85 9.68583 113.173 7.17773 116.73 7.17773C118.559 7.17773 120.348 8.16452 120.903 9.62415V7.32164H123.103V19.6565H120.903ZM113.07 13.4685C113.07 15.9355 114.674 17.5596 116.997 17.5596C119.299 17.5596 120.903 15.9355 120.903 13.4891C120.903 11.0632 119.258 9.37746 116.976 9.37746C114.653 9.37746 113.07 11.0015 113.07 13.4685Z"
        fill="#41395C"
      />
      <path
        d="M109.798 7.17773V9.37746C106.756 9.3569 105.008 10.9399 105.008 13.7152V19.6565H102.808V7.32164H105.008V9.85029C105.995 7.75336 108.092 7.17773 109.798 7.17773Z"
        fill="#41395C"
      />
      <path
        d="M95.5157 13.3242H91.4863V19.6561H89.266V2.18164H95.3306C98.8872 2.18164 101.293 4.27857 101.293 7.71178C101.293 11.0628 98.9283 13.3242 95.5157 13.3242ZM95.3306 4.38136H91.4863V11.1039H95.5157C97.5715 11.1039 99.0722 9.70592 99.0722 7.71178C99.0722 5.71764 97.5509 4.38136 95.3306 4.38136Z"
        fill="#41395C"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8954_1108"
          x1="0.932312"
          y1="10"
          x2="20.9323"
          y2="10"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8954_1108"
          x1="30.9325"
          y1="9.60544"
          x2="83.8576"
          y2="9.60544"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_8954_1108"
          x1="30.9325"
          y1="9.60544"
          x2="83.8576"
          y2="9.60544"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_8954_1108"
          x1="30.9325"
          y1="9.60544"
          x2="83.8576"
          y2="9.60544"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_8954_1108"
          x1="30.9325"
          y1="9.60544"
          x2="83.8576"
          y2="9.60544"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_8954_1108"
          x1="30.9325"
          y1="9.60544"
          x2="83.8576"
          y2="9.60544"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <clipPath id="clip0_8954_1108">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.932312)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
