import { EnterIcon } from "./icons/EnterIcon";

export const EnterButtonCard = () => {
  return (
    <div className="t-bg-neutral-0 t-justify-center t-items-center  t-rounded-full t-text-neutral-70 t-px-3 t-py-2 t-text-body-sm t-gap-2 t-flex">
      <EnterIcon />
      <span>Return</span>
    </div>
  );
};
