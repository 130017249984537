import ToolTip from "components/design/toolTip";
import React from "react";
import HomeStateIcon from "static/images/HomeStateIcon.svg";

export const StateColumn = ({
  stateDetail,
  isHomeState,
}: {
  stateDetail: string;
  isHomeState: boolean;
}) => {
  return (
    <div className="t-flex t-items-center t-gap-1 t-py-3">
      <div className="t-text-body t-text-text-60">{stateDetail}</div>
      {isHomeState && (
        <ToolTip text="State of Incorporation">
          <img src={HomeStateIcon} alt="HomeStateIcon" />
        </ToolTip>
      )}
    </div>
  );
};
