import { Divider } from "components/design/Divider";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import {
  Combobox,
  OptionData,
} from "components/DesignSystem/Combobox/Combobox";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import Modal from "components/DesignSystem/Modal/Modal";
import { Stepper } from "components/DesignSystem/Stepper/Stepper";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { TransactionsForWrapper } from "components/GeneralLedger/GeneralLedgerFilters";
import { ArrowRight } from "components/icons/ArrowRight";
import { Cross } from "components/icons/Cross";
import { LoadingIcon } from "components/icons/LoadingIcon";
import { PriceInput } from "components/PriceInput/PriceInput";
import { TransactionsView } from "components/TransactionsView/TransactionsView";
import {
  CategoryIndent,
  coaOptions,
} from "components/Transaction/TransactionColumn";
import { datePeriod } from "constants/bookkeeping";
import { DD_MMM_YYYY, YYYY_MM_DD } from "constants/date";
import dayjs from "dayjs";
import {
  Field,
  FieldProps,
  Form,
  Formik,
  FormikHelpers,
  useFormikContext,
} from "formik";
import { categorisationRuleSchema } from "formValidations/categorisationRuleSchema";
import { AnimatePresence, motion } from "framer-motion";
import { useChartOfAccounts } from "hooks/useChartOfAccounts";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { usePaginatedQuery } from "hooks/usePaginatedQuery";
import { useToast } from "hooks/useToast";
import { EmptyScreen } from "pages/Books/EmptyScreen";
import randomBytes from "randombytes";
import { ReactNode, useId, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { MultiValue, PropsValue, SingleValue } from "react-select";
import {
  useCreateRuleMutation,
  useGetAllConditionsQuery,
  useGetPrefilledRuleQuery,
  useLazyGetRuleTransactionsQuery,
  useMarkRuleActiveMutation,
} from "store/apis/ruleEngine";
import { Transactions } from "types/Models/books";
import { RulesData } from "types/Models/ruleEngine";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";
import { flattenTypes } from "utils/flattenCOA";
import { CategoryLabel } from "components/design/CategoryLabel";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { SourceValue } from "./SourceValue";
import { VendorValue } from "./VendorValue";
import { formatOrdinals } from "utils/formatOrdinals";

export type RuleValuesType = {
  name: string;
  startDate: string;
  endDate: string;
  transactionsFor: string;
  assigned_category_id: string;
  apply_for_future_txns: boolean;
  apply_for_txns_with_no_category: boolean;
  apply_on_all_past_txns: boolean;
  ruleConditionData: {
    id: string;
    field: string;
    conditionFunction: string;
    conditionValue: string | string[];
  }[];
};

const initialRuleConditionData = {
  field: "",
  conditionFunction: "",
  conditionValue: "",
};

export const RuleStepper = ({
  currentStep,
  onStepperClick,
}: {
  onStepperClick: (step: number) => void;
  currentStep: number;
}) => {
  const breadcrumbs = [
    { name: "Apply rule", step: 1 },
    { name: "Preview transactions", step: 2 },
  ];

  return (
    <Stepper size="small" direction="horizontal">
      {breadcrumbs?.map(({ name, step }) => (
        <Stepper.Step
          step={step}
          key={step}
          isActive={currentStep >= step}
          clickable={step === 1}
          onClick={() => onStepperClick(step)}
        >
          <div className="t-flex t-items-center">
            {name}
            {step === 1 && <ArrowRight color="currentColor" />}
          </div>
        </Stepper.Step>
      ))}
    </Stepper>
  );
};

export const TransactionsPreview = ({
  uuid,
  close,
  onSubmit,
  currentStep,
  setCurrentStep,
  linfoLabel = false,
  submitText,
  modalTitle,
}: {
  uuid: string | undefined;
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  close: () => void;
  onSubmit: ({ ruleId }: { ruleId: string }) => void | Promise<any>;
  linfoLabel?: ReactNode;
  submitText: string;
  modalTitle: string;
}) => {
  const infiniteScrollId = useId();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();

  const {
    data: transactionData,
    loadNext,
    isLoading,
    isSuccess,
  } = usePaginatedQuery<{
    rule: RulesData;
    transactions: Transactions[];
  }>(useLazyGetRuleTransactionsQuery, "transactions", {
    groupId,
    entityId,
    ruleId: uuid || "",
  });

  const {
    rule,
    transactions = [],
    current_page = 1,
    total_pages = 1,
    total_count,
  } = transactionData || {};

  const { category } = rule || {};
  const { name } = category || {};
  const isEmpty = transactions.length === 0;

  return (
    <Formik
      initialValues={{
        ruleId: uuid || "",
      }}
      onSubmit={onSubmit}
    >
      {({ submitForm, isSubmitting }) => (
        <Modal.Content size="xl" useCustomOverlay>
          <Modal.Header>
            <div>
              <Modal.Title>{modalTitle}</Modal.Title>
              <Modal.Subtitle>
                <RuleStepper
                  currentStep={currentStep}
                  onStepperClick={setCurrentStep}
                />
              </Modal.Subtitle>
            </div>
            <Modal.Close />
          </Modal.Header>
          <Async.Root {...{ isEmpty, isLoading, isSuccess }}>
            <Async.Empty>
              <EmptyScreen text="No transactions found!" />
            </Async.Empty>

            <Async.Success>
              <Modal.Body className="t-flex t-gap-6 t-flex-col t-overflow-x-hidden">
                <Form
                  className="t-m-0 t-flex t-flex-col t-h-full t-overflow-y-auto t-gap-6"
                  id={infiniteScrollId}
                >
                  {Boolean(linfoLabel) && <>{linfoLabel}</>}
                  <div className="t-flex t-justify-between t-text-subtext t-text-text-60">
                    <span className="t-uppercase">CATEGORY: {name}</span>
                    <span>{total_count} Transactions</span>
                  </div>

                  <InfiniteScroll
                    dataLength={transactions?.length || 0}
                    next={loadNext}
                    hasMore={total_pages > current_page}
                    scrollableTarget={infiniteScrollId}
                    className="t-space-y-5"
                    loader={
                      <div className="t-flex t-justify-center t-items-center t-w-full t-gap-2 t-p-2">
                        <span className="t-flex t-origin-center t-animate-spin">
                          <LoadingIcon />
                        </span>
                        Loading...
                      </div>
                    }
                  >
                    <TransactionsView
                      transactions={transactions.map((t) => t.transaction)}
                    />
                  </InfiniteScroll>
                </Form>
              </Modal.Body>
            </Async.Success>
          </Async.Root>
          <Modal.FooterButtonGroup>
            <Button
              onClick={close}
              disabled={isSubmitting || isLoading}
              type="button"
            >
              Cancel
            </Button>
            <Button
              customType="primary"
              onClick={submitForm}
              isLoading={isSubmitting}
              disabled={isSubmitting || isLoading}
            >
              {submitText}
            </Button>
          </Modal.FooterButtonGroup>
        </Modal.Content>
      )}
    </Formik>
  );
};

export const RuleConsoleItems = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) => {
  return (
    <div className="t-flex t-flex-col t-gap-4">
      <span className="t-text-subtext t-text-text-100">{title}</span>
      {children}
    </div>
  );
};

const TitleAndDuration = () => {
  const { values, setFieldValue, errors } = useFormikContext<RuleValuesType>();
  const { startDate, endDate } = values;

  const customComboboxOptions = useMemo(
    () => [
      {
        label: "All past transactions",
        value: "allPastTransactions",
      },
      {
        label: "All future transactions",
        value: "allFutureTransactions",
      },
      { label: "All transactions", value: "allTransactions" },
      { label: "Custom", value: "custom" },
    ],
    []
  );

  const customOptions = customComboboxOptions.map(({ value }) => value);

  const onDurationChange = (
    option: MultiValue<OptionData> | SingleValue<OptionData>,
    handleChange: ({ value }: { value?: string }) => void
  ) => {
    if (option instanceof Array) {
      return null;
    }

    if (option?.value && customOptions.includes(option?.value)) {
      switch (option?.value) {
        case "allFutureTransactions": {
          setFieldValue("startDate", dayjs().format(DD_MMM_YYYY));
          setFieldValue("endDate", null);
          setFieldValue("apply_for_future_txns", true);
          setFieldValue("apply_on_all_past_txns", false);
          return;
        }
        case "allPastTransactions": {
          setFieldValue("startDate", null);
          setFieldValue("endDate", dayjs().format(DD_MMM_YYYY));
          setFieldValue("apply_on_all_past_txns", true);
          return;
        }
        case "allTransactions": {
          setFieldValue("startDate", null);
          setFieldValue("endDate", null);
          setFieldValue("apply_for_future_txns", true);
          setFieldValue("apply_on_all_past_txns", true);
          return;
        }
        case "custom": {
          setFieldValue("startDate", null);
          setFieldValue("endDate", null);
          setFieldValue("apply_for_future_txns", false);
          setFieldValue("apply_on_all_past_txns", false);
          return;
        }
      }
    } else {
      handleChange({ value: option?.value });
    }
  };

  return (
    <RuleConsoleItems title="Title">
      <div className="t-flex t-gap-4 t-flex-col">
        <div className="t-flex t-gap-4">
          <TextInput
            name="name"
            label="Rule name"
            placeholder="Rule#1"
            required
          />
          <TransactionsForWrapper>
            {({ handleChange }) => (
              <Combobox
                withForm
                name="transactionsFor"
                label="Duration"
                placeholder="Select period"
                onChange={(
                  option: MultiValue<OptionData> | SingleValue<OptionData>
                ) => onDurationChange(option, handleChange)}
                components={{ ClearIndicator: () => null }}
                options={[...datePeriod, ...customComboboxOptions]}
                menuPortalTarget={document.body}
                block
                required
              />
            )}
          </TransactionsForWrapper>
        </div>
        {values?.transactionsFor === "custom" && (
          <div className="t-flex t-gap-4">
            <Field name="startDate">
              {({ field }: FieldProps) => {
                return (
                  <DateInput
                    {...field}
                    maxDate={endDate ? new Date(endDate) : new Date()}
                    label="From"
                    portalId="transaction-start"
                    block
                    required
                  />
                );
              }}
            </Field>
            <Field name="endDate">
              {({ field }: FieldProps) => {
                return (
                  <DateInput
                    {...field}
                    maxDate={new Date()}
                    {...(startDate ? { minDate: new Date(startDate) } : {})}
                    label="To"
                    portalId="transaction-date"
                    block
                    required
                  />
                );
              }}
            </Field>
          </div>
        )}
      </div>
    </RuleConsoleItems>
  );
};

const ConditionWrapper = ({
  itemId,
  children,
}: {
  children: ({ field }: { field: string | undefined }) => ReactNode;
  itemId: string;
}) => {
  const { values } = useFormikContext<RuleValuesType>();
  const { ruleConditionData = [] } = values || {};
  const { field } = ruleConditionData.find(({ id }) => id === itemId) || {};

  return <>{children({ field })}</>;
};

export const ConditionValue = ({
  index,
  field,
  defaultValue,
}: {
  index: number;
  field: string | undefined;
  defaultValue?: PropsValue<OptionData>;
}) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();

  const { data: conditions = [] } = useGetAllConditionsQuery(
    {
      groupId,
      entityId,
    },
    { skip: !groupId || !entityId }
  );

  const conditionFunctions = conditions.find(
    ({ uuid, name }) => field === uuid || name === field
  );
  const { possible_values = [], value_supported } = conditionFunctions || {};

  const conditionValueOptions = possible_values.map(({ name, uuid }) => ({
    label: <span className="t-lowercase">{name}</span>,
    value: name,
  }));

  switch (value_supported) {
    case "SOURCE": {
      return (
        <SourceValue
          index={index}
          label={index === 0 ? "Value" : ""}
          name={`ruleConditionData[${index}].conditionValue`}
        />
      );
    }

    case "VENDOR": {
      return (
        <VendorValue
          index={index}
          label={index === 0 ? "Value" : ""}
          name={`ruleConditionData[${index}].conditionValue`}
        />
      );
    }
    case "CHOICE": {
      return (
        <Combobox
          aria-label={`${formatOrdinals(index + 1)} Value`}
          key={field}
          label={index === 0 ? "Value" : ""}
          name={`ruleConditionData[${index}].conditionValue`}
          required
          withForm
          hideError
          placeholder="Select"
          menuPortalTarget={document.body}
          options={conditionValueOptions}
          isClearable={false}
          block
          defaultValue={Boolean(defaultValue) ? defaultValue : undefined}
        />
      );
    }

    case "DECIMAL": {
      return (
        <PriceInput
          aria-label={`${formatOrdinals(index + 1)} Value`}
          label={index === 0 ? "Value" : ""}
          name={`ruleConditionData[${index}].conditionValue`}
          required
          placeholder="Enter amount"
          hideError
          showErrorOnceTouched
        />
      );
    }

    case "TEXT": {
      return (
        <TextInput
          aria-label={`${formatOrdinals(index + 1)} Value`}
          label={index === 0 ? "Value" : ""}
          name={`ruleConditionData[${index}].conditionValue`}
          required
          placeholder="Enter value"
          hideError
          showErrorOnceTouched
        />
      );
    }

    default:
      return (
        <TextInput
          aria-label={`${formatOrdinals(index + 1)} Value`}
          disabled
          label={index === 0 ? "Value" : ""}
          required
          placeholder="Enter value"
          name={`ruleConditionData[${index}].conditionValue`}
          hideError
          showErrorOnceTouched
        />
      );
  }
};

export const ConditionFunction = ({
  index,
  field,
  defaultValue,
}: {
  index: number;
  field: string | undefined;
  defaultValue?: PropsValue<OptionData>;
}) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();

  const { data: conditions = [], isLoading } = useGetAllConditionsQuery(
    {
      groupId,
      entityId,
    },
    { skip: !groupId || !entityId }
  );

  const conditionFunctions = conditions.find(
    ({ uuid, name }) => field === uuid || name === field
  );

  const { available_operators = [] } = conditionFunctions || {};

  const conditionFunctionOptions = available_operators.map(
    ({ name, uuid }) => ({
      label: <span className="t-lowercase">{name}</span>,
      value: uuid,
    })
  );

  return (
    <Combobox
      aria-label={`${formatOrdinals(index + 1)} Function`}
      key={field}
      isDisabled={isLoading || !Boolean(available_operators.length)}
      placeholder="Select"
      menuPortalTarget={document.body}
      name={`ruleConditionData[${index}].conditionFunction`}
      withForm
      label={index === 0 ? "Function" : ""}
      required
      options={conditionFunctionOptions}
      isClearable={false}
      block
      hideError
      styles={{
        menuPortal: (base) => ({
          ...base,
          width: 250,
        }),
      }}
      defaultValue={Boolean(defaultValue) ? defaultValue : undefined}
    />
  );
};

const Conditions = () => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { values, setFieldValue } = useFormikContext<RuleValuesType>();
  const { ruleConditionData = [] } = values || {};

  const {
    data: conditions = [],
    isLoading,
    isSuccess,
  } = useGetAllConditionsQuery(
    {
      groupId,
      entityId,
    },
    { skip: !groupId || !entityId }
  );

  const onFieldChange = (index: number) => {
    setFieldValue(`ruleConditionData[${index}].conditionFunction`, "");
    setFieldValue(`ruleConditionData[${index}].conditionValue`, "");
  };

  const addNewCondition = () => {
    if (ruleConditionData.length <= 3) {
      const newValues = [
        ...values.ruleConditionData,
        {
          id: randomBytes(10).toString("hex"),
          ...initialRuleConditionData,
        },
      ];
      setFieldValue("ruleConditionData", newValues);
    }
  };

  const onRemove = (conditionId: string) => {
    if (ruleConditionData.length > 1) {
      const newValues = ruleConditionData.filter(({ id }) => {
        return id !== conditionId;
      });

      setFieldValue("ruleConditionData", newValues);
    }
  };

  const fieldOptions = conditions
    .filter(
      ({ uuid }) => !ruleConditionData.find(({ field }) => field === uuid)
    )
    .map(({ name, uuid }) => ({
      label: name,
      value: uuid,
    }));

  if (isSuccess) {
    return (
      <RuleConsoleItems title="Define Conditions">
        <AnimatePresence>
          {ruleConditionData.map(({ id, conditionFunction, field }, i) => {
            const defaultConditionField = conditions.find(
              ({ uuid }) => uuid === field
            );

            const defaultConditionFunction =
              defaultConditionField?.available_operators.find(
                ({ uuid }) => uuid === conditionFunction
              );

            return (
              <motion.div
                className="t-flex t-gap-4"
                key={id}
                layout
                transition={{ duration: 0.1, ease: "easeOut" }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: -500, opacity: 0 }}
              >
                <ConditionWrapper itemId={id}>
                  {({ field }) => (
                    <div className="t-grid t-grid-cols-3 t-gap-4 t-w-full">
                      <Combobox
                        label={i === 0 ? "Field" : ""}
                        aria-label={`${formatOrdinals(i + 1)} Field`}
                        isDisabled={isLoading}
                        placeholder="Select"
                        menuPortalTarget={document.body}
                        name={`ruleConditionData[${i}].field`}
                        withForm
                        options={fieldOptions}
                        isClearable={false}
                        block
                        required
                        onChange={() => onFieldChange(i)}
                        {...{
                          defaultValue:
                            Boolean(defaultConditionField?.uuid) && i === 0
                              ? {
                                  label: defaultConditionField?.name || "",
                                  value: defaultConditionField?.uuid || "",
                                }
                              : undefined,
                        }}
                      />
                      <ConditionFunction
                        index={i}
                        field={field}
                        {...{
                          defaultValue: Boolean(defaultConditionFunction?.uuid)
                            ? {
                                label: defaultConditionFunction?.name || "",
                                value: defaultConditionFunction?.uuid || "",
                              }
                            : undefined,
                        }}
                      />
                      <ConditionValue index={i} field={field} />
                    </div>
                  )}
                </ConditionWrapper>
                <div className="t-self-end">
                  <Button
                    customType="ghost_icon"
                    onClick={() => onRemove(id)}
                    disabled={ruleConditionData.length === 1}
                    type="button"
                  >
                    <Cross />
                  </Button>
                </div>
              </motion.div>
            );
          })}
        </AnimatePresence>

        <div>
          <Button
            size="small"
            onClick={addNewCondition}
            disabled={ruleConditionData.length === 4}
            type="button"
            customType="link"
          >
            Add a condition
          </Button>
        </div>
      </RuleConsoleItems>
    );
  }

  return null;
};

const RuleApply = () => {
  return (
    <div className="t-flex t-gap-2 t-flex-col">
      <Field name="apply_for_txns_with_no_category">
        {({ field }: FieldProps) => (
          <Checkbox
            {...field}
            label="Only apply to transactions with no category"
            defaultChecked={true}
          />
        )}
      </Field>
    </div>
  );
};

export const Categories = ({
  assigned_category_id,
}: {
  assigned_category_id: string;
}) => {
  const { chartOfAccounts = [], isLoading } = useChartOfAccounts({
    hiddenCategoryTypes: ["BANK_ACCOUNT"],
  });

  const defaultAssignedCategory = flattenTypes({
    accounts: chartOfAccounts,
  }).find(({ uuid }) => uuid === assigned_category_id);

  if (isLoading) {
    return null;
  }

  return (
    <RuleConsoleItems title="Assign Categories">
      <Combobox
        aria-label="Assign Categories"
        menuPlacement="top"
        isDisabled={isLoading}
        placeholder="Select"
        menuPortalTarget={document.body}
        withForm
        name="assigned_category_id"
        options={coaOptions(chartOfAccounts)}
        filterOption={(v, i) =>
          v.data.data?.toLocaleLowerCase().includes(i.toLocaleLowerCase()) ||
          false
        }
        value={
          assigned_category_id
            ? {
                label: (
                  <CategoryIndent
                    label={<CategoryLabel category={defaultAssignedCategory} />}
                    indentLevel={0}
                  />
                ),
                value: defaultAssignedCategory?.uuid || "",
              }
            : null
        }
      />
    </RuleConsoleItems>
  );
};

const RuleConsole = ({
  close,
  onSubmit,
  currentStep,
  setCurrentStep,
}: {
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  close: () => void;
  onSubmit: (
    values: RuleValuesType,
    formikHelpers: FormikHelpers<RuleValuesType>
  ) => void | Promise<any>;
}) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();

  const { categoryId, merchantIds } = useSelector(
    (state: RootState) => state.ruleEngine
  );

  const { data: prefilledRuleData, isSuccess } = useGetPrefilledRuleQuery(
    {
      groupId,
      entityId,
      categoryId,
      merchantIds,
    },
    { skip: !groupId || !entityId || !categoryId || !merchantIds }
  );

  const { conditions = [] } = prefilledRuleData || {};

  const ruleConditionData = useMemo(() => {
    return conditions.length > 0
      ? conditions.map(({ operand, operator, values }) => {
          return {
            id: randomBytes(10).toString("hex"),
            ...{
              field: operand?.uuid || "",
              conditionFunction: operator?.uuid || "",
              conditionValue: values || "",
            },
          };
        })
      : [
          {
            id: randomBytes(10).toString("hex"),
            ...{
              field: "",
              conditionFunction: "",
              conditionValue: "",
            },
          },
        ];
  }, [isSuccess]);

  return (
    <Formik
      initialValues={{
        name: "",
        startDate: "",
        endDate: "",
        transactionsFor: "",
        assigned_category_id: categoryId || "",
        apply_for_future_txns: true,
        apply_on_all_past_txns: false,
        apply_for_txns_with_no_category: true,
        ruleConditionData,
      }}
      validateOnChange
      validationSchema={categorisationRuleSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({
        submitForm,
        validateForm,
        isSubmitting,
        values: { assigned_category_id, ...restValue },
      }) => (
        <Modal.Content size="xl" useCustomOverlay>
          <Modal.Header>
            <div>
              <Modal.Title>Create new rule</Modal.Title>
              <Modal.Subtitle>
                <RuleStepper
                  currentStep={currentStep}
                  onStepperClick={setCurrentStep}
                />
              </Modal.Subtitle>
            </div>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            <Form>
              <div className="t-flex t-flex-col t-gap-6">
                <TitleAndDuration />
                <Divider />
                <Conditions />
                <Categories assigned_category_id={assigned_category_id} />
                <RuleApply />
              </div>
            </Form>
          </Modal.Body>
          <Modal.FooterButtonGroup>
            <Button onClick={close} disabled={isSubmitting} type="button">
              Cancel
            </Button>
            <Button
              customType="primary"
              onClick={submitForm}
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              Preview transactions
            </Button>
          </Modal.FooterButtonGroup>
        </Modal.Content>
      )}
    </Formik>
  );
};

export const CreateRule = ({ close, isOpen }: ModalProps) => {
  const { alertToast, successToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const [currentStep, setCurrentStep] = useState(1);
  const [markRuleActive] = useMarkRuleActiveMutation();
  const [createRule, { data }] = useCreateRuleMutation();

  const { uuid } = data || {};

  const onClose = () => {
    setCurrentStep(1);
    close();
  };

  const onCreate = async (
    { endDate, startDate, ruleConditionData, ...rest }: RuleValuesType,
    { resetForm }: FormikHelpers<RuleValuesType>
  ) => {
    try {
      await createRule({
        entityId,
        groupId,
        rule_condition_data: ruleConditionData.map(
          ({ conditionFunction, conditionValue, field }) => ({
            value: conditionValue,
            operator_id: conditionFunction,
            operand_id: field,
          })
        ),
        rule_end_date: endDate ? dayjs(endDate).format(YYYY_MM_DD) : undefined,
        rule_start_date: startDate
          ? dayjs(startDate).format(YYYY_MM_DD)
          : undefined,
        ...rest,
      }).unwrap();
      setCurrentStep(2);
      resetForm();
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const onActive = async ({ ruleId }: { ruleId: string }) => {
    try {
      await markRuleActive({ entityId, groupId, ruleId }).unwrap();
      onClose();
      successToast({ message: "Rule created" });
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={onClose} modal={false}>
      {currentStep === 1 ? (
        <RuleConsole
          onSubmit={onCreate}
          close={close}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />
      ) : (
        <TransactionsPreview
          uuid={uuid}
          onSubmit={onActive}
          close={onClose}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          submitText="Create and apply"
          modalTitle="Create new rule"
        />
      )}
    </Modal.Root>
  );
};
