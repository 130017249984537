import { DownloadIcon } from "components/icons/Download";
import { debounce } from "utils/debouncing";
import { Button } from "../DesignSystem/Button/Button";
import { Search } from "components/DesignSystem/Search/Search";

export function Header({
  setShowModal,
  searchedTerm,
  taskStates,
  selectedTaskState,
  update,
  exportCsv,
  isDownloadingCsv,
}) {
  const searchTerm = debounce((e) => {
    const { value } = e.target;
    if (value) {
      update({ query: "search_term", value: value });
    } else {
      update({ query: "search_term", value: null });
    }
  });

  return (
    <div className="t-w-full">
      <div className="t-flex t-pb-3 t-pl-0 t-w-full t-justify-between">
        <div className="t-w-1/3">
          <Search
            onChange={searchTerm}
            placeholder="  Search"
            defaultValue={searchedTerm}
            block
          />
        </div>

        <div className="t-ml-auto t-flex t-gap-4">
          <Button
            onClick={exportCsv}
            customType="icon"
            size="small"
            isLoading={isDownloadingCsv}
          >
            <span className="t-text-text-30">
              <DownloadIcon color="currentColor" size={20} />
            </span>
          </Button>
          <Button
            size="small"
            customType="primary"
            onClick={() => setShowModal(true)}
          >
            New group
          </Button>
        </div>
      </div>
    </div>
  );
}
