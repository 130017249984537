import { InkleBooksSymbolSmall } from "components/icons/Logos/InkleBooksSymbolSmall";
import { InkleCommunitySymbolSmall } from "components/icons/Logos/InkleCommunitySymbolSmall";
import { InkleIntragroupSymbolSmall } from "components/icons/Logos/InkleIntragroupSymbolSmall";
import { InkleMailroomSymbolSmall } from "components/icons/Logos/InkleMailroomSymbolSmall";
import { InklePracticeSymbol } from "components/icons/Logos/InklePracticeSymbol";
import { InkleSalesTaxSymbolSmall } from "components/icons/Logos/InkleSalesTaxSymbolSmall";
import { InkleTaxSymbolSmall } from "components/icons/Logos/InkleTaxSymbolSmall";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useReducer,
  Dispatch,
} from "react";

type AppName =
  | "Tax Admin"
  | "Books Admin"
  | "Tax Practice"
  | "Books"
  | "Tax"
  | "Mailroom"
  | "Sales Tax"
  | "Intragroup"
  | "Community";

export interface App {
  name: AppName;
  AppSymbol: React.ComponentType<any>;
  redirectUrl: string;
}

type CurrentApp = {
  name: AppName;
  url: string;
};

export interface CurrentAppContextType {
  currentApp: CurrentApp;
  dispatch: Dispatch<{ type: string; payload: { currentApp: AppName } }>;
  appList: App[];
}

const adminAppList: App[] = [
  { name: "Tax Admin", AppSymbol: InkleTaxSymbolSmall, redirectUrl: "/admin" },
  {
    name: "Books Admin",
    AppSymbol: InkleBooksSymbolSmall,
    redirectUrl: "/books",
  },
];

const fcaAppList: App[] = [
  {
    name: "Tax Practice",
    AppSymbol: InklePracticeSymbol,
    redirectUrl: "/practice",
  },
  {
    name: "Books Admin",
    AppSymbol: InkleBooksSymbolSmall,
    redirectUrl: "/books",
  },
];

const customerAppList: App[] = [
  { name: "Books", AppSymbol: InkleBooksSymbolSmall, redirectUrl: "/books" },
  { name: "Tax", AppSymbol: InkleTaxSymbolSmall, redirectUrl: "/tax" },
  {
    name: "Mailroom",
    AppSymbol: InkleMailroomSymbolSmall,
    redirectUrl: "/mailroom",
  },
  {
    name: "Sales Tax",
    AppSymbol: InkleSalesTaxSymbolSmall,
    redirectUrl: "/sales-tax",
  },
  {
    name: "Intragroup",
    AppSymbol: InkleIntragroupSymbolSmall,
    redirectUrl: "/intragroup",
  },
  {
    name: "Community",
    AppSymbol: InkleCommunitySymbolSmall,
    redirectUrl: "/community",
  },
];

export const CurrentAppContext = createContext<
  CurrentAppContextType | undefined
>(undefined);

export const CurrentAppContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { isCustomer, isForeignCA } = useRoleBasedView();

  const initialState: { currentApp: CurrentApp } = {
    currentApp: {
      name: isCustomer ? "Books" : isForeignCA ? "Tax Practice" : "Tax Admin",
      url: isCustomer ? "/books" : isForeignCA ? "/practice" : "/admin",
    },
  };

  const appList = useMemo(() => {
    if (isCustomer) return customerAppList;
    if (isForeignCA) return fcaAppList;
    return adminAppList;
  }, [isCustomer, isForeignCA]);

  const reducer = (
    state: {
      currentApp: CurrentApp;
    },
    action: {
      type: string;
      payload: { currentApp: AppName };
    }
  ) => {
    switch (action.type) {
      case "SET_CURRENT_APP":
        const app = appList.find(
          (app) => app.name === action.payload.currentApp
        );
        return app
          ? { ...state, currentApp: { name: app.name, url: app.redirectUrl } }
          : state;

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const contextValue = useMemo(
    () => ({ ...state, dispatch, appList }),
    [state, dispatch, appList]
  );

  return (
    <CurrentAppContext.Provider value={contextValue}>
      {children}
    </CurrentAppContext.Provider>
  );
};

export const useCurrentAppContext = (): CurrentAppContextType => {
  const context = useContext(CurrentAppContext);
  if (!context) {
    throw new Error(
      "useCurrentAppContext must be used within a CurrentAppContextProvider"
    );
  }

  return context;
};
