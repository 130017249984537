import classNames from "classnames";
import { ParentSelector } from "components/ChartOfAccounts/ParentSelector";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import { CategoryLabel } from "components/design/CategoryLabel";
import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import Table from "components/DesignSystem/Table/V2/Table";
import { Label, TextInput } from "components/DesignSystem/TextInput/TextInput";
import { DeleteIcon } from "components/icons/delete";
import { MagnifyingGlass } from "components/icons/MagnifyingGlass";
import { PriceInput } from "components/PriceInput/PriceInput";
import {
  MerchantComponent,
  MerchantSelector,
} from "components/Transaction/MerchantSelector";
import {
  CategoryIndent,
  coaOptions,
} from "components/Transaction/TransactionColumn";
import { useFormikContext } from "formik";
import { AnimatePresence, motion } from "framer-motion";
import { useChartOfAccounts } from "hooks/useChartOfAccounts";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { EmptyScreen } from "pages/Books/EmptyScreen";
import randomBytes from "randombytes";
import { useMemo } from "react";
import { SingleValue } from "react-select";
import {
  CellContext,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import {
  useCreateMerchantMutation,
  useGetMerchantByIdQuery,
} from "store/apis/merchant";
import { useLazyGetPreviewUrlQuery } from "store/apis/previewUrl";
import { useUploadManualTransactionInvoiceMutation } from "store/apis/transactions";
import { BackendError } from "types/utils/error";
import { flattenTypes } from "utils/flattenCOA";
import { openLink } from "utils/openLink";
import { initialTransaction, Transaction } from "./AddJournalEntryModal";
import { FileInput, FileType } from "components/FileInput/FileInput";
import { EnterButtonCard } from "components/EnterButtonCard";
import { onEnterKey } from "utils/onEnterKey";
import { HiddenCategoryTypes } from "store/apis/chartOfAccounts";
import { validate } from "uuid";
import { BankSelector } from "components/BankSelector/BankSelector";
import { CategorySelector } from "components/CategorySelector/CategorySelector";

export const Vendor = ({
  id,
  merchant,
  creatable = false,
}: {
  id: string;
  merchant: string;
  creatable?: boolean;
}) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { setFieldValue, values: formValues } = useFormikContext<{
    transactions: Transaction[];
  }>();

  const { data, isLoading } = useGetMerchantByIdQuery(
    { groupId, merchantId: merchant || "" },
    { skip: !groupId || !merchant }
  );
  const { logo, name = "" } = data || {};

  const selected = merchant
    ? {
        value: name,
        label: <MerchantComponent logo={logo} name={name} />,
        merchantId: merchant,
        data: name,
      }
    : null;

  const merchantChange = async (values: SingleValue<any>) => {
    setFieldValue(`transactions[${id}].merchant`, values?.merchantId || null);
  };

  const { alertToast } = useToast();
  const [createMerchant] = useCreateMerchantMutation();

  const onCreateMerchant = async (name: string) => {
    try {
      const index = Number(id);
      const { transactions } = formValues;
      const txn_uuid = transactions[index].uuid;
      if (txn_uuid) {
        const payload = new FormData();

        payload.append("name", name);
        if (validate(txn_uuid)) {
          payload.append("txn_uuid", txn_uuid);
        }

        const data = await createMerchant({
          groupId,
          entityId,
          payload,
        }).unwrap();
        setFieldValue(`transactions[${id}].merchant`, data.merchant_id);
      }
    } catch (error) {
      alertToast({ message: (error as BackendError)?.data?.error?.message });
    }
  };

  return (
    <MerchantSelector
      isLoading={isLoading}
      isDisabled={isLoading}
      merchantChange={merchantChange}
      onCreateMerchant={onCreateMerchant}
      withForm
      name={`transactions[${id}].merchant`}
      value={selected}
      creatable={creatable}
    />
  );
};

export const BankAccount = ({
  id,
  account_id,
}: {
  id: string;
  account_id: string;
}) => {
  return (
    <BankSelector
      name={`transactions[${id}].account_id`}
      withForm
      placeholder="Select"
      menuPortalTarget={document.body}
      styles={{
        menuPortal: (base) => ({
          ...base,
          width: 450,
        }),
      }}
    />
  );
};

export const Description = (info: CellContext<Transaction, string>) => {
  const id = info.row.id;

  return (
    <TextInput
      name={`transactions[${id}].description`}
      placeholder="Enter description"
      hideError
      showErrorOnceTouched
    />
  );
};

export const Invoice = ({
  id,
  invoice,
}: {
  id: string;
  invoice: Transaction["invoice"];
}) => {
  const { alertToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const [getPreviewUrl] = useLazyGetPreviewUrlQuery();

  const [uploadManualTransactionInvoice, { isLoading: isUploading }] =
    useUploadManualTransactionInvoiceMutation();

  const { setFieldValue } = useFormikContext<{
    transactions: Transaction[];
  }>();

  const onDrop = async (files: FileType[]) => {
    try {
      const file = await uploadManualTransactionInvoice({
        invoice: files[0] as File,
        entityId,
        groupId,
      }).unwrap();
      setFieldValue(`transactions[${id}].invoice`, file);
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const onPreview = async ({ fileId }: { fileId: string }) => {
    try {
      const { preview_url } = await getPreviewUrl({ groupId, fileId }).unwrap();
      openLink(preview_url);
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const onInvoiceDelete = () => {
    setFieldValue(`transactions[${id}].invoice`, null);
  };

  return (
    <FileInput
      file={
        invoice
          ? {
              uuid: invoice.uuid,
              name: invoice?.name,
              file_type: invoice?.file_type,
              is_previewable: invoice?.is_previewable,
            }
          : null
      }
      onDrop={onDrop}
      isUploading={isUploading}
      variant="icon"
      onDelete={onInvoiceDelete}
      onFileClick={({ e, uuid: fileId }) => onPreview({ fileId })}
    />
  );
};

export const Delete = ({ id }: { id: string }) => {
  const { values, setFieldValue } = useFormikContext<{
    transactions: Transaction[];
  }>();

  const onDelete = () => {
    const newValues = values.transactions.filter((transaction) => {
      return transaction.id !== id;
    });

    setFieldValue("transactions", newValues);
  };

  return (
    <Button customType="ghost_icon" onClick={onDelete}>
      <DeleteIcon />
    </Button>
  );
};

export const Amount = ({
  id,
  type,
}: {
  id: string;
  type?: "debit" | "credit";
}) => {
  const { setFieldValue } = useFormikContext<{
    transactions: Transaction[];
  }>();

  const onValueChange = () => {
    const inversType = type === "debit" ? "credit" : "debit";
    setFieldValue(`transactions[${id}].${inversType}`, 0);
  };

  return (
    <PriceInput
      aria-label={`${type} input`}
      name={`transactions[${id}].${type}`}
      hideError
      allowNegative={false}
      onValueChange={onValueChange}
      placeholder={`transactions[${id}].${type}`}
    />
  );
};

export const AddJournalEntryTable = () => {
  const { setFieldValue, values } = useFormikContext<{
    transactions: Transaction[];
  }>();

  const addAnotherTransaction = () => {
    const newValues = [
      ...values.transactions,
      {
        id: randomBytes(10).toString("hex"),
        ...initialTransaction,
      },
    ];
    setFieldValue("transactions", newValues);
  };

  const createColumn = createColumnHelper<Transaction>();

  const columns = useMemo(
    () => [
      createColumn.accessor("description", {
        size: 15,
        header: () => <Label required>DESCRIPTION</Label>,
        cell: Description,
      }),

      createColumn.accessor("merchant", {
        size: 15,
        header: () => <Label>VENDOR</Label>,
        cell: (info) => {
          const id = info.row.id;
          const { merchant = "" } = info.row.original || {};

          return <Vendor id={id} merchant={merchant} creatable />;
        },
      }),

      createColumn.accessor("category", {
        size: 20,
        header: () => <Label required>Category</Label>,
        cell: (info) => {
          const id = info.row.id;
          const { category = "" } = info.row.original || {};

          return (
            <CategorySelector
              name={`transactions[${id}].category`}
              category={category}
              hiddenCategories={[]}
            />
          );
        },
      }),

      createColumn.accessor("debit", {
        size: 10,
        header: () => (
          <div className="t-flex t-justify-end">
            <Label required>debit</Label>
          </div>
        ),
        cell: (info) => {
          const id = info.row.id;

          return <Amount id={id} type="debit" />;
        },
      }),

      createColumn.accessor("credit", {
        size: 10,
        header: () => (
          <div className="t-flex t-justify-end">
            <Label required>credit</Label>
          </div>
        ),
        cell: (info) => {
          const id = info.row.id;

          return <Amount id={id} type="credit" />;
        },
      }),

      createColumn.accessor("invoice", {
        size: 5,
        header: "",
        cell: (info) => {
          const id = info.row.id;
          const invoice = info.getValue();

          return (
            <Invoice
              id={id}
              invoice={
                invoice
                  ? {
                      file_type: invoice?.file_type || "IMAGE",
                      is_previewable: invoice?.is_previewable || false,
                      name: invoice?.name || "",
                      uuid: invoice?.uuid || "",
                    }
                  : null
              }
            />
          );
        },
      }),

      createColumn.accessor("id", {
        size: 5,
        header: "",
        cell: (info) => {
          const { id } = info.row.original;

          return <Delete id={id} />;
        },
      }),
    ],
    []
  );

  const table = useReactTable({
    data: values.transactions || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 1,
      maxSize: 100,
    },
  });

  const totalDebit = values.transactions.reduce(
    (acc, { debit }) => acc + Number(debit),
    0
  );

  const totalCredit = values.transactions.reduce(
    (acc, { credit }) => acc + Number(credit),
    0
  );

  const noTransaction = values?.transactions?.length === 0;
  const amountDiff = Math.abs(totalDebit - totalCredit);

  return (
    <>
      {noTransaction ? (
        <EmptyScreen text="No journal entry found" className="!t-h-56">
          <span className="t-text-i-neutral-10">
            <MagnifyingGlass size="149" />
          </span>
        </EmptyScreen>
      ) : (
        <Table.Container>
          <Table.Content>
            <Table.Head>
              {table.getHeaderGroups().map((headerGroup) => (
                <Table.Row key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Table.HeadCell
                      key={header.id}
                      style={{ width: `${header.getSize()}%` }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </Table.HeadCell>
                  ))}
                </Table.Row>
              ))}
            </Table.Head>
            <Table.Body>
              <AnimatePresence>
                {table.getRowModel().rows.map((row) => (
                  <motion.tr
                    layout
                    key={row.original.id}
                    transition={{ duration: 0.1, ease: "easeOut" }}
                    animate={{ x: 0 }}
                    exit={{ x: -1000 }}
                    className="t-px-3 t-border-solid t-border-neutral-0 t-border-b t-border-0 t-text-body"
                  >
                    {row.getVisibleCells().map((cell) => (
                      <Table.Cell
                        key={cell.id}
                        style={{ width: `${cell.column.getSize()}%` }}
                        onKeyDown={onEnterKey(addAnotherTransaction)}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Table.Cell>
                    ))}
                  </motion.tr>
                ))}
              </AnimatePresence>
              <Table.Row
                className={classNames("!t-border-none t-sticky t-bg-white", {
                  "t-bottom-[37px]": Boolean(amountDiff),
                  "t-bottom-0": !Boolean(amountDiff),
                })}
              >
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell className="t-text-end t-p-2 t-text-subtitle-sm t-text-text-30">
                  Total
                </Table.Cell>
                <Table.Cell className="t-p-2 t-text-subtitle-sm t-px-5">
                  <AmountSuperScript amount={totalDebit} />
                </Table.Cell>
                <Table.Cell className="t-p-2 t-text-subtitle-sm t-px-5">
                  <AmountSuperScript amount={totalCredit} />
                </Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>
              {Boolean(amountDiff) && (
                <Table.Row className="!t-border-none t-sticky t-bottom-0 t-bg-white">
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell className="t-text-end t-p-2 t-text-subtitle-sm t-text-red">
                    Difference
                  </Table.Cell>
                  <Table.Cell className="t-p-2 t-text-subtitle-sm t-px-5 t-text-red">
                    <AmountSuperScript amount={amountDiff} />
                  </Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table.Content>
        </Table.Container>
      )}
      <div className="t-bg-white t-bottom-0 t-flex t-items-center t-gap-2 t-pb-4">
        <Button type="button" onClick={addAnotherTransaction}>
          Add
          {noTransaction ? " item" : " more"}
        </Button>
        <span className=" t-text-button t-text-neutral-30">or</span>
        <EnterButtonCard />
      </div>
    </>
  );
};
