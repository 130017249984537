export const BankLogo = ({
  color = "#39395C",
  size = 16,
}: {
  color?: string;
  size?: number;
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2546_33170)">
        <path
          d="M1.5 6.40234H14.5L8 2.40234L1.5 6.40234Z"
          stroke="#706A85"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.5 6.40234V11.4023"
          stroke="#706A85"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.5 6.40234V11.4023"
          stroke="#706A85"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.5 6.40234V11.4023"
          stroke="#706A85"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.5 6.40234V11.4023"
          stroke="#706A85"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2 11.4023H14"
          stroke="#706A85"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1 13.4023H15"
          stroke="#706A85"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2546_33170">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.402344)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
