export const TASK_CHANNEL = "TASK_CHANNEL";
export const COMPANY_GROUP_CUSTOM_TYPE = "GROUP_CUSTOM_TYPE";
export const DATAROOM_CUSTOM_TYPE = "DATAROOM_CHANNEL";
export const MAILROOM_CUSTOM_TYPE = "MAILROOM_CHANNEL";
export const INKLE_CPA_TEAM_CHAT_TYPE = "INKLE_CPA_TEAM_CHANNEL";
export const ANNOUNCEMENTS_CHANNEL_CUSTOM_TYPE = "ANNOUNCEMENTS_CHANNEL";
export const PRIVATE_CHANNEL_CUSTOM_TYPE = "PRIVATE_CHANNEL";
export const BOOKS_CHANNEL_CUSTOM_TYPE = "BOOKS_CHANNEL";
export const INKLE_FCA_TEAM_CHANNEL = "INKLE_FCA_TEAM_CHAT_TYPE";
export const TICKET_CHANNEL = "TICKET_CHANNEL";
