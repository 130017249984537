import classNames from "classnames";
import { Divider } from "components/design/Divider";
import { Avatar } from "components/DesignSystem/AvatarGroup/Avatar";
import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import {
  Combobox,
  OptionData,
} from "components/DesignSystem/Combobox/Combobox";
import Modal from "components/DesignSystem/Modal/Modal";
import { ArrowDown } from "components/icons/ArrowDown";
import { Cross } from "components/icons/Cross";
import { useToast } from "hooks/useToast";
import { useMemo, useState } from "react";
import { MultiValue } from "react-select";
import {
  useForwardMessageToWhatsappMutation,
  useGetAllChannelMemberQuery,
} from "store/apis/chat";
import Whatsapp from "../../static/images/WhatsappIcon.svg";
import {
  MessageUIComponentProps,
  useChannelStateContext,
} from "stream-chat-react";
import { BackendError } from "types/utils/error";

type WhatsappAvailability = {
  mobile: string;
  available: boolean;
  send: boolean;
};

type SelectedMembers = {
  label: string;
  value: {
    email: string;
    mobile: string;
    profile_url: string | null;
    name: string;
    whatsapp_availability: WhatsappAvailability[];
  };
};

const SelectedMembersMobileSelector = ({
  available,
  mobile,
  send,
  selectedMembers,
  setSelectedMembers,
}: {
  mobile: string;
  available: boolean;
  send: boolean;
  selectedMembers: SelectedMembers[];
  setSelectedMembers: React.Dispatch<React.SetStateAction<SelectedMembers[]>>;
}) => {
  return (
    <Checkbox
      name={mobile}
      label={
        <div
          className={classNames(
            "t-flex t-gap-1 t-items-center t-text-text-60",
            {
              "t-text-body": !available,
              "t-text-subtitle-sm": available,
            }
          )}
        >
          {mobile}
          {available && (
            <img src={Whatsapp} alt="available on wa" className="t-w-4 t-h-4" />
          )}
        </div>
      }
      defaultChecked={send}
      onChange={(e) => {
        const updatedMembers = selectedMembers.map((member) => ({
          ...member,
          value: {
            ...member.value,
            whatsapp_availability: member.value.whatsapp_availability.map(
              (wa) =>
                wa.mobile === mobile ? { ...wa, send: e.target.checked } : wa
            ),
          },
        }));
        setSelectedMembers(updatedMembers);
      }}
    />
  );
};

export const SendToWhatsapp = ({
  isOpen,
  close,
  message,
}: {
  isOpen: boolean;
  close: () => void;
  message: MessageUIComponentProps["message"] & {
    forwarded_message?: MessageUIComponentProps["message"];
  };
}) => {
  const { channel } = useChannelStateContext();
  const groupId = channel.data?.group_uuid! as string;
  const { data: members, isLoading } = useGetAllChannelMemberQuery(
    {
      groupId: groupId,
      channelId: channel.id!,
    },
    {
      skip: !channel.id || !channel.data?.group_uuid,
    }
  );
  const membersWithPhone = members?.filter(({ mobile }) => Boolean(mobile));
  const [forwardMessageToWhatsapp, { isLoading: isForwarding }] =
    useForwardMessageToWhatsappMutation();
  const [selectedMembers, setSelectedMembers] = useState<SelectedMembers[]>([]);
  const { alertToast, successToast } = useToast();

  const isAnyNumberSelected = selectedMembers.some(
    ({ value: { whatsapp_availability } }) =>
      whatsapp_availability.some(({ send }) => send)
  );

  const onChange = (values?: MultiValue<OptionData>) => {
    const parsedValues =
      values?.map(({ label, value }) => ({
        label: label as string,
        value: JSON.parse(value),
      })) || [];
    setSelectedMembers(parsedValues);
  };

  const handleSelectAll = () => {
    const parsedValues =
      membersWithPhone?.map(
        ({ name, email, mobile, profile_url, whatsapp_availability }) => ({
          label: name,
          value: {
            email,
            mobile: mobile!,
            name,
            profile_url,
            whatsapp_availability: whatsapp_availability.map(
              ({ mobile, available }) => ({
                mobile,
                available,
                send: available,
              })
            ),
          },
        })
      ) || [];
    setSelectedMembers(parsedValues);
  };

  const handleResetAll = () => {
    setSelectedMembers([]);
  };

  const forwardMessage = async () => {
    try {
      const toUsers = selectedMembers.flatMap(
        ({ value: { email, whatsapp_availability } }) =>
          whatsapp_availability
            .filter(({ send }) => send)
            .map(({ mobile }) => ({
              email,
              mobile,
            }))
      );

      await forwardMessageToWhatsapp({
        groupId: groupId!,
        channelId: channel.id!,
        messageId: message.id,
        payload: {
          text: message.text || "",
          to_users: toUsers,
        },
      }).unwrap();
      successToast({ message: "Message forwarded successfully" });
      close();
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const options = useMemo(
    () =>
      membersWithPhone?.map((member) => ({
        label: member.name,
        value: JSON.stringify({
          email: member.email,
          mobile: member.mobile,
          profile_url: member.profile_url,
          name: member.name,
          whatsapp_availability: member.whatsapp_availability.map(
            ({ mobile, available }) => ({
              mobile,
              available,
              send: available,
            })
          ),
        }),
      }))!,
    [JSON.stringify(membersWithPhone)]
  );

  return (
    <Modal.Root open={isOpen} onOpenChange={close} modal={false}>
      <Modal.Content useCustomOverlay size="small">
        <Modal.Header>
          <Modal.Title>Forward To</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <Combobox
            label="This message will be forwarded to"
            components={{
              DropdownIndicator: (props) => (
                <div
                  className={classNames("t-text-text-60 t-p-1", {
                    "t-rotate-180": props.selectProps.menuIsOpen,
                  })}
                >
                  <ArrowDown color="currentColor" />
                </div>
              ),
              ClearIndicator: () => (
                <div
                  className="t-text-text-60 t-p-1"
                  role="button"
                  onClick={handleResetAll}
                >
                  <Cross color="currentColor" />
                </div>
              ),
            }}
            menuPortalTarget={document.body}
            isMulti
            styles={{
              menu: () => ({ width: "400px" }),
            }}
            placeholder="Select"
            name="members"
            //@ts-ignore
            onChange={onChange}
            options={options}
            value={
              selectedMembers.length > 0
                ? selectedMembers?.map((member) => ({
                    value: JSON.stringify({
                      email: member.value.email,
                      mobile: member.value.mobile,
                      profile_url: member.value.profile_url,
                      name: member.value.name,
                      whatsapp_availability: member.value.whatsapp_availability,
                    }),
                    label: member.label,
                  }))
                : null
            }
            actions={
              <div>
                <Button customType="text" size="small" onClick={handleResetAll}>
                  Reset Selection
                </Button>
                <Button
                  customType="text"
                  size="small"
                  onClick={handleSelectAll}
                >
                  Select All
                </Button>
              </div>
            }
            isLoading={isLoading}
          />
          <div className="t-mt-4 t-flex t-flex-col t-gap-4">
            {selectedMembers?.map(({ value }) => (
              <div className="t-flex t-gap-2 t-flex-col" key={value?.email}>
                <div className="t-flex t-gap-2 t-flex-col">
                  <div className="t-flex t-w-full t-gap-2 t-items-center">
                    <Avatar
                      src={value?.profile_url}
                      alt={value?.name || ""}
                      size="regular"
                    />
                    <div className="t-text-text-100 t-text-subtitle-sm">
                      {value.name}
                    </div>
                  </div>
                  <div className="t-px-4 t-flex t-flex-col t-gap-4">
                    {value?.whatsapp_availability?.map(
                      ({ available, mobile, send }) => (
                        <SelectedMembersMobileSelector
                          key={mobile}
                          available={available}
                          mobile={mobile}
                          send={send}
                          selectedMembers={selectedMembers}
                          setSelectedMembers={setSelectedMembers}
                        />
                      )
                    )}
                  </div>
                </div>
                <Divider />
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer className="t-justify-end t-flex t-gap-3">
          <Button onClick={close}>Cancel</Button>
          <Button
            customType="primary"
            onClick={forwardMessage}
            disabled={!isAnyNumberSelected || isForwarding}
            isLoading={isForwarding}
          >
            Forward
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
