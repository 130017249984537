import { Button } from "components/DesignSystem/Button/Button";
import { useToast } from "hooks/useToast";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useDeleteMessageMutation } from "store/apis/chat";
import { setDeleteMessageData } from "store/slices/chat";
import { RootState } from "store/store";
import { useAnalytics } from "hooks/useAnalytics";
import { CHAT_MESSAGE_DELETED } from "constants/analyticsEvents";
import Modal from "components/DesignSystem/Modal/Modal";

const DeleteMessageModal = () => {
  const { alertToast, successToast } = useToast();
  const [deleteMessage, { isLoading }] = useDeleteMessageMutation();
  const dispatch = useDispatch();
  const { deleteMessageData } = useSelector((state: RootState) => state.chat);
  const { channelId, message, showDeleteMessageModal } = deleteMessageData;
  const { trackEvent } = useAnalytics();

  const closeModal = () => {
    dispatch(
      setDeleteMessageData({
        message: { id: "" },
        showDeleteMessageModal: false,
        channelId: "",
      })
    );
  };

  const deleteChannelMessage = async () => {
    try {
      await deleteMessage({
        channel_id: channelId,
        message_id: message?.id,
      }).unwrap();
      // @ts-ignore
      trackEvent(CHAT_MESSAGE_DELETED, message);
      successToast({ message: "Message deleted successfully" });
      closeModal();
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
      closeModal();
    }
  };

  return (
    <Modal.Root open={showDeleteMessageModal} onOpenChange={closeModal}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Delete this message</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <div className="t-text-body-lg t-text-text-black">
            Are you sure you want to delete this message?
          </div>
        </Modal.Body>
        <Modal.FooterButtonGroup>
          <Modal.RawClose asChild>
            <Button onClick={closeModal}>Cancel</Button>
          </Modal.RawClose>
          <Button
            disabled={isLoading}
            customType="danger"
            isLoading={isLoading}
            onClick={deleteChannelMessage}
          >
            Confirm
          </Button>
        </Modal.FooterButtonGroup>
      </Modal.Content>
    </Modal.Root>
  );
};

export default DeleteMessageModal;
