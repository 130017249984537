import { Divider } from "components/design/Divider";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { ChangeEvent, useMemo } from "react";
import { useGetAllAccountQuery } from "store/apis/transactions";
import { FiltersValues } from "store/slices/transactionFilter";
import { SourceFilter } from "types/Models/books";

type FromFilterProps = {
  updateFilter: (v: any, a: any) => void;
  fromIds?: FiltersValues;
  otherIds: { name: string; value: boolean }[];
};

export const Source = ({
  updateFilter,
  fromIds,
  otherIds,
}: FromFilterProps) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const {
    data: froms = [],
    isLoading,
    isSuccess,
  } = useGetAllAccountQuery(
    {
      groupId,
      entityId,
      account_type: "EXCLUDE_STRIPE",
    },
    { skip: !groupId || !entityId }
  );

  let selectFromIds = fromIds?.value || [];

  const banksByName = useMemo(
    () =>
      froms.reduce((acc, cc) => {
        if (!acc[cc.brand_name]) {
          acc[cc.brand_name] = [];
        }
        acc[cc.brand_name].push(cc);
        return acc;
      }, {} as Record<string, SourceFilter[]>),
    [isSuccess]
  );

  const onBankNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = e.target;
    const allbanks = new Set(banksByName[name].map(({ uuid }) => uuid));

    if (Array.isArray(selectFromIds)) {
      let newSelectedIds: Set<string>;

      if (checked) {
        newSelectedIds = new Set([...selectFromIds, ...allbanks]);
      } else {
        newSelectedIds = new Set(
          selectFromIds.filter((fromId) => !allbanks.has(fromId))
        );
      }
      updateFilter(
        "fromIds",
        newSelectedIds.size > 0 ? Array.from(newSelectedIds) : undefined
      );
    }
  };

  const onHandleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;

    if (Array.isArray(selectFromIds)) {
      let newSelectedIds: Set<string> = new Set();
      if (checked) {
        newSelectedIds = new Set([...selectFromIds, value]);
      } else {
        newSelectedIds = new Set(
          [...selectFromIds]?.filter((fromId) => fromId !== value)
        );
      }
      updateFilter(
        "fromIds",
        newSelectedIds.size > 0 ? [...newSelectedIds] : undefined
      );
    }
  };

  const onOtherHandleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    if (checked) {
      if (name === "Manual Entry") {
        updateFilter("manualEntry", true);
      } else if (name === "Bank statement") {
        updateFilter("bankStatement", true);
      }
    } else {
      if (name === "Manual Entry") {
        updateFilter("manualEntry", null);
      } else if (name === "Bank statement") {
        updateFilter("bankStatement", null);
      }
    }
  };

  return (
    <div className="t-w-full t-flex t-flex-col t-gap-5 ">
      <div className="t-text-body-sm t-text-text-30">Sent from</div>
      <div className="t-flex t-flex-col t-gap-4">
        <Async.Root
          isLoading={isLoading}
          isSuccess={isSuccess}
          isEmpty={froms.length === 0}
          loaderType="secondary"
        >
          <Async.Empty>
            <></>
          </Async.Empty>
          <Async.Success>
            {Object.entries(banksByName).map(([name, banks]) => {
              const checked = banks.every((bank) =>
                new Set(selectFromIds as string[]).has(bank.uuid)
              );

              const indeterminate =
                banksByName[name].some((bank) =>
                  new Set(selectFromIds as string[]).has(bank.uuid)
                ) && !checked;

              return (
                <span className="t-flex t-flex-col t-gap-4" key={name}>
                  <Checkbox
                    name={name}
                    label={<span className="t-text-text-30">{name}</span>}
                    value={name}
                    onChange={onBankNameChange}
                    checked={checked}
                    indeterminate={indeterminate}
                  />
                  <span className="t-ml-6 t-flex t-flex-col t-gap-4">
                    {banks.map((from) => (
                      <Checkbox
                        key={from.uuid}
                        name={from?.uuid}
                        label={
                          <>
                            {from?.nickname}
                            {from?.mask && <> - {from?.mask}</>}
                          </>
                        }
                        value={from?.uuid}
                        checked={new Set(selectFromIds as string[]).has(
                          from?.uuid
                        )}
                        onChange={onHandleChange}
                      />
                    ))}
                  </span>
                  <Divider color="grey" />
                </span>
              );
            })}
          </Async.Success>
        </Async.Root>
        {otherIds?.map((other) => (
          <Checkbox
            key={other.name}
            name={other.name}
            label={other.name}
            value={other?.name}
            checked={Boolean(other.value)}
            onChange={onOtherHandleChange}
          />
        ))}
      </div>
    </div>
  );
};
