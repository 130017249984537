import cx from "classnames";
import { LoadingIcon } from "components/icons/LoadingIcon";
import { ButtonHTMLAttributes, forwardRef, ReactNode, Ref } from "react";

export type ButtonProps = {
  customType?:
    | "icon"
    | "primary"
    | "secondary"
    | "danger"
    | "transparent"
    | "text"
    | "ghost_icon"
    | "warning"
    | "primary-outlined"
    | "success"
    | "icon-primary-outlined"
    | "ghost"
    | "link"
    | "danger-outlined"
    | "danger-link";
  size?: "extra-small" | "small" | "regular" | "large";
  block?: boolean;
  isLoading?: boolean;
  active?: boolean;
  icon?: ReactNode;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const BUTTON_TYPE_CLASSES = {
  /* @tw */
  PRIMARY:
    "t-bg-purple hover:t-bg-purple-70 focus:t-bg-purple-80  t-text-white disabled:t-bg-neutral-10 disabled:t-text-neutral-40",
  /* @tw */
  SECONDARY:
    "t-border t-border-solid t-border-neutral-10 hover:t-bg-surface-lighter-grey t-text-neutral t-bg-white disabled:t-text-neutral-20 focus:t-bg-neutral-0 data-[active=true]:t-bg-neutral-0  disabled:t-bg-neutral-10",
  /* @tw */
  DANGER:
    "t-bg-red hover:t-bg-red-70 focus:t-bg-red-80 t-text-white disabled:t-text-neutral-40 disabled:t-bg-neutral-10",
  /* @tw */
  WARNING:
    " t-bg-orange hover:t-bg-orange-70 active:t-bg-orange-80 t-text-white  disabled:t-text-neutral-40 disabled:t-bg-neutral-10",
  /* @tw */
  HEIGHT:
    "data-[size=regular]:t-h-10 data-[size=small]:t-h-8 data-[size=large]:t-h-12 data-[size=extra-small]:t-h-5",
  /* @tw */
  ICON: "t-border t-border-solid t-border-neutral-10 data-[size=regular]:t-w-10 data-[size=small]:t-w-8 data-[size=large]:t-w-12 !t-px-0",
  /* @tw */
  FONTS:
    "data-[size=regular]:t-text-body data-[size=small]:t-text-body-sm data-[size=large]:t-text-body-lg",
  /* @tw */
  TRANSPARENT:
    "t-border-neutral-10 t-bg-surface-transparent !t-border-none !t-px-0 t-drop-shadow-none",
  /* @tw */
  GHOST_ICON:
    "t-border-neutral-10 t-bg-surface-transparent !t-border-none !t-px-1 t-drop-shadow-none  hover:!t-bg-neutral-0 active:!t-bg-neutral-20 disabled:t-text-neutral-20 data-[size=regular]:t-w-10 data-[size=small]:t-w-8 data-[size=large]:t-w-12 data-[size=extra-small]:t-w-5 data-[size=extra-small]:!t-px-0",
  /* @tw */
  GHOST:
    "t-border-neutral-10 t-bg-surface-transparent !t-border-none !t-px-1 t-drop-shadow-none hover:!t-bg-neutral-0 active:!t-bg-neutral-20 disabled:t-text-neutral-20",
  /* @tw */
  TEXT: "t-border-neutral-10 t-bg-surface-transparent !t-border-none !t-p-0 t-drop-shadow-none t-font-light !t-text-purple disabled:!t-text-neutral-40",
  /* @tw */
  PRIMARY_OUTLINED:
    "t-border t-border-solid t-border-purple t-text-purple hover:t-bg-purple-0 focus:t-bg-purple-10 disabled:t-text-purple-30 disabled:t-bg-white disabled:t-border-purple-10",
  /* @tw */
  SUCCESS:
    "t-bg-dark_green hover:t-bg-dark_green-70 focus:t-bg-dark_green-80 focus:t-border-dark_green-100 t-text-white disabled:t-bg-neutral-10 disabled:t-text-neutral-40",
  /* @tw */
  ICON_PRIMARY_OUTLINED:
    "t-border t-border-solid t-border-purple t-text-purple hover:t-bg-purple-0 focus:t-bg-purple-10 disabled:t-text-purple-30 disabled:t-border-purple-10 data-[size=regular]:t-w-10 data-[size=small]:t-w-8 data-[size=large]:t-w-12 !t-px-0",
  /* @tw */
  LINK: "t-text-purple t-bg-surface-transparent !t-border-none !t-p-0 !t-h-auto hover:t-text-purple-80 hover:t-underline disabled:!t-text-neutral-20",
  DANGER_OUTLINED:
    "t-border t-border-solid t-border-red t-text-red hover:t-bg-red-10 focus:t-bg-red-10 disabled:t-text-red-30 disabled:t-bg-white disabled:t-border-red-20",
  /* @tw */
  DANGER_LINK:
    "t-text-red t-bg-surface-transparent !t-border-none !t-p-0 !t-h-auto hover:t-text-red-80 hover:t-underline disabled:!t-text-text-100",
};

export const Button = forwardRef(
  (
    {
      children,
      customType = "secondary",
      size = "regular",
      block = false,
      isLoading = false,
      active = false,
      icon,
      ...props
    }: ButtonProps,
    ref: Ref<HTMLButtonElement>
  ) => {
    const loadingIcon =
      (customType === "ghost_icon" || customType === "icon") && isLoading;

    return (
      <button
        ref={ref}
        data-size={size}
        data-active={active}
        className={cx(
          "all:unset t-box-border t-text-nowrap t-flex t-cursor-pointer t-items-center t-justify-center t-rounded t-px-4 !t-font-medium t-drop-shadow-button t-transition-all disabled:t-pointer-events-none disabled:t-cursor-not-allowed",
          BUTTON_TYPE_CLASSES.FONTS,
          BUTTON_TYPE_CLASSES.HEIGHT,
          {
            [BUTTON_TYPE_CLASSES.PRIMARY]: customType === "primary",
            [BUTTON_TYPE_CLASSES.WARNING]: customType === "warning",
            [BUTTON_TYPE_CLASSES.DANGER]: customType === "danger",
            [BUTTON_TYPE_CLASSES.SECONDARY]: customType === "secondary",
            [`${BUTTON_TYPE_CLASSES.SECONDARY} ${BUTTON_TYPE_CLASSES.ICON}`]:
              customType === "icon",
            [BUTTON_TYPE_CLASSES.TRANSPARENT]: customType === "transparent",
            [BUTTON_TYPE_CLASSES.GHOST_ICON]: customType === "ghost_icon",
            [BUTTON_TYPE_CLASSES.GHOST]: customType === "ghost",
            [BUTTON_TYPE_CLASSES.TEXT]: customType === "text",
            [BUTTON_TYPE_CLASSES.PRIMARY_OUTLINED]:
              customType === "primary-outlined",
            [BUTTON_TYPE_CLASSES.SUCCESS]: customType === "success",
            [BUTTON_TYPE_CLASSES.ICON_PRIMARY_OUTLINED]:
              customType === "icon-primary-outlined",
            [BUTTON_TYPE_CLASSES.LINK]: customType === "link",
            [BUTTON_TYPE_CLASSES.DANGER_OUTLINED]:
              customType === "danger-outlined",
            [BUTTON_TYPE_CLASSES.DANGER_LINK]: customType === "danger-link",
            "t-w-full": block,
          }
        )}
        {...props}
      >
        {isLoading && (
          <span
            className={cx("t-flex t-origin-center t-animate-spin", {
              "t-mr-1.5 ": !loadingIcon,
            })}
          >
            <LoadingIcon />
          </span>
        )}
        {icon && (
          <span
            className={cx("t-flex", {
              "t-mr-1.5 ": !loadingIcon,
            })}
          >
            {icon}
          </span>
        )}
        {loadingIcon ? null : children}
      </button>
    );
  }
);
