import classNames from "classnames";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { useToast } from "hooks/useToast";
import React, {
  forwardRef,
  Suspense,
  useEffect,
  useRef,
  useState,
} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { debounce } from "utils/debouncing";
import { formatDate } from "utils/formatDate";
import {
  addGroupNotes,
  addGroupNotesWithDates,
  getGroupNotes,
} from "../../apis/serviceTeamGroups";
import "../../static/styles/components/salesCrm.css";
import DateInputBox from "../design/dateInput";
import NoDataAvailable from "../design/noDataAvailable";
import { Loader } from "components/DesignSystem/Loader/Loader";

const QuillEditor = React.lazy(() => import("../QuillEditor/QuillEditor"));

const Note = ({
  note,
}: {
  note: {
    uuid: string;
    description: string;
    created_at: string;
    created_by?: { name: string };
  };
}) => {
  let formattedNotes = "";
  try {
    formattedNotes = JSON.parse(note.description);
  } catch (error) {
    formattedNotes = note.description;
  }

  const quillRef = useRef();

  const { description, created_at, created_by } = note;

  if (typeof formattedNotes === "object") {
    return (
      <div className="noteData">
        <span className="noteDescription">
          <QuillEditor
            initReadOnly
            theme="bubble"
            defaultValue={formattedNotes}
            parentRef={quillRef}
            onTextChange={(update) => {
              // @ts-ignore
              setNotesPayload(quillRef?.current?.getContents());
            }}
          />
          <span className="t-text-text-30 noteDetails">
            {created_by?.name}, {formatDate(created_at)}
          </span>
        </span>
      </div>
    );
  }

  return (
    <div className="noteData">
      <span className="noteDescription">
        {description} <br />
        <span className="t-text-text-30 noteDetails">
          {created_by?.name}, {formatDate(created_at)}
        </span>
      </span>
    </div>
  );
};

export const CrmNotes = forwardRef(
  (
    {
      showModal,
      closeModal,
      clickedNote,
      needDateInput,
      setLoad,
    }: {
      showModal: boolean;
      closeModal: () => void;
      clickedNote: any;
      needDateInput: boolean;
      setLoad: () => void;
    },
    ref
  ) => {
    const { alertToast } = useToast();
    const [notes, setNotes] = useState<any>([]);
    const [notesPayload, setNotesPayload] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [datePayload, setDatePayload] = useState({
      last_contact_date: clickedNote.last_contact_date,
      next_contact_date: clickedNote.next_contact_date || "",
      description: "",
    });
    const handleDateChange = async (e: any) => {
      const { name, value } = e.target;
      setDatePayload({ ...datePayload, [name]: value });
    };

    const { uuid } = clickedNote;
    const { next_contact_date } = datePayload;

    // const handleChange = (e) => {
    //   const { value } = e.target;
    //   setNotesPayload({ description: value });
    //   setDatePayload({ ...datePayload, description: value });
    // };

    useEffect(() => {
      if (uuid) fetchNotes(true);
    }, [currentPage, uuid]);

    const postNotes = async () => {
      try {
        if (needDateInput) {
          await addGroupNotesWithDates({
            groupId: uuid,
            payload: {
              description: JSON.stringify(notesPayload),
            },
          });
          setDatePayload({
            last_contact_date: clickedNote.last_contact_date,
            next_contact_date: clickedNote.next_contact_date || "",
            description: "",
          });
          setNotesPayload({});
          fetchNotes();
          closeModal();
        } else {
          await addGroupNotes({ groupId: uuid, payload: notesPayload });
          setNotesPayload({});
          fetchNotes();
        }
      } catch (error: any) {
        alertToast({ message: error?.response?.data?.error?.message });
      }
    };

    const fetchNotes = debounce(async (paginate?: any) => {
      try {
        if (paginate) {
          const response = await getGroupNotes({ groupId: uuid, currentPage });
          setNotes((prevNotes: any) =>
            prevNotes.concat(response.data.data.notes)
          );
          setTotalPage(response.data.data.total_pages);
          setCurrentPage(response.data.data.current_page);
        }
        if (!paginate) {
          const response = await getGroupNotes({
            groupId: uuid,
            currentPage: 1,
          });
          if (currentPage === 1) {
            setNotes(response.data.data.notes);
          }
          if (currentPage !== 1) setNotes([]);
          setCurrentPage(response.data.data.current_page);
          setTotalPage(response.data.data.total_pages);
        }
      } catch (error: any) {
        alertToast({
          message: error?.response?.data?.error?.message,
        });
      }
    });

    const setPagination = () => {
      if (currentPage < totalPage) setCurrentPage((page) => page + 1);
    };

    const quillRef = useRef();

    return (
      <Modal.Root open={showModal} onOpenChange={closeModal}>
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>Notes</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            <Suspense fallback={<Loader size="small" />}>
              <div id="scrollableDiv" className="scrollableNotes">
                {notes.length > 0 ? (
                  <InfiniteScroll
                    loader={<></>}
                    dataLength={notes.length}
                    next={setPagination}
                    hasMore={true}
                    inverse={true}
                    style={{ display: "flex", flexDirection: "column-reverse" }}
                    scrollableTarget="scrollableDiv"
                  >
                    {notes?.map((note: any) => {
                      // @ts-ignore
                      return <Note key={note.uuid} note={note} />;
                    })}
                  </InfiniteScroll>
                ) : (
                  <NoDataAvailable noDataText="No Notes Available" />
                )}
              </div>
            </Suspense>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <Suspense fallback={<Loader size="small" />}>
                <QuillEditor
                  parentRef={quillRef}
                  onTextChange={(update) => {
                    // @ts-ignore
                    setNotesPayload(quillRef?.current?.getContents());
                  }}
                />
              </Suspense>
              {/* <textarea
              onChange={handleChange}
              className="salesCrmNotesInput"
              placeholder="Add a note ...."
              value={notesPayload.description}
            /> */}
              <div
                className={classNames("t-flex t-items-center t-mt-1", {
                  "t-justify-between": needDateInput,
                  "t-justify-end": !needDateInput,
                })}
              >
                {needDateInput && (
                  <span>
                    Next date of contact:
                    <DateInputBox
                      placeholder="Select Date"
                      disable={false}
                      value={next_contact_date}
                      onChange={handleDateChange}
                      name="next_contact_date"
                      calendarIcon
                    />
                  </span>
                )}
                <Button
                  onClick={postNotes}
                  disabled={Object.entries(notesPayload || {}).length === 0}
                >
                  Save
                </Button>
              </div>
            </div>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Root>
    );
  }
);
