import classNames from "classnames";
import { TableUI } from "components/design/TableUI";
import Table from "components/DesignSystem/Table/V2/Table";
import React, { useMemo } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import { StateColumn } from "./StateColumn";
import {
  StateRegistrationsRes,
  useGetStateRegistrationsQuery,
} from "store/apis/StateRegistrations";
import { RegisteredStateColumn } from "./RegistrationStateColumn";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useQuery } from "hooks/useQuery";
import Loader from "components/design/loader";
import { AddStateRegistrationModal } from "./AddStateRegistrationModal";
import { useModal } from "hooks/useModal";
import { Button } from "components/DesignSystem/Button/Button";

export const StateRegistrations = () => {
  const { uuid: groupId } = useCurrentGroupContext();
  const query = useQuery();
  let entityId = query.get("entity");
  const {
    data: stateRegistrations = [],
    isLoading,
    isSuccess,
  } = useGetStateRegistrationsQuery(
    { groupId: groupId!, entityId: entityId! },
    { skip: !entityId || !groupId, refetchOnMountOrArgChange: true }
  );
  const addStateModal = useModal();

  const createColumn = createColumnHelper<StateRegistrationsRes>();

  const columns = [
    createColumn.accessor("state", {
      header: "STATE",
      size: 20,
      cell: (info) => (
        <StateColumn
          stateDetail={info.getValue() as string}
          isHomeState={info.row.original.is_home_state}
        />
      ),
    }),
    createColumn.accessor("SECRETARY_OF_STATE", {
      header: "SECRETARY OF STATE",
      size: 26,
      cell: (info) => (
        <RegisteredStateColumn
          stateDetails={info.getValue()}
          stateId={info.row.original.state_id}
          stateType="SECRETARY_OF_STATE"
        />
      ),
    }),
    createColumn.accessor("REGISTERED_AGENT", {
      header: "REGISTERED AGENT ",
      size: 20,
      cell: (info) => (
        <RegisteredStateColumn
          stateDetails={info.getValue()}
          stateId={info.row.original.state_id}
          stateType="REGISTERED_AGENT"
        />
      ),
    }),
    createColumn.accessor("PAY_ROLL", {
      header: "PAYROLL",
      size: 17,
      cell: (info) => (
        <RegisteredStateColumn
          stateDetails={info.getValue()}
          stateId={info.row.original.state_id}
          stateType="PAY_ROLL"
        />
      ),
    }),
    createColumn.accessor("SALES_TAX", {
      header: "SALES TAX",
      size: 17,
      cell: (info) => (
        <RegisteredStateColumn
          stateDetails={info.getValue()}
          stateId={info.row.original.state_id}
          stateType="SALES_TAX"
        />
      ),
    }),
  ];

  const data = useMemo(() => stateRegistrations, [stateRegistrations]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 5,
    },
    enableRowSelection: true,
  });

  if (isLoading || !isSuccess) {
    return <Loader />;
  }

  return (
    <div>
      <div className="t-flex t-items-center t-justify-between">
        <div className="t-text-subtitle t-text-text-100 t-mb-4">
          State Registrations
        </div>
        <Button
          customType="primary-outlined"
          size="small"
          onClick={addStateModal.open}
        >
          Add state
        </Button>
      </div>
      <div>
        <TableUI table={table} size="small">
          {({ row }) => (
            <Table.Row key={row.id} tabIndex={0} role="button" className="">
              {row.getVisibleCells().map((cell, i) => {
                return (
                  <Table.Cell
                    key={cell.id}
                    style={{ width: `${cell.column.getSize()}%` }}
                    aria-label={cell.column.id}
                    className={classNames("t-group !t-p-0 !t-px-2", {
                      "t-cursor-auto": i === 0,
                      "hover:t-bg-surface-lighter-grey t-cursor-pointer":
                        i !== 0,
                    })}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Table.Cell>
                );
              })}
            </Table.Row>
          )}
        </TableUI>
      </div>
      <AddStateRegistrationModal {...addStateModal} />
    </div>
  );
};
