import { AgentAvailability, Agents } from "types/Models/agents";
import { emptyApi } from "./emptyApi";
import { Pagination } from "types/Models/pagination";

const extendedApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getAssignAgents: build.query<
      Agents[],
      { agentType?: "ACCOUNT_EXECUTIVE" | "SALES_EXECUTIVE" | "CALL_EXECUTIVE" }
    >({
      query: ({ agentType }) => {
        return {
          url: `/api/inkle/company/agents/`,
          params: { agent_type: agentType },
        };
      },
    }),

    getAgentAvailability: build.query<AgentAvailability[], void>({
      query: () => `/api/inkle/company/v2/support-agent/`,
      providesTags: ["AGENT_AVAILABILITY"],
    }),

    checkAgentsAvailability: build.query<
      { available_agent_count: number },
      { groupId: string }
    >({
      query: ({ groupId }) =>
        `/api/inkle/company/v2/group/${groupId}/agent-available/`,
      providesTags: ["AGENT_AVAILABILITY"],
    }),

    updateAgentAvailability: build.mutation<
      AgentAvailability,
      {
        agentId: string;
        payload: {
          is_available?: boolean;
          available_start_time?: string;
          available_end_time?: string;
        };
      }
    >({
      query: ({ agentId, payload }) => {
        return {
          url: `/api/inkle/company/v2/support-agent/${agentId}/`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: ["AGENT_AVAILABILITY"],
    }),

    addAgentAvailability: build.mutation<
      AgentAvailability,
      {
        payload: {
          profile_id: string;
          is_available: boolean;
          available_start_time: string;
          available_end_time: string;
        };
      }
    >({
      query: ({ payload }) => {
        return {
          url: `/api/inkle/company/v2/support-agent/`,
          method: "post",
          body: payload,
        };
      },
      invalidatesTags: ["AGENT_AVAILABILITY"],
    }),

    assignAgentTocompany: build.mutation<
      Agents & { group_id: string },
      { userId: string; groupId: string }
    >({
      query: ({ groupId, userId }) => {
        return {
          url: `api/inkle/company/agents/`,
          method: "post",
          body: {
            user_id: userId,
            group_id: groupId,
          },
        };
      },
      invalidatesTags: (result, _error) => {
        return result
          ? [{ type: "CrmCompanyGroups", id: result.group_id }]
          : [{ type: "CrmCompanyGroups", id: "GROUP" }];
      },
    }),

    removeAgentFromCompany: build.mutation<
      {
        group_id: string;
      },
      {
        agentId: string;
        groupId: string;
      }
    >({
      query: ({ agentId, groupId }) => {
        return {
          url: `api/inkle/company/agents/`,
          method: "DELETE",
          body: {
            agent_id: agentId,
            group_id: groupId,
          },
        };
      },
      invalidatesTags: (result, _error) => {
        return result
          ? [{ type: "CrmCompanyGroups", id: result.group_id }]
          : [{ type: "CrmCompanyGroups", id: "GROUP" }];
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useAssignAgentTocompanyMutation,
  useGetAssignAgentsQuery,
  useRemoveAgentFromCompanyMutation,
  useGetAgentAvailabilityQuery,
  useUpdateAgentAvailabilityMutation,
  useAddAgentAvailabilityMutation,
  useCheckAgentsAvailabilityQuery,
} = extendedApi;
