import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { Form, Formik } from "formik";
import { couponSchema } from "formValidations/couponCodeSchema";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useEffect } from "react";
import SuccessfullPartyEmoji from "static/images/SuccessfullPartyEmoji.svg";
import { useApplyGroupCouponMutation } from "store/apis/billing";

type ApplyCouponModalProps = {
  show: boolean;
  closeModal: () => void;
};

const ApplyCouponModal = ({ show, closeModal }: ApplyCouponModalProps) => {
  const [applyGroupCoupon, { data, isError, error }] =
    useApplyGroupCouponMutation();
  const { uuid: groupId } = useCurrentGroupContext();
  const successModal = useModal();

  const applyCoupon = async ({ couponCode }: { couponCode: string }) => {
    try {
      await applyGroupCoupon({
        groupId,
        couponCode,
      }).unwrap();

      closeModal();
      successModal.open();
    } catch (e: any) {}
  };

  useEffect(() => {
    if (successModal.isOpen) {
      setTimeout(() => {
        successModal.close();
      }, 5000);
    }
  }, [successModal.isOpen]);

  return (
    <>
      <Modal.Root open={show} onOpenChange={closeModal}>
        <Modal.Content useCustomOverlay>
          <Formik
            onSubmit={applyCoupon}
            initialValues={{ couponCode: "" }}
            validationSchema={couponSchema}
          >
            {({ isSubmitting, isValid, values, submitForm }) => {
              return (
                <>
                  <Modal.Header>
                    <Modal.Title>Apply coupon</Modal.Title>
                    <Modal.Close onClick={closeModal} />
                  </Modal.Header>
                  <Modal.Body>
                    <Form className="t-m-0 t-mt-4 t-w-full">
                      <TextInput
                        block
                        name="couponCode"
                        type="text"
                        placeholder="Enter Coupon"
                      />
                      {values.couponCode.length !== 0 && isError && (
                        <div className="t-mt-1 t-text-caption t-text-red">
                          {/* @ts-ignore */}
                          {error?.data?.error?.message}
                        </div>
                      )}
                    </Form>
                  </Modal.Body>
                  <Modal.FooterButtonGroup>
                    <Button
                      isLoading={isSubmitting}
                      disabled={!isValid || isSubmitting}
                      onClick={submitForm}
                    >
                      Apply
                    </Button>
                  </Modal.FooterButtonGroup>
                </>
              );
            }}
          </Formik>
        </Modal.Content>
      </Modal.Root>

      <Modal.Root open={successModal.isOpen} onOpenChange={successModal.close}>
        <Modal.Content>
          <Modal.Header>
            <Modal.Title />
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            <div className="t-flex t-flex-col t-items-center">
              <img src={SuccessfullPartyEmoji} alt="SuccessfullPartyEmoji" />
              <div className="t-mt-6 t-text-h5 t-font-bold t-text-text-100">
                Congrats!
              </div>
              <div className="t-mt-1">
                {/* @ts-ignore */}
                Coupon code <b>{data?.coupon_code}</b> applied successfully.
              </div>
            </div>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </>
  );
};

export default ApplyCouponModal;
