import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import Modal from "components/DesignSystem/Modal/Modal";
import { Slider } from "components/DesignSystem/Slider/Slider";
import Table from "components/DesignSystem/Table/V2/Table";
import { ConditionalLink } from "components/conditionalLink";
import { Cross } from "components/icons/Cross";
import { Field, FieldProps, Form, Formik, FormikValues } from "formik";
import { useModal } from "hooks/useModal";
import { useQuery } from "hooks/useQuery";
import { useToast } from "hooks/useToast";
import { EmptyScreen } from "pages/Books/EmptyScreen";
import { ContactsTable } from "pages/Resources/Raise";
import { MouseEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CellContext,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import Globe from "static/images/Globe.svg";
import LinkedInLogo from "static/images/LinkedInLogo.svg";
import ProfileEmptyState from "static/images/ProfileEmptyState.svg";
import {
  Contact,
  VentureCapitalist,
  useGetAllVCsQuery,
  useGetVCContactsQuery,
  useInviteVCsMutation,
} from "store/apis/vcCrm";
import {
  createProfile,
  setEditable,
  setOpenSlider,
} from "store/slices/raiseCrm";
import { RootState } from "store/store";
import { BackendError } from "types/utils/error";
import { InvestorEdit } from "./InvestorEdit";
import { InvestorView } from "./InvestorView";
import { Intro } from "store/apis/vcIntro";
import { Tag } from "components/DesignSystem/Tag/Tag";
import Loader from "components/design/loader";

const CheckBoxLabel = ({
  name,
  email,
  isInvited,
  isVerified,
}: {
  name: string;
  email: string;
  isInvited?: boolean | null;
  isVerified?: boolean;
}) => {
  return (
    <div className="t-flex t-justify-between t-ml-2 t-w-full t-items-center">
      <div className="t-flex t-gap-3">
        <div className="t-bg-surface-grey t-w-8 t-h-8 t-flex t-justify-center t-items-center t-rounded-full">
          {name?.at(0)?.toUpperCase()}
        </div>
        <div className="t-flex t-flex-col t-gap-0.5">
          <div className="t-text-subtext-sm">{name}</div>
          <div className="t-text-body-sm t-text-text-60">{email}</div>
        </div>
      </div>
      <>
        {isVerified ? (
          <Tag tagType="green">ACTIVE</Tag>
        ) : isInvited ? (
          <Tag tagType="purple">INVITED</Tag>
        ) : (
          <Tag tagType="red">NOT INVITED</Tag>
        )}
      </>
    </div>
  );
};

const InviteModal = ({ vcId }: { vcId: string }) => {
  const { open, isOpen, close } = useModal();
  const { alertToast, successToast } = useToast();

  const [inviteVCs, { isLoading: isInviting }] = useInviteVCsMutation();

  const { data: contacts, isLoading } = useGetVCContactsQuery(
    { vcId },
    { skip: !vcId || !isOpen }
  );

  const onInvite = async (values: FormikValues) => {
    const payload = {
      vc_contact_ids: Object.entries(values)
        .filter((ele) => ele[1])
        .map((ele) => ele[0]),
    };
    try {
      await inviteVCs({ payload }).unwrap();
      successToast({ message: "Invite sent" });
      close();
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  return (
    <div className="t-flex t-items-center" onClick={(e) => e.stopPropagation()}>
      <Button customType="primary" size="small" onClick={open}>
        Invite
      </Button>
      <Modal.Root open={isOpen} onOpenChange={close}>
        <Formik initialValues={{}} onSubmit={onInvite}>
          {({ submitForm, isSubmitting }) => {
            return (
              <Form className="t-m-0 t-w-full">
                <Modal.Content>
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <>
                      <Modal.Header>
                        <Modal.Title>Invite</Modal.Title>
                        <Modal.Close />
                      </Modal.Header>
                      <Modal.Body>
                        <div className="t-flex t-flex-col t-gap-3 t-w-full">
                          {contacts?.map(
                            ({
                              name,
                              email,
                              uuid,
                              is_invited,
                              is_profile_verified,
                            }) => (
                              <Field name={uuid} key={uuid}>
                                {({ field }: FieldProps) => (
                                  <Checkbox
                                    {...field}
                                    label={
                                      <CheckBoxLabel
                                        name={name}
                                        email={email}
                                        isInvited={is_invited}
                                        isVerified={is_profile_verified}
                                      />
                                    }
                                    name={uuid}
                                  />
                                )}
                              </Field>
                            )
                          )}
                        </div>
                      </Modal.Body>
                      <Modal.Footer className="t-flex t-gap-3 t-justify-end">
                        <Button onClick={close} type="button">
                          Cancel
                        </Button>
                        <Button
                          customType="primary"
                          isLoading={isSubmitting}
                          disabled={isSubmitting}
                          onClick={submitForm}
                        >
                          Invite
                        </Button>
                      </Modal.Footer>
                    </>
                  )}
                </Modal.Content>
              </Form>
            );
          }}
        </Formik>
      </Modal.Root>
    </div>
  );
};

export const ContactColumn = (
  info:
    | CellContext<VentureCapitalist, Contact[]>
    | CellContext<Intro, Contact[]>
) => {
  const contacts = info.getValue();

  if (!contacts || contacts.length === 0) {
    return "-";
  }

  const firstContact = contacts[0];
  const otherContacts = contacts.slice(1);

  return (
    <>
      <div className="t-flex t-gap-2 t-items-center">
        <ConditionalLink
          to={firstContact.linkedIn_url}
          className="t-flex t-items-center t-gap-1 t-max-w"
          onClick={(e) => e.stopPropagation()}
        >
          {firstContact.linkedIn_url && <img src={LinkedInLogo} alt="In" />}
        </ConditionalLink>
        <div className="t-text-text-100">{firstContact.name}</div>
      </div>
      {otherContacts.length > 0 && (
        <div onClick={(e) => e.stopPropagation()}>
          <Modal.Root>
            <Modal.Trigger asChild>
              <button className="all:unset hover:t-underline t-text-body-sm t-text-text-30">
                +{otherContacts.length} more
              </button>
            </Modal.Trigger>

            <Modal.Content>
              <Modal.Header>
                <Modal.Title>Investors</Modal.Title>
                <Modal.Close />
              </Modal.Header>
              <Modal.Body>
                <ContactsTable contacts={contacts} />
              </Modal.Body>
              <Modal.Footer>
                <Modal.RawClose asChild>
                  <Button block>Close</Button>
                </Modal.RawClose>
              </Modal.Footer>
            </Modal.Content>
          </Modal.Root>
        </div>
      )}
    </>
  );
};

export const InvestorTable = () => {
  const query = useQuery();
  const pageNum = Number(query.get("page")) || 1;
  const searchTerm = query.get("search_term") || null;
  const dispatch = useDispatch();

  const { openSlider, enableEdit } = useSelector(
    (state: RootState) => state.raiseCrm
  );

  const [rowData, setRowData] = useState<VentureCapitalist | null>();

  const { data, isLoading, isSuccess } = useGetAllVCsQuery({
    pageNum,
  });

  const {
    vcs = [],
    current_page = 1,
    per_page = 1,
    total_count = 1,
    total_pages = 1,
  } = data || {};

  const columnHelper = createColumnHelper<VentureCapitalist>();

  const columns = [
    columnHelper.accessor("name", {
      cell: (info) => {
        const {
          row: {
            original: { name, website },
          },
        } = info;

        return (
          <div className="t-flex t-gap-1 t-items-center">
            <ConditionalLink to={website} onClick={(e) => e.stopPropagation()}>
              <img src={Globe} alt="website" />
            </ConditionalLink>
            <div className="t-text-body">{name}</div>
          </div>
        );
      },
      header: "Fund Name",
      size: 14,
    }),

    columnHelper.accessor("contacts", {
      cell: ContactColumn,
      header: "Contact",
      size: 12,
    }),

    columnHelper.accessor("location", {
      cell: (info) => {
        return (
          <div className="t-text-subtext t-text-text-60">{info.getValue()}</div>
        );
      },
      header: "Location",
      size: 20,
    }),

    columnHelper.accessor("vc_type", {
      cell: (
        info: CellContext<VentureCapitalist, VentureCapitalist["vc_type"]>
      ) => {
        return (
          <div className="t-text-subtext t-text-text-60">{info.getValue()}</div>
        );
      },
      header: "Type",
      size: 12,
    }),

    // columnHelper.display({
    //   cell: (info) => {
    //     const interested = 0;
    //     const notInterested = 0;
    //     return (
    //       <div className="t-flex t-gap-2">
    //         <div className="t-flex t-gap-1.5 t-bg-green-10 t-rounded t-px-2 t-py-0.5 t-items-center">
    //           <div className="t-rounded-full t-w-1.5 t-h-1.5 t-bg-green-50"></div>
    //           <div className="t-text-green-70 t-text-body-sm">
    //             {interested} Interested
    //           </div>
    //         </div>
    //         <div className="t-flex t-gap-1.5 t-bg-red-10 t-rounded t-px-2 t-py-0.5  t-items-center">
    //           <div className="t-rounded-full t-w-1.5 t-h-1.5 t-bg-red-50"></div>
    //           <div className="t-text-red-60 t-text-body-sm">
    //             {notInterested} Not interested
    //           </div>
    //         </div>
    //       </div>
    //     );
    //   },
    //   header: "Activity",
    //   size: 14,
    // }),

    columnHelper.display({
      cell: (info) => {
        const {
          row: {
            original: { uuid },
          },
        } = info;

        return <InviteModal vcId={uuid} />;
      },
      header: "Action",
      size: 14,
    }),
  ];

  const table = useReactTable({
    data: vcs || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 1,
    },
  });

  const onRowClick = (
    e: MouseEvent<HTMLTableRowElement>,
    row: { original: VentureCapitalist }
  ) => {
    if (openSlider && row.original.uuid === rowData?.uuid) {
      dispatch(setOpenSlider(false));
      dispatch(setEditable(false));
      setRowData(null);
      return;
    }
    e.stopPropagation();
    setRowData(row.original);
    dispatch(setOpenSlider(true));
  };

  const isEmpty = vcs.length === 0;

  return (
    <div className="t-p-4">
      <Async.Root {...{ isLoading, isSuccess, isEmpty }}>
        <Async.Empty>
          <EmptyScreen text="" className="!t-h-full">
            <div className="t-h-full t-flex t-flex-col t-items-center t-gap-4">
              <div>
                <img src={ProfileEmptyState} alt="noProfiles" />
              </div>
              <Button
                customType="primary"
                size="small"
                onClick={() => dispatch(createProfile())}
              >
                Add fund
              </Button>
            </div>
          </EmptyScreen>
        </Async.Empty>
        <Async.Success>
          <Table.Container className="t-h-full">
            <Table.Content className="t-w-full">
              <Table.Head>
                {table.getHeaderGroups().map((headerGroup) => (
                  <Table.Row key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <Table.HeadCell
                        key={header.id}
                        className="t-text-subtext-sm t-uppercase t-py-2 t-h-10"
                        style={{
                          width: `${header.getSize()}%`,
                        }}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </Table.HeadCell>
                    ))}
                  </Table.Row>
                ))}
              </Table.Head>
              <Table.Body>
                {table.getRowModel().rows.map((row) => (
                  <Table.Row
                    key={row.id}
                    onClick={(e) => onRowClick(e, row)}
                    className="t-cursor-pointer hover:t-bg-surface-lighter-grey"
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        style={{
                          width: `${cell.column.getSize()}%`,
                        }}
                        className="t-py-2 t-text-body t-h-10"
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </Table.Row>
                ))}
              </Table.Body>
            </Table.Content>
          </Table.Container>
        </Async.Success>
      </Async.Root>
      <Slider
        open={openSlider}
        position="right"
        width={500}
        className="t-overflow-auto t-rounded t-border t-border-solid t-border-neutral-0 t-shadow !t-z-slider-overlay !t-fixed t-w-[500px] !t-p-0"
      >
        <div className="t-flex t-justify-between t-border-b t-border-neutral-10 t-border-solid t-border-0 t-p-4 t-sticky t-top-0 t-bg-surface t-z-slider">
          <div className="t-flex t-gap-2 t-items-center">
            {rowData?.name && (
              <div className="t-bg-surface-grey t-w-8 t-h-8 t-flex t-justify-center t-items-center t-rounded-full">
                {rowData?.name?.at(0)?.toUpperCase()}
              </div>
            )}

            <span>{rowData?.name}</span>
          </div>
          <Button
            customType="ghost_icon"
            size="small"
            onClick={() => {
              dispatch(setEditable(false));
              setRowData(null);
              dispatch(setOpenSlider(false));
            }}
          >
            <Cross color="currentColor" />
          </Button>
        </div>

        {enableEdit ? (
          <InvestorEdit investorId={rowData?.uuid!} />
        ) : (
          <InvestorView investorId={rowData?.uuid!} />
        )}
      </Slider>
    </div>
  );
};
