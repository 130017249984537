import axios from "axios";
import {
  SIGNUP_OTP_VERIFICATION_FAILURE,
  SIGNUP_OTP_VERIFICATION_SUCCESS,
} from "constants/analyticsEvents";
import { TASK_SESSION_ID } from "constants/session";
import { AFTER_AUTH_REDIRECT_TO } from "constants/storageKeys";
import { useAnalytics } from "hooks/useAnalytics";
import { useToast } from "hooks/useToast";
import jwt_decode from "jwt-decode";
import { createContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { INCORRECT_OTP, LINK_EXPIRED, TOKEN } from "../constants/magicLink";

const SignIn_url =
  process.env.REACT_APP_BASE_URL + "api/inkle/users/emaillogin/";
const OtpRequest_url =
  process.env.REACT_APP_BASE_URL + "api/inkle/users/verifyemailmobile/";
const Token_MagicSignIn_url =
  process.env.REACT_APP_BASE_URL +
  "api/inkle/users/verify-magic-link-token/?token=";
const Payload_MagicSignIn_url =
  process.env.REACT_APP_BASE_URL +
  "api/inkle/users/verify-magic-link-token/?payload=";

export const authContext = createContext();

export default authContext;
export const AuthProvider = ({ children }) => {
  const { alertToast } = useToast();
  const history = useHistory();
  const { trackEvent } = useAnalytics();
  const [entities, setEntities] = useState([]);
  const [sendbirdCreds, setSendbirdCreds] = useState({});
  const [location, setLocation] = useState("");

  let [authtoken, setAuthtoken] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );

  let [user, setUser] = useState(() =>
    JSON.parse(localStorage.getItem("authTokens"))?.access
      ? jwt_decode(JSON.parse(localStorage.getItem("authTokens")).access)
      : null
  );

  const updateUser = ({ data: { data } }) => {
    const updatedUser = { ...(authtoken || {}), ...data };
    setAuthtoken(updatedUser);
    localStorage.setItem("authTokens", JSON.stringify(updatedUser));
  };

  const updateSignupData = (res) => {
    setAuthtoken(res.data.data);
    setUser(res.data.data.access);
    localStorage.setItem("authTokens", JSON.stringify(res.data.data));
    if (res.data.data.is_restricted) {
      history.push("/verify");
    } else if (res.data.data.is_group_created) {
      history.push("/");
    } else {
      history.push("/profile");
    }
  };

  let signupUser = async (values) => {
    try {
      const res = await axios.post(OtpRequest_url, {
        mobile: values.phone_no,
        email: values.email_id,
        otp: values.p_verify,
        token: values.e_verify,
        name: values.first_name,
        last_name: values.last_name,
        password: values.password,
        confirm_password: values.cnf_password,
        wa_consent: values.whtsp,
        coupon_code: values?.couponCode,
      });

      if (res.status === 200) updateSignupData(res);
      else history.push("/signup");
    } catch (error) {
      alertToast({ message: error?.response?.data?.error?.message });
    }
  };

  const updateSigninData = (res) => {
    setAuthtoken(res.data.data);
    setUser(jwt_decode(res.data.data.access));
    localStorage.setItem("authTokens", JSON.stringify(res.data.data));
    localStorage.setItem(
      "isMobileVerified",
      JSON.stringify(res.data.data.is_mobile_verified)
    );
    if (res.data.data.is_restricted) {
      history.push("/verify");
    } else if (res.data.data?.is_archived && res.data.data.is_group_created) {
      history.push("/archived");
    } else if (res.data.data.is_group_created) {
      history.push(localStorage.getItem(AFTER_AUTH_REDIRECT_TO) || "/");
      localStorage.removeItem(AFTER_AUTH_REDIRECT_TO);
    } else {
      history.push("/profile");
    }
  };

  let loginUser = async (values) => {
    try {
      const res = await axios.post(SignIn_url, {
        email: values.email_id,
        password: values.password,
      });
      if (res.status === 200) updateSigninData(res);
      else history.push("/signin");
    } catch (error) {
      alertToast({ message: error?.response?.data?.error?.message });
      history.push(location);
    }
  };

  const loginMagicUser = async (type, otp, payload) => {
    try {
      let updatedUrl;
      if (type === TOKEN) updatedUrl = Token_MagicSignIn_url + otp;
      else updatedUrl = Payload_MagicSignIn_url + otp;

      const res = await axios.post(updatedUrl, payload);
      if (res.status === 200) {
        if (res.data.data?.mobile && res.data?.data?.is_mobile_verified) {
          updateSigninData(res);
        } else {
          localStorage.setItem("currentUser", JSON.stringify(res.data.data));
          trackEvent(SIGNUP_OTP_VERIFICATION_SUCCESS, {
            screen: "OTP Verification - Email",
            first_name: res.data.data.first_name,
            last_name: res.data.data.last_name,
            email: res.data.data.email,
          });
          return true;
        }
      }
    } catch (e) {
      if (payload?.first_name) {
        trackEvent(SIGNUP_OTP_VERIFICATION_FAILURE, {
          screen: "OTP Verification - Email",
          first_name: payload.first_name,
          last_name: payload.last_name,
          email: payload.email,
          error_message: e?.response?.data?.error?.message,
        });
      }
      alertToast({ message: e?.response?.data?.error?.message });
      if (e?.response?.data?.error?.message === INCORRECT_OTP)
        return INCORRECT_OTP;
      else if (e?.response?.data?.error?.message === LINK_EXPIRED)
        return LINK_EXPIRED;
    }
  };

  let logoutUser = () => {
    setAuthtoken(null);
    setUser(null);
    localStorage.clear();
    setEntities([]);
    history.push("/signin");
    console.clear();
  };

  let contextData = {
    user: user,
    setUser: setUser,
    loginUser: loginUser,
    loginMagicUser: loginMagicUser,
    logoutUser: logoutUser,
    authtoken: authtoken,
    setAuthtoken: setAuthtoken,
    signupUser: signupUser,
    setLocation: setLocation,
    sendbirdCreds: sendbirdCreds,
    setSendbirdCreds: setSendbirdCreds,
    groupEntities: entities,
    setGroupEntities: setEntities,
    updateSigninData: updateSigninData,
    updateUser,
  };

  return (
    <authContext.Provider value={contextData}>{children}</authContext.Provider>
  );
};
