import { AmountSuperScript } from "components/design/AmountSuperScript";
import { Divider } from "components/design/Divider";
import Loader from "components/design/loader";
import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import Modal from "components/DesignSystem/Modal/Modal";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useGetSeasonSummaryQuery } from "store/apis/financialClosing";
import { openFloatingChat } from "store/slices/chat";

export const PaymentModal = ({
  isOpen,
  setIsOpen,
  uuid,
  onProceedToPay,
  inLoading,
}: {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  uuid: string;
  onProceedToPay: () => void;
  inLoading: boolean;
}) => {
  const [agree, setAgree] = useState(false);
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();

  const dispatch = useDispatch();

  const { data: seasonSummary, isLoading } = useGetSeasonSummaryQuery(
    { entityId, groupId, uuid, getUnpaidInvoices: true },
    {
      skip: !groupId || !entityId || !uuid,
    }
  );

  const { name, unpaid_invoices = [] } = seasonSummary || {};
  const isUnpaid = unpaid_invoices?.length > 0;

  const totalUnpaidAmount = unpaid_invoices.reduce(
    (accumulator, { amount }) => accumulator + +amount,
    0
  );

  const chatWithUs = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsOpen(false);
    dispatch(openFloatingChat());
  };

  const onAgree = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setAgree(checked);
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={setIsOpen}>
      <Modal.Content className="!t-w-[628px]">
        <Modal.Header>
          <Modal.Title>Review Books for {name}</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <Modal.Description>
            <p className="t-text-body-sm t-text-neutral">
              By clicking {isUnpaid ? '"proceed to pay"' : '"proceed"'}, you are
              agreeing that you have reviewed the financials of the ongoing year
              and want to close the books. Connect with us on chat if you have
              any queries.
            </p>
          </Modal.Description>
          <div className="t-flex t-flex-col t-gap-5">
            {isUnpaid && (
              <>
                <div className="t-flex t-flex-col t-gap-3 t-text-subtitle-sm">
                  <div className="t-text-text-100">Pending Payments</div>
                  <div className="t-flex t-flex-col t-gap-3 t-font-medium t-text-i-text-light">
                    {unpaid_invoices.map(({ amount, short_name, uuid }) => (
                      <div className="t-flex t-justify-between" key={uuid}>
                        <span>{short_name}</span>
                        <span>
                          <AmountSuperScript amount={+amount} />
                        </span>
                      </div>
                    ))}
                  </div>
                  <Divider />
                  <div className="t-flex t-justify-between">
                    <div className="t-text-text-100">Pending Payments</div>
                    <AmountSuperScript amount={totalUnpaidAmount} />
                  </div>
                </div>
              </>
            )}
            <Checkbox
              name="agree"
              label={
                <span className="t-text-subtitle-sm t-font-medium t-text-text-black">
                  I have reviewed the books for this year and would like to mark
                  it as closed
                </span>
              }
              onChange={onAgree}
              checked={agree}
            />
          </div>
        </Modal.Body>
        <Modal.FooterButtonGroup>
          <Button customType="secondary" onClick={chatWithUs}>
            Chat with us
          </Button>
          <Button
            customType="primary"
            disabled={!agree || inLoading}
            onClick={onProceedToPay}
            isLoading={inLoading}
          >
            Proceed {isUnpaid && "to pay"}
          </Button>
        </Modal.FooterButtonGroup>
      </Modal.Content>
    </Modal.Root>
  );
};
