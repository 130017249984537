import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { Form, Formik, FormikHelpers } from "formik";
import { addFilingManualBankSchema } from "formValidations/AddFilingManualBankSchema";
import { useToast } from "hooks/useToast";
import {
  useCreateAutofillMutation,
  useUpdateAutofillMutation,
} from "store/apis/autofill";
import {
  AutofillComponentProps,
  BankAutofill,
} from "types/Models/bankAutofill";
import { BackendError } from "types/utils/error";

type AddManualBankValues = {
  name: string;
  account_number: string;
  routing_number: string;
};

export const AddFilingManualBank = ({
  isOpenEdit,
  onClose,
  entityId,
  initialValue,
  groupId,
  onSuccess,
}: AutofillComponentProps & {
  initialValue?: BankAutofill;
  onSuccess: (value: BankAutofill) => void;
}) => {
  const bankAutofillKey = "bank_accounts";
  const { alertToast, successToast } = useToast();
  const [createAutofill, { isLoading: isAdding }] = useCreateAutofillMutation();
  const [editAutofill, { isLoading: isUpdating }] = useUpdateAutofillMutation();

  const addBank = async (
    values: AddManualBankValues,
    { resetForm }: FormikHelpers<AddManualBankValues>
  ) => {
    try {
      if (entityId) {
        const bank = (await createAutofill({
          groupId,
          entityId,
          autofillKey: bankAutofillKey,
          fields: {
            account_number: values.account_number,
            name: values.name,
            routing_number: values.routing_number,
            entity_id: entityId,
          },
        }).unwrap()) as BankAutofill;
        successToast({ message: "Bank added successfully!" });
        resetForm();
        onSuccess?.(bank);
        onClose();
      }
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const editBank = async (
    values: AddManualBankValues,
    { resetForm }: FormikHelpers<AddManualBankValues>
  ) => {
    try {
      if (entityId) {
        const bank = (await editAutofill({
          groupId,
          entityId,
          autofillKey: bankAutofillKey,
          fields: {
            account_number: values.account_number,
            name: values.name,
            routing_number: values.routing_number,
            entity_id: entityId,
          },
          uuid: initialValue?.uuid!,
        }).unwrap()) as BankAutofill;
        successToast({ message: "Bank added successfully!" });
        resetForm();
        onSuccess?.(bank);
        onClose();
      }
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const initialValueForEdit = {
    name: initialValue?.name!,
    account_number: initialValue?.account_number!,
    routing_number: initialValue?.routing_number!,
  };

  const initialValueForAdd = {
    name: "",
    account_number: "",
    routing_number: "",
  };
  const isEditingFlow = Boolean(initialValue?.uuid);

  return (
    <>
      <Modal.Root open={isOpenEdit} onOpenChange={onClose}>
        <Formik
          initialValues={
            isEditingFlow ? initialValueForEdit : initialValueForAdd
          }
          onSubmit={isEditingFlow ? editBank : addBank}
          validateOnChange
          validationSchema={addFilingManualBankSchema}
          enableReinitialize
        >
          {({ submitForm, isSubmitting }) => (
            <Form>
              <Modal.Content useCustomOverlay>
                <Modal.Header>
                  <Modal.Title>
                    {isEditingFlow ? "Edit" : "Add"} Bank Account
                  </Modal.Title>
                  <Modal.Close />
                </Modal.Header>
                <Modal.Body className="t-flex t-gap-4 t-flex-col">
                  <TextInput
                    required
                    name="name"
                    label="Bank Name (As mentioned in Domestic Wire Details)"
                  />
                  <TextInput
                    required
                    name="account_number"
                    label="Account Number"
                  />
                  <TextInput
                    required
                    name="routing_number"
                    label="Routing Number"
                  />
                </Modal.Body>
                <Modal.Footer>
                  <div className="t-flex t-gap-3 t-justify-end t-w-full">
                    <Button
                      type="reset"
                      onClick={onClose}
                      disabled={isSubmitting}
                    >
                      Cancel
                    </Button>
                    <Button
                      customType="primary"
                      onClick={submitForm}
                      isLoading={isAdding || isUpdating}
                      disabled={isAdding || isUpdating}
                      type="submit"
                    >
                      Save
                    </Button>
                  </div>
                </Modal.Footer>
              </Modal.Content>
            </Form>
          )}
        </Formik>
      </Modal.Root>
    </>
  );
};
