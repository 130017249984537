import { CustomLayerProps, ResponsiveLine } from "@nivo/line";
import classNames from "classnames";
import { colors } from "constants/colors";
import { motion } from "framer-motion";
import { useState } from "react";
import { formatAmount } from "utils/foramtAmount";

const Crosshair = ({
  hoverSectionId,
  ...props
}: CustomLayerProps & {
  hoverSectionId: string;
}) => {
  const { points } = props;

  return (
    <g>
      {points.map(({ id, x, y }) => {
        return (
          <motion.line
            layout
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "100%" }}
            transition={{ duration: 0.1, delay: 0.1, ease: "easeInOut" }}
            key={id}
            className={classNames({
              "t-hidden": id !== hoverSectionId,
              "t-block": id === hoverSectionId,
            })}
            x1={x}
            x2={x}
            y1={y}
            y2="90%"
            stroke={colors.purple[40]}
            strokeWidth={1}
          />
        );
      })}
    </g>
  );
};

const Chart = ({
  title,
  chartData,
}: {
  title: string;
  chartData: {
    x: string;
    y: number;
  }[];
}) => {
  const [hoverSectionId, setHoverPoint] = useState("");

  const data = [
    {
      id: "revenue",
      data: chartData,
    },
  ];

  return (
    <div className="t-h-80 t-w-full">
      <ResponsiveLine
        key={title}
        onMouseMove={(p) => {
          // @ts-ignore
          const [point] = p.points;
          setHoverPoint(point?.id);
        }}
        onMouseLeave={() => setHoverPoint("")}
        margin={{ bottom: 20, left: 50, right: 50, top: 20 }}
        enableArea={true}
        data={data}
        pointColor={colors.purple[40]}
        defs={[
          {
            id: "revenueArea",
            type: "linearGradient",
            colors: [
              { offset: 0, color: "#BBA7FF" },
              { offset: 100, color: "#F7F4FF" },
            ],
            gradientTransform: "rotate(350 0.5 0.5)",
          },
        ]}
        fill={[{ match: { id: "revenue" }, id: "revenueArea" }]}
        colors={[colors.purple[40]]}
        enableGridX={false}
        enableGridY={false}
        pointLabel="data.yFormatted"
        pointLabelYOffset={-12}
        pointSize={6}
        lineWidth={1.5}
        enableTouchCrosshair={true}
        useMesh={true}
        axisBottom={{
          renderTick: (tick) => (
            <g transform={`translate(${tick.x},${tick.y})`}>
              <text dy={16} textAnchor="middle" fontSize={10} fill="#666">
                {tick.value}
              </text>
            </g>
          ),
        }}
        axisLeft={{
          format: formatAmount,
        }}
        sliceTooltip={({ slice }) => {
          return (
            <div
              className={
                "t-bg-neutral-100 t-p-3 t-rounded t-text-subtext-sm t-text-white t-min-w-56 t-min-h-16 t-flex t-flex-col t-gap-2 t-justify-center t-items-center"
              }
            >
              <div>{slice.points[0].data.xFormatted}</div>
              <div className="t-flex t-gap-2">
                <div
                  style={{
                    background:
                      "linear-gradient(350deg, #F7F4FF 4.17%, #BBA7FF 129.05%)",
                  }}
                  className="t-w-4 t-h-4 t-rounded-sm"
                ></div>
                {title}: {formatAmount(Number(slice.points[0].data.y))}
              </div>
            </div>
          );
        }}
        enableSlices="x"
        layers={[
          "grid",
          "markers",
          "axes",
          "areas",
          (props) => <Crosshair {...props} hoverSectionId={hoverSectionId} />,
          "lines",
          "slices",
          "points",
          "mesh",
          "legends",
        ]}
      />
    </div>
  );
};

export const RevenueChart = ({
  title,
  chartData,
}: {
  title: string;
  chartData: {
    x: string;
    y: number;
  }[];
}) => {
  return (
    <div className="t-flex t-gap-3 t-border t-border-solid t-border-neutral-0 t-shadow-light-30 t-rounded-lg t-flex-col">
      <div className="t-w-full t-border t-border-solid t-border-neutral-0 t-border-t-0 t-border-l-0 t-border-r-0 t-p-5 t-text-subtitle">
        {title}
      </div>
      <div className="t-p-5 t-w-full">
        <Chart title={title} chartData={chartData} />
      </div>
    </div>
  );
};
