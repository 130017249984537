export const LAST_MONTH = "lastmonth";
export const CURRENT_MONTH = "currentmonth";
export const LAST_3_MONTHS = "last3months";
export const LAST_6_MONTHS = "last6months";
export const LAST_12_MONTHS = "last12months";
export const CURRENT_YEAR = "currentYear";
export const LAST_YEAR = "lastYear";
export const LAST_30_DAYS = "last30days";
export const LAST_90_DAYS = "last90days";
export const LAST_180_DAYS = "last180days";
export const CUSTOM = "custom";
