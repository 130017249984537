export const Link = ({
  size = "16",
  color = "#706A85",
  stroke = "1.4",
}: {
  size?: string;
  color?: string;
  stroke?: string;
}) => {
  return (
    <>
      <span className="t-sr-only">Link</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 16 16"
        fill="none"
      >
        <g clip-path="url(#clip0_2091_15057)">
          <path
            d="M8.83627 4.04249L9.52377 3.35499C9.79435 3.08434 10.1156 2.86964 10.4692 2.72316C10.8227 2.57668 11.2017 2.50128 11.5844 2.50128C11.9671 2.50128 12.3461 2.57668 12.6996 2.72316C13.0532 2.86964 13.3744 3.08434 13.645 3.35499C13.9157 3.62557 14.1304 3.94682 14.2769 4.30038C14.4233 4.65395 14.4987 5.03291 14.4987 5.41562C14.4987 5.79833 14.4233 6.17729 14.2769 6.53085C14.1304 6.88442 13.9157 7.20566 13.645 7.47624L12.1213 8.99999L11.475 9.64624C11.2041 9.91718 10.8825 10.132 10.5284 10.2785C10.1744 10.425 9.795 10.5003 9.41187 10.5C9.02874 10.4997 8.64942 10.4239 8.29563 10.2769C7.94183 10.1298 7.6205 9.91446 7.35002 9.64312C7.07005 9.36325 6.85023 9.02911 6.70406 8.66122C6.55789 8.29333 6.48846 7.89944 6.50002 7.50374"
            stroke={color}
            strokeWidth={stroke}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.16374 11.9575L6.47624 12.645C6.20534 12.9159 5.88369 13.1308 5.52967 13.2773C5.17565 13.4238 4.79622 13.499 4.41309 13.4988C4.02996 13.4985 3.65064 13.4226 3.29685 13.2756C2.94305 13.1286 2.62172 12.9132 2.35124 12.6419C1.80623 12.095 1.50052 11.3541 1.50122 10.582C1.50192 9.80993 1.80898 9.06966 2.35499 8.52374L4.52499 6.35374C4.79557 6.08309 5.11681 5.86839 5.47038 5.72191C5.82394 5.57542 6.2029 5.50003 6.58561 5.50003C6.96832 5.50003 7.34728 5.57542 7.70085 5.72191C8.05441 5.86839 8.37566 6.08309 8.64624 6.35374C8.92737 6.63359 9.14816 6.96808 9.295 7.33657C9.44184 7.70506 9.5116 8.09974 9.49999 8.49624"
            stroke={color}
            strokeWidth={stroke}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_2091_15057">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};
