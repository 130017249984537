import { Tag } from "types/Models/FCA";
import { emptyApi } from "./emptyApi";

const extendedApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getTaskTag: build.query<Tag[], void>({
      query: () => {
        return { url: `/api/inkle/tags/TASK_TAG/` };
      },
    }),

    getAllTaskStates: build.query<{ name: string; uuid: string }[], void>({
      query: () => {
        return { url: `/api/inkle/tasks/get_all_task_states/` };
      },
    }),

    getTaskData: build.query({
      query: ({ uuid }) => {
        return { url: `/api/inkle/tasks/${uuid}/` };
      },
    }),

    addTags: build.mutation({
      query: ({ taskId, tagId }) => {
        return {
          url: `/api/inkle/tags/tasks/${taskId}/tag/${tagId}/`,
          method: "POST",
        };
      },
      invalidatesTags: (result) =>
        result ? ["All_SERVICE_PROVIDERS", "Tasks"] : [],
    }),
    removeTags: build.mutation({
      query: ({ taskId, tagId }) => {
        return {
          url: `/api/inkle/tags/tasks/${taskId}/tag/${tagId}/`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result) => (result ? ["All_SERVICE_PROVIDERS"] : []),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTaskTagQuery,
  useGetAllTaskStatesQuery,
  useLazyGetTaskDataQuery,
  useAddTagsMutation,
  useRemoveTagsMutation,
} = extendedApi;
