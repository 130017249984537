import "chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm";
import classNames from "classnames";
import DashboardContainer from "components/dashboard/DashboardContainer";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Avatar } from "components/DesignSystem/AvatarGroup/Avatar";
import { Button } from "components/DesignSystem/Button/Button";
import { Chip } from "components/DesignSystem/Chips/Chips";
import { Filter } from "components/DesignSystem/Filter/Filter";
import { Link } from "components/DesignSystem/Link/Link";
import { Loader } from "components/DesignSystem/Loader/Loader";
import Modal from "components/DesignSystem/Modal/Modal";
import Table from "components/DesignSystem/Table/V2/Table";
import { DateFilter } from "components/Filters/DateFilter";
import { Expand } from "components/icons/Expand";
import { MagnifyingGlass } from "components/icons/MagnifyingGlass";
import { TransactionSlider } from "components/Transaction/Slider/TransactionSlider";
import TransactionColumn from "components/Transaction/TransactionColumn";
import { TRANSACTIONS } from "constants/chatType";
import { DD_MMM_YYYY, YYYY_MM_DD } from "constants/date";
import dayjs from "dayjs";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useConstructInternalLink } from "hooks/useConstructInternalLink";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useFilters } from "hooks/useFilter";
import { useToast } from "hooks/useToast";
import { EmptyScreen } from "pages/Books/EmptyScreen";
import { MouseEvent, ReactNode, useMemo } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  Table as TableType,
  useReactTable,
} from "react-table-8.10.7";
import { useGetInsightsQuery } from "store/apis/metrics";
import {
  useGetAllTransactionsDataQuery,
  usePushToChatMutation,
} from "store/apis/transactions";
import { openFloatingChat, setToOpenChatId } from "store/slices/chat";
import { openSlider } from "store/slices/transactions";
import { Transactions } from "types/Models/books";
import { formatDate } from "utils/formatDate";
import { getDateRange } from "utils/getDateRange";
import { TopCategoriesExpanded } from "./TopCategoriesExpanded";
import { TopVendorsExpanded } from "./TopVendorsExpanded";
import { DoughnutChart } from "./DoughnutChat";
import { EmptyVendor } from "components/Illustrations/EmptyVendor";
import { EmptyCategory } from "components/Illustrations/EmptyCategory";
import { EmptyTopSpends } from "components/Illustrations/EmptyTopSpends";
import { EmptyMetrics } from "components/EmptyMetrics/EmptyMetrics";

const TransactionsPreviewTable = ({
  table,
  isFetching,
  onRowClick,
}: {
  table: TableType<Transactions>;
  isFetching: boolean;
  onRowClick?: (
    transactionId: string,
    e: MouseEvent<HTMLTableRowElement, globalThis.MouseEvent>
  ) => void;
}) => {
  const { sliderTransactions } = useAppSelector((state) => state?.transactions);

  return (
    <Table.Container className="t-h-full" isLoading={isFetching}>
      <Table.Content>
        <Table.Head>
          {table.getHeaderGroups().map((headerGroup) => (
            <Table.HeadRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Table.HeadCell
                  className="t-text-subtext-sm t-uppercase t-px-2 t-py-4 t-group"
                  key={header.id}
                  style={{ width: `${header.getSize()}%` }}
                >
                  <span className="t-flex t-gap-1 t-items-center">
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </span>
                </Table.HeadCell>
              ))}
            </Table.HeadRow>
          ))}
        </Table.Head>
        <Table.Body>
          {table.getRowModel().rows.map((row) => (
            <>
              <Table.Row
                key={row.id}
                className={classNames({
                  "t-bg-surface-lighter-grey": sliderTransactions.find(
                    ({ transactionId }) =>
                      transactionId === row?.original?.transaction?.uuid
                  ),
                  "hover:t-bg-surface-lighter-grey t-cursor-pointer":
                    Boolean(onRowClick),
                })}
                onClick={(e) => onRowClick?.(row.original.transaction.uuid, e)}
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <Table.Cell
                      key={cell.id}
                      style={{
                        width: `${cell.column.getSize()}%`,
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Table.Cell>
                  );
                })}
              </Table.Row>
            </>
          ))}
        </Table.Body>
      </Table.Content>
    </Table.Container>
  );
};

const Card = ({
  children,
  title,
}: {
  children: React.ReactNode;
  title: ReactNode;
}) => {
  return (
    <div className="t-p-5 t-border t-border-solid t-border-neutral-0 t-rounded-lg t-overflow-hidden t-gap-6 t-flex t-flex-col">
      <p className="t-m-0 t-text-subtitle-sm t-text-text-60">{title}</p>
      {children}
    </div>
  );
};

export const TopFiveVendors = ({
  startDate,
  endDate,
  top_five_merchants,
  isInsightsSuccess,
  isInsightsLoading,
  isInsightsFetching,
}: {
  startDate: string;
  endDate: string;
  top_five_merchants: Array<{
    amount: number;
    logo: string | null;
    merchant_name: string;
  }>;
  isInsightsSuccess: boolean;
  isInsightsLoading: boolean;
  isInsightsFetching: boolean;
}) => {
  const { link } = useConstructInternalLink();

  return (
    <Card
      title={
        <div className="t-flex t-justify-between">
          <p className="t-m-0">Top Vendors</p>
          <Modal.Root>
            <Modal.Trigger asChild>
              <Button
                disabled={top_five_merchants.length === 0}
                customType="ghost_icon"
                size="small"
                data-testid="Expand button"
              >
                <Expand />
              </Button>
            </Modal.Trigger>
            <Modal.Content size="xl">
              <Modal.Header>
                <Modal.Title>Top Vendors</Modal.Title>
                <Modal.Close />
              </Modal.Header>
              <Modal.Body>
                <TopVendorsExpanded startDate={startDate} endDate={endDate} />
              </Modal.Body>
              <Modal.FooterButtonGroup>
                <Modal.RawClose asChild>
                  <Button>Close</Button>
                </Modal.RawClose>
                <Link to={link("/books/customers-and-vendors")}>
                  <Button customType="primary">View all</Button>
                </Link>
              </Modal.FooterButtonGroup>
            </Modal.Content>
          </Modal.Root>
        </div>
      }
    >
      <Async.Root
        {...{
          isLoading: isInsightsLoading,
          isEmpty: top_five_merchants.length === 0,
          isSuccess: isInsightsSuccess,
        }}
      >
        <Async.Empty>
          <EmptyMetrics
            illustration={<EmptyVendor />}
            title="No vendors found"
            subtitle="Add transactions to see top vendors"
          />
        </Async.Empty>
        <Async.Success>
          <div className="t-grid t-grid-cols-1 t-gap-5">
            {top_five_merchants.map(({ amount, logo, merchant_name }, i) => (
              <div
                key={i}
                className="t-grid t-grid-cols-2 t-justify-between t-text-text-60 t-text-body t-gap-2 t-items-center"
              >
                <div className="t-flex t-gap-4 t-items-center">
                  {isInsightsFetching ? (
                    <>
                      <div className="t-h-8 t-w-8 t-animate-pulse t-bg-neutral-20 t-rounded-full t-py-2" />
                      <div className="t-h-6 t-animate-pulse t-bg-neutral-20 t-rounded-md t-py-2 t-w-4/5" />
                    </>
                  ) : (
                    <>
                      <Avatar alt={merchant_name} src={logo!} />
                      {merchant_name}
                    </>
                  )}
                </div>
                <div className="t-text-end t-justify-self-end">
                  {isInsightsFetching ? (
                    <div className="t-h-6 t-animate-pulse t-bg-neutral-20 t-rounded-md t-py-2 t-w-28" />
                  ) : (
                    <AmountSuperScript amount={amount} />
                  )}
                </div>
              </div>
            ))}
          </div>
        </Async.Success>
      </Async.Root>
    </Card>
  );
};

export const TopFiveCategories = ({
  startDate,
  endDate,
  top_five_categories,
  isInsightsSuccess,
  isInsightsLoading,
  isInsightsFetching,
}: {
  startDate: string;
  endDate: string;
  top_five_categories: Array<{
    amount: number;
    category_name: string;
  }>;
  isInsightsSuccess: boolean;
  isInsightsLoading: boolean;
  isInsightsFetching: boolean;
}) => {
  const { link } = useConstructInternalLink();
  return (
    <Card
      title={
        <div className="t-flex t-justify-between">
          <p className="t-m-0">Top Categories</p>
          <Modal.Root>
            <Modal.Trigger asChild>
              <Button
                customType="ghost_icon"
                size="small"
                disabled={top_five_categories.length === 0}
              >
                <Expand />
              </Button>
            </Modal.Trigger>
            <Modal.Content size="xxxl">
              <Modal.Header>
                <Modal.Title>Top Categories</Modal.Title>
                <Modal.Close />
              </Modal.Header>
              <Modal.Body>
                <TopCategoriesExpanded
                  startDate={startDate}
                  endDate={endDate}
                />
              </Modal.Body>
              <Modal.FooterButtonGroup>
                <Modal.RawClose asChild>
                  <Button>Close</Button>
                </Modal.RawClose>
                <Link to={link("/books/categories")}>
                  <Button customType="primary">View all</Button>
                </Link>
              </Modal.FooterButtonGroup>
            </Modal.Content>
          </Modal.Root>
        </div>
      }
    >
      <Async.Root
        {...{
          isLoading: isInsightsLoading,
          isEmpty: top_five_categories.length === 0,
          isSuccess: isInsightsSuccess,
        }}
      >
        <Async.Empty>
          <EmptyMetrics
            illustration={<EmptyCategory />}
            title="No transactions categorised"
            subtitle="Add/categorise transactions to see top categories"
          />
        </Async.Empty>
        <Async.Success>
          <div className="t-w-full t-h-full t-flex t-justify-center">
            {isInsightsFetching ? (
              <Loader />
            ) : (
              <DoughnutChart topFiveCategories={top_five_categories} />
            )}
          </div>
        </Async.Success>
      </Async.Root>
    </Card>
  );
};

export const TopInsights = () => {
  const defaultDateRange = getDateRange("currentYear");
  const { values, updateFilter } = useFilters({
    initialValue: {
      START_DATE: dayjs(defaultDateRange.startDate).format(YYYY_MM_DD),
      END_DATE: dayjs(defaultDateRange.endDate).format(YYYY_MM_DD),
      SELECT_PERIOD: "currentYear",
    },
  });

  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const dispatch = useAppDispatch();

  const {
    data: transactionData,
    isLoading,
    isSuccess,
    isFetching,
  } = useGetAllTransactionsDataQuery(
    {
      groupId,
      entityId,
      sortCol: "AMOUNT",
      sortOrder: "ASC",
      startDate: {
        value: values.START_DATE || null,
      },
      endDate: {
        value: values.END_DATE || null,
      },
    },
    { skip: !groupId || !entityId, refetchOnMountOrArgChange: true }
  );

  const {
    data: insights,
    isLoading: isInsightsLoading,
    isSuccess: isInsightsSuccess,
    isFetching: isInsightsFetching,
  } = useGetInsightsQuery(
    {
      groupId,
      entityId,
      start_date: values.START_DATE
        ? dayjs(values.START_DATE).format(YYYY_MM_DD)
        : "",
      end_date: values.END_DATE
        ? dayjs(values.END_DATE).format(YYYY_MM_DD)
        : "",
      limit: 5,
    },
    { skip: !groupId || !entityId, refetchOnMountOrArgChange: true }
  );

  const { categories = [], merchants = [] } = insights || {};

  const [pushToChat] = usePushToChatMutation();
  const { successToast, alertToast } = useToast();

  const columnHelper = createColumnHelper<Transactions>();

  const { channel_url } = transactionData || {};

  const onRowClick = (
    transactionId: string,
    e: Event | React.MouseEvent<HTMLTableRowElement, globalThis.MouseEvent>
  ) => {
    e.stopPropagation();
    dispatch(openSlider(transactionId));
  };

  const sendToChat = async ({
    transactionIds,
  }: {
    transactionIds: string[];
  }) => {
    if (channel_url) {
      dispatch(setToOpenChatId(channel_url));
      try {
        const payload = {
          channel_url: channel_url,
          custom_type: TRANSACTIONS,
          transaction_ids: transactionIds.join(","),
        };
        await pushToChat({ groupId, payload, entityId }).unwrap();
        successToast({ message: "Transaction sent to chat" });
      } catch (error) {
        alertToast({ message: "Failed to send to chat" });
      }
      dispatch(openFloatingChat());
    }
  };

  const columns = [
    columnHelper.accessor("transaction.date", {
      id: "DATE",
      size: 13,
      header: () => <span className="px-1">Date</span>,
      cell: (info) => (
        <div className="t-text-subtext t-text-text-30 t-px-1">
          {dayjs(info.getValue()).format(DD_MMM_YYYY)}
        </div>
      ),
    }),

    columnHelper.accessor("transaction.from", {
      id: "logo",
      size: 5,
      header: () => (
        <div className="t-flex t-justify-center t-w-full">Source</div>
      ),
      cell: (info) => {
        const transaction = info.row.original.transaction;

        return <TransactionColumn.Source transaction={transaction} />;
      },
    }),

    columnHelper.accessor("transaction.merchant", {
      id: "merchant",
      size: 23,
      header: "Vendor",
      cell: (info) => {
        return <TransactionColumn.Merchant info={info} />;
      },
    }),

    columnHelper.accessor("transaction.category", {
      id: "category",
      size: 28,
      header: "Category",
      cell: (info) => {
        return <TransactionColumn.Category info={info} />;
      },
    }),

    columnHelper.accessor("transaction.amount", {
      id: "AMOUNT",
      size: 6,
      header: () => (
        <span className="t-flex t-justify-end t-ml-auto">Amount</span>
      ),
      cell: (info) => {
        const amount = info.getValue();
        const {
          transaction: { is_credit_card },
        } = info.row.original || {};

        return (
          <TransactionColumn.Amount
            amount={amount}
            isCreditCard={is_credit_card}
          />
        );
      },
    }),

    columnHelper.accessor("invoices", {
      id: "invoice",
      size: 3,
      header: "Invoice",
      cell: TransactionColumn.Invoice,
    }),

    columnHelper.accessor("transaction", {
      id: "threedots",
      size: 5,
      header: "",
      cell: (info) => (
        <TransactionColumn.Actions
          info={info}
          onRowClick={onRowClick}
          sendToChat={(transactionIds) =>
            sendToChat({ transactionIds: [transactionIds] })
          }
        />
      ),
    }),
  ];

  const data = useMemo(() => {
    return transactionData?.transactions.slice(0, 5) || [];
  }, [transactionData?.transactions]);

  const top10Spends = useMemo(() => {
    return transactionData?.transactions.slice(0, 10) || [];
  }, [transactionData?.transactions]);

  const table = useReactTable({
    data: data || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      size: 10,
      minSize: 1,
      maxSize: 100,
    },
  });

  const transactionsExpandedTable = useReactTable({
    data: top10Spends || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      size: 10,
      minSize: 1,
      maxSize: 100,
    },
  });

  const { link } = useConstructInternalLink();

  return (
    <DashboardContainer className="t-relative t-gap-6">
      <DashboardContainer.Header className="t-sticky t-top-0 t-z-header">
        <div className="t-bg-surface t-flex t-flex-col t-gap-6">
          <p className="t-m-0 t-text-subtitle t-text-text-100">Top Insights</p>
          <div className="t-flex t-gap-2">
            <Filter.Root
              defaultValue="DATE"
              title="Filter"
              capsule={
                <>
                  {values.START_DATE && (
                    <Chip
                      onClose={() => {
                        updateFilter(
                          "START_DATE",
                          dayjs(defaultDateRange.startDate).format(DD_MMM_YYYY)
                        );
                        updateFilter(
                          "END_DATE",
                          dayjs(defaultDateRange.endDate).format(DD_MMM_YYYY)
                        );
                      }}
                      isActive
                      isRemovable={false}
                      filterType="DATE"
                    >
                      From: {formatDate(values.START_DATE)} To:{" "}
                      {formatDate(values.END_DATE)}
                    </Chip>
                  )}
                </>
              }
            >
              <Filter.Portal>
                <Filter.List>
                  <Filter.ListItem value="DATE">Date</Filter.ListItem>
                </Filter.List>

                <Filter.Body value="DATE" block>
                  <DateFilter values={values} updateFilter={updateFilter} />
                </Filter.Body>
              </Filter.Portal>
            </Filter.Root>
          </div>
        </div>
      </DashboardContainer.Header>
      <DashboardContainer.Content>
        <div className="t-grid t-grid-cols-2 t-gap-5">
          <TopFiveVendors
            startDate={values.START_DATE}
            endDate={values.END_DATE}
            isInsightsFetching={isInsightsFetching}
            isInsightsLoading={isInsightsLoading}
            top_five_merchants={merchants}
            isInsightsSuccess={isInsightsSuccess}
          />

          <TopFiveCategories
            startDate={values.START_DATE}
            endDate={values.END_DATE}
            top_five_categories={categories}
            isInsightsSuccess={isInsightsSuccess}
            isInsightsLoading={isInsightsLoading}
            isInsightsFetching={isInsightsFetching}
          />

          <div className="t-col-span-2">
            <Card
              title={
                <div className="t-flex t-justify-between">
                  <p className="t-m-0">Top Spends</p>
                  <Modal.Root>
                    <Modal.Trigger asChild>
                      <Button
                        customType="ghost_icon"
                        size="small"
                        disabled={transactionData?.transactions.length === 0}
                      >
                        <Expand />
                      </Button>
                    </Modal.Trigger>
                    <Modal.Content size="xxxl">
                      <Modal.Header>
                        <Modal.Title>Top Spends</Modal.Title>
                        <Modal.Close />
                      </Modal.Header>
                      <Modal.Body>
                        <Async.Root
                          {...{
                            isLoading: isLoading,
                            isEmpty: transactionData?.transactions.length === 0,
                            isSuccess: isSuccess,
                          }}
                        >
                          <Async.Empty>
                            <div className="t-flex t-flex-col t-justify-center t-items-center t-h-full t-gap-2 t-text-text-30">
                              <EmptyTopSpends />
                              <p className="t-m-0 t-text-subtext">
                                No spends found
                              </p>
                              <p className="t-m-0 t-text-caption">
                                Add transactions to see top spends
                              </p>
                            </div>
                          </Async.Empty>
                          <Async.Success>
                            <TransactionsPreviewTable
                              table={transactionsExpandedTable}
                              isFetching={isFetching}
                            />
                          </Async.Success>
                          <TransactionSlider />
                        </Async.Root>
                      </Modal.Body>
                      <Modal.FooterButtonGroup>
                        <Modal.RawClose asChild>
                          <Button>Close</Button>
                        </Modal.RawClose>
                        <Link to={link("/books/transactions")}>
                          <Button customType="primary">View all</Button>
                        </Link>
                      </Modal.FooterButtonGroup>
                    </Modal.Content>
                  </Modal.Root>
                </div>
              }
            >
              <Async.Root
                {...{
                  isLoading: isLoading,
                  isEmpty: transactionData?.transactions.length === 0,
                  isSuccess: isSuccess,
                }}
              >
                <Async.Empty>
                  <EmptyMetrics
                    illustration={<EmptyTopSpends />}
                    title="No spends found"
                    subtitle="Add transactions to see top spends"
                  />
                </Async.Empty>
                <Async.Success>
                  <TransactionsPreviewTable
                    table={table}
                    isFetching={isFetching}
                    onRowClick={onRowClick}
                  />
                </Async.Success>
                <TransactionSlider />
              </Async.Root>
            </Card>
          </div>
        </div>
      </DashboardContainer.Content>
    </DashboardContainer>
  );
};
