import { Children, ReactNode } from "react";
import cx from "classnames";
import { ConditionalLink } from "components/conditionalLink";
import Dropdown from "../Dropdown/Dropdown";
import { OptionDropdown } from "components/icons/optionDropdown";

type BreadcrumbRootProps = {
  children: ReactNode;
  itemsToDisplay?: number;
};

type ItemProps =
  | {
      as?: "link";
      link?: string;
      onClick?: never;
    }
  | {
      as?: "button";
      onClick: () => void;
      link?: never;
    };

/**
 * Root component for the Breadcrumb navigation.
 *
 * @component
 * @param {BreadcrumbRootProps} props - Props for the Breadcrumb.Root component.
 * @returns {JSX.Element} The rendered breadcrumb navigation.
 *
 * @example
 * ```tsx
 * <Breadcrumb.Root itemsToDisplay={10}>
 *   <Breadcrumb.Item as="link" link="/home">Home</Breadcrumb.Item>
 *  <Breadcrumb.Item as="button" onClick={() => alert('Clicked')}>Action</Breadcrumb.Item>
 * </Breadcrumb.Root>
 * ```
 */

const Root = ({ children, itemsToDisplay = 5 }: BreadcrumbRootProps) => {
  const childrenArray = Children.toArray(children);
  const totalChildren = childrenArray.length;
  const hasOverflow = totalChildren > itemsToDisplay;

  return (
    <nav aria-label="breadcrumb" className="t-flex t-gap-2">
      {childrenArray
        .slice(0, hasOverflow ? 1 : itemsToDisplay)
        .map((child, index) => (
          <div key={index} className="t-flex items-center t-gap-2">
            <div className="t-text-body-sm">{child}</div>
            {index < itemsToDisplay - 1 && index < totalChildren - 1 && (
              <span className="t-text-neutral-30 t-text-[9px] t-leading-5">
                /
              </span>
            )}
          </div>
        ))}

      {hasOverflow && (
        <>
          <Dropdown.Root>
            <Dropdown.Trigger asChild>
              <button className="all:unset t-text-text-60 t-text-[9px] t-leading-5 t-rotate-90 data-state-open:t-text-purple hover:t-text-purple">
                <OptionDropdown color="currentColor" />
              </button>
            </Dropdown.Trigger>
            <Dropdown.Portal>
              <Dropdown.Content align="start" sideOffset={4}>
                {childrenArray.slice(1, -1).map((child, index) => (
                  <Dropdown.Item key={index}>{child}</Dropdown.Item>
                ))}
              </Dropdown.Content>
            </Dropdown.Portal>
          </Dropdown.Root>
          <span className="t-text-neutral-30 t-text-[9px] t-leading-5">/</span>
          <div className="t-text-body-sm">
            {childrenArray[totalChildren - 1]}
          </div>
        </>
      )}
    </nav>
  );
};

/**
 * Item component for an individual breadcrumb item, which can be either a link or a button.
 *
 * @component
 * @param {ItemProps} props - Props for the Breadcrumb.Item component.
 * @returns {JSX.Element} The rendered breadcrumb item.
 *
 */

const Item = ({
  children,
  link,
  as = "link",
  onClick,
  block = true,
}: ItemProps & { children: ReactNode; block?: boolean }) => {
  return as === "link" ? (
    <ConditionalLink
      to={link}
      className={cx({
        "t-w-full": block,
      })}
    >
      <div
        className={cx("t-text-text-60 ", {
          "hover:t-text-purple": Boolean(link),
          "t-w-full": block,
        })}
      >
        {children}
      </div>
    </ConditionalLink>
  ) : (
    <button
      onClick={onClick}
      className={cx("all:unset t-text-text-60 hover:t-text-purple", {
        "t-w-full": block,
      })}
    >
      {children}
    </button>
  );
};

const Breadcrumb = {
  Root,
  Item,
};
export default Breadcrumb;
