import { AllCompliances } from "./../../types/Models/calendar";
import { CALENDAR_SESSION_ID } from "constants/session";
import { stringify } from "qs";
import { CALENDAR_TYPES } from "types/contants/calendarTypes";
import {
  Calendar,
  CalendarReminder,
  ComplianceDetail,
  ComplianceOverview,
} from "types/Models/calendar";
import { FileObject } from "types/Models/fileObject";
import { Quote } from "types/Models/quote";
import { getSessionId, setOrGetRandomSessionId } from "utils/sessionId";
import { emptyApi } from "./emptyApi";

type PermissionParams = {
  session_id?: string;
  group_id?: string;
};

const getDeadlineEndPoint = (isCrm: boolean) =>
  isCrm ? "user-deadlines-crm" : "user-deadlines";
const getQuestionEndPoint = (isCrm: boolean) =>
  isCrm ? "question-answers-crm" : "question-answers";

export const calendarApis = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getCalender: build.query<
      Calendar,
      {
        taxPeriod: CALENDAR_TYPES;
        retake?: boolean;
        groupId?: string;
        calendarYear: string;
        entityId?: string | null;
      }
    >({
      query: ({ taxPeriod, retake, groupId, calendarYear, entityId }) => {
        let permission: PermissionParams = {
          session_id: setOrGetRandomSessionId(CALENDAR_SESSION_ID),
        };

        if (groupId) {
          permission = { group_id: groupId };
        }

        const query = stringify(
          {
            ...permission,
            tax_period: taxPeriod,
            retake: retake,
            season: calendarYear,
            entity_id: entityId || null,
          },
          { addQueryPrefix: true, skipNulls: true }
        );

        return {
          url: `/api/inkle/inklecalendar/${getDeadlineEndPoint(
            Boolean(groupId)
          )}/${query}`,
        };
      },

      providesTags: (_result, _error, { retake }) =>
        !retake ? [{ type: "Calender", id: "ITEM" }] : [],
      keepUnusedDataFor: 0,
    }),

    applyCoupon: build.mutation<
      Calendar,
      { coupon: string; calendarYear: string; entityId?: string | null }
    >({
      query: ({ coupon, calendarYear, entityId }) => {
        const sessionId = getSessionId(CALENDAR_SESSION_ID);
        const query = stringify(
          {
            session_id: sessionId,
            season: calendarYear,
            entity_id: entityId || null,
          },
          { addQueryPrefix: true, skipNulls: true }
        );

        return {
          url: `/api/inkle/inklecalendar/coupon/${query}`,
          method: "put",
          body: {
            coupon: coupon,
          },
        };
      },

      invalidatesTags: (result) =>
        result ? [{ type: "Calender", id: "ITEM" }] : [],
    }),

    calendarPdf: build.query<
      string,
      { url: string; calendarYear: string; entityId?: string | null }
    >({
      query: ({ calendarYear, entityId }) => {
        const sessionId = setOrGetRandomSessionId(CALENDAR_SESSION_ID);
        return {
          url: `/api/inkle/inklecalendar/generate_quote/`,
          params: {
            session_id: sessionId,
            season: calendarYear,
            entity_id: entityId || null,
          },
        };
      },
    }),

    sendPdfToEmail: build.query<
      { encoded_pdf: string },
      { email: string; calendarYear: string; entityId?: string | null }
    >({
      query: ({ email, calendarYear, entityId }) => {
        const sessionId = setOrGetRandomSessionId(CALENDAR_SESSION_ID);
        return {
          url: `/api/inkle/inklecalendar/user-deadlines/`,
          method: "PATCH",
          params: {
            session_id: sessionId,
            season: calendarYear,
            entity_id: entityId || null,
          },
          body: {
            email,
          },
        };
      },
    }),

    calendarDownloadPdf: build.query<
      { encoded_pdf: string },
      { calendarYear: string; entityId?: string | null }
    >({
      query: ({ calendarYear, entityId }) => {
        const sessionId = setOrGetRandomSessionId(CALENDAR_SESSION_ID);
        return {
          url: `/api/inkle/inklecalendar/download-calendar/`,
          method: "GET",
          params: {
            session_id: sessionId,
            season: calendarYear,
            entity_id: entityId || null,
          },
        };
      },
    }),

    updateQuestion: build.mutation<
      Calendar,
      {
        questionId: string;
        answerId: string;
        groupId?: string;
        calendarYear: string;
        entityId?: string | null;
      }
    >({
      query: ({ questionId, answerId, groupId, calendarYear, entityId }) => {
        let permission: PermissionParams & { season: string } = {
          session_id: setOrGetRandomSessionId(CALENDAR_SESSION_ID),
          season: calendarYear,
        };

        if (groupId) {
          permission = {
            group_id: groupId,
            season: calendarYear,
          };
        }

        const query = stringify(permission, {
          addQueryPrefix: true,
          skipNulls: true,
        });

        return {
          url: `/api/inkle/inklecalendar/${getQuestionEndPoint(
            Boolean(groupId)
          )}/${query}&entity_id=${entityId || null}`,
          method: "put",
          body: {
            question_id: questionId,
            answer_id: answerId,
          },
        };
      },
      invalidatesTags: (result) =>
        result ? [{ type: "Calender", id: "ITEM" }] : [],
    }),

    sendQuote: build.mutation<
      { groupId: string; quote_file: FileObject },
      {
        groupId: string;
        file: File;
        recipients: string;
        entityId: string | null;
      }
    >({
      query: ({ groupId, file, recipients, entityId }) => {
        const formData = new FormData();

        formData.append("quote_file", file);
        formData.append("recipients", recipients);

        return {
          url: `/api/inkle/inklecalendar/group/${groupId}/quotations/?entity_id=${
            entityId || null
          }`,
          method: "post",
          body: formData,
        };
      },
      invalidatesTags: (result) =>
        result ? [{ type: "Quotes", id: "LIST" }] : [],
    }),

    generateQuoteSheetLink: build.query<
      { quote_link: string },
      { groupId: string; calendarYear: string; entityId?: string | null }
    >({
      query: ({ groupId, calendarYear, entityId }) =>
        `/api/inkle/inklecalendar/group/${groupId}/generate_quote_link/?season=${calendarYear}&entity_id=${entityId}`,
    }),

    getAllGroupQuote: build.query<
      { company_group_quotes: Quote[] },
      { groupId: string; entityId?: string | null }
    >({
      query: ({ groupId, entityId }) =>
        `api/inkle/inklecalendar/group/${groupId}/quotations/?entity_id=${entityId}`,
      providesTags: (result) =>
        result ? [{ type: "Quotes", id: "LIST" }] : [],
    }),

    getCalendarReminder: build.query<
      CalendarReminder,
      { entityId?: string | null }
    >({
      query: ({ entityId }) => {
        const sessionId = setOrGetRandomSessionId(CALENDAR_SESSION_ID);

        return `/api/inkle/inklecalendar/user_instance/?session_id=${sessionId}&entity_id=${
          entityId || null
        }`;
      },
      providesTags: (result) => (result ? [{ type: "CalendarReminder" }] : []),
    }),

    addToGoogleCaledar: build.mutation<
      null,
      { email: string; calendarYear: string; entityId?: string | null }
    >({
      query: ({ email, calendarYear, entityId }) => {
        const sessionId = setOrGetRandomSessionId(CALENDAR_SESSION_ID);

        return {
          url: `/api/inkle/inklecalendar/add_to_calendar/?session_id=${sessionId}&season=${calendarYear}&entity_id=${
            entityId || null
          }`,
          method: "POST",
          body: {
            email,
          },
        };
      },
    }),

    updateCalendarReminder: build.mutation<
      {
        session_id: string;
        selected_tax_period: string;
      },
      Partial<
        Pick<
          CalendarReminder,
          | "email"
          | "mobile"
          | "send_email_reminders"
          | "send_wa_reminders"
          | "reminder_periods"
          | "entity_id"
        >
      >
    >({
      query: ({
        email,
        mobile,
        send_email_reminders,
        send_wa_reminders,
        reminder_periods,
        entity_id,
      }) => {
        const sessionId = setOrGetRandomSessionId(CALENDAR_SESSION_ID);
        return {
          url: `/api/inkle/inklecalendar/user_instance/`,
          method: "PATCH",
          params: {
            session_id: sessionId,
            entity_id: entity_id || null,
          },
          body: {
            email,
            send_email_reminders,
            send_wa_reminders,
            mobile,
            reminder_periods,
          },
        };
      },
      invalidatesTags: (result) =>
        result ? [{ type: "CalendarReminder" }] : [],
    }),

    getAllCompliances: build.query<
      AllCompliances,
      { entityId: string; startDate?: string; endDate?: string }
    >({
      query: ({ entityId, startDate, endDate }) => ({
        url: `/api/inkle/alarm/entity/${entityId}/`,
        params: {
          from_date: startDate,
          end_date: endDate,
        },
      }),
      providesTags: ["ALL_COMPLIANCES"],
    }),

    getComplianceOverview: build.query<
      ComplianceOverview,
      { entityId: string }
    >({
      query: ({ entityId }) => {
        return `/api/inkle/alarm/overview/entity/${entityId}/`;
      },
    }),
    updateDeadlineFiling: build.mutation<ComplianceDetail, { alarmId: string }>(
      {
        query: ({ alarmId }) => {
          return {
            url: `/api/inkle/alarm/file_externally/${alarmId}/`,
            method: "PATCH",
          };
        },
        invalidatesTags: ["ALL_COMPLIANCES", "TASK_ALARM"],
      }
    ),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetCalenderQuery,
  useGetCalenderQuery,
  useUpdateQuestionMutation,
  useGetCalendarReminderQuery,
  useUpdateCalendarReminderMutation,
  useLazyCalendarPdfQuery,
  useCalendarPdfQuery,
  useLazySendPdfToEmailQuery,
  useSendQuoteMutation,
  useLazyGenerateQuoteSheetLinkQuery,
  useGetAllGroupQuoteQuery,
  useApplyCouponMutation,
  useLazyCalendarDownloadPdfQuery,
  useAddToGoogleCaledarMutation,
  useGetAllCompliancesQuery,
  useLazyGetAllCompliancesQuery,
  useGetComplianceOverviewQuery,
  useUpdateDeadlineFilingMutation,
} = calendarApis;
