export const ChatTeardropDots = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_5194_6907)">
      <path
        d="M8.25 13.5H3C2.86739 13.5 2.74021 13.4473 2.64645 13.3536C2.55268 13.2598 2.5 13.1326 2.5 13V7.75C2.5 6.22501 3.1058 4.76247 4.18414 3.68414C5.26247 2.6058 6.72501 2 8.25 2C9.0051 2 9.75281 2.14873 10.4504 2.43769C11.1481 2.72666 11.7819 3.1502 12.3159 3.68414C12.8498 4.21807 13.2733 4.85195 13.5623 5.54957C13.8513 6.24719 14 6.9949 14 7.75C14 8.5051 13.8513 9.25281 13.5623 9.95043C13.2733 10.6481 12.8498 11.2819 12.3159 11.8159C11.7819 12.3498 11.1481 12.7733 10.4504 13.0623C9.75281 13.3513 9.0051 13.5 8.25 13.5Z"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.2 8C8.2 7.97239 8.22239 7.95 8.25 7.95C8.27761 7.95 8.3 7.97239 8.3 8C8.3 8.02761 8.27761 8.05 8.25 8.05C8.22239 8.05 8.2 8.02761 8.2 8Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.6"
      />
      <path
        d="M5.5 8.75C5.91421 8.75 6.25 8.41421 6.25 8C6.25 7.58579 5.91421 7.25 5.5 7.25C5.08579 7.25 4.75 7.58579 4.75 8C4.75 8.41421 5.08579 8.75 5.5 8.75Z"
        fill="currentColor"
      />
      <path
        d="M11 8.75C11.4142 8.75 11.75 8.41421 11.75 8C11.75 7.58579 11.4142 7.25 11 7.25C10.5858 7.25 10.25 7.58579 10.25 8C10.25 8.41421 10.5858 8.75 11 8.75Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_5194_6907">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
