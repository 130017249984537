import { PaperPlaneRightSmall } from "components/icons/Chat/PaperPlaneRightSmall";
import { Formik, Form } from "formik";
import { AnimatePresence, motion } from "framer-motion";
import {
  ReactNode,
  useState,
  useCallback,
  useContext,
  createContext,
  useMemo,
  forwardRef,
  useEffect,
  useRef,
} from "react";
import { LoadingIndicator } from "react-file-utils";
import {
  DefaultStreamChatGenerics,
  MessageInputProps,
  ChatAutoCompleteProps,
  useMessageInputContext,
  useComponentContext,
  AutoCompleteTextarea,
  MessageInput,
  useChatContext,
  Channel,
  MessageToSend,
} from "stream-chat-react";
import { CustomTrigger } from "stream-chat-react/dist/types/types";
import * as Popover from "@radix-ui/react-popover";
import {
  Note,
  NoteResponse,
  useCreateNotesMutation,
  useGetNoteQuery,
} from "store/apis/notes";
import classNames from "classnames";
import { Avatar } from "components/DesignSystem/AvatarGroup/Avatar";
import dayjs from "dayjs";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { Button } from "components/DesignSystem/Button/Button";
import { Loader } from "components/DesignSystem/Loader/Loader";
import { formatDate } from "utils/formatDate";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import { useAppSelector } from "hooks/useAppSelector";
import { stopPropagation } from "utils/stopPropagation";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { useToast } from "hooks/useToast";
import { BackendError } from "types/utils/error";
import { useAnalytics } from "hooks/useAnalytics";
import { ADD_COMMENT_CLICKED, COMMENT_ADDED } from "constants/analyticsEvents";

const ShowReplyContext = createContext({
  show: null as string | null,
  setShow: (comment: NoteResponse) => {},
});

const CommentThreadContext = createContext("");
const MessageSendingContext = createContext(false);

export const ActiveComment = ({
  children,
  activeComment,
  setActiveComment,
}: {
  children: ReactNode;
  activeComment: string | null;
  setActiveComment: (comment: NoteResponse) => void;
}) => {
  const { trackEvent } = useAnalytics();

  const setActiveCommentWithAnalytics = (comment: NoteResponse) => {
    setActiveComment(comment);
    trackEvent(ADD_COMMENT_CLICKED, {
      type: comment.category ? "CATEGORY" : "TRANSACTION",
      id: (comment.transaction?.uuid ||
        comment.category?.uuid ||
        comment.ledger_entry?.uuid)!,
    });
  };

  return (
    <ShowReplyContext.Provider
      value={{ show: activeComment, setShow: setActiveCommentWithAnalytics }}
    >
      {children}
    </ShowReplyContext.Provider>
  );
};

const CommentInput = <
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics,
  V extends CustomTrigger = CustomTrigger
>(
  props: MessageInputProps<StreamChatGenerics, V> & ChatAutoCompleteProps
) => {
  const messageInput = useMessageInputContext<StreamChatGenerics, V>(
    "ChatAutoComplete"
  );

  const updateInnerRef = useCallback(
    (ref: HTMLTextAreaElement | null) => {
      if (messageInput.textareaRef) {
        messageInput.textareaRef.current = ref;
      }
    },
    [messageInput.textareaRef]
  );

  const emojiReplace = async (word: string) => {
    const found = (await messageInput.emojiSearchIndex?.search(word)) || [];
    const emoji = found
      .filter(Boolean)
      .slice(0, 10)
      .find(({ emoticons }: any) => !!emoticons?.includes(word));
    if (!emoji || !("native" in emoji)) return null;
    return emoji.native;
  };

  const {
    AutocompleteSuggestionItem: SuggestionItem,
    AutocompleteSuggestionList: SuggestionList,
  } = useComponentContext<StreamChatGenerics, V>("ChatAutoComplete");

  return (
    <AutoCompleteTextarea
      additionalTextareaProps={{
        ...messageInput.additionalTextareaProps,
        id: "message-textarea",
        autofocus: true,
        "data-popover-trigger": "true",
      }}
      // aria-label={cooldownRemaining ? "Slow Mode ON" : placeholder}
      className="t-w-full t-flex-1 t-resize-none t-border-0 t-outline-none t-bg-surface-lighter-grey"
      closeCommandsList={messageInput.closeCommandsList}
      closeMentionsList={messageInput.closeMentionsList}
      containerClassName="str-chat__textarea str-chat__message-textarea-react-host"
      // disabled={disabled || !!cooldownRemaining}
      disableMenUploadButtontions={messageInput.disableMentions}
      dropdownClassName="str-chat__emojisearch"
      grow={true} // :TODO:
      style={{ fontSize: "14px" }}
      handleSubmit={messageInput.handleSubmit}
      innerRef={updateInnerRef}
      itemClassName="str-chat__emojisearch__item"
      listClassName="str-chat__emojisearch__list"
      loadingComponent={LoadingIndicator}
      maxRows={10} // :TODO:
      minRows={1}
      minChar={0}
      onBlur={props.onBlur}
      onChange={messageInput.handleChange}
      onFocus={props.onFocus}
      onPaste={props.onPaste || messageInput.onPaste}
      placeholder={props.placeholder || "Add a comment"}
      replaceWord={emojiReplace}
      rows={1}
      shouldSubmit={messageInput.shouldSubmit}
      showCommandsList={messageInput.showCommandsList}
      showMentionsList={messageInput.showMentionsList}
      SuggestionItem={SuggestionItem}
      SuggestionList={SuggestionList}
      trigger={messageInput.autocompleteTriggers || {}}
      value={messageInput.text}
    />
  );
};

const CreateCommentInput = <
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics,
  V extends CustomTrigger = CustomTrigger
>(
  props: MessageInputProps<StreamChatGenerics, V> & ChatAutoCompleteProps
) => {
  const messageInput = useMessageInputContext<StreamChatGenerics, V>(
    "ChatAutoComplete"
  );
  const sendingMessage = useContext(MessageSendingContext);

  return (
    <>
      <div className="t-col-start-[1] t-w-full t-row-start-[2] t-resize-none t-rounded t-border t-border-solid t-border-neutral-10 t-bg-surface-lighter-grey t-p-3 t-text-body t-text-text-100 t-transition-all focus-within:t-border-purple focus-within:t-bg-surface-transparent focus-within:t-outline-none">
        <CommentInput {...props} />
      </div>
      <div className="t-flex t-gap-2 t-items-center t-justify-end">
        <Popover.Close asChild disabled={sendingMessage}>
          <Button size="small">Cancel</Button>
        </Popover.Close>
        <Button
          onClick={() => messageInput.handleSubmit()}
          customType="primary"
          size="small"
          isLoading={sendingMessage}
          disabled={sendingMessage}
        >
          Comment
        </Button>
      </div>
    </>
  );
};

const ReplyInput = <
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics,
  V extends CustomTrigger = CustomTrigger
>(
  props: MessageInputProps<StreamChatGenerics, V> & ChatAutoCompleteProps
) => {
  const reply = useContext(ShowReplyContext);
  const threadId = useContext(CommentThreadContext);
  const messageSending = useContext(MessageSendingContext);

  return (
    <AnimatePresence>
      {reply.show === threadId && (
        <motion.div className="t-col-start-[1] t-w-full t-row-start-[2] t-resize-none t-rounded t-border t-border-solid t-border-neutral-10 t-bg-surface-lighter-grey t-pl-3 t-text-body t-text-text-100 t-transition-all focus-within:t-border-purple t focus-within:t-outline-none t-flex t-items-center t-overflow-hidden t-justify-stretch">
          <CommentInput {...props} />
          <Button
            customType="ghost_icon"
            isLoading={messageSending}
            size="small"
          >
            <span className="t-text-neutral-30">
              <PaperPlaneRightSmall />
            </span>
          </Button>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export const CommentThread = forwardRef<
  HTMLDivElement,
  {
    notes: NoteResponse;
    type: "TOGETHER" | "SPACED";
  }
>(({ notes, type = "TOGETHER" }, ref) => {
  const { client } = useChatContext();
  const { setShow } = useContext(ShowReplyContext);
  const entityId = useCurrentEntityId();
  const { entities } = useCurrentGroupContext();
  const currentEntity = entities.find((e) => e.uuid === entityId);

  const channel = useMemo(() => {
    return client.channel("messaging", currentEntity?.bookkeeping_channel);
  }, [currentEntity?.bookkeeping_channel]);

  const reply = useContext(ShowReplyContext);
  const id =
    notes.transaction?.uuid ||
    notes.category?.uuid ||
    notes.ledger_entry?.uuid ||
    notes.comments[0].uuid;

  const commentType = notes.comments[0]?.comment_type;

  const [createNote, { isLoading }] = useCreateNotesMutation();

  const { alertToast } = useToast();

  const onNewNoteSubmit = async (
    message: MessageToSend<DefaultStreamChatGenerics>
  ) => {
    try {
      if (notes.transaction || notes.category) {
        await createNote({
          entityId,
          parentObjId: (notes.transaction?.uuid || notes.category?.uuid)!,
          payload: {
            description: message.text,
            mentioned_stream_user_ids: message.mentioned_users?.map(
              (u) => u.id
            ),
            comment_type: commentType,
          },
        });
      }
    } catch (error: any) {
      alertToast(
        { message: (error as BackendError)?.data?.error?.message },
        error as {}
      );
    }
  };

  return (
    <CommentThreadContext.Provider value={id}>
      <motion.button
        layout
        className={classNames(
          "t-flex t-flex-col t-gap-3 t-group/comments all:unset",
          {
            "t-p-3 t-bg-surface-lighter-grey t-border t-border-solid t-border-neutral-0 t-rounded-lg":
              type === "TOGETHER",
            "t-border-purple-30 t-bg-surface-transparent t-outline-none focus-within:t-shadow-light-30":
              reply.show === id,
          }
        )}
        onClick={() => setShow(notes)}
      >
        {notes.transaction && (
          <motion.p
            layout
            className="t-m-0 t-flex t-items-center t-gap-1 t-text-body-sm t-text-text-30"
          >
            <span>{formatDate(notes.transaction.date)}</span>
            <span>•</span>
            {notes.transaction.merchant && (
              <>
                <span>{notes.transaction.merchant}</span>
                <span>•</span>
              </>
            )}
            <AmountSuperScript amount={Number(notes.transaction.amount)} />
          </motion.p>
        )}
        {notes.category && (
          <motion.p
            layout
            className="t-m-0 t-flex t-items-center t-gap-1 t-text-body-sm t-text-text-30"
          >
            <span>{notes.category.identifier}</span>
            <span>•</span>
            <span>{notes.category.name}</span>
          </motion.p>
        )}
        {notes.comments.map((note) => (
          <div
            key={note.uuid}
            className={classNames("t-flex t-flex-col", {
              "t-gap-5": type === "TOGETHER",
              "t-gap-2": type === "SPACED",
            })}
          >
            <div
              className={classNames({
                "t-p-3 t-bg-surface-lighter-grey t-border t-border-solid t-border-neutral-0 t-rounded-lg":
                  type === "SPACED",
              })}
            >
              <CommentBox note={note} />
            </div>
          </div>
        ))}
        {reply.show === id && (
          <div ref={ref} onClick={stopPropagation}>
            <MessageSendingContext.Provider value={isLoading}>
              <Channel
                LoadingIndicator={() => <></>}
                channel={channel}
                Input={ReplyInput}
              >
                <MessageInput overrideSubmitHandler={onNewNoteSubmit} />
              </Channel>
            </MessageSendingContext.Provider>
          </div>
        )}
      </motion.button>
    </CommentThreadContext.Provider>
  );
});

export const CommentBox = ({ note }: { note: Note }) => {
  return (
    <motion.div layout className="t-flex t-gap-2">
      <Avatar
        size="regular"
        src={note.created_by.profile_url}
        alt={note.created_by.name}
      />
      <div className="t-flex t-flex-col t-gap-1.5">
        <div className="t-flex t-flex-col">
          <span className="t-text-subtext">{note.created_by.name}</span>
          <span className="t-text-body-sm t-text-text-30">
            {dayjs(note.created_at).format("MMM DD")} at{" "}
            {dayjs(note.created_at).format("h:mm a")}
          </span>
        </div>
        <p className="t-text-body t-text-text-60 t-mb-0 t-word-break t-hyphens-auto">
          {note.description}
        </p>
      </div>
    </motion.div>
  );
};

const CommentThreadWithData = ({
  transactionId,
}: {
  transactionId: string;
}) => {
  const entityId = useCurrentEntityId();
  const { data, isFetching, isLoading } = useGetNoteQuery(
    {
      entityId,
      parentObjId: transactionId,
    },
    {
      skip: !entityId || !transactionId,
    }
  );

  return (
    <Async.Root
      {...{
        isLoading,
        isEmpty: data?.comments.length === 0,
        isSuccess: Boolean(data),
      }}
    >
      <Async.Empty>
        <></>
      </Async.Empty>
      <Async.Success>
        <div className="t-border-b t-border-0 t-border-solid t-border-neutral-10 t-flex t-gap-2 t-flex-col t-p-3">
          {data && <CommentThread type="SPACED" notes={data} />}
          {isFetching && (
            <div className="t-flex t-justify-center t-py-3">
              <Loader size="small" />
            </div>
          )}
        </div>
      </Async.Success>
    </Async.Root>
  );
};

type Props = {
  commentType: Note["comment_type"];
  contentId: string;
};

export const AddCommentPop = (props: Props) => {
  const [createNote, { isLoading }] = useCreateNotesMutation();
  const entityId = useCurrentEntityId();
  const { client } = useChatContext();
  const { entities } = useCurrentGroupContext();
  const currentEntity = entities.find((e) => e.uuid === entityId);

  const { alertToast } = useToast();

  const channel = useMemo(() => {
    return client.channel("messaging", currentEntity?.bookkeeping_channel);
  }, [currentEntity?.bookkeeping_channel]);

  const onNewNoteSubmit = async (
    message: MessageToSend<DefaultStreamChatGenerics>
  ) => {
    trackEvent(COMMENT_ADDED, {
      type: props.commentType,
      id: props.contentId,
      message: message.text!,
    });

    try {
      await createNote({
        entityId,
        parentObjId: props.contentId,
        payload: {
          description: message.text,
          mentioned_stream_user_ids: message.mentioned_users?.map((u) => u.id),
          comment_type: props.commentType,
        },
      });
    } catch (error: any) {
      alertToast(
        { message: (error as BackendError)?.data?.error?.message },
        new Error(JSON.stringify(error as BackendError))
      );
    }
  };

  const { trackEvent } = useAnalytics();

  useEffect(() => {
    trackEvent(ADD_COMMENT_CLICKED, {
      type: props.commentType,
      id: props.contentId,
    });
  }, [props.commentType, props.contentId, trackEvent]);

  return (
    <Popover.Content
      className="t-z-popover t-w-[390px] t-max-h-[480px] t-overflow-y-auto t-shadow-light-60 t-rounded-lg"
      side="left"
      alignOffset={-10}
      align="start"
      sideOffset={4}
      onClick={stopPropagation}
      onInteractOutside={(e) => {
        // @ts-ignore
        if (e.target?.getAttribute("data-popover-trigger") === "true") {
          e.preventDefault();
        }
      }}
    >
      <div className="t-bg-white">
        <div>
          <CommentThreadWithData transactionId={props.contentId} />
          <Formik initialValues={{ comment: "" }} onSubmit={() => {}}>
            {({ submitForm }) => (
              <div className="t-sticky t-bottom-0 t-bg-white t-p-3">
                <MessageSendingContext.Provider value={isLoading}>
                  <Form className="t-group/comments">
                    <Channel channel={channel} Input={CreateCommentInput}>
                      <div className="t-flex t-gap-3 t-flex-col t-w-full">
                        <MessageInput overrideSubmitHandler={onNewNoteSubmit} />
                      </div>
                    </Channel>
                  </Form>
                </MessageSendingContext.Provider>
              </div>
            )}
          </Formik>
        </div>
      </div>
      <Popover.Arrow asChild width={24} height={12} className="t-text-white">
        <svg
          width="24"
          height="12"
          viewBox="0 0 24 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.5858 10.5858L0 0H24L13.4142 10.5858C12.6332 11.3668 11.3668 11.3668 10.5858 10.5858Z"
            fill="currentColor"
          />
        </svg>
      </Popover.Arrow>
    </Popover.Content>
  );
};
