import { Button } from "components/DesignSystem/Button/Button";
import { Header } from "components/DesignSystem/Header/Header";
import { Search } from "components/DesignSystem/Search/Search";
import React, { useContext, useMemo, useState } from "react";
import AddUserIcon from "../../static/images/UserPlus.svg";
import { Badge } from "components/design/badge";
import { InviteTeammate } from "./Modals/InviteTeammate";
import { debounce } from "utils/debouncing";
import {
  TeamMember,
  useGetTeamManagementMembersQuery,
} from "store/apis/teamSettingCPA";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { MakeAdmin } from "./MakeAdmin";
import authContext from "jwt_context&axios/authContext";
import { useParams } from "react-router-dom";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import { TableUI } from "components/design/TableUI";
import { DashboardLayout } from "components/DashboardLayout";
import { Tag } from "components/DesignSystem/Tag/Tag";

const STATUS_MAP = {
  JOINED: "Joined",
  INVITED: "Invited",
  ARCHIVED: "Archived",
};

const STATUS_COLOR_MAP = {
  JOINED: "green",
  INVITED: "yellow",
  ARCHIVED: "gray",
} as const;

export const TeamManagement = ({ groupDomain }: { groupDomain?: string }) => {
  const { isAnyServiceSuperAdmin } = useRoleBasedView();
  const [showInviteModal, setShowInviteModal] = useState<boolean>(false);
  const [search, setSearch] = useState<string | null>(null);
  const { authtoken } = useContext(authContext);
  const { practicecrmId } = useParams<{ practicecrmId: string }>();

  const { data: members = [], isLoading } = useGetTeamManagementMembersQuery({
    searchTerm: search,
    serviceTeamId: practicecrmId || authtoken.service_team_id,
  });

  const createColumn = createColumnHelper<TeamMember>();

  const columns = useMemo(() => {
    const makeAdminOption = isAnyServiceSuperAdmin
      ? [
          createColumn.display({
            id: "ACTION",
            size: 10,
            cell: (info) => {
              return <MakeAdmin data={info.row.original} />;
            },
          }),
        ]
      : [];

    return [
      createColumn.accessor("name", {
        id: "Name",
        header: () => "Name",
        size: 30,
        cell: (info) => {
          return <div>{info.getValue()}</div>;
        },
      }),
      createColumn.accessor("email", {
        id: "Email",
        header: () => "Email",
        size: 40,
        cell: (info) => {
          return <div>{info.getValue()}</div>;
        },
      }),
      createColumn.accessor("role", {
        id: "Role",
        header: () => "Role",
        size: 20,
        cell: (info) => {
          return <div>{info.getValue()}</div>;
        },
      }),
      createColumn.accessor("status", {
        id: "Status",
        header: () => "Status",
        size: 10,
        cell: (info) => {
          return (
            <span>
              <Tag tagType={STATUS_COLOR_MAP[info.getValue()]}>
                {STATUS_MAP[info.getValue()]}
              </Tag>
            </span>
          );
        },
      }),
      ...makeAdminOption,
    ];
  }, [isAnyServiceSuperAdmin]);

  const data = useMemo(() => members || [], [members]);

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleSearchChange = debounce(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      if (value) {
        setSearch(value);
      } else {
        setSearch(null);
      }
    }
  );
  return (
    <div className="t-w-full t-h-full">
      <DashboardLayout
        header={
          <Header
            className="!t-static"
            v2
            title="Team Management"
            right={
              <div className="t-flex t-gap-3">
                <Search placeholder="Search..." onChange={handleSearchChange} />
                <Button
                  customType="primary"
                  size="small"
                  onClick={() => setShowInviteModal(true)}
                >
                  <img src={AddUserIcon} alt="AddUser" />
                  &nbsp; Invite member
                </Button>
              </div>
            }
          />
        }
      >
        <div className="t-overflow-auto t-relative t-pb-20">
          <TableUI table={table} />
        </div>
        <InviteTeammate
          showInviteModal={showInviteModal}
          setShowInviteModal={setShowInviteModal}
          groupDomain={groupDomain}
        />
      </DashboardLayout>
    </div>
  );
};
