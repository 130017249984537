import * as SelectPrimitive from "@radix-ui/react-select";
import cx from "classnames";
import { ArrowRight } from "components/icons/ArrowRight";
import { CaretDown } from "components/icons/CaretDown";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import check from "static/images/check.svg";

type ContentProps = {
  className?: string;
};

type TriggerProps = {
  appearance?: "bordered" | "borderless";
} & SelectPrimitive.SelectTriggerProps &
  React.RefAttributes<HTMLButtonElement> &
  ContentProps;

type RootProps = SelectPrimitive.SelectProps &
  React.RefAttributes<HTMLDivElement>;

type ContextProps = {
  appearance?: "bordered" | "borderless";
  size?: "small" | "regular" | "large";
  type?: "error";
};

const Context = React.createContext<ContextProps>({});

const Provider = ({
  children,
  ...props
}: ContextProps & SelectPrimitive.SelectProps) => (
  <Context.Provider value={{ ...props }}>{children}</Context.Provider>
);

const useSelectContext = () => {
  const context = React.useContext(Context);
  if (!context) {
    throw new Error("useSelectContext must be used within a SelectProvider");
  }
  return context;
};

const Root = ({
  appearance = "bordered",
  size = "regular",
  type,
  ...props
}: RootProps & ContextProps) => (
  <Provider appearance={appearance} size={size} type={type}>
    <SelectPrimitive.Root {...props}>{props.children}</SelectPrimitive.Root>
  </Provider>
);

const Trigger = ({ className = "", children, ...props }: TriggerProps) => {
  const { appearance, size, type } = useSelectContext();
  return (
    <SelectPrimitive.Trigger
      {...props}
      className={cx(
        "all:unset t-flex t-gap-1 t-group t-select-none t-items-center t-px-2 t-text-body disabled:t-text-neutral-30 disabled:t-pointer-events-none",
        {
          [className]: className,
          "t-border t-border-solid t-border-neutral-10 t-rounded t-flex t-items-center t-bg-surface-lighter-grey t-justify-center focus:t-bg-surface focus:t-border-purple active:t-bg-surface active:t-border-purple disabled:t-bg-neutral-0":
            appearance === "bordered" && !props.disabled,
          "t-border-none": appearance === "borderless",
          "t-border-red-50 t-border": type === "error",
          "t-h-6 !t-min-h-8": size === "small",
          "t-h-7 !t-min-h-10": size === "regular",
          "t-h-8 !t-min-h-12": size === "large",
        }
      )}
    >
      {children}
      <Select.Icon asChild>
        <span
          className={cx(
            "group-data-state-open:-t-rotate-180 t-transform t-transition t-duration-300 t-ease-in-out t-text-neutral t-flex",
            {
              "t-text-neutral-30": props.disabled,
            }
          )}
        >
          <CaretDown size="14" />
        </span>
      </Select.Icon>
    </SelectPrimitive.Trigger>
  );
};

const Item = ({
  className = "",
  ...props
}: SelectPrimitive.SelectItemProps &
  React.RefAttributes<HTMLDivElement> &
  ContentProps) => {
  return (
    <SelectPrimitive.Item
      {...props}
      className={cx(
        "t-group t-w-full t-cursor-pointer t-truncate !t-border-none t-px-6 t-py-2 t-font-medium hover:t-bg-i-surface-light-purple focus-visible:t-outline-none data-[disabled]:t-cursor-not-allowed data-[disabled]:t-text-neutral-30 data-[disabled]:hover:t-bg-surface-transparent data-[highlighted]:t-bg-surface-lighter-grey data-[state='checked']:t-bg-purple-10 data-[state='checked']:hover:t-bg-purple-20 t-relative t-flex t-items-center t-select-none t-text-body",
        {
          [className]: className,
        }
      )}
    >
      <Select.ItemIndicator asChild className="t-absolute t-left-1.5">
        <img src={check} alt="selcted" className="t-h-4 t-w-4" />
      </Select.ItemIndicator>
      <Select.ItemText>{props.children}</Select.ItemText>
    </SelectPrimitive.Item>
  );
};

const ScrollDownButton = ({
  className = "",
  ...props
}: SelectPrimitive.SelectScrollDownButtonProps &
  React.RefAttributes<HTMLDivElement> &
  ContentProps) => {
  return (
    <SelectPrimitive.ScrollDownButton
      {...props}
      className={cx(
        "t-w-full t-cursor-pointer !t-border-none hover:t-bg-i-surface-light-purple focus-visible:t-outline-none t-bg-neutral-0 t-flex t-items-center t-justify-center t-h-7",
        {
          [className]: className,
        }
      )}
    >
      <div className="t-rotate-90 t-text-text-black">
        <ArrowRight stroke="2" color="currentColor" />
      </div>
    </SelectPrimitive.ScrollDownButton>
  );
};

const ScrollUpButton = ({
  className = "",
  ...props
}: SelectPrimitive.SelectScrollUpButtonProps &
  React.RefAttributes<HTMLDivElement> &
  ContentProps) => {
  return (
    <SelectPrimitive.ScrollUpButton
      {...props}
      className={cx(
        "t-w-full t-cursor-pointer !t-border-none hover:t-bg-i-surface-light-purple focus-visible:t-outline-none t-bg-neutral-10 t-flex t-items-center t-justify-center t-h-7",
        {
          [className]: className,
        }
      )}
    >
      <div className="-t-rotate-90 t-text-text-black">
        <ArrowRight stroke="2" color="currentColor" />
      </div>
    </SelectPrimitive.ScrollUpButton>
  );
};

const Portal = (props: SelectPrimitive.SelectPortalProps) => (
  <AnimatePresence>
    <SelectPrimitive.Portal {...props} />
  </AnimatePresence>
);

const Content = ({
  position = "popper",
  sideOffset = 2,
  className = "",
  container,
  children,
  ...props
}: SelectPrimitive.SelectContentProps &
  React.RefAttributes<HTMLDivElement> &
  ContentProps & {
    container?: SelectPrimitive.SelectPortalProps["container"];
  }) => {
  return (
    <Portal container={container}>
      <SelectPrimitive.Content
        {...props}
        position={position}
        sideOffset={sideOffset}
        asChild
        className={cx(
          "t-border primary-border t-z-dropdown t-rounded-md t-bg-surface t-text-subtext-sm t-drop-shadow-i-dropdown t-overflow-hidden",
          {
            [className]: className,
          }
        )}
      >
        <motion.div initial={{ y: -10 }} animate={{ y: 0 }} exit={{ y: -10 }}>
          <ScrollUpButton />
          <SelectPrimitive.Viewport>{children}</SelectPrimitive.Viewport>
          <ScrollDownButton />
        </motion.div>
      </SelectPrimitive.Content>
    </Portal>
  );
};

const Select = {
  ...SelectPrimitive,
  Root,
  Item,
  Content,
  Portal,
  ScrollDownButton,
  ScrollUpButton,
  Trigger,
};

export default Select;
