import classNames from "classnames";
import ToolTip from "components/design/toolTip";
import { Button } from "components/DesignSystem/Button/Button";
import { Chip } from "components/DesignSystem/Chips/Chips";
import {
  Filter,
  MultiSelectFilter,
} from "components/DesignSystem/Filter/Filter";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import Radio from "components/DesignSystem/RadioGroup/RadioGroup";
import { Search } from "components/DesignSystem/Search/Search";
import { DateFilter } from "components/Filters/DateFilter";
import { DownloadIcon } from "components/icons/Download";
import {
  NO_SERVICE_TEAM_NAME,
  NO_SERVICE_TEAM_UUID,
} from "constants/settingServiceProvider";
import { COMPANY_GROUP_TAG } from "constants/tagCategory";
import { NO_TAG, NO_TAG_KEY } from "constants/tagColors";
import { ETD_FILTER_OPTIONS } from "constants/task";
import { CRM_SUBSCRIPTION_TYPES } from "dictionaries";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { ChangeEvent, MouseEvent, useContext, useState } from "react";
import { useGetTagsForCategoryQuery } from "store/apis/billing";
import { useGetAllInkleCPATeamsQuery } from "store/apis/internalTeamSetting";
import { useGetServiceTeamQuery } from "store/apis/serviceTeam";
import { formatDate } from "utils/formatDate";
import { sortOptions } from "../../../constants/foreignCATask";
import authContext from "../../../jwt_context&axios/authContext";
import SortDescendingIcon from "../../../static/images/SortDescending.svg";
import { TaskTemplateResponse } from "types/Models/taskTemplate";
import { Tag } from "types/Models/FCA";
import BroadcastIcon from "components/icons/Broadcast";
import { BroadcastMessage } from "components/BroadcastMessage/BroadcastMessage";
import Modal from "components/DesignSystem/Modal/Modal";
import { useModal } from "hooks/useModal";
import { parse } from "qs";
import { useLocation } from "react-router-dom";

type ForeignCATaskFilterType = {
  searchedTerm: string | null;
  selectedTag: string | null;
  selectedCompanyTag: string | null;
  tags: Tag[];
  selectedStateFilter: string | null;
  taskStates: { name: string; uuid: string }[];
  templates: TaskTemplateResponse[];
  selectedTemplateFilter: string | null;
  selectedAssignee: string | null;
  assignees: {
    name: string;
    uuid: string;
  }[];
  selectedSortOption: {
    id: number;
    title: string;
    value: string;
    sortKey: string | null;
  };
  setSelectedSortOption: (
    newSate: React.SetStateAction<{
      id: number;
      title: string;
      value: string;
      sortKey: string | null;
    }>
  ) => void;
  showSortFeature: boolean;
  update: ({
    query,
    value,
  }: {
    query: string | null;
    value: boolean | number | string | null;
  }) => void;
  totalCount: number;
  totalPages: number;
  selectedSubscription: string | null;
  download: (newSatte: React.SetStateAction<boolean>) => void;
  pageNum: number;
  paginationData: {
    totalPage: number;
    currentPage: number;
    itemsPerPage: number;
    totalItemCount: number;
  };
  isLoading: boolean;
  selectedETD: string | null;
  selectedTeams: string | null;
  selectedMembers: string | null;
  values: {
    START_DATE: string;
    END_DATE: string;
    SELECT_PERIOD: string;
  };
  updateFilter: <S extends "START_DATE" | "END_DATE" | "SELECT_PERIOD">(
    name: S,
    newValue: {
      START_DATE: string;
      END_DATE: string;
      SELECT_PERIOD: string;
    }[S]
  ) => void;
  serviceTeamId?: string;
  showUnassignedTeam: string | null;
  showUnassignedPreparer: string | null;
  taskFilters: string;
};

export const ForeignCATaskFilter = ({
  searchedTerm,
  selectedTag,
  selectedCompanyTag,
  tags,
  selectedStateFilter,
  taskStates,
  templates,
  selectedTemplateFilter,
  selectedAssignee,
  assignees,
  selectedSortOption,
  setSelectedSortOption,
  showSortFeature,
  update,
  totalPages,
  selectedSubscription,
  download,
  pageNum,
  paginationData,
  isLoading,
  selectedETD,
  selectedTeams,
  selectedMembers,
  values,
  updateFilter,
  serviceTeamId,
  showUnassignedTeam,
  showUnassignedPreparer,
  taskFilters,
}: ForeignCATaskFilterType) => {
  const { authtoken } = useContext(authContext);
  const [hideSortDiv, setHideSortDiv] = useState(true);
  const { isCpa, isAdmin, isServiceSuperAdmin } = useRoleBasedView();
  const { data: companyTagsData = [] } = useGetTagsForCategoryQuery({
    tagType: COMPANY_GROUP_TAG,
  });
  const { data: teamOptions = [] } = useGetAllInkleCPATeamsQuery(
    {
      accessibleTeams: "CPA_TEAM",
    },
    { skip: !isAdmin }
  );
  const serviceTeamOptions = [
    ...teamOptions,
    { uuid: NO_SERVICE_TEAM_UUID, name: NO_SERVICE_TEAM_NAME },
  ];
  const { data: teamMembers = [] } = useGetServiceTeamQuery(
    {
      serviceTeamId: selectedTeams || serviceTeamId,
    },
    { skip: !selectedTeams && !serviceTeamId, refetchOnMountOrArgChange: true }
  );
  const companyTags = [...companyTagsData, { title: NO_TAG, uuid: NO_TAG_KEY }];
  const selectedEtdLabel = ETD_FILTER_OPTIONS.find(
    ({ value }) => value === selectedETD
  )?.label;

  const {
    open: openBroadcast,
    close: closeBroadcast,
    isOpen: isBroadcastOpen,
  } = useModal();

  const { search } = useLocation();
  const filters = parse(search, { ignoreQueryPrefix: true });

  const searchQuery = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value) {
      update({ query: "search_term", value: value });
    } else {
      update({ query: "search_term", value: null });
    }
  };

  const templateFilter = (values: string[] | null) => {
    if (values) {
      update({ query: "task_template_filter_uuids", value: values.join(",") });
    } else {
      update({
        query: "task_template_filter_uuids",
        value: null,
      });
    }
  };

  const subscriptionFilter = (values: string[] | null) => {
    if (values) {
      update({ query: "subscription_filter", value: values.join(",") });
    } else {
      update({ query: "subscription_filter", value: null });
    }
  };

  const serviceTeamFilter = (values: string[] | null) => {
    if (values)
      update({ query: "service_team_uuids", value: values.join(",") || null });
  };

  const teamMemberFilter = (values: string[] | null) => {
    if (values)
      update({ query: "team_member_uuids", value: values.join(",") || null });
  };

  const handleEtdFilter = (value: string) => {
    update({
      query: "etd_filter",
      value: value || null,
    });
  };

  const updateTagFilter = (values: string[] | null) => {
    if (values) {
      update({ query: "task_tag_filter_uuids", value: values.join(",") });
    } else {
      update({ query: "task_tag_filter_uuids", value: null });
    }
  };

  const updateCompanyTagFilter = (values: string[] | null) => {
    if (values) {
      update({ query: "company_tag_filter_uuids", value: values.join(",") });
    } else {
      update({ query: "company_tag_filter_uuids", value: null });
    }
  };

  const stateFilter = (values: string[] | null) => {
    if (values) {
      update({ query: "task_state_filter_uuids", value: values.join(",") });
    } else {
      update({ query: "task_state_filter_uuids", value: null });
    }
  };

  const updateAssigneeFilter = (values: string[] | null) => {
    if (values) {
      update({ query: "assignee_uuids", value: values.join(",") });
    } else {
      update({ query: "assignee_uuids", value: null });
    }
  };

  const updateNoTeamFilter = () => {
    update({
      query: "show_unassigned_team",
      value: showUnassignedTeam ? null : true,
    });
  };

  const updateNoAssigneeFilter = () => {
    update({
      query: "show_unassigned_preparer",
      value: showUnassignedPreparer ? null : true,
    });
  };

  const downloadCSV = () => {
    download(true);
  };

  function manageSortOptionModal() {
    setHideSortDiv((hideSortDiv) => !hideSortDiv);
  }

  function setSortOption(
    e: MouseEvent<HTMLParagraphElement>,
    sortOption: {
      id: number;
      title: string;
      value: string;
      sortKey: string | null;
    }
  ) {
    setHideSortDiv(true);
    setSelectedSortOption(sortOption);
    e.stopPropagation();
  }

  const goToFirstPage = () => {
    update({ query: "page", value: 1 });
  };

  const goToPrevPage = () => {
    const localCurrentPage = pageNum < totalPages ? pageNum : totalPages;
    update({ query: "page", value: localCurrentPage - 1 });
  };

  const goToNextPage = () => {
    if (pageNum < totalPages) {
      update({ query: "page", value: pageNum + 1 });
    }
  };

  const goToLastPage = () => {
    update({ query: "page", value: totalPages });
  };

  return (
    <>
      <div className="t-flex t-justify-between t-items-center">
        <div className="t-w-1/2 t-flex t-items-center">
          <Search
            placeholder="Search..."
            defaultValue={searchedTerm || undefined}
            onChange={searchQuery}
            block
            autoFocus
          />
        </div>
        <Pagination
          {...paginationData}
          goToFirstPage={goToFirstPage}
          goToPrevPage={goToPrevPage}
          goToNextPage={goToNextPage}
          goToLastPage={goToLastPage}
        />
      </div>

      <div className="t-flex t-items-center t-justify-between">
        <div className="t-flex t-items-center t-gap-2">
          <Filter.Root
            defaultValue="STATE"
            title="Filter"
            capsule={
              <>
                {isAdmin && (
                  <Chip
                    onClose={updateNoTeamFilter}
                    filterType="SERVICE_TEAM"
                    isActive={Boolean(showUnassignedTeam)}
                    isFixedFilter
                    onClick={updateNoTeamFilter}
                  >
                    Unassigned Team
                  </Chip>
                )}
                {(isServiceSuperAdmin || isAdmin) && (
                  <Chip
                    onClose={updateNoAssigneeFilter}
                    filterType="SERVICE_TEAM_MEMBER"
                    isActive={Boolean(showUnassignedPreparer)}
                    isFixedFilter
                    onClick={updateNoAssigneeFilter}
                  >
                    Unassigned Preparer
                  </Chip>
                )}
                {selectedStateFilter &&
                  selectedStateFilter?.split(",").length > 0 && (
                    <Chip
                      onClose={() => stateFilter(null)}
                      isActive
                      filterType="STATE"
                    >
                      State ({selectedStateFilter.split(",").length})
                    </Chip>
                  )}
                {selectedTemplateFilter &&
                  selectedTemplateFilter?.split(",").length > 0 && (
                    <Chip
                      onClose={() => templateFilter(null)}
                      isActive
                      filterType="FILING"
                    >
                      Filing ({selectedTemplateFilter.split(",").length})
                    </Chip>
                  )}
                {selectedAssignee &&
                  selectedAssignee?.split(",").length > 0 && (
                    <Chip
                      onClose={() => updateAssigneeFilter(null)}
                      isActive
                      filterType="AGENT"
                    >
                      Agent ({selectedAssignee.split(",").length})
                    </Chip>
                  )}
                {selectedTag && selectedTag?.split(",").length > 0 && (
                  <Chip
                    onClose={() => updateTagFilter(null)}
                    isActive
                    filterType="TAG"
                  >
                    Tag ({selectedTag.split(",").length})
                  </Chip>
                )}
                {selectedCompanyTag &&
                  selectedCompanyTag?.split(",").length > 0 && (
                    <Chip
                      onClose={() => updateCompanyTagFilter(null)}
                      isActive
                      filterType="COMPANY_TAG"
                    >
                      Company Tag ({selectedCompanyTag.split(",").length})
                    </Chip>
                  )}
                {selectedTeams && selectedTeams?.split(",").length > 0 && (
                  <Chip
                    onClose={() => serviceTeamFilter([])}
                    isActive
                    filterType="SERVICE_TEAM"
                  >
                    Team ({selectedTeams?.split(",").length})
                  </Chip>
                )}
                {selectedMembers && selectedMembers?.split(",").length > 0 && (
                  <Chip
                    onClose={() => teamMemberFilter([])}
                    isActive
                    filterType="SERVICE_TEAM_MEMBER"
                  >
                    Members ({selectedMembers?.split(",").length})
                  </Chip>
                )}
                {selectedETD && selectedEtdLabel && (
                  <Chip
                    onClose={() => handleEtdFilter("")}
                    isActive
                    filterType="ETD"
                  >
                    {selectedEtdLabel}
                  </Chip>
                )}
                {values.START_DATE && (
                  <Chip
                    onClose={() => {
                      updateFilter("START_DATE", "");
                      updateFilter("END_DATE", "");
                    }}
                    isActive
                    filterType="COMPLETED_DATE"
                  >
                    From: {formatDate(values.START_DATE)} To:{" "}
                    {formatDate(values.END_DATE)}
                  </Chip>
                )}
              </>
            }
          >
            <Filter.Portal>
              <Filter.List>
                <Filter.ListItem value="STATE">State</Filter.ListItem>
                <Filter.ListItem value="FILING">Filing</Filter.ListItem>
                {!authtoken.is_foreign_ca && (
                  <Filter.ListItem value="AGENT">Agent</Filter.ListItem>
                )}
                {isCpa && (
                  <Filter.ListItem value="SUBSCRIPTION">
                    Subscription
                  </Filter.ListItem>
                )}
                <Filter.ListItem value="TAG">Tag</Filter.ListItem>
                <Filter.ListItem value="COMPANY_TAG">
                  Company Tag
                </Filter.ListItem>
                {isAdmin && (
                  <Filter.ListItem value="SERVICE_TEAM">
                    Service Team
                  </Filter.ListItem>
                )}
                {((isAdmin && selectedTeams) || serviceTeamId) && (
                  <Filter.ListItem value="SERVICE_TEAM_MEMBER">
                    Preparer
                  </Filter.ListItem>
                )}
                <Filter.ListItem value="ETD">ETD</Filter.ListItem>
                <Filter.ListItem value="COMPLETED_DATE">
                  Completed Date Filter
                </Filter.ListItem>
              </Filter.List>
              <Filter.Body value="STATE" block>
                {taskStates && (
                  <MultiSelectFilter
                    onChange={stateFilter}
                    options={taskStates.map((taskState) => ({
                      value: taskState.uuid,
                      label: taskState.name,
                    }))}
                    selected={selectedStateFilter?.split(",")}
                  />
                )}
              </Filter.Body>
              {!authtoken.is_foreign_ca && (
                <Filter.Body value="AGENT">
                  <MultiSelectFilter
                    onChange={updateAssigneeFilter}
                    options={assignees?.map((assignee) => ({
                      value: assignee.uuid,
                      label: assignee.name,
                    }))}
                    selected={selectedAssignee?.split(",")}
                  />
                </Filter.Body>
              )}
              {isCpa && (
                <Filter.Body value="SUBSCRIPTION">
                  <MultiSelectFilter
                    onChange={subscriptionFilter}
                    options={CRM_SUBSCRIPTION_TYPES?.map(
                      (subscription_name) => ({
                        value: subscription_name,
                        label: subscription_name,
                      })
                    )}
                    selected={selectedSubscription?.split(",")}
                  />
                </Filter.Body>
              )}
              {templates && (
                <Filter.Body value="FILING">
                  <MultiSelectFilter
                    onChange={templateFilter}
                    options={templates.map((template) => ({
                      value: template.uuid,
                      label: `${template.title}  ${
                        !template.year ? null : `- ${template.year}`
                      }`,
                    }))}
                    selected={selectedTemplateFilter?.split(",")}
                  />
                </Filter.Body>
              )}
              <Filter.Body value="TAG" block>
                {tags && (
                  <MultiSelectFilter
                    onChange={updateTagFilter}
                    options={tags.map((tag) => ({
                      value: tag.uuid,
                      label: tag.title,
                    }))}
                    selected={selectedTag?.split(",")}
                  />
                )}
              </Filter.Body>
              <Filter.Body value="COMPANY_TAG" block>
                {companyTags && (
                  <MultiSelectFilter
                    onChange={updateCompanyTagFilter}
                    options={companyTags.map((tag) => ({
                      value: tag.uuid,
                      label: tag.title,
                    }))}
                    selected={selectedCompanyTag?.split(",")}
                  />
                )}
              </Filter.Body>
              <Filter.Body value="SERVICE_TEAM">
                <MultiSelectFilter
                  onChange={serviceTeamFilter}
                  options={serviceTeamOptions?.map((team) => ({
                    value: team.uuid,
                    label: team.name,
                  }))}
                  selected={selectedTeams?.split(",")}
                />
              </Filter.Body>
              <Filter.Body value="SERVICE_TEAM_MEMBER">
                <MultiSelectFilter
                  onChange={teamMemberFilter}
                  options={teamMembers?.map((member) => ({
                    value: member.uuid,
                    label: member.name,
                  }))}
                  selected={selectedMembers?.split(",")}
                />
              </Filter.Body>
              <Filter.Body value="ETD">
                <Radio.Root
                  value={selectedETD || undefined}
                  onValueChange={handleEtdFilter}
                >
                  <Radio.Content className="t-gap-3 t-flex t-flex-col">
                    {ETD_FILTER_OPTIONS.map((item) => (
                      <Radio.Item key={item.value} value={item.value}>
                        {item.label}
                      </Radio.Item>
                    ))}
                  </Radio.Content>
                </Radio.Root>
              </Filter.Body>
              <Filter.Body value="COMPLETED_DATE" block>
                <DateFilter values={values} updateFilter={updateFilter} />
              </Filter.Body>
            </Filter.Portal>
          </Filter.Root>
        </div>
        <div className="t-relative t-flex t-gap-4">
          <ToolTip text="Download as csv">
            <span>
              <Button
                onClick={downloadCSV}
                customType="icon"
                size="small"
                isLoading={isLoading}
              >
                <DownloadIcon color="#706A85" size={20} />
              </Button>
            </span>
          </ToolTip>
          <div
            className={classNames("selectSortFilterDiv", {
              "d-none": !showSortFeature,
            })}
            onClick={manageSortOptionModal}
          >
            <img
              src={SortDescendingIcon}
              alt="icon"
              height="20px"
              width="20px"
            />
            <span className="selectSortFilter">Sort By :</span>
            <span className="selectSortFilter">
              {selectedSortOption?.value}
            </span>
          </div>
          <div
            className={classNames(
              "sortOptionModal t-absolute t-mt-2 t-bg-surface t-z-filter-head",
              {
                hideSortFilterDiv: hideSortDiv,
              }
            )}
          >
            {sortOptions.map((option) => {
              return (
                <p
                  key={option.id}
                  className={classNames("consultantDashBoardSortOptions", {
                    consultantDashBoardSelectedSortOption:
                      option.value === selectedSortOption?.value,
                  })}
                  onClick={(e) => setSortOption(e, option)}
                >
                  {option.title}
                </p>
              );
            })}
          </div>
          {isAdmin && (
            <div>
              <Button onClick={openBroadcast}>
                <div className="t-flex t-gap-1">
                  <BroadcastIcon /> <span>Broadcast</span>
                </div>
              </Button>

              <Modal.Root open={isBroadcastOpen} onOpenChange={closeBroadcast}>
                <Modal.Content size="large" useCustomOverlay>
                  <BroadcastMessage
                    onClose={closeBroadcast}
                    crmFilters={taskFilters}
                    fromTaskTracker
                  />
                </Modal.Content>
              </Modal.Root>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
