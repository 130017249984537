import React from "react";

export const InkleAdminBooksSymbol = () => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30.5247 29.6934H28.6824C28.6146 29.6934 28.6296 29.8308 28.8439 29.9407C29.0153 30.0286 29.0397 30.3351 29.0305 30.4773V40H32.9518V30.5836C32.808 29.9473 31.6814 29.725 31.1361 29.6934H30.5247Z"
      fill="url(#paint0_linear_5723_7980)"
    />
    <path
      d="M24.1478 26.2754H22.3054C22.2376 26.2754 22.2527 26.4584 22.467 26.6047C22.6384 26.7218 22.6628 27.1299 22.6535 27.3194V40H26.5748V27.4609C26.431 26.6135 25.3045 26.3175 24.7592 26.2754H24.1478Z"
      fill="url(#paint1_linear_5723_7980)"
    />
    <path
      d="M17.8782 22.4004H16.0359C15.9681 22.4004 15.9831 22.6344 16.1974 22.8216C16.3689 22.9713 16.3932 23.4932 16.384 23.7354V39.9512H20.3053V23.9164C20.1615 22.8328 19.0349 22.4542 18.4896 22.4004H17.8782Z"
      fill="url(#paint2_linear_5723_7980)"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M29.7143 19.4216H16V16H29.7143C31.4172 16 33.9334 16.4958 36.0731 18.0332C38.3002 19.6335 40 22.2856 40 26.2647V39.9509H36.5714V26.2647C36.5714 23.4007 35.4141 21.7758 34.0698 20.8099C32.6381 19.7812 30.8685 19.4216 29.7143 19.4216Z"
      fill="url(#paint3_linear_5723_7980)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5723_7980"
        x1="28.6465"
        y1="34.8467"
        x2="32.9518"
        y2="34.8467"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_5723_7980"
        x1="22.2695"
        y1="33.1377"
        x2="26.5748"
        y2="33.1377"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_5723_7980"
        x1="16"
        y1="31.1758"
        x2="20.3053"
        y2="31.1758"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_5723_7980"
        x1="16"
        y1="27.9755"
        x2="40"
        y2="27.9755"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#8B08FD" />
        <stop offset="1" stop-color="#5622FF" />
      </linearGradient>
    </defs>
  </svg>
);
