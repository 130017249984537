export const ArrowLeft = ({
  size = "24",
  strokeWidth = "2",
}: {
  size?: string;
  strokeWidth?: string;
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_507_29456)">
      <path
        d="M20.25 12H3.75"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 5.25L3.75 12L10.5 18.75"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_507_29456">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
