import qs from "qs";
import { Transactions } from "types/Models/books";
import { Pagination } from "types/Models/pagination";
import {
  ConditionResult,
  PreFilledRule,
  RulesData,
} from "types/Models/ruleEngine";
import { emptyApi } from "./emptyApi";

const ruleEngine = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getAllConditions: build.query<
      ConditionResult[],
      { groupId: string; entityId: string }
    >({
      query: ({ groupId, entityId }) => {
        return {
          url: `api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/categorization/rule_conditions/`,
        };
      },
    }),

    getAllRules: build.query<
      { rules_data: RulesData[] } & Pagination,
      { groupId: string; entityId: string; pageNum: number; searchTerm: string }
    >({
      query: ({ groupId, entityId, pageNum, searchTerm }) => {
        let queryUrl = qs.stringify(
          {
            page_num: pageNum,
            search_term: searchTerm,
          },
          {
            filter: (prefix, value) =>
              value === false || value === "" ? undefined : value,
            skipNulls: true,
            addQueryPrefix: true,
          }
        );
        return {
          url: `api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/categorization/rules/${queryUrl}`,
        };
      },
      providesTags: ["RULE_ENGINE"],
    }),

    createRule: build.mutation<
      RulesData,
      {
        groupId: string;
        entityId: string;
        name: string;
        rule_start_date?: string;
        rule_end_date?: string;
        assigned_category_id: string;
        apply_for_txns_with_no_category: boolean;
        apply_for_future_txns: boolean;
        apply_on_all_past_txns: boolean;
        rule_condition_data: {
          operand_id: string;
          operator_id: string;
          value: string | number | string[];
        }[];
      }
    >({
      query: ({
        groupId,
        entityId,
        name,
        rule_start_date,
        rule_end_date,
        rule_condition_data,
        assigned_category_id,
        apply_for_future_txns,
        apply_for_txns_with_no_category,
        apply_on_all_past_txns,
      }) => {
        return {
          url: `api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/categorization/rules/`,
          method: "POST",
          body: {
            name,
            rule_end_date,
            rule_start_date,
            rule_condition_data,
            assigned_category_id,
            apply_for_future_txns,
            apply_for_txns_with_no_category,
            apply_on_all_past_txns,
          },
        };
      },
    }),

    getRuleTransactions: build.query<
      { rule: RulesData; transactions: Transactions[] } & Pagination,
      { groupId: string; entityId: string; ruleId: string; page_num: number }
    >({
      query: ({ groupId, entityId, ruleId, page_num }) => {
        let queryUrl = qs.stringify(
          {
            page_num,
          },
          { skipNulls: true, addQueryPrefix: true }
        );

        return {
          url: `api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/categorization/rules/${ruleId}/transactions/${queryUrl}`,
        };
      },
    }),

    markRuleActive: build.mutation<
      RulesData,
      { groupId: string; entityId: string; ruleId: string }
    >({
      query: ({ groupId, entityId, ruleId }) => {
        return {
          url: `api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/categorization/rules/${ruleId}`,
          method: "PUT",
        };
      },
      invalidatesTags: (res) => (res ? ["RULE_ENGINE"] : []),
    }),

    getRuleById: build.query<
      RulesData,
      { groupId: string; entityId: string; ruleId: string }
    >({
      query: ({ groupId, entityId, ruleId }) => {
        return {
          url: `api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/categorization/rules/${ruleId}/`,
        };
      },
      providesTags: ["RULE_ENGINE"],
    }),

    editRule: build.mutation<
      RulesData,
      {
        groupId: string;
        entityId: string;
        ruleId: string;
        name: string;
        rule_start_date: string;
        rule_end_date?: string;
        assigned_category_id: string;
        rule_condition_data: {
          uuid?: string;
          operand_id: string;
          operator_id: string;
          value: string | number | string[];
        }[];
      }
    >({
      query: ({
        groupId,
        entityId,
        ruleId,
        name,
        rule_end_date,
        rule_start_date,
        rule_condition_data,
        assigned_category_id,
      }) => {
        return {
          url: `api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/categorization/rules/${ruleId}/`,
          method: "PATCH",
          body: {
            name,
            rule_end_date,
            rule_start_date,
            rule_condition_data,
            assigned_category_id,
          },
        };
      },
      invalidatesTags: (res) => (res ? ["RULE_ENGINE"] : []),
    }),

    deleteRule: build.mutation<
      void,
      {
        groupId: string;
        entityId: string;
        ruleId: string;
      }
    >({
      query: ({ groupId, entityId, ruleId }) => {
        return {
          url: `api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/categorization/rules/${ruleId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (res) => (res ? ["RULE_ENGINE"] : []),
    }),

    getPrefilledRule: build.query<
      PreFilledRule,
      {
        groupId: string;
        entityId: string;
        categoryId: string;
        merchantIds: string;
      }
    >({
      query: ({ groupId, entityId, categoryId, merchantIds }) => {
        let queryUrl = qs.stringify(
          { vendor_uuids: merchantIds, category_id: categoryId },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/categorization/get_prefilled_rule_data/${queryUrl}`,
        };
      },
    }),
  }),
});

export const {
  useGetAllRulesQuery,
  useGetAllConditionsQuery,
  useCreateRuleMutation,
  useLazyGetRuleTransactionsQuery,
  useMarkRuleActiveMutation,
  useGetRuleByIdQuery,
  useEditRuleMutation,
  useDeleteRuleMutation,
  useGetPrefilledRuleQuery,
} = ruleEngine;
