import { RestrictedRoute } from "RestrictedRoute";
import { AppLayout } from "components/AppLayout/AppLayout";
import { AutofillPage } from "components/AutoFill/AutofillPage";
import { BookkeepingTracker } from "components/BooksCrm/BookkeepingTracker";
import { ChatLeftNav } from "components/ChatLeftNav/ChatLeftNav";
import { DeadlinesTracker } from "components/DeadlinesTracker";
import EntityDetail from "components/Entity/EntityDetail";
import { GroupPracticeCrm } from "components/PracticeCrm/GroupPracticeCrm";
import { PracticeCrm } from "components/PracticeCrm/PracticeCrm";
import { RoutesAccrossApps } from "components/RoutesAcrossApps/RoutesAcrossApps";
import {
  LeftBar,
  LeftBarItem,
  LeftBarLogo,
  LeftBarSeparator,
} from "components/Sidebar/LeftBar";
import { TeamManagement } from "components/TeamManagement/TeamManagement";
import { TopBar } from "components/TopBar/TopBar";
import { GroupData } from "components/crm/groupData";
import GroupSelect from "components/dashboard/sideBar/groupSelect";
import { PageNotFound } from "components/design/PageNotFound";
import WelcomeUser from "components/design/welcomeUser";
import { Captable } from "components/icons/LeftNav/Board/Captable";
import { Filings } from "components/icons/LeftNav/Filings";
import { Home } from "components/icons/LeftNav/Home";
import { Ticket } from "components/icons/LeftNav/Ticket";
import { InkleTax } from "components/icons/Logos/InkleTax";
import { InkleTaxSymbol } from "components/icons/Logos/InkleTaxSymbol";
import PerksCrm from "components/perksCrm/perksCrm";
import { SalesCrmRoute } from "components/salesCrm/SalesCrmRoute";
import { ArchivedCrm } from "components/serviceTeam/ArchivedCrm";
import { BooksCRM } from "components/serviceTeam/BooksCrm";
import RegAgentDeadline from "components/serviceTeam/regAgentDeadline";
import ServiceTeam from "components/serviceTeam/serviceTeam";
import ConsultantDashboard from "components/tasks/consultantDashboard/consultantDashBoard";
import { ForeignCATask } from "components/tasks/taskForForeignCA/ForeignCATask";
import { FOREIGN_CA } from "constants/onBoarding";
import { useAuth } from "hooks/useAuth";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { Redirect, Switch, useRouteMatch } from "react-router-dom";
import "static/styles/containers/dashboard.css";
import { AuditLogs } from "./AuditLogs/AuditLogs";
import { RaiseCRM } from "./RaiseCRM";
import { SalesQuotes } from "./SalesQuotes";
import { useCurrentAppContext } from "contexts/CurrentAppContext";
import { useEffect } from "react";

export const AdminNavbar = () => {
  const { type } = useAuth();
  const { isAdmin } = useRoleBasedView();

  return (
    <div className="t-h-full t-flex t-flex-col t-justify-between">
      <div>
        {type === FOREIGN_CA && (
          <div className="t-m-2">
            <GroupSelect />
          </div>
        )}

        <LeftBarItem useParentPath={false} exact to="/admin" icon={Home}>
          Home
        </LeftBarItem>

        <LeftBarItem useParentPath={false} to="/admin/filings" icon={Filings}>
          Tasks Tracker
        </LeftBarItem>
        <LeftBarItem useParentPath={false} to="/admin/crm" icon={Captable}>
          Ops CRM
        </LeftBarItem>

        {isAdmin && (
          <>
            <LeftBarItem
              useParentPath={false}
              to="/admin/salescrm"
              icon={Captable}
            >
              Sales CRM
            </LeftBarItem>
            <LeftBarItem
              useParentPath={false}
              to="/admin/practicecrm"
              icon={Captable}
            >
              Practice CRM
            </LeftBarItem>
            <LeftBarItem
              useParentPath={false}
              to="/admin/perkscrm"
              icon={Captable}
            >
              Perks CRM
            </LeftBarItem>

            <LeftBarItem
              useParentPath={false}
              to="/admin/raisecrm"
              icon={Captable}
            >
              Raise CRM
            </LeftBarItem>
            <LeftBarItem
              useParentPath={false}
              to="/admin/sales-quotes"
              icon={Captable}
            >
              Sales Quotes
            </LeftBarItem>
          </>
        )}
        <LeftBarItem
          useParentPath={false}
          to="/admin/teammanagement"
          icon={Captable}
        >
          Team Management
        </LeftBarItem>
      </div>

      <div>
        <LeftBarSeparator />
        <LeftBarItem useParentPath={false} to="/admin/tickets" icon={Ticket}>
          Tickets
        </LeftBarItem>
        <ChatLeftNav useParentPath={false} to={"/admin/chat/unreplied"} />
        {isAdmin && (
          <LeftBarItem useParentPath={false} to="/admin/auditlog" icon={Ticket}>
            Audit logs
          </LeftBarItem>
        )}
      </div>
    </div>
  );
};

export const AdminDashboard = () => {
  const { path, url } = useRouteMatch();
  const { isAdmin } = useRoleBasedView();
  const { dispatch: currentAppDispatch } = useCurrentAppContext();

  useEffect(() => {
    currentAppDispatch({
      type: "SET_CURRENT_APP",
      payload: { currentApp: "Tax Admin" },
    });
  }, []);

  return (
    <AppLayout
      sidebar={
        <LeftBar
          logo={<LeftBarLogo logo={InkleTax} symbol={InkleTaxSymbol} />}
          showCollapse
        >
          <AdminNavbar />
        </LeftBar>
      }
      topbar={
        <TopBar
          logo={<LeftBarLogo logo={InkleTax} symbol={InkleTaxSymbol} />}
          nav={
            <LeftBar>
              <AdminNavbar />
            </LeftBar>
          }
        />
      }
      // banner={
      //   <DashboardBanner
      //     isCustomer={isCustomer!}
      //     subscriptionInactive={subscriptionInactive}
      //     stripePromise={stripePromise}
      //   />
      // }
    >
      <Switch>
        <RestrictedRoute
          exact
          path={`${path}/auditlog`}
          component={isAdmin ? AuditLogs : PageNotFound}
        />
        <RestrictedRoute
          exact
          path={`${path}/crm/:groupId/autofills/addresses`}
          render={() => <AutofillPage type="addresses" />}
        />
        <RestrictedRoute
          exact
          path={`${path}/crm/:groupId/autofills/bank_accounts`}
          render={() => <AutofillPage type="bank_accounts" />}
        />
        <RestrictedRoute
          exact
          path={`${path}/crm/:groupId/autofills/authorized_persons`}
          render={() => <AutofillPage type="authorized_persons" />}
        />
        <RestrictedRoute path={`${path}/crm/:groupId/entity/:entityId`}>
          <EntityDetail parentUrl={url} />
        </RestrictedRoute>
        <RestrictedRoute path={`${path}/crm/:groupId`} component={GroupData} />
        <RestrictedRoute path={`${path}/crm`} component={ServiceTeam} />
        <RestrictedRoute
          path={`${path}/salescrm`}
          component={isAdmin ? SalesCrmRoute : PageNotFound}
        />
        <RestrictedRoute
          path={`${path}/practicecrm/:practicecrmId`}
          component={isAdmin ? GroupPracticeCrm : PageNotFound}
        />
        <RestrictedRoute
          path={`${path}/practicecrm`}
          component={isAdmin ? PracticeCrm : PageNotFound}
        />
        <RestrictedRoute
          exact
          path={`${path}/perkscrm`}
          component={isAdmin ? PerksCrm : PageNotFound}
        />
        <RestrictedRoute path={`${path}/filings`} component={ForeignCATask} />
        <RestrictedRoute
          path={`${path}/raisecrm`}
          component={isAdmin ? RaiseCRM : PageNotFound}
        />
        <RestrictedRoute
          exact
          path={`${path}/myfilings`}
          component={ConsultantDashboard}
        />
        <RestrictedRoute
          exact
          path={`${path}/deadlines-tracker`}
          component={DeadlinesTracker}
        />

        <RestrictedRoute exact path="/mytasks">
          <Redirect to={`${path}/myfilings`} />
        </RestrictedRoute>

        <RestrictedRoute
          exact
          path={`${path}/bookkeeping-tracker`}
          component={BookkeepingTracker}
        />

        <RestrictedRoute
          exact
          path={`${path}/radeadlines`}
          component={RegAgentDeadline}
        />
        <RestrictedRoute exact path={`${path}/bookscrm`} component={BooksCRM} />
        <RestrictedRoute
          exact
          path={`${path}/archivedcrm`}
          component={ArchivedCrm}
        />
        <RestrictedRoute
          path={`${path}/sales-quotes`}
          component={isAdmin ? SalesQuotes : PageNotFound}
        />
        <RestrictedRoute
          path={`${path}/teammanagement`}
          component={TeamManagement}
        />

        <RestrictedRoute exact path={path} component={WelcomeUser} />
        <RoutesAccrossApps />
      </Switch>
    </AppLayout>
  );
};
