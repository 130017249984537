import { AppLayout } from "components/AppLayout/AppLayout";
import { AutofillPage } from "components/AutoFill/AutofillPage";
import { ChatLeftNav } from "components/ChatLeftNav/ChatLeftNav";
import { GroupData } from "components/crm/groupData";
import GroupSelect from "components/dashboard/sideBar/groupSelect";
import Loader from "components/design/loader";
import EntityDetail from "components/Entity/EntityDetail";
import { ForeignCAHome } from "components/home/ForeignCAHome";
import { Captable } from "components/icons/LeftNav/Board/Captable";
import { Filings } from "components/icons/LeftNav/Filings";
import { Home } from "components/icons/LeftNav/Home";
import { InklePractice } from "components/icons/Logos/InklePractice";
import { InklePracticeSymbol } from "components/icons/Logos/InklePracticeSymbol";
import { InkleTax } from "components/icons/Logos/InkleTax";
import { InkleTaxSymbol } from "components/icons/Logos/InkleTaxSymbol";
import { Billing } from "components/icons/Navbar/Billing";
import { GroupPracticeCrm } from "components/PracticeCrm/GroupPracticeCrm";
import { RoutesAccrossApps } from "components/RoutesAcrossApps/RoutesAcrossApps";
import ServiceTeam from "components/serviceTeam/serviceTeam";
import {
  LeftBar,
  LeftBarItem,
  LeftBarLogo,
  LeftBarSeparator,
} from "components/Sidebar/LeftBar";
import { TeamManagement } from "components/TeamManagement/TeamManagement";
import { TopBar } from "components/TopBar/TopBar";
import { FOREIGN_CA } from "constants/onBoarding";
import { useAuth } from "hooks/useAuth";
import { lazy, Suspense, useEffect } from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import { RestrictedRoute } from "RestrictedRoute";
import "static/styles/containers/dashboard.css";
import { ForeignCATask } from "components/tasks/taskForForeignCA/ForeignCATask";
import { useCurrentAppContext } from "contexts/CurrentAppContext";

const PracticeBilling = lazy(() => import("./PracticeBilling"));

export const PracticeNavbar = () => {
  const { type } = useAuth();

  return (
    <div className="t-h-full t-flex t-flex-col t-justify-between">
      <div>
        {type === FOREIGN_CA && (
          <div className="t-m-2">
            <GroupSelect />
          </div>
        )}

        <LeftBarItem useParentPath={false} exact to="/practice" icon={Home}>
          Home
        </LeftBarItem>

        <LeftBarItem
          useParentPath={false}
          to="/practice/filings"
          icon={Filings}
        >
          Tasks Tracker
        </LeftBarItem>
        <LeftBarItem useParentPath={false} to="/practice/crm" icon={Captable}>
          Clients
        </LeftBarItem>

        <LeftBarItem
          useParentPath={false}
          to="/practice/teammanagement"
          icon={Captable}
        >
          Team Management
        </LeftBarItem>
      </div>

      <div>
        <LeftBarSeparator />
        <ChatLeftNav useParentPath={false} to={"/practice/chat"} />
        <LeftBarItem
          useParentPath={false}
          icon={Billing}
          to="/practice/billing"
        >
          Billing
        </LeftBarItem>
      </div>
    </div>
  );
};

const PracticeDashboard = () => {
  const { path, url } = useRouteMatch();
  const { dispatch: currentAppDispatch } = useCurrentAppContext();

  useEffect(() => {
    currentAppDispatch({
      type: "SET_CURRENT_APP",
      payload: { currentApp: "Tax Practice" },
    });
  }, []);

  return (
    <AppLayout
      sidebar={
        <LeftBar
          logo={
            <LeftBarLogo logo={InklePractice} symbol={InklePracticeSymbol} />
          }
          showCollapse
        >
          <PracticeNavbar />
        </LeftBar>
      }
      topbar={
        <TopBar
          logo={<LeftBarLogo logo={InkleTax} symbol={InkleTaxSymbol} />}
          nav={
            <LeftBar>
              <PracticeNavbar />
            </LeftBar>
          }
        />
      }
    >
      <Suspense
        fallback={
          <div className="t-flex t-justify-center t-items-center t-h-screen">
            <Loader />
          </div>
        }
      >
        <Switch>
          <RestrictedRoute
            path={`${path}/practicecrm/:practicecrmId`}
            component={GroupPracticeCrm}
          />

          <RestrictedRoute
            exact
            path={`${path}/crm/:groupId/autofills/addresses`}
            render={() => <AutofillPage type="addresses" />}
          />
          <RestrictedRoute
            exact
            path={`${path}/crm/:groupId/autofills/bank_accounts`}
            render={() => <AutofillPage type="bank_accounts" />}
          />
          <RestrictedRoute
            exact
            path={`${path}/crm/:groupId/autofills/authorized_persons`}
            render={() => <AutofillPage type="authorized_persons" />}
          />
          <RestrictedRoute path={`${path}/crm/:groupId/entity/:entityId`}>
            <EntityDetail parentUrl={url} />
          </RestrictedRoute>
          <RestrictedRoute
            path={`${path}/crm/:groupId`}
            component={GroupData}
          />
          <RestrictedRoute path={`${path}/crm`} component={ServiceTeam} />

          <RestrictedRoute path={`${path}/filings`} component={ForeignCATask} />

          <RestrictedRoute
            path={`${path}/teammanagement`}
            component={TeamManagement}
          />

          <RestrictedRoute path={`${path}/billing`}>
            <PracticeBilling />
          </RestrictedRoute>

          <RestrictedRoute exact path={path} component={ForeignCAHome} />
          <RoutesAccrossApps />
        </Switch>
      </Suspense>
    </AppLayout>
  );
};

export default PracticeDashboard;
