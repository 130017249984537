import { YYYY_MM_DD } from "constants/date";
import dayjs from "dayjs";
import { FiltersValues } from "store/slices/transactionFilter";
import { getDateRange } from "utils/getDateRange";

export interface AssignVendorFilterState {
  minAmount: FiltersValues;
  maxAmount: FiltersValues;
  startDate: FiltersValues;
  endDate: FiltersValues;
  hasComments: FiltersValues;
  lastMonth?: FiltersValues;
  uncategorised: FiltersValues;
  intercompany?: FiltersValues;
  fromPlaid: FiltersValues;
  fromCSV: FiltersValues;
  manuallyAddedTxn: FiltersValues;
  manualEntry?: FiltersValues;
  cashFlow?: FiltersValues;
  categoryIds?: FiltersValues;
  vendors?: FiltersValues;
  fromIds?: FiltersValues;
  bankStatement?: FiltersValues;
  hasNoVendors?: FiltersValues;
  splitTransactions?: FiltersValues;
  notReviewed?: FiltersValues;
  hasInvoiceOrMemo?: FiltersValues;
  excludedTransactions?: FiltersValues;
  selectPeriod?: FiltersValues;
  bankTransactions?: FiltersValues;
}

export type FilterName =
  | "minAmount"
  | "maxAmount"
  | "startDate"
  | "endDate"
  | "hasComments"
  | "lastMonth"
  | "uncategorised"
  | "intercompany"
  | "fromPlaid"
  | "fromCSV"
  | "manuallyAddedTxn"
  | "manualEntry"
  | "cashFlow"
  | "categoryIds"
  | "vendors"
  | "fromIds"
  | "bankStatement"
  | "hasNoVendors"
  | "splitTransactions"
  | "excludedTransactions"
  | "notReviewed"
  | "hasInvoiceOrMemo"
  | "SelectPeriod"
  | "bankTransactions";

const defaultDateRange = getDateRange("currentYear");

export let assignVendorFilterValues: AssignVendorFilterState = {
  cashFlow: {
    name: "Cash Flow",
    value: "DEBIT",
    type: "amount",
  },
  minAmount: { name: "Min Amount", value: null, type: "amount" },
  maxAmount: { name: "Max Amount", value: null, type: "amount" },

  startDate: {
    name: "Since",
    value: dayjs(defaultDateRange.startDate).format(YYYY_MM_DD),
    type: "transactionDate",
  },
  endDate: {
    name: "Till",
    value: dayjs(defaultDateRange.endDate).format(YYYY_MM_DD),
    type: "transactionDate",
  },
  selectPeriod: {
    name: "Select period",
    value: "CUSTOM",
    type: "transactionDate",
  },

  hasComments: { name: "Has Comments", value: false, type: "others" },
  fromPlaid: { name: "Bank connection", value: false, type: "sources" },
  fromCSV: { name: "CSV", value: false, type: "sources" },
  manuallyAddedTxn: {
    name: "Manually added",
    value: false,
    type: "sources",
  },
  uncategorised: {
    name: "Uncategorised",
    value: false,
    type: "others",
    fixed: false,
  },
  categoryIds: {
    name: "Categories",
    value: null,
    type: "category",
    fixed: false,
  },
  vendors: {
    name: "Vendors",
    value: null,
    type: "vendors",
    fixed: false,
  },
  fromIds: {
    name: "Source",
    value: null,
    type: "from",
    fixed: false,
  },
  manualEntry: {
    name: "Manual Entry",
    value: false,
    type: "from",
    fixed: false,
  },
  bankStatement: {
    name: "Bank statement",
    value: false,
    type: "from",
    fixed: false,
  },
  notReviewed: {
    name: "Not Reviewed",
    value: false,
    type: "others",
    fixed: false,
  },
  hasNoVendors: {
    name: "Unknown Vendors",
    value: true,
    type: "others",
    fixed: true,
  },
  splitTransactions: {
    name: "Split Transactions",
    value: false,
    type: "others",
    fixed: false,
  },
  hasInvoiceOrMemo: {
    name: "Has Invoice or Memo",
    value: false,
    type: "others",
    fixed: false,
  },
  excludedTransactions: {
    name: "Excluded Transactions",
    value: false,
    type: "others",
    fixed: false,
  },
  bankTransactions: {
    name: "Bank Transactions",
    value: true,
    type: "others",
    fixed: false,
  },
};
