import { ConditionalLink } from "components/conditionalLink";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import { TableUI } from "components/design/TableUI";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import Modal from "components/DesignSystem/Modal/Modal";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { FileInput } from "components/FileInput/FileInput";
import { Info } from "components/icons/Info";
import {
  BANK_CONNECTED_BROADCAST_MESSAGE,
  BANK_CONNECTION_SUCCESS_BROADCAST_CHANNEL,
} from "constants/connections";
import { Field, FieldProps, FormikValues, useFormikContext } from "formik";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useBeforeunload } from "hooks/useBeforeunload";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { Link, useHistory } from "react-router-dom";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import {
  bankConnectionsApi,
  useGetEntityBanksQuery,
} from "store/apis/bankConnections";
import {
  useFinalizeCOAImportMutation,
  useGetCOAImportStateQuery,
  useUpdateBankAccountMapMutation,
  useUploadCOAfileMutation,
} from "store/apis/chartOfAccounts";
import { ImportCOAData } from "types/Models/books";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";
import { array, boolean, mixed, object, string } from "yup";

const DataLostWrapper = ({ children }: { children: React.ReactNode }) => {
  useBeforeunload();
  return children;
};

export const ImportCOA = ({ close, isOpen }: ModalProps) => {
  const entityId = useCurrentEntityId();
  const { uuid: groupId } = useCurrentGroupContext();
  const [uploadCOAfile, { data, isLoading }] = useUploadCOAfileMutation();
  const { alertToast } = useToast();
  const mapBankCOA = useModal();
  const reviewCOA = useModal();

  const { coa_import_state_id, bank_account_names = [] } = data || {};

  const handleSubmit = async ({
    file,
    mark_opening_balances_zero,
  }: FormikValues) => {
    try {
      const { bank_account_names } = await uploadCOAfile({
        mark_opening_balances_zero,
        csv: file,
        entityId,
        groupId,
      }).unwrap();

      if (bank_account_names.length === 0) {
        reviewCOA.open();
      } else {
        mapBankCOA.open();
      }

      close();
    } catch (error) {
      alertToast(
        { message: (error as BackendError).data?.error?.message },
        error as {}
      );
    }
  };

  return (
    <>
      <Modal.FormikRoot
        open={isOpen}
        onOpenChange={close}
        initialValues={{ file: null, mark_opening_balances_zero: false }}
        onSubmit={handleSubmit}
        validationSchema={object().shape({
          file: mixed().required("File is required"),
          mark_opening_balances_zero: boolean().notRequired(),
        })}
        validateOnChange
        validateOnBlur
      >
        {({ values: { file }, submitForm }) => (
          <Modal.Content size="large">
            <Modal.Header>
              <Modal.Title>Import Chart of Accounts</Modal.Title>
              <Modal.Close />
            </Modal.Header>
            <Modal.Body className="t-flex t-flex-col t-gap-5">
              <FileInput
                disabled={isLoading}
                withForm
                name="file"
                label="Upload file (Formats supported: csv, xlsx)"
                accept={{
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                    [".xlsx"],
                  "text/csv": [".csv"],
                }}
                file={
                  file
                    ? {
                        // @ts-ignore
                        name: file?.name,
                        file_type: "CSV",
                        uuid: "",
                        is_previewable: false,
                      }
                    : null
                }
                required
              />
              <div className="t-text-text-60 t-text-body">
                <ConditionalLink
                  to="https://inkle-django-files-prod.s3.ap-south-1.amazonaws.com/coa_import_sample_csv.csv"
                  className="t-text-purple t-mr-1 hover:!t-underline"
                >
                  Click here
                </ConditionalLink>
                to see the supported format for importing Chart of Accounts
              </div>

              <Field name="mark_opening_balances_zero">
                {({ field }: FieldProps) => (
                  <Checkbox {...field} label="Set opening balances to 0" />
                )}
              </Field>

              <div className="t-text-body-sm t-text-text-60 t-rounded-lg t-border t-border-solid t-bg-surface-lighter-grey t-border-neutral-0 t-p-3 t-flex t-gap-2 t-items-center">
                <Info stroke="1.4" />
                <div>
                  This will replace the existing chart of accounts and
                  uncategorise transactions.
                </div>
              </div>
            </Modal.Body>
            <Modal.FooterButtonGroup>
              <Modal.RawClose asChild disabled={isLoading}>
                <Button>Cancel</Button>
              </Modal.RawClose>
              <Button
                customType="primary"
                onClick={submitForm}
                isLoading={isLoading}
                disabled={isLoading}
              >
                Continue
              </Button>
            </Modal.FooterButtonGroup>
          </Modal.Content>
        )}
      </Modal.FormikRoot>
      <MapBankCOA
        isOpen={mapBankCOA.isOpen}
        close={mapBankCOA.close}
        banksAccountNames={bank_account_names}
        coaImportStateId={coa_import_state_id!}
      />
      <ReviewCOA {...reviewCOA} coaImportStateId={coa_import_state_id!} />
    </>
  );
};

const BankSource = ({
  bank,
  index,
}: {
  bank: { name: string; source_id: string };
  index: number;
}) => {
  const {
    values: { banks },
  } = useFormikContext<{
    banks: {
      name: string;
      source_id: string;
    }[];
  }>();

  const {
    location: { search },
  } = useHistory();

  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const dispatch = useAppDispatch();

  const { data: banksAccounts } = useGetEntityBanksQuery(
    { entityId, groupId },
    { skip: !entityId || !groupId }
  );

  const connectionSuccessBroadcastChannel = new BroadcastChannel(
    BANK_CONNECTION_SUCCESS_BROADCAST_CHANNEL
  );
  connectionSuccessBroadcastChannel.onmessage = (event) => {
    if (event.data === BANK_CONNECTED_BROADCAST_MESSAGE) {
      dispatch(
        bankConnectionsApi.util.invalidateTags([{ type: "CONNECTION" }])
      );
      connectionSuccessBroadcastChannel.close();
    }
  };

  const allReadySelectedSources = banks.map((bank) => bank.source_id);

  const accounts = banksAccounts?.accounts.map(
    ({ account: { mask, nickname, uuid } }) => ({
      value: uuid,
      label: `${nickname}  ${mask ? `- ${mask}` : ""}`,
      isDisabled: allReadySelectedSources.includes(uuid),
    })
  );

  const selectedBank = accounts?.find(({ value }) => value === bank.source_id);

  return (
    <>
      <div className="t-grid t-gap-6 t-grid-cols-2">
        <TextInput disabled name={`banks[${index}].name`} />
        <Combobox
          options={accounts}
          value={selectedBank}
          name={`banks[${index}].source_id`}
          withForm
          actions={
            <Link
              target="_blank"
              to={{
                pathname: `/books/data-sources${search}&modal=BANK_CONNECTION_MODAL`,
              }}
            >
              <Button customType="link">Add data source</Button>
            </Link>
          }
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (base) => ({ ...base, width: 500 }),
          }}
        />
      </div>
    </>
  );
};

const MapBankCOA = ({
  close,
  isOpen,
  banksAccountNames,
  coaImportStateId,
}: ModalProps & { banksAccountNames: string[]; coaImportStateId: string }) => {
  const [updateBankAccountMap, { isLoading }] =
    useUpdateBankAccountMapMutation();
  const reviewCOA = useModal();
  const entityId = useCurrentEntityId();
  const { uuid: groupId } = useCurrentGroupContext();
  const { alertToast } = useToast();

  const handleSubmit = async ({ banks }: FormikValues) => {
    try {
      await updateBankAccountMap({
        bankAccountMap: banks,
        entityId,
        groupId,
        coaImportStateId,
      }).unwrap();
      reviewCOA.open();
      close();
    } catch (error) {
      alertToast(
        { message: (error as BackendError).data?.error?.message },
        error as {}
      );
    }
  };

  const banksList = banksAccountNames.map((name) => ({ name, source_id: "" }));

  return (
    <>
      <Modal.FormikRoot
        open={isOpen}
        onOpenChange={close}
        modal={false}
        initialValues={{ banks: banksList }}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={object({
          banks: array().of(
            object({
              name: string().required("Please enter bank name"),
              source_id: string().required("Please select source"),
            })
          ),
        })}
        validateOnChange
        validateOnBlur
      >
        {({ values: { banks }, submitForm }) => (
          <Modal.Content size="large" useCustomOverlay>
            <DataLostWrapper>
              <Modal.Header>
                <Modal.Title>Import Chart of Accounts</Modal.Title>
                <Modal.Close />
              </Modal.Header>
              <Modal.Body className="t-flex t-flex-col t-gap-5">
                <span className="t-text-text-100 t-text-body">
                  Map properties from the uploaded COA to Inkle Books
                </span>

                {banks.map((bank, index) => (
                  <BankSource key={bank.name} bank={bank} index={index} />
                ))}
              </Modal.Body>
              <Modal.FooterButtonGroup>
                <Modal.RawClose asChild disabled={isLoading}>
                  <Button>Cancel</Button>
                </Modal.RawClose>
                <Button
                  customType="primary"
                  disabled={isLoading}
                  isLoading={isLoading}
                  onClick={submitForm}
                >
                  Review
                </Button>
              </Modal.FooterButtonGroup>
            </DataLostWrapper>
          </Modal.Content>
        )}
      </Modal.FormikRoot>
      <ReviewCOA {...reviewCOA} coaImportStateId={coaImportStateId} />
    </>
  );
};

const ReviewCOA = ({
  close,
  isOpen,
  coaImportStateId,
}: ModalProps & { coaImportStateId: string }) => {
  const entityId = useCurrentEntityId();
  const { uuid: groupId } = useCurrentGroupContext();
  const showConfirmation = useModal();

  const { data, isLoading, isSuccess } = useGetCOAImportStateQuery(
    {
      entityId,
      groupId,
      coaImportStateId,
    },
    {
      skip: !coaImportStateId || !entityId || !groupId || !isOpen,
    }
  );

  const { coa_data = [] } = data || {};
  const isEmpty = coa_data.length === 0;

  const onContinue = () => {
    close();
    showConfirmation.open();
  };

  const createColumn = createColumnHelper<ImportCOAData>();

  const columns = [
    createColumn.accessor("name", {
      size: 20,
      header: "CATEGORY NAME",
      cell: (row) => {
        return row.getValue() || "-";
      },
    }),

    createColumn.accessor("parent", {
      size: 20,
      header: "PARENT NAME",
      cell: (row) => {
        return row.getValue() || "-";
      },
    }),

    createColumn.accessor("identifier", {
      size: 10,
      header: "CATEGORY ID",
      cell: (row) => row.getValue() || "-",
    }),

    createColumn.accessor("opening_balance", {
      size: 20,
      header: () => <div className="t-text-end">OPENING BALANCE</div>,
      cell: (row) => {
        if (row.getValue()) {
          return (
            <div className="t-text-end">
              <AmountSuperScript amount={row.getValue()} />
            </div>
          );
        }

        return <div className="t-text-end">-</div>;
      },
    }),

    createColumn.accessor("description", {
      size: 30,
      header: "DESCRIPTION",
      cell: (row) => row.getValue() || "-",
    }),
  ];

  const table = useReactTable({
    data: coa_data,
    columns,
    getRowId: (row) => row.identifier.toString(),
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      size: 10,
      minSize: 1,
      maxSize: 100,
    },
  });

  return (
    <>
      <Modal.Root open={isOpen} onOpenChange={close}>
        <Modal.Content size="xxxl">
          <DataLostWrapper>
            <Modal.Header>
              <Modal.Title>Import Chart of Accounts</Modal.Title>
              <Modal.Close />
            </Modal.Header>
            <Modal.Body className="t-relative !t-pb-0">
              <Async.Root
                isLoading={isLoading}
                isSuccess={isSuccess}
                isEmpty={isEmpty}
              >
                <Async.Empty>
                  <></>
                </Async.Empty>
                <Async.Success>
                  <TableUI table={table} />
                </Async.Success>
              </Async.Root>
              <div className="t-text-text-30 t-text-subtext-sm t-sticky t-bottom-0 t-bg-white t-py-5">
                Note: The opening balances imported might differ from the actual
                file as Inkle Books and Quickbooks handle opening balances
                differently.
              </div>
            </Modal.Body>

            <Modal.FooterButtonGroup>
              <Modal.RawClose asChild>
                <Button>Cancel</Button>
              </Modal.RawClose>
              <Button customType="primary" onClick={onContinue}>
                Continue
              </Button>
            </Modal.FooterButtonGroup>
          </DataLostWrapper>
        </Modal.Content>
      </Modal.Root>
      <ConfirmImport
        isOpen={showConfirmation.isOpen}
        close={showConfirmation.close}
        coaImportStateId={coaImportStateId}
      />
    </>
  );
};

const ConfirmImport = ({
  isOpen,
  close,
  coaImportStateId,
}: ModalProps & { coaImportStateId: string }) => {
  const entityId = useCurrentEntityId();
  const { uuid: groupId } = useCurrentGroupContext();
  const { alertToast, infoToast } = useToast();

  const [finalizeCOAImport, { isLoading }] = useFinalizeCOAImportMutation();

  const onConfirm = async () => {
    try {
      await finalizeCOAImport({ entityId, groupId, coaImportStateId }).unwrap();
      infoToast({
        title: "Importing",
        message: "We'll update you once done",
      });
      close();
    } catch (error) {
      alertToast(
        { message: (error as BackendError).data?.error?.message },
        error as {}
      );
    }
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Import Chart of Accounts</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <div className="t-text-body">
            This will replace the existing chart of accounts and uncategorise
            transactions.
          </div>
          <br />
          <div className="t-text-subtitle-sm">
            This action cannot be undone.
          </div>
        </Modal.Body>
        <Modal.FooterButtonGroup>
          <Modal.RawClose asChild disabled={isLoading}>
            <Button>Close</Button>
          </Modal.RawClose>
          <Button
            customType="primary"
            onClick={onConfirm}
            disabled={isLoading}
            isLoading={isLoading}
          >
            Confirm
          </Button>
        </Modal.FooterButtonGroup>
      </Modal.Content>
    </Modal.Root>
  );
};
