import classNames from "classnames";
import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import { InfoItem } from "components/DesignSystem/InfoItem/InfoItem";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { PriceInput } from "components/PriceInput/PriceInput";
import { INKLE_CREDITS, SOURCE_BANK } from "constants/invoiceSource";
import { BankLogos } from "dictionaries";
import { Form, Formik, FormikValues } from "formik";
import { refundSchema } from "formValidations/refundSchema";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  useInitiateRefundMutation,
  useLazyGetBillingInvoiceQuery,
} from "store/apis/billingInvoice";
import { RootState } from "store/store";
import { BillingInvoice, RefundDetails } from "types/Models/billing";
import { BackendError } from "types/utils/error";
import { RefundSuccess } from "./RefundSuccess";

export const RefundForm = () => {
  const { showBillingSlider, invoice } = useSelector(
    (store: RootState) => store.billingInvoiceSlider
  );
  const { alertToast } = useToast();
  const [proceedWithRefund, setProceedWithRefund] = useState(false);
  const [showRefundSuccess, setShowRefundSuccess] = useState(false);
  const [invoiceDetail, setInvoiceDetail] = useState<BillingInvoice>();
  const [initiateRefund, { isLoading: isRefundInitiating }] =
    useInitiateRefundMutation();
  const [getInvoiceDetail, { isLoading: isGettingInvoiceDetail }] =
    useLazyGetBillingInvoiceQuery();
  const cardDetail = invoice?.payment_card_details;
  const cardBrand = cardDetail?.brand || "default";
  const cardBrandIcon = BankLogos[cardBrand];
  const entityId = useCurrentEntityId();

  const handleSubmit = async (values: FormikValues) => {
    try {
      const { price, refundTo, refundReason } = values;
      await initiateRefund({
        groupId: invoice?.company_group.uuid as string,
        refund_amount: price,
        invoice_id: invoice?.uuid as string,
        refund_reason: refundReason,
        refund_destination: refundTo,
        refund_type: invoice?.paid_amount == price ? "FULL" : "PARTIAL",
      }).unwrap();

      const invoiceDetail = await getInvoiceDetail({
        groupId: invoice?.company_group?.uuid as string,
        invoiceId: invoice?.uuid!,
        entityId: entityId!,
      }).unwrap();
      setInvoiceDetail(invoiceDetail);
      setShowRefundSuccess(true);
    } catch (error) {
      alertToast(
        { message: (error as BackendError)?.data?.error?.message },
        error as {}
      );
    }
  };

  const sourceBankOption = cardDetail
    ? [{ label: "Original Payment Method", value: SOURCE_BANK }]
    : [];

  const refundSourceOptions = [
    ...sourceBankOption,
    { label: "Inkle Credits", value: INKLE_CREDITS },
  ];

  if (!invoice) {
    return null;
  }

  if (
    showRefundSuccess &&
    invoiceDetail &&
    Array.isArray(invoiceDetail?.refund_data)
  ) {
    return (
      <RefundSuccess
        refundDetails={invoiceDetail.refund_data[0]}
        adjustedAmount={invoiceDetail.refund_adjusted_amount}
      />
    );
  }

  return (
    <Formik
      initialValues={{
        price: invoice?.paid_amount || 0,
        refundTo: "",
        refundReason: "",
      }}
      onSubmit={handleSubmit}
      validationSchema={refundSchema({ amount: invoice.paid_amount })}
      validateOnBlur
    >
      {({ values, isValid, dirty }) => {
        const disableInitiateBtn =
          !isValid ||
          !dirty ||
          !proceedWithRefund ||
          isRefundInitiating ||
          isGettingInvoiceDetail;

        return (
          <Form className="all:unset">
            <div className="t-rounded-lg t-border-neutral-10 t-border t-border-solid t-m-5">
              <div className="!t-border-b t-border-0 t-border-neutral-10 t-p-3 t-border-solid t-text-subtext t-text-text-100">
                Refund details
              </div>
              <div className="t-p-4 t-flex t-gap-6 t-flex-col t-mt-3">
                <PriceInput
                  required
                  name="price"
                  label="Refund amount"
                  allowNegative={false}
                  value={Number(values.price)}
                  defaultValue={Number(values.price)}
                  note={
                    Boolean(invoice.credits_used)
                      ? `Note: $${invoice.credits_used} paid by Inkle Credits are non-refundable. `
                      : null
                  }
                />
                <TextInput
                  block
                  required
                  label="Refund reason"
                  name="refundReason"
                  placeholder="Type your reason here..."
                />
                <Combobox
                  name="refundTo"
                  withForm
                  required
                  label="Refund to"
                  placeholder="Select Source"
                  options={refundSourceOptions}
                />
                {values.refundTo === SOURCE_BANK && (
                  <InfoItem label="Payment Method">
                    <div className="t-text-subtext t-text-text-100 t-flex t-items-center t-gap-0.5">
                      <img
                        className="t-h-5"
                        src={cardBrandIcon}
                        alt="cardBrand"
                      />
                      **** {cardDetail?.last_four}
                    </div>
                  </InfoItem>
                )}
              </div>
            </div>
            <div
              className={classNames({
                "t-fixed t-bottom-0 t-w-[480px] t-bg-surface":
                  showBillingSlider,
              })}
            >
              <div className="t-p-5">
                <Checkbox
                  name="proceedWithRefund"
                  label="I confirm that I want to proceed with the refund."
                  checked={proceedWithRefund}
                  onChange={(e) => setProceedWithRefund(e.target.checked)}
                />
              </div>
              <div
                className={classNames({
                  "t-px-5 t-py-3 t-bg-surface t-shadow-tasks-slider-bottom t-w-full":
                    showBillingSlider,
                })}
              >
                <Button
                  customType="primary"
                  disabled={disableInitiateBtn}
                  isLoading={isRefundInitiating || isGettingInvoiceDetail}
                  type="submit"
                  block
                >
                  Refund
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
