export const InklePracticeProLogo = () => {
  return (
    <svg
      width="186"
      height="21"
      viewBox="0 0 186 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.432251 2.46511C0.432251 1.21221 1.21228 0.400146 2.43803 0.400146C3.64149 0.400146 4.42152 1.21221 4.42152 2.46511C4.42152 3.71801 3.64149 4.55328 2.43803 4.55328C1.21228 4.55328 0.432251 3.71801 0.432251 2.46511ZM4.22094 20.2841H0.655115V6.36302H4.22094V20.2841Z"
        fill="url(#paint0_linear_8954_3527)"
      />
      <path
        d="M15.1612 13.3004C15.1612 11.2586 14.136 9.91291 12.5537 9.91291C11.0605 9.91291 9.96844 11.3514 9.96844 13.3236V20.2841H6.40262V6.36302H9.96844V7.98715C10.4365 6.94307 11.8182 6.20061 13.2 6.20061C16.6098 6.20061 18.727 9.03124 18.727 13.3004V20.2841H15.1612V13.3004Z"
        fill="url(#paint1_linear_8954_3527)"
      />
      <path
        d="M24.4442 20.2841H20.8783V0.562559H24.4442V12.6043L28.9683 6.36302H32.8684L28.322 13.3236L33.3364 20.2841H28.8123L24.4442 14.0428V20.2841Z"
        fill="url(#paint2_linear_8954_3527)"
      />
      <path
        d="M38.2371 20.2841H34.6713V0.562559H38.2371V20.2841Z"
        fill="url(#paint3_linear_8954_3527)"
      />
      <path
        d="M49.0784 16.015H52.8671C51.9533 18.6136 49.5687 20.4001 46.8497 20.4001C42.9496 20.4001 40.2084 17.4535 40.2084 13.2772C40.2084 9.24005 43.0388 6.17741 46.7829 6.17741C50.527 6.17741 53.3574 9.24005 53.3574 13.2772C53.3574 13.718 53.3351 14.182 53.2682 14.5997H43.9525C44.3537 16.015 45.4457 16.9199 46.8275 16.9199C47.8749 16.9199 48.6995 16.5486 49.0784 16.015ZM43.9525 11.9315H49.6133C49.2121 10.5626 48.1646 9.68089 46.7829 9.68089C45.4011 9.68089 44.3537 10.5626 43.9525 11.9315Z"
        fill="url(#paint4_linear_8954_3527)"
      />
      <path
        d="M173.891 14.1406C173.891 10.6132 176.501 7.90137 179.886 7.90137C183.25 7.90137 185.84 10.6132 185.84 14.1406C185.84 17.6884 183.25 20.4003 179.886 20.4003C176.501 20.4003 173.891 17.6884 173.891 14.1406ZM179.886 10.0831C177.745 10.0831 176.093 11.8366 176.093 14.1406C176.093 16.465 177.745 18.1982 179.886 18.1982C182.027 18.1982 183.658 16.465 183.658 14.1406C183.658 11.8366 182.027 10.0831 179.886 10.0831Z"
        fill="#41395C"
      />
      <path
        d="M172.93 7.90137V10.0831C169.912 10.0627 168.179 11.6327 168.179 14.3853V20.2779H165.997V8.0441H168.179V10.552C169.158 8.47228 171.237 7.90137 172.93 7.90137Z"
        fill="#41395C"
      />
      <path
        d="M158.764 13.9985H154.767V20.2785H152.565V2.94727H158.58C162.107 2.94727 164.493 5.02702 164.493 8.43209C164.493 11.7556 162.148 13.9985 158.764 13.9985ZM158.58 5.12896H154.767V11.7964H158.764C160.803 11.7964 162.291 10.4099 162.291 8.43209C162.291 6.45429 160.782 5.12896 158.58 5.12896Z"
        fill="#41395C"
      />
      <path
        d="M142.786 16.5261H145.11C144.274 18.8097 142.133 20.3797 139.687 20.3797C136.18 20.3797 133.713 17.7903 133.713 14.1201C133.713 10.5723 136.261 7.88086 139.626 7.88086C142.969 7.88086 145.559 10.5519 145.559 14.0182C145.559 14.324 145.539 14.6298 145.477 14.9153H135.955C136.261 16.9543 137.709 18.3204 139.666 18.3204C141.094 18.3204 142.235 17.5864 142.786 16.5261ZM135.976 13.3045H143.316C143.01 11.3471 141.542 9.96061 139.626 9.96061C137.729 9.96061 136.282 11.3267 135.976 13.3045Z"
        fill="#41395C"
      />
      <path
        d="M126.244 10.0626C124.021 10.0626 122.512 11.6733 122.512 14.1201C122.512 16.5669 124.021 18.1777 126.244 18.1777C127.916 18.1777 129.2 17.2601 129.751 15.6493H132.055C131.423 18.6058 129.241 20.3797 126.244 20.3797C122.716 20.3797 120.31 17.8922 120.31 14.1201C120.31 10.3684 122.716 7.88086 126.244 7.88086C129.22 7.88086 131.423 9.65476 132.055 12.5909H129.751C129.18 10.9801 127.916 10.0626 126.244 10.0626Z"
        fill="#41395C"
      />
      <path
        d="M115.957 4.37428C115.957 3.59947 116.466 3.06934 117.282 3.06934C118.057 3.06934 118.567 3.59947 118.567 4.37428C118.567 5.16948 118.057 5.69961 117.282 5.69961C116.466 5.69961 115.957 5.16948 115.957 4.37428ZM118.383 20.2782H116.181V8.04442H118.383V20.2782Z"
        fill="#41395C"
      />
      <path
        d="M109.841 5.45508H111.492V8.04457H114.367V10.1243H111.492V16.0373C111.492 17.485 112.084 18.1171 113.47 18.1171H114.367V20.2784H113.266C110.473 20.2784 109.29 19.0958 109.29 16.1597V10.1243H107.394V8.04457H109.29L109.841 5.45508Z"
        fill="#41395C"
      />
      <path
        d="M99.935 10.0626C97.7125 10.0626 96.2037 11.6733 96.2037 14.1201C96.2037 16.5669 97.7125 18.1777 99.935 18.1777C101.607 18.1777 102.891 17.2601 103.442 15.6493H105.746C105.114 18.6058 102.932 20.3797 99.935 20.3797C96.4076 20.3797 94.0016 17.8922 94.0016 14.1201C94.0016 10.3684 96.4076 7.88086 99.935 7.88086C102.912 7.88086 105.114 9.65476 105.746 12.5909H103.442C102.871 10.9801 101.607 10.0626 99.935 10.0626Z"
        fill="#41395C"
      />
      <path
        d="M89.7358 20.2779V17.9943C89.1853 19.4215 87.4318 20.4003 85.5967 20.4003C82.0897 20.4003 79.7653 17.9127 79.7653 14.1406C79.7653 10.3889 82.0693 7.90137 85.5967 7.90137C87.4114 7.90137 89.1853 8.88007 89.7358 10.3277V8.0441H91.9175V20.2779H89.7358ZM81.9673 14.1406C81.9673 16.5874 83.5577 18.1982 85.8618 18.1982C88.1454 18.1982 89.7358 16.5874 89.7358 14.161C89.7358 11.755 88.1046 10.0831 85.8414 10.0831C83.5374 10.0831 81.9673 11.6939 81.9673 14.1406Z"
        fill="#41395C"
      />
      <path
        d="M78.7212 7.90137V10.0831C75.7035 10.0627 73.9704 11.6327 73.9704 14.3853V20.2779H71.7887V8.0441H73.9704V10.552C74.9491 8.47228 77.0288 7.90137 78.7212 7.90137Z"
        fill="#41395C"
      />
      <path
        d="M64.5558 13.9985H60.5594V20.2785H58.3574V2.94727H64.3723C67.8997 2.94727 70.2853 5.02702 70.2853 8.43209C70.2853 11.7556 67.9405 13.9985 64.5558 13.9985ZM64.3723 5.12896H60.5594V11.7964H64.5558C66.5948 11.7964 68.0832 10.4099 68.0832 8.43209C68.0832 6.45429 66.5744 5.12896 64.3723 5.12896Z"
        fill="#41395C"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8954_3527"
          x1="0.432251"
          y1="10.4001"
          x2="53.3574"
          y2="10.4001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8954_3527"
          x1="0.432251"
          y1="10.4001"
          x2="53.3574"
          y2="10.4001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_8954_3527"
          x1="0.432251"
          y1="10.4001"
          x2="53.3574"
          y2="10.4001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_8954_3527"
          x1="0.432251"
          y1="10.4001"
          x2="53.3574"
          y2="10.4001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_8954_3527"
          x1="0.432251"
          y1="10.4001"
          x2="53.3574"
          y2="10.4001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
