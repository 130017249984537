import { emptyApi } from "./emptyApi";
import { Profile } from "types/Models/user";

const extendedApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    sendOtpForMobile: build.mutation<
      { message: string },
      {
        mobile: string;
        whatsapp_consent?: boolean;
        email: string;
        accept_tos?: boolean;
      }
    >({
      query: ({ mobile, email }) => {
        return {
          url: `/api/inkle/users/change-mobile/`,
          method: "POST",
          body: { mobile, email },
        };
      },
    }),
    verifyMobileOtp: build.mutation<
      Profile,
      {
        payload: {
          mobile: string;
          profile_id: string;
          otp: string;
          coupon_code?: string;
          wa_consent: boolean;
          referral_code: string;
          current_timezone: string;
        };
      }
    >({
      query: ({ payload }) => {
        return {
          url: `/api/inkle/users/verifymobileotp/`,
          method: "POST",
          body: payload,
        };
      },
    }),
    sendOtpForEmail: build.mutation<{ message: string }, { email: string }>({
      query: ({ email }) => {
        return {
          url: `/api/inkle/users/change-email/`,
          method: "POST",
          body: { email },
        };
      },
    }),
    updateProfileEmailAndMobile: build.mutation<
      Profile,
      {
        operation: "EMAIL_UPDATE" | "MOBILE_UPDATE";
        otp?: string;
        token?: string;
        mobile?: string;
        email?: string;
      }
    >({
      query: ({ operation, otp, token, mobile, email }) => {
        return {
          url: `/api/inkle/users/profile/`,
          method: "PUT",
          body: { operation, otp, token, mobile, email },
        };
      },
    }),
    updateName: build.mutation<
      Profile,
      {
        payload: { first_name: string; last_name: string; profile_id: string };
      }
    >({
      query: ({ payload }) => {
        return {
          url: `/api/inkle/users/profile/update/`,
          method: "PUT",
          body: payload,
        };
      },
    }),
    profilePicUpdate: build.mutation<Profile, File>({
      query: (acceptedFiles) => {
        const formData = new FormData();
        formData.append("display_picture", acceptedFiles);
        return {
          url: `/api/inkle/users/display_picture/upload/`,
          method: "POST",
          body: formData,
        };
      },
    }),
  }),
});

export const {
  useUpdateProfileEmailAndMobileMutation,
  useSendOtpForEmailMutation,
  useSendOtpForMobileMutation,
  useUpdateNameMutation,
  useProfilePicUpdateMutation,
  useVerifyMobileOtpMutation,
} = extendedApi;
