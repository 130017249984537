import { Button } from "components/DesignSystem/Button/Button";
import { LinkIcon } from "components/icons/LinkIcon";
import { Redirect } from "components/icons/Redirect";
import { useHistory, useLocation } from "react-router-dom";
import { Invoice } from "store/apis/invoices";

const InvoiceItem = ({
  uuid,
  invoiceNumber,
}: {
  uuid: string;
  invoiceNumber: string;
}) => {
  const { search } = useLocation();
  const history = useHistory();
  return (
    <div className="t-flex t-justify-between t-gap-2 t-h-full">
      <div className="t-w-full t-p-2 t-border t-border-solid t-border-neutral-10 t-flex t-gap-2 t-rounded">
        <LinkIcon color="#585170" />
        <div className="t-text-body-sm t-text-text-100">{invoiceNumber}</div>
      </div>
      <Button
        customType="icon"
        size="small"
        onClick={() => history.push(`/books/invoicing/${uuid}${search}`)}
      >
        <Redirect size={16} />
      </Button>
    </div>
  );
};

export const LinkedInvoices = ({
  transactionId,
  invoices,
}: {
  transactionId: string;
  invoices: Invoice[];
}) => {
  return (
    <div className="t-flex t-flex-col t-gap-2">
      {invoices.map(({ uuid, invoice_number }) => (
        <InvoiceItem key={uuid} invoiceNumber={invoice_number} uuid={uuid} />
      ))}
    </div>
  );
};
