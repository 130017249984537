import Loader from "components/design/loader";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { Form, Formik } from "formik";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { useParams } from "react-router-dom";
import {
  useEditEntityStateDataMutation,
  useGetEntityStateDataQuery,
} from "store/apis/entity";
import { EntityDataAttribute } from "types/Models/entity";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";
import { ValueType } from "./AddJurisdiction";
import AddNewField from "./AddNewField";
import { FieldBuilder } from "./FieldBuilder";

const EditJurisdiction = ({
  close,
  isOpen,
  stateId,
  entityId: entityIdFromProps,
}: ModalProps & { stateId: string; entityId?: string }) => {
  const { isAdmin } = useRoleBasedView();
  const { successToast, alertToast } = useToast();
  const { entityId: entityIdFromParams } = useParams<{ entityId: string }>();
  const entityId = entityIdFromParams || entityIdFromProps || "";
  const { uuid: groupId } = useCurrentGroupContext();
  const [addEntityState] = useEditEntityStateDataMutation();

  const { data = [], isLoading } = useGetEntityStateDataQuery(
    {
      groupId,
      entityId: entityId!,
      stateId,
    },
    { skip: !groupId || !entityId || !stateId || !isOpen }
  );

  const initialValues = data.reduce(
    (acc, field) => {
      acc[field.name] = field.value;
      return acc;
    },
    { stateId } as Record<string, EntityDataAttribute["value"]>
  );

  const onSubmit = async (values: ValueType) => {
    const { stateId, ...rest } = values;
    try {
      await addEntityState({
        groupId,
        entityId,
        stateId: stateId as string,
        payload: rest,
      }).unwrap();
      successToast({ message: "Entity updated successfully" });
      close();
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  return (
    <Modal.Root onOpenChange={close} open={isOpen} modal={false}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ submitForm, isSubmitting, values }) => (
          <Modal.Content useCustomOverlay>
            <Modal.Header>
              <Modal.Title>Edit State</Modal.Title>
              <Modal.Close />
            </Modal.Header>
            <Modal.Body>
              {isLoading ? (
                <Loader />
              ) : (
                <Form className="t-flex t-flex-col t-gap-5 t-m-0">
                  <>
                    {data.map((field) => (
                      <FieldBuilder field={field} key={field.uuid} />
                    ))}
                    {isAdmin && values?.stateId && (
                      <AddNewField
                        stateId={values?.stateId as string}
                        entityId={entityId}
                      />
                    )}
                  </>
                </Form>
              )}
            </Modal.Body>
            <Modal.FooterButtonGroup>
              <Modal.RawClose asChild>
                <Button onClick={close} disabled={isSubmitting}>
                  Cancel
                </Button>
              </Modal.RawClose>
              <Button
                customType="primary"
                disabled={isSubmitting}
                isLoading={isSubmitting}
                onClick={submitForm}
              >
                Save
              </Button>
            </Modal.FooterButtonGroup>
          </Modal.Content>
        )}
      </Formik>
    </Modal.Root>
  );
};

export default EditJurisdiction;
