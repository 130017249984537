import { combineReducers, configureStore } from "@reduxjs/toolkit";
import chat from "store/slices/chat";
import { emptyApi } from "./apis/emptyApi";
import addonsSlice from "./slices/addons";
import billingFilters from "./slices/billingFilters";
import bookkeepingTracker from "./slices/bookkeepingTracker";
import booksOnboarding from "./slices/booksOnboarding";
import calendar from "./slices/calender";
import cartSubscription from "./slices/cartSubscription";
import channelInfoSlider from "./slices/channelInfoSlider";
import channelPendingActions from "./slices/channelPendingActions";
import channelPinnedItems from "./slices/channelPinnedItems";
import chartOfAccounts from "./slices/chartOfAccounts";
import chatSearch from "./slices/chatSearch";
import chatUnreadCount from "./slices/chatUnreadCount";
import ticket from "./slices/ticket";
import credits from "./slices/credit";
import dashboard from "./slices/dashboard";
import entitySlice from "./slices/entity";
import financialClosing from "./slices/financialClosing";
import ledgerFilter from "./slices/generalLedger";
import investorUpdate from "./slices/investorUpdates";
import invoiceModalTypes from "./slices/invoiceModalTypes";
import invoices from "./slices/invoices";
import messageForward from "./slices/messageForward";
import messageSearch from "./slices/messageSearch";
import messageToScrollTo from "./slices/messageToScrollTo";
import notification from "./slices/notification";
import onboarding from "./slices/onboarding";
import onboardingWidget from "./slices/onboardingWidget";
import openItem from "./slices/openItem";
import payment from "./slices/payment";
import perks from "./slices/perks";
import reconciliation from "./slices/reconciliation";
import registerAgent from "./slices/registerAgent";
import requestDocumentsInChat from "./slices/requestDocumentsInChat";
import reviewAndBalancePayment from "./slices/reviewAndBalancePayment";
import slack from "./slices/slack";
import subscriptionModal from "./slices/subscriptionModal";
import taskSlice from "./slices/task";
import task7004 from "./slices/task7004";
import taskList from "./slices/taskList";
import taskReview from "./slices/TaskReview";
import toast from "./slices/toast";
import transactionFilter from "./slices/transactionFilter";
import transactions from "./slices/transactions";
import tpPaymentsSlice from "./slices/transferpricing";
import vendors from "./slices/vendors";
import vendorsFilter from "./slices/vendorsFilter";
import raiseCrm from "./slices/raiseCrm";
import sidebar from "./slices/sidebar";
import investor from "./slices/investor";
import connections from "./slices/connections";
import serviceList from "./slices/serviceList";
import reportFilter from "./slices/reportFilter";
import ruleEngine from "./slices/ruleEngine";
import transactionsInChat from "./slices/transactionsInChat";
import transactionCardPreview from "./slices/transactionCardPreview";
import fixedAsset from "./slices/fixedAsset";
import similarTransactions from "./slices/similarTransactions";
import intragroupList from "./slices/IntragroupService";
import salexTaxList from "./slices/SalesTaxService";
import splitTransaction from "./slices/splitTransaction";
import billingInvoiceSlider from "./slices/billingInvoiceSlider";
import practiceBilling from "./slices/practiceBilling";
import linkInkleInvoice from "./slices/linkInkleInvoice";
import openComment from "./slices/openComment";

const rootReducer = combineReducers({
  [emptyApi.reducerPath]: emptyApi.reducer,
  subscriptionModal,
  chat,
  calendar,
  transactions,
  vendors,
  invoices,
  task7004,
  credits,
  onboarding,
  invoiceModalTypes,
  taskList,
  entitySlice,
  transactionFilter,
  tpPaymentsSlice,
  dashboard,
  notification,
  registerAgent,
  toast,
  ticket,
  chatSearch,
  investorUpdate,
  messageToScrollTo,
  chatUnreadCount,
  perks,
  addonsSlice,
  messageSearch,
  billingFilters,
  financialClosing,
  onboardingWidget,
  reviewAndBalancePayment,
  openItem,
  slack,
  taskReview,
  channelInfoSlider,
  channelPendingActions,
  channelPinnedItems,
  chartOfAccounts,
  requestDocumentsInChat,
  payment,
  reconciliation,
  cartSubscription,
  taskSlice,
  ledgerFilter,
  vendorsFilter,
  messageForward,
  bookkeepingTracker,
  raiseCrm,
  sidebar,
  investor,
  connections,
  booksOnboarding,
  serviceList,
  reportFilter,
  ruleEngine,
  transactionsInChat,
  transactionCardPreview,
  fixedAsset,
  similarTransactions,
  intragroupList,
  salexTaxList,
  splitTransaction,
  billingInvoiceSlider,
  practiceBilling,
  linkInkleInvoice,
  openComment,
});

export const setupStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(emptyApi.middleware),
  });
};

export const store = setupStore();

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = typeof store.dispatch;
