import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import cx from "classnames";
import { motion, AnimatePresence } from "framer-motion";
import { ComponentProps, ReactNode, Ref, forwardRef, useId } from "react";
import { ArrowRight } from "components/icons/ArrowRight";
import { Checkbox } from "../Checkbox/Checkbox";
import classNames from "classnames";

type ContentProps = {
  className?: string;
};

type ItemProps = {
  icon?: ReactNode;
  tag?: ReactNode;
  type?: "danger" | "ordinary";
};

export type DropdownItemProps = DropdownMenu.DropdownMenuItemProps &
  React.RefAttributes<HTMLDivElement> &
  ContentProps &
  ItemProps;

const Label = ({
  className = "",
  disabled,
  ...props
}: DropdownMenu.DropdownMenuLabelProps &
  React.RefAttributes<HTMLDivElement> &
  ContentProps & { disabled?: boolean }) => (
  <DropdownMenu.Label
    {...props}
    className={cx(
      "t-group t-w-full t-truncate !t-border-none t-px-3 t-py-2 t-font-medium t-text-subtext-sm",
      {
        [className]: className,
        "t-text-text-30": !disabled,
        "t-text-neutral-40": disabled,
      }
    )}
  />
);

const BaseItem = forwardRef(
  (
    {
      className = "",
      tag,
      icon,
      type,
      ...props
    }: ComponentProps<"div"> & ItemProps,
    ref: Ref<HTMLDivElement>
  ) => {
    return (
      <div
        ref={ref}
        className={cx(
          "t-group t-w-full t-cursor-pointer t-truncate !t-border-none t-px-3 t-py-2 hover:t-bg-surface-grey focus-visible:t-outline-none data-[disabled]:t-cursor-not-allowed data-[disabled]:t-text-neutral-30 data-[disabled]:hover:t-bg-surface-transparent data-[highlighted]:t-bg-surface-lighter-grey t-flex t-gap-1.5 t-items-center group-data-state-open:t-text-purple t-rounded",
          {
            [className]: className,
            "t-text-red": type === "danger",
            "hover:t-text-text-100 ": type === "ordinary",
          }
        )}
        {...props}
      >
        {icon && <span className="t-flex t-text-text-30">{icon}</span>}
        {props.children}
        <div className="t-ml-auto">{tag}</div>
      </div>
    );
  }
);

const Item = ({
  icon,
  tag,
  children,
  type = "ordinary",
  ...props
}: DropdownItemProps) => {
  return (
    <DropdownMenu.Item asChild {...props}>
      <BaseItem type={type} icon={icon} tag={tag}>
        {children}
      </BaseItem>
    </DropdownMenu.Item>
  );
};

const SubTrigger = ({
  className = "",
  icon,
  children,
  ...props
}: DropdownMenu.DropdownMenuSubTriggerProps &
  React.RefAttributes<HTMLDivElement> &
  ContentProps & { icon?: ReactNode }) => {
  return (
    <DropdownMenu.SubTrigger {...props}>
      <div className="t-group t-outline-none">
        <BaseItem icon={icon} tag={<ArrowRight />}>
          {children}
        </BaseItem>
      </div>
    </DropdownMenu.SubTrigger>
  );
};

const CheckboxItem = ({
  children,
  ...props
}: DropdownMenu.DropdownMenuCheckboxItemProps) => {
  const id = useId();

  return (
    <DropdownMenu.CheckboxItem
      asChild
      {...props}
      onSelect={(e) => e.preventDefault()}
    >
      <div className="t-outline-none">
        <label htmlFor={id} className="t-w-full">
          <BaseItem
            icon={
              <Checkbox
                id={id}
                checked={
                  typeof props.checked === "boolean" ? props.checked : undefined
                }
                indeterminate={props.checked === "indeterminate"}
                onChange={() => props.onCheckedChange?.(!props.checked)}
              />
            }
          >
            {children}
          </BaseItem>
        </label>
      </div>
    </DropdownMenu.CheckboxItem>
  );
};

const Separator = () => (
  <DropdownMenu.Separator asChild>
    <div className="t-my-2 t-mx-3 t-border-t t-border-0 t-border-solid t-border-neutral-20" />
  </DropdownMenu.Separator>
);

const Content = ({
  className = "",
  children,
  ...props
}: DropdownMenu.DropdownMenuContentProps &
  React.RefAttributes<HTMLDivElement> &
  ContentProps) => {
  return (
    <DropdownMenu.Content {...props} asChild>
      <motion.div
        className={cx(
          "t-z-dropdown t-relative t-border-solid t-min-w-56 t-border t-border-neutral-10 t-shadow-light-100 t-rounded-md t-bg-surface t-text-body t-overflow-auto t-p-1",
          {
            [className]: className,
          }
        )}
        initial={{ y: -10 }}
        animate={{ y: 0 }}
        exit={{ y: -10 }}
      >
        {children}
      </motion.div>
    </DropdownMenu.Content>
  );
};

const Portal = (props: DropdownMenu.DropdownMenuPortalProps) => (
  <AnimatePresence>
    <DropdownMenu.Portal {...props} />
  </AnimatePresence>
);

const SubContent = ({
  className = "",
  children,
  ...props
}: DropdownMenu.DropdownMenuSubContentProps &
  React.RefAttributes<HTMLDivElement> &
  ContentProps) => {
  return (
    <DropdownMenu.SubContent {...props} asChild>
      <motion.div
        className={cx(
          "t-z-dropdown t-border-solid t-border t-border-neutral-10 t-shadow-light-100 t-rounded-md t-bg-surface t-text-body t-overflow-auto t-min-w-56 t-p-1",
          {
            [className]: className,
          }
        )}
        initial={{ y: -10 }}
        animate={{ y: 0 }}
        exit={{ y: -10 }}
      >
        {children}
      </motion.div>
    </DropdownMenu.SubContent>
  );
};

const FooterButtonGroup = ({
  children,
  direction = "horizontal",
}: {
  children: ReactNode;
  direction?: "horizontal" | "vertical";
}) => (
  <div
    className={classNames(
      "t-flex t-gap-2 t-items-center t-py-2 t-px-3 t-w-full",
      {
        "t-flex-col": direction === "vertical",
      }
    )}
  >
    {children}
  </div>
);

const Dropdown = {
  ...DropdownMenu,
  Portal: Portal,
  Item: Item,
  Content: Content,
  Label,
  SubContent,
  Separator,
  SubTrigger,
  CheckboxItem,
  FooterButtonGroup,
  BaseItem,
};

export default Dropdown;
