import classNames from "classnames";
import { ArrowLeft } from "components/icons/ArrowLeft";
import { CaretDown } from "components/icons/CaretDown";
import { CaretRight } from "components/icons/CaretRight";
import { DD_MMM_YYYY, FILTER_DATE_INPUT_FORMAT } from "constants/date";
import dayjs from "dayjs";
import { Field, FieldProps, getIn, useFormikContext } from "formik";
import { MouseEventHandler, ReactNode, useState } from "react";
import ReactDatePicker, {
  ReactDatePickerCustomHeaderProps,
  ReactDatePickerProps,
} from "react-datepicker";
import { Button } from "../Button/Button";
import Select from "../Select/Select";
import { BareInput, InputProps, Label } from "../TextInput/TextInput";

export const checkForMonthDisable = ({
  date,
  minDate,
  maxDate,
}: {
  date: Date;
  minDate: Date | null | undefined;
  maxDate: Date | null | undefined;
}) => {
  const currentYear = Number(dayjs(date).format("YYYY"));
  const maxDateYear = maxDate && Number(dayjs(maxDate).format("YYYY"));
  const minDateYear = minDate && Number(dayjs(minDate).format("YYYY"));
  const maxDateMonth = maxDate && Number(dayjs(maxDate).format("MM")) - 1;
  const minDateMonth = minDate && Number(dayjs(minDate).format("MM")) - 1;
  const disableMaxMonthYear = maxDateYear === currentYear;
  const disableMinMonthYear = minDateYear === currentYear;

  return {
    disableMaxMonthYear,
    disableMinMonthYear,
    minDateMonth,
    maxDateMonth,
  };
};

const MonthName = ({ month }: { month: number }) => {
  return (
    <>
      {new Date(2022, month, 1).toLocaleString("default", {
        month: "short",
      })}{" "}
    </>
  );
};

type DateInputProps = {
  dateValue: string;
  label?: ReactNode;
  onDateChange?: (date: string) => void;
  withForm?: boolean;
} & InputProps &
  Omit<ReactDatePickerProps, "onChange">;

const NavigationButton = ({
  onClick,
  disabled,
}: {
  onClick: MouseEventHandler<HTMLButtonElement>;
  disabled: boolean;
}) => {
  return (
    <Button
      customType="ghost_icon"
      onClick={onClick}
      type="button"
      size="small"
      disabled={disabled}
    >
      <span
        className={classNames("t-rotate-90", { "t-text-neutral": !disabled })}
      >
        <CaretDown size="14" />
      </span>
    </Button>
  );
};

const Months = ({
  onSelect,
  monthDate,
  maxDate,
  minDate,
}: {
  onSelect: (selectedMonth: number) => void;
  monthDate: Date;
  maxDate: Date | null | undefined;
  minDate: Date | null | undefined;
}) => {
  const currentMonth = new Date(monthDate).getMonth();

  const {
    disableMaxMonthYear,
    disableMinMonthYear,
    minDateMonth,
    maxDateMonth,
  } = checkForMonthDisable({
    date: monthDate,
    minDate,
    maxDate,
  });

  return (
    <span id="month-selector">
      <Select.Root
        appearance="borderless"
        size="small"
        key={currentMonth}
        onOpenChange={(open) => {
          if (open) {
            setTimeout(() => {
              document.body.style.pointerEvents = "auto";
            });
          }
        }}
        onValueChange={(month) => onSelect(Number(month))}
        defaultValue={currentMonth.toString()}
      >
        <Select.Trigger>
          <Select.Value asChild>
            <span className="t-text-subtext-sm">
              <MonthName month={currentMonth} />
            </span>
          </Select.Value>
        </Select.Trigger>

        <Select.Content
          className="t-max-h-60"
          container={document.getElementById("month-selector")}
        >
          {Array.from({ length: 12 }, (_, index) => index).map((month) => (
            <Select.Item
              key={month}
              value={month.toString()}
              disabled={
                (month > maxDateMonth! && disableMaxMonthYear) ||
                (month < minDateMonth! && disableMinMonthYear)
              }
              className="!t-text-subtext-sm"
            >
              <MonthName month={month} />
            </Select.Item>
          ))}
        </Select.Content>
      </Select.Root>
    </span>
  );
};

const Years = ({
  onSelect,
  monthDate,
  maxDate,
  minDate,
}: {
  onSelect: (selectedMonth: number) => void;
  monthDate: Date;
  maxDate: Date | null | undefined;
  minDate: Date | null | undefined;
}) => {
  const currentYear = new Date(monthDate).getFullYear();

  const maxDateYear = maxDate && Number(dayjs(maxDate).format("YYYY"));
  const minDateYear = minDate && Number(dayjs(minDate).format("YYYY"));

  return (
    <span id="year-selector">
      <Select.Root
        appearance="borderless"
        size="small"
        key={currentYear}
        onOpenChange={(open) => {
          if (open) {
            setTimeout(() => {
              document.body.style.pointerEvents = "auto";
            });
          }
        }}
        onValueChange={(year) => onSelect(Number(year))}
        defaultValue={currentYear.toString()}
      >
        <Select.Trigger>
          <Select.Value />
          <Select.Value asChild>
            <span className="t-text-subtext-sm">{currentYear}</span>
          </Select.Value>
        </Select.Trigger>
        <Select.Content
          className="t-max-h-60"
          container={document.getElementById("year-selector")}
        >
          {Array.from({ length: 201 }, (_, index) => 2000 - 100 + index).map(
            (year) => (
              <Select.Item
                key={year}
                value={year.toString()}
                disabled={year > maxDateYear! || year < minDateYear!}
                className="!t-text-subtext-sm"
              >
                {year}
              </Select.Item>
            )
          )}
        </Select.Content>
      </Select.Root>
    </span>
  );
};

const HeaderStyledWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <div className="t-flex t-justify-between t-items-center t-mb-2 t-py-3 t-px-4 t-border-0 t-border-b t-border-solid t-border-neutral-10">
      {children}
    </div>
  );
};

export const CustomHeader = ({
  showYearDropdown,
  showMonthDropdown,
  showMonthYearPicker,
  showQuarterYearPicker,
  maxDate,
  showYearPicker,
  minDate,
  ...props
}: ReactDatePickerCustomHeaderProps & {
  showYearDropdown?: boolean;
  showMonthDropdown?: boolean;
  showMonthYearPicker?: boolean;
  showQuarterYearPicker?: boolean;
  showYearPicker?: boolean;
  maxDate: Date | null | undefined;
  minDate: Date | null | undefined;
}) => {
  if (showMonthYearPicker || showQuarterYearPicker) {
    return (
      <HeaderStyledWrapper>
        <Years
          onSelect={props.changeYear}
          monthDate={props.monthDate}
          {...{ maxDate, minDate }}
        />
        <span className="t-flex t-item-center">
          <NavigationButton
            onClick={props.decreaseYear}
            disabled={props.prevYearButtonDisabled}
          />
          <span className="t-rotate-180">
            <NavigationButton
              onClick={props.increaseYear}
              disabled={props.nextYearButtonDisabled}
            />
          </span>
        </span>
      </HeaderStyledWrapper>
    );
  }

  if ((showMonthYearPicker || showQuarterYearPicker) && showYearDropdown) {
    return (
      <HeaderStyledWrapper>
        <Years
          onSelect={props.changeYear}
          monthDate={props.monthDate}
          {...{ maxDate, minDate }}
        />
      </HeaderStyledWrapper>
    );
  }

  if (showYearPicker) {
    return (
      <HeaderStyledWrapper>
        <div></div>
        <span className="t-flex t-item-center">
          <NavigationButton
            onClick={props.decreaseYear}
            disabled={props.prevYearButtonDisabled}
          />
          <span className="t-rotate-180">
            <NavigationButton
              onClick={props.increaseYear}
              disabled={props.nextYearButtonDisabled}
            />
          </span>
        </span>
      </HeaderStyledWrapper>
    );
  }

  if (showMonthDropdown && showYearDropdown) {
    return (
      <HeaderStyledWrapper>
        <div className="t-flex t-justify-center t-items-center t-gap-1">
          <Months
            onSelect={props.changeMonth}
            monthDate={props.monthDate}
            {...{ maxDate, minDate }}
          />
          <Years
            onSelect={props.changeYear}
            monthDate={props.monthDate}
            {...{ maxDate, minDate }}
          />
        </div>
        <span className="t-flex t-item-center">
          <NavigationButton
            onClick={props.decreaseMonth}
            disabled={props.prevMonthButtonDisabled}
          />
          <span className="t-rotate-180">
            <NavigationButton
              onClick={props.increaseMonth}
              disabled={props.nextMonthButtonDisabled}
            />
          </span>
        </span>
      </HeaderStyledWrapper>
    );
  }

  if (showMonthDropdown) {
    return (
      <HeaderStyledWrapper>
        <span className="t-text-subtext-sm t-text-text-black t-flex t-gap-1 t-items-center">
          <Months
            onSelect={props.changeMonth}
            monthDate={props.monthDate}
            {...{ maxDate, minDate }}
          />
          {dayjs(props.monthDate).format("YYYY")}
        </span>
        <span className="t-flex t-item-center">
          <NavigationButton
            onClick={props.decreaseYear}
            disabled={props.prevYearButtonDisabled}
          />
          <span className="t-rotate-180">
            <NavigationButton
              onClick={props.increaseYear}
              disabled={props.nextYearButtonDisabled}
            />
          </span>
        </span>
      </HeaderStyledWrapper>
    );
  }

  if (showYearDropdown) {
    return (
      <HeaderStyledWrapper>
        <span className="t-text-subtext-sm t-text-text-black t-flex t-gap-1 t-items-center">
          {dayjs(props.monthDate).format("MMMM")}
          <Years
            onSelect={props.changeYear}
            monthDate={props.monthDate}
            {...{ maxDate, minDate }}
          />
        </span>
        <span className="t-flex t-item-center">
          <NavigationButton
            onClick={props.decreaseMonth}
            disabled={props.prevMonthButtonDisabled}
          />
          <span className="t-rotate-180">
            <NavigationButton
              onClick={props.increaseMonth}
              disabled={props.nextMonthButtonDisabled}
            />
          </span>
        </span>
      </HeaderStyledWrapper>
    );
  }

  return (
    <HeaderStyledWrapper>
      <span className="t-text-subtext-sm t-text-text-black">
        {dayjs(props.monthDate).format("MMM YYYY")}
      </span>
      <span className="t-flex t-item-center">
        <NavigationButton
          onClick={props.decreaseMonth}
          disabled={props.prevMonthButtonDisabled}
        />
        <span className="t-rotate-180">
          <NavigationButton
            onClick={props.increaseMonth}
            disabled={props.nextMonthButtonDisabled}
          />
        </span>
      </span>
    </HeaderStyledWrapper>
  );
};
