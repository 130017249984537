import { Button } from "components/DesignSystem/Button/Button";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import Modal from "components/DesignSystem/Modal/Modal";
import { DD_MMM_YYYY, YYYY_MM_DD } from "constants/date";
import dayjs from "dayjs";
import { Field, FieldProps, Form, Formik, FormikValues } from "formik";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetStartingDateQuery,
  useUpdateStartDateMutation,
} from "store/apis/chartOfAccounts";
import { setBookingStartDateModal } from "store/slices/chartOfAccounts";
import { RootState } from "store/store";
import { BackendError } from "types/utils/error";

export const StartDateModal = () => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { alertToast, successToast } = useToast();
  const dispatch = useDispatch();
  const { isBookkeepingStartDateModalOpen } = useSelector(
    (state: RootState) => state.chartOfAccounts
  );

  const [updateStartDate, { isLoading }] = useUpdateStartDateMutation();

  const { data: startDateData } = useGetStartingDateQuery(
    {
      groupId,
      entityId,
    },
    { skip: !groupId || !entityId }
  );

  const { start_date } = startDateData || {};

  const onSubmit = async (values: FormikValues) => {
    try {
      await updateStartDate({
        groupId,
        entityId,
        startDate: dayjs(values.start_date).format(YYYY_MM_DD),
      }).unwrap();
      close();
      successToast({ message: "Starting date updated" });
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const close = () => {
    dispatch(setBookingStartDateModal(false));
  };

  return (
    <Modal.Root
      open={isBookkeepingStartDateModalOpen}
      onOpenChange={close}
      modal={false}
    >
      <Formik
        initialValues={{ start_date: dayjs(start_date).format(DD_MMM_YYYY) }}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ isSubmitting, submitForm }) => (
          <Modal.Content useCustomOverlay asChild>
            <Form className="t-w-full t-m-0">
              <Modal.Header>
                <Modal.Title>Edit Starting Date</Modal.Title>
                <Modal.Close />
              </Modal.Header>
              <Modal.Body>
                <Field name="start_date">
                  {({ field }: FieldProps) => {
                    return (
                      <DateInput
                        {...field}
                        label="Starting date"
                        placeholder="Select date"
                        block
                        portalId="coa-start-date"
                      />
                    );
                  }}
                </Field>
              </Modal.Body>
              <Modal.FooterButtonGroup>
                <Button onClick={close}>Cancel</Button>
                <Button
                  customType="primary"
                  isLoading={isLoading || isSubmitting}
                  disabled={isLoading || isSubmitting}
                  onClick={submitForm}
                >
                  Confirm
                </Button>
              </Modal.FooterButtonGroup>
            </Form>
          </Modal.Content>
        )}
      </Formik>
    </Modal.Root>
  );
};
