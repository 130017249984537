import { array, object, string } from "yup";

export const chatInviteValidation = object({
  team: string().when("type", {
    is: "NEW_TEAM",
    then: string().required("Please select a Team"),
    otherwise: string().notRequired(),
  }),
  members: array()
    .of(string())
    .when("type", {
      is: "NEW_MEMBER",
      then: array().of(string()).min(1, "Please select at least one member"),
      otherwise: array().of(string()).nullable().notRequired(),
    }),
});
