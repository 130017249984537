import { AmountSuperScript } from "components/design/AmountSuperScript";
import { Divider } from "components/design/Divider";
import { Button } from "components/DesignSystem/Button/Button";
import Table from "components/DesignSystem/Table/V2/Table";
import { Label } from "components/DesignSystem/TextInput/TextInput";
import { DeleteIcon } from "components/icons/delete";
import { DD_MMM_YYYY } from "constants/date";
import dayjs from "dayjs";
import { getIn, useFormikContext } from "formik";
import { AnimatePresence, motion } from "framer-motion";
import { useMemo, useState } from "react";
import {
  CellContext,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import { Transactions } from "types/Models/books";
import { Transaction } from "../AddTransaction/AddTransactionManuallyModal";
import {
  Amount,
  Description,
  Invoice,
} from "../AddTransaction/TransactionTable";
import { CategorySelector } from "components/CategorySelector/CategorySelector";
import { Vendor } from "components/JournalEntry/AddJournalEntryTable";
import { LinkTransaction, SelectedRow } from "../LinkTransaction";
import { useModal } from "hooks/useModal";
import { Link } from "components/icons/Link";
import Modal from "components/DesignSystem/Modal/Modal";
import { TransactionsView } from "components/TransactionsView/TransactionsView";
import { useGetAllTransactionsDataQuery } from "store/apis/transactions";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useChartOfAccounts } from "hooks/useChartOfAccounts";
import { flattenTypes } from "utils/flattenCOA";
import { useGetMerchantByIdQuery } from "store/apis/merchant";
import { useGetTransaction } from "hooks/useGetTransaction";

const Delete = (info: CellContext<Transaction, number>) => {
  const { id } = info.row.original;

  const { values, setValues } = useFormikContext<{
    transactions: Transaction[];
  }>();

  const onDelete = () => {
    const newValues = values.transactions.filter(
      (transaction) => transaction.id !== id
    );
    setValues({ transactions: newValues });
  };
  const disableDelete = values.transactions.length <= 2;

  return (
    <Button customType="ghost_icon" onClick={onDelete} disabled={disableDelete}>
      <DeleteIcon />
    </Button>
  );
};

const TransactionInfo = ({
  label,
  value,
}: {
  label: string;
  value: string;
}) => {
  return (
    <div className="t-flex t-flex-col t-gap-1">
      <div className="t-text-body-sm t-text-text-30">{label}</div>
      <div className="t-text-body t-text-text-100">{value}</div>
    </div>
  );
};

const Category = (info: CellContext<Transaction, string>) => {
  const { values, setFieldValue } = useFormikContext<{
    transactions: Transaction[];
  }>();
  const unlinkModal = useModal();
  const [intermediatroyCategory, setIntermediateCategory] = useState<
    string | null
  >(null);
  const groupId = useCurrentGroupContext()?.uuid;

  const [newCategory, setNewCategory] = useState<{
    label: string;
    value: string;
    categoryType: string;
    data: string;
  } | null>(null);

  const id = info.row.id;
  const { category = "" } = info.row.original || {};
  const linkTransactionModal = useModal();

  const selectedCategories: string[] = values.transactions.reduce(
    (categories: string[], transaction) =>
      transaction.category && transaction.id - 1 !== Number(id)
        ? [...categories, transaction.category]
        : categories,
    []
  );

  const alreadyLinkedTransactions = values.transactions.reduce(
    (linkedTransactions: string[], transaction) =>
      transaction.linked_transaction && transaction.id - 1 !== Number(id)
        ? [...linkedTransactions, transaction.linked_transaction]
        : linkedTransactions,
    []
  );

  const currentTransaction = info.row.original;
  const parentTransaction = // @ts-ignore
    info.table.options.meta?.transaction as Transactions;

  const { data: merchantInfo } = useGetMerchantByIdQuery(
    {
      groupId: groupId,
      merchantId: currentTransaction.merchant,
    },
    {
      skip: !groupId,
    }
  );

  const { chartOfAccounts = [] } = useChartOfAccounts({
    hiddenCategoryTypes: ["BANK_ACCOUNT"],
  });

  const currentCategory = flattenTypes({
    accounts: chartOfAccounts,
  }).find(({ uuid }) => uuid === category);

  const onChange = (
    value: {
      label: string;
      value: string;
      categoryType: string;
      data: string;
    } | null
  ) => {
    const { categoryType, data } = value || {};

    if (
      (currentCategory?.category_type === "BANK_TRANSFER" ||
        currentCategory?.category_type === "PAY_DOWN_CREDIT") &&
      categoryType !== "BANK_TRANSFER" &&
      categoryType !== "PAY_DOWN_CREDIT" &&
      info.row.original.linked_transaction
    ) {
      setIntermediateCategory(value?.value || null);
      return unlinkModal.open();
    }

    const suggestedCategoryCheck =
      categoryType === "SUGGESTED" &&
      (data?.includes("Bank Transfer") || data?.includes("Pay Down Credit"));

    if (
      categoryType === "BANK_TRANSFER" ||
      categoryType === "PAY_DOWN_CREDIT" ||
      suggestedCategoryCheck
    ) {
      setNewCategory(value);
      return linkTransactionModal.open();
    }

    setFieldValue(`transactions[${id}].category`, value?.value);
  };

  const onSelect = (transaction: Transaction) => {
    const currentAmount = getIn(values, `transactions[${id}].amount`);

    if (
      currentAmount === 0 &&
      newCategory?.categoryType === "PAY_DOWN_CREDIT"
    ) {
      setFieldValue(`transactions[${id}].amount`, transaction.amount);
    }

    if (currentAmount === 0 && newCategory?.categoryType === "BANK_TRANSFER") {
      setFieldValue(`transactions[${id}].amount`, -transaction.amount);
    }

    setFieldValue(`transactions[${id}.linked_transaction`, transaction.uuid);
    setFieldValue(`transactions[${id}].category`, newCategory?.value);
    setNewCategory(null);
    linkTransactionModal.close();
  };

  const onLinkClose = () => {
    setNewCategory(null);
    linkTransactionModal.close();
  };

  const onUnlinkClose = () => {
    setIntermediateCategory(null);
    unlinkModal.close();
  };

  const onUnlink = () => {
    setIntermediateCategory(null);
    setFieldValue(`transactions[${id}].linked_transaction`, null);
    setFieldValue(`transactions[${id}].category`, intermediatroyCategory);
    unlinkModal.close();
  };

  return (
    <>
      <CategorySelector
        category={category}
        hiddenCategories={selectedCategories}
        name={`transactions[${id}].category`}
        onChange={onChange}
        withForm={false}
      />

      {newCategory && (
        <LinkTransaction
          isLinkTransactionOpen={linkTransactionModal.isOpen}
          closeLinkTransaction={onLinkClose}
          // @ts-ignore
          onSelect={onSelect}
          excludeTransactionIds={alreadyLinkedTransactions}
          sourceTransaction={{
            transactionInfo: {
              amount: currentTransaction.amount,
              from: parentTransaction.transaction.from,
              logo: parentTransaction.transaction.logo,
              merchant: merchantInfo?.name,
              date: parentTransaction.transaction.date,
              uuid: parentTransaction.transaction.uuid,
            },
            category: {
              ...newCategory,
              categoryName: newCategory.label,
              categoryId: newCategory.value,
            },
          }}
        />
      )}
      {info.row.original.linked_transaction && (
        <Modal.Root open={unlinkModal.isOpen} onOpenChange={onUnlinkClose}>
          <Modal.Content size="xxxl">
            <Modal.Header>
              <Modal.Title>Linked transaction</Modal.Title>
              <Modal.Close />
            </Modal.Header>
            <UnlinkTransactionModal
              onUnlink={onUnlink}
              merchantId={info.row.original.merchant}
              categoryId={info.row.original.category}
              transactionId={info.row.original.linked_transaction}
              {...info.row.original}
            />
          </Modal.Content>
        </Modal.Root>
      )}
    </>
  );
};

const UnlinkTransactionModal = ({
  from,
  logo,
  amount,
  date,
  transactionId,
  merchantId,
  categoryId,
  onUnlink,
}: Pick<
  Transactions["transaction"],
  "amount" | "from" | "logo" | "merchant" | "date"
> & {
  transactionId: string;
  merchantId: string;
  categoryId: string;
  onUnlink: () => void;
}) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();

  const { data: transactionData } = useGetTransaction({
    transactionId,
  });

  const { data: merchantInfo } = useGetMerchantByIdQuery(
    {
      groupId: groupId,
      merchantId: merchantId,
    },
    {
      skip: !groupId,
    }
  );

  const { chartOfAccounts = [] } = useChartOfAccounts({
    hiddenCategoryTypes: ["BANK_ACCOUNT"],
  });

  const currentCategory = flattenTypes({
    accounts: chartOfAccounts,
  }).find(({ uuid }) => uuid === categoryId);

  return (
    <>
      <Modal.Body>
        <div className="t-flex t-flex-col t-gap-8">
          <SelectedRow
            from={from}
            merchant={merchantInfo?.name}
            category={currentCategory?.name}
            amount={amount}
            date={date}
            logo={logo}
          />
          {transactionData?.transaction && (
            <div className="t-px-3">
              <TransactionsView transactions={[transactionData?.transaction]} />
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.FooterButtonGroup>
        <Modal.RawClose asChild>
          <Button>Cancel</Button>
        </Modal.RawClose>
        <Button onClick={onUnlink} customType="primary">
          Unlink
        </Button>
      </Modal.FooterButtonGroup>
    </>
  );
};

const UnLinkTransaction = (info: CellContext<Transaction, string>) => {
  const unlinkModal = useModal();
  const linkedTransaction = info.getValue();
  const { setFieldValue } = useFormikContext<{
    transactions: Transaction[];
  }>();

  if (!linkedTransaction) {
    return null;
  }

  const onUnlink = () => {
    setFieldValue(`transactions[${info.row.id}].linked_transaction`, null);
    setFieldValue(`transactions[${info.row.id}].category`, null);
    unlinkModal.close();
  };

  return (
    <Modal.Root open={unlinkModal.isOpen} onOpenChange={unlinkModal.toggle}>
      <Modal.Trigger asChild>
        <Button customType="ghost_icon">
          <span className="t-text-purple">
            <Link color="currentColor" />
          </span>
        </Button>
      </Modal.Trigger>
      <Modal.Content size="xxxl">
        <Modal.Header>
          <Modal.Title>Unlink Transaction</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <UnlinkTransactionModal
          onUnlink={onUnlink}
          merchantId={info.row.original.merchant}
          categoryId={info.row.original.category}
          transactionId={linkedTransaction}
          {...info.row.original}
        />
      </Modal.Content>
    </Modal.Root>
  );
};

export const SplitTransactionTable = ({
  transaction,
  isDeleteSplitTxnsFlow,
  totalAmount,
  amountDifference,
  errors,
}: {
  transaction: Transactions | {};
  isDeleteSplitTxnsFlow: boolean;
  totalAmount: number;
  amountDifference: number;
  errors?: { [key: string]: string | null };
}) => {
  const { setFieldValue, values } = useFormikContext<{
    transactions: Transaction[];
  }>();
  const showDifference = amountDifference !== 0;

  const addAnotherTransaction = () => {
    const newValues = [
      ...values.transactions,
      {
        id: values.transactions.length + 1,
        merchant: "",
        category: "",
        description: "",
        amount: 0,
        invoice: null,
      },
    ];
    setFieldValue("transactions", newValues);
  };

  const createColumn = createColumnHelper<Transaction>();

  const columns = useMemo(
    () => [
      createColumn.accessor("merchant", {
        size: 20,
        header: () => <Label>VENDOR</Label>,
        cell: (info) => {
          const id = info.row.id;
          const merchant = info.getValue();

          return <Vendor id={id} merchant={merchant} creatable />;
        },
      }),

      createColumn.accessor("description", {
        size: 20,
        header: () => <Label required>DESCRIPTION</Label>,
        cell: Description,
      }),

      createColumn.accessor("category", {
        size: 20,
        header: () => <Label required>Category</Label>,
        cell: Category,
      }),

      createColumn.accessor("amount", {
        size: 20,
        header: () => (
          <div className="t-flex t-justify-end">
            <Label required>AMOUNT</Label>
          </div>
        ),
        cell: Amount,
      }),

      createColumn.accessor("invoice", {
        size: 5,
        header: "",
        cell: (info) => <Invoice info={info} size="sm" />,
      }),

      createColumn.accessor("id", {
        size: 5,
        header: "",
        cell: Delete,
      }),
      createColumn.accessor("linked_transaction", {
        size: 5,
        header: "",
        cell: UnLinkTransaction,
      }),
    ],
    [values.transactions]
  );

  const table = useReactTable({
    data: values.transactions,
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 1,
    },
    meta: {
      transaction,
      errors,
    },
  });

  if (!("transaction" in transaction)) {
    return null;
  }

  return (
    <div className="t-flex t-flex-col t-gap-4">
      <div className="t-text-subtitle t-text-text-100 t-flex t-gap-2">
        <span>Transaction Amount:</span>
        <AmountSuperScript amount={transaction.transaction?.amount} />
      </div>
      <div className="t-flex t-gap-12">
        <TransactionInfo
          label="Date"
          value={dayjs(transaction.transaction.date).format(DD_MMM_YYYY) || "-"}
        />
        <TransactionInfo
          label="Source"
          value={transaction.transaction.from?.source || "-"}
        />
        <TransactionInfo
          label="Vendor"
          value={transaction.transaction.merchant || "-"}
        />
        <TransactionInfo
          label="Description"
          value={transaction.transaction.description || "-"}
        />
      </div>
      <Divider />
      <Table.Container>
        <Table.Content>
          <Table.Head>
            {table.getHeaderGroups().map((headerGroup) => (
              <Table.Row key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Table.HeadCell
                    key={header.id}
                    style={{ width: `${header.getSize()}%` }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </Table.HeadCell>
                ))}
              </Table.Row>
            ))}
          </Table.Head>
          <Table.Body>
            <AnimatePresence>
              {table.getRowModel().rows.map((row) => (
                <motion.tr
                  layout
                  key={row.original.id}
                  transition={{ duration: 0.1, ease: "easeOut" }}
                  animate={{ x: 0 }}
                  exit={{ x: -1000 }}
                  className="t-px-3 t-border-solid t-border-neutral-0 t-border-b t-border-0 t-text-body"
                >
                  {row.getVisibleCells().map((cell) => (
                    <Table.Cell
                      key={cell.id}
                      style={{ width: `${cell.column.getSize()}%` }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Table.Cell>
                  ))}
                </motion.tr>
              ))}
              <motion.tr
                layout
                key="total amount"
                transition={{ duration: 0.1, ease: "easeOut" }}
                animate={{ x: 0 }}
                exit={{ x: -1000 }}
                className="t-px-4"
              >
                <Table.Cell key="amount" style={{ width: "20%" }}></Table.Cell>
                <Table.Cell key="amount" style={{ width: "20%" }}></Table.Cell>
                <Table.Cell key="amount" style={{ width: "20%" }}></Table.Cell>
                <Table.Cell key="total" style={{ width: "20%" }}>
                  <div className="t-w-full t-pr-3.5 t-text-subtitle-sm t-text-text-30 t-pt-2 t-flex t-gap-6 t-justify-end">
                    <div className="t-flex t-flex-col t-gap-3">
                      <div>Total</div>
                      {showDifference && (
                        <div className="t-text-red">Difference</div>
                      )}
                    </div>
                    <div className="t-flex t-flex-col t-gap-3 t-items-end">
                      <AmountSuperScript amount={totalAmount} />
                      {showDifference && (
                        <div className="t-text-red">
                          <AmountSuperScript amount={amountDifference} />
                        </div>
                      )}
                    </div>
                  </div>
                </Table.Cell>
              </motion.tr>
            </AnimatePresence>
          </Table.Body>
        </Table.Content>
      </Table.Container>

      {!isDeleteSplitTxnsFlow && (
        <div className="t-mt-2 t-bg-white t-py-4 t-sticky t-bottom-0">
          <Button size="small" type="button" onClick={addAnotherTransaction}>
            Add more
          </Button>
        </div>
      )}
    </div>
  );
};
