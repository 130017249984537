import { EntityLabel } from "components/AddProductToCart/AddProductToCart";
import { ConditionalLink } from "components/conditionalLink";
import { Divider } from "components/design/Divider";
import ToolTip from "components/design/toolTip";
import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import { Loader } from "components/DesignSystem/Loader/Loader";
import Modal from "components/DesignSystem/Modal/Modal";
import {
  ToggleGroup,
  ToggleGroupItem,
} from "components/DesignSystem/ToggleGroup/ToggleGroup";
import { Link } from "components/icons/Link";
import { Price } from "components/Price";
import { BOOKS_PLUS_PLAN } from "constants/addons";
import { MONTHLY, YEARLY } from "constants/billingCycles";
import { INKLE_BOOKS_PRICING_URL } from "constants/inklePricing";
import { Form, Formik } from "formik";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useUpdateQuery } from "hooks/useQuery";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import { useAddProductToCartMutation } from "store/apis/products";
import { useGetSubscriptionsQuery } from "store/apis/subscriptions";
import {
  setAddonPayload,
  setAddonType,
  setOpenCheckoutModal,
} from "store/slices/addons";
import { Subscription } from "types/Models/subscription";
import { BackendError } from "types/utils/error";

const BookkeepingPlusPlan = () => {
  const { alertToast } = useToast();
  const dispatch = useAppDispatch();
  const { update } = useUpdateQuery();
  const { addonData } = useAppSelector((state) => state.addonsSlice);
  const entityId = useCurrentEntityId();
  const { entities, uuid: groupId } = useCurrentGroupContext();
  const selectedEntity = entities?.find((entity) => entity.uuid === entityId);
  const [addToCart] = useAddProductToCartMutation();

  const isCartSubscriptionActive = useAppSelector(
    (state) => state.cartSubscription.isCartSubscriptionActive
  );

  const [selctedBillingCycle, setSelctedBillingCycle] =
    useState<string>(MONTHLY);

  const [showAddonModal, setShowAddonModal] = useState<boolean>(
    addonData?.subscription_type === BOOKS_PLUS_PLAN
  );

  const { data: allSubscriptions = [], isLoading } = useGetSubscriptionsQuery(
    {
      groupId,
      entityId,
    },
    { skip: !groupId || !entityId }
  );

  const slectedPlusPlan = allSubscriptions.find(
    (subscriptions) =>
      subscriptions.subscription_type === BOOKS_PLUS_PLAN &&
      subscriptions.billing_cycle === selctedBillingCycle
  );

  const onSubmit = async ({
    slectedPlusPlan,
  }: {
    slectedPlusPlan: Subscription | undefined;
  }) => {
    try {
      if (isCartSubscriptionActive && slectedPlusPlan) {
        await addToCart({
          groupId: groupId,
          entityId,
          payload: {
            product_id: slectedPlusPlan.uuid,
            product_content_type_id: slectedPlusPlan.product_content_type_id,
            selected_tier_amount: slectedPlusPlan.amount,
          },
        });
        dispatch(setAddonType(""));
        setShowAddonModal(false);
        return;
      }

      if (slectedPlusPlan) {
        dispatch(
          setAddonPayload({
            title: slectedPlusPlan?.subscription_name,
            amount: slectedPlusPlan?.amount,
            uuid: slectedPlusPlan?.uuid,
            billing_cycle: slectedPlusPlan?.billing_cycle,
            type: slectedPlusPlan?.subscription_type,
          })
        );
      }
      dispatch(setAddonType(""));
      dispatch(setOpenCheckoutModal());
      setShowAddonModal(false);
    } catch (error) {
      alertToast({
        message: (error as BackendError).data?.error?.message,
      });
    }
  };

  const onClose = () => {
    setShowAddonModal(false);
    dispatch(setAddonType(""));
    update({
      query: "subscription_to_open",
      value: null,
    });
  };

  if (isLoading) {
    return (
      <Modal.Root open={showAddonModal} onOpenChange={onClose}>
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>Inkle Books</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            <div className="t-flex t-items-center t-justify-center">
              <Loader />
            </div>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    );
  }

  return (
    <Modal.Root open={showAddonModal} onOpenChange={onClose}>
      <Formik
        initialValues={{ selectedEntity, slectedPlusPlan }}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({
          isSubmitting,
          values: { selectedEntity, slectedPlusPlan },
          submitForm,
        }) => (
          <Modal.Content>
            <Modal.Header>
              <Modal.Title>Inkle Books</Modal.Title>
              <Modal.Close />
            </Modal.Header>
            <Modal.Body>
              <Form className="t-flex t-gap-4 t-flex-col t-m-0 t-w-full">
                <ToolTip text="Change entity from top right of the page">
                  <Combobox
                    isDisabled
                    components={{
                      DropdownIndicator: () => null,
                    }}
                    menuPortalTarget={document.body}
                    name="entity_id"
                    label="Entity"
                    value={
                      selectedEntity && {
                        label: (
                          <EntityLabel
                            name={selectedEntity.name}
                            countryCode={selectedEntity.country_code}
                          />
                        ),
                        value: selectedEntity.uuid,
                      }
                    }
                    options={entities?.map((entity) => ({
                      label: (
                        <EntityLabel
                          name={entity.name}
                          countryCode={entity.country_code}
                        />
                      ),
                      value: entity.uuid,
                    }))}
                  />
                </ToolTip>
                <Divider />
                <div className="t-flex t-justify-between t-items-start">
                  <div className="t-flex t-flex-col">
                    <h5 className="t-text-h5">Plus Plan</h5>
                    <Price
                      amount={slectedPlusPlan?.amount}
                      size="medium"
                      cycle={slectedPlusPlan?.billing_cycle}
                    />
                  </div>

                  <ToggleGroup
                    defaultValue={MONTHLY}
                    onValueChange={setSelctedBillingCycle}
                  >
                    <ToggleGroupItem value={MONTHLY}>Monthly</ToggleGroupItem>
                    <ToggleGroupItem value={YEARLY}>Annually</ToggleGroupItem>
                  </ToggleGroup>
                </div>

                <div
                  dangerouslySetInnerHTML={{
                    __html: slectedPlusPlan?.description || <></>,
                  }}
                  className="t-text-body [&>ul]:t-m-0 [&>ul]:t-text-body [&>ul>li]:t-mt-1 [&>h3]:t-text-subtitle [&>p]:t-ml-6 [&>p]:t-mt-1.5  [&>p]:t-mb-0"
                />
                <ConditionalLink
                  to={INKLE_BOOKS_PRICING_URL}
                  className="t-w-max"
                >
                  <Button customType="link" type="button">
                    <Link size="16" color="currentColor" />
                    <span className="t-ml-1">
                      See everything you get with Plus Plan
                    </span>
                  </Button>
                </ConditionalLink>
              </Form>
            </Modal.Body>
            <Modal.FooterButtonGroup>
              <Button onClick={onClose} disabled={isSubmitting} type="button">
                Cancel
              </Button>
              <Button
                customType="primary"
                onClick={submitForm}
                isLoading={isSubmitting}
                disabled={isSubmitting}
              >
                {isCartSubscriptionActive ? "Add to Cart" : "Next"}
              </Button>
            </Modal.FooterButtonGroup>
          </Modal.Content>
        )}
      </Formik>
    </Modal.Root>
  );
};

export default BookkeepingPlusPlan;
