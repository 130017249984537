import { TagCombobox } from "components/TagCombobox/TagCombobox";
import { useToast } from "hooks/useToast";
import {
  useAddTeamSettingTagsMutation,
  useRemoveServiceProvidersTagMutation,
} from "store/apis/teamSetting";
import "../../static/styles/components/taskAssignee.css";

export default function Tags({ uuid, tags, currentTags, refetch }) {
  const { alertToast } = useToast();

  const [postTeamSettingTags, { isLoading: isAdding }] =
    useAddTeamSettingTagsMutation();
  const [deleteTeamSettingTags, { isLoading: isDeleting }] =
    useRemoveServiceProvidersTagMutation();

  const onTagsChange = async (selectedTags) => {
    const addedTag = selectedTags.filter(
      ({ value }) => !currentTags?.some(({ uuid }) => uuid === value)
    )?.[0];

    const removedTag = currentTags?.filter(
      ({ uuid }) => !selectedTags?.some(({ value }) => uuid === value)
    )?.[0];

    try {
      if (removedTag) {
        await deleteTeamSettingTags({
          serviceProviderId: uuid,
          tagId: removedTag?.uuid,
        }).unwrap();
      } else {
        await postTeamSettingTags({ uuid, tagId: addedTag.value }).unwrap();
      }
    } catch (error) {
      alertToast({ message: error.data?.error?.message });
    }
  };

  return (
    <TagCombobox
      tags={tags}
      currentTags={currentTags}
      isLoading={isAdding || isDeleting}
      onTagsChange={onTagsChange}
    />
  );
}
