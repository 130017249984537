import Loader from "components/design/loader";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { Tag } from "components/DesignSystem/Tag/Tag";
import { Label, TextInput } from "components/DesignSystem/TextInput/TextInput";
import { Field, FieldProps, Form, Formik, useFormikContext } from "formik";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import {
  useGetStartingDateQuery,
  useGetTagsQuery,
  useGetTransactionCategoryQuery,
  useUpdateTransactionCategoryMutation,
} from "store/apis/chartOfAccounts";
import { editCOAModalClose } from "store/slices/chartOfAccounts";
import { associatedReport } from "utils/associatedReport";
import { ConfirmEdit } from "./ConfirmEdit";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import { PatternInput } from "components/PatternInput/PatternInput";
import { useAppSelector } from "hooks/useAppSelector";
import { useAppDispatch } from "hooks/useAppDispatch";

export const CategoryTag = ({ parentId }: { parentId: string }) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { values } = useFormikContext<{ category_tag: string }>();

  const { data: tags = [] } = useGetTagsQuery(
    {
      groupId,
      entityId,
      parentCategoryId: parentId,
    },
    { skip: !parentId || !entityId || !groupId }
  );

  return (
    <Combobox
      name="category_tag"
      withForm
      label="Tags"
      backspaceRemovesValue={false}
      placeholder="Select a tag"
      menuPortalTarget={document.body}
      options={tags?.map((tag) => ({ label: tag, value: tag }))}
      {...{
        value: values?.category_tag
          ? {
              label: values?.category_tag,
              value: values?.category_tag,
            }
          : null,
      }}
    />
  );
};

export const EditCategory = () => {
  const dispatch = useAppDispatch();
  const { alertToast, successToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();

  const [updatedPayload, setUpdatedPayload] = useState<
    | undefined
    | {
        category_uuid: string;
        name: string;
        description: string;
        start_date: string | null;
        opening_balance?: number | null;
        category_tag: string;
        identifier: number;
      }
  >();

  const { uuid: categoryId, open: isOpenModal } = useAppSelector(
    (state) => state.chartOfAccounts.editCOA
  );

  const { data: coaData, isFetching } = useGetTransactionCategoryQuery(
    { categoryId, entityId, groupId },
    { skip: !categoryId || !entityId || !groupId }
  );

  const { isOpen, open, close } = useModal();

  const [updateTransactionCategory, { isLoading }] =
    useUpdateTransactionCategoryMutation();

  const { data: startDateData } = useGetStartingDateQuery(
    {
      groupId,
      entityId,
    },
    { skip: !groupId || !entityId }
  );

  const { start_date } = startDateData || {};

  const onEditModalClose = () => {
    dispatch(editCOAModalClose());
  };

  const updateCategory = async (values: {
    account_name: string;
    description: string;
    start_date: string | null;
    opening_balance?: number | null;
    category_tag: string;
    account_id: string;
  }) => {
    const payload = {
      category_uuid: categoryId,
      name: values.account_name,
      description: values.description,
      start_date: values.start_date,
      opening_balance: values.opening_balance,
      category_tag: values.category_tag,
      identifier: Number(values.account_id),
    };
    setUpdatedPayload(payload);
    open();
    onEditModalClose();
  };

  const onClose = () => {
    close();
    setUpdatedPayload(undefined);
  };

  const onConfirmSubmit = async () => {
    try {
      await updateTransactionCategory({
        groupId,
        entityId,
        payload: updatedPayload,
      }).unwrap();
      successToast({ title: "Account updated" });
      onClose();
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  const {
    identifier_prefix,
    name,
    parent_name,
    identifier,
    description,
    opening_balance,
    category_tag,
    manually_added,
  } = coaData || {};

  const { isBalanceSheetAssociated, isIncomeStatementAssociated } =
    associatedReport(identifier_prefix!);

  return (
    <>
      <Modal.Root open={isOpenModal} onOpenChange={onEditModalClose}>
        <Formik
          initialValues={{
            account_name: name || "",
            parent_account: parent_name || "",
            account_id: identifier?.toString() || "",
            description: description || "",
            start_date: start_date || null,
            opening_balance: opening_balance,
            category_tag: category_tag || "",
            dateSelector: "custom",
            identifier_prefix: identifier_prefix || 1,
          }}
          onSubmit={updateCategory}
          enableReinitialize
        >
          {({ isSubmitting, dirty, values, submitForm }) => (
            <Modal.Content asChild>
              <Form className="all:unset">
                {isFetching ? (
                  <Loader />
                ) : (
                  <>
                    <Modal.Header>
                      <Modal.Title>Edit account</Modal.Title>
                      <Modal.Close />
                    </Modal.Header>
                    <Modal.Body className="t-flex t-flex-col t-gap-5">
                      <TextInput
                        block
                        label="Account name"
                        name="account_name"
                      />
                      <TextInput
                        block
                        label="Parent account"
                        name="parent_account"
                        disabled
                      />
                      {manually_added ? (
                        <PatternInput
                          fieldProps={{
                            name: "account_id",
                            value: values.account_id,
                          }}
                          patternProps={{
                            format: `${values.identifier_prefix}####`,
                            mask: "_",
                            allowEmptyFormatting: true,
                          }}
                          label="Category ID"
                        />
                      ) : (
                        <TextInput
                          block
                          label="Category ID"
                          name="account_id"
                          disabled
                        />
                      )}
                      {isBalanceSheetAssociated && (
                        <div className="t-flex t-gap-4">
                          <div className="t-w-1/2">
                            <Field name="start_date">
                              {({ field }: FieldProps) => {
                                return (
                                  <DateInput
                                    disabled
                                    {...field}
                                    label="Starting date"
                                    placeholder="Select date"
                                    block
                                  />
                                );
                              }}
                            </Field>
                          </div>
                          <div className="t-w-1/2">
                            <TextInput
                              name="opening_balance"
                              label={`Account balance as on ${
                                values.start_date || "_"
                              }`}
                              type="number"
                            />
                          </div>
                        </div>
                      )}
                      <TextInput
                        block
                        label="Description/explanation of account (optional)"
                        name="description"
                      />
                      <CategoryTag parentId={categoryId} />
                      <div className="t-flex t-flex-col">
                        <Label>Associated Reports</Label>
                        {isBalanceSheetAssociated && (
                          <Tag tagType="yellow">Balance Sheet</Tag>
                        )}
                        {isIncomeStatementAssociated && (
                          <Tag tagType="orange">Income Statement</Tag>
                        )}
                      </div>
                    </Modal.Body>
                    <Modal.Footer className="t-flex t-justify-end t-gap-3">
                      <Button onClick={onEditModalClose}>Cancel</Button>
                      <Button
                        type="submit"
                        customType="primary"
                        isLoading={isSubmitting || isLoading}
                        disabled={isSubmitting || isLoading}
                        onClick={submitForm}
                      >
                        Continue
                      </Button>
                    </Modal.Footer>
                  </>
                )}
              </Form>
            </Modal.Content>
          )}
        </Formik>
      </Modal.Root>
      <ConfirmEdit
        open={isOpen}
        onClose={onClose}
        onConfirmSubmit={onConfirmSubmit}
      />
    </>
  );
};
