import { ChannelPreview } from "components/ChannelPreview/ChannelPreview";
import { useCurrentGroup } from "hooks/useCurrentGroup";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setToOpenChatId } from "store/slices/chat";
import { RootState } from "store/store";
import { Channel, ChannelFilters, ChannelSort } from "stream-chat";
import { InfiniteScroll, useChatContext } from "stream-chat-react";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import { ChannelSearch } from "components/ChannelSearch/ChannelSearch";
import DashboardContainer from "components/dashboard/DashboardContainer";
import { ChatSearch } from "components/ChatSearch/ChatSearch";
import { EmptyChannelList } from "components/EmptyChannelList";
import cx from "classnames";
import { ANNOUNCEMENTS_CHANNEL } from "constants/chatType";
import { StreamChannelList } from "components/StreamChannelList/StreamChannelList";
import { useParams } from "react-router-dom";
import {
  ToggleGroup,
  ToggleGroupItem,
} from "components/DesignSystem/ToggleGroup/ToggleGroup";
import * as CHANNEL_TYPES from "constants/channelType";

export const ChannelList = ({
  filters: propFilters,
  unreplied = false,
  broadcast = false,
  onSelect,
  channelId,
  hideTabBar,
}: {
  filters?: ChannelFilters<DefaultStreamChatGenerics>;
  unreplied?: boolean;
  broadcast?: boolean;
  onSelect?: (channelId: string, messageId?: string) => void;
  channelId?: string;
  hideTabBar?: boolean;
}) => {
  const { isAdmin, isCpa, isCustomer, isUserTypeForeignCA, isForeignCA } =
    useRoleBasedView();
  const { client } = useChatContext();
  const dispatch = useDispatch();
  const isFCA = isUserTypeForeignCA || isForeignCA;
  const [channelType, setChannelType] = React.useState<string>(
    isFCA ? "ALL" : "PUBLIC"
  );
  const searchValue = useSelector((state: RootState) => state.chatSearch.value);
  const isUnreadFilterActive = useSelector(
    (state: RootState) => state.chatSearch.unread
  );
  const { secondary_channel_url } = useCurrentGroup();
  const { channel: currentChannel } = useChatContext();
  const showMentionedMessages = useSelector(
    (state: RootState) => state.chatSearch.showMentionedMessages
  );

  let filters = propFilters || {
    type: "messaging",
    members: { $in: [client.userID!] },
  };

  filters = {
    ...filters,
    frozen: false,
  };

  if (channelType === "CLOSED" && !broadcast) {
    filters = {
      ...filters,
      frozen: true,
    };
  }

  if (channelType === "PRIVATE" && isCpa && !broadcast) {
    filters = {
      ...filters,
      custom_type: { $eq: CHANNEL_TYPES.PRIVATE_CHANNEL_CUSTOM_TYPE },
    };
  }

  if (channelType === "PUBLIC" && isCpa && !broadcast) {
    filters = {
      ...filters,
      custom_type: {
        $in: Object.values(CHANNEL_TYPES).filter(
          (key) =>
            ![
              CHANNEL_TYPES.PRIVATE_CHANNEL_CUSTOM_TYPE,
              CHANNEL_TYPES.ANNOUNCEMENTS_CHANNEL_CUSTOM_TYPE,
            ].includes(key)
        ),
      },
    };
  }

  const onChannelUpdated = async (
    setChannels: React.Dispatch<
      React.SetStateAction<Array<Channel<DefaultStreamChatGenerics>>>
    >
  ) => {
    setChannels((channels) => {
      if (unreplied) {
        return channels.filter((channel) => channel?.data?.needs_reply);
      }

      if (broadcast) {
        return channels.filter(
          (channel) => channel?.data?.custom_type !== ANNOUNCEMENTS_CHANNEL
        );
      }

      let customActiveChannelObject = channels.find(
        (chan) => chan?.id === customActiveChannel
      );

      let newChannels = [
        currentChannel,
        ...channels.filter(
          (channel) =>
            channel?.id !== customActiveChannel &&
            channel?.id !== currentChannel?.id
        ),
      ];

      if (
        customActiveChannelObject &&
        customActiveChannelObject?.id !== currentChannel?.id
      ) {
        newChannels = [customActiveChannelObject, ...newChannels];
      }

      return newChannels as Channel<DefaultStreamChatGenerics>[];
    });
  };

  const { groupId: groupIdFromParams } = useParams<{ groupId?: string }>();
  const isCrmView = Boolean(groupIdFromParams);

  const channelSortKey =
    (isAdmin || isCpa) && !isCrmView
      ? "last_non_broadcast_message_at"
      : "last_message_at";

  let sort: ChannelSort = { [channelSortKey]: unreplied ? 1 : -1 };

  if (isUnreadFilterActive) {
    sort = {
      has_unread: -1,
    };
  }

  let customActiveChannel: string | undefined;

  if (isAdmin || isCustomer) {
    customActiveChannel =
      searchValue || isUnreadFilterActive ? undefined : secondary_channel_url;
  }

  const onSelectCallback = (channelId?: string, messageId?: string) => {
    if (onSelect && channelId) {
      onSelect(channelId, messageId);
      dispatch(setToOpenChatId(channelId));
    }
  };

  return (
    <DashboardContainer className="t-h-full t-transition-all">
      <DashboardContainer.Header>
        <div className="t-py-4 t-px-6 t-flex t-flex-col t-gap-4">
          {!hideTabBar &&
            (isCpa && !isFCA && !broadcast ? (
              <ToggleGroup
                value={channelType}
                onValueChange={(value) => {
                  if (value) {
                    setChannelType(value);
                  }
                }}
              >
                <ToggleGroupItem value="ALL">All</ToggleGroupItem>
                <ToggleGroupItem value="PUBLIC">Public</ToggleGroupItem>
                <ToggleGroupItem value="PRIVATE">Private</ToggleGroupItem>
                <ToggleGroupItem value="CLOSED">Closed</ToggleGroupItem>
              </ToggleGroup>
            ) : (
              <ToggleGroup
                value={channelType}
                onValueChange={(value) => {
                  if (value) {
                    setChannelType(value);
                  }
                }}
              >
                <ToggleGroupItem value="PUBLIC">Open</ToggleGroupItem>
                <ToggleGroupItem value="CLOSED">Closed</ToggleGroupItem>
              </ToggleGroup>
            ))}
          <ChannelSearch />
        </div>
      </DashboardContainer.Header>
      <DashboardContainer.Content
        className={cx({
          "hide-scrollbar": searchValue,
        })}
      >
        {searchValue || showMentionedMessages ? (
          <ChatSearch
            onSelect={onSelect && onSelectCallback}
            filters={filters}
            sort={sort}
            unreplied={unreplied}
          />
        ) : (
          <StreamChannelList
            EmptyStateIndicator={EmptyChannelList}
            Paginator={(props) => <InfiniteScroll {...props} threshold={400} />}
            Preview={(props) => (
              <ChannelPreview
                {...props}
                unreplied={unreplied}
                {...(onSelect
                  ? {
                      onSelect: () => onSelectCallback(props.channel.id),
                    }
                  : {})}
              />
            )}
            filters={filters}
            customActiveChannel={customActiveChannel}
            setActiveChannelOnMount={false}
            sort={[sort]}
            options={{ limit: 10 }}
            onChannelUpdated={onChannelUpdated}
          />
        )}
      </DashboardContainer.Content>
    </DashboardContainer>
  );
};
