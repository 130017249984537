import Loader from "components/design/loader";
import NoDataAvailable from "components/design/noDataAvailable";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useGetVendorsQuery } from "store/apis/vendors";
import { setDefaultChannel } from "store/slices/chat";
import { MerchantTable } from "./merchantTable";

export const MerchantsPanel = () => {
  const { uuid: groupId } = useCurrentGroupContext();
  const dispatch = useDispatch();
  const query = useQuery();
  const selectedSeason = query.get("season") || "2023";
  const page = Number(query.get("page")) || 1;
  let selectedEntityId = useCurrentEntityId();
  const wForm = query.get("wForm");
  const searchTerm = query.get("search_term");
  const [sorting, setSorting] = useState([
    {
      id: "AMOUNT",
      desc: false,
    },
  ]);
  const sortOrder = sorting[0]?.desc;
  let vendorType = null;
  if (wForm === "wFormNotRequired") {
    vendorType = "NOT_REQUIRED";
  }
  if (wForm === "wFormRequired") {
    vendorType = "VENDORS";
  }

  const {
    data: merchantData,
    isLoading,
    isSuccess,
    refetch,
  } = useGetVendorsQuery(
    {
      groupId,
      search_term: searchTerm,
      type: vendorType,
      page_num: page,
      season: selectedSeason,
      entityId: selectedEntityId,
      sort_order: sortOrder ? "DSC" : "ASC",
    },
    { skip: !groupId || !selectedEntityId, refetchOnMountOrArgChange: true }
  );

  const {
    merchants = [],
    channel_url,
    current_page,
    per_page,
    total_count,
    total_pages,
    transactions_without_vendors_count,
  } = merchantData || {};

  const paginationData = {
    totalPage: total_pages,
    currentPage: current_page,
    itemsPerPage: per_page,
    totalItemCount: total_count,
  };

  useEffect(() => {
    dispatch(setDefaultChannel(channel_url));
  }, [channel_url]);

  const noMerchant = merchants.length === 0;

  if (isLoading || !isSuccess) {
    return <Loader />;
  }

  return (
    <MerchantTable
      merchants={merchants}
      groupId={groupId}
      showWform={wForm === "wFormRequired"}
      totalPages={total_pages}
      paginationData={paginationData}
      noMerchant={noMerchant}
      sorting={sorting}
      setSorting={setSorting}
      transactionsWithoutVendorsCount={transactions_without_vendors_count}
    />
  );
};
