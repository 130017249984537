import { ReactNode } from "react";

export const EmptyMetrics = ({
  illustration,
  title,
  subtitle,
}: {
  illustration: ReactNode;
  title: ReactNode;
  subtitle: ReactNode;
}) => (
  <div className="t-flex t-flex-col t-justify-center t-items-center t-h-full t-min-h-[280px] t-text-text-30 t-gap-2">
    {illustration}
    <div className="t-flex t-flex-col t-items-center t-justify-center">
      <p className="t-m-0 t-text-subtext">{title}</p>
      <p className="t-m-0 t-text-caption">{subtitle}</p>
    </div>
  </div>
);
