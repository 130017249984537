import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import Modal from "components/DesignSystem/Modal/Modal";
import { Stepper } from "components/DesignSystem/Stepper/Stepper";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { ArrowRight } from "components/icons/ArrowRight";
import { coaOptions } from "components/Transaction/TransactionColumn";
import { Form, Formik } from "formik";
import { useChartOfAccounts } from "hooks/useChartOfAccounts";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetCategoryUsageQuery,
  useRemoveTransactionCategoryMutation,
} from "store/apis/chartOfAccounts";
import { deleteCOAModalClose } from "store/slices/chartOfAccounts";
import { RootState } from "store/store";
import { flattenTypes } from "utils/flattenCOA";
import { pluralize } from "utils/pluralize";
import { mixed, object, string } from "yup";

export const DeleteCategory = () => {
  const dispatch = useDispatch();
  const { alertToast, successToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const [currentStep, setCurrentStep] = useState(1);
  const { chartOfAccounts = [], isLoading: isCoaLoading } = useChartOfAccounts({
    hiddenCategoryTypes: ["BANK_ACCOUNT", "BANK_TRANSFER", "PAY_DOWN_CREDIT"],
  });

  const { uuid: selectedCategory, open } = useSelector(
    (state: RootState) => state.chartOfAccounts.deleteCOA
  );

  const { data: categoryUsage } = useGetCategoryUsageQuery(
    {
      entityId: entityId,
      categoryId: selectedCategory,
      groupId: groupId,
    },
    { skip: !entityId || !groupId || !selectedCategory }
  );

  const selectedCategoryDetails = flattenTypes({
    accounts: chartOfAccounts,
  }).find(({ uuid }) => uuid === selectedCategory);

  const [deleteTransactionCategory, { isLoading, isSuccess }] =
    useRemoveTransactionCategoryMutation();

  const onClose = () => {
    dispatch(deleteCOAModalClose());
    setCurrentStep(1);
  };

  const deleteCategory = async (values: {
    name: string;
    replace_category_id: string;
  }) => {
    try {
      await deleteTransactionCategory({
        groupId,
        entityId,
        payload: {
          category_uuid: selectedCategory,
          replace_category_id: values.replace_category_id,
          name: values.name,
        },
      }).unwrap();
      successToast({ title: "Account deleted" });
      onClose();
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  if (!open) {
    return null;
  }

  const replace = categoryUsage && categoryUsage?.total > 0;

  return (
    <Formik
      initialValues={{ name: "", replace_category_id: "" }}
      onSubmit={deleteCategory}
      validationSchema={object({
        replace_category_id: replace
          ? string().required("Select category to replace")
          : mixed().notRequired(),
        name:
          currentStep > 1 || !replace
            ? string().required("Please enter the name")
            : string().notRequired(),
      })}
    >
      {({ values, isSubmitting, validateForm, setFieldTouched }) => {
        const replaceWithCategory = flattenTypes({
          accounts: chartOfAccounts,
        }).find(({ uuid }) => uuid === values.replace_category_id);

        const onContinue = async () => {
          try {
            setFieldTouched("replace_category_id", true);
            const errors = await validateForm();
            if (Object.values(errors).length === 0) {
              setCurrentStep(2);
              setFieldTouched("name", false);
            }
          } catch (error) {}
        };

        return (
          <Modal.Root open={open} onOpenChange={onClose}>
            <Modal.Content size="large" asChild useCustomOverlay>
              <Form className="all:unset">
                <Modal.Header>
                  <div className="">
                    <Modal.Title>Delete Account</Modal.Title>
                    {replace && (
                      <Modal.Subtitle>
                        <Stepper size="small" direction="horizontal">
                          <Stepper.Step
                            step={1}
                            isActive={currentStep >= 1}
                            clickable
                            onClick={() => setCurrentStep(1)}
                          >
                            <div className="t-flex t-items-center">
                              Replace
                              <ArrowRight color="currentColor" />
                            </div>
                          </Stepper.Step>
                          <Stepper.Step
                            step={2}
                            isActive={currentStep === 2}
                            clickable
                          >
                            <div className="t-flex t-items-center">Confirm</div>
                          </Stepper.Step>
                        </Stepper>
                      </Modal.Subtitle>
                    )}
                  </div>
                  <Modal.Close />
                </Modal.Header>
                <Modal.Body>
                  {currentStep === 1 && replace && (
                    <>
                      <div className="t-text-subtitle t-mb-2">
                        {selectedCategoryDetails?.name} is being used at{" "}
                        {pluralize(categoryUsage?.total, "place", "places")}.
                        Select an account to replace:
                      </div>
                      <Combobox
                        label="Select account"
                        menuPlacement="top"
                        isDisabled={isLoading}
                        placeholder="Select"
                        isClearable
                        menuPortalTarget={document.body}
                        withForm
                        name="replace_category_id"
                        options={coaOptions(chartOfAccounts)}
                        filterOption={(option, search) => {
                          if (option.data.value === selectedCategory) {
                            return false;
                          }

                          return (
                            option.data.data
                              ?.toLocaleLowerCase()
                              .includes(search.toLocaleLowerCase()) || false
                          );
                        }}
                        value={
                          values.replace_category_id
                            ? {
                                label: replaceWithCategory?.name,
                                value: replaceWithCategory?.uuid || "",
                              }
                            : null
                        }
                      />
                    </>
                  )}
                  {(currentStep === 2 || !replace) && (
                    <>
                      <div className="t-text-subtitle t-mb-5">
                        Are you sure? This change cannot be undone.
                      </div>
                      <TextInput
                        block
                        label="Please enter the name of the account to delete it permanently"
                        name="name"
                      />
                    </>
                  )}
                </Modal.Body>
                <Modal.FooterButtonGroup>
                  <Button onClick={onClose}>Cancel</Button>
                  {currentStep === 1 && replace && (
                    <Button
                      type="button"
                      onClick={onContinue}
                      customType="primary"
                    >
                      Continue
                    </Button>
                  )}
                  {(currentStep === 2 || !replace) && (
                    <Button
                      customType="danger"
                      isLoading={isSubmitting || isLoading}
                      disabled={isSubmitting || isLoading}
                    >
                      Delete
                    </Button>
                  )}
                </Modal.FooterButtonGroup>
              </Form>
            </Modal.Content>
          </Modal.Root>
        );
      }}
    </Formik>
  );
};
