import * as Accordion from "@radix-ui/react-accordion";
import classNames from "classnames";
import { Button } from "components/DesignSystem/Button/Button";
import AddJurisdiction from "components/Entity/EntityFormEngine/AddJurisdiction";
import { CaretDown } from "components/icons/CaretDown";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import React from "react";
import { useGetEntityAllStateDataQuery } from "store/apis/entity";
import { RegisteredStates } from "./RegisteredStates";

export const EntityStateRegistrations = ({
  entityId,
}: {
  entityId: string;
}) => {
  const AddJurisdictionModal = useModal();
  const { uuid: groupId } = useCurrentGroupContext();
  const { data = [] } = useGetEntityAllStateDataQuery(
    { entityId, groupId },
    { skip: !groupId || !entityId }
  );

  const isEmpty = !data || data.length === 0;

  return (
    <div className="t-border t-border-solid t-p-2 t-rounded-lg t-border-neutral-10">
      <Accordion.Root
        type="single"
        defaultValue=""
        className="t-space-y-4"
        collapsible
      >
        <Accordion.Item
          key="stateRegistrations"
          value="stateRegistrations"
          className="t-p-2"
        >
          <Accordion.Trigger className="all:unset t-text-subtext-sm t-text-text-100 t-flex t-gap-2 t-group t-w-full t-justify-between">
            <div className="t-flex t-gap-2 t-items-center">
              <span className="group-data-state-open:t-rotate-0 group-data-state-closed:-t-rotate-90 t-text-neutral t-transform t-transition t-duration-300 t-ease-in-out">
                <CaretDown />
              </span>
              <div className="t-text-subtext t-text-text-100">
                State Registrations
              </div>
            </div>
            {!isEmpty && (
              <div
                onClick={(e) => e.stopPropagation()}
                className="group-data-state-open:t-visible group-data-state-closed:t-hidden"
              >
                <Button
                  type="button"
                  size="small"
                  onClick={AddJurisdictionModal.open}
                >
                  Add state
                </Button>
              </div>
            )}
          </Accordion.Trigger>
          <Accordion.Content className="t-mt-3 t-pl-6">
            <RegisteredStates entityId={entityId} />
            {isEmpty && (
              <div className="t-mb-6 t-mt-12 t-flex t-justify-center t-w-full">
                <Button
                  type="button"
                  size="small"
                  onClick={AddJurisdictionModal.open}
                >
                  Add state
                </Button>
              </div>
            )}
          </Accordion.Content>
        </Accordion.Item>
      </Accordion.Root>
      {AddJurisdictionModal.isOpen && (
        <AddJurisdiction {...AddJurisdictionModal} entityId={entityId} />
      )}
    </div>
  );
};
