import Loader from "components/design/loader";
import { SubscriptionsContext } from "contexts/SubscriptionsContext";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useStreamClient } from "hooks/useStreamClient";
import { ReactNode, useEffect } from "react";
import { useGetEntitiesQuery } from "store/apis/group";
import { useGetSubscriptionsQuery } from "store/apis/subscriptions";
import { Chat } from "stream-chat-react";

export const GlobalContexts = ({ children }: { children: ReactNode }) => {
  const { chatClient } = useStreamClient();
  const { dispatch: groupDispatch } = useCurrentGroupContext();
  const { isCpa } = useRoleBasedView();
  const { data: { groups: [group] = [] } = {} } = useGetEntitiesQuery(
    undefined,
    {
      skip: isCpa,
    }
  );

  const { data: subscriptions = [] } = useGetSubscriptionsQuery({
    subscription_types: "PLATFORM",
  });

  useEffect(() => {
    if (group) {
      groupDispatch({ type: "SET_GROUP", payload: group });
    }
  }, [group]);

  if (!chatClient) {
    return <Loader />;
  }

  return (
    <SubscriptionsContext.Provider value={{ subscriptions }}>
      <Chat
        client={chatClient}
        customClasses={{
          channelList:
            "str-chat-channel-list str-chat__channel-list str-chat__channel-list-react !t-border-none",
          /* @tw */
          channel:
            "str-chat messaging light str-chat-channel str-chat__channel group-[&]/comments:t-bg-surface-transparent",
        }}
      >
        {children}
      </Chat>
    </SubscriptionsContext.Provider>
  );
};
