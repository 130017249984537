import { AddManualBank } from "components/Connections/AddManualBank";
import { ConnectionsModal } from "components/Connections/ConnectionsModal";
import { EditBankAccount } from "components/Connections/EditBankAccount";
import Modal from "components/DesignSystem/Modal/Modal";
import { LockSecure } from "components/icons/LockSecure";
import { useBankConnect } from "hooks/useBankConnect";
import { useModal } from "hooks/useModal";
import React from "react";

const ConnectBankInQuotes = ({
  isOpen,
  close,
}: {
  isOpen: boolean;
  close: () => void;
}) => {
  const { onConnect, isLoading, originalArgs } = useBankConnect();
  const addManualBankModal = useModal();

  return (
    <>
      <Modal.Root open={isOpen} onOpenChange={close}>
        <Modal.Content size="large">
          <Modal.Header>
            <Modal.Title>Connect</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            <ConnectionsModal
              isLoading={isLoading}
              loadingConnectionId={originalArgs?.connectionId}
              onConnect={onConnect}
              close={close}
              onComplete={close}
              onManualBankAdd={addManualBankModal.open}
            />
          </Modal.Body>
          <Modal.Footer>
            <div className="t-flex t-items-center t-gap-3">
              <div>
                <LockSecure />
              </div>
              <p className="t-m-0 t-text-text-30 t-text-body-sm">
                Inkle connects your account securely in compliance with industry
                standards. Inkle will only have read-only access to your
                transactions.
              </p>
            </div>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Root>
      <EditBankAccount />
      <AddManualBank
        isOpen={addManualBankModal.isOpen}
        close={addManualBankModal.close}
      />
    </>
  );
};

export default ConnectBankInQuotes;
