import { DashboardLayout } from "components/DashboardLayout";
import { Header } from "components/DesignSystem/Header/Header";
import Tab from "components/DesignSystem/Tab/Tab";
import { AnnualisedRecurringRevenue } from "components/RevenueMetrics/AnnualisedRecurringRevenue";
import { MonthlyRecurringRevenue } from "components/RevenueMetrics/MonthlyRecurringRevenue";
import { TotalRevenue } from "components/RevenueMetrics/TotalRevenue";
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import { motion } from "framer-motion";
import DashboardContainer from "components/dashboard/DashboardContainer";

export const RevenueMetrics = () => {
  const { path, url } = useRouteMatch();
  const { pathname, search } = useLocation();

  const isMonthlyRevenue = pathname.includes("monthly-recurring-revenue");
  const isAnnualisedRevenue = pathname.includes("annualised-recurring-revenue");
  const isTotalRevenue = !isMonthlyRevenue && !isAnnualisedRevenue;

  return (
    <Tab.Root asChild defaultValue={pathname} value="MATCH">
      <DashboardContainer className="t-h-full">
        <DashboardContainer.Header className="t-px-10 t-z-header">
          <Header
            v2
            title="Revenue Metrics"
            bottom={
              <Tab.List>
                <Tab.NavTrigger
                  exact
                  to={`${url}${search}`}
                  value={isTotalRevenue ? "MATCH" : ""}
                >
                  Total Revenue
                </Tab.NavTrigger>

                <Tab.NavTrigger
                  exact
                  to={`${url}/monthly-recurring-revenue${search}`}
                  value={isMonthlyRevenue ? "MATCH" : ""}
                >
                  MRR
                </Tab.NavTrigger>

                <Tab.NavTrigger
                  exact
                  to={`${url}/annualised-recurring-revenue${search}`}
                  value={isAnnualisedRevenue ? "MATCH" : ""}
                >
                  ARR
                </Tab.NavTrigger>
              </Tab.List>
            }
          />
        </DashboardContainer.Header>
        <DashboardContainer.Content className="t-my-5 t-px-10 t-h-full t-w-full !t-overflow-x-hidden">
          <motion.div
            layout
            key={pathname}
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <Switch>
              <Route exact path={path}>
                <TotalRevenue />
              </Route>
              <Route exact path={`${path}/monthly-recurring-revenue`}>
                <MonthlyRecurringRevenue />
              </Route>
              <Route exact path={`${path}/annualised-recurring-revenue`}>
                <AnnualisedRecurringRevenue />
              </Route>
            </Switch>
          </motion.div>
        </DashboardContainer.Content>
      </DashboardContainer>
    </Tab.Root>
  );
};
