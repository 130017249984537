import Loader from "components/design/loader";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import Modal from "components/DesignSystem/Modal/Modal";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { Field, FieldProps, Form, Formik } from "formik";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useGetRelatedLedgerEntryQuery } from "store/apis/generalLedger";
import { JournalEntry } from "types/Models/journalEntry";
import { ModalProps } from "types/utils/modal";
import {
  DeleteJournalEntryTable,
  TransactionTable,
} from "./DeleteJournalEntryTable";
import { SwitchField } from "components/DesignSystem/Switch/SwitchField";
import { RecurringJournalEntrySettings } from "./AddJournalEntryModal";
import { Button } from "components/DesignSystem/Button/Button";
import { useModal } from "hooks/useModal";
import { EditJournalEntry } from "./EditJournalEntry";

export const ViewJournalEntryModal = ({
  isOpen,
  close,
  transactionId,
}: {
  transactionId: string;
} & ModalProps) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { data, isLoading } = useGetRelatedLedgerEntryQuery(
    { groupId, entityId, transactionId: transactionId! },
    { skip: !groupId || !entityId || !transactionId }
  );

  const { ledger_entry } = data || {};
  const { entry_name, entry_number, date } = ledger_entry || {};

  const {
    isOpen: isEditModalOpen,
    open: openEditModal,
    close: closeEditModal,
  } = useModal();
  const {
    isOpen: isDeleteModalOpen,
    open: openDeleteModal,
    close: closeDeleteModal,
  } = useModal();
  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Modal.Content size="xxxl" useCustomOverlay>
        <Modal.Header>
          <Modal.Title>{entry_name}</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body className="t-pb-10 t-flex t-gap-4 t-flex-col">
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <Formik
                initialValues={{
                  transaction_date: date,
                  ledger_entry_name: entry_name,
                  is_recurring_enabled:
                    ledger_entry?.settings?.is_recurring_enabled,
                  frequency: ledger_entry?.settings?.frequency,
                  end_date: ledger_entry?.settings?.end_date,
                  max_recurring_count:
                    ledger_entry?.settings?.max_recurring_count,
                }}
                onSubmit={() => {}}
                enableReinitialize
              >
                {() => (
                  <Form>
                    <div className="t-flex t-gap-6 t-items-center">
                      <div className="t-flex t-gap-6 t-w-2/4">
                        <Field name="transaction_date">
                          {({ field }: FieldProps) => {
                            return (
                              <DateInput
                                label="Date"
                                {...field}
                                portalId="transaction_date"
                                required
                                block
                                disabled
                                maxDate={new Date()}
                                placeholder="DD-MMM-YYYY"
                              />
                            );
                          }}
                        </Field>
                        <TextInput
                          disabled
                          name="ledger_entry_name"
                          label="Journal entry title"
                          required
                        />
                      </div>

                      {ledger_entry?.settings?.is_recurring_enabled && (
                        <div className="t-ml-auto">
                          <SwitchField
                            disabled
                            name="is_recurring_enabled"
                            label="Recurring entry"
                          />
                        </div>
                      )}
                    </div>
                    {ledger_entry?.settings?.is_recurring_enabled && (
                      <div className="t-flex t-gap-6 t-pt-4">
                        <RecurringJournalEntrySettings disabled />
                      </div>
                    )}
                  </Form>
                )}
              </Formik>
              <TransactionTable transactions={data?.transactions!} />
            </>
          )}
          {isEditModalOpen && (
            <EditJournalEntry
              isOpen={isEditModalOpen}
              close={() => {
                closeEditModal();
                close();
              }}
              transactionId={transactionId}
            />
          )}
          {isDeleteModalOpen && (
            <DeleteJournalEntryTable
              isOpen={isDeleteModalOpen}
              close={() => {
                closeDeleteModal();
                close();
              }}
              transactionId={transactionId}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="t-flex t-items-center t-gap-3 t-justify-end">
            <Button onClick={openDeleteModal}>Delete</Button>
            <Button
              onClick={() => {
                openEditModal();
              }}
            >
              Edit
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
