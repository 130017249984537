import { BillingInfo, BillingInvoice } from "types/Models/billing";
import { SavePaymentResponse } from "types/Models/SavedCards";
import { emptyApi } from "./emptyApi";
import { Cart } from "./products";

type ZeroPaymentResponse = {
  message: string;
  invoices: string[];
  credits_used: number;
  is_payment_required: boolean;
  pending_ledger_event_uuid: string | null;
};

export const billingApis = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    applyCredit: build.mutation<
      ZeroPaymentResponse,
      {
        entityId: string;
        invoices: string[];
        is_post_payment: boolean;
        credits_applied: boolean;
        brex_points_used?: boolean;
        brex_quote_id?: string;
        credits_used: number;
      }
    >({
      query: ({
        entityId,
        invoices,
        is_post_payment,
        credits_applied,
        credits_used,
        brex_points_used,
        brex_quote_id,
      }) => ({
        url: `/api/inkle/payments/entity/${entityId}/credits/apply/`,
        method: "put",
        body: {
          invoices,
          is_post_payment,
          credits_applied,
          credits_used,
          brex_points_used,
          brex_quote_id,
        },
      }),
    }),
    applyGroupCoupon: build.mutation<
      { data: { coupon_code: string } },
      { groupId: string; couponCode: string }
    >({
      query: ({ groupId, couponCode }) => {
        return {
          url: `/api/inkle/billing/group/${groupId}/coupon/`,
          method: "post",
          body: { coupon_code: couponCode },
        };
      },
      invalidatesTags: ["GroupCoupon"],
    }),

    getGroupCoupon: build.query<{ coupon_code: string }, { groupId: string }>({
      query: ({ groupId }) => `/api/inkle/billing/group/${groupId}/coupon/`,
      providesTags: ["GroupCoupon"],
    }),

    getAllSavedCards: build.query<
      SavePaymentResponse[],
      { groupId: string; entityId: string }
    >({
      query: ({ groupId, entityId }) =>
        `api/inkle/payments/${groupId}/entity/${entityId}/saved/`,
      providesTags: ["SavedCards"],
    }),

    deleteCard: build.mutation<
      SavePaymentResponse[],
      { groupId: string; paymentMethodId: string; entityId: string }
    >({
      query: ({ groupId, paymentMethodId, entityId }) => {
        return {
          url: `api/inkle/payments/${groupId}/entity/${entityId}/payment/${paymentMethodId}/`,
          method: "delete",
        };
      },
      invalidatesTags: ["SavedCards"],
    }),

    getTagsForCategory: build.query<
      {
        uuid: string;
        tag_category: number;
        title: string;
        color_hex: string;
        bg_color_hex: string;
      }[],
      { tagType: string }
    >({
      query: ({ tagType }) => `api/inkle/tags/${tagType}/`,
      providesTags: ["ChatTickets"],
    }),

    patchBillingEmail: build.mutation<
      { billing_email: string },
      {
        group_id: string;
        billing_email?: string;
        invoice_emails?: string[];
        entityId: string;
      }
    >({
      query: ({ group_id, billing_email, invoice_emails, entityId }) => {
        return {
          url: `api/inkle/billing/group/${group_id}/entity/${entityId}/billing_info/`,
          method: "PATCH",
          body: {
            billing_email,
            invoice_emails,
          },
        };
      },
      invalidatesTags: ["BILLING_INFO"],
    }),
    getBillingInfo: build.query<
      BillingInfo,
      { group_id: string; entityId: string }
    >({
      query: ({ group_id, entityId }) => {
        return {
          url: `api/inkle/billing/group/${group_id}/entity/${entityId}/billing_info/`,
        };
      },
      providesTags: ["BILLING_INFO"],
    }),

    getPricingTiers: build.query<
      {
        uuid: string;
        range: string;
        lower_range: number;
        higher_range: number;
        amount: number;
      }[],
      void
    >({
      query: () => `/api/inkle/payments/pricing-tiers/`,
    }),

    addProductsToCartAndCheckout: build.mutation<
      { cart_details: Cart; invoice: BillingInvoice; request_id: string },
      {
        groupId: string;
        productData: {
          product_id: string;
          product_content_type_id: number;
          selected_tier_amount?: number;
          entity_id?: string;
          season?: string | null;
          quantity?: number;
          payment_amount?: number;
          alarm_id?: string;
        }[];
        entityId: string;
        from_alarms?: boolean;
        from_other?: boolean;
      }
    >({
      query: ({ groupId, productData, entityId, from_alarms, from_other }) => {
        return {
          url: `/api/inkle/billing/group/${groupId}/entity/${entityId}/cart/checkout/`,
          method: "PUT",
          body: {
            product_datas: productData,
            from_alarms,
            from_other,
          },
        };
      },
    }),

    getBrexPoints: build.query<
      {
        connection:
          | {
              item_id: string;
              connection_available: true;
              balance: number;
              rate: number;
              quote_id: string;
              available_usd: number;
            }
          | {
              item_id: string;
              connection_available: false;
              quote_id: string;
              available_usd: number;
            }
          | null;
      },
      {
        entityId: string;
      }
    >({
      query: ({ entityId }) => {
        return {
          url: `/api/inkle/payments/entity/${entityId}/brex-points/`,
        };
      },
      providesTags: (res) => (res ? [{ type: "CONNECTION" }] : []),
    }),
  }),
  overrideExisting: false,
});

export const {
  useApplyCreditMutation,
  useApplyGroupCouponMutation,
  useGetGroupCouponQuery,
  useGetAllSavedCardsQuery,
  useDeleteCardMutation,
  useGetTagsForCategoryQuery,
  useGetPricingTiersQuery,
  useGetBillingInfoQuery,
  usePatchBillingEmailMutation,
  useAddProductsToCartAndCheckoutMutation,
  useGetBrexPointsQuery,
  useLazyGetBrexPointsQuery,
} = billingApis;
