export const itemTypesOptions = [
  { label: "Product", value: "PRODUCT" },
  { label: "Service", value: "SERVICE" },
];

export const sourceFilterOptions = [
  {
    value: "STRIPE",
    label: "Stripe",
  },
  {
    value: "MANUAL",
    label: "Manually added",
  },
];

export const typeFilterOptions = [
  {
    value: "PRODUCT",
    label: "Product",
  },
  {
    value: "SERVICE",
    label: "Services",
  },
];
