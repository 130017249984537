import { Chart as ChartJS } from "chart.js";
import { ChartData, ChartOptions, TooltipItem } from "chart.js";
import "chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm";
import { Doughnut } from "react-chartjs-2";
import { currency } from "utils/Currency";
import ChartsJSDataLabels from "chartjs-plugin-datalabels";

const Colors = ["#5622FF", "#784EFF", "#9A7AFF", "#EEE9FF", "#F7F4FF"];
const LabelColor: { [key: string]: string } = {
  "#5622FF": "#F7F4FF",
  "#784EFF": "#F7F4FF",
  "#9A7AFF": "#F7F4FF",
  "#EEE9FF": "#110733",
  "#F7F4FF": "#110733",
};

type Dataset = {
  backgroundColor: (string | CanvasGradient | CanvasPattern)[];
  borderColor?: (string | CanvasGradient | CanvasPattern)[];
  borderWidth?: number | number[];
  data: number[];
};

const options: ChartOptions<"doughnut"> = {
  responsive: true,
  maintainAspectRatio: false,
  aspectRatio: 1,
  layout: {
    padding: 4,
  },
  plugins: {
    legend: {
      display: true,
      position: "right",
      fullSize: true,
      labels: {
        boxWidth: 20,
        padding: 20,
        usePointStyle: true,
        pointStyle: "rectRounded",
        //@ts-ignore
        generateLabels: (chart: ChartJS<"doughnut">) => {
          const data = chart.data;
          if (data.labels?.length) {
            return data.labels.map((label, index) => {
              const dataset = data.datasets[0] as Dataset;
              const total = dataset.data.reduce(
                (acc: number, value: number) => acc + value,
                0
              );
              const value = dataset.data[index] as number;
              const percentage = ((value / total) * 100).toFixed(2);

              const formattedLabel = `${label}  ${percentage}%`;
              const datasetMeta = chart.getDatasetMeta(0);

              return {
                text: formattedLabel,
                padding: 3,
                fillStyle: (dataset.backgroundColor[index] as string) || "#000",
                strokeStyle: dataset.borderColor
                  ? (dataset.borderColor[index] as string)
                  : "#000",
                lineWidth: 0,
                hidden:
                  !chart.isDatasetVisible(0) ||
                  //@ts-ignore
                  datasetMeta.data[index]?.hidden ||
                  false,
                index: index,
                pointStyle: "rectRounded",
              };
            });
          }
          return [];
        },
      },
    },
    tooltip: {
      callbacks: {
        title: (tooltipItems: TooltipItem<"doughnut">[]) => {
          const context = tooltipItems[0];
          const value = context.raw as number;

          const percentage = (
            (value /
              context.dataset.data.reduce((a: number, b: number) => a + b, 0)) *
            100
          ).toFixed(2);
          return `${context.label}    ${percentage}%`;
        },
        label: (context: TooltipItem<"doughnut">) => {
          return `${context.label}: ${currency({
            amount: context?.raw as number,
          })}`;
        },
      },
      padding: 10,
      caretPadding: 10,
      boxPadding: 8,
      titleMarginBottom: 10,
      bodySpacing: 8,
      footerMarginTop: 10,
    },
    datalabels: {
      display: true,
      formatter: (value: number, context: any) => {
        const percentage = (
          (value /
            context.chart.data.datasets[0].data.reduce(
              (a: number, b: number) => a + b,
              0
            )) *
          100
        ).toFixed(2);
        return Number(percentage) > 5 ? `${percentage}%` : null;
      },
      color: (context: any) => {
        const chartColor =
          context.chart.data.datasets[0].backgroundColor[context.dataIndex];
        return LabelColor[chartColor as string] || "#110733";
      },
      font: (context) => ({
        size: 12,
        weight: "normal",
      }),
    },
  },
};

export const DoughnutChart = ({
  topFiveCategories,
}: {
  topFiveCategories: Array<{
    amount: number;
    category_name: string;
  }>;
}) => {
  const data: ChartData<"doughnut", number[], string> = {
    labels: topFiveCategories.map(({ category_name }) => category_name),
    datasets: [
      {
        data: topFiveCategories.map(({ amount }) => Math.abs(amount)),
        borderWidth: 0,
        hoverBorderWidth: 3,
        hoverBorderColor: Colors,
        hoverBackgroundColor: Colors,
        backgroundColor: Colors,
        hoverOffset: 8,
      },
    ],
  };

  return (
    // @ts-ignore
    <Doughnut data={data} options={options} plugins={[ChartsJSDataLabels]} />
  );
};
