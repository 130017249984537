import * as Popover from "@radix-ui/react-popover";
import { useAppSelector } from "hooks/useAppSelector";
import { useEffect, useRef } from "react";

export const AddCommentAnchor = ({
  contentId,
  children,
  ...rest
}: {
  contentId: string;
  children: React.ReactNode;
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const openComment = useAppSelector((state) => state.openComment.openComment);

  useEffect(() => {
    if (contentId === openComment && ref.current) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [contentId, openComment]);

  return (
    <Popover.Anchor {...rest} asChild ref={ref}>
      {children}
    </Popover.Anchor>
  );
};
