import React from "react";
import { ComplianceDetail } from "types/Models/calendar";
import { NewDeadlineCard } from "./NewDeadlineCard";

export const deadlineStatusName = {
  NOT_STARTED: "Not started",
  ONGOING: "Ongoing",
  COMPLETED: "Completed",
  ARCHIVED: "Archived",
  OUTSIDE_INKLE: "External Filing",
  NOT_APPLICABLE: "Not applicable",
  TASK_CREATION_IN_PROGRESS: "Launch in progress",
};

export const AlarmCard = ({
  deadline,
  isAlarmTask,
}: {
  deadline: ComplianceDetail;
  isAlarmTask?: boolean;
}) => {
  return (
    <NewDeadlineCard
      key={deadline.uuid}
      description={
        deadline.task_info?.description! ||
        deadline.base_task_template_info?.description!
      }
      taskTemplateId={deadline.base_task_template_info?.base_task_template_id}
      deadlinePassed={false}
      date={deadline.deadline}
      title={
        deadline.task_info?.title || deadline.base_task_template_info?.title
      }
      deadlineStatus={{
        displayName: deadlineStatusName[deadline.filing_state],
        type: deadline.filing_state,
      }}
      extension={deadline.extension}
      taskId={deadline.task_info?.task_id}
      uuid={deadline.uuid}
      isAlarmTask={isAlarmTask}
      deadline={deadline}
    />
  );
};

export const CurrentDeadlines = ({
  deadlines,
}: {
  deadlines: ComplianceDetail[];
}) => {
  return (
    <div className="t-space-y-6">
      {deadlines.map((deadline) => (
        <AlarmCard key={deadline.uuid} deadline={deadline} />
      ))}
    </div>
  );
};
