import React from "react";

export const LoadingCircle = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.047 3.00586C12.2915 3.57629 13.3036 4.55519 13.9151 5.78005C14.5267 7.00492 14.7009 8.40209 14.409 9.73964C14.1171 11.0772 13.3765 12.2747 12.3102 13.1334C11.2439 13.992 9.91605 14.4602 8.547 14.4602C7.17796 14.4602 5.85009 13.992 4.7838 13.1334C3.71751 12.2747 2.97693 11.0772 2.685 9.73964C2.39307 8.40209 2.56734 7.00492 3.17888 5.78005C3.79043 4.55519 4.80246 3.57629 6.047 3.00586"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
