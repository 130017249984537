import { CheckoutModal } from "components/CheckoutModal/CheckoutModal";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import {
  IN_PROGRESS,
  PENDING,
  WORK_IN_PROGRESS,
} from "constants/billingInvoiceStatuses";
import { useCurrentGroup } from "hooks/useCurrentGroup";
import { useGetBillingInvoicesQuery } from "store/apis/billingInvoice";
import { useModal } from "hooks/useModal";

const EntityInvoiceList = ({ entityId }: { entityId: string }) => {
  const group = useCurrentGroup();
  const entity = group?.entities?.find((e) => e.uuid === entityId);
  const checkoutModal = useModal();

  const { data: invoices } = useGetBillingInvoicesQuery(
    {
      entityId: entityId,
      pageNumber: 1,
      groupId: group.uuid!,
      paymentStatuses: [PENDING, IN_PROGRESS, WORK_IN_PROGRESS].join(","),
    },
    { skip: !group.uuid }
  );

  if (!invoices?.group_invoices || invoices.group_invoices.length === 0) {
    return null;
  }

  return (
    <div className="t-flex t-flex-col t-gap-2 t-min-h-full">
      <p className="t-text-subtitle">{entity?.name}</p>

      {invoices.group_invoices
        .filter((i) => !isNaN(Number(i.amount)))
        .map((invoice) => (
          <div
            className="t-flex t-justify-between t-text-text-60"
            key={invoice.uuid}
          >
            <p className="t-m-0">{invoice.name}</p>
            <p className="t-m-0">
              <AmountSuperScript
                amount={
                  invoice.discount
                    ? Number(invoice.amount) - Number(invoice.discount)
                    : Number(invoice.amount)
                }
              />
            </p>
          </div>
        ))}

      <div className="t-border-0 t-border-solid t-border-t t-border-neutral-10" />

      <div className="t-flex t-justify-between t-text-subtitle-sm">
        <p className="t-m-0">Amount Overdue</p>
        <p className="t-m-0">
          <AmountSuperScript amount={invoices.due_amount} />
        </p>
      </div>

      <div className="t-sticky t-bottom-0 t-mt-auto">
        <Button block customType="primary" onClick={checkoutModal.open}>
          <div className="t-flex t-gap-2">
            <span>Pay</span> <AmountSuperScript amount={invoices.due_amount} />
          </div>
        </Button>
      </div>

      {invoices && (
        <CheckoutModal
          entityId={entityId}
          type="invoices"
          onInvoicePaid={checkoutModal.close}
          open={checkoutModal.isOpen}
          onClose={checkoutModal.close}
          invoices={invoices.group_invoices
            .filter((i) => !isNaN(Number(i.amount)))
            .filter(Boolean)}
        />
      )}
    </div>
  );
};

export const PayDebt = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const group = useCurrentGroup();

  return (
    <>
      <Modal.Root open={isOpen} onOpenChange={onClose}>
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>Overdue Invoices</Modal.Title>
            <Modal.Close />
          </Modal.Header>

          <Modal.Body>
            {group?.entities && (
              <div>
                <p className="t-text-red t-mb-6">
                  Please clear the overdue invoices to access the platform
                </p>
                <div className="t-flex t-flex-col t-gap-3">
                  <div>
                    <div className="t-h-[330px] t-overflow-auto t-space-y-5">
                      {group.entities.map((entity) => (
                        <EntityInvoiceList
                          key={entity.uuid}
                          entityId={entity.uuid}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </>
  );
};
