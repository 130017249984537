import { useEffect, useState } from "react";
import { useLazyGetForwardedFromMessageQuery } from "store/apis/chat";
import { MessageResponse } from "stream-chat";
import { useChatContext } from "stream-chat-react";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import * as Sentry from "@sentry/browser";

export const useGetMessage = (
  messageID: string,
  forwardMessageId = "",
  channelUrl = ""
) => {
  const { client } = useChatContext();
  const [message, setMessage] =
    useState<MessageResponse<DefaultStreamChatGenerics>>();

  const [getForwardedFromMessage] = useLazyGetForwardedFromMessageQuery();
  useEffect(() => {
    const getMessage = async () => {
      try {
        const { message } = await client.getMessage(messageID);
        setMessage(message);
      } catch (error) {
        try {
          if (forwardMessageId && channelUrl) {
            const data = await getForwardedFromMessage({
              messageID: forwardMessageId,
              channelUrl,
            }).unwrap();

            if (data) {
              setMessage(
                data.message as MessageResponse<DefaultStreamChatGenerics>
              );
            }
          } else {
            console.log(error);

            Sentry.captureException(error);
          }
        } catch (error) {
          console.log(error);
          Sentry.captureException(error);
        }
      }
    };
    if (messageID) {
      getMessage();
    }
  }, [messageID]);

  return message;
};
