export const EmptyTopSpends = () => (
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="21" cy="21" r="20.5" fill="#FAFAFB" stroke="#F3F3F5" />
    <path
      d="M19.8333 29.1667C24.988 29.1667 29.1667 24.988 29.1667 19.8333C29.1667 14.6787 24.988 10.5 19.8333 10.5C14.6787 10.5 10.5 14.6787 10.5 19.8333C10.5 24.988 14.6787 29.1667 19.8333 29.1667Z"
      stroke="#CFCDD6"
      strokeWidth="2.13268"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.5 31.4999L26.425 26.4249"
      stroke="#CFCDD6"
      strokeWidth="2.13268"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <mask id="path-4-inside-1_1053_1925" fill="white">
      <rect x="14" y="17" width="11.0101" height="6.60605" rx="0.49099" />
    </mask>
    <rect
      x="14"
      y="17"
      width="11.0101"
      height="6.60605"
      rx="0.49099"
      stroke="#CFCDD6"
      strokeWidth="1.4"
      mask="url(#path-4-inside-1_1053_1925)"
    />
    <circle cx="17.0279" cy="20.3031" r="1.65151" fill="#CFCDD6" />
    <rect
      x="19.505"
      y="19.202"
      width="4.12878"
      height="0.825756"
      fill="#CFCDD6"
    />
    <rect
      x="19.505"
      y="20.5782"
      width="2.75252"
      height="0.825756"
      fill="#CFCDD6"
    />
  </svg>
);
