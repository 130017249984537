import * as Accordion from "@radix-ui/react-accordion";
import classNames from "classnames";
import {
  BookkeepingAccordionItem,
  BookkeepingAccordionTrigger,
} from "components/BookkeepingAccordion/BookkeepingAccordion";
import BookkeepingAccordionContent from "components/BookkeepingAccordion/BookkeepingAccordionContent";
import BookkeepingAccordionFooter from "components/BookkeepingAccordion/BookkeepingAccordionFooter";
import Loader from "components/design/loader";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { Label } from "components/DesignSystem/TextInput/TextInput";
import { FileInput } from "components/FileInput/FileInput";
import { BalanceSheetIcon } from "components/icons/Navbar/BalanceSheetIcon";
import { CashflowIcon } from "components/icons/Navbar/CashflowIcon";
import { PNLIcon } from "components/icons/Navbar/PNLIcon";
import { DocumentPreviewModal } from "components/PreviewModal";
import { Badge } from "components/Task/Badge";
import {
  COMPLETED,
  IN_CLIENT_REVIEW,
  IN_PROGRESS,
  NOT_APPLICABLE,
  NOT_STARTED,
} from "constants/bookkeepingMonthlySummaryStatus";
import { BYOA, PAYG, PRO } from "constants/subscriptionType";
import dayjs from "dayjs";
import { BOOKS_STATUS, BOOKS_STATUS_TYPE } from "dictionaries";
import { Form, Formik, FormikValues } from "formik";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useDocPreview } from "hooks/useDocPreview";
import { useModal } from "hooks/useModal";
import { useQuery } from "hooks/useQuery";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import {
  useDeleteReportMutation,
  useGetMonthSummaryQuery,
  useGetTransactionMonthlySummariesQuery,
  useMarkMonthCompletedMutation,
  useUpdateMonthSummaryMutation,
} from "store/apis/financialClosing";
import { useLazyGetPreviewUrlQuery } from "store/apis/previewUrl";
import {
  openBillingModal,
  openDeleteMonthCardModal,
  openInvoiceModal,
  setBillingInfoMonthId,
} from "store/slices/financialClosing";
import { FileObject } from "types/Models/fileObject";
import { openLink } from "utils/openLink";
import { AddSummaryModal, Reports } from "./AddSummaryModal";
import { AnnualSummary } from "./AnnualSummary";
import { Billing } from "./BillingInfo";
import DeleteCard from "./DeleteCard";
import { InvoiceModal } from "./InvoiceModal";
import { NoMonthFound } from "./NoMonthFound";

const MonthReportModal = ({
  isOpen,
  onClose,
  monthId,
}: {
  isOpen: boolean;
  onClose: () => void;
  monthId: string;
}) => {
  const [updateMonthSummary] = useUpdateMonthSummaryMutation();
  const { alertToast, successToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();

  const onSubmit = async (values: FormikValues) => {
    const { monthly_observation_overview } = values;

    const monthlyObservationOverview =
      monthly_observation_overview?.uuid || monthly_observation_overview;

    const formData = new FormData();
    formData.append("uuid", monthId);
    formData.append("monthly_observation_overview", monthlyObservationOverview);
    formData.append("status", IN_CLIENT_REVIEW);

    try {
      await updateMonthSummary({
        payload: formData,
        groupId,
        entityId,
      }).unwrap();
      successToast({ message: "Month Updated!" });
      onClose();
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };
  return (
    <Modal.Root open={isOpen} onOpenChange={onClose}>
      <Modal.Content size="regular">
        <Modal.Header>
          <Modal.Title>Add Data & Reports</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Formik
          initialValues={{ monthly_observation_overview: null }}
          onSubmit={onSubmit}
        >
          {({
            isSubmitting,
            setFieldValue,
            values: { monthly_observation_overview },
          }) => {
            return (
              <Form className="all:unset">
                <Modal.Body className="t-flex t-flex-col t-gap-4">
                  <div>
                    <Label>Reports</Label>
                    <FileInput
                      withForm
                      withInkleDocs
                      file={monthly_observation_overview}
                      name="monthly_observation_overview"
                      label="Monthly Observation Review"
                    />
                  </div>
                </Modal.Body>
                <Modal.Footer className="t-flex t-justify-end">
                  <Button
                    customType="primary"
                    size="small"
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                  >
                    Submit for review
                  </Button>
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      </Modal.Content>
    </Modal.Root>
  );
};

const AccordionContent = ({
  status,
  transferPricingVolume,
  expenses,
  noOfTransactions,
  date,
  onStartProcessing,
  onMonthStart,
  membership,
}: {
  status: string;
  transferPricingVolume: null | number;
  expenses: null | number;
  noOfTransactions: null | number;
  date: string;
  onStartProcessing: () => void;
  onMonthStart?: () => void;
  membership: typeof PRO | typeof PAYG | typeof BYOA;
}) => {
  const isBYOA = membership === BYOA;

  if (status === NOT_APPLICABLE) {
    return <BookkeepingAccordionContent.NotApplicable />;
  }

  if (status === NOT_STARTED) {
    return (
      <BookkeepingAccordionContent.Upcoming
        date={date}
        onMonthStart={onMonthStart}
        isBYOA={isBYOA}
      />
    );
  }

  if (
    status === COMPLETED ||
    status === IN_CLIENT_REVIEW ||
    (status === IN_PROGRESS && isBYOA)
  ) {
    return (
      <BookkeepingAccordionContent.SummaryCards
        expenseVolume={expenses}
        transferPricingVolume={`${transferPricingVolume!}`}
        noOfTransactions={noOfTransactions}
      />
    );
  }

  if (status === IN_PROGRESS) {
    return (
      <BookkeepingAccordionContent.Processing
        onStartProcessing={onStartProcessing}
      />
    );
  }

  return null;
};

const AccordionReports = ({
  status,
  profitLossStatement,
  balanceSheet,
  cashFlowStatement,
  quarterlyObservationOverview,
  monthlyObservationOverview,
}: {
  status: string;
  profitLossStatement: FileObject | null;
  balanceSheet: FileObject | null;
  cashFlowStatement: FileObject | null;
  quarterlyObservationOverview: FileObject | null;
  monthlyObservationOverview: FileObject | null;
}) => {
  const openPreview = useDocPreview();
  const { alertToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const [getPreviewUrl] = useLazyGetPreviewUrlQuery();

  const onDownload = async ({ fileId }: { fileId: string }) => {
    try {
      const { data } = await getPreviewUrl({ groupId, fileId });
      openLink(data?.download_url);
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  const isAnyReport =
    Boolean(cashFlowStatement) ||
    Boolean(balanceSheet) ||
    Boolean(profitLossStatement) ||
    Boolean(quarterlyObservationOverview);

  if (!isAnyReport) {
    return null;
  }

  return (
    <div className="t-mt-4 t-flex t-flex-col t-gap-4">
      <span className="t-text-subtitle-sm t-font-medium t-text-text-100">
        Reports
      </span>
      <div className="t-flex t-flex-col t-gap-2">
        {monthlyObservationOverview && (
          <BookkeepingAccordionContent.SummaryReport
            onPreview={() => {
              openPreview(monthlyObservationOverview.uuid);
            }}
            icon={<PNLIcon />}
            label="Monthly Observation Review"
            onDownload={() =>
              onDownload({ fileId: monthlyObservationOverview.uuid })
            }
          />
        )}
        {quarterlyObservationOverview && (
          <BookkeepingAccordionContent.SummaryReport
            onPreview={() => {
              openPreview(quarterlyObservationOverview.uuid);
            }}
            icon={<CashflowIcon />}
            label="Quarterly Observation Overview"
            onDownload={() =>
              onDownload({ fileId: quarterlyObservationOverview.uuid })
            }
          />
        )}
        {cashFlowStatement && (
          <BookkeepingAccordionContent.SummaryReport
            onPreview={() => {
              openPreview(cashFlowStatement.uuid);
            }}
            icon={<CashflowIcon />}
            label="Cashflow Statement"
            onDownload={() => onDownload({ fileId: cashFlowStatement.uuid })}
          />
        )}
        {balanceSheet && (
          <BookkeepingAccordionContent.SummaryReport
            icon={<BalanceSheetIcon />}
            onPreview={() => {
              openPreview(balanceSheet.uuid);
            }}
            label="Balance Sheet"
            onDownload={() => onDownload({ fileId: balanceSheet.uuid })}
          />
        )}
        {profitLossStatement && (
          <BookkeepingAccordionContent.SummaryReport
            onPreview={() => {
              openPreview(profitLossStatement.uuid);
            }}
            icon={<PNLIcon />}
            label="PNL Statement"
            onDownload={() => onDownload({ fileId: profitLossStatement.uuid })}
          />
        )}
        <DocumentPreviewModal />
      </div>
    </div>
  );
};

const AccordionFooter = ({
  status,
  onPay,
  onEdit,
  amount,
  isPaid,
  membership,
  monthUuid,
  showFooter,
}: {
  status: string;
  onPay: () => void;
  onEdit: () => void;
  amount: string;
  isPaid: boolean;
  membership: typeof PRO | typeof PAYG | typeof BYOA;
  monthUuid: string;
  showFooter: boolean;
}) => {
  const { alertToast, successToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const [markMonthCompleted] = useMarkMonthCompletedMutation();
  const dispatch = useAppDispatch();
  const isBYOA = membership === BYOA;
  const onViewInvoice = () => {
    dispatch(openInvoiceModal(monthUuid));
  };

  const {
    close: closeReportModal,
    isOpen: isReportModalOpen,
    open: openReportModal,
  } = useModal();

  const onMarkReviewed = async () => {
    try {
      await markMonthCompleted({
        groupId,
        entityId,
        uuid: monthUuid,
      }).unwrap();
      successToast({ message: "Month Updated!" });
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  const onDelete = () => {
    dispatch(openDeleteMonthCardModal(monthUuid));
  };

  if (isBYOA) {
    return (
      <>
        <BookkeepingAccordionFooter.BYOAFooter
          status={status}
          onMarkReviewed={onMarkReviewed}
          onEdit={onEdit}
          onAddReport={openReportModal}
        />
        <MonthReportModal
          isOpen={isReportModalOpen}
          onClose={closeReportModal}
          monthId={monthUuid}
        />
      </>
    );
  }

  if (showFooter) {
    if (isPaid && membership !== PRO) {
      return <BookkeepingAccordionFooter.Paid viewInvoices={onViewInvoice} />;
    }

    if (status === IN_CLIENT_REVIEW) {
      return (
        <BookkeepingAccordionFooter.InReview
          onPay={onPay}
          amount={amount}
          isPaid={isPaid}
          onEdit={onEdit}
          viewInvoices={onViewInvoice}
          membership={membership}
          onDelete={onDelete}
        />
      );
    }

    if (status === COMPLETED) {
      return (
        <BookkeepingAccordionFooter.Completed
          onPay={onPay}
          isPaid={isPaid}
          amount={amount}
          onEdit={onEdit}
          membership={membership}
          viewInvoices={onViewInvoice}
        />
      );
    }
  }

  return null;
};

export const Summary = () => {
  const query = useQuery();
  const openMonthId = query.get("openMonthId");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [monthId, setMonthId] = useState("");
  const [month, setMonth] = useState("");
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { isAdmin, isCpa, isForeignCA } = useRoleBasedView();
  const [updateMonthSummary] = useUpdateMonthSummaryMutation();
  const [deleteReport] = useDeleteReportMutation();
  const dispatch = useAppDispatch();
  const { alertToast, successToast } = useToast();

  const {
    data: monthlySummary,
    isSuccess: isGetMonthSuccess,
    isFetching: isGetMonthFetching,
  } = useGetMonthSummaryQuery(
    { entityId, groupId, uuid: monthId },
    { skip: !groupId || !entityId || !monthId, refetchOnMountOrArgChange: true }
  );

  const { membership } = monthlySummary || {};

  const {
    data: monthlySummaries = [],
    isLoading,
    isSuccess,
  } = useGetTransactionMonthlySummariesQuery(
    {
      groupId,
      entityId,
    },
    { skip: !groupId || !entityId }
  );

  const isEmpty = monthlySummaries.every(
    ({ monthly_transaction_summaries }) =>
      monthly_transaction_summaries.length === 0
  );

  const monthClosingStart = async (values: FormikValues) => {
    const {
      number_of_transactions,
      expenses,
      profit_loss_statement,
      cash_flow_statement,
      balance_sheet,
      quarterly_observation_overview,
      assignee,
      team,
    } = values;

    const localExpenses = expenses === "" ? null : expenses;
    const localNumberOfTransactions =
      number_of_transactions === "" ? null : number_of_transactions;

    const files: any = {
      profit_loss_statement:
        profit_loss_statement?.uuid || profit_loss_statement,
      cash_flow_statement: cash_flow_statement?.uuid || cash_flow_statement,
      balance_sheet: balance_sheet?.uuid || balance_sheet,
      quarterly_observation_overview:
        quarterly_observation_overview?.uuid || quarterly_observation_overview,
    };

    const formData = new FormData();
    formData.append("uuid", monthId);
    formData.append("number_of_transactions", localNumberOfTransactions);
    formData.append("expenses", localExpenses);
    formData.append("assignee", assignee);
    for (var key in files) {
      if (files[key]) {
        formData.append(key, files[key]);
      }
    }

    try {
      membership === BYOA
        ? formData.append("status", IN_PROGRESS)
        : formData.append("status", IN_CLIENT_REVIEW);

      await updateMonthSummary({
        payload: formData,
        groupId,
        entityId,
      }).unwrap();

      setIsModalOpen(false);
      successToast({ message: "Month Updated!" });
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  const onPay = ({ monthId }: { monthId: string }) => {
    dispatch(setBillingInfoMonthId(monthId));
    dispatch(openBillingModal());
  };

  const onReportDelete = async ({ field }: { field: Reports }) => {
    try {
      await deleteReport({
        entityId,
        groupId,
        field,
        uuid: monthId,
        reportFrom: "update_transactions_monthly_summary",
      }).unwrap();
      successToast({ message: `Report deleted` });
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  const closeAddSummaryModal = (open: boolean) => {
    setMonthId("");
    setIsModalOpen(open);
    setMonth("");
  };

  const onDelete = ({ monthUuid }: { monthUuid: string }) => {
    dispatch(openDeleteMonthCardModal(monthUuid));
  };

  return (
    <Async.Root {...{ isLoading, isEmpty, isSuccess }}>
      <Async.Empty>
        <NoMonthFound />
      </Async.Empty>
      <Async.Success>
        <div className="t-grid t-grid-cols-1 t-gap-6 md:t-grid-cols-[57%_39%]">
          <div className="t-flex t-flex-col t-gap-8">
            {monthlySummaries.map(
              ({ uuid, name, monthly_transaction_summaries = [] }) => (
                <>
                  {monthly_transaction_summaries.length > 0 && (
                    <div className="t-flex t-flex-col t-gap-3" key={uuid}>
                      <span className="t-text-subtitle">{name}</span>
                      <Accordion.Root
                        className="t-flex t-flex-col t-gap-4"
                        type="single"
                        collapsible
                        defaultValue={
                          openMonthId || monthly_transaction_summaries?.[0].uuid
                        }
                      >
                        {monthly_transaction_summaries.map(
                          ({
                            status,
                            expenses,
                            transfer_pricing_volume,
                            number_of_transactions,
                            quarterly_observation_overview,
                            monthly_observation_overview,
                            date,
                            uuid: monthUuid,
                            cash_flow_statement,
                            profit_loss_statement,
                            balance_sheet,
                            invoice,
                            membership,
                            end_date,
                            is_historical,
                          }) => {
                            const name = (
                              <>
                                {dayjs(date).format("MMMM YYYY")}
                                {end_date && (
                                  <> - {dayjs(end_date).format("MMMM YYYY")}</>
                                )}
                              </>
                            );
                            return (
                              <BookkeepingAccordionItem
                                value={monthUuid}
                                key={monthUuid}
                              >
                                <BookkeepingAccordionTrigger
                                  name={name}
                                  isDeleteAllowed={
                                    (isCpa || isAdmin || isForeignCA) &&
                                    status === NOT_STARTED
                                  }
                                  onDelete={() => onDelete({ monthUuid })}
                                  description={
                                    <>
                                      <div
                                        data-testid="monthly-summary-card"
                                        className={classNames(
                                          "t-w-max t-rounded t-px-2 t-py-1 t-text-overline",
                                          {
                                            "t-bg-yellow-30":
                                              membership === PRO,
                                            "t-bg-purple-20":
                                              membership === PAYG ||
                                              membership === BYOA ||
                                              is_historical,
                                          }
                                        )}
                                      >
                                        {is_historical
                                          ? "HISTORICAL CLEANUP"
                                          : membership}
                                      </div>
                                    </>
                                  }
                                  statusBadge={
                                    //@ts-ignore
                                    <Badge type={BOOKS_STATUS_TYPE[status]}>
                                      <span
                                        role="status"
                                        className="t-sr-only"
                                        aria-label={BOOKS_STATUS[status]}
                                      />
                                      {BOOKS_STATUS[status]}
                                    </Badge>
                                  }
                                  status={status}
                                />
                                <Accordion.Content>
                                  <AccordionContent
                                    membership={membership}
                                    status={status}
                                    expenses={expenses}
                                    date={date}
                                    transferPricingVolume={
                                      transfer_pricing_volume
                                    }
                                    noOfTransactions={number_of_transactions}
                                    onStartProcessing={() => {
                                      setIsModalOpen(true);
                                      setMonthId(monthUuid);
                                      setMonth(date);
                                    }}
                                    onMonthStart={() => {
                                      setIsModalOpen(true);
                                      setMonthId(monthUuid);
                                      setMonth(date);
                                    }}
                                  />
                                  <AccordionReports
                                    status={status}
                                    profitLossStatement={profit_loss_statement}
                                    balanceSheet={balance_sheet}
                                    cashFlowStatement={cash_flow_statement}
                                    quarterlyObservationOverview={
                                      quarterly_observation_overview
                                    }
                                    monthlyObservationOverview={
                                      monthly_observation_overview
                                    }
                                  />
                                  <AccordionFooter
                                    onEdit={() => {
                                      setIsModalOpen(true);
                                      setMonthId(monthUuid);
                                      setMonth(date);
                                    }}
                                    membership={membership}
                                    amount={invoice?.amount!}
                                    isPaid={invoice?.status === "Paid"}
                                    status={status}
                                    monthUuid={monthUuid}
                                    onPay={() => {
                                      onPay({
                                        monthId: monthUuid,
                                      });
                                    }}
                                    showFooter={
                                      isCpa ||
                                      isAdmin ||
                                      isForeignCA ||
                                      Boolean(invoice)
                                    }
                                  />
                                </Accordion.Content>
                              </BookkeepingAccordionItem>
                            );
                          }
                        )}
                      </Accordion.Root>
                    </div>
                  )}
                </>
              )
            )}
          </div>

          <div className="t-sticky t-top-0 t-h-min">
            <AnnualSummary />
          </div>
          <Modal.Root open={isModalOpen} onOpenChange={closeAddSummaryModal}>
            <Modal.Content size="regular">
              <>
                {isGetMonthFetching || !isGetMonthSuccess ? (
                  <Loader />
                ) : (
                  <>
                    <Modal.Header>
                      <div className="t-w-full">
                        <Modal.Title>
                          {dayjs(monthlySummary?.date).format("MMMM YYYY")}
                          {monthlySummary?.end_date && (
                            <>
                              -
                              {dayjs(monthlySummary?.end_date).format(
                                "MMMM YYYY"
                              )}
                            </>
                          )}
                        </Modal.Title>
                        <Modal.Subtitle>Data & Reports</Modal.Subtitle>
                      </div>
                      <Modal.Close />
                    </Modal.Header>
                    <AddSummaryModal
                      onSubmit={monthClosingStart}
                      summary={monthlySummary}
                      summaryFor="month"
                      month={month}
                      onReportDelete={({ field }) => onReportDelete({ field })}
                      isDeleteAllowed={true}
                      onClose={() => closeAddSummaryModal(false)}
                    />
                  </>
                )}
              </>
            </Modal.Content>
          </Modal.Root>
          <Billing />
          <InvoiceModal />
          <DeleteCard />
        </div>
      </Async.Success>
    </Async.Root>
  );
};
