import { EntityDataAttribute, EntityStateData } from "types/Models/entity";
import { emptyApi } from "./emptyApi";

export const entityApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getEntityDataAttributes: build.query<
      EntityDataAttribute[],
      { groupId: string; entityId: string }
    >({
      query: ({ groupId, entityId }) => ({
        url: `/api/inkle/company/group/${groupId}/entity/${entityId}/data_attributes/`,
      }),
      providesTags: ["ENTITY_DATA_ATTRIBUTES"],
    }),

    getEntityTree: build.query({
      query: ({ groupId }) => ({
        url: `/api/inkle/company/group/${groupId}/entities/tree/`,
      }),
    }),

    editEntityDataAttributes: build.mutation<
      EntityDataAttribute,
      {
        groupId: string;
        entityId: string;
        payload: Record<string, EntityDataAttribute["value"]>;
      }
    >({
      query: ({ groupId, entityId, payload }) => {
        return {
          url: `/api/inkle/company/group/${groupId}/entity/${entityId}/data_attributes/`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: (res) =>
        res ? ["ENTITY_DATA_ATTRIBUTES", "MISSING_DATA_POINTS"] : [],
    }),

    getEntityAllStateData: build.query<
      EntityStateData[],
      { groupId: string; entityId: string }
    >({
      query: ({ groupId, entityId }) => ({
        url: `/api/inkle/company/group/${groupId}/entity/${entityId}/state/data_attributes/`,
      }),
      providesTags: ["ENTITY_DATA_ATTRIBUTES"],
    }),

    getEntityStateData: build.query<
      EntityDataAttribute[],
      { groupId: string; entityId: string; stateId: string }
    >({
      query: ({ groupId, entityId, stateId }) => ({
        url: `/api/inkle/company/group/${groupId}/entity/${entityId}/state/${stateId}/v2/`,
      }),
      providesTags: ["ENTITY_DATA_ATTRIBUTES"],
    }),

    editEntityStateData: build.mutation<
      EntityDataAttribute,
      {
        groupId: string;
        entityId: string;
        payload: Record<string, EntityDataAttribute["value"]>;
        stateId: string;
      }
    >({
      query: ({ groupId, entityId, stateId, payload }) => {
        return {
          url: `/api/inkle/company/group/${groupId}/entity/${entityId}/state/${stateId}/v2/`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: (res) =>
        res ? ["ENTITY_DATA_ATTRIBUTES", "MISSING_DATA_POINTS"] : [],
    }),

    deleteEntityStateData: build.mutation<
      void,
      {
        groupId: string;
        entityId: string;
        stateId: string;
      }
    >({
      query: ({ groupId, entityId, stateId }) => {
        return {
          url: `/api/inkle/company/group/${groupId}/entity/${entityId}/state/${stateId}/v2/`,
          method: "DELETE",
        };
      },
      invalidatesTags: (res) =>
        res ? ["ENTITY_DATA_ATTRIBUTES", "MISSING_DATA_POINTS"] : [],
    }),

    addNewField: build.mutation<
      EntityDataAttribute,
      {
        entityId: string;
        fieldName: string;
        stateId: string;
        fieldType: "STATE_FIELD";
      }
    >({
      query: ({ entityId, stateId, fieldName, fieldType }) => {
        return {
          url: `/api/inkle/company/data_attributes/add/`,
          method: "POST",
          body: {
            entity_id: entityId,
            state_id: stateId,
            field_name: fieldName,
            field_type: fieldType,
          },
        };
      },
      invalidatesTags: (res) => (res ? ["ENTITY_DATA_ATTRIBUTES"] : []),
    }),

    deleteField: build.mutation<
      void,
      {
        dataAttributeId: string;
      }
    >({
      query: ({ dataAttributeId }) => {
        return {
          url: `api/inkle/company/data_attribute/${dataAttributeId}/`,
          method: "DELETE",
        };
      },
      invalidatesTags: (res) => (res ? ["ENTITY_DATA_ATTRIBUTES"] : []),
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetEntityDataAttributesQuery,
  useEditEntityDataAttributesMutation,
  useGetEntityAllStateDataQuery,
  useGetEntityStateDataQuery,
  useEditEntityStateDataMutation,
  useDeleteEntityStateDataMutation,
  useAddNewFieldMutation,
  useDeleteFieldMutation,
  useGetEntityTreeQuery,
} = entityApi;
