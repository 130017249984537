import React from "react";

export const Chat = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 17 17"
      fill="none"
    >
      <g clip-path="url(#clip0_3791_47690)">
        <path
          d="M3.57114 14.4629C3.49832 14.5242 3.40952 14.5634 3.31518 14.5759C3.22084 14.5885 3.12489 14.5739 3.03858 14.5338C2.95227 14.4937 2.8792 14.4298 2.82796 14.3496C2.77671 14.2694 2.74941 14.1762 2.74927 14.0811V4.08105C2.74927 3.94845 2.80195 3.82127 2.89571 3.7275C2.98948 3.63373 3.11666 3.58105 3.24927 3.58105H14.2493C14.3819 3.58105 14.5091 3.63373 14.6028 3.7275C14.6966 3.82127 14.7493 3.94845 14.7493 4.08105V12.0811C14.7493 12.2137 14.6966 12.3408 14.6028 12.4346C14.5091 12.5284 14.3819 12.5811 14.2493 12.5811H5.74927L3.57114 14.4629Z"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.74927 7.08105H10.7493"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.74927 9.08105H10.7493"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3791_47690">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.749268 0.0810547)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
