import { ActionCard } from "components/ActionCard/ActionCard";
import { DashboardLayout } from "components/DashboardLayout";
import { Button } from "components/DesignSystem/Button/Button";
import { Header } from "components/DesignSystem/Header/Header";
import { ConditionalLink } from "components/conditionalLink";
import { LargeCard } from "components/home/Home";
import { useAuth } from "hooks/useAuth";
import { useHistory, useRouteMatch } from "react-router-dom";

export const RaiseHome = () => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const { first_name } = useAuth();

  return (
    <DashboardLayout header={<Header v2 title={`Welcome ${first_name}`} />}>
      <div className="t-pb-8 t-grid t-gap-6 t-flex-wrap md:t-grid-cols-[repeat(auto-fill,minmax(462px,1fr))] t-grid-cols-[repeat(auto-fill,1fr)]">
        <LargeCard>
          <ActionCard
            className="t-bg-[url('static/images/LightMetallic.svg')] t-bg-no-repeat t-bg-right-bottom"
            name="Perks"
            content={
              <span className="t-text-body t-text-text-30 t-mb-10">
                Enjoy Perks from over 100 top companies as a part of the Inkle
                Community.
              </span>
            }
            action={
              <ConditionalLink to={`${path}/perks`}>
                <Button size="small">Redeem perks</Button>
              </ConditionalLink>
            }
          />
        </LargeCard>
        <LargeCard>
          <ActionCard
            className="t-bg-surface-lighter-grey"
            name="Raise"
            content={
              <span className="t-text-body t-text-text-30 t-mb-5">
                We know, growth is tough. Raise is a platform that aids founders
                fundraise, network & scale effectively. Get access to over 50
                active investors for your start-up.
              </span>
            }
            action={
              <ConditionalLink to={`${path}/raise`}>
                <Button size="small"> Apply for Raise</Button>
              </ConditionalLink>
            }
          />
        </LargeCard>
        <LargeCard>
          <ActionCard
            className="t-bg-[url('static/images/LightMetallic.svg')] t-bg-no-repeat t-bg-right-bottom"
            name="Investor Updates"
            content={
              <span className="t-text-body t-text-text-30 t-mb-10">
                Track Key Metrics & Update Investors effectively in this
                comprehensive tool
              </span>
            }
            action={
              <Button
                size="small"
                onClick={() => {
                  history.push(`${path}/investor-updates`);
                }}
              >
                Get Started
              </Button>
            }
          />
        </LargeCard>
        <LargeCard>
          <ActionCard
            name="Events"
            className="t-bg-surface-lighter-grey"
            content={
              <span className="t-text-body t-text-text-30 t-mb-5">
                A series of exciting events for early-stage founders! Come
                network & engage with the brightest minds in the ecosystem.
              </span>
            }
            action={
              <ConditionalLink to="https://www.inkle.io/resources/events/talks">
                <Button size="small">Join events</Button>
              </ConditionalLink>
            }
          />
        </LargeCard>
      </div>
    </DashboardLayout>
  );
};
