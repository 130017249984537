import { AppLayout } from "components/AppLayout/AppLayout";
import { ComingSoon } from "components/ComingSoon/ComingSoon";
import { Home } from "components/icons/LeftNav/Home";
import { MyEntities } from "components/icons/LeftNav/MyEntities";
import { InkleSalesTaxSymbol } from "components/icons/Logos/InkleSalesTaxSymbol";
import { NavAcrossApps } from "components/NavAcrossApps/NavAcrossApps";
import { RoutesAccrossApps } from "components/RoutesAcrossApps/RoutesAcrossApps";
import {
  LeftBar,
  LeftBarItem,
  LeftBarLogo,
  LeftBarSeparator,
} from "components/Sidebar/LeftBar";
import { TopBar } from "components/TopBar/TopBar";
import { Switch, useLocation, useRouteMatch } from "react-router-dom";
import { RestrictedRoute } from "RestrictedRoute";
import "static/styles/containers/dashboard.css";

import GroupSelect from "components/dashboard/sideBar/groupSelect";
import { FilingDetails } from "components/fileTax/FilingDetails";
import { Filings } from "components/icons/LeftNav/Filings";
import { InkleSalesTaxLogo } from "components/icons/Logos/InkleSalesTaxLogo";
import { SalesTaxServices } from "components/SalesTaxService/SalesTaxService";
import TemplateMenu from "components/tasks/modal/AllTemplatesModal";
import { FOREIGN_CA } from "constants/onBoarding";
import { useAuth } from "hooks/useAuth";
import { parse, stringify } from "qs";
import { UpgradeTaxPlan } from "./Tax/UpgradeTaxPlan";
import { useCurrentAppContext } from "contexts/CurrentAppContext";
import { useEffect } from "react";

const Navbar = () => {
  const { url } = useRouteMatch();
  const { type } = useAuth();
  const location = useLocation();
  const parsedSearch = parse(location.search, { ignoreQueryPrefix: true });

  const search = stringify(
    {
      entity: parsedSearch.entity,
    },
    { skipNulls: true, addQueryPrefix: true }
  );

  return (
    <div className="t-h-full t-flex t-flex-col t-justify-between">
      <div>
        {type === FOREIGN_CA && (
          <div className="t-m-2">
            <GroupSelect />
          </div>
        )}
        <LeftBarItem exact to="/" icon={Home}>
          Home
        </LeftBarItem>

        <LeftBarItem to="/state-filings" icon={MyEntities}>
          State Filings
        </LeftBarItem>

        <LeftBarItem to={`/services${search}`} icon={Filings}>
          Services
        </LeftBarItem>
      </div>
      <div>
        <LeftBarSeparator />
        <NavAcrossApps />
      </div>
    </div>
  );
};

export const SalesTaxDashboard = () => {
  const { path, url } = useRouteMatch();
  const { dispatch: currentAppDispatch } = useCurrentAppContext();

  useEffect(() => {
    currentAppDispatch({
      type: "SET_CURRENT_APP",
      payload: { currentApp: "Sales Tax" },
    });
  }, []);

  return (
    <AppLayout
      sidebar={
        <LeftBar
          logo={
            <LeftBarLogo
              logo={InkleSalesTaxLogo}
              symbol={InkleSalesTaxSymbol}
            />
          }
          showCollapse
        >
          <Navbar />
        </LeftBar>
      }
      topbar={
        <TopBar
          upgradeButton={<UpgradeTaxPlan />}
          logo={
            <LeftBarLogo
              logo={InkleSalesTaxLogo}
              symbol={InkleSalesTaxSymbol}
            />
          }
          nav={
            <LeftBar>
              <Navbar />
            </LeftBar>
          }
        />
      }
      // banner={
      //   <DashboardBanner
      //     isCustomer={isCustomer!}
      //     subscriptionInactive={subscriptionInactive}
      //     stripePromise={stripePromise}
      //   />
      // }
    >
      <Switch>
        <RestrictedRoute exact path={`${path}/services/addtask`}>
          <TemplateMenu
            to={`/sales-tax/services`}
            taskCategoryType="SALES_TAX"
          />
        </RestrictedRoute>
        <RestrictedRoute path={`${path}/services/:taskId`}>
          <FilingDetails isFromServices parentRoute="/sales-tax/services" />
        </RestrictedRoute>
        <RestrictedRoute
          path={`${path}/state-filings`}
          component={ComingSoon}
        />
        <RestrictedRoute path={`${path}/services/`}>
          <SalesTaxServices />
        </RestrictedRoute>
        <RestrictedRoute exact path={path} component={ComingSoon} />

        <RoutesAccrossApps />
      </Switch>
    </AppLayout>
  );
};
