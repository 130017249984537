import React from "react";

export const InkleAdminBooksLogo = () => {
  return (
    <svg
      width="125"
      height="16"
      viewBox="0 0 125 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.68281 9.12939H8.45458C8.40938 9.12939 8.41942 9.22102 8.56228 9.29428C8.67656 9.35288 8.69283 9.55721 8.68667 9.65205V16.0004H11.3009V9.72289C11.205 9.29867 10.4539 9.15047 10.0904 9.12939H9.68281Z"
        fill="url(#paint0_linear_2382_170)"
      />
      <path
        d="M5.43134 6.85107H4.20311C4.15791 6.85107 4.16796 6.97309 4.31081 7.07064C4.4251 7.14868 4.44136 7.42077 4.43521 7.54705V16.0008H7.04939V7.64139C6.95351 7.07649 6.20248 6.87914 5.83894 6.85107H5.43134Z"
        fill="url(#paint1_linear_2382_170)"
      />
      <path
        d="M1.25214 4.26709H0.0239133C-0.0212873 4.26709 -0.0112427 4.42312 0.131613 4.54787C0.245898 4.64766 0.262163 4.9956 0.25601 5.1571V15.9676H2.87019V5.27774C2.77431 4.55535 2.02328 4.30298 1.65974 4.26709H1.25214Z"
        fill="url(#paint2_linear_2382_170)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.14279 2.28103H0V0H9.14279C10.2781 0 11.9555 0.3305 13.382 1.35547C14.8667 2.4223 15.9999 4.19035 15.9999 6.8431V15.9672H13.7142V6.8431C13.7142 4.93379 12.9426 3.85054 12.0464 3.2066C11.092 2.52079 9.91229 2.28103 9.14279 2.28103Z"
        fill="url(#paint3_linear_2382_170)"
      />
      <path
        d="M24 1.65197C24 0.64965 24.624 0 25.6046 0C26.5674 0 27.1914 0.64965 27.1914 1.65197C27.1914 2.65428 26.5674 3.32249 25.6046 3.32249C24.624 3.32249 24 2.65428 24 1.65197ZM27.0309 15.9071H24.1783V4.77028H27.0309V15.9071Z"
        fill="url(#paint4_linear_2382_170)"
      />
      <path
        d="M35.7831 10.3201C35.7831 8.68674 34.9629 7.61018 33.6971 7.61018C32.5025 7.61018 31.6289 8.76099 31.6289 10.3387V15.9071H28.7763V4.77028H31.6289V6.06958C32.0033 5.23432 33.1087 4.64035 34.2141 4.64035C36.9419 4.64035 38.6357 6.90485 38.6357 10.3201V15.9071H35.7831V10.3201Z"
        fill="url(#paint5_linear_2382_170)"
      />
      <path
        d="M43.2094 15.9071H40.3568V0.129929H43.2094V9.7633L46.8287 4.77028H49.9488L46.3116 10.3387L50.3232 15.9071H46.7039L43.2094 10.9141V15.9071Z"
        fill="url(#paint6_linear_2382_170)"
      />
      <path
        d="M54.2437 15.9071H51.3911V0.129929H54.2437V15.9071Z"
        fill="url(#paint7_linear_2382_170)"
      />
      <path
        d="M62.9166 12.4918H65.9476C65.2166 14.5707 63.3089 15.9999 61.1337 15.9999C58.0137 15.9999 55.8207 13.6426 55.8207 10.3016C55.8207 7.0719 58.085 4.62179 61.0803 4.62179C64.0755 4.62179 66.3398 7.0719 66.3398 10.3016C66.3398 10.6543 66.322 11.0255 66.2685 11.3596H58.816C59.1369 12.4918 60.0105 13.2157 61.1159 13.2157C61.9539 13.2157 62.6136 12.9187 62.9166 12.4918ZM58.816 9.22502H63.3445C63.0236 8.1299 62.1857 7.42457 61.0803 7.42457C59.9749 7.42457 59.1369 8.1299 58.816 9.22502Z"
        fill="url(#paint8_linear_2382_170)"
      />
      <path
        d="M123.083 10.9914C123.083 9.01766 122.071 7.74535 120.505 7.74535C119.054 7.74535 118.01 9.11553 118.01 11.0077V15.9012H116.248V6.11418H118.01V7.94109C118.385 6.81558 119.494 6 120.799 6C123.278 6 124.844 7.97372 124.844 10.9914V15.9012H123.083V10.9914Z"
        fill="#41395C"
      />
      <path
        d="M112.586 3.17871C112.586 2.55887 112.994 2.13477 113.646 2.13477C114.266 2.13477 114.674 2.55887 114.674 3.17871C114.674 3.81487 114.266 4.23898 113.646 4.23898C112.994 4.23898 112.586 3.81487 112.586 3.17871ZM114.527 15.9018H112.765V6.11482H114.527V15.9018Z"
        fill="#41395C"
      />
      <path
        d="M102.458 10.9914C102.458 9.01766 101.447 7.74535 99.8809 7.74535C98.4291 7.74535 97.3852 9.11553 97.3852 11.0077V15.9012H95.6235V6.11418H97.3852V7.94109C97.7604 6.81558 98.8696 6 100.174 6C101.74 6 102.947 6.79927 103.616 8.13683C104.252 6.78296 105.362 6 106.764 6C109.391 6 111.054 7.97372 111.054 10.9914V15.9012H109.293V10.9914C109.293 9.01766 108.281 7.74535 106.699 7.74535C105.264 7.74535 104.22 9.11553 104.22 10.9914V15.9012H102.458V10.9914Z"
        fill="#41395C"
      />
      <path
        d="M92.1331 15.9021V14.0751C91.6927 15.217 90.3062 15.9999 88.8382 15.9999C86.0163 15.9999 84.1567 14.0099 84.1567 10.9922C84.1567 7.99088 86.0163 6.00085 88.8382 6.00085C90.2736 6.00085 91.6927 6.78381 92.1331 7.94194V2.03711H93.8948V15.9021H92.1331ZM85.9184 10.9922C85.9184 12.9496 87.207 14.2383 89.0339 14.2383C90.8771 14.2383 92.1494 12.9496 92.1331 11.0085C92.1331 9.08376 90.8282 7.7462 89.0339 7.7462C87.1907 7.7462 85.9184 9.03483 85.9184 10.9922Z"
        fill="#41395C"
      />
      <path
        d="M70.3398 15.9021L75.951 2.03711H77.8269L83.4381 15.9021H81.5623L80.0779 12.2482H73.7L72.2157 15.9021H70.3398ZM74.3851 10.5355H79.3928L76.8971 4.35337L74.3851 10.5355Z"
        fill="#41395C"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2382_170"
          x1="8.43066"
          y1="12.5649"
          x2="11.3009"
          y2="12.5649"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2382_170"
          x1="4.1792"
          y1="11.4259"
          x2="7.04939"
          y2="11.4259"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2382_170"
          x1="0"
          y1="10.1174"
          x2="2.87019"
          y2="10.1174"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2382_170"
          x1="0"
          y1="7.98362"
          x2="15.9999"
          y2="7.98362"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2382_170"
          x1="24"
          y1="7.99997"
          x2="66.3398"
          y2="7.99997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_2382_170"
          x1="24"
          y1="7.99997"
          x2="66.3398"
          y2="7.99997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_2382_170"
          x1="24"
          y1="7.99997"
          x2="66.3398"
          y2="7.99997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_2382_170"
          x1="24"
          y1="7.99997"
          x2="66.3398"
          y2="7.99997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_2382_170"
          x1="24"
          y1="7.99997"
          x2="66.3398"
          y2="7.99997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
