import { Button } from "components/DesignSystem/Button/Button";
import {
  Combobox,
  OptionData,
} from "components/DesignSystem/Combobox/Combobox";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { SelectAutofillCombobox } from "components/SelectAutofillCombobox";
import { Form, useFormikContext } from "formik";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { useParams } from "react-router-dom";
import { MultiValue, SingleValue } from "react-select";
import { useGetAutofillQuery } from "store/apis/autofill";
import {
  useCreateEntityMutation,
  useGetEntitiesQuery,
  useGetGroupDataQuery,
} from "store/apis/group";
import { useGetCountriesDataQuery } from "store/apis/onboarding";
import { BackendError } from "types/utils/error";
import AddEntity from "../addMoreEntity";
import { COMPANY_ENTITY, PERSONA } from "./AddShareholder";
import { PersonaAutofill } from "types/Models/personaAutofill";
import { DirectorAutofill } from "types/Models/directorAutofill";
import { BankAutofill } from "types/Models/bankAutofill";
import { AddressAutofill } from "types/Models/addressAutofill";
import { NumericInput } from "components/NumericInput/NumericInput";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";

export type ShareholderPayloadType = {
  shareholderType: string;
  persona: string;
  entity: string;
  tin: string;
  ownership: string;
  country: string;
  address: string;
};

export const AddShareholderForm = ({
  entityId: entityIdFromProps,
}: {
  entityId?: string;
}) => {
  const { setFieldValue, values } = useFormikContext<ShareholderPayloadType>();
  const { alertToast, successToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const { entityId } = useParams<{ entityId: string }>();
  const entity = entityId || entityIdFromProps;
  const { isCustomer } = useRoleBasedView();
  const addEntityModal = useModal();
  const { data: countries } = useGetCountriesDataQuery();
  const [createEntity, { isLoading: isCreating }] = useCreateEntityMutation();
  const { data: autofills = [] } = useGetAutofillQuery(
    {
      groupId,
      autofillKey: "authorized_persons",
      entityId: entity!,
    },
    { skip: !groupId || !entity, refetchOnMountOrArgChange: true }
  );
  const {
    data: { groups: [group] = [null] } = {},
    refetch: refetchUserEntities,
  } = useGetEntitiesQuery(
    { include_shareholder: true },
    {
      skip: !isCustomer,
    }
  );
  let { data, refetch: refetchAdminEntities } = useGetGroupDataQuery(
    { groupId, include_shareholder: true },
    { skip: isCustomer }
  );
  const entities = (isCustomer ? group?.entities : data?.entities) || [];

  const refetchAllEntities = async () => {
    try {
      if (isCustomer) await refetchUserEntities().unwrap();
      if (!isCustomer) await refetchAdminEntities().unwrap();
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const addEntityData = async (groupData: {
    company_name: any;
    formation_type_id: any;
    country: any;
    state_id: any;
    entity_type: any;
  }) => {
    try {
      let payload = {
        company_name: groupData.company_name,
        formation_type_id: groupData.formation_type_id,
        country_id: groupData.country,
        state_id: groupData.state_id,
        entity_type: groupData.entity_type,
      };
      const entity = await createEntity({ groupId, payload }).unwrap();
      addEntityModal.close();

      await refetchAllEntities();
      setFieldValue("entity", entity.company_id || "");
      setFieldValue("country", entity.country_id || "");
      successToast({ message: "Entity created successfully" });
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const handlePersonaChange = (personaUuid: string) => {
    const selectedPersona = autofills.find(({ uuid }) => uuid === personaUuid);

    if (selectedPersona) {
      setFieldValue("persona", selectedPersona.uuid || "");
      setFieldValue("tin", selectedPersona.tax_id || "");
      setFieldValue("ownership", selectedPersona.ownership_stake || "");
      const selectedCountry = countries?.find(
        ({ name }) => name === selectedPersona.citizenship
      );
      setFieldValue("country", selectedCountry?.uuid || "");
      setFieldValue("address", selectedPersona.address_id || "");
    }
  };

  const handlePersonaSelect = (
    selectedPersona:
      | PersonaAutofill
      | BankAutofill
      | AddressAutofill
      | DirectorAutofill
  ) => {
    if (selectedPersona) {
      setFieldValue("persona", selectedPersona.uuid || "");
      setFieldValue("tin", (selectedPersona as PersonaAutofill).tax_id || "");
      setFieldValue(
        "ownership",
        (selectedPersona as PersonaAutofill).ownership_stake || ""
      );
      const selectedCountry = countries?.find(
        ({ name }) => name === (selectedPersona as PersonaAutofill).citizenship
      );
      setFieldValue("country", selectedCountry?.uuid || "");
      setFieldValue(
        "address",
        (selectedPersona as PersonaAutofill).address_id || ""
      );
    }
  };

  const handleEntitySelect = (
    selectedOption: MultiValue<OptionData> | SingleValue<OptionData>
  ) => {
    const selectedEntity = entities.find(
      ({ uuid }) => uuid === (selectedOption as SingleValue<OptionData>)?.value
    );

    if (selectedEntity) {
      setFieldValue("entity", selectedEntity.uuid || "");
      setFieldValue("tin", selectedEntity.ein_number || "");
      setFieldValue("country", selectedEntity.country_id || "");
    }
  };

  const selectedCountry = countries?.find(
    ({ uuid }) => uuid === values.country
  );

  const shareholderTypeOptions = [
    { label: "Individual", value: PERSONA },
    {
      label: "Entity",
      value: COMPANY_ENTITY,
    },
  ];

  const selectedShareholderType = values.shareholderType
    ? shareholderTypeOptions.find(
        ({ value }) => value === values.shareholderType
      ) || null
    : null;

  const selectedEntity = entities.find(({ uuid }) => uuid === values.entity);

  return (
    <Form className="t-m-0 t-space-y-3">
      <Combobox
        required
        label="Shareholder type"
        placeholder="Select"
        withForm
        options={shareholderTypeOptions}
        name="shareholderType"
        menuPortalTarget={document.body}
        value={selectedShareholderType}
      />
      {values.shareholderType === PERSONA && (
        <SelectAutofillCombobox
          placeholder="Select"
          entityId={entity!}
          type="authorized_persons"
          withForm
          name="persona"
          selected={values?.persona}
          label="Persona"
          required
          onChange={handlePersonaChange}
          onSelect={handlePersonaSelect}
          menuPortal={null}
        />
      )}

      {values.shareholderType === COMPANY_ENTITY && (
        <Combobox
          label="Entity"
          placeholder="Select"
          withForm
          options={entities?.map((entity) => ({
            value: entity.uuid,
            label: entity.name,
          }))}
          name="entity"
          required
          actions={
            <Button customType="link" onClick={addEntityModal.open}>
              Add new entity
            </Button>
          }
          onChange={handleEntitySelect}
          value={
            selectedEntity && {
              value: selectedEntity.uuid,
              label: selectedEntity.name,
            }
          }
        />
      )}

      {values.shareholderType !== "" && (
        <>
          <TextInput
            name="tin"
            label="TIN"
            placeholder="Enter"
            showErrorOnceTouched
          />
          <NumericInput
            fieldProps={{ name: "ownership" }}
            storeNumeric
            numericProps={{
              fixedDecimalScale: true,
              decimalScale: 2,
              suffix: " %",
              allowNegative: false,
            }}
            label="Ownership"
            required
          />
          <Combobox
            label="Country"
            placeholder="Select"
            withForm
            options={countries?.map((country) => ({
              value: country.uuid,
              label: country.name,
            }))}
            name="country"
            required
            menuPortalTarget={document.body}
            value={
              selectedCountry && {
                label: selectedCountry.name,
                value: selectedCountry.uuid,
              }
            }
          />
          <SelectAutofillCombobox
            withForm
            type="addresses"
            selected={values.address}
            name="address"
            entityId={entity!}
            label="Address"
            onSelect={(newValue) => setFieldValue("address", newValue.uuid)}
          />
        </>
      )}
      <AddEntity
        addEntityData={addEntityData}
        showAddEntity={addEntityModal.isOpen}
        setShowAddEntity={addEntityModal.close}
        showEntityTypeOption
        isCreating={isCreating}
      />
    </Form>
  );
};
