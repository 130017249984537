import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import React from "react";
import { useDispatch } from "react-redux";
import FreePlatformSubscription from "static/images/FreePlatformSubscription.svg";
import GreenTick from "static/images/GreenTick.svg";
import {
  closeFreeSubscription,
  openCardAddModal,
} from "store/slices/subscriptionModal";

interface FreeSubscriptionModalProps {
  openSubscription: boolean;
  closeSubscription: () => void;
  closeModal?: () => void;
}

const FreeSubscriptionModal = ({
  openSubscription,
  closeSubscription,
  closeModal = () => {},
}: FreeSubscriptionModalProps) => {
  const dispatch = useDispatch();

  const handleClick = (e: any) => {
    dispatch(closeFreeSubscription());
    dispatch(openCardAddModal());
    closeModal();
  };

  return (
    <Modal.Root open={openSubscription} onOpenChange={closeSubscription}>
      <Modal.Content size="large">
        <Modal.Header>
          <div></div>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              background:
                "linear-gradient(180deg, #DDD3FF 0%, rgba(221, 211, 255, 0) 60%)",
            }}
            className="t-w-100 t-bg- t-h-[470px] !t-p-12 !t-pb-0"
          >
            <div className="t-w-full t-text-center">
              <img
                src={FreePlatformSubscription}
                alt="FreePlatformSubscription"
              />
            </div>
            <div className="t-text-h6 t-font-semibold t-leading-6 t-text-text-100">
              Platform Subscription
            </div>
            <div className="t-mt-3.5 t-text-[18px] t-font-medium t-leading-5 t-text-text-100">
              <span className="t-text-body t-font-extralight">Try</span>{" "}
              Standard Plan
            </div>
            <div className="t-mt-2.5 t-text-[24px] t-font-bold t-leading-9 t-text-text-100">
              14 days free
            </div>
            <div className="t-mt-1.5 t-text-body t-font-light t-text-text-60">
              Then $300 per year
            </div>
            <div className="t-mt-5 t-w-full t-border t-border-solid t-border-surface-grey"></div>
          </div>
          <div className="t-w-100 !t-p-12 !t-pt-0">
            <li className="t-mt-3">
              Billing starts after the 14-days free trial
            </li>
            <li className="t-mb-6 t-mt-3">Cancel anytime</li>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button customType="primary" block onClick={handleClick}>
            Continue
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};

export default FreeSubscriptionModal;
