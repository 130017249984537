import { number, object, string } from "yup";

export const addProductAndServiceSchema = () => {
  return object({
    name: string().required("Name is required"),
    type: string().required("Type is required"),
    product_category: string().required("Category is required"),
    price: number().required("Price is required"),
    transaction_category_id: string().notRequired().nullable(),
  });
};
