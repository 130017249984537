import { TagCombobox } from "components/TagCombobox/TagCombobox";
import { useToast } from "hooks/useToast";
import { useAddTagsMutation, useRemoveTagsMutation } from "store/apis/taskTags";
import { BackendError } from "types/utils/error";
import { TaskTags } from "types/Models/task";
import { MultiValue, SingleValue } from "react-select";
import { OptionData } from "components/DesignSystem/Combobox/Combobox";

export const FilingTags = ({
  taskId,
  currentTags,
  tags,
  size = "small",
}: {
  taskId: string;
  currentTags: TaskTags[] | undefined;
  tags: TaskTags[];
  size?: "small" | "regular" | "large";
}) => {
  const { alertToast } = useToast();
  const [addTags, { isLoading: isAdding }] = useAddTagsMutation();
  const [removeTags, { isLoading: isRemoving }] = useRemoveTagsMutation();

  const isLoading = Boolean(isAdding || isRemoving);

  const onTagsChange = async (
    selectedTags: MultiValue<OptionData> | SingleValue<OptionData>
  ) => {
    const selectedValues = Array.isArray(selectedTags)
      ? selectedTags
      : selectedTags
      ? [selectedTags]
      : [];

    const addedTag = selectedValues.filter(
      ({ value }) => !currentTags?.some(({ uuid }) => uuid === value)
    )?.[0];

    const removedTag = currentTags?.filter(
      ({ uuid }) => !selectedValues?.some(({ value }) => uuid === value)
    )?.[0];

    try {
      if (removedTag) {
        await removeTags({ taskId, tagId: removedTag?.uuid }).unwrap();
      } else {
        await addTags({ taskId, tagId: addedTag.value }).unwrap();
      }
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  return (
    <TagCombobox
      tags={tags}
      currentTags={currentTags}
      isLoading={isLoading}
      onTagsChange={onTagsChange}
    />
  );
};
