import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { SmallPlusIcon } from "components/icons/export const SmallPlusIcon";
import { PlusIcon } from "components/icons/PlusIcon";
import { Form, Formik } from "formik";
import { merchantEmailAddSchema } from "formValidations/merchantSchema";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import { CellContext } from "react-table-8.10.7";
import { useEditVendorsMutation } from "store/apis/vendors";
import { Merchant } from "types/Models/merchant";
import { Vendor } from "types/Models/vendors";

export const AddVendorEmail = ({
  show,
  closeModal,
  editMerchantId,
}: {
  show: boolean;
  closeModal: () => void;
  editMerchantId: string;
}) => {
  const { alertToast, successToast } = useToast();
  const [editVendor, { isLoading: isEmailAdding }] = useEditVendorsMutation();
  const { uuid: groupId } = useCurrentGroupContext();

  const handleSubmit = async (payload: { email: string }) => {
    try {
      await editVendor({ groupId, uuid: editMerchantId, payload }).unwrap();
      successToast({ message: "Vendor email has been added" });
      closeModal();
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  return (
    <Modal.Root open={show} onOpenChange={closeModal}>
      <Modal.Content size="regular">
        <Modal.Header>
          <Modal.Title>Add Email</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              email: "",
            }}
            onSubmit={handleSubmit}
            validationSchema={merchantEmailAddSchema}
            validateOnChange
            validateOnMount={false}
          >
            {({ isValid }) => (
              <Form className="t-m-0">
                <div className="t-flex t-w-full t-flex-col t-gap-5">
                  <TextInput
                    block
                    type="email"
                    name="email"
                    label="Email"
                    placeholder="vendor@inkle.io"
                    required
                  />
                </div>
                <div className="t-flex t-gap-3 t-justify-end t-mt-6">
                  <Button
                    customType="secondary"
                    size="regular"
                    type="reset"
                    onClick={closeModal}
                  >
                    Cancel
                  </Button>
                  <Button
                    customType="primary"
                    size="regular"
                    type="submit"
                    disabled={!isValid || isEmailAdding}
                    isLoading={isEmailAdding}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};

const Email = ({ info }: { info: CellContext<Vendor, Vendor["email"]> }) => {
  const [editMerchantId, setEditMerchantId] = useState("");

  const rowData = info.row.original;

  const {
    open: openAddVendorEmailModal,
    close: closeAddVendorEmailModal,
    isOpen: showAddVendorEmailModal,
  } = useModal();

  const openAddVendorEmail = (merchant: Merchant) => {
    setEditMerchantId(merchant.uuid);
    openAddVendorEmailModal();
  };

  const closeAddVendorEmail = () => {
    setEditMerchantId("");
    closeAddVendorEmailModal();
  };

  return (
    <div>
      {rowData?.email || (
        <>
          <div
            className="t-text-text-30 t-flex t-gap-0.5 t-items-center"
            role="button"
            onClick={() => openAddVendorEmail(rowData)}
          >
            <SmallPlusIcon />
            <div className="t-text-body-sm">Add email</div>
          </div>
          {showAddVendorEmailModal && (
            <AddVendorEmail
              show={showAddVendorEmailModal}
              closeModal={closeAddVendorEmail}
              editMerchantId={editMerchantId}
            />
          )}
        </>
      )}
    </div>
  );
};

const VendorColumn = {
  Email,
};

export default VendorColumn;
