export const Invoicing = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_6445_268)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.69999 3.69999V12.3674L3.68694 11.8739C3.88401 11.7754 4.11597 11.7754 4.31304 11.8739L5.99999 12.7174L7.68694 11.8739C7.88401 11.7754 8.11597 11.7754 8.31304 11.8739L9.99999 12.7174L11.6869 11.8739C11.884 11.7754 12.116 11.7754 12.313 11.8739L13.3 12.3674V3.69999H2.69999ZM1.65146 2.65146C1.8765 2.42642 2.18173 2.29999 2.49999 2.29999H13.5C13.8182 2.29999 14.1235 2.42642 14.3485 2.65146C14.5736 2.8765 14.7 3.18173 14.7 3.49999V13.5C14.7 13.7426 14.5744 13.9679 14.368 14.0954C14.1616 14.223 13.9039 14.2346 13.6869 14.1261L12 13.2826L10.313 14.1261C10.116 14.2246 9.88401 14.2246 9.68694 14.1261L7.99999 13.2826L6.31304 14.1261C6.11597 14.2246 5.88401 14.2246 5.68694 14.1261L3.99999 13.2826L2.31304 14.1261C2.09605 14.2346 1.83835 14.223 1.63198 14.0954C1.4256 13.9679 1.29999 13.7426 1.29999 13.5V3.49999C1.29999 3.18173 1.42642 2.8765 1.65146 2.65146Z"
        fill="currentColor"
      />
      <g clip-path="url(#clip1_6445_268)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.99999 4.58463C8.38659 4.58463 8.69999 4.89803 8.69999 5.28463V5.69295C8.69999 6.07955 8.38659 6.39295 7.99999 6.39295C7.61339 6.39295 7.29999 6.07955 7.29999 5.69295V5.28463C7.29999 4.89803 7.61339 4.58463 7.99999 4.58463Z"
          fill="currentColor"
        />
        <path
          d="M8 10.068V10.6131"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.4306 5.55775C6.72639 5.21266 7.15282 4.99295 7.62499 4.99295H8.37499C8.60639 4.99295 8.83218 5.04632 9.03833 5.14594C9.244 5.24533 9.4233 5.38732 9.56938 5.55775C9.71531 5.72799 9.82644 5.92453 9.90079 6.13394C10.0301 6.49825 9.83965 6.89845 9.47533 7.0278C9.11102 7.15715 8.71082 6.96666 8.58147 6.60235C8.56159 6.54635 8.53461 6.50174 8.50642 6.46886C8.47839 6.43615 8.451 6.41702 8.42918 6.40647C8.40786 6.39617 8.38982 6.39295 8.37499 6.39295H7.62499C7.59988 6.39295 7.5494 6.40371 7.49356 6.46886C7.43486 6.53734 7.38749 6.65039 7.38749 6.7867C7.38749 6.92301 7.43486 7.03606 7.49356 7.10454C7.53926 7.15786 7.56602 7.17589 7.57354 7.18036C7.57458 7.18041 7.57591 7.18045 7.57756 7.18045H8.56249C9.03467 7.18045 9.4611 7.40016 9.75688 7.74525C10.0498 8.087 10.2 8.53035 10.2 8.9742C10.2 9.41806 10.0498 9.8614 9.75688 10.2032C9.4611 10.5482 9.03467 10.768 8.56249 10.768H7.43749C6.96532 10.768 6.53889 10.5482 6.2431 10.2032C5.93864 9.84794 5.88191 9.52621 5.82919 9.22722L5.82705 9.21512C5.75987 8.8344 6.01403 8.4713 6.39475 8.40412C6.77547 8.33693 7.13856 8.5911 7.20575 8.97182C7.23349 9.12903 7.24531 9.18155 7.25826 9.21792L7.25835 9.21818C7.26336 9.23224 7.26944 9.24932 7.30606 9.29204C7.3619 9.35719 7.41238 9.36795 7.43749 9.36795H8.56249C8.5876 9.36795 8.63808 9.35719 8.69392 9.29204C8.75263 9.22356 8.79999 9.11051 8.79999 8.9742C8.79999 8.83789 8.75263 8.72484 8.69392 8.65636C8.63808 8.59121 8.5876 8.58045 8.56249 8.58045H7.57756C7.06334 8.58045 6.69358 8.32246 6.4306 8.01565C6.13767 7.6739 5.98749 7.23056 5.98749 6.7867C5.98749 6.34285 6.13767 5.8995 6.4306 5.55775Z"
          fill="currentColor"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_6445_268">
        <rect width="16" height="16" fill="white" />
      </clipPath>
      <clipPath id="clip1_6445_268">
        <rect
          width="6"
          height="7"
          fill="white"
          transform="translate(5 4.38046)"
        />
      </clipPath>
    </defs>
  </svg>
);
