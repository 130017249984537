import { EntityDataAttribute } from "types/Models/entity";
import { emptyApi } from "./emptyApi";
import {
  RegistrationActionType,
  RegistrationStatus,
  StateFilingData,
} from "./registrations";

export type EntityDataAttributeWithFieldKey = EntityDataAttribute & {
  field_key: string;
  order: number;
};

export type StateRegistrationType = {
  data_attributes: EntityDataAttributeWithFieldKey[];
  status: RegistrationStatus;
  status_text: string;
  title: string;
  purpose: string;
  relevance: string;
  action_type: RegistrationActionType;
  action_label: string;
  action_description: string;
  short_description: string;
  filing_data: StateFilingData;
  department_of_registration: string;
  state_id: string;
  state: string;
};

export type StateRegistrationNameType =
  | "SALES_TAX"
  | "PAY_ROLL"
  | "REGISTERED_AGENT"
  | "SECRETARY_OF_STATE";

export type StateRegistrationsRes = {
  state: string;
  is_home_state: boolean;
  state_id: string;
  SALES_TAX: StateRegistrationType;
  PAY_ROLL: StateRegistrationType;
  REGISTERED_AGENT: StateRegistrationType;
  SECRETARY_OF_STATE: StateRegistrationType;
};

export const stateRegistrationsApis = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getStateRegistrations: build.query<
      StateRegistrationsRes[],
      {
        groupId: string;
        entityId: string;
      }
    >({
      query: ({ groupId, entityId }) =>
        `/api/inkle/tax_compliance/group/${groupId}/entity/${entityId}/state_registrations/`,
      providesTags: ["STATE_REGISTRATIONS"],
    }),

    getStateRegistrationById: build.query<
      StateRegistrationType,
      {
        groupId: string;
        entityId: string;
        stateId: string;
        stateType: StateRegistrationNameType;
      }
    >({
      query: ({ groupId, entityId, stateId, stateType }) =>
        `/api/inkle/tax_compliance/group/${groupId}/entity/${entityId}/state_registration/${stateId}/?type=${stateType}`,
      providesTags: ["STATE_REGISTRATIONS"],
    }),

    updateStateRegistration: build.mutation<
      StateRegistrationType,
      {
        payload: {
          data_attributes: EntityDataAttributeWithFieldKey[];
          type: StateRegistrationNameType;
        };
        groupId: string;
        entityId: string;
        stateId: string;
      }
    >({
      query: ({ groupId, payload, entityId, stateId }) => ({
        url: `/api/inkle/tax_compliance/group/${groupId}/entity/${entityId}/state_registration/${stateId}/`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["STATE_REGISTRATIONS"],
    }),

    addStateRegistration: build.mutation<
      StateRegistrationType,
      {
        groupId: string;
        entityId: string;
        stateId: string;
      }
    >({
      query: ({ groupId, entityId, stateId }) => ({
        url: `/api/inkle/tax_compliance/group/${groupId}/entity/${entityId}/state_registration/${stateId}/`,
        method: "POST",
      }),
      invalidatesTags: ["STATE_REGISTRATIONS"],
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetStateRegistrationsQuery,
  useGetStateRegistrationByIdQuery,
  useUpdateStateRegistrationMutation,
  useAddStateRegistrationMutation,
} = stateRegistrationsApis;
