import { Button } from "components/DesignSystem/Button/Button";
import React from "react";
import { RegisteredStateTags } from "../Registrations/RegisteredStateTags";
import ArrowUpRight from "static/images/ArrowUpRight.svg";
import { FederalRegistrationModal } from "./FederalRegistrationModal";
import { useModal } from "hooks/useModal";
import { RegistrationRes } from "store/apis/registrations";
import { PermissionBasedUI } from "components/PermissionBasedUI/PermissionBasedUI";
import { FEDERAL_REGISTERATIONS } from "constants/subscriptionPermissionFeatures";

export const FederalRegistration = ({
  registrationDetail,
}: {
  registrationDetail: RegistrationRes;
}) => {
  const FederalRegistrationFlow = useModal();

  return (
    <>
      <div
        className="t-w-[412px] t-h-[132px] t-bg-[url('static/images/FederalRegistrationCardBG.svg')] t-bg-no-repeat t-bg-right-bottom t-px-4 t-py-5 t-flex t-flex-col t-justify-between t-border t-border-solid t-border-neutral-0 t-rounded-lg t-shadow-light-30 t-group hover:t-border-purple-30"
        role="button"
        onClick={FederalRegistrationFlow.open}
        aria-label={registrationDetail.title}
      >
        <div>
          <div className="t-text-subtext t-text-text-60">
            {registrationDetail.title}
          </div>
          <div className="t-text-body-sm t-text-text-30">
            Department: {registrationDetail.department_of_registration}
          </div>
        </div>
        <div className="t-flex t-gap-1.5 t-items-center">
          <RegisteredStateTags status={registrationDetail.status} />

          <div className="group-hover:t-visible t-invisible">
            <PermissionBasedUI feature={FEDERAL_REGISTERATIONS}>
              <Button customType="ghost_icon" size="extra-small">
                <img src={ArrowUpRight} alt="ArrowUpRight" />
              </Button>
            </PermissionBasedUI>
          </div>
        </div>
      </div>
      {FederalRegistrationFlow.isOpen && (
        <FederalRegistrationModal
          {...FederalRegistrationFlow}
          registrationId={registrationDetail.uuid}
          title={registrationDetail.title}
        />
      )}
    </>
  );
};
