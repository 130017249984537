import Tab from "components/DesignSystem/Tab/Tab";
import { useRoleBasedView } from "hooks/useRoleBasedView";
function TaskTabBar({ defaultValue }) {
  const { isCpa, isCpaInkleServiceAdmin, isForeignCA } = useRoleBasedView();

  let tabNames;
  if (isCpaInkleServiceAdmin || isCpa) {
    const fcaTabName = isForeignCA
      ? []
      : [
          {
            name: "Bookkeeping tracker",
            route: "/admin/bookkeeping-tracker",
          },
        ];

    tabNames = [
      {
        name: "All filings",
        route: "/admin/filings",
      },
      {
        name: "My filings",
        route: "/admin/myfilings",
      },
      ...fcaTabName,
    ];
  } else {
    tabNames = [
      {
        name: "All filings",
        route: "/admin/filings",
      },
    ];
  }

  return (
    <div className="t-box-border t-w-full t-border t-border-l-0 t-border-r-0 t-border-t-0 t-border-solid t-border-neutral-20 t-pl-5">
      <Tab.Root defaultValue={`/${defaultValue}`}>
        <Tab.List>
          {tabNames.map(({ name, route }) => {
            return (
              <Tab.NavTrigger exact value={route} omitUrl key={route}>
                {name}
              </Tab.NavTrigger>
            );
          })}
        </Tab.List>
      </Tab.Root>
    </div>
  );
}

export default TaskTabBar;
