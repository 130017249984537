import { http, HttpResponse } from "msw";
import { baseURL } from "store/apis/emptyApi";
import { MOCK_ENTITY_ID, MOCK_GROUP_ID } from "./group";
import { entityTransactions } from "./transactionTable";

export const rule = {
  name: "Bookeeepingvivi",
  uuid: "6aaa372f-2018-4fbc-b711-be6ee4c39bae",
  start_date: "2023-12-27",
  end_date: "2024-03-26",
  entity_id: "7b2b0e1b-1ea1-41b4-abb9-1cfa2f6d8bfe",
  apply_to_txns_with_no_category: true,
  apply_to_future_txns: true,
  category: {
    name: "New parent account test",
    uuid: "e1938650-a90b-48c8-a5c8-4b2a9b3d41ad",
  },
  conditions: [
    {
      uuid: "53805b56-6cff-4c04-befb-b728196e9170-1",
      condition_operand: {
        name: "Vendor",
        uuid: "edc7f555-2286-4f5e-be1f-769b400ece38",
      },
      condition_operator: {
        name: "Is",
        uuid: "a4e226ee-6e4c-4360-a024-6a2a0a5b54a4",
      },
      value: [
        "5eaa9849-5bc4-4b7e-ba63-0bdc0bc0beea",
        "37665a61-4c27-456b-a960-920e372b3fe4",
      ],
      value_type: "VENDOR",
    },
    {
      uuid: "30bfdc26-a927-46f6-85a6-77b360332411-1",
      condition_operand: {
        name: "Source",
        uuid: "30bfdc26-a927-46f6-85a6-77b360332411",
      },
      condition_operator: {
        name: "Is",
        uuid: "a4e226ee-6e4c-4360-a024-6a2a0a5b54a4",
      },
      value: [
        "9ba416fc-b2d9-43bc-a300-115cde471168",
        "5bdcc096-a81e-46c6-9b77-783ef344a095",
      ],
      value_type: "SOURCE",
    },
    {
      uuid: "f212b119-175f-4c9b-aa35-9980c4743b8c-1",
      condition_operand: {
        name: "Cashflow",
        uuid: "f212b119-175f-4c9b-aa35-9980c4743b8c",
      },
      condition_operator: {
        name: "Is",
        uuid: "a4e226ee-6e4c-4360-a024-6a2a0a5b54a4",
      },
      value: "DEBIT",
      value_type: "CHOICE",
    },
  ],
};

const preFetchedCondition = {
  data: {
    conditions: [
      {
        uuid: "53805b56-6cff-4c04-befb-b728196e9170-1",
        operand: {
          name: "Vendor",
          uuid: "edc7f555-2286-4f5e-be1f-769b400ece38",
        },
        operator: {
          name: "Is",
          uuid: "a4e226ee-6e4c-4360-a024-6a2a0a5b54a4-1",
        },
        values: [
          "5eaa9849-5bc4-4b7e-ba63-0bdc0bc0beea",
          "37665a61-4c27-456b-a960-920e372b3fe4",
        ],
      },
    ],
  },
};

export const allRules = {
  data: {
    rules_data: [rule],
    total_pages: 1,
    current_page: 1,
    total_count: 1,
    per_page: 20,
  },
};

export const conditions = {
  data: [
    {
      uuid: "30bfdc26-a927-46f6-85a6-77b360332411",
      name: "Source",
      value_supported: "SOURCE",
      available_operators: [
        {
          uuid: "a4e226ee-6e4c-4360-a024-6a2a0a5b54a4",
          name: "Is",
          operator_type: "IS",
          is_multi_value_condition: false,
        },
        {
          uuid: "52edadae-67ca-484c-aeaf-aff5952f6c93",
          name: "Is not",
          operator_type: "IS_NOT",
          is_multi_value_condition: false,
        },
      ],
      possible_values: [],
    },
    {
      uuid: "f212b119-175f-4c9b-aa35-9980c4743b8c",
      name: "Cashflow",
      value_supported: "CHOICE",
      available_operators: [
        {
          uuid: "a4e226ee-6e4c-4360-a024-6a2a0a5b54a4",
          name: "Is",
          operator_type: "IS",
          is_multi_value_condition: false,
        },
        {
          uuid: "52edadae-67ca-484c-aeaf-aff5952f6c93",
          name: "Is not",
          operator_type: "IS_NOT",
          is_multi_value_condition: false,
        },
      ],
      possible_values: [
        {
          name: "BOTH",
          uuid: "80b03907-2b45-4187-af0e-88a1448cc314",
        },
        {
          name: "DEBIT",
          uuid: "65a49f29-e393-40aa-8d81-fbea6d3e8a92",
        },
        {
          name: "CREDIT",
          uuid: "26a9c2ac-18b3-4f3f-a363-c6a15310e2cb",
        },
      ],
    },
    {
      uuid: "673da894-5170-4534-8d5c-eaf72047fd5a",
      name: "Amount",
      value_supported: "DECIMAL",
      available_operators: [
        {
          uuid: "ea18a145-4356-4042-8777-2658b21daae2",
          name: "Is less than",
          operator_type: "IS_LESS_THAN",
          is_multi_value_condition: false,
        },
        {
          uuid: "2a52cc5a-e7ed-4e16-83ee-7979a8271b8f",
          name: "Is more than",
          operator_type: "IS_MORE_THAN",
          is_multi_value_condition: false,
        },
        {
          uuid: "cb4e70dd-c0e3-4011-94f2-09fe357041be",
          name: "Is equal to",
          operator_type: "IS_EQUAL_TO",
          is_multi_value_condition: false,
        },
        {
          uuid: "c099e994-2fcf-4202-ab24-5f3db71e8ce5",
          name: "Is not equal to",
          operator_type: "IS_NOT_EQUAL_TO",
          is_multi_value_condition: false,
        },
        {
          uuid: "77a183af-a4a8-49c9-b0a5-566f38e911dd",
          name: "Is less than and equal to",
          operator_type: "IS_LESS_THAN_EQUAL_TO",
          is_multi_value_condition: false,
        },
        {
          uuid: "94ba502e-66a0-4b19-ae98-1ec72e80f791",
          name: "Is more than and equal to",
          operator_type: "IS_MORE_THAN_EQUAL_TO",
          is_multi_value_condition: false,
        },
      ],
      possible_values: [],
    },
    {
      uuid: "edc7f555-2286-4f5e-be1f-769b400ece38",
      name: "Vendor",
      value_supported: "VENDOR",
      available_operators: [
        {
          uuid: "52edadae-67ca-484c-aeaf-aff5952f6c93",
          name: "Is not",
          operator_type: "IS_NOT",
          is_multi_value_condition: false,
        },
        {
          uuid: "a4e226ee-6e4c-4360-a024-6a2a0a5b54a4",
          name: "Is",
          operator_type: "IS",
          is_multi_value_condition: false,
        },
      ],
      possible_values: [],
    },
    {
      uuid: "183d2e48-d33d-4efc-8832-46690b5c0476",
      name: "Description",
      value_supported: "TEXT",
      available_operators: [
        {
          uuid: "63bae124-a1c4-4616-8845-282933a17ac6",
          name: "Contains",
          operator_type: "CONTAINS",
          is_multi_value_condition: false,
        },
        {
          uuid: "00a52841-6651-4b7e-b143-0d90cc69e588",
          name: "Does Not Contain",
          operator_type: "DOES_NOT_CONTAIN",
          is_multi_value_condition: false,
        },
        {
          uuid: "af3cccdc-6dd5-42c8-9ced-25ac4b73af1b",
          name: "Is Exactly",
          operator_type: "IS_EXACTLY",
          is_multi_value_condition: false,
        },
      ],
      possible_values: [],
    },
  ],
};

const previewTransactions = {
  data: {
    rule: rule,
    transactions: entityTransactions.data.transactions,
    total_pages: 1,
    current_page: 1,
    total_count: 2,
    per_page: 20,
  },
};

export const ruleEngineHandlers = [
  http.get(
    `${baseURL}api/inkle/bookkeeping/group/${MOCK_GROUP_ID}/entity/${MOCK_ENTITY_ID}/categorization/rules/`,
    () => {
      return HttpResponse.json(allRules);
    }
  ),

  http.get(
    `${baseURL}api/inkle/bookkeeping/group/${MOCK_GROUP_ID}/entity/${MOCK_ENTITY_ID}/categorization/rule_conditions/`,
    () => {
      return HttpResponse.json(conditions);
    }
  ),

  http.get(
    `${baseURL}api/inkle/bookkeeping/group/${MOCK_GROUP_ID}/entity/${MOCK_ENTITY_ID}/categorization/rules/:ruleId/transactions/`,
    () => {
      return HttpResponse.json(previewTransactions);
    }
  ),

  http.post(
    `${baseURL}api/inkle/bookkeeping/group/${MOCK_GROUP_ID}/entity/${MOCK_ENTITY_ID}/categorization/rules/`,
    () => {
      return HttpResponse.json({ data: rule });
    }
  ),

  // markRuleActive
  http.put(
    `${baseURL}api/inkle/bookkeeping/group/${MOCK_GROUP_ID}/entity/${MOCK_ENTITY_ID}/categorization/rules/:ruleId/`,
    () => {
      return HttpResponse.json({ data: rule });
    }
  ),

  http.get(
    `${baseURL}api/inkle/bookkeeping/group/${MOCK_GROUP_ID}/entity/${MOCK_ENTITY_ID}/categorization/rules/:ruleId/`,
    () => {
      return HttpResponse.json({ data: rule });
    }
  ),

  // editRule
  http.patch(
    `${baseURL}api/inkle/bookkeeping/group/${MOCK_GROUP_ID}/entity/${MOCK_ENTITY_ID}/categorization/rules/:ruleId/`,
    () => {
      return HttpResponse.json({ data: rule });
    }
  ),

  http.delete(
    `${baseURL}api/inkle/bookkeeping/group/${MOCK_GROUP_ID}/entity/${MOCK_ENTITY_ID}/categorization/rules/:ruleId/`,
    () => {
      return HttpResponse.json({ data: null });
    }
  ),

  http.get(
    `${baseURL}api/inkle/bookkeeping/group/${MOCK_GROUP_ID}/entity/${MOCK_ENTITY_ID}/categorization/get_prefilled_rule_data/`,
    () => {
      return HttpResponse.json(preFetchedCondition);
    }
  ),
];
