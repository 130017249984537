import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import Modal from "components/DesignSystem/Modal/Modal";
import Table from "components/DesignSystem/Table/V2/Table";
import { TextArea } from "components/DesignSystem/TextArea/TextArea";
import { ClockCountdown } from "components/icons/ClockCountdown";
import { Info } from "components/icons/Info";
import Pencil from "components/icons/pencil";
import { YYYY_MM_DD } from "constants/date";
import { timezones } from "data/timezones";
import { CHAT_SETTING_BANNER_TYPE_DICT } from "dictionaries";
import { addHolidaySchema } from "formValidations/addHolidaySchema";
import { Field, FieldProps, Form, Formik } from "formik";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { useMemo, useState } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import {
  useAddHolidayMutation,
  useEditBannerMutation,
  useGetChatSettingsQuery,
} from "store/apis/chatSettings";
import { BackendError } from "types/utils/error";
import { formatDate } from "utils/formatDate";
import { AvailabilitySettings } from "components/AvailabilitySettings/AvailabilitySettings";

export const ChatSettings = () => {
  const { data: chatSettings } = useGetChatSettingsQuery();
  const [
    editBanner,
    { isLoading: isEditingBanner, originalArgs: editBannerArgs },
  ] = useEditBannerMutation();
  const [addHoliday, { isLoading: isAddingHoliday }] = useAddHolidayMutation();

  const { successToast, alertToast } = useToast();
  const addHolidayModal = useModal();
  const [editBannerId, setEditBannerId] = useState<string | null>("");
  const selectedEditBanner = chatSettings?.banners.find(
    (banner) => banner.uuid === editBannerId
  );

  const createColumn = createColumnHelper<{ date: string; timezone: string }>();

  const columns = useMemo(
    () => [
      createColumn.accessor("date", {
        header: "DATE",
        cell: (info) => formatDate(info.getValue()),
        size: 75,
      }),
      createColumn.accessor("timezone", {
        header: "TIME ZONE",
        size: 25,
      }),
    ],
    []
  );

  const data = useMemo(
    () =>
      chatSettings?.holidays.map((holiday) => ({
        ...holiday,
        timezone:
          timezones.find((tz) => tz.value === holiday.timezone)?.abbr ||
          holiday.timezone,
      })) || [],
    [chatSettings]
  );

  const table = useReactTable({
    columns,
    data: data,
    getCoreRowModel: getCoreRowModel(),
  });

  const onBannerSave = async ({
    text,
    bannerId,
  }: {
    text: string;
    bannerId: string;
  }) => {
    try {
      await editBanner({
        uuid: bannerId,
        text,
      }).unwrap();
      setEditBannerId(null);
      successToast({ message: "Banner updated successfully" });
    } catch (error: any) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  const onAddHoliday = async ({
    date,
    timezone,
  }: {
    date: string;
    timezone: string;
  }) => {
    try {
      await addHoliday({
        date,
        timezone,
      }).unwrap();
      successToast({ message: "Holiday added successfully" });
      addHolidayModal.close();
    } catch (error: any) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  return (
    <div className="t-px-10 t-h-full t-w-full t-my-5">
      <div className="t-w-[88%] t-flex t-flex-col t-gap-8">
        <div className="t-flex t-flex-col t-gap-3 t-w-4/6">
          <p className="t-m-0 t-text-subtitle-sm">Banners</p>
          <div className="t-flex t-gap-2 t-flex-col">
            {chatSettings?.banners.map((banner) => (
              <div className="t-flex t-flex-col t-gap-1.5" key={banner.uuid}>
                <p className="t-m-0 t-text-subtext-sm">
                  {CHAT_SETTING_BANNER_TYPE_DICT[banner.banner_type]}
                </p>
                <div className="t-py-2 t-px-3 t-flex t-gap-2 t-items-center t-bg-surface-lighter-grey t-text-neutral-40">
                  <ClockCountdown />
                  <span className="t-text-neutral t-text-body-sm">
                    {banner.banner_text}
                  </span>
                  <div className="t-ml-auto t-text-text-30">
                    <Button
                      size="extra-small"
                      customType="ghost_icon"
                      onClick={() => setEditBannerId(banner.uuid)}
                    >
                      <Pencil color="currentColor" size="16" />
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {selectedEditBanner && (
          <Modal.Root
            onOpenChange={() => setEditBannerId(null)}
            open={Boolean(selectedEditBanner)}
          >
            <Modal.Content>
              <Formik
                initialValues={{
                  text: selectedEditBanner.banner_text,
                  bannerId: selectedEditBanner.uuid,
                }}
                onSubmit={onBannerSave}
              >
                {({ submitForm }) => (
                  <>
                    <Modal.Header>
                      <Modal.Title>
                        Edit{" "}
                        {
                          CHAT_SETTING_BANNER_TYPE_DICT[
                            selectedEditBanner.banner_type
                          ]
                        }
                      </Modal.Title>
                      <Modal.Close />
                    </Modal.Header>
                    <Modal.Body>
                      <Form className="t-m-0 t-flex t-flex-col t-gap-1">
                        <TextArea name="text" />
                      </Form>
                    </Modal.Body>
                    <Modal.FooterButtonGroup>
                      <Button
                        size="small"
                        customType="primary"
                        disabled={
                          isEditingBanner &&
                          editBannerArgs?.uuid === selectedEditBanner.uuid
                        }
                        isLoading={
                          isEditingBanner &&
                          editBannerArgs?.uuid === selectedEditBanner.uuid
                        }
                        onClick={submitForm}
                      >
                        Save
                      </Button>
                    </Modal.FooterButtonGroup>
                  </>
                )}
              </Formik>
            </Modal.Content>
          </Modal.Root>
        )}

        <hr className="t-text-neutral-0" />

        <AvailabilitySettings />

        <hr className="t-text-neutral-0" />

        <div className="t-w-4/6">
          <div className="t-flex t-justify-between">
            <p className="t-m-0 t-text-subtitle-sm">Holidays</p>
            <Modal.Root
              onOpenChange={addHolidayModal.toggle}
              open={addHolidayModal.isOpen}
            >
              <Modal.Trigger asChild>
                <Button size="small" customType="primary-outlined">
                  Add
                </Button>
              </Modal.Trigger>
              <Modal.Content useCustomOverlay>
                <Formik
                  onSubmit={onAddHoliday}
                  initialValues={{ date: "", timezone: "India Standard Time" }}
                  validationSchema={addHolidaySchema}
                >
                  {({ values, submitForm }) => {
                    const selectedTimezone = timezones.find(
                      (z) => z.value === values.timezone
                    );
                    return (
                      <>
                        <Modal.Header>
                          <Modal.Title>Add holiday</Modal.Title>
                          <Modal.Close />
                        </Modal.Header>
                        <Modal.Body>
                          <Form className="t-m-0 t-flex t-flex-col t-gap-4">
                            <Field name="date">
                              {({ field }: FieldProps) => {
                                return (
                                  <>
                                    <DateInput
                                      {...field}
                                      required
                                      saveFormat={YYYY_MM_DD}
                                      portalId="date-of-holiday"
                                      label="Date of Holiday"
                                    />
                                  </>
                                );
                              }}
                            </Field>
                            <Combobox
                              isClearable={false}
                              label="Select time zone"
                              menuPortalTarget={document.body}
                              withForm
                              name="timezone"
                              value={
                                selectedTimezone
                                  ? {
                                      value: selectedTimezone?.value,
                                      label: `${selectedTimezone?.value} (${selectedTimezone?.abbr})`,
                                    }
                                  : null
                              }
                              options={timezones
                                .map(({ value, abbr, text }) => [
                                  value,
                                  `${value} (${abbr})`,
                                ])
                                .sort((a, b) => a[0].localeCompare(b[0]))
                                ?.map((option) => {
                                  return { label: option[1], value: option[0] };
                                })}
                            />
                          </Form>
                        </Modal.Body>
                        <Modal.FooterButtonGroup>
                          <Modal.RawClose asChild>
                            <Button>Cancel</Button>
                          </Modal.RawClose>

                          <Button
                            onClick={submitForm}
                            customType="primary"
                            isLoading={isAddingHoliday}
                            disabled={isAddingHoliday}
                          >
                            Save
                          </Button>
                        </Modal.FooterButtonGroup>
                      </>
                    );
                  }}
                </Formik>
              </Modal.Content>
            </Modal.Root>
          </div>
          <Table.Container size="regular">
            <Table.Content>
              <Table.Head className="!t-static">
                {table.getHeaderGroups().map((headerGroup) => (
                  <Table.Row key={headerGroup.id} className="!t-border-0">
                    {headerGroup.headers.map((header) => (
                      <Table.HeadCell
                        key={header.id}
                        style={{ width: `${header.getSize()}%` }}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </Table.HeadCell>
                    ))}
                  </Table.Row>
                ))}
              </Table.Head>
              <Table.Body>
                {table.getRowModel().rows.map((row) => (
                  <Table.Row key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Table.Cell
                          key={cell.id}
                          style={{ width: `${cell.column.getSize()}%` }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Table.Cell>
                      );
                    })}
                  </Table.Row>
                ))}
              </Table.Body>
            </Table.Content>
          </Table.Container>

          <div className="t-bg-blue-0 t-px-3 t-py-1.5 t-border-solid t-border t-border-blue-10 t-rounded-lg t-flex t-gap-2 t-items-center t-mt-6">
            <span className="t-text-blue t-flex">
              <Info stroke="1.5" size="20" />
            </span>
            <p className="t-m-0 t-text-body-sm t-text-blue-90">
              Saturday and Sunday are holidays
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
