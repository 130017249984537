import { channel } from "diagnostics_channel";
import { stringify } from "qs";
import { emptyApi } from "store/apis/emptyApi";
import {
  SensitiveDataFormField,
  SharedProfile,
} from "types/Models/sensitiveData";

// Request Objects

// Response
export type SensitiveDataResponse = {
  uuid: string;
  name: string;
  description: string;
  form_data: SensitiveDataFormField[];
  has_share_and_revoke_access: boolean;
  has_edit_access: boolean;
  has_delete_access: boolean;
  shared_profiles: SharedProfile[];
  sensitive_data_type: string;
};

export const sensitiveDataApis = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getAllSensitiveDataTypes: build.query<
      {
        uuid: string;
        name: string;
      }[],
      void
    >({
      query: () => {
        return {
          url: `/api/inkle/sensitivestore/sensitive-data-types/`,
        };
      },
    }),
    getEmptyFormData: build.query<
      SensitiveDataFormField[],
      {
        entityId: string;
        formId: string;
      }
    >({
      query: ({ entityId, formId }) => {
        return {
          url: `api/inkle/sensitivestore/entity/${entityId}/sensitive-data-form/${formId}/`,
        };
      },
    }),
    getAllSensitiveData: build.query<
      SensitiveDataResponse[],
      {
        entityId: string;
      }
    >({
      query: ({ entityId }) => {
        return {
          url: `api/inkle/sensitivestore/entity/${entityId}/`,
        };
      },
      providesTags: ["SENSITIVE_DATA"],
    }),
    getSingleSensitiveData: build.query<
      SensitiveDataResponse,
      {
        entityId: string;
        formId: string;
      }
    >({
      query: ({ entityId, formId }) => {
        return {
          url: `api/inkle/sensitivestore/entity/${entityId}/sensitive-data/${formId}/`,
        };
      },
      providesTags: ["SENSITIVE_DATA", "SENSITIVE_DATA_ACCESS"],
    }),
    createSensitiveData: build.mutation<
      SensitiveDataResponse,
      {
        entityId: string;
        payload: any;
      }
    >({
      query: ({ entityId, payload }) => {
        return {
          url: `api/inkle/sensitivestore/entity/${entityId}/`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["SENSITIVE_DATA"],
    }),
    grantAccessToSensitiveData: build.mutation<
      SensitiveDataResponse[],
      {
        entityId: string;
        formId: string;
        profileId: string;
      }
    >({
      query: ({ entityId, formId, profileId }) => {
        return {
          url: `api/inkle/sensitivestore/entity/${entityId}/sensitive-data/${formId}/grant-access/`,
          method: "POST",
          body: {
            profile_id: profileId,
          },
        };
      },
      invalidatesTags: ["SENSITIVE_DATA_ACCESS"],
    }),
    revokeAccessToSensitiveData: build.mutation<
      SensitiveDataResponse[],
      {
        entityId: string;
        sensitiveDataMapId: string;
      }
    >({
      query: ({ entityId, sensitiveDataMapId }) => {
        return {
          url: `api/inkle/sensitivestore/entity/${entityId}/revoke-access/${sensitiveDataMapId}/`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["SENSITIVE_DATA_ACCESS"],
    }),
    sendChatCard: build.mutation<
      void,
      {
        entityId: string;
        sensitiveDataInstanceId: string;
        channelUrl: string;
      }
    >({
      query: ({ entityId, sensitiveDataInstanceId, channelUrl }) => {
        return {
          url: `/api/inkle/sensitivestore/entity/${entityId}/sensitive-data/${sensitiveDataInstanceId}/chat-card/`,
          method: "POST",
          body: {
            channel_url: channelUrl,
          },
        };
      },
    }),
    editSensitiveData: build.mutation<
      {
        encoded_value: string;
      },
      {
        entityId: string;
        formId: string;
        payload: any;
      }
    >({
      query: ({ entityId, formId, payload }) => {
        return {
          url: `/api/inkle/sensitivestore/entity/${entityId}/sensitive-data/${formId}/`,
          method: "PATCH",
          body: payload,
        };
      },
      invalidatesTags: ["SENSITIVE_DATA"],
    }),
    deleteSensitiveData: build.mutation<
      {
        encoded_value: string;
      },
      {
        entityId: string;
        formId: string;
      }
    >({
      query: ({ entityId, formId }) => {
        return {
          url: `/api/inkle/sensitivestore/entity/${entityId}/sensitive-data/${formId}/`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["SENSITIVE_DATA"],
    }),
  }),
});

export const {
  useGetAllSensitiveDataTypesQuery,
  useGetEmptyFormDataQuery,
  useGetAllSensitiveDataQuery,
  useCreateSensitiveDataMutation,
  useGetSingleSensitiveDataQuery,
  useGrantAccessToSensitiveDataMutation,
  useRevokeAccessToSensitiveDataMutation,
  useSendChatCardMutation,
  useDeleteSensitiveDataMutation,
  useEditSensitiveDataMutation,
} = sensitiveDataApis;
