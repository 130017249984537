import { ConditionalLink } from "components/conditionalLink";
import HoverCard from "components/DesignSystem/HoverCard/HoverCard";
import { Tick } from "components/icons/Tick";
import { APP_SWITCHER_CLICKED } from "constants/analyticsEvents";
import { App, useCurrentAppContext } from "contexts/CurrentAppContext";
import { useAnalytics } from "hooks/useAnalytics";
import { useConstructInternalLink } from "hooks/useConstructInternalLink";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import authContext from "jwt_context&axios/authContext";
import { ReactNode, useContext, useRef, useState } from "react";
import { Timeout } from "react-number-format/types/types";
import { useRouteMatch } from "react-router-dom";

export const AppNavigation = ({ children }: { children: ReactNode }) => {
  const { link } = useConstructInternalLink();
  const { path } = useRouteMatch();
  const { isForeignCA } = useRoleBasedView();
  const [pop, setPop] = useState(false);
  const closeRef = useRef<Timeout>();
  const { trackEvent } = useAnalytics();
  const {
    authtoken: { email },
  } = useContext(authContext);

  const { appList } = useCurrentAppContext();

  const onClose = () => {
    if (closeRef.current) {
      clearTimeout(closeRef.current);
    }

    closeRef.current = setTimeout(() => {
      setPop(false);
    }, 200);
  };

  const onOpen = () => {
    closeRef.current && clearTimeout(closeRef.current);
    setPop(true);
  };

  const handleClick = ({ redirectUrl }: Pick<App, "redirectUrl">) => {
    trackEvent(APP_SWITCHER_CLICKED, {
      app_url: redirectUrl,
      email,
    });
  };

  if (isForeignCA) {
    return children;
  }

  return (
    <HoverCard.Root open={pop} openDelay={300}>
      <HoverCard.Trigger
        asChild
        onClick={(e) => e.preventDefault()}
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
      >
        {children}
      </HoverCard.Trigger>

      <HoverCard.Portal>
        <HoverCard.Content
          className="t-min-w-[230px] t-flex t-flex-col t-gap-1 t-p-1.5"
          align="start"
          onMouseEnter={onOpen}
          onMouseLeave={onClose}
          side="bottom"
          sideOffset={-2}
          alignOffset={10}
        >
          {appList.map(({ name, AppSymbol, redirectUrl }) => {
            const isSelected = Boolean(path.includes(redirectUrl));

            return (
              <ConditionalLink
                to={link(redirectUrl)}
                key={name}
                onClick={() => handleClick({ redirectUrl })}
                className="all:unset t-flex t-justify-between t-items-center t-group t-w-full t-cursor-pointer t-truncate !t-border-none t-px-3 t-py-2 hover:t-bg-surface-grey focus-visible:t-outline-none data-[highlighted]:t-bg-surface-lighter-grey t-gap-1.5 group-data-state-open:t-text-purple t-rounded"
              >
                <div className="t-flex t-w-full t-items-center t-gap-3">
                  <AppSymbol />
                  {name}
                </div>
                {isSelected && (
                  <span className="t-text-purple">
                    <Tick />
                  </span>
                )}
              </ConditionalLink>
            );
          })}
        </HoverCard.Content>
      </HoverCard.Portal>
    </HoverCard.Root>
  );
};
