// Date formats for dayjs
export const YYYY = "YYYY";
export const MMM_YYYY = "MMM-YYYY";
export const YYYY_MM_DD = "YYYY-MM-DD";
export const DD_MMM_YYYY = "DD-MMM-YYYY";
export const DD_MMM_YYYY_LT = "DD-MMM-YYYY | LT";
export const Q_YYYY = "Q-YYYY";

// Date formats for date picker
export const TIME_FORMAT = "hh:mm A";
export const RANDOM_DATE_FOR_CHANNELS_LAST_MESSAGE = "2000-01-01T01:00:00.00Z";
export const FILTER_DATE_INPUT_FORMAT = "dd-MMM-yyyy";
export const QUARTER_FORMAT = "QQQ-yyyy";
export const MONTH_FORMAT = "MMM-yyyy";
export const YEAR_FORMAT = "yyyy";
