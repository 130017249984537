import { AddressAutofill } from "types/Models/addressAutofill";
import { Combobox, OptionData } from "./DesignSystem/Combobox/Combobox";
import { DirectorAutofill } from "types/Models/directorAutofill";
import { BankAutofill } from "types/Models/bankAutofill";
import { PersonaAutofill } from "types/Models/personaAutofill";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { ComponentProps, useState } from "react";
import {
  useCreateAutofillMutation,
  useGetAutofillQuery,
} from "store/apis/autofill";
import { FormikValues } from "formik";
import { Button } from "./DesignSystem/Button/Button";
import { AddressModal } from "./AutoFill/AddressModal";
import { useModal } from "hooks/useModal";
import { PersonaModal } from "./AutoFill/PersonaModal";
import { BankModal } from "./AutoFill/BankModal";
import { useToast } from "hooks/useToast";
import { BackendError } from "types/utils/error";
import { MultiValue, SingleValue } from "react-select";

type SelectAddressAutofillProps = {
  type: "authorized_persons" | "addresses" | "bank_accounts";
  selected?: string;
  onSelect?: (
    autofill:
      | BankAutofill
      | AddressAutofill
      | PersonaAutofill
      | DirectorAutofill
  ) => void;
  entityId: string;
  name: string;
  withForm?: boolean;
  label: string;
  placeholder?: string;
  canAddMore?: boolean;
  required?: boolean;
  onChange?: (uuid: string) => void;
  menuPortal?: HTMLElement | null;
  hideClearAction?: boolean;
  menuPlacement?: ComponentProps<typeof Combobox>["menuPlacement"];
};

export const SelectAutofillCombobox = ({
  type,
  selected,
  entityId,
  onSelect,
  canAddMore = true,
  onChange,
  menuPortal = document.body,
  hideClearAction = false,
  ...props
}: SelectAddressAutofillProps) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const { data: autofills = [], isLoading } = useGetAutofillQuery(
    {
      groupId,
      autofillKey: type,
      entityId,
    },
    { skip: !groupId || !entityId }
  );

  const addModal = useModal();
  const { successToast, alertToast } = useToast();

  const [createAutofill, { isLoading: addingAutofill }] =
    useCreateAutofillMutation();

  const onAddAutofill = async (values: FormikValues) => {
    try {
      const autofill = await createAutofill({
        fields: values,
        groupId,
        autofillKey: type,
        entityId,
      }).unwrap();

      onSelect?.(autofill);
      addModal.close();
      successToast({ message: "Autofill added successfully" });
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  const currentAutofill = autofills.find((a) => a.uuid === selected);

  let comboboxComponent = {};
  if (hideClearAction) {
    comboboxComponent = { ClearIndicator: () => null };
  }

  return (
    <div>
      <Combobox
        {...props}
        isLoading={isLoading}
        components={comboboxComponent}
        options={autofills?.map((autofill) => ({
          value: autofill.uuid,
          label: autofill.autofill_string,
        }))}
        value={
          currentAutofill?.uuid
            ? {
                label: currentAutofill.autofill_string,
                value: currentAutofill.uuid,
              }
            : null
        }
        actions={
          canAddMore && (
            <Button onClick={addModal.open} customType="link">
              Add {type === "addresses" && "Address"}
              {type === "authorized_persons" && "Persona autofill"}
              {type === "bank_accounts" && "Bank Account"}
            </Button>
          )
        }
        menuPortalTarget={menuPortal || null}
        onChange={(
          selectedOption: MultiValue<OptionData> | SingleValue<OptionData>
        ) =>
          onChange?.((selectedOption as SingleValue<OptionData>)?.value || "")
        }
      />
      {type === "addresses" && (
        <AddressModal
          open={addModal.isOpen}
          onSubmit={onAddAutofill}
          onClose={addModal.close}
          isLoading={addingAutofill}
          initialValues={{}}
        />
      )}

      {type === "authorized_persons" && (
        <PersonaModal
          entityId={entityId}
          open={addModal.isOpen}
          onSubmit={onAddAutofill}
          onClose={addModal.close}
          isLoading={addingAutofill}
          initialValues={{}}
        />
      )}

      {type === "bank_accounts" && (
        <BankModal
          open={addModal.isOpen}
          onSubmit={onAddAutofill}
          onClose={addModal.close}
          isLoading={addingAutofill}
          initialValues={{}}
        />
      )}
    </div>
  );
};
