import classNames from "classnames";
import { Divider } from "components/design/Divider";
import Loader from "components/design/loader";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import { Filter } from "components/DesignSystem/Filter/Filter";
import Radio from "components/DesignSystem/RadioGroup/RadioGroup";
import { Search } from "components/DesignSystem/Search/Search";
import { NumericInput } from "components/NumericInput/NumericInput";
import {
  Capsule,
  isChecked,
  DateRangeFilter,
} from "components/Transaction/Filter";
import { DD_MMM_YYYY, YYYY_MM_DD } from "constants/date";
import dayjs from "dayjs";
import {
  Field,
  FieldProps,
  Form,
  Formik,
  FormikHelpers,
  useFormikContext,
} from "formik";
import { useChartOfAccounts } from "hooks/useChartOfAccounts";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { parse } from "qs";
import React, { ChangeEvent, ReactNode, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getLedgerFilterStatus } from "store/selector/legderFilter";
import { FilterName, setLedgerFilter } from "store/slices/generalLedger";
import { TxnCategories } from "types/Models/books";
import { debounce } from "utils/debouncing";
import { flattenTypes } from "utils/flattenCOA";
import { CategoryLabel } from "components/design/CategoryLabel";
import { capitalize } from "utils/capitalize";
import { useFilters } from "hooks/useFilter";

export const TransactionsForWrapper = ({
  children,
  queryUpdate = false,
  updateDates,
}: {
  queryUpdate?: boolean;
  children: ({
    handleChange,
    handleDateChange,
  }: {
    handleChange: ({ value }: any) => void;
    handleDateChange: ({ date, name }: { date: Date; name: string }) => void;
  }) => ReactNode;
  updateDates?: ({
    startDate,
    endDate,
  }: {
    startDate: dayjs.Dayjs;
    endDate: dayjs.Dayjs;
  }) => void;
}) => {
  const { update, updateMultiple } = useUpdateQuery();

  const { setFieldValue } = useFormikContext();

  const handleDateChange = debounce(
    ({ date, name }: { date: Date; name: string }) => {
      if (date && isNaN(date.valueOf())) {
        return;
      }

      if (queryUpdate) {
        update({
          query: name,
          value: date ? dayjs(date).format(YYYY_MM_DD) : null,
        });
      }
    }
  );

  const handleMultipleDateChange = ({
    startDate,
    endDate,
  }: {
    startDate: Date;
    endDate: Date;
  }) => {
    if (queryUpdate) {
      updateMultiple([
        {
          query: "startDate",
          value: startDate ? dayjs(startDate).format(YYYY_MM_DD) : null,
        },
        {
          query: "endDate",
          value: endDate ? dayjs(endDate).format(YYYY_MM_DD) : null,
        },
      ]);
    }
  };

  const updateFieldValues = ({
    startDate,
    endDate,
  }: {
    startDate: dayjs.Dayjs;
    endDate: dayjs.Dayjs;
  }) => {
    setFieldValue("startDate", startDate.format(DD_MMM_YYYY));
    setFieldValue("endDate", endDate.format(DD_MMM_YYYY));
    handleMultipleDateChange({
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
    });
    updateDates?.({ startDate, endDate });
  };

  const handleChange = ({ value }: { value: string }) => {
    setFieldValue("transactionsFor", value);
    switch (value) {
      case "lastmonth": {
        updateFieldValues({
          startDate: dayjs().subtract(1, "month").startOf("month"),
          endDate: dayjs().subtract(1, "month").endOf("month"),
        });
        return;
      }
      case "last3months": {
        updateFieldValues({
          startDate: dayjs().subtract(3, "month").startOf("month"),
          endDate: dayjs().subtract(1, "month").endOf("month"),
        });
        return;
      }

      case "currentYear": {
        updateFieldValues({
          startDate: dayjs().startOf("year"),
          endDate: dayjs(),
        });

        return;
      }

      case "lastYear": {
        updateFieldValues({
          startDate: dayjs().subtract(1, "year").startOf("year"),
          endDate: dayjs().subtract(1, "year").endOf("year"),
        });

        return;
      }

      case "last30days": {
        updateFieldValues({
          startDate: dayjs().subtract(30, "days"),
          endDate: dayjs(),
        });
        return;
      }
      case "last90days": {
        updateFieldValues({
          startDate: dayjs().subtract(90, "days"),
          endDate: dayjs(),
        });
        return;
      }
    }
  };

  return <>{children({ handleChange, handleDateChange })}</>;
};

const Amount = () => {
  const { update } = useUpdateQuery();
  const { filters } = useSelector(getLedgerFilterStatus);
  const [cashFlow, minAmount, maxAmount] = filters.amount;

  const handleRadioCheck = (
    value: string,
    setValues: FormikHelpers<{
      minAmount: string;
      maxAmount: string;
    }>["setValues"]
  ) => {
    if (value === "") {
      setValues({ minAmount: "", maxAmount: "" });
      update({ query: minAmount, value: null });
      update({ query: maxAmount, value: null });
    }
    update({
      query: "cashFlow",
      value,
    });
  };

  const handleAmountChange = debounce((e: ChangeEvent<HTMLFormElement>) => {
    const { value, name } = e.target;
    update({ query: name, value });
  });

  return (
    <Formik
      initialValues={{
        minAmount: minAmount.value || "",
        maxAmount: maxAmount.value || "",
      }}
      onSubmit={() => {}}
    >
      {({ setValues }) => {
        return (
          <div className="t-flex t-flex-col t-gap-5">
            <div className="t-flex t-flex-col t-gap-3">
              <div className="t-text-caption t-text-neutral-80 t-w-full">
                Type
              </div>
              <Radio.Root
                onValueChange={(value) => handleRadioCheck(value, setValues)}
                /* @ts-ignore */
                defaultValue={cashFlow?.value || ""}
              >
                <Radio.Content>
                  <Radio.Item asChild value={""}>
                    Any
                  </Radio.Item>
                  <Radio.Item asChild value="credit">
                    Credit only
                  </Radio.Item>
                  <Radio.Item asChild value="debit">
                    Debit only
                  </Radio.Item>
                </Radio.Content>
              </Radio.Root>
            </div>
            <div className="t-flex t-flex-col t-gap-3">
              <Form
                className="all:unset t-flex t-flex-col t-gap-4"
                onChange={handleAmountChange}
              >
                <NumericInput
                  label="Min. Amount"
                  storeNumeric
                  fieldProps={{ name: "minAmount" }}
                  numericProps={{
                    thousandSeparator: true,
                    prefix: "$",
                    disabled: Boolean(!cashFlow?.value),
                  }}
                />
                <NumericInput
                  label="Max. Amount"
                  storeNumeric
                  fieldProps={{ name: "maxAmount" }}
                  numericProps={{
                    thousandSeparator: true,
                    prefix: "$",
                    disabled: Boolean(!cashFlow?.value),
                  }}
                />
              </Form>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

const AccountingMethod = () => {
  const dispatch = useDispatch();
  const { filters } = useSelector(getLedgerFilterStatus);
  const { update } = useUpdateQuery();
  const [accountingMethod] = filters.accountingMethod;

  const onChange = (option: any) => {
    dispatch(setLedgerFilter({ accountingMethod: option.value }));
    update({
      query: "accountingMethod",
      value: option.value,
    });
  };

  return (
    <Combobox
      label="Accounting method"
      components={{ ClearIndicator: () => null }}
      options={[
        { value: "CASH", label: "Cash method" },
        { value: "ACCRUAL", label: "Accrual method" },
      ]}
      {...{
        value: accountingMethod?.value
          ? {
              label: capitalize(accountingMethod?.value as string) + " method",
              value: (accountingMethod?.value as string)
                .toString()
                .toUpperCase(),
            }
          : null,
      }}
      onChange={onChange}
    />
  );
};

export const DropDownItem = ({
  types,
  level,
  onHandleChange,
  categoryIds = [],
}: {
  types?: TxnCategories[];
  level: number;
  onHandleChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    types?: TxnCategories[]
  ) => void;
  categoryIds?: string[];
}) => {
  const maxIndent = 2;
  let nextLevel = level;
  if (nextLevel <= maxIndent) {
    nextLevel = nextLevel + 1;
  }

  return (
    <div>
      {types?.map((type) => (
        <div key={type.uuid}>
          <Dropdown.Item
            className="t-flex t-gap-1.5 hover:!t-bg-surface-transparent"
            onSelect={(e) => {
              e.preventDefault();
            }}
            textValue=""
          >
            <Checkbox
              name={type.uuid}
              value={type.uuid}
              label={<CategoryLabel category={type} />}
              onChange={(e) => onHandleChange(e, type?.types)}
              checked={Boolean(isChecked(categoryIds, type.uuid))}
            />
          </Dropdown.Item>
          <div style={{ paddingLeft: nextLevel > maxIndent ? "0px" : "16px" }}>
            <DropDownItem
              types={type.types}
              level={nextLevel}
              onHandleChange={onHandleChange}
              categoryIds={categoryIds}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

const CategoryFilter = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const { chartOfAccounts, isLoading } = useChartOfAccounts({
    search,
    hiddenCategoryTypes: ["BANK_TRANSFER", "PAY_DOWN_CREDIT"],
  });
  const { filters } = useSelector(getLedgerFilterStatus);
  const categoryIds = filters.category?.[0]?.value || [];

  const onHandleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    types?: TxnCategories[]
  ) => {
    const { checked, value } = e.target;
    const selectedChilds =
      flattenTypes({ accounts: types! })?.map(({ uuid }) => uuid) || [];
    if (Array.isArray(categoryIds)) {
      let newCategories: Set<string> = new Set();
      if (checked) {
        newCategories = new Set([...categoryIds, ...selectedChilds, value]);
      } else {
        newCategories = new Set(
          [...categoryIds]?.filter(
            (categoryId) =>
              !selectedChilds.includes(categoryId) && categoryId !== value
          )
        );
      }
      dispatch(setLedgerFilter({ categoryIds: [...newCategories] }));
    }
  };

  const handleSearch = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearch(value.toLowerCase() || "");
  });

  const noCategoriesFound = chartOfAccounts.length === 0;

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="t-w-full t-flex t-flex-col t-gap-5 t-h-full">
      <Dropdown.Root onOpenChange={() => setSearch("")}>
        <Dropdown.Trigger asChild>
          <div>
            <label className="t-text-caption t-text-text-30 t-mb-1.5">
              Location of the new account
            </label>
            <div className="all:unset form-input w-full t-box-border t-flex t-h-10 t-w-[100%] t-items-center t-rounded t-border t-border-solid t-border-neutral-10 t-bg-surface-lighter-grey t-px-4 t-pr-5 t-font-sans t-text-body !t-font-medium t-text-text-100 t-transition-all">
              <div className="t-overflow-hidden t-text-ellipsis t-whitespace-nowrap t-text-text-30">
                {categoryIds?.length > 0
                  ? categoryIds.length + " categories selected"
                  : "Select a parent account"}
              </div>
            </div>
          </div>
        </Dropdown.Trigger>
        <Dropdown.Portal>
          <Dropdown.Content
            sideOffset={6}
            align="start"
            className="t-relative t-w-[430px]"
          >
            <Search
              placeholder="Search..."
              onChange={handleSearch}
              autoFocus
              customSize="regular"
              block
            />
            {noCategoriesFound ? (
              <div className="t-my-1.5 t-flex t-h-48 t-items-center t-justify-center">
                No categories found
              </div>
            ) : (
              <div className="t-max-h-72 t-overflow-auto">
                {chartOfAccounts?.map((account) => (
                  <div key={account.uuid}>
                    <Dropdown.Label className="t-bg-neutral-0 !t-text-text-60">
                      {account.identifier + "-" + account.name}
                    </Dropdown.Label>
                    <DropDownItem
                      types={account.types}
                      level={0}
                      onHandleChange={onHandleChange}
                      categoryIds={categoryIds as string[]}
                    />
                  </div>
                ))}
              </div>
            )}
          </Dropdown.Content>
        </Dropdown.Portal>
      </Dropdown.Root>
    </div>
  );
};

const OtherFilters = () => {
  const { update } = useUpdateQuery();
  const {
    filters: { others },
    getFilterName,
  } = useSelector(getLedgerFilterStatus);
  const dispatch = useDispatch();

  const handleCheckBox = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = e.target;

    if (checked) {
      update({
        query: name,
        value: checked,
      });
      //@ts-ignore
      dispatch(setLedgerFilter({ [name]: checked }));
      return;
    }
    update({
      query: name,
      value: null,
    });
    dispatch(setLedgerFilter({ [name]: undefined }));
  };
  return (
    <div className="t-flex t-flex-col t-gap-3">
      {others.map(({ name, value }) => (
        <Checkbox
          key={name}
          name={getFilterName(name)}
          checked={Boolean(value)}
          label={name}
          onChange={handleCheckBox}
        />
      ))}
    </div>
  );
};

const CapsuleFilters = () => {
  const { update, updateMultiple } = useUpdateQuery();
  const dispatch = useDispatch();
  const query = useQuery();
  const startDate = query.get("startDate");
  const endDate = query.get("endDate");
  const accountingMethod = query.get("accountingMethod");
  const { capsuleFilters, getFilterName } = useSelector(getLedgerFilterStatus);

  const handleClick = ({ name }: { name: FilterName }) => {
    update({ query: name, value: null });
    dispatch(setLedgerFilter({ [name]: undefined }));
  };

  useEffect(() => {
    if (!endDate && !startDate && !accountingMethod) {
      updateMultiple([
        {
          query: "startDate",
          value: dayjs()
            .subtract(1, "month")
            .startOf("month")
            .format(YYYY_MM_DD),
        },
        {
          query: "endDate",
          value: dayjs().subtract(1, "month").endOf("month").format(YYYY_MM_DD),
        },
        { query: "accountingMethod", value: "CASH" },
      ]);
    }
  }, [startDate, endDate, accountingMethod]);

  return (
    <>
      {capsuleFilters.map(({ name, value, type }) => {
        return (
          <Capsule
            key={name}
            value={
              type === "transactionDate" && typeof value === "string"
                ? dayjs(value).format(DD_MMM_YYYY)
                : Array.isArray(value) && (value as unknown as []).length > 0
                ? `(${(value as unknown as []).length})`
                : value
            }
            onCapsuleClick={() => {
              if (
                !(
                  (type === "transactionDate" || type === "accounting") &&
                  typeof value === "string"
                )
              ) {
                handleClick({ name: getFilterName(name)! as FilterName });
              }
            }}
            isRemovable={
              type === "transactionDate" || type === "accounting" ? false : true
            }
            type={type}
          >
            {name}
          </Capsule>
        );
      })}
    </>
  );
};

export const GeneralLedgerFilters = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  let query = parse(search, { ignoreQueryPrefix: true });
  const { appliedFiltersCount } = useSelector(getLedgerFilterStatus);

  useEffect(() => {
    delete query.company;
    delete query.entity;
    delete query.page;
    delete query.category;
    delete query.reportType;
    delete query.selected_transaction_id;
    delete query.selected_category_id;
    dispatch(setLedgerFilter(query));
  }, [search]);

  const { values: dates, updateFilter: updateDateFilter } = useFilters({
    initialValue: {
      START_DATE: "",
      END_DATE: "",
      SELECT_PERIOD: "" as string,
    },
  });

  return (
    <div className="t-flex t-justify-between t-align-bottom t-bg-surface t-pb-1">
      <div className="t-flex t-gap-2">
        <Filter.Root
          defaultValue="category"
          title={
            <span className="t-text-body t-font-medium t-leading-none">
              Filters {appliedFiltersCount ? <>({appliedFiltersCount})</> : ""}
            </span>
          }
          capsule={<CapsuleFilters />}
        >
          <Filter.Portal>
            <Filter.List>
              <Filter.ListItem value="transactionDate">Date</Filter.ListItem>
              <Filter.ListItem value="category">Category</Filter.ListItem>
              <Filter.ListItem value="accounting">
                Accounting method
              </Filter.ListItem>
              <Filter.ListItem value="amount">Amount</Filter.ListItem>
              <Filter.ListItem value="others">Others</Filter.ListItem>
            </Filter.List>
            <Filter.Body value="transactionDate" block>
              <DateRangeFilter updateFilter={updateDateFilter} values={dates} />
            </Filter.Body>
            <Filter.Body value="category" block>
              <CategoryFilter />
            </Filter.Body>
            <Filter.Body value="accounting" block>
              <AccountingMethod />
            </Filter.Body>
            <Filter.Body value="amount" block>
              <Amount />
            </Filter.Body>
            <Filter.Body value="others" block>
              <OtherFilters />
            </Filter.Body>
          </Filter.Portal>
        </Filter.Root>
      </div>
      {/* <Button customType="primary" size="small" onClick={() => {}}>
              Export
            </Button> */}
    </div>
  );
};
