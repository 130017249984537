import React from "react";

export const AICategorisedAccepted = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
    >
      <circle cx="16.8202" cy="16.1459" r="16.1459" fill="#BBA7FF" />
      <path
        d="M24.5282 16.6882C24.53 16.9248 24.4581 17.1562 24.3224 17.3501C24.1867 17.544 23.994 17.6907 23.7711 17.77L19.197 19.4339L17.5343 24.0087C17.4524 24.2295 17.3048 24.42 17.1115 24.5545C16.9181 24.689 16.6883 24.7611 16.4528 24.7611C16.2173 24.7611 15.9874 24.689 15.7941 24.5545C15.6008 24.42 15.4532 24.2295 15.3713 24.0087L13.7086 19.4332L9.1345 17.77C8.91373 17.688 8.72332 17.5404 8.58886 17.3471C8.4544 17.1537 8.38232 16.9237 8.38232 16.6882C8.38232 16.4526 8.4544 16.2227 8.58886 16.0293C8.72332 15.8359 8.91373 15.6883 9.1345 15.6063L13.7086 13.9432L15.3713 9.36764C15.4532 9.1468 15.6008 8.95633 15.7941 8.82183C15.9874 8.68733 16.2173 8.61523 16.4528 8.61523C16.6883 8.61523 16.9181 8.68733 17.1115 8.82183C17.3048 8.95633 17.4524 9.1468 17.5343 9.36764L19.1977 13.9432L23.7711 15.6063C23.994 15.6856 24.1867 15.8324 24.3224 16.0263C24.4581 16.2202 24.53 16.4515 24.5282 16.6882Z"
        fill="white"
      />
      <path
        d="M26.1424 10.2292C26.1431 10.3238 26.1144 10.4164 26.0601 10.4939C26.0058 10.5715 25.9288 10.6302 25.8396 10.6619L24.0099 11.3275L23.3449 13.1574C23.3121 13.2457 23.2531 13.3219 23.1757 13.3757C23.0984 13.4295 23.0065 13.4583 22.9123 13.4583C22.8181 13.4583 22.7261 13.4295 22.6488 13.3757C22.5715 13.3219 22.5124 13.2457 22.4797 13.1574L21.8146 11.3272L19.985 10.6619C19.8966 10.6291 19.8205 10.5701 19.7667 10.4927C19.7129 10.4154 19.6841 10.3234 19.6841 10.2292C19.6841 10.1349 19.7129 10.043 19.7667 9.96562C19.8205 9.88826 19.8966 9.82922 19.985 9.79643L21.8146 9.13117L22.4797 7.30096C22.5124 7.21263 22.5715 7.13644 22.6488 7.08264C22.7261 7.02884 22.8181 7 22.9123 7C23.0065 7 23.0984 7.02884 23.1757 7.08264C23.2531 7.13644 23.3121 7.21263 23.3449 7.30096L24.0102 9.13117L25.8396 9.79643C25.9288 9.82815 26.0058 9.88686 26.0601 9.96441C26.1144 10.042 26.1431 10.1345 26.1424 10.2292Z"
        fill="white"
      />
    </svg>
  );
};
