import React from "react";
import * as Accordion from "@radix-ui/react-accordion";
import { CaretDown } from "components/icons/CaretDown";
import { FieldBuilder } from "components/Entity/EntityFormEngine/FieldBuilder";
import { EntityDataAttribute } from "types/Models/entity";
import { BASIC_INFORMATION_SECTION } from "constants/onBoarding";

export const BasicInfo = ({
  currentEntityDetails,
  onChange,
}: {
  currentEntityDetails: EntityDataAttribute[];
  onChange?: ({
    name,
    value,
  }: {
    name: string;
    value: EntityDataAttribute["value"];
  }) => void;
}) => {
  return (
    <div className="t-border t-border-solid t-p-2 t-rounded-lg t-border-neutral-10">
      <Accordion.Root
        type="single"
        defaultValue={BASIC_INFORMATION_SECTION}
        className="t-space-y-4"
        collapsible
      >
        <Accordion.Item
          key={BASIC_INFORMATION_SECTION}
          value={BASIC_INFORMATION_SECTION}
          className="t-p-2"
        >
          <Accordion.Trigger className="all:unset t-text-subtext-sm t-text-text-100 t-flex t-gap-2 t-group t-w-full">
            <span className="group-data-state-open:t-rotate-0 group-data-state-closed:-t-rotate-90 t-text-neutral t-transform t-transition t-duration-300 t-ease-in-out">
              <CaretDown />
            </span>
            <div className="t-space-x-1">
              <span className="t-text-subtext t-text-text-100">
                Basic Information
              </span>
              <span className="t-text-body-sm t-text-red">(Required)</span>
            </div>
          </Accordion.Trigger>
          <Accordion.Content className="t-mt-3 t-pl-6">
            <div className="t-grid t-grid-cols-2 t-gap-x-8 t-gap-y-3">
              {currentEntityDetails
                .filter(
                  ({ section_name, is_onboarding_field }) =>
                    section_name === BASIC_INFORMATION_SECTION &&
                    is_onboarding_field
                )
                .map((field) => {
                  return (
                    <FieldBuilder
                      field={field}
                      onChange={onChange}
                      key={field.uuid}
                    />
                  );
                })}
            </div>
          </Accordion.Content>
        </Accordion.Item>
      </Accordion.Root>
    </div>
  );
};
