import { Info } from "components/icons/Info";
import React from "react";

export const ProductInfo = ({ information }: { information: string }) => {
  return (
    <div className="t-flex t-gap-1.5 t-items-center t-bg-purple-0 t-rounded-lg t-p-2.5">
      <div className="t-text-purple-40 -t-mt-1">
        <Info stroke="1" />
      </div>
      <div className="t-text-body-sm t-text-text-60">{information}</div>
    </div>
  );
};
