import { DoubleArrowIcon } from "components/icons/DoubleArrowIcon";
import { SingleArrowIcon } from "components/icons/SingleArrowIcon";
import { motion } from "framer-motion";
import { Button } from "../Button/Button";
import { Combobox, OptionData } from "../Combobox/Combobox";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { MultiValue, SingleValue } from "react-select";
import { useState } from "react";

type PaginationProps = {
  totalItemCount: number;
  totalPage: number;
  currentPage: number;
  itemsPerPage: number;
  goToFirstPage: () => void;
  goToPrevPage: () => void;
  goToNextPage: () => void;
  goToLastPage: () => void;
  showOptions?: boolean;
};

export const Pagination = ({
  totalItemCount = 1,
  totalPage = 1,
  currentPage = 1,
  itemsPerPage = 1,
  goToFirstPage,
  goToPrevPage,
  goToNextPage,
  goToLastPage,
  showOptions = false,
}: PaginationProps) => {
  const localCurrentPage = currentPage < totalPage ? currentPage : totalPage;
  let startItem = 0;
  const { update } = useUpdateQuery();
  if (totalItemCount > 0) {
    startItem = (localCurrentPage - 1) * itemsPerPage + 1;
  }

  const endItem = Math.min(startItem + itemsPerPage - 1, totalItemCount);
  const disableNext = totalPage === localCurrentPage;
  const disablePrev = localCurrentPage === 1;

  const paginationOptions = [
    {
      label: "20 items",
      value: "20",
    },
    {
      label: "50 items",
      value: "50",
    },
    {
      label: "75 items",
      value: "75",
    },
    {
      label: "100 items",
      value: "100",
    },
  ];
  const query = useQuery();
  const currentPaginationOption = query.get("page_size");
  const selectedOption = paginationOptions.find(
    ({ value: optionValue }) => currentPaginationOption === optionValue
  );
  const handleOptions = (
    values: SingleValue<OptionData> | MultiValue<OptionData>
  ) => {
    if (values && !(values instanceof Array)) {
      const { value } = values;

      update({
        query: "page_size",
        value,
      });
    }
  };

  return (
    <div className="t-flex t-items-center t-gap-1">
      <div className="t-text-body-sm t-text-text-60 t-flex t-gap-1 t-overflow-hidden">
        <div className="t-flex t-gap-0.5">
          <motion.div
            key="startItem"
            layout
            initial={{ y: "60px" }}
            animate={{ y: 0 }}
            transition={{
              type: "spring",
              mass: 1,
              damping: 30,
              stiffness: 300,
              duration: 0.1,
            }}
          >
            {startItem}
          </motion.div>
          -
          <motion.div
            key="endItem"
            layout
            initial={{ y: "-60px" }}
            animate={{ y: 0 }}
            transition={{
              type: "spring",
              mass: 1,
              damping: 30,
              stiffness: 300,
              duration: 0.1,
            }}
          >
            {endItem}
          </motion.div>
        </div>

        <div className="t-whitespace-nowrap">of {totalItemCount}</div>
      </div>
      <span className="t-flex t-items-center">
        <Button
          size="small"
          customType="ghost_icon"
          disabled={disablePrev}
          onClick={goToFirstPage}
        >
          <span className="t-sr-only">Go to first page</span>
          <DoubleArrowIcon color="currentColor" />
        </Button>
        <Button
          size="small"
          customType="ghost_icon"
          disabled={disablePrev}
          onClick={goToPrevPage}
        >
          <span className="t-sr-only">Go to previous page</span>
          <SingleArrowIcon color="currentColor" />
        </Button>
        <span className="t-rotate-180">
          <Button
            size="small"
            customType="ghost_icon"
            disabled={disableNext}
            onClick={goToNextPage}
          >
            <span className="t-sr-only">Go to next page</span>
            <SingleArrowIcon color="currentColor" />
          </Button>
        </span>
        <span className="t-rotate-180">
          <Button
            size="small"
            customType="ghost_icon"
            disabled={disableNext}
            onClick={goToLastPage}
          >
            <span className="t-sr-only">Go to last page</span>
            <DoubleArrowIcon color="currentColor" />
          </Button>
        </span>

        {showOptions && (
          <Combobox
            menuPortalTarget={document.body}
            options={paginationOptions}
            onChange={handleOptions}
            isClearable={false}
            isSearchable={false}
            value={
              selectedOption
                ? {
                    label: selectedOption?.value,
                    value: selectedOption?.value,
                  }
                : {
                    label: "20",
                    value: "20",
                  }
            }
            styles={{
              menu: () => ({ width: "100px" }),
            }}
            size="small"
          />
        )}
      </span>
    </div>
  );
};
