import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { openMeetWithTranqModal } from "store/slices/chat";
import Lottie from "lottie-react";
import CallingGif from "components/animation/callRingingAnimation.json";
import authContext from "jwt_context&axios/authContext";
import { useMakeCustomerPhoneCallMutation } from "store/apis/chat";
import PhoneCallError from "static/images/PhoneCallError.svg";
import { useCheckAgentsAvailabilityQuery } from "store/apis/agents";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { InfoSolid } from "components/InfoSolid";
import { INKLE_CONSULTATION } from "constants/meetingRequestUrls";
import { UsersThree } from "components/icons/UsersThree";
import { ModalProps } from "types/utils/modal";

const Content = ({
  onCall,
  isLoading,
  close,
}: {
  onCall: () => void;
  isLoading: boolean;
  close: () => void;
}) => {
  const { authtoken } = useContext(authContext);
  const { mobile } = authtoken;
  const group = useCurrentGroupContext();

  const { data: availableAgents, isLoading: agentsLoading } =
    useCheckAgentsAvailabilityQuery(
      {
        groupId: group.uuid,
      },
      { skip: !group.uuid }
    );
  const agentsNotAvailable = availableAgents?.available_agent_count === 0;

  const dispatch = useDispatch();
  const commonLoader = isLoading || agentsLoading;

  return (
    <>
      <Modal.Header>
        <Modal.Title>Call Inkle Support</Modal.Title>
        <Modal.Close />
      </Modal.Header>
      <Modal.Body className="t-mb-0">
        {agentsNotAvailable ? (
          <div className="t-flex t-w-full t-border-solid t-border t-border-red-70 t-rounded t-px-2 t-py-1.5 t-items-center t-gap-1 t-bg-red-20">
            <span className="t-text-red-80">
              <InfoSolid size="24" />
            </span>
            <span className="t-text-body-sm t-text-text-60">
              Our team is currently unavailable. Please use Inkle Chat to reach
              out or schedule a call at your convenience.
            </span>
          </div>
        ) : (
          <>
            Please ensure you're available to answer the call at
            <b> {mobile}</b> by proceeding further.
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="t-flex t-justify-end t-gap-3">
          <div>
            <Button onClick={close}>Cancel</Button>
          </div>
          <div>
            {agentsNotAvailable ? (
              <Button
                customType="primary"
                onClick={() =>
                  dispatch(openMeetWithTranqModal(INKLE_CONSULTATION))
                }
              >
                <div className="t-flex t-gap-1 t-items-center t-justify-center">
                  <UsersThree />
                  <span>Meet Inkle Support</span>
                </div>
              </Button>
            ) : (
              <Button
                customType="primary"
                isLoading={commonLoader}
                disabled={commonLoader}
                onClick={onCall}
              >
                Continue
              </Button>
            )}
          </div>
        </div>
      </Modal.Footer>
    </>
  );
};

export const AgentCallingModals = ({ isOpen, close }: ModalProps) => {
  const { authtoken } = useContext(authContext);

  const { mobile } = authtoken;

  const [showPhoneCallingModal, setShowPhoneCallingModal] = useState(false);
  const [showErrorStateModal, setShowErrorStateModal] = useState(false);

  const [makeCall, { isLoading }] = useMakeCustomerPhoneCallMutation();
  const group = useCurrentGroupContext();

  const onCallHandler = async () => {
    try {
      await makeCall({ groupId: group.uuid }).unwrap();
      setShowErrorStateModal(false);
      close();
      setShowPhoneCallingModal(true);
      setTimeout(() => {
        setShowPhoneCallingModal(false);
      }, 10000);
    } catch (e) {
      close();
      setShowErrorStateModal(true);
    }
  };

  return (
    <>
      {/* Confirm call Modal */}
      <Modal.Root open={isOpen} onOpenChange={close}>
        <Modal.Content size="small">
          <Content onCall={onCallHandler} isLoading={isLoading} close={close} />
        </Modal.Content>
      </Modal.Root>

      {/* Phone Ringing Modal */}
      <Modal.Root
        open={showPhoneCallingModal}
        onOpenChange={() => setShowPhoneCallingModal(false)}
      >
        <Modal.Content size="small">
          <Modal.Header>
            <Modal.Title>Connecting your call</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            <div className="t-mb-11 t-mt-5 t-flex t-justify-center">
              <Lottie
                className="t-h-24 t-w-24"
                animationData={CallingGif}
                loop
              />
            </div>
            <div className="t-flex t-gap-4">
              <div className="t-flex t-h-6 t-w-6 t-justify-center t-rounded-[50%] t-border t-bg-i-surface-grey t-text-purple-50">
                1
              </div>
              <div>
                You'll receive a call on <b>{mobile}</b>
              </div>
            </div>
            <br />
            <div className="t-flex t-gap-4">
              <div className="t-flex t-h-6 t-w-6 t-justify-center t-rounded-[50%] t-bg-i-surface-grey t-text-purple-50">
                2
              </div>
              <div>Connecting you to an agent</div>
            </div>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>

      {/* Error State modal */}
      <Modal.Root
        open={showErrorStateModal}
        onOpenChange={() => setShowErrorStateModal(false)}
      >
        <Modal.Content size="small">
          <div className="t-flex t-justify-end t-p-4 t-items-center">
            <Modal.Close />
          </div>
          <Modal.Body className="!t-mb-0 t-pt-2">
            <div className="t-flex t-flex-col">
              <div className="t-flex t-justify-center">
                <img
                  src={PhoneCallError}
                  alt="PhoneCallError"
                  width="160px"
                  height="99px"
                />
              </div>
              <div className="t-flex t-justify-center">
                Something went wrong!
              </div>
              <div className="t-flex t-justify-center t-pt-5">
                <Button customType="primary" onClick={onCallHandler}>
                  Try Again
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </>
  );
};
