import {
  ABA_CODE,
  BIC,
  PAYMENT_METHOD,
  ROUTING_NUMBER,
} from "constants/tppayments";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { ReactNode, useState } from "react";
import CopyPurple from "static/images/CopyPurple.svg";
import { useGetParticularAutofillQuery } from "store/apis/autofill";
import { AddressAutofill } from "types/Models/addressAutofill";
import { Currency } from "types/Models/Currency";
import { TPAccountInfo } from "types/Models/TransferPricingTransfer";
import CopyCode from "./design/copyCode";
import { Divider } from "./design/Divider";
import DropDown from "./DesignSystem/Dropdown/Dropdown";
import Modal from "./DesignSystem/Modal/Modal";
import { AddEditAddress } from "./fileTax/AddEditAddress";
import { ArrowRight } from "./icons/ArrowRight";
import Pencil from "./icons/pencil";
import { InfoSolid } from "./InfoSolid";

interface TransferModalProps {
  openTransferMoney: boolean;
  onClose: () => void;
  accountInfo?: TPAccountInfo;
}

export const TransferDetails = ({
  receiver,
  senderBankAccount,
}: {
  receiver: TPAccountInfo["receiver"] | undefined;
  senderBankAccount: TPAccountInfo["sender_bank_account"] | undefined;
}) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<{
    name: string;
    key: string;
    required: string;
  }>({ name: "FedWire", key: "12", required: "Routing Number" });

  const getSelectedPaymentMode = () => {
    if (selectedPaymentMethod?.required === ABA_CODE) {
      return senderBankAccount?.domestic_wire || "";
    }
    if (selectedPaymentMethod?.required === ROUTING_NUMBER) {
      if (selectedPaymentMethod.name === "FedWire") {
        return senderBankAccount?.domestic_wire || "";
      }
      return senderBankAccount?.domestic_credit || "";
    }
    if (selectedPaymentMethod?.required === BIC) {
      return senderBankAccount?.global_wire || "";
    }
    return "";
  };

  return (
    <div className="t-flex t-flex-col t-gap-6">
      <div>
        <div className="t-flex t-w-full t-flex-col t-gap-3">
          <div className="t-flex t-flex-col t-gap-1.5">
            <div className="t-text-body-sm t-text-text-30">Recipient Name</div>
            <div className="t-flex t-gap-1 t-text-body">
              {receiver?.name && (
                <CopyCode
                  getCopyText={() => receiver.name}
                  defaultText={receiver.name}
                  copyIcon={CopyPurple}
                  /* @tw */
                  addClassName="all:unset"
                  alert={false}
                  codeCopiedText="Copied"
                />
              )}
            </div>
          </div>
          <div className="t-w-full t-flex t-gap-10">
            <div className="t-flex t-flex-col t-gap-1.5">
              <div className="t-text-body-sm t-text-text-30">Bank</div>
              {senderBankAccount?.bank_name && (
                <div className="t-flex t-gap-1 t-text-body">
                  <CopyCode
                    getCopyText={() => senderBankAccount?.bank_name}
                    defaultText={senderBankAccount?.bank_name}
                    copyIcon={CopyPurple}
                    /* @tw */
                    addClassName="all:unset"
                    alert={false}
                    codeCopiedText="Copied"
                  />
                </div>
              )}
            </div>
            <div className="t-flex t-flex-col t-gap-1.5">
              <div className="t-text-body-sm t-text-text-30">Account Type</div>
              <div className="t-flex t-gap-1 t-text-body">
                Business Checking
              </div>
            </div>
          </div>
          <div className="t-flex t-flex-col t-gap-1.5">
            <div className="t-text-body-sm t-text-text-30">Address</div>
            <div className="t-flex t-flex-col t-gap-3">
              <div className="t-flex t-gap-1 t-text-body">
                {senderBankAccount?.bank_address && (
                  <CopyCode
                    getCopyText={() => senderBankAccount?.bank_address}
                    defaultText={senderBankAccount?.bank_address}
                    copyIcon={CopyPurple}
                    /* @tw */
                    addClassName="all:unset"
                    alert={false}
                    codeCopiedText="Copied"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Divider />

      <div className="t-flex t-flex-col t-pr-6 t-gap-2">
        <div className="t-text-body-sm t-w-[120px] t-text-text-30">
          Payment Method
        </div>
        <div>
          <DropDown.Root>
            <DropDown.Trigger asChild>
              <div className="secondary-border t-flex t-items-center t-justify-between t-px-2 t-py-1 t-text-body t-font-medium t-drop-shadow-i-dropdown t-w-40">
                <div className="t-text-body">{selectedPaymentMethod.name}</div>
                <div className="t-rotate-90 group-data-state-open:-t-rotate-90">
                  <ArrowRight stroke="2.5" color="currentColor" />
                </div>
              </div>
            </DropDown.Trigger>
            <DropDown.Portal>
              <DropDown.Content className="t-w-[180px] t-py-2">
                {PAYMENT_METHOD.map((ele: any) => (
                  <DropDown.Item
                    key={ele.key}
                    onSelect={() => setSelectedPaymentMethod(ele)}
                  >
                    <div className="t-text-body">{ele.name}</div>
                  </DropDown.Item>
                ))}
              </DropDown.Content>
            </DropDown.Portal>
          </DropDown.Root>
        </div>
      </div>
      <div className="t-flex t-gap-3">
        <div className="t-flex t-flex-col t-pr-4">
          <div className="t-text-body-sm t-text-text-30">A/C Number</div>
          <div className="t-flex t-gap-1 t-pt-2 t-text-body">
            <CopyCode
              getCopyText={() => senderBankAccount?.account_number}
              defaultText={senderBankAccount?.account_number}
              copyIcon={CopyPurple}
              /* @tw */
              addClassName="all:unset"
              alert={false}
              codeCopiedText="Copied"
            />
          </div>
        </div>

        <div className="t-flex t-flex-col">
          <div className="t-text-body-sm t-text-text-30">
            {selectedPaymentMethod?.required}
          </div>
          <div className="t-flex t-gap-1 t-pt-2 t-text-body ">
            <CopyCode
              getCopyText={getSelectedPaymentMode}
              defaultText={getSelectedPaymentMode()}
              copyIcon={CopyPurple}
              /* @tw */
              addClassName="all:unset"
              alert={false}
              codeCopiedText="Copied"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const AccountDetails = ({
  title,
  body,
}: {
  title: string;
  body: ReactNode;
}) => {
  return (
    <div className="t-flex t-flex-col t-gap-4">
      <div className="t-text-subtitle">{title}</div>
      <div className="t-border t-border-solid t-border-neutral-0 t-rounded t-p-4">
        {body}
      </div>
    </div>
  );
};

export const TransferPricingInfo = ({
  openTransferMoney,
  onClose,
  accountInfo,
}: TransferModalProps) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const [addressData, setAddressData] = useState<AddressAutofill | undefined>();
  const [entityId, setEntityId] = useState("");

  const { receiver, sender_bank_account, sender, receiver_bank_account } =
    accountInfo || {};

  const { data: senderAddress } = useGetParticularAutofillQuery(
    {
      groupId,
      entityId: sender?.uuid!,
      uuid: accountInfo?.sender_address_uuid || "",
      autofillKey: "addresses",
    },
    {
      skip: !accountInfo?.sender_address_uuid || !sender?.uuid,
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: receiverAddress } = useGetParticularAutofillQuery(
    {
      groupId,
      entityId: receiver?.uuid!,
      uuid: accountInfo?.receiver_address_uuid || "",
      autofillKey: "addresses",
    },
    {
      skip: !accountInfo?.receiver_address_uuid || !receiver?.uuid,
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    isOpen: isAddEditAddressOpen,
    close: closeAddEditAddressOpen,
    open: openAddEditAddressOpen,
  } = useModal();

  const onAddressEdit = ({ type }: { type: "SENDER" | "RECEIVER" }) => {
    if (type === "SENDER") {
      setAddressData(senderAddress as unknown as AddressAutofill);
      setEntityId(sender?.uuid!);
    } else {
      setAddressData(receiverAddress as unknown as AddressAutofill);
      setEntityId(receiver?.uuid!);
    }
    openAddEditAddressOpen();
  };

  return (
    <>
      <Modal.Root open={openTransferMoney} onOpenChange={onClose}>
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>Account Info</Modal.Title>
            <Modal.Close className="all:unset" />
          </Modal.Header>
          <Modal.Body>
            <div className="t-flex t-flex-col t-gap-8">
              <AccountDetails
                title="Virtual Account transfer instructions"
                body={
                  <TransferDetails
                    receiver={receiver}
                    senderBankAccount={sender_bank_account}
                  />
                }
              />
              <AccountDetails
                title="Sender"
                body={
                  <div className="t-flex t-flex-col t-gap-4 t-text-body">
                    <div className="t-text-blue-70">{sender?.name}</div>
                    <div className="t-flex t-flex-col t-gap-1.5">
                      <div className="t-text-body-sm t-text-text-30 t-flex t-gap-0.5 t-items-center">
                        <span>Legal address</span>
                        <button
                          className="all:unset"
                          onClick={() => onAddressEdit({ type: "SENDER" })}
                        >
                          <Pencil color="currentColor" size="12" />
                        </button>
                      </div>
                      <div>
                        {
                          (senderAddress as unknown as AddressAutofill)
                            ?.autofill_string
                        }
                      </div>
                    </div>
                  </div>
                }
              />
              <AccountDetails
                title="Ultimate Recipient"
                body={
                  <div className="t-flex t-flex-col t-gap-4">
                    <div className="t-flex t-flex-col t-gap-4 t-text-body">
                      <div className=" t-text-blue-70">{receiver?.name}</div>
                      <div className="t-flex t-flex-col t-gap-1.5">
                        <div className="t-text-body-sm t-text-text-30 t-flex t-gap-0.5 t-items-center">
                          <span>Legal address</span>
                          <button
                            className="all:unset"
                            onClick={() => onAddressEdit({ type: "RECEIVER" })}
                          >
                            <Pencil color="currentColor" size="12" />
                          </button>
                        </div>
                        <div>
                          {
                            (receiverAddress as unknown as AddressAutofill)
                              ?.autofill_string
                          }
                        </div>
                      </div>

                      <Divider />
                      <div className="t-flex t-gap-4">
                        <div className="t-flex t-flex-col t-gap-1.5">
                          <div className="t-text-body-sm t-text-text-30">
                            Account number
                          </div>
                          <div>{receiver_bank_account?.account_number}</div>
                        </div>
                        <div className="t-flex t-flex-col t-gap-1.5">
                          <div className="t-text-body-sm t-text-text-30">
                            IFSC
                          </div>
                          <div>{receiver_bank_account?.domestic_credit}</div>
                        </div>
                      </div>
                    </div>
                    <div className="t-rounded-lg t-bg-yellow-10 t-flex t-gap-2 t-px-3 t-py-2 t-items-center">
                      <InfoSolid color="#A07D14" />
                      <div className="t-text-body-sm t-text-yellow-100">
                        For changing ultimate recipient bank account
                        information, please reach out to us on chat
                      </div>
                    </div>
                  </div>
                }
              />
            </div>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
      {isAddEditAddressOpen && (
        <AddEditAddress
          entityId={entityId}
          isOpenAdd={isAddEditAddressOpen}
          onClose={closeAddEditAddressOpen}
          initialValue={addressData as AddressAutofill}
          isEditFlow={true}
          onSuccess={(address: AddressAutofill) => {
            // setFieldValue("address.uuid", address.uuid);
          }}
          groupId={groupId}
          autofillKey="addresses"
        />
      )}
    </>
  );
};
