export const RenameIcon = ({
  color = "#706A85",
  size = 16,
  stroke = "1.4",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M6 13.5H3C2.86739 13.5 2.74022 13.4473 2.64645 13.3536C2.55268 13.2598 2.5 13.1326 2.5 13V10.2063C2.49978 10.1413 2.51236 10.077 2.53702 10.0169C2.56169 9.95688 2.59796 9.90228 2.64375 9.85626L10.1438 2.35626C10.1903 2.30901 10.2457 2.2715 10.3069 2.24589C10.3681 2.22028 10.4337 2.20709 10.5 2.20709C10.5663 2.20709 10.632 2.22028 10.6931 2.24589C10.7543 2.2715 10.8097 2.30901 10.8563 2.35626L13.6438 5.14376C13.691 5.19028 13.7285 5.24574 13.7541 5.3069C13.7797 5.36806 13.7929 5.4337 13.7929 5.50001C13.7929 5.56631 13.7797 5.63195 13.7541 5.69311C13.7285 5.75428 13.691 5.80973 13.6438 5.85626L6 13.5Z"
        stroke={color}
        strokeWidth={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 13.5H6"
        stroke={color}
        strokeWidth={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 4L12 7.5"
        stroke={color}
        strokeWidth={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
