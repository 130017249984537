import classNames from "classnames";
import { ConditionalLink } from "components/conditionalLink";
import { Divider } from "components/design/Divider";
import { Button } from "components/DesignSystem/Button/Button";
import ChatCard from "components/DesignSystem/ChatCard/ChatCard";
import { Link } from "components/DesignSystem/Link/Link";
import { Redirect } from "components/icons/Redirect";
import { ShieldCheck } from "components/icons/ShieldCheck";
import { ShieldStar } from "components/icons/ShieldStar";
import { EditSensitiveDataDynamicForm } from "components/SensitiveData/SensitiveData";
import { REQUESTED, SUBMITTED } from "constants/sensitiveData";
import { useAuth } from "hooks/useAuth";
import { useModal } from "hooks/useModal";
import { useRoleBasedView } from "hooks/useRoleBasedView";

type SensitiveDataCardProps = {
  customData: {
    description: string;
    header: string;
    card_status: typeof REQUESTED | typeof SUBMITTED;

    metadata: {
      name: string;
      sensitive_data_instance_id: string;
      entity_id: string;
      shared_profiles: { profile_id: string; name: string }[] | null;
    };
  };
  groupId: string;
  messageId: string;
};

const CardStatus = ({ status = REQUESTED }: { status: string }) => {
  return (
    <div
      className={classNames("t-p-1 t-flex t-items-center t-rounded", {
        "t-bg-green-30": status === SUBMITTED,
        "t-bg-yellow-30": status === REQUESTED,
      })}
    >
      <div
        className={classNames("", {
          "t-text-green-80": status === SUBMITTED,
          "t-text-yellow-80": status === REQUESTED,
        })}
      >
        <ShieldStar />
      </div>
    </div>
  );
};
export const SensitiveDataCard = ({
  customData,
  groupId,
  messageId,
}: SensitiveDataCardProps) => {
  const { metadata, description, header, card_status } = customData || {};
  const {
    sensitive_data_instance_id,
    entity_id,
    name: formName,
    shared_profiles,
  } = metadata || {};
  const { isCustomer, isAdmin } = useRoleBasedView();
  const { uuid: currentProfileId } = useAuth();
  const adminViewLink = `/admin/crm/${groupId}/entity/${entity_id}/sensitive-data/${sensitive_data_instance_id}`;

  const customerViewLink = `/tax/entities/entity/${entity_id}/sensitive-data/${sensitive_data_instance_id}`;

  const isSharedAccess = shared_profiles
    ? Boolean(
        shared_profiles.find(
          ({ profile_id }) => profile_id === currentProfileId
        )
      )
    : false;

  const {
    isOpen: isEditOpen,
    open: openEditModal,
    close: closeEditModal,
  } = useModal();

  return (
    <>
      <ChatCard.Root>
        <ChatCard.Header status={<></>}>
          <div className="t-flex t-gap-2 t-items-center">
            <CardStatus status={card_status} />
            <span className="t-flex t-flex-col">
              <span className=" t-text-subtitle-sm">{header} Requested</span>
              <span className=" t-text-body-sm t-items-center t-flex t-gap-1">
                This data will be secured{" "}
                <span className=" t-text-blue">
                  <ShieldCheck size="14" />
                </span>
              </span>
            </span>
          </div>
        </ChatCard.Header>
        <ChatCard.Body>
          <span className=" t-text-body">{formName}</span>
        </ChatCard.Body>
        <ChatCard.Footer>
          <div className=" t-w-full t-p-2">
            {card_status == REQUESTED && isCustomer && (
              <Button block customType="primary" onClick={openEditModal}>
                Fill out details
              </Button>
            )}
            {card_status == REQUESTED && isAdmin && (
              <Button block onClick={openEditModal}>
                Edit details
              </Button>
            )}
            {card_status == SUBMITTED &&
              (isCustomer || isAdmin || isSharedAccess) && (
                <ConditionalLink
                  target="_blank"
                  to={isCustomer ? customerViewLink : adminViewLink}
                >
                  <Button block customType="secondary" size="small">
                    <span className="t-flex t-gap-2 t-items-center">
                      View in sensitive data store{" "}
                      <Redirect color="currentColor" size={16} />
                    </span>
                  </Button>
                </ConditionalLink>
              )}
          </div>
        </ChatCard.Footer>
      </ChatCard.Root>
      <EditSensitiveDataDynamicForm
        formId={sensitive_data_instance_id}
        isOpen={isEditOpen}
        close={closeEditModal}
        entityId={entity_id!}
      />
    </>
  );
};
