import "bootstrap/dist/css/bootstrap.min.css";
import classNames from "classnames";
import CardAdd from "components/billing/CardAdd";
import { BrexHomeCard } from "components/BrexHomeCard/BrexHomeCard";
import { DashboardLayout } from "components/DashboardLayout";
import { DeadlineCard } from "components/DeadlineCard/DeadlineCard";
import { Header } from "components/DesignSystem/Header/Header";
import { ECLHomeCard } from "components/ECLHomeCard/ECLHomeCard";
import { OnboardingHomeCard } from "components/OnboardingHomeCard/OnboardingHomeCard";
import { ReferHomeCard } from "components/ReferHomeCard/ReferHomeCard";
import { SubscriptionModal } from "components/SubscriptionModal/SubscriptionModal";
import FreeSubscriptionModal from "components/tasks/task7004/FreeSubscriptionModal";
import * as PRODUCT_ONBOARDING from "constants/productOnboardings";
import { ACTIVE } from "constants/tax";
import { motion } from "framer-motion";
import { useAnalytics } from "hooks/useAnalytics";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { usePageTitle } from "hooks/usePageTitle";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { ReactNode, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import LockOnboarding from "static/images/LockOnboarding.svg";
import { useGetOnboardingProductsQuery } from "store/apis/productOnboarding";
import { openOnboardingWidget } from "store/slices/onboardingWidget";
import {
  closeCardAddModal,
  closeFreeSubscription,
} from "store/slices/subscriptionModal";
import { RootState } from "store/store";
import authContext from "../../jwt_context&axios/authContext";
import "../../static/styles/components/dashboardHome.css";
import "../../static/styles/components/home.css";
import WelcomeUser from "../design/welcomeUser";
import { ForeignCAHome } from "./ForeignCAHome";

export const LargeCard = ({
  children,
  block,
}: {
  children: ReactNode;
  block?: boolean;
}) => (
  <motion.div
    layout
    className={classNames(
      "t-w-full hover:t-shadow-light-30 t-transition-shadow t-rounded-lg t-overflow-hidden md:t-h-[200px]"
    )}
  >
    {children}
  </motion.div>
);

export const Home = () => {
  usePageTitle("Home");
  const history = useHistory();
  const dispatch = useDispatch();
  const { trackEvent } = useAnalytics();
  const { authtoken } = useContext(authContext);
  const { uuid: groupId } = useCurrentGroupContext();
  const { isCustomer, isForeignCA } = useRoleBasedView();

  const { isOpenFreeSubscription, isOpenCardAddModal } = useSelector(
    (state: RootState) => state.subscriptionModal
  );

  const { email, name, mobile, user_type } = authtoken;
  const { platform_subscription: platformSubscription } = authtoken || {};

  const subscriptionModal = useModal();

  const { data: products } = useGetOnboardingProductsQuery(
    {
      groupId: groupId!,
    },
    { skip: !groupId }
  );

  const platformOnboarding = products?.find(
    (p) =>
      p.name === PRODUCT_ONBOARDING.TAX || p.name === PRODUCT_ONBOARDING.TAXV2
  );
  const isTaxOnboardingInComplete =
    platformOnboarding && platformOnboarding.status !== "COMPLETED";

  const showCardsOnSubcription =
    isTaxOnboardingInComplete &&
    platformSubscription?.subscription_status !== ACTIVE;

  return (
    <div className="t-w-full">
      {isForeignCA && <ForeignCAHome />}
      {isCustomer ? (
        <DashboardLayout
          header={
            <Header
              v2
              className="!t-border-0"
              title={`Welcome ${authtoken?.first_name}`}
            />
          }
        >
          <motion.div
            layout
            className="t-pb-8 t-grid t-gap-6 t-flex-wrap md:t-grid-cols-[repeat(auto-fill,minmax(462px,1fr))] t-grid-cols-[repeat(auto-fill,1fr)]"
          >
            {showCardsOnSubcription ? (
              <>
                {isTaxOnboardingInComplete && (
                  <LargeCard>
                    <OnboardingHomeCard
                      onClick={() =>
                        dispatch(openOnboardingWidget(platformOnboarding?.name))
                      }
                      progress={platformOnboarding?.progress}
                    />
                  </LargeCard>
                )}
                <div className="t-flex t-flex-col t-mt-3 t-bg-surface-lighter-grey t-gap-4 t-items-center t-align-middle t-text-center t-py-12 t-rounded-lg t-w-full">
                  <img src={LockOnboarding} alt="Lock" />
                  <div className="t-flex t-flex-col t-gap-2">
                    <div className="t-text-subtitle-sm">Locked Features</div>
                    <div className="t-flex t-text-body">
                      <button
                        className="all:unset t-flex t-text-purple"
                        onClick={subscriptionModal.open}
                      >
                        Subscribe to Inkle Tax&nbsp;
                      </button>
                      <div>to unlock more features.</div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <DeadlineCard />
                {isTaxOnboardingInComplete && (
                  <LargeCard>
                    <OnboardingHomeCard
                      onClick={() =>
                        dispatch(openOnboardingWidget(platformOnboarding?.name))
                      }
                      progress={platformOnboarding?.progress}
                    />
                  </LargeCard>
                )}

                <LargeCard>
                  <ReferHomeCard />
                </LargeCard>

                <BrexHomeCard />
                <ECLHomeCard />
              </>
            )}
          </motion.div>

          <SubscriptionModal
            onPaymentDone={subscriptionModal.close}
            show={subscriptionModal.isOpen}
            onClose={subscriptionModal.close}
          />
          <FreeSubscriptionModal
            openSubscription={isOpenFreeSubscription}
            closeSubscription={() => dispatch(closeFreeSubscription())}
          />
          {isOpenCardAddModal && (
            <CardAdd
              openAddCard={isOpenCardAddModal}
              closeAddCard={() => dispatch(closeCardAddModal())}
            />
          )}
        </DashboardLayout>
      ) : (
        <WelcomeUser />
      )}
    </div>
  );
};
