import { AccordionAnimation } from "components/AccordionAnimation";
import Async from "components/DesignSystem/AsyncComponents/Async";
import Table from "components/DesignSystem/Table/V2/Table";
import { AutoAssignModal } from "components/Transaction/AutoAssignModal";
import { RuleConfirmation } from "components/Transaction/RuleConfirmation";
import { SplitTransaction } from "components/Transaction/SplitTransaction/SplitTransaction";
import TransactionColumn from "components/Transaction/TransactionColumn";
import { CategoryHeader } from "components/Transaction/TransactionTable";
import ConditionalToolTip from "components/design/conditionalToolTip";
import Loader from "components/design/loader";
import { DD_MMM_YYYY } from "constants/date";
import dayjs from "dayjs";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useWindowSize } from "hooks/useWindowSize";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import { useGetAllTransactionsDataQuery } from "store/apis/transactions";
import { RootState } from "store/store";
import { Transactions } from "types/Models/books";

export const VendorTransactions = ({
  vendorName,
  season,
}: {
  vendorName: string;
  season: string;
}) => {
  const { populateTransactionIDsList } = useSelector(
    (state: RootState) => state.chat
  );
  const dispatch = useAppDispatch();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { width = 0 } = useWindowSize();

  const { isOpen } = useSelector(
    (state: RootState) => state.similarTransactions
  );
  const { showSplitTransactionModal } = useSelector(
    (state: RootState) => state.splitTransaction
  );

  const {
    data: transactionData,
    isLoading,
    isSuccess,
    isFetching,
  } = useGetAllTransactionsDataQuery(
    {
      vendors: { value: [vendorName] },
      groupId: groupId,
      entityId: entityId,
      txnAccountType: "EXCLUDE_STRIPE",
      sortCol: "DATE",
      sortOrder: "DSC",
      startDate: {
        value: dayjs(`${season}-01-01`).startOf("year").format("YYYY-MM-DD"),
      },
      endDate: {
        value: dayjs(`${season}-01-01`).endOf("year").format("YYYY-MM-DD"),
      },
    },
    {
      skip: !groupId || !entityId || !vendorName,
      refetchOnMountOrArgChange: true,
    }
  );

  const { transactions = [] } = transactionData || {};

  const isEmpty = transactions.length === 0;
  const loading = Boolean(populateTransactionIDsList) ? isFetching : isLoading;
  const transactionsTableData = useMemo(() => transactions || [], [isFetching]);

  const showDescriptionColumn = width >= 1536;

  const columnHelper = createColumnHelper<Transactions>();

  const columns = useMemo(
    () => [
      columnHelper.accessor("transaction.date", {
        id: "DATE",
        size: showDescriptionColumn ? 15 : 17,
        header: "Date",
        cell: (info) => (
          <div className="t-text-subtext t-text-text-30">
            {dayjs(info.getValue()).format(DD_MMM_YYYY)}
          </div>
        ),
      }),

      columnHelper.accessor("transaction.from", {
        id: "logo",
        size: showDescriptionColumn ? 2 : 5,
        enableSorting: false,
        header: () => (
          <div className="t-flex t-justify-center t-w-full">Source</div>
        ),
        cell: (info) => {
          const transaction = info.row.original.transaction;

          return <TransactionColumn.Source transaction={transaction} />;
        },
      }),

      columnHelper.accessor("transaction.merchant", {
        id: "VENDOR",
        size: showDescriptionColumn ? 30 : 33,
        header: "TO/FROM",
        cell: (info) => {
          return <TransactionColumn.Merchant info={info} showSplitVendors />;
        },
      }),

      columnHelper.accessor("transaction.description", {
        id: "DESCRIPTION",
        enableSorting: false,
        size: showDescriptionColumn ? 15 : 0,
        header: "DESCRIPTION",
        cell: (info) => {
          const description = info.getValue();
          return (
            <ConditionalToolTip condition={description}>
              <span className="t-line-clamp-1">{description || "-"}</span>
            </ConditionalToolTip>
          );
        },
      }),

      columnHelper.accessor("transaction.amount", {
        id: "AMOUNT",
        size: showDescriptionColumn ? 8 : 12,
        header: () => (
          <span className="t-flex t-justify-end t-ml-auto">Amount</span>
        ),
        cell: (info) => {
          const amount = info.getValue();
          const {
            transaction: { is_credit_card },
          } = info.row.original || {};

          return (
            <TransactionColumn.Amount
              amount={amount}
              isCreditCard={is_credit_card}
            />
          );
        },
      }),

      columnHelper.accessor("transaction.category", {
        id: "category",
        size: 28,
        header: CategoryHeader,
        enableSorting: false,
        cell: (info) => {
          return (
            <TransactionColumn.Category
              info={info}
              tooltip
              truncate
              showSplitActions
            />
          );
        },
      }),
    ],
    [showDescriptionColumn]
  );

  const table = useReactTable({
    data: transactionsTableData || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      size: 10,
      minSize: 1,
      maxSize: 100,
    },
  });

  const openConfirmation = useModal();

  return (
    <AccordionAnimation>
      <Async.Root
        {...{ isLoading: loading, isEmpty, isSuccess }}
        loaderType="secondary"
        customLoader={
          <div className="t-w-full t-flex t-justify-center">
            <div className="t-h-4 t-w-4">
              <Loader customType="secondary" />
            </div>
          </div>
        }
      >
        <Async.Empty>
          <div className="t-text-text-60 t-text-body-sm t-text-center t-w-full t-p-1.5">
            No transactions found
          </div>
        </Async.Empty>
        <Async.Success>
          <div className="t-flex t-justify-end t-border t-border-solid t-border-t-0 t-border-r-0 t-border-l-0 t-border-neutral-0 t-pb-3 t-pt-1 t-text-text-60 t-text-body-sm">
            {transactions.length} transactions
          </div>
          <Table.Container className="!t-pb-0">
            <Table.Content>
              <Table.Head>
                {table.getHeaderGroups().map((headerGroup) => (
                  <Table.Row key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <Table.HeadCell
                        key={header.id}
                        style={{ width: `${header.getSize()}%` }}
                        className="!t-py-3"
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </Table.HeadCell>
                    ))}
                  </Table.Row>
                ))}
              </Table.Head>
              <Table.Body>
                {table.getRowModel().rows.map((row) => (
                  <Table.Row key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Table.Cell
                          key={cell.id}
                          style={{ width: `${cell.column.getSize()}%` }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Table.Cell>
                      );
                    })}
                  </Table.Row>
                ))}
              </Table.Body>
            </Table.Content>
          </Table.Container>
        </Async.Success>
      </Async.Root>
      {isOpen && <AutoAssignModal openConfirmation={openConfirmation.open} />}
      <RuleConfirmation
        isOpen={openConfirmation.isOpen}
        close={openConfirmation.close}
      />

      {showSplitTransactionModal && (
        <SplitTransaction isOpen={showSplitTransactionModal} />
      )}
    </AccordionAnimation>
  );
};
