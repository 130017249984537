import {
  SensitiveDataFormField,
  SensitiveDataFormFieldOptionType,
} from "types/Models/sensitiveData";
import { AnySchema, mixed, object, string, StringSchema } from "yup";

export const createDynamicValidationSchema = (
  fields: SensitiveDataFormField[]
) => {
  const shape: Record<string, AnySchema> = {};

  fields.forEach((field: SensitiveDataFormField) => {
    switch (field.data_type) {
      case "STRING":
        shape[field.field_key] = field.is_required
          ? string().required(`${field.key} is required`).nullable()
          : string().nullable();
        break;
      case "MULTI_FILE_INPUT":
        shape[field.field_key] = field.is_required
          ? mixed().required(`${field.key} is required`)
          : mixed();
        break;
      case "SELECT":
        shape[field.field_key] = field.is_required
          ? string().required(`${field.key} is required`).nullable()
          : string().nullable();
        break;
      default:
        shape[field.field_key] = string().nullable();
    }
  });

  // Return the Yup object schema
  return object().shape(shape);
};
