import Tab from "components/DesignSystem/Tab/Tab";
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import { ArchivedCrm } from "./ArchivedCrm";
import SalesCrm from "./salesCrm";
import SalesCrmProfiles from "./SalesCrmProfiles";
import { DashboardLayout } from "components/DashboardLayout";
import { Header } from "components/DesignSystem/Header/Header";

export const SalesCrmRoute = ({ selectedTabIs }: { selectedTabIs: string }) => {
  const { path, url } = useRouteMatch();
  const { pathname } = useLocation();

  return (
    <DashboardLayout
      header={
        <Header
          v2
          title={<div className="t-flex t-gap-2 t-items-center">Sales CRM</div>}
          bottom={
            <Tab.Root defaultValue={pathname} value={pathname}>
              <Tab.List>
                <Tab.NavTrigger exact value={url}>
                  Groups
                </Tab.NavTrigger>
                <Tab.NavTrigger exact value={`${url}/profiles`}>
                  Profiles
                </Tab.NavTrigger>
                <Tab.NavTrigger exact value={`${url}/archived`}>
                  Archived
                </Tab.NavTrigger>
              </Tab.List>
            </Tab.Root>
          }
        />
      }
    >
      <Switch>
        <Route exact path={path}>
          <SalesCrm />
        </Route>
        <Route exact path={`${path}/profiles`}>
          <SalesCrmProfiles />
        </Route>
        <Route exact path={`${path}/archived`}>
          <ArchivedCrm />
        </Route>
      </Switch>
    </DashboardLayout>
  );
};
