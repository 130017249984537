import { Button } from "components/DesignSystem/Button/Button";
import { REGISTERED_AGENT } from "constants/addons";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useDispatch } from "react-redux";
import { useGetSubscriptionsQuery } from "store/apis/subscriptions";
import { setAddonData, setAddonType } from "store/slices/addons";

export const SubscribeRA = ({ stateName }: { stateName?: string }) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { data: allSubscriptions = [] } = useGetSubscriptionsQuery(
    {
      groupId,
      entityId,
    },
    {
      skip: !entityId || !groupId,
    }
  );
  const dispatch = useDispatch();

  const registeredAgents = allSubscriptions.filter(
    (ele) => ele.subscription_type === REGISTERED_AGENT
  );

  const registeredAgentStateMappings =
    registeredAgents
      ?.filter((ele) => ele.group_subscription)
      ?.map((ele) => {
        return {
          amount: ele?.amount,
          selectedStates: ele?.group_subscription,
          type: ele?.metadata?.ra_state,
          uuid: ele?.uuid,
        };
      }) || [];

  const registeredAgentMinAmount = registeredAgents?.reduce((min, obj) => {
    return Number(obj?.amount) < Number(min)
      ? Number(obj?.amount)
      : Number(min);
  }, Number(registeredAgents?.[0]?.amount));

  const registeredAgentUnselectedStates = registeredAgents
    ?.filter((ele) => !ele.group_subscription)
    ?.map((ele) => {
      return {
        amount: ele?.amount,
        type: ele?.metadata?.ra_state,
        uuid: ele?.uuid,
      };
    });

  const registeredAgent = {
    amount: registeredAgentMinAmount,
    billing_cycle: "MONTHLY",
    description:
      "Registered Agent service for all states of the United States of America",
    subscription_name: "Registered Agent",
    subscription_type: REGISTERED_AGENT,
    ...(registeredAgentStateMappings?.length > 0
      ? { group_subscription: registeredAgentStateMappings }
      : {}),
    selectedState: stateName,
    disableStateChange: true,
    unselectedStates: registeredAgentUnselectedStates,
  };

  const openRAAddon = () => {
    dispatch(setAddonData(registeredAgent));
    dispatch(setAddonType(REGISTERED_AGENT));
  };

  return (
    <div className="t-space-y-3">
      <div className="t-text-body t-text-text-60">
        Apply for Registered Agent with Inkle
      </div>
      <Button
        type="button"
        customType="primary"
        size="small"
        onClick={openRAAddon}
      >
        Get agent
      </Button>
    </div>
  );
};
