export const BookkeepingServices = () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_424_50135)">
      <path
        d="M14.8113 10H11.8113V11.5H14.8113V10Z"
        stroke="currentColor"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8113 14.75V9.25C15.8113 9.11193 15.6994 9 15.5613 9H11.0613C10.9232 9 10.8113 9.11193 10.8113 9.25V14.75C10.8113 14.8881 10.9232 15 11.0613 15H15.5613C15.6994 15 15.8113 14.8881 15.8113 14.75Z"
        stroke="currentColor"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0613 13C12.2684 13 12.4363 12.8321 12.4363 12.625C12.4363 12.4179 12.2684 12.25 12.0613 12.25C11.8542 12.25 11.6863 12.4179 11.6863 12.625C11.6863 12.8321 11.8542 13 12.0613 13Z"
        fill="currentColor"
      />
      <path
        d="M13.3113 13C13.5184 13 13.6863 12.8321 13.6863 12.625C13.6863 12.4179 13.5184 12.25 13.3113 12.25C13.1042 12.25 12.9363 12.4179 12.9363 12.625C12.9363 12.8321 13.1042 13 13.3113 13Z"
        fill="currentColor"
      />
      <path
        d="M14.5613 13C14.7684 13 14.9363 12.8321 14.9363 12.625C14.9363 12.4179 14.7684 12.25 14.5613 12.25C14.3542 12.25 14.1863 12.4179 14.1863 12.625C14.1863 12.8321 14.3542 13 14.5613 13Z"
        fill="currentColor"
      />
      <path
        d="M12.0613 14.25C12.2684 14.25 12.4363 14.0821 12.4363 13.875C12.4363 13.6679 12.2684 13.5 12.0613 13.5C11.8542 13.5 11.6863 13.6679 11.6863 13.875C11.6863 14.0821 11.8542 14.25 12.0613 14.25Z"
        fill="currentColor"
      />
      <path
        d="M13.3113 14.25C13.5184 14.25 13.6863 14.0821 13.6863 13.875C13.6863 13.6679 13.5184 13.5 13.3113 13.5C13.1042 13.5 12.9363 13.6679 12.9363 13.875C12.9363 14.0821 13.1042 14.25 13.3113 14.25Z"
        fill="currentColor"
      />
      <path
        d="M14.5613 14.25C14.7684 14.25 14.9363 14.0821 14.9363 13.875C14.9363 13.6679 14.7684 13.5 14.5613 13.5C14.3542 13.5 14.1863 13.6679 14.1863 13.875C14.1863 14.0821 14.3542 14.25 14.5613 14.25Z"
        fill="currentColor"
      />
      <path
        d="M7 10C9.20914 10 11 8.20914 11 6C11 3.79086 9.20914 2 7 2C4.79086 2 3 3.79086 3 6C3 8.20914 4.79086 10 7 10Z"
        stroke="currentColor"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 13.5C2.21063 11.4081 4.40937 10 7 10C8.30018 10 8.97261 10.3822 10 11"
        stroke="currentColor"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_424_50135">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0.169189)"
        />
      </clipPath>
    </defs>
  </svg>
);
