import React from "react";

export const InkleMailroomLogo = () => {
  return (
    <svg
      width="185"
      height="20"
      viewBox="0 0 185 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.0381 12.2876L3.9262 7.37914V16.7971C3.9262 17.6458 3.23826 18.3337 2.38964 18.3337C1.54103 18.3337 0.853088 17.6458 0.853088 16.7971V3.22076C0.853088 2.37214 1.54103 1.6842 2.38964 1.6842C2.45699 1.6842 2.52332 1.68854 2.58838 1.69694C2.8906 1.67275 3.26972 1.6793 3.76873 1.68792C4.08252 1.69334 4.44373 1.69958 4.86302 1.69951L10.6955 1.69951L16.3629 1.69945C17.0168 1.69945 17.5508 1.68817 17.9925 1.67883C18.5461 1.66713 18.9549 1.65849 19.2736 1.67894C19.288 1.67854 19.3024 1.67834 19.3169 1.67834C20.1655 1.67834 20.8534 2.36628 20.8534 3.2149V16.7913C20.8534 17.6399 20.1655 18.3278 19.3169 18.3278C18.4683 18.3278 17.7803 17.6399 17.7803 16.7913V7.52831L11.3712 12.3113C10.9734 12.6082 10.4251 12.5985 10.0381 12.2876Z"
        fill="url(#paint0_linear_8954_1071)"
      />
      <path
        d="M30.8526 2.77255C30.8526 1.56909 31.6326 0.789062 32.8584 0.789062C34.0618 0.789062 34.8419 1.56909 34.8419 2.77255C34.8419 3.97602 34.0618 4.77833 32.8584 4.77833C31.6326 4.77833 30.8526 3.97602 30.8526 2.77255ZM34.6413 19.8885H31.0755V6.51667H34.6413V19.8885Z"
        fill="url(#paint1_linear_8954_1071)"
      />
      <path
        d="M45.5815 13.1803C45.5815 11.2191 44.5563 9.92649 42.974 9.92649C41.4808 9.92649 40.3888 11.3082 40.3888 13.2026V19.8885H36.823V6.51667H40.3888V8.07672C40.8568 7.07383 42.2386 6.36066 43.6203 6.36066C47.0301 6.36066 49.1473 9.07961 49.1473 13.1803V19.8885H45.5815V13.1803Z"
        fill="url(#paint2_linear_8954_1071)"
      />
      <path
        d="M54.8645 19.8885H51.2987V0.945067H54.8645V12.5117L59.3886 6.51667H63.2888L58.7423 13.2026L63.7568 19.8885H59.2326L54.8645 13.8935V19.8885Z"
        fill="url(#paint3_linear_8954_1071)"
      />
      <path
        d="M68.6575 19.8885H65.0917V0.945067H68.6575V19.8885Z"
        fill="url(#paint4_linear_8954_1071)"
      />
      <path
        d="M79.4987 15.7878H83.2874C82.3737 18.2839 79.989 19.9999 77.2701 19.9999C73.37 19.9999 70.6287 17.1696 70.6287 13.158C70.6287 9.28019 73.4591 6.33838 77.2032 6.33838C80.9474 6.33838 83.7777 9.28019 83.7777 13.158C83.7777 13.5815 83.7554 14.0272 83.6886 14.4283H74.3729C74.774 15.7878 75.8661 16.657 77.2478 16.657C78.2953 16.657 79.1199 16.3004 79.4987 15.7878ZM74.3729 11.8654H80.0336C79.6325 10.5505 78.585 9.70363 77.2032 9.70363C75.8215 9.70363 74.774 10.5505 74.3729 11.8654Z"
        fill="url(#paint5_linear_8954_1071)"
      />
      <path
        d="M90.9798 19.8776H88.7777V2.54639H90.9798L96.8316 11.946L102.704 2.54639H104.906V19.8776H102.704V6.74667L97.7288 14.5559H95.9549L90.9798 6.74667V19.8776Z"
        fill="#41395C"
      />
      <path
        d="M116.592 19.8776V17.594C116.041 19.0213 114.288 20 112.453 20C108.946 20 106.621 17.5124 106.621 13.7403C106.621 9.98863 108.925 7.50108 112.453 7.50108C114.267 7.50108 116.041 8.47979 116.592 9.92746V7.64381H118.774V19.8776H116.592ZM108.823 13.7403C108.823 16.1871 110.414 17.7979 112.718 17.7979C115.001 17.7979 116.592 16.1871 116.592 13.7607C116.592 11.3547 114.961 9.68278 112.697 9.68278C110.393 9.68278 108.823 11.2936 108.823 13.7403Z"
        fill="#41395C"
      />
      <path
        d="M120.96 3.97367C120.96 3.19886 121.47 2.66873 122.286 2.66873C123.06 2.66873 123.57 3.19886 123.57 3.97367C123.57 4.76886 123.06 5.299 122.286 5.299C121.47 5.299 120.96 4.76886 120.96 3.97367ZM123.387 19.8776H121.184V7.64381H123.387V19.8776Z"
        fill="#41395C"
      />
      <path
        d="M127.821 19.8776H125.619V2.54639H127.821V19.8776Z"
        fill="#41395C"
      />
      <path
        d="M136.864 7.50108V9.68278C133.847 9.66239 132.114 11.2324 132.114 13.985V19.8776H129.932V7.64381H132.114V10.1517C133.092 8.07199 135.172 7.50108 136.864 7.50108Z"
        fill="#41395C"
      />
      <path
        d="M137.826 13.7403C137.826 10.2129 140.436 7.50108 143.821 7.50108C147.185 7.50108 149.775 10.2129 149.775 13.7403C149.775 17.2881 147.185 20 143.821 20C140.436 20 137.826 17.2881 137.826 13.7403ZM143.821 9.68278C141.68 9.68278 140.028 11.4363 140.028 13.7403C140.028 16.0648 141.68 17.7979 143.821 17.7979C145.962 17.7979 147.593 16.0648 147.593 13.7403C147.593 11.4363 145.962 9.68278 143.821 9.68278Z"
        fill="#41395C"
      />
      <path
        d="M151.292 13.7403C151.292 10.2129 153.901 7.50108 157.286 7.50108C160.65 7.50108 163.24 10.2129 163.24 13.7403C163.24 17.2881 160.65 20 157.286 20C153.901 20 151.292 17.2881 151.292 13.7403ZM157.286 9.68278C155.145 9.68278 153.494 11.4363 153.494 13.7403C153.494 16.0648 155.145 17.7979 157.286 17.7979C159.427 17.7979 161.058 16.0648 161.058 13.7403C161.058 11.4363 159.427 9.68278 157.286 9.68278Z"
        fill="#41395C"
      />
      <path
        d="M173.688 13.7403C173.688 11.2732 172.423 9.68278 170.466 9.68278C168.651 9.68278 167.346 11.3955 167.346 13.7607V19.8776H165.144V7.64381H167.346V9.92746C167.815 8.52057 169.202 7.50108 170.833 7.50108C172.79 7.50108 174.299 8.50018 175.135 10.1721C175.93 8.47979 177.317 7.50108 179.07 7.50108C182.353 7.50108 184.433 9.96824 184.433 13.7403V19.8776H182.231V13.7403C182.231 11.2732 180.967 9.68278 178.989 9.68278C177.195 9.68278 175.89 11.3955 175.89 13.7403V19.8776H173.688V13.7403Z"
        fill="#41395C"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8954_1071"
          x1="0.853088"
          y1="10.0002"
          x2="20.8534"
          y2="10.0002"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8954_1071"
          x1="30.8526"
          y1="10.3945"
          x2="83.7777"
          y2="10.3945"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_8954_1071"
          x1="30.8526"
          y1="10.3945"
          x2="83.7777"
          y2="10.3945"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_8954_1071"
          x1="30.8526"
          y1="10.3945"
          x2="83.7777"
          y2="10.3945"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_8954_1071"
          x1="30.8526"
          y1="10.3945"
          x2="83.7777"
          y2="10.3945"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_8954_1071"
          x1="30.8526"
          y1="10.3945"
          x2="83.7777"
          y2="10.3945"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
