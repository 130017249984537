import {
  PAY_AS_YOU_GO_ANNUAL_BOOKKEEPING,
  PRO_MONTHLY_BOOKKEEPING,
} from "types/Models/services";
import { object, string } from "yup";

export const createServiceSchema = object({
  endDate: string().when("serviceActionType", {
    is: PRO_MONTHLY_BOOKKEEPING,
    then: string().notRequired(),
    otherwise: string().when("serviceActionType", {
      is: PAY_AS_YOU_GO_ANNUAL_BOOKKEEPING,
      then: string().notRequired(),
      otherwise: string().required("Please select end date"),
    }),
  }),
  startDate: string().when("serviceActionType", {
    is: PAY_AS_YOU_GO_ANNUAL_BOOKKEEPING,
    then: string().notRequired(),
    otherwise: string().required("Please select start date"),
  }),
});
