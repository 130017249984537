import Loader from "components/design/loader";
import { TableUI } from "components/design/TableUI";
import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import Modal from "components/DesignSystem/Modal/Modal";
import { BareTimePicker, TimePicker } from "components/TimePicker/TimePicker";
import dayjs from "dayjs";
import { Formik, Form, Field, FieldProps } from "formik";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { memo, useMemo, useCallback } from "react";
import {
  createColumnHelper,
  CellContext,
  useReactTable,
  getCoreRowModel,
} from "react-table-8.10.7";
import {
  useGetAgentAvailabilityQuery,
  useUpdateAgentAvailabilityMutation,
  useGetAssignAgentsQuery,
  useAddAgentAvailabilityMutation,
} from "store/apis/agents";
import { AgentAvailability } from "types/Models/agents";
import { BackendError } from "types/utils/error";
import utcPlugin from "dayjs/plugin/utc";
import { timeToDateTime } from "utils/timeToDateTime";

dayjs.extend(utcPlugin);

const createAvailabilityColumn = createColumnHelper<AgentAvailability>();

export const AvailabilitySettings = () => {
  const availabilityModal = useModal();
  const { successToast, alertToast } = useToast();

  const { data: availability } = useGetAgentAvailabilityQuery();
  const [updateAgentAvailability, { isLoading: isUpdating }] =
    useUpdateAgentAvailabilityMutation();
  const availabilityTableData = useMemo(
    () => availability || [],
    [availability]
  );

  const updateAgent = useCallback(
    async <
      T extends "is_available" | "available_start_time" | "available_end_time"
    >(
      uuid: string,
      field: T,
      allAgents: AgentAvailability[],
      value: boolean | string
    ) => {
      try {
        let newValue: typeof value | undefined = value;

        if (
          (field === "available_start_time" ||
            field === "available_end_time") &&
          typeof newValue !== "boolean"
        ) {
          if (!dayjs(newValue).isValid()) {
            newValue = undefined;
          } else {
            newValue = dayjs(newValue).utc().format("HH:mm:ss");
          }
        }

        const currentAgent = allAgents.find((agent) => agent.uuid === uuid);

        await updateAgentAvailability({
          agentId: uuid,
          payload: { ...currentAgent, [field]: newValue },
        }).unwrap();
        successToast({ message: "Agent updated successfully" });
      } catch (error) {
        alertToast({ message: (error as BackendError).data?.error?.message });
      }
    },
    [updateAgentAvailability, successToast, alertToast]
  );

  const availabilityColumns = useMemo(() => {
    return [
      createAvailabilityColumn.accessor("name", { header: "Agent", size: 35 }),
      createAvailabilityColumn.accessor("is_available", {
        header: "Available",
        size: 15,
        cell: (info) => {
          return (
            <Checkbox
              checked={info.getValue()}
              onChange={(e) =>
                updateAgent(
                  info.row.original.uuid,
                  "is_available",
                  info.table.options.data,
                  e.target.checked
                )
              }
            />
          );
        },
      }),

      createAvailabilityColumn.accessor("available_start_time", {
        header: "Start at",
        size: 25,
        cell: (info) => {
          const defaultValue = info.getValue()
            ? timeToDateTime(info.getValue())
            : null;

          return (
            <BareTimePicker
              name="available_start_time"
              onDateChange={(date) =>
                date &&
                updateAgent(
                  info.row.original.uuid,
                  "available_start_time",
                  info.table.options.data,
                  date
                )
              }
              value={defaultValue || ""}
            />
          );
        },
      }),
      createAvailabilityColumn.accessor("available_end_time", {
        header: "End at",
        size: 25,
        cell: (info) => {
          const defaultValue = info.getValue()
            ? timeToDateTime(info.getValue())
            : null;

          return (
            <BareTimePicker
              name="available_end_time"
              onDateChange={(date) =>
                date &&
                updateAgent(
                  info.row.original.uuid,
                  "available_end_time",
                  info.table.options.data,
                  date
                )
              }
              value={defaultValue || ""}
            />
          );
        },
      }),
    ];
  }, [updateAgent]);

  const availabilityTable = useReactTable({
    data: availabilityTableData,
    columns: availabilityColumns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.uuid,
  });
  const { data: agents } = useGetAssignAgentsQuery({});

  const [addAgentAvailability, { isLoading: creatingAgentAvailability }] =
    useAddAgentAvailabilityMutation();

  const onAvailabilitySubmit = async (
    values: Parameters<typeof addAgentAvailability>[0]["payload"]
  ) => {
    try {
      await addAgentAvailability({
        payload: {
          profile_id: values.profile_id,
          is_available: values.is_available,
          available_start_time: dayjs(values.available_start_time)
            .utc()
            .format("HH:mm:ss"),
          available_end_time: dayjs(values.available_end_time)
            .utc()
            .format("HH:mm:ss"),
        },
      }).unwrap();
      availabilityModal.close();
      successToast({ message: "Agent availability added successfully" });
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  return (
    <div className="t-w-4/6 t-relative">
      <div className="t-flex t-justify-between">
        <p className="t-m-0 t-text-subtitle-sm">Availability</p>
        <Modal.Root
          onOpenChange={availabilityModal.toggle}
          open={availabilityModal.isOpen}
        >
          <Modal.Trigger asChild>
            <Button size="small" customType="primary-outlined">
              Add
            </Button>
          </Modal.Trigger>
          <Modal.Content useCustomOverlay>
            <Formik
              onSubmit={onAvailabilitySubmit}
              initialValues={{
                profile_id: "",
                is_available: true,
                available_start_time: "",
                available_end_time: "",
              }}
            >
              {({ submitForm }) => {
                return (
                  <>
                    <Modal.Header>
                      <Modal.Title>Add Availability</Modal.Title>
                      <Modal.Close />
                    </Modal.Header>
                    <Modal.Body>
                      <Form className="t-m-0 t-flex t-flex-col t-gap-4">
                        <Combobox
                          menuPortalTarget={document.body}
                          name="profile_id"
                          withForm
                          label="Agent"
                          options={
                            agents?.map((agent) => ({
                              value: agent.profile_id,
                              label: agent.name,
                            })) || []
                          }
                        />
                        <Field name="available_start_time">
                          {({ field }: FieldProps) => (
                            <TimePicker label="Available from " {...field} />
                          )}
                        </Field>
                        <Field name="available_end_time">
                          {({ field }: FieldProps) => (
                            <TimePicker label="Available till" {...field} />
                          )}
                        </Field>

                        <Field name="is_available">
                          {({ field }: FieldProps) => (
                            <div className="t-flex t-gap-1">
                              <Checkbox
                                {...field}
                                checked={field.value}
                                id="is_available-add"
                              />
                              <label htmlFor="is_available-add">
                                Is available
                              </label>
                            </div>
                          )}
                        </Field>
                      </Form>
                    </Modal.Body>
                    <Modal.FooterButtonGroup>
                      <Modal.RawClose asChild>
                        <Button>Cancel</Button>
                      </Modal.RawClose>
                      <Button
                        onClick={submitForm}
                        customType="primary"
                        isLoading={creatingAgentAvailability}
                        disabled={creatingAgentAvailability}
                      >
                        Save
                      </Button>
                    </Modal.FooterButtonGroup>
                  </>
                );
              }}
            </Formik>
          </Modal.Content>
        </Modal.Root>
      </div>
      <TableUI table={availabilityTable} />
      {isUpdating && (
        <div className="t-h-full t-w-full t-flex t-items-center t-justify-center t-absolute t-inset-0">
          <div className="t-h-full t-w-full t-absolute t-bg-white t-opacity-60"></div>
          <Loader />
        </div>
      )}
    </div>
  );
};
