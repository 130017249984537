import { createSelector } from "@reduxjs/toolkit";
import { VendorsFilterName } from "store/slices/vendorsFilter";
import { RootState } from "store/store";

const getVendorsFilter = (state: RootState) => state.vendorsFilter;

export const getVendorsFilterStatus = createSelector(
  [getVendorsFilter],
  ({
    filters,
  }: {
    filters: {
      [key: string]: {
        name: string;
        value: any;
        type: string;
        fixed: boolean;
        removable: boolean;
      };
    };
  }) => {
    const season = Object.values(filters).filter(
      ({ type }) => type === "season"
    );

    const wForm = Object.values(filters).filter(({ type }) => type === "wForm");

    const appliedFiltersCount = [...season, ...wForm].filter(({ value }) => {
      if (Array.isArray(value) && value?.length === 0) {
        return null;
      }
      return value;
    })?.length;

    const capsuleFilters = Object.values(filters).filter(
      ({ name, value, fixed }) => {
        if ((Array.isArray(value) && value?.length === 0) || fixed) {
          return null;
        }
        return value;
      }
    );

    const fixedFilters = Object.values(filters).filter(({ fixed }) => fixed);

    const getFilterName = (name: string) => {
      for (let key in filters) {
        if (filters.hasOwnProperty(key) && filters[key].name === name) {
          return key as VendorsFilterName;
        }
      }
    };

    return {
      appliedFiltersCount,
      capsuleFilters,
      getFilterName,
      filters: {
        season,
        wForm,
      },
      fixedFilters,
    };
  }
);
