import dayjs from "dayjs";
import { colors } from "./colors";

export const lastFiveYears = Array.from({ length: 5 }, (_, i) => i)
  .reverse()
  .map((i) => dayjs().subtract(i, "year").format("YYYY"));

export enum RevenueBasis {
  PRODUCT = "PRODUCT",
  CUSTOMER = "CUSTOMER",
}

export enum PeriodType {
  MONTHLY = "MONTHLY",
  YEARLY = "YEARLY",
  QUARTERLY = "QUARTERLY",
}

export enum MetricType {
  TOTAL = "TOTAL",
  MRR = "MRR",
  ARR = "ARR",
}

export enum AccountingMethod {
  CASH = "CASH",
  ACCRUAL = "ACCRUAL",
}

export const PROFIT_LOSS_LINE_COLOR = colors.purple[30];
export const REVENUE_BAR_COLOR = colors.purple[20];
export const EXPENSE_BAR_COLOR = colors.neutral[10];
