import { Transaction } from "@sentry/react";
import { Avatar } from "components/DesignSystem/AvatarGroup/Avatar";
import { MerchantSelector } from "components/Transaction/MerchantSelector";
import { getIn, useFormikContext } from "formik";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { SingleValue } from "react-select";
import {
  useCreateMerchantMutation,
  useGetAllTransactionMerchantsQuery,
} from "store/apis/merchant";
import { BackendError } from "types/utils/error";
import { formatOrdinals } from "utils/formatOrdinals";

export const VendorValue = ({
  name,
  label,
  index,
}: {
  name: string;
  label: string;
  index: number;
}) => {
  const { setFieldValue, values } = useFormikContext<{
    transactions: Transaction[];
  }>();

  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();

  const currentValues = (getIn(values, name) || []) as string[];
  const merchantIds = currentValues.join(",");

  const {
    data: merchants,
    isLoading,
    isFetching,
  } = useGetAllTransactionMerchantsQuery(
    {
      merchantIds: merchantIds,
      groupId,
      entityId,
    },
    { skip: !merchantIds || !groupId || !entityId }
  );

  const { alertToast } = useToast();
  const [createMerchant] = useCreateMerchantMutation();

  const merchantChange = async (values: SingleValue<any>) => {
    setFieldValue(name, values?.merchantId || null);
  };

  const onCreateMerchant = async (name: string) => {
    try {
      const payload = new FormData();

      payload.append("name", name);

      const data = await createMerchant({
        groupId,
        entityId,
        payload,
      }).unwrap();
      setFieldValue(name, data.merchant_id);
    } catch (error) {
      alertToast({ message: (error as BackendError)?.data?.error?.message });
    }
  };

  const selected = currentValues.length
    ? currentValues
        .map((merchantId) =>
          merchants?.merchants.find((merchant) => merchant.uuid === merchantId)
        )
        .filter((merchant) => merchant !== undefined)
        .map((merchant) => ({
          value: merchant.uuid,
          label: (
            <div className="t-flex t-gap-2 t-items-center">
              <Avatar src={merchant.logo || ""} alt={merchant.name} />
              <div className="t-text-subtext t-max-w-[300px] t-truncate">
                {merchant.name}
              </div>
            </div>
          ),
        }))
    : null;

  return (
    <MerchantSelector
      aria-label={`${formatOrdinals(index + 1)} Value`}
      label={label}
      isMulti
      multiValueShowLimit={1}
      isLoading={isLoading || isFetching}
      merchantChange={merchantChange}
      onCreateMerchant={onCreateMerchant}
      withForm
      name={name}
      value={selected}
      creatable={false}
      filterOption={() => true}
      components={{
        MultiValueLabel: (e) => {
          const merchant = merchants?.merchants.find(
            (merchant) => merchant.uuid === e.data.value
          );
          return <span className="t-line-clamp-1">{merchant?.name}</span>;
        },
      }}
    />
  );
};
