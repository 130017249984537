export const Padlock = () => (
  <>
    <span className="t-sr-only">Padlock</span>
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00004 11.3333C8.36823 11.3333 8.66671 11.0349 8.66671 10.6667C8.66671 10.2985 8.36823 10 8.00004 10C7.63185 10 7.33337 10.2985 7.33337 10.6667C7.33337 11.0349 7.63185 11.3333 8.00004 11.3333Z"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6667 6.6665H3.33333C2.59695 6.6665 2 7.26346 2 7.99984V13.3332C2 14.0696 2.59695 14.6665 3.33333 14.6665H12.6667C13.403 14.6665 14 14.0696 14 13.3332V7.99984C14 7.26346 13.403 6.6665 12.6667 6.6665Z"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.66663 6.66683V4.66683C4.66663 3.78277 5.01782 2.93493 5.64294 2.30981C6.26806 1.68469 7.1159 1.3335 7.99996 1.3335C8.88401 1.3335 9.73186 1.68469 10.357 2.30981C10.9821 2.93493 11.3333 3.78277 11.3333 4.66683V6.66683"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </>
);
