import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import randomBytes from "randombytes";

export interface TransactionState {
  sliderTransactions: { transactionId: string; animationId: string }[];
  silderAccordionValue: string[];
  selectedTransactionIds: string[];
}

const initialState: TransactionState = {
  sliderTransactions: [],
  silderAccordionValue: ["INVOICE_AND_MEMO", "COMMENT"],
  selectedTransactionIds: [],
};

export const transactions = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    openSlider: (state, action: PayloadAction<string>) => {
      const isAlreadyOpen = state.sliderTransactions.find(
        ({ transactionId }) => transactionId === action.payload
      );

      if (isAlreadyOpen) {
        state.sliderTransactions = [
          ...state.sliderTransactions.filter(
            ({ transactionId }) => transactionId !== action.payload
          ),
          {
            transactionId: action.payload,
            animationId: randomBytes(10).toString("hex"),
          },
        ];
      } else {
        state.sliderTransactions = [
          ...state.sliderTransactions,
          {
            transactionId: action.payload,
            animationId: randomBytes(10).toString("hex"),
          },
        ];
      }
    },

    closeSlider: (state, action: PayloadAction<string>) => {
      state.sliderTransactions = state.sliderTransactions.filter(
        ({ transactionId }) => transactionId !== action.payload
      );
      state.silderAccordionValue = [];
    },

    setSilderAccordionValue: (
      state,
      action: PayloadAction<TransactionState["silderAccordionValue"]>
    ) => {
      state.silderAccordionValue = action.payload;
    },

    setSelectedTransactionIds: (
      state,
      action: PayloadAction<TransactionState["selectedTransactionIds"]>
    ) => {
      state.selectedTransactionIds = action.payload;
    },
  },
});

export const {
  openSlider,
  closeSlider,
  setSilderAccordionValue,
  setSelectedTransactionIds,
} = transactions.actions;

export default transactions.reducer;
