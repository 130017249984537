export const Forum = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_6503_4190)">
      <path
        d="M1.97875 10.985C1.91442 11.0034 1.84633 11.0043 1.78155 10.9875C1.71677 10.9708 1.65766 10.937 1.61034 10.8897C1.56303 10.8423 1.52923 10.7832 1.51247 10.7184C1.4957 10.6537 1.49658 10.5856 1.515 10.5212L2.04812 8.65437C1.53018 7.70414 1.37203 6.59907 1.60267 5.5417C1.8333 4.48433 2.43728 3.5455 3.30389 2.89726C4.1705 2.24903 5.2417 1.93483 6.32116 2.01223C7.40061 2.08964 8.41602 2.55348 9.18127 3.31873C9.94652 4.08398 10.4104 5.09938 10.4878 6.17884C10.5652 7.2583 10.251 8.3295 9.60273 9.19611C8.9545 10.0627 8.01567 10.6667 6.9583 10.8973C5.90093 11.128 4.79586 10.9698 3.84562 10.4519L1.97875 10.985Z"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.75375 10.9931C5.97038 11.6081 6.31797 12.1687 6.77249 12.6361C7.22701 13.1036 7.77762 13.4668 8.38627 13.7006C8.99491 13.9344 9.64706 14.0332 10.2977 13.9903C10.9482 13.9474 11.5817 13.7636 12.1544 13.4519L14.0213 13.985C14.0856 14.0034 14.1537 14.0043 14.2184 13.9875C14.2832 13.9708 14.3423 13.937 14.3897 13.8896C14.437 13.8423 14.4708 13.7832 14.4875 13.7184C14.5043 13.6537 14.5034 13.5856 14.485 13.5212L13.9519 11.6544C14.3156 10.9873 14.5045 10.2389 14.5009 9.47912C14.4974 8.71931 14.3015 7.97277 13.9315 7.30911C13.5616 6.64544 13.0296 6.08626 12.3852 5.68369C11.7408 5.28112 11.0049 5.04828 10.2463 5.00687"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6503_4190">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
