export const TicketUnresolved = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_55_7411)">
        <path
          d="M10 10L5.25 6.4375C5.17238 6.37928 5.10938 6.30379 5.06598 6.21701C5.02259 6.13022 5 6.03453 5 5.9375V3.125C5 2.95924 5.06585 2.80027 5.18306 2.68306C5.30027 2.56585 5.45924 2.5 5.625 2.5H14.375C14.5408 2.5 14.6997 2.56585 14.8169 2.68306C14.9342 2.80027 15 2.95924 15 3.125V5.90937C14.9997 6.00574 14.9771 6.10074 14.934 6.18693C14.8909 6.27313 14.8285 6.34819 14.7516 6.40625L10 10Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 10L5.25 13.5625C5.17238 13.6207 5.10938 13.6962 5.06598 13.783C5.02259 13.8698 5 13.9655 5 14.0625V16.875C5 17.0408 5.06585 17.1997 5.18306 17.3169C5.30027 17.4342 5.45924 17.5 5.625 17.5H14.375C14.5408 17.5 14.6997 17.4342 14.8169 17.3169C14.9342 17.1997 15 17.0408 15 16.875V14.0906C14.9999 13.994 14.9775 13.8987 14.9344 13.8122C14.8913 13.7257 14.8287 13.6504 14.7516 13.5922L10 10Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_55_7411">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
