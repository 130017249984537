export const Transfers = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.23235 8.60985V3.17306C9.23235 3.09151 9.23235 3.00693 9.25867 2.92538C9.28168 2.84402 9.33263 2.76947 9.40574 2.71021C9.47885 2.65094 9.57119 2.60934 9.67226 2.59011C9.7813 2.56897 10.2588 2.56897 10.3603 2.56897H12.9923C13.0938 2.56897 13.1991 2.56897 13.3006 2.59011C13.4019 2.6086 13.4947 2.64953 13.5685 2.70826C13.6422 2.767 13.694 2.84117 13.7179 2.92236C13.7443 3.00693 13.7443 3.09151 13.7443 3.17306V8.60985H9.23235Z"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.27878 13.1114V7.67464C2.27878 7.59309 2.27878 7.50852 2.3051 7.42697C2.32811 7.34561 2.37907 7.27106 2.45218 7.21179C2.52529 7.15253 2.61762 7.11092 2.71869 7.0917C2.82773 7.07056 3.30524 7.07056 3.40676 7.07056H6.03871C6.14023 7.07056 6.24551 7.07056 6.34703 7.0917C6.44831 7.11019 6.54111 7.15112 6.61488 7.20985C6.68866 7.26858 6.74045 7.34276 6.76438 7.42395C6.7907 7.50852 6.7907 7.59309 6.7907 7.67464V13.1114H2.27878Z"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.25 8.60986H14.7036"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.29643 13.1115H7.75001"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.45764 9.25226H5.13807"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.9288 6.78699L11.7379 6.78699"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.2268 4.69482L12.0359 4.69482"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.98877 11.3052L4.79785 11.3052"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.65735 4.69483L7.37836 2.97382L5.65735 1.25281"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.25904 5.52432C2.25904 4.84788 2.55271 4.19915 3.07545 3.72084C3.59819 3.24253 4.30718 2.97382 5.04644 2.97382L6.52757 2.97382"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.0659 11.3052L9.34488 13.0262L11.0659 14.7472"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.4642 10.4757C14.4642 11.1521 14.1705 11.8008 13.6478 12.2792C13.125 12.7575 12.4161 13.0262 11.6768 13.0262L10.1957 13.0262"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
