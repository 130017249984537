import React, {
  Dispatch,
  ReactNode,
  useContext,
  useMemo,
  useReducer,
} from "react";
import { Group } from "types/Models/group";

type CurrentGroupAction = {
  type: "SET_GROUP";
  payload: Partial<Group>;
};

const initialState: Group = {
  uuid: "",
  name: "",
  documents_group_id: "",
  created_at: "",
  private_channel_url: "",
  last_contact_date: "",
  next_contact_date: "",
  subscription_type: "",
  is_fiscal_customer: false,
  has_mailroom_subscription: false,
  new_bookkeeping_customer: false,
  group_channel_url: "",
  secondary_channel_url: "",
  mailbox_channel_url: [],
  entities: [],
  roles: [],
  team: [],
  platform_subscription: {
    entity_id: "",
    subscription_name: "Free plan",
    amount: 0,
    upgrade_level: 0,
    billing_cycle: "MONTHLY",
    started_on: null,
    stripe_subscription_id: "",
    subscription_description: "",
    subscription_uuid: "",
    uuid: "",
    subscription_status: "Active",
  },
  is_archived_user: false,
  unsorted_folder_id: "",
  onboarding_details: {
    text: "",
    button_text: "",
    link: "",
  },
  billing_email: "",
  due_amount: 0,
  invoices_count: 0,
  has_books_access_to_fca: false,
};

export type CurrentGroupContextT = Group & {
  dispatch: Dispatch<CurrentGroupAction>;
};

export const CurrentGroupContext = React.createContext<CurrentGroupContextT>(
  initialState as CurrentGroupContextT
);

export const CurrentGroupContextProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const reducer = (state: Group, action: CurrentGroupAction) => {
    switch (action.type) {
      case "SET_GROUP":
        return { ...state, ...action.payload };

      default:
        return { ...state };
    }
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  const contextValue = useMemo(
    () => ({ ...state, dispatch }),
    [JSON.stringify(state), dispatch]
  );

  return (
    <CurrentGroupContext.Provider value={contextValue}>
      {children}
    </CurrentGroupContext.Provider>
  );
};
