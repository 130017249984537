import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import React, { useState } from "react";
import { ArrowRight } from "components/icons/ArrowRight";
import classNames from "classnames";

type SelectAllProps = {
  onSelectAllChange: (value: boolean) => void;
  indeterminate?: boolean;
  checked: boolean;
  label: React.ReactNode;
};
export const SelectAll = ({
  onSelectAllChange,
  checked,
  indeterminate,
  label,
}: SelectAllProps) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="t-flex t-items-center t-gap-2 t-select-none">
      <div
        className={classNames("t-flex t-gap-1 t-items-center t-rounded-sm", {
          "t-bg-purple-10": open,
          "hover:t-bg-neutral-0": !open,
        })}
      >
        <Checkbox
          onChange={(e) => onSelectAllChange(e.target.checked)}
          name="selected_all_txns"
          indeterminate={indeterminate}
          checked={checked}
        />
        <Dropdown.Root open={open} onOpenChange={setOpen}>
          <Dropdown.Trigger className="t-group" asChild>
            <button className="all:unset t-transform t-transition t-duration-300 t-ease-in-out group-data-state-open:-t-rotate-90 t-rotate-90 t-flex-1 t-items-center group-data-state-open:!t-text-text-60 t-text-text-30">
              <ArrowRight color="currentColor" stroke="1.5" />
            </button>
          </Dropdown.Trigger>
          <Dropdown.Portal>
            <Dropdown.Content
              align="start"
              sideOffset={4}
              alignOffset={-20}
              className="!t-min-w-24"
            >
              <Dropdown.Item onSelect={() => onSelectAllChange(true)}>
                Select all
              </Dropdown.Item>
              <Dropdown.Item onSelect={() => onSelectAllChange(false)}>
                Clear all
              </Dropdown.Item>
            </Dropdown.Content>
          </Dropdown.Portal>
        </Dropdown.Root>
      </div>

      {label}
    </div>
  );
};
