export const CONNECT_BANK_ACCOUNT = "CONNECT_BANK_ACCOUNT";
export const RECURRING_JOURNAL_ENTRY = "RECURRING_JOURNAL_ENTRY";
export const RECURRING_INVOICE = "RECURRING_INVOICE";
export const INVOICE_REMINDER = "INVOICE_REMINDER";
export const CONNECT_REVENUE_SOURCE = "CONNECT_REVENUE_SOURCE";
export const PRODUCTS_AND_SERVICES = "PRODUCTS_AND_SERVICES";
export const REVENUE_METRICS = "REVENUE_METRICS";
export const CALL_AGENT = "CALL_AGENT";
export const FEDERAL_REGISTERATIONS = "FEDERAL_REGISTRATIONS";
export const FILINGS_PAGE = "FILINGS";
export const FILINGS_STATE_CHANGE = "FILINGS_STATE_CHANGE";
