import {
  Chart as ChartJS,
  ChartOptions,
  ChartData,
  registerables,
  TooltipItem,
} from "chart.js";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import { TopInsights } from "components/TopInsights/TopInsights";
import { YYYY_MM_DD } from "constants/date";
import dayjs from "dayjs";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useFilters } from "hooks/useFilter";
import { usePageTitle } from "hooks/usePageTitle";
import { Line } from "react-chartjs-2";
import { useGetCashBalanceQuery, useGetMetricsQuery } from "store/apis/metrics";
import { DateRangeValue, getDateRange } from "utils/getDateRange";
import "chartjs-plugin-annotation";
import { currency } from "utils/Currency";
import { Tag } from "components/DesignSystem/Tag/Tag";
import Modal from "components/DesignSystem/Modal/Modal";
import { Form, Formik } from "formik";
import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import Pencil from "components/icons/pencil";
import { Plugin } from "chart.js";
import { TooltipCallbacks } from "chart.js";
import { formatAmount } from "utils/foramtAmount";
import { Expand } from "components/icons/Expand";
import classNames from "classnames";
import { datePeriod } from "constants/bookkeeping";
import { Link } from "components/DesignSystem/Link/Link";
import { useConstructInternalLink } from "hooks/useConstructInternalLink";
import { useGetAllAccountQuery } from "store/apis/transactions";
import { EmptyCashBalanceMetrics } from "components/Illustrations/EmptyCashBalanceMetrics";
import { EmptyMetrics } from "components/EmptyMetrics/EmptyMetrics";

ChartJS.register(...registerables);

interface CorsairPluginChart extends ChartJS<"line", number[], string> {
  corsair?: {
    x: number;
    y: number;
    draw?: boolean;
  };
}

const corsairPlugin: Plugin<"line"> = {
  id: "corsair",
  afterInit: (chart: CorsairPluginChart, args, opts) => {
    chart.corsair = {
      x: 0,
      y: 0,
    };
  },
  afterEvent: (chart: CorsairPluginChart, args) => {
    const { inChartArea } = args;
    const { x, y } = args.event;

    chart.corsair = {
      x: x !== null ? x : 0,
      y: y !== null ? y : 0,
      draw: inChartArea,
    };
    chart.draw();
  },
  afterDraw: (chart, args, opts) => {
    const { ctx } = chart;
    const { bottom } = chart.chartArea;

    //@ts-ignore
    if (!chart?.corsair) return;

    //@ts-ignore
    const { x, draw } = chart?.corsair;

    if (!draw || !x) return;

    ctx.save();

    ctx.beginPath();
    ctx.lineWidth = 1;
    ctx.strokeStyle = "#BBA7FF";
    let closestY: number | null = null;
    chart.data.datasets.forEach((dataset, datasetIndex: number) => {
      const meta = chart.getDatasetMeta(datasetIndex);
      const dataPoint = meta.data.find((point) => point.x >= x);

      if (dataPoint) {
        const yValue = dataPoint.y;
        if (closestY === null || yValue < closestY) {
          closestY = yValue;
        }
      }
    });

    if (closestY !== null) {
      ctx.moveTo(x, bottom);
      ctx.lineTo(x, closestY + 5);
      ctx.stroke();
    }

    ctx.stroke();

    ctx.restore();
  },
};

const options: ChartOptions<"line"> = {
  responsive: true,
  maintainAspectRatio: false,

  interaction: {
    mode: "index",
    intersect: false,
  },
  elements: {
    line: {
      borderCapStyle: "round",
      borderJoinStyle: "round",
      tension: 0.6,
    },
  },
  scales: {
    x: {
      type: "time",
      time: {
        unit: "month",
        tooltipFormat: "MMM D, YYYY",
        displayFormats: {
          month: "MMM YYYY",
        },
      },
      ticks: {
        source: "auto",
        callback: function (value: string | number) {
          return dayjs(value).format("MMM YY");
        },
        align: "center",
        labelOffset: 15,
      },
      grid: {
        display: false,
      },
    },

    y: {
      beginAtZero: true,
      ticks: {
        callback: function (
          tickValue: string | number
        ): string | number | string[] | number[] | null | undefined {
          if (typeof tickValue === "number") {
            return formatAmount(tickValue);
          }
          return tickValue;
        },
      },
      grid: {
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      mode: "index",
      intersect: false,
      callbacks: {
        title: function (tooltipItems: TooltipItem<"line">[]) {
          return dayjs(tooltipItems[0].label).format("MMM D, YYYY");
        },
        label: function (context: TooltipItem<"line">) {
          return `Cash balance : ${currency({
            amount: context.raw as string,
          })}`;
        },
      } as TooltipCallbacks<"line">,

      padding: 10,
      boxPadding: 8,
      titleMarginBottom: 10,
      bodySpacing: 8,
    },
  },
};

const dataSetOptions = {
  fill: true,
  borderWidth: 1,
  borderColor: "#9A7AFF",
  backgroundColor: (context: any) => {
    const chart = context.chart;
    const { ctx, chartArea } = chart;

    if (!chartArea) {
      return null;
    }

    const gradient = ctx.createLinearGradient(
      0,
      chartArea.top,
      0,
      chartArea.bottom
    );
    gradient.addColorStop(0, "#DDD3FF");
    gradient.addColorStop(1, "#F7F4FF");

    return gradient;
  },
  tension: 0.6,
  pointStyle: "circle",
  pointRadius: 0,
  pointHoverRadius: 5,
  pointHoverBorderWidth: 2,
  pointHoverBorderColor: "#9A7AFF",
  pointHoverBackgroundColor: "#F7F4FF",
};

const CashBalanceLineChart = ({
  size = "small",
  canChangeDate,
}: {
  size?: "small" | "large";
  canChangeDate?: boolean;
}) => {
  const entityId = useCurrentEntityId();
  const { values, updateFilter } = useFilters({
    initialValue: {
      range: "last180days" as DateRangeValue,
    },
  });

  const defaultDateRange = getDateRange(values.range);

  const { data: cashBalance = [], isLoading } = useGetCashBalanceQuery(
    {
      entityId,
      start_date: dayjs(defaultDateRange.startDate).format(YYYY_MM_DD),
      end_date: dayjs(defaultDateRange.endDate).format(YYYY_MM_DD),
    },
    { skip: !entityId }
  );

  const labels = cashBalance.map((c) => dayjs(c.date).format(YYYY_MM_DD));
  const dataPoints = cashBalance.map((c) => c.balance);
  const cashBalanceAmount = cashBalance?.[cashBalance.length - 1]?.balance || 0;

  const data: ChartData<"line", number[] | { x: Date; y: number }[], string> = {
    labels: labels,
    datasets: [
      {
        data: dataPoints,
        ...dataSetOptions,
      },
    ],
  };

  if (isLoading) {
    return (
      <div className="t-bg-neutral-0 t-rounded-md t-my-2 t-h-11 t-animate-pulse-fast"></div>
    );
  }

  const ranges = [
    { label: "Last month", value: "lastmonth" as const },
    { label: "Last 180 days", value: "last180days" as const },
    { label: "Last 3 months", value: "last3months" as const },
    { label: "Last 6 months", value: "last6months" as const },
  ];

  const rangeValue = ranges.find((r) => r.value === values.range);

  return (
    <div
      className={classNames("t-flex t-flex-col t-h-full t-w-full", {
        "t-gap-2": size === "small",
        "t-gap-7": size === "large",
      })}
    >
      <div className="t-text-h5 t-flex t-w-full t-justify-between">
        <AmountSuperScript amount={cashBalanceAmount || 0} />
        {canChangeDate && (
          <Combobox
            styles={{
              menu: (base) => ({
                ...base,
                width: "200px",
              }),
            }}
            size="small"
            isClearable={false}
            menuPortalTarget={document.body}
            name="range"
            value={rangeValue || null}
            onChange={(v) => {
              if (!(v instanceof Array)) {
                updateFilter("range", v?.value as DateRangeValue);
              }
            }}
            options={ranges}
            isSearchable={false}
          />
        )}
      </div>
      <div
        className={classNames("t-flex t-w-full t-justify-center", {
          "t-h-60": size === "small",
          "t-h-[50vh]": size === "large",
        })}
      >
        <Line data={data} options={options} plugins={[corsairPlugin]} />
      </div>
    </div>
  );
};

export const Metrics = () => {
  usePageTitle("Home");
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();

  const ranges = [
    { label: "Last month", value: "lastmonth" as const },
    { label: "Last 180 days", value: "last180days" as const },
    { label: "Last 3 months", value: "last3months" as const },
    { label: "Last 6 months", value: "last6months" as const },
  ];

  const defaultDateRange = getDateRange("last3months");
  const { values, updateFilter } = useFilters({
    initialValue: {
      START_DATE: defaultDateRange.startDate,
      END_DATE: defaultDateRange.endDate,
      RANGE: "last3months" as (typeof ranges)[number]["value"],
    },
  });

  const { data: metrics, isFetching } = useGetMetricsQuery({
    groupId,
    burn_rate_end_date: dayjs(values.END_DATE).format("YYYY-MM-DD"),
    burn_rate_start_date: dayjs(values.START_DATE).format("YYYY-MM-DD"),
  });

  const { data: accounts } = useGetAllAccountQuery(
    {
      groupId: groupId,
      entityId: entityId,
      account_type: "ALL",
    },
    {
      skip: !groupId || !entityId,
    }
  );

  const runwayEnd = dayjs().add(metrics?.runway || 0, "month");

  const { link } = useConstructInternalLink();

  return (
    <div className="t-flex t-flex-col t-gap-6 t-w-full">
      <div className="t-grid t-grid-cols-2 t-gap-6 t-w-full">
        <div className="t-flex t-gap-3 t-border t-border-solid t-border-neutral-0 t-shadow-light-30 t-rounded-lg t-flex-col">
          <div className="t-w-full t-border t-border-solid t-border-neutral-0 t-border-t-0 t-border-l-0 t-border-r-0 t-p-5 t-text-subtitle t-flex t-justify-between">
            <p className="t-m-0">Cash Balance</p>

            <Modal.Root>
              <Modal.Trigger asChild>
                <Button
                  size="small"
                  customType="ghost_icon"
                  disabled={accounts?.length === 0}
                >
                  <span
                    className={classNames({
                      "t-text-text-30": accounts?.length !== 0,
                      "t-text-neutral-20": accounts?.length === 0,
                    })}
                  >
                    <Expand />
                  </span>
                </Button>
              </Modal.Trigger>
              <Modal.Content size="xxl">
                <Modal.Header>
                  <Modal.Title>Cash Balance</Modal.Title>
                  <Modal.Close />
                </Modal.Header>
                <Modal.Body>
                  <CashBalanceLineChart size="large" canChangeDate />
                </Modal.Body>
                <Modal.FooterButtonGroup>
                  <Modal.RawClose asChild>
                    <Button>Close</Button>
                  </Modal.RawClose>
                  <Link to={link("/books/data-sources")}>
                    <Button customType="primary">View more</Button>
                  </Link>
                </Modal.FooterButtonGroup>
              </Modal.Content>
            </Modal.Root>
          </div>
          <div className="t-flex t-flex-col t-justify-between t-items-center t-px-5 t-h-72 t-relative">
            {accounts?.length === 0 ? (
              <div className="t-absolute t-inset-0 t-bg-white t-bg-opacity-65">
                <EmptyMetrics
                  illustration={<EmptyCashBalanceMetrics />}
                  title="Cash balance unavailable"
                  subtitle={
                    <>
                      <Link to={link("/books/data-sources")}>
                        {" "}
                        Connect your bank accounts
                      </Link>{" "}
                      to see cash balance
                    </>
                  }
                />
              </div>
            ) : (
              <CashBalanceLineChart />
            )}
          </div>
        </div>
        <div className="t-flex t-gap-3 t-border t-border-solid t-border-neutral-0 t-shadow-light-30 t-rounded-lg t-flex-col">
          <div className="t-w-full t-border t-border-solid t-border-neutral-0 t-border-t-0 t-border-l-0 t-border-r-0 t-py-4 t-px-5 t-text-subtitle t-flex t-justify-between t-items-center">
            <div>Cash Metrics</div>
            <Formik
              initialValues={{
                range: "last3months" as (typeof ranges)[number]["value"],
              }}
              onSubmit={(values) => {
                const range = getDateRange(values.range as DateRangeValue);
                updateFilter("END_DATE", range.endDate);
                updateFilter("START_DATE", range.startDate);
                updateFilter("RANGE", values.range);
              }}
            >
              {({ submitForm, values }) => {
                const rangeValue = ranges.find((r) => r.value === values.range);

                return (
                  <Modal.Root>
                    <Modal.Trigger asChild>
                      <span>
                        <Button size="small" customType="icon">
                          <Pencil color="currentColor" size="16" />
                        </Button>
                      </span>
                    </Modal.Trigger>
                    <Modal.Content>
                      <Modal.Header>
                        <Modal.Title>Gross Burn Rate</Modal.Title>
                        <Modal.Close />
                      </Modal.Header>
                      <Modal.Body>
                        <Form className="t-m-0">
                          <Combobox
                            menuPortalTarget={document.body}
                            name="range"
                            value={rangeValue || null}
                            options={ranges}
                            withForm
                            label="Calculate burn rate based on:"
                          />
                        </Form>
                      </Modal.Body>

                      <Modal.FooterButtonGroup>
                        <Modal.RawClose asChild>
                          <Button>Cancel</Button>
                        </Modal.RawClose>
                        <Modal.RawClose asChild>
                          <Button onClick={submitForm} customType="primary">
                            Confirm
                          </Button>
                        </Modal.RawClose>
                      </Modal.FooterButtonGroup>
                    </Modal.Content>
                  </Modal.Root>
                );
              }}
            </Formik>
          </div>

          <div className="t-px-5 t-flex t-flex-col t-gap-4">
            <div className="t-flex t-flex-col t-flex-1">
              <div className="t-flex t-gap-2 t-h-8 t-items-center">
                <span className="t-text-subtext t-text-text-60">
                  Gross burn rate
                </span>
                <Tag tagType="blue" size="small" bordered rounded>
                  {ranges.find((r) => r.value === values.RANGE)?.label}
                </Tag>
              </div>

              <span className="t-text-h3">
                {isFetching ? (
                  <div className="t-bg-neutral-0 t-rounded-md t-my-2 t-h-11 t-animate-pulse-fast"></div>
                ) : (
                  <AmountSuperScript amount={metrics?.group_burn_rate || 0} />
                )}
              </span>

              <span className="t-pt-2 t-text-text-30 t-text-body">
                Cash spends in a month.
              </span>
            </div>

            <div className="t-border-b-[2px] t-border-0 t-border-solid t-border-neutral-0" />

            <div className="t-flex t-flex-col t-flex-1">
              <div className="t-flex t-gap-2 t-justify-between t-h-8 t-items-center">
                <span className="t-text-subtext t-text-text-60">
                  Runway runrate
                </span>
              </div>

              {isFetching ? (
                <div className="t-bg-neutral-0 t-rounded-md t-my-2 t-h-11 t-animate-pulse-fast"></div>
              ) : (
                <span className="t-text-h3">
                  {metrics?.group_burn_rate === 0 && metrics?.runway === 0 ? (
                    "N/A"
                  ) : (
                    <>
                      <span>{runwayEnd.format("MMM YYYY")}</span>
                      <span className="t-inline-block t-ml-2 t-text-subtitle">
                        ({metrics?.runway} months)
                      </span>
                    </>
                  )}
                </span>
              )}

              <span className="t-pt-2 t-text-text-30 t-text-body">
                Based on burn rate.
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="t-flex t-gap-4 t-border t-border-solid t-border-neutral-0 t-p-5 t-shadow-light-30 t-rounded-lg t-flex-col">
        <TopInsights />
      </div>
    </div>
  );
};
