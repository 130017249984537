export const PaperPlaneRightSmall = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_5109_13012)">
      <path
        d="M14.9999 8.49327C15.0004 8.67144 14.9532 8.84649 14.8633 9.00033C14.7735 9.15416 14.6441 9.28119 14.4887 9.36827L3.99366 15.3689C3.84306 15.4543 3.67303 15.4995 3.49991 15.5001C3.3404 15.4993 3.18342 15.4603 3.04206 15.3864C2.90069 15.3125 2.77906 15.2059 2.6873 15.0754C2.59555 14.9449 2.53633 14.7944 2.51461 14.6364C2.49288 14.4783 2.50927 14.3174 2.56241 14.167L4.24991 9.17014C4.2664 9.12129 4.2976 9.07873 4.33922 9.0483C4.38084 9.01786 4.43086 9.00104 4.48241 9.00014H8.99991C9.06845 9.00029 9.1363 8.98635 9.19922 8.95918C9.26215 8.932 9.31882 8.89218 9.36571 8.84219C9.4126 8.79219 9.44871 8.73309 9.4718 8.66855C9.49488 8.60401 9.50445 8.53541 9.49991 8.46702C9.48857 8.33845 9.42908 8.2189 9.33335 8.13233C9.23762 8.04575 9.11272 7.99854 8.98366 8.00014H4.48366C4.43136 8.00015 4.38037 7.98375 4.33787 7.95325C4.29538 7.92275 4.26352 7.8797 4.24679 7.83014L2.55929 2.83389C2.49212 2.64239 2.48481 2.43498 2.53833 2.23922C2.59184 2.04346 2.70366 1.86862 2.85891 1.73792C3.01416 1.60723 3.2055 1.52686 3.40752 1.50749C3.60953 1.48813 3.81266 1.53069 3.98991 1.62952L14.4899 7.62264C14.6445 7.70952 14.7731 7.83593 14.8628 7.98892C14.9524 8.1419 14.9997 8.31597 14.9999 8.49327Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_5109_13012">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
