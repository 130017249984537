import { ConditionalLink } from "components/conditionalLink";
import { DashboardLayout } from "components/DashboardLayout";
import { Button } from "components/DesignSystem/Button/Button";
import { Header } from "components/DesignSystem/Header/Header";
import { Search } from "components/DesignSystem/Search/Search";
import Tab from "components/DesignSystem/Tab/Tab";
import { ALL, COMPLETED, ONGOING } from "constants/task";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { usePageTitle } from "hooks/usePageTitle";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { parse, stringify } from "qs";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, useLocation, useRouteMatch } from "react-router-dom";
import {
  useGetTaskListForServiceTeamQuery,
  useGetTaskListForUserQuery,
} from "store/apis/taskList";
import { RootState } from "store/store";
import { RequestServiceActions } from "./RequestServiceActions";
import { ServiceActionModal } from "./ServiceListModal/ServiceActionModal";
import { ServicesTable } from "./ServicesTable";
import Async from "components/DesignSystem/AsyncComponents/Async";
import TemplateMenu from "components/tasks/modal/AllTemplatesModal";
import { RestrictedRoute } from "RestrictedRoute";
import { BOOKS } from "constants/taskCategoryType";

export const Services = () => {
  usePageTitle("Bookkeeping Services");
  const { update } = useUpdateQuery();
  const query = useQuery();
  const serviceType = query.get("services");
  const search = query.get("search");
  const rRef = useRef(null);
  const { uuid: groupId } = useCurrentGroupContext();
  const { isCpa, isCustomer, isForeignCA } = useRoleBasedView();
  const {
    open: openServiceActionModal,
    close: closeServiceActionModal,
    isOpen: showServiceActionModal,
  } = useModal();
  const dispatch = useDispatch();
  const { ongoing_count, count, completed_count } = useSelector(
    (state: RootState) => state.serviceList.taskCounts
  );

  const location = useLocation();
  const parsedSearch = parse(location.search, { ignoreQueryPrefix: true });
  const queryData = stringify(
    {
      entity: parsedSearch.entity,
      company: parsedSearch.company,
    },
    { skipNulls: true, addQueryPrefix: true }
  );
  const {
    data: userTaskData,
    isLoading: isUserLoading,
    isSuccess: isUserSuccess,
  } = useGetTaskListForUserQuery(
    {
      taskCategoryType: BOOKS,
    },
    { skip: isCpa || isForeignCA, refetchOnMountOrArgChange: true }
  );

  const {
    data: cpaTaskList,
    isLoading: isAdminLoading,
    isSuccess: isAdminSuccess,
  } = useGetTaskListForServiceTeamQuery(
    {
      groupId,
      taskCategoryType: BOOKS,
    },
    { skip: isCustomer || !groupId, refetchOnMountOrArgChange: true }
  );

  const { count: totalCount } = (userTaskData || cpaTaskList || {}) as {
    count?: number;
  };
  const noServiceRequested = totalCount === 0;
  const userData = userTaskData || cpaTaskList || {};

  const tabs = [
    { name: "All", value: "ALL", taskCount: count },
    { name: "Ongoing", value: "ONGOING", taskCount: ongoing_count },
    {
      name: "Completed",
      value: "COMPLETED",
      taskCount: completed_count,
    },
  ];

  const handleTabChange = (value: string) => {
    update({ query: "services", value: value || null });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    update({ query: "search", value: value || null });
  };
  const { path } = useRouteMatch();

  return (
    <>
      <Async.Root
        {...{
          isEmpty: noServiceRequested,
          isLoading: isUserLoading || isAdminLoading,
          isSuccess: isAdminSuccess || isUserSuccess,
        }}
      >
        <Async.Empty>
          <DashboardLayout ref={rRef} header={<Header v2 title="Services" />}>
            <RequestServiceActions
              openServiceActionModal={openServiceActionModal}
            />
            {showServiceActionModal && (
              <ServiceActionModal
                isOpen={showServiceActionModal}
                close={closeServiceActionModal}
              />
            )}
          </DashboardLayout>
        </Async.Empty>
        <Async.Success>
          <Tab.Root
            asChild
            defaultValue="ALL"
            onValueChange={handleTabChange}
            value={serviceType || "ALL"}
          >
            <DashboardLayout
              ref={rRef}
              header={
                <Header
                  v2
                  title="Services"
                  right={
                    <ConditionalLink to={`/books/services/addtask${queryData}`}>
                      <Button size="small" customType="primary">
                        Request a service
                      </Button>
                    </ConditionalLink>
                  }
                  bottom={
                    <Tab.List>
                      {tabs.map(({ name, value, taskCount }) => (
                        <Tab.Trigger
                          value={value}
                          key={value}
                          suffix={taskCount?.toString()}
                          customType="primary"
                        >
                          {name}
                        </Tab.Trigger>
                      ))}
                    </Tab.List>
                  }
                />
              }
            >
              <div className="t-w-1/2">
                <Search
                  placeholder="Search..."
                  onChange={handleChange}
                  value={search || ""}
                  block
                />
              </div>
              <div>
                <Tab.Content value={ALL} className="t-h-full">
                  <ServicesTable
                    serviceType={"services"}
                    taskCategoryType={BOOKS}
                    parentRoute="/books/services"
                    addEntityInUrl
                  />
                </Tab.Content>
                <Tab.Content value={ONGOING} className="t-h-full">
                  <ServicesTable
                    serviceType={"services"}
                    taskCategoryType={BOOKS}
                    parentRoute="/books/services"
                    addEntityInUrl
                  />
                </Tab.Content>
                <Tab.Content value={COMPLETED} className="t-h-full">
                  <ServicesTable
                    serviceType={"services"}
                    taskCategoryType={BOOKS}
                    parentRoute="/books/services"
                    addEntityInUrl
                  />
                </Tab.Content>
              </div>
            </DashboardLayout>
          </Tab.Root>
        </Async.Success>
      </Async.Root>
    </>
  );
};
