import React from "react";

export const RuleCategorised = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
    >
      <circle cx="16.8202" cy="16.1459" r="16.1459" fill="#BBA7FF" />
      <circle cx="10.4145" cy="11.0849" r="3.01265" fill="white" />
      <rect
        x="9.0166"
        y="17.7598"
        width="6.02531"
        height="6.02531"
        rx="0.932488"
        fill="white"
      />
      <path
        d="M15.4746 10.333C14.9396 10.333 14.5059 10.7667 14.5059 11.3018C14.5059 11.8368 14.9396 12.2705 15.4746 12.2705V10.333ZM15.4746 12.2705H23.9571V10.333H15.4746V12.2705ZM24.1934 12.5068V19.7842H26.1309V12.5068H24.1934ZM23.9571 20.0205H17.7016V21.958H23.9571V20.0205ZM24.1934 19.7842C24.1934 19.9147 24.0876 20.0205 23.9571 20.0205V21.958C25.1576 21.958 26.1309 20.9848 26.1309 19.7842H24.1934ZM23.9571 12.2705C24.0876 12.2705 24.1934 12.3763 24.1934 12.5068H26.1309C26.1309 11.3063 25.1576 10.333 23.9571 10.333V12.2705Z"
        fill="white"
      />
      <path
        d="M20.3185 17.7598L17.9415 20.1368C17.4709 20.6074 17.4709 21.3704 17.9415 21.841L20.3185 24.2181"
        stroke="white"
        strokeWidth="1.61459"
        strokeLinecap="round"
      />
    </svg>
  );
};
