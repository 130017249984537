import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import Modal from "components/DesignSystem/Modal/Modal";
import { Form, Formik } from "formik";
import { mergeCategoriesSchema } from "formValidations/mergeCategoriesSchema";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useToast } from "hooks/useToast";
import { useMergeCategoriesMutation } from "store/apis/chartOfAccounts";
import { TxnCategories } from "types/Models/books";
import { BackendError } from "types/utils/error";

export const MergeCategories = ({
  categories,
  onSuccess,
}: {
  categories: TxnCategories[];
  onSuccess: () => void;
}) => {
  const entityId = useCurrentEntityId();
  const [mergeCategories, { isLoading }] = useMergeCategoriesMutation();
  const { successToast, alertToast } = useToast();

  const onSubmit = async (values: {
    mergeCategories: string[];
    primary_category: string;
  }) => {
    try {
      await mergeCategories({
        entityId,
        payload: {
          child_category_ids: values.mergeCategories,
          primary_category_id: values.primary_category,
        },
      }).unwrap();
      successToast({ message: "Categories merged successfully" });
      onSuccess();
    } catch (error: unknown) {
      alertToast(
        {
          message: (error as BackendError).data?.error?.message,
        },
        error as {}
      );
    }
  };

  return (
    <Formik
      initialValues={{
        mergeCategories: categories.map((category) => category.uuid),
        primary_category: "",
      }}
      onSubmit={onSubmit}
      validationSchema={mergeCategoriesSchema}
    >
      {({ values, submitForm }) => {
        const primaryCategory = categories.find(
          (category) => category.uuid === values.primary_category
        );
        const mergeCategories = categories
          .filter((category) => {
            return values.mergeCategories.includes(category.uuid);
          })
          .map((category) => ({
            value: category.uuid,
            label: category.name,
          }));

        return (
          <>
            <Modal.Header>
              <Modal.Title>Merge Categories</Modal.Title>
              <Modal.Close />
            </Modal.Header>
            <Modal.Body>
              <Form className="t-flex t-gap-4 t-flex-col">
                <Combobox
                  menuPortalTarget={document.body}
                  options={categories.map((category) => ({
                    value: category.uuid,
                    label: category.name,
                  }))}
                  isMulti
                  value={mergeCategories.length > 0 ? mergeCategories : null}
                  name="mergeCategories"
                  label="Categories to merge"
                  withForm
                />
                <Combobox
                  withForm
                  menuPortalTarget={document.body}
                  options={categories.map((category) => ({
                    value: category.uuid,
                    label: category.name,
                  }))}
                  value={
                    primaryCategory
                      ? {
                          value: primaryCategory.uuid,
                          label: primaryCategory.name,
                        }
                      : null
                  }
                  name="primary_category"
                  label="Select primary category to merge into"
                />
              </Form>
            </Modal.Body>
            <Modal.FooterButtonGroup>
              <Modal.RawClose asChild>
                <Button>Cancel</Button>
              </Modal.RawClose>
              <Button
                onClick={submitForm}
                customType="primary"
                isLoading={isLoading}
                disabled={isLoading}
              >
                Merge
              </Button>
            </Modal.FooterButtonGroup>
          </>
        );
      }}
    </Formik>
  );
};
