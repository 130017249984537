export const EmptyCategory = () => (
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="21" cy="21" r="20.5" fill="#FAFAFB" stroke="#F3F3F5" />
    <rect x="12" y="9" width="17" height="23.8" rx="2.26667" fill="#E7E6EB" />
    <rect
      x="14.2667"
      y="11.2667"
      width="12.4667"
      height="7.93333"
      rx="1.13333"
      fill="#CFCDD6"
    />
    <circle cx="15.4001" cy="22.6" r="1.13333" fill="#B8B5C2" />
    <circle cx="15.4001" cy="26" r="1.13333" fill="#B8B5C2" />
    <circle cx="15.4001" cy="29.4" r="1.13333" fill="#B8B5C2" />
    <circle cx="18.8" cy="22.6" r="1.13333" fill="#B8B5C2" />
    <circle cx="18.8" cy="26" r="1.13333" fill="#B8B5C2" />
    <circle cx="18.8" cy="29.4" r="1.13333" fill="#B8B5C2" />
    <circle cx="22.2" cy="22.6" r="1.13333" fill="#B8B5C2" />
    <circle cx="22.2" cy="26" r="1.13333" fill="#B8B5C2" />
    <circle cx="22.2" cy="29.4" r="1.13333" fill="#B8B5C2" />
    <circle cx="25.6" cy="22.6" r="1.13333" fill="#B8B5C2" />
    <circle cx="25.6" cy="26" r="1.13333" fill="#B8B5C2" />
    <circle cx="25.6" cy="29.4" r="1.13333" fill="#B8B5C2" />
  </svg>
);
