import { Loader } from "components/DesignSystem/Loader/Loader";
import BillingSubscription from "components/billing/BillingSubscription";
import {
  PRODUCT_BOOKS,
  PRODUCT_INTRAGROUP,
  PRODUCT_MAILROOM,
  PRODUCT_SALESTAX,
  PRODUCT_TAX,
} from "constants/billing";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroup } from "hooks/useCurrentGroup";
import { useQuery } from "hooks/useQuery";
import { useGetAllProductsQuery } from "store/apis/products";
import { BooksProduct } from "./BooksProduct";
import { IntragroupProduct } from "./IntragroupProduct";
import { MailroomProduct } from "./MailroomProduct";
import { SalesTaxProduct } from "./SalesTaxProduct";
import { useGetSubscriptionsToShow } from "hooks/useGetSubscriptionsToShow";
import { useGetSubscriptionsQuery } from "store/apis/subscriptions";

dayjs.extend(advancedFormat);

export const ProductBody = () => {
  const group = useCurrentGroup();
  const groupId = group.uuid;
  const entityId = useCurrentEntityId();
  const query = useQuery();
  const search = query.get("search") || "";
  const isBooksActive = query.get(PRODUCT_BOOKS);
  const isTaxActive = query.get(PRODUCT_TAX);
  const isMailroomActive = query.get(PRODUCT_MAILROOM);
  const isSalesTaxActive = query.get(PRODUCT_SALESTAX);
  const isIntragroupActive = query.get(PRODUCT_INTRAGROUP);
  let selectedProduct = [];
  if (isBooksActive) selectedProduct.push("BOOKS");
  if (isTaxActive) selectedProduct.push("TAX");
  if (isMailroomActive) selectedProduct.push("MAILROOM");
  if (isSalesTaxActive) selectedProduct.push("SALES_TAX");
  if (isIntragroupActive) selectedProduct.push("INTRA_GROUP");
  const productType = selectedProduct.join(",");
  const { data: allSubscriptions = [] } = useGetSubscriptionsQuery(
    {
      groupId,
      entityId,
    },
    { skip: !groupId || !entityId }
  );

  const { allAddOnSubscriptions } = useGetSubscriptionsToShow({
    allSubscriptions,
  });

  const {
    data: products,
    isLoading,
    isSuccess,
  } = useGetAllProductsQuery(
    {
      groupId: groupId!,
      entityId: entityId,
      product_type: productType || null,
      search: search || null,
    },
    { skip: !groupId || !entityId }
  );

  if (isLoading || !isSuccess) {
    return (
      <div className="t-h-64 t-w-full t-flex t-justify-center t-items-center">
        <Loader />
      </div>
    );
  }

  if (!products) {
    return null;
  }

  let updatedProducts = JSON.parse(JSON.stringify(products));

  Object.keys(updatedProducts).forEach((productGroupKey) => {
    let subscriptions = updatedProducts[productGroupKey];

    Object.keys(subscriptions).forEach((subscriptionKey) => {
      let subscriptionList = subscriptions[subscriptionKey];

      subscriptionList?.forEach?.(
        (firstSub: {
          subscription_type: string;
          uuid: string;
          metadata: { ra_state: string };
        }) => {
          allAddOnSubscriptions.forEach((secondSub) => {
            if (firstSub.uuid === secondSub.uuid) {
              Object.assign(firstSub, secondSub);
            } else if (
              firstSub.subscription_type === "REGISTERED_AGENT" &&
              firstSub.metadata?.ra_state === "Others" &&
              secondSub.subscription_type === "REGISTERED_AGENT"
            ) {
              Object.assign(firstSub, secondSub);
            }
          });
        }
      );
    });
  });

  return (
    <div className="t-pb-16 t-relative t-space-y-10">
      {"BOOKS" in products && (
        <BooksProduct booksProduct={updatedProducts.BOOKS} />
      )}
      {"TAX" in products && (
        <BillingSubscription taxProduct={updatedProducts.TAX} />
      )}
      {"MAILROOM" in products && (
        <MailroomProduct mailroomProduct={updatedProducts.MAILROOM} />
      )}
      {"SALES_TAX" in products && (
        <SalesTaxProduct salesTaxProduct={updatedProducts.SALES_TAX} />
      )}
      {"INTRA_GROUP" in products && (
        <IntragroupProduct intragroupProduct={updatedProducts.INTRA_GROUP} />
      )}
    </div>
  );
};
