import { captureException } from "@sentry/react";
import { ToastSizeType } from "components/DesignSystem/Toast/Toast";
import randomBytes from "randombytes";
import { ReactNode, useCallback } from "react";
import { removeAllToasts, removeToast, showToast } from "store/slices/toast";
import { useAppSelector } from "./useAppSelector";
import { useAppDispatch } from "./useAppDispatch";

type ToastProps = {
  message?: string;
  title?: string | ReactNode;
  size?: ToastSizeType;
  id?: string;
};

export const useToast = () => {
  const dispatch = useAppDispatch();
  const { toast } = useAppSelector((state) => state.toast);

  const dismissToast = useCallback((id: string) => {
    dispatch(removeToast(id));
  }, []);

  const dissmissAllToasts = useCallback(() => {
    dispatch(removeAllToasts());
  }, []);

  const alertToast = useCallback(
    (
      {
        message = "Please try again",
        title = "Error",
        size = "regular",
        id = randomBytes(10).toString("hex"),
      }: ToastProps,
      error?: object
    ) => {
      if (error) {
        captureException(new Error(JSON.stringify(error)));
      }

      dispatch(
        showToast({
          message,
          title,
          size,
          type: "error",
          id: id,
        })
      );

      return {
        message,
        title,
        size,
        type: "error",
        id: id,
      };
    },
    []
  );

  const successToast = useCallback(
    ({
      message,
      title = "Successful",
      size = "regular",
      id = randomBytes(10).toString("hex"),
    }: ToastProps) => {
      dispatch(
        showToast({
          message,
          title,
          size,
          type: "success",
          id: id,
        })
      );

      return {
        message,
        title,
        size,
        type: "success",
        id: id,
      };
    },
    []
  );

  const infoToast = useCallback(
    ({
      message,
      title,
      size = "regular",
      id = randomBytes(10).toString("hex"),
    }: ToastProps) => {
      dispatch(
        showToast({
          message,
          title,
          size,
          type: "default",
          id: id,
        })
      );

      return {
        message,
        title,
        size,
        type: "default",
        id: id,
      };
    },
    []
  );

  return {
    activeToasts: toast,
    alertToast,
    successToast,
    infoToast,
    dismissToast,
    dissmissAllToasts,
  };
};
