import classNames from "classnames";
import ConditionalToolTip from "components/design/conditionalToolTip";
import { ArrowRight } from "components/icons/ArrowRight";
import {
  Fragment,
  HTMLAttributes,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { TxnCategories } from "types/Models/books";
import { MultilevelAccordionContext } from "./MultilevelAccordionContext";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import dayjs from "dayjs";
import { DD_MMM_YYYY } from "constants/date";
import { AnimatePresence, motion } from "framer-motion";
import { AccordionAnimation } from "components/AccordionAnimation";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { stopPropagation } from "utils/stopPropagation";

type RootTypes = {
  /**
   * Maximum indent level, default 4
   */
  maxIndent?: number;
  children: ReactNode;
} & HTMLAttributes<HTMLDivElement>;

type ItemType = {
  data?: TxnCategories[];
  level?: number;
  defaultOpenUuid?: string;
  action?: (category: TxnCategories, level: number) => ReactNode;
  onCheck?: (category: TxnCategories, isChecked: boolean) => void;
  checkedCategories: TxnCategories[];
  widthType?: "max" | "full";
  openCategories?: string[];
  refFun?: (node: HTMLButtonElement | null, category: TxnCategories) => void;
  selected?: string | null;
};

const Root = ({ maxIndent = 4, children, ...props }: RootTypes) => {
  return (
    <MultilevelAccordionContext.Provider value={{ maxIndent }}>
      <div {...props}>{children}</div>
    </MultilevelAccordionContext.Provider>
  );
};

const Header = ({
  children,
  widthType = "full",
  ...props
}: { children: ReactNode; widthType: "full" | "max" } & React.DetailedHTMLProps<
  HTMLAttributes<HTMLElement>,
  HTMLElement
>) => {
  return (
    <header
      {...props}
      className={classNames(
        "t-py-3 t-text-text-30 t-text-subtext-sm t-border t-border-b t-border-solid t-border-t-0 t-border-x-0 t-border-neutral-10 t-rounded-t-lg t-flex t-justify-between t-gap-4 t-items-center t-sticky t-top-0 t-bg-white t-z-table-head",
        {
          "t-w-max": widthType === "max",
          "t-w-full": widthType === "full",
        }
      )}
    >
      {children}
    </header>
  );
};

export const Trigger = ({
  data,
  level = 0,
  action,
  defaultOpenUuid,
  openCategories,
  onCheck,
  checkedCategories,
  widthType = "full",
  refFun,
  selected,
}: ItemType) => {
  const { maxIndent } = useContext(MultilevelAccordionContext);

  let nextLevel = level;
  if (nextLevel <= maxIndent) {
    nextLevel = nextLevel + 1;
  }

  const [activeAccordion, setActiveAccordion] = useState<Set<string>>(
    new Set(defaultOpenUuid ? [...defaultOpenUuid] : [])
  );

  useEffect(() => {
    setActiveAccordion((open) => new Set([...open, ...(openCategories || [])]));
  }, [JSON.stringify(openCategories)]);

  const toggleAccordion = (uuid: string, types: any) => {
    if (!types) {
      return;
    } else if (activeAccordion.has(uuid)) {
      setActiveAccordion((prev) => {
        const newList = new Set(Array.from(prev));
        newList.delete(uuid);
        return newList;
      });
    } else {
      setActiveAccordion((prev) => {
        const newList = new Set(Array.from(prev));
        newList.add(uuid);
        return newList;
      });
    }
  };

  if (!data) {
    return null;
  }

  const checkedCategoriesUuids = checkedCategories.map((c) => c.uuid);

  return (
    <Fragment>
      {data?.map((category) => {
        const {
          uuid,
          types,
          name,
          description,
          identifier,
          opening_balance,
          start_date,
          is_standard_category,
          is_system_category,
        } = category;

        return (
          <div
            className={classNames({
              "t-border t-border-b t-border-solid t-border-t-0 t-border-x-0 t-border-neutral-0":
                nextLevel === 1,
              "t-w-max": widthType === "max",
              "t-w-full": widthType === "full",
              "animate-transaction-row": selected === category.uuid,
            })}
            key={uuid}
          >
            <ConditionalToolTip condition={description}>
              <button
                className="all:unset t-flex t-justify-between t-w-full hover:t-bg-surface-lighter-grey t-py-3 t-pr-3 t-gap-4 t-items-center t-pl-1"
                aria-label={name}
                onClick={() => toggleAccordion(uuid, types)}
                ref={(ref) => refFun?.(ref, category)}
              >
                <div className="t-flex t-items-center t-justify-between t-w-full t-max-w-full t-gap-2">
                  <div
                    className="t-flex t-items-center t-w-80"
                    style={{
                      paddingLeft: types ? 8 * nextLevel : 9 * nextLevel,
                    }}
                  >
                    <div
                      className={classNames({
                        "t-block": Boolean(types),
                        "t-invisible": !Boolean(types),
                      })}
                    >
                      <div
                        className={classNames(
                          "t-transform t-transition t-duration-300 t-ease-in-out",
                          { "t-rotate-90": activeAccordion.has(uuid) }
                        )}
                      >
                        <ArrowRight stroke="1.5" color="currentColor" />
                      </div>
                    </div>
                    <div className="t-flex t-items-center t-gap-1">
                      {nextLevel > 1 && (
                        <div className="t-mr-1">
                          <ConditionalToolTip
                            condition={
                              (is_system_category || !is_standard_category) &&
                              "Categories used by system cannot be moved or merged"
                            }
                          >
                            <span>
                              <Checkbox
                                aria-label={name}
                                disabled={
                                  is_system_category || !is_standard_category
                                }
                                checked={checkedCategoriesUuids.includes(uuid)}
                                onChange={(e) => {
                                  onCheck?.(category, e.target.checked);
                                }}
                                onClick={stopPropagation}
                              />
                            </span>
                          </ConditionalToolTip>
                        </div>
                      )}
                      <div className="t-min-w-32 t-truncate t-max-w-60 t-text-body">
                        {name}
                      </div>
                    </div>
                  </div>
                  {nextLevel > 1 ? (
                    <>
                      <div className="t-text-body t-w-28">{identifier}</div>

                      <div className="t-text-body t-w-36 t-flex t-justify-end">
                        {opening_balance && (
                          <AmountSuperScript amount={opening_balance} />
                        )}
                      </div>

                      <div className="t-text-body t-w-44 t-flex t-justify-center">
                        {start_date && (
                          <>{dayjs(start_date).format(DD_MMM_YYYY)}</>
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="t-w-[444px]" />
                  )}
                </div>
                <div className="t-flex t-w-28 t-justify-between t-gap-1 t-h-full t-items-center">
                  <div className="t-text-body t-items-center t-line-clamp-1">
                    {description || "-"}
                  </div>
                </div>
                <div className="t-sticky t-right-0">
                  {action?.(category, level)}
                </div>
              </button>
            </ConditionalToolTip>
            <AnimatePresence>
              {activeAccordion.has(uuid) && types && (
                <AccordionAnimation>
                  <div
                    className={classNames({
                      "t-text-text-100": nextLevel === 0,
                      "t-text-text-60": nextLevel === 1,
                      "t-text-text-30": nextLevel >= 2,
                    })}
                  >
                    <Trigger
                      widthType={widthType}
                      checkedCategories={checkedCategories}
                      onCheck={onCheck}
                      data={types}
                      level={nextLevel}
                      action={action}
                      defaultOpenUuid={defaultOpenUuid}
                      openCategories={openCategories}
                      refFun={refFun}
                      selected={selected}
                    />
                  </div>
                </AccordionAnimation>
              )}
            </AnimatePresence>
          </div>
        );
      })}
    </Fragment>
  );
};

const ChartOfAccountsAccordion = {
  Root,
  Trigger,
  Header,
};

export default ChartOfAccountsAccordion;
