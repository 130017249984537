import classNames from "classnames";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import CopyCode from "components/design/copyCode";
import Loader from "components/design/loader";
import { Avatar } from "components/DesignSystem/AvatarGroup/Avatar";
import { Button } from "components/DesignSystem/Button/Button";
import { InfoItem } from "components/DesignSystem/InfoItem/InfoItem";
import Slider from "components/DesignSystem/Slider/Slider";
import { SliderAccordion } from "components/DesignSystem/SliderAccordion/SliderAccordion";
import { FileInput } from "components/FileInput/FileInput";
import { CheckCircleFilled } from "components/icons/CheckCircleFilled";
import { SmallBellIcon } from "components/icons/LeftNav/SmallBellIcon";
import { ListPlus } from "components/icons/ListPlus";
import { LoadingIcon } from "components/icons/LoadingIcon";
import { OutlinedPaperPlaneRight } from "components/icons/OutlinedPaperPlaneRight";
import { PencilWithPaper } from "components/icons/PencilWithPaper";
import { TransactionSlider } from "components/Transaction/Slider/TransactionSlider";
import { DD_MMM_YYYY } from "constants/date";
import { SENT } from "constants/invoiceStatuses";
import dayjs from "dayjs";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useDocPreview } from "hooks/useDocPreview";
import { usePaginatedQuery } from "hooks/usePaginatedQuery";
import { MouseEvent } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory } from "react-router-dom";
import CopyPurple from "static/images/CopyPurple.svg";
import {
  AuditLog,
  useLazyGetByObjectIdAuditLogsQuery,
} from "store/apis/auditlogs";
import { useGetEntityInvoiceQuery } from "store/apis/invoices";
import { openSlider } from "store/slices/transactions";
import { formatDate, formatTime } from "utils/formatDate";

const InvoiceAuditIcon: { [key: string]: JSX.Element } = {
  "Customer Invoice Created": <ListPlus color="currentColor" />,
  "Customer Invoice Updated": <PencilWithPaper color="currentColor" />,
  "Customer Invoice Sent": (
    <div className="-t-rotate-45">
      <OutlinedPaperPlaneRight size="20" color="currentColor" />
    </div>
  ),
  "Reminder for the invoice sent to Customer": (
    <SmallBellIcon color="currentColor" />
  ),
  "Customer Invoice Paid": <CheckCircleFilled color="currentColor" />,
};

type StepProps = {
  isLastStep?: boolean;
  name: string | null;
  eventDate: string;
};

export const InvoiceAudit = ({ name, eventDate, isLastStep }: StepProps) => {
  return (
    <div
      className={classNames("t-h-max t-pl-2.5 t-relative", {
        "t-border t-border-solid t-border-neutral-10 t-border-t-0 t-border-r-0 t-border-b-0":
          !isLastStep,
      })}
    >
      <div className="t-absolute -t-ml-5 t-bg-surface t-h-5 t-w-5 t-text-neutral-20">
        {name ? InvoiceAuditIcon[name] : InvoiceAuditIcon["Invoice created"]}
      </div>
      <div className="t-ml-3 t-space-y-1">
        {name && (
          <div className="t-text-subtext-sm t-text-text-60">
            {name.replace("Customer ", "")}
          </div>
        )}
        <div
          className={classNames("t-text-body-sm t-text-text-30", {
            "!t-mb-5": !isLastStep,
          })}
        >
          {formatDate(eventDate)} at {formatTime(eventDate)}
        </div>
      </div>
    </div>
  );
};

export const InvoiceSlider = ({
  invoiceId,
  redirectLink,
}: {
  invoiceId: string;
  redirectLink: string;
}) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const history = useHistory();
  const openPreview = useDocPreview();
  const dispatch = useAppDispatch();

  const { data: invoice, isLoading } = useGetEntityInvoiceQuery(
    {
      groupId: groupId,
      entityId: entityId,
      invoiceId,
    },
    { skip: !groupId || !entityId }
  );

  const isStripeInvoice = invoice?.source === "STRIPE";
  const { customer, associated_txns, paid_amount } = invoice || {};

  const {
    data: InvoiceAuditLogs,

    loadNext,
    pageNum,
  } = usePaginatedQuery<{
    logs: AuditLog[];
  }>(useLazyGetByObjectIdAuditLogsQuery, "logs", {
    entityId: entityId,
    objectId: invoiceId,
  });

  const { logs = [], total_pages = 1 } = InvoiceAuditLogs || {};

  const onEditInvoice = (e: MouseEvent<HTMLButtonElement>) => {
    if (invoice?.status === SENT) {
      history.push(`${redirectLink}&editflow=true`);
    } else {
      history.push(`${redirectLink}`);
    }
    e.stopPropagation();
  };

  if (isLoading) {
    return (
      <div className="t-w-full t-h-full t-justify-center t-align-middle">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <Slider.Header>
        <Slider.Title>{invoice?.title || invoice?.invoice_number}</Slider.Title>
        <Slider.Close />
      </Slider.Header>
      <Slider.Body className="t-h-[85%] t-flex t-flex-col t-gap-4 t-overflow-auto">
        <SliderAccordion.Root
          type="multiple"
          defaultValue={[
            "INVOICE_DETAILS",
            "CUSTOMER_DETAILS",
            "TIMELINE",
            "ATTACHMENT",
            "LINKED_TXN",
          ]}
          className="t-flex t-flex-col t-gap-4"
        >
          <SliderAccordion.Item value="INVOICE_DETAILS">
            <SliderAccordion.Trigger disabled>
              Invoice Details
            </SliderAccordion.Trigger>
            <SliderAccordion.Content
              forceMount
              className="t-flex t-flex-col t-gap-6"
            >
              <div className="t-grid t-grid-cols-2">
                <SliderAccordion.ItemGrid>
                  <div className="t-col-span-2">
                    <InfoItem label="To">
                      {invoice?.customer?.company_name || "-"}
                    </InfoItem>
                  </div>
                </SliderAccordion.ItemGrid>
                <SliderAccordion.ItemGrid>
                  <div className="t-col-span-2">
                    <InfoItem label="Due Date">
                      {formatDate(invoice?.due_date) || "-"}
                    </InfoItem>
                  </div>
                </SliderAccordion.ItemGrid>
              </div>
              <SliderAccordion.ItemGrid>
                <InfoItem label="Amount Paid">
                  <AmountSuperScript amount={Number(paid_amount) || 0} />
                </InfoItem>
              </SliderAccordion.ItemGrid>
            </SliderAccordion.Content>
          </SliderAccordion.Item>
          <SliderAccordion.Item value="CUSTOMER_DETAILS">
            <SliderAccordion.Trigger disabled>
              Customer Details
            </SliderAccordion.Trigger>
            <SliderAccordion.Content
              forceMount
              className="t-flex t-flex-col t-gap-6"
            >
              <div className="t-grid t-grid-cols-2">
                <SliderAccordion.ItemGrid>
                  <InfoItem label="Name">
                    {customer?.company_name || "-"}
                  </InfoItem>
                </SliderAccordion.ItemGrid>
                <SliderAccordion.ItemGrid>
                  <InfoItem label="Email ID">
                    {invoice?.customer?.email ? (
                      <CopyCode
                        getCopyText={() => invoice?.customer?.email}
                        defaultText={invoice?.customer?.email}
                        copyIcon={CopyPurple}
                        /* @tw */
                        addClassName="all:unset"
                        codeCopiedText="Copied"
                      />
                    ) : (
                      "-"
                    )}
                  </InfoItem>
                </SliderAccordion.ItemGrid>
              </div>
              <SliderAccordion.ItemGrid>
                <InfoItem label="Contact">{customer?.phone || "-"}</InfoItem>
              </SliderAccordion.ItemGrid>
              <SliderAccordion.ItemGrid>
                <InfoItem label="Address">
                  {customer?.billing_address?.address_string || "-"}
                </InfoItem>
              </SliderAccordion.ItemGrid>
            </SliderAccordion.Content>
          </SliderAccordion.Item>

          {associated_txns?.map(({ amount, date, uuid, source }) => (
            <div
              className="t-rounded-lg t-bg-purple-10 t-p-3 t-text-subtext"
              key={uuid}
            >
              <div className="t-bg-white t-rounded-lg">
                <div className="t-p-3 t-border-b t-border-0 t-border-solid t-border-neutral-0">
                  Linked Transaction
                </div>
                <div className="t-text-subtext t-p-3">
                  <div className="t-grid t-grid-cols-6">
                    <div className="t-col-span-4 t-flex t-gap-2">
                      <Avatar src={""} alt={customer?.company_name || ""} />
                      <div>
                        <div className="">{customer?.company_name}</div>
                        <div className="t-text-body-sm t-text-text-30 first-letter:t-uppercase t-lowercase">
                          {source}
                        </div>
                      </div>
                    </div>

                    <div className="t-col-span-2 t-justify-self-end t-flex t-flex-col t-justify-end">
                      <div className="t-self-end">
                        <AmountSuperScript amount={Number(amount)} />
                      </div>
                      <div className="t-text-body-sm t-text-text-30">
                        {dayjs(date).format(DD_MMM_YYYY)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="t-mt-4">
                <Button
                  block
                  customType="primary"
                  size="small"
                  onClick={() => dispatch(openSlider(uuid))}
                >
                  View transaction
                </Button>
              </div>
            </div>
          ))}

          {logs.length > 0 && !isStripeInvoice && (
            <SliderAccordion.Item value="TIMELINE">
              <SliderAccordion.Trigger disabled>
                Timeline
              </SliderAccordion.Trigger>
              <SliderAccordion.Content forceMount>
                <div id="scrollableDiv" className="t-max-h-96 t-overflow-auto">
                  <InfiniteScroll
                    dataLength={logs.length}
                    next={loadNext}
                    hasMore={pageNum < total_pages}
                    scrollableTarget="scrollableDiv"
                    loader={
                      <div className="t-flex t-justify-center t-items-center t-w-full t-text-body-sm t-gap-2 t-p-2">
                        <span className="t-flex t-origin-center t-animate-spin">
                          <LoadingIcon />
                        </span>
                        Loading...
                      </div>
                    }
                    className="t-flex t-flex-col t-space-y-2 t-pl-2"
                  >
                    {logs?.map(({ event, uuid }, index) => (
                      <InvoiceAudit
                        isLastStep={index === logs.length - 1}
                        name={event.name}
                        eventDate={event.time}
                        key={uuid}
                      />
                    ))}
                  </InfiniteScroll>
                </div>
              </SliderAccordion.Content>
            </SliderAccordion.Item>
          )}

          {invoice?.document && (
            <SliderAccordion.Item value="ATTACHMENT">
              <SliderAccordion.Trigger disabled>
                Attachment
              </SliderAccordion.Trigger>
              <SliderAccordion.Content forceMount>
                <FileInput
                  file={{
                    uuid: invoice.document?.file_id,
                    name: invoice.document?.file_name,
                    file_type: "PDF",
                    is_previewable: true,
                  }}
                  groupId={groupId}
                  onFileClick={({ e, uuid: fileId }) => openPreview(fileId)}
                />
              </SliderAccordion.Content>
            </SliderAccordion.Item>
          )}
        </SliderAccordion.Root>
      </Slider.Body>
      {!isStripeInvoice && (
        <Slider.Footer>
          <Button
            customType="primary"
            size="small"
            block
            onClick={onEditInvoice}
          >
            Edit Invoice
          </Button>
        </Slider.Footer>
      )}
      <TransactionSlider />
    </>
  );
};
