import { Button } from "components/DesignSystem/Button/Button";
import {
  Combobox,
  OptionData,
} from "components/DesignSystem/Combobox/Combobox";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import { TextArea } from "components/DesignSystem/TextArea/TextArea";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import Pencil from "components/icons/pencil";
import { InvoiceCustomerModal } from "components/InvoiceCustomerModal/InvoiceCustomerModal";
import { InvoiceItems } from "components/InvoiceItems/InvoiceItems";
import { NumericInput } from "components/NumericInput/NumericInput";
import { PriceInput } from "components/PriceInput/PriceInput";
import { DD_MMM_YYYY, YYYY_MM_DD } from "constants/date";
import { Field, FieldProps, Form, useFormikContext } from "formik";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { ReactNode, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { components, OptionProps } from "react-select";
import {
  Invoice,
  useGetAllInvoiceCustomersQuery,
  useGetEntityInvoiceQuery,
  useGetInvoiceSettingsQuery,
} from "store/apis/invoices";
import * as INVOICE_STATUSES from "constants/invoiceStatuses";
import { FormikListener } from "pages/Books/Invoicing/AddInvoice";
import {
  ToggleGroup,
  ToggleGroupItem,
} from "components/DesignSystem/ToggleGroup/ToggleGroup";
import { Percent } from "components/icons/Percent";
import { Hash } from "components/icons/Hash";
import { useQuery } from "hooks/useQuery";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import WarningCircle from "static/images/WarningCircle.svg";

const SelectOrEditCustomer = ({
  children,
  ...props
}: OptionProps<OptionData>) => {
  return (
    <components.Option
      {...props}
      className="[&.select\_\_option--is-focused]:t-bg-surface-lighter-grey [&.select\_\_option--is-selected]:t-bg-i-surface-light-purple"
    >
      <div className="t-flex t-gap-2.5 t-font-medium t-items-center t-justify-between t-px-3 ">
        <div className="t-cursor-pointer t-truncate !t-border-none t-py-2.5">
          {children}
        </div>
        <Button
          customType="ghost_icon"
          size="small"
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            // @ts-ignore
            props.selectProps?.onEditCustomer?.(props.data.value);
          }}
        >
          <Pencil color="currentColor" />
        </Button>
      </div>
    </components.Option>
  );
};

export const InvoiceItemGroup = ({
  title,
  children,
  required,
}: {
  title?: ReactNode;
  children: ReactNode;
  required?: boolean;
}) => (
  <div className="t-flex t-flex-col t-gap-6">
    {title && (
      <div className="t-text-subtitle">
        {title} {required && <span className="t-text-red">*</span>}
      </div>
    )}
    {children}
  </div>
);

export const invoiceInitialValues = {
  title: "",
  invoice_number: "",
  invoice_number_without_prefix: "",
  po_number: "",
  sent_to: "",
  sent_on: "",
  invoice_amount: "",
  due_amount: "",
  discount: "",
  discount_type: "PERCENT",
  invoice_date: "",
  status: INVOICE_STATUSES.DRAFT,
  due_date: "",
  sub_total: "",
  tax: "",
  tax_type: "PERCENT",
  total: "",
  paid_amount: "",
  due_balance: "",
  notes: "",
  terms: "",
  customer_id: "",
  created_on: "",
  invoice_items: [
    {
      description: "",
      quantity: 1,
      rate: 0.0,
      uuid: "",
    },
  ],
  // shipping_date: "",
  // ship_from: {
  //   street_address: "",
  //   city: "",
  //   state: "",
  //   zipcode: "",
  //   country: "",
  // },
  // ship_to: {
  //   street_address: "",
  //   city: "",
  //   state: "",
  //   zipcode: "",
  //   country: "",
  // },
};

const ToggleNumbers = ({
  value,
  onValueChange,
}: {
  value: string;
  onValueChange: (v: string) => void;
}) => (
  <div className="-t-mr-2 t-py-1">
    <ToggleGroup
      value={value}
      onValueChange={(value) => {
        if (value) {
          onValueChange(value);
        }
      }}
    >
      <ToggleGroupItem color="primary" size="small" value="PERCENT">
        <Percent />
      </ToggleGroupItem>
      <ToggleGroupItem color="primary" size="small" value="ABSOLUTE">
        <Hash />
      </ToggleGroupItem>
    </ToggleGroup>
  </div>
);

export const InvoiceForm = ({
  onChange,
}: {
  onChange: (invoice: Partial<Invoice>) => void;
}) => {
  const [customerId, setCustomerId] = useState<string | null | "NEW">("");
  const { invoiceId } = useParams<{ invoiceId: string }>();
  const group = useCurrentGroupContext();
  const query = useQuery();
  const editFlow = query.get("editflow");
  const entityId = useCurrentEntityId();

  const { values, setFieldValue, errors } =
    useFormikContext<typeof invoiceInitialValues>();

  const { data: invoiceSettings, isLoading: invoiceSettingLoading } =
    useGetInvoiceSettingsQuery(
      {
        groupId: group?.uuid!,
        entityId,
      },
      { skip: !group?.uuid || !entityId }
    );

  const { data: invoice, isLoading: invoiceLoading } = useGetEntityInvoiceQuery(
    {
      groupId: group?.uuid!,
      entityId: entityId!,
      invoiceId,
    },
    { skip: !group?.uuid || !entityId }
  );
  const isSentInvoiceEditFlow = invoice?.status === "SENT" && Boolean(editFlow);

  const { data: invoiceCustomers } = useGetAllInvoiceCustomersQuery(
    {
      groupId: group?.uuid!,
      entityId: entityId!,
    },
    { skip: !group?.uuid || !entityId }
  );

  const customer = invoiceCustomers?.find(
    (customer) => customer.customer_id === values.customer_id
  );

  return (
    <Form className="t-m-0 md:t-h-full md:t-w-7/12">
      <div className="t-pb-10 t-gap-12 t-flex t-flex-col ">
        <FormikListener values={values} callback={onChange} />
        <div className="t-flex t-flex-col t-gap-12">
          <InvoiceItemGroup title="Invoice Details">
            <div className="t-grid t-gap-4 t-grid-cols-2">
              <div className="t-col-span-2">
                <TextInput name="title" label="Title" />
              </div>

              <TextInput
                name="invoice_number_without_prefix"
                label="Invoice Number"
                customPrefix={
                  <div className=" t-text-text-30">
                    {invoiceSettings?.invoice_prefix}
                  </div>
                }
                onBlurCapture={() => {
                  if (errors?.invoice_number_without_prefix) {
                    setFieldValue(
                      "invoice_number_without_prefix",
                      invoice?.invoice_number_without_prefix
                    );
                  }
                }}
                rightComponent={
                  errors?.invoice_number_without_prefix ? (
                    <img src={WarningCircle} alt="WarningCircle" />
                  ) : (
                    <></>
                  )
                }
              />

              <Field name="invoice_date" required>
                {({ field }: FieldProps) => {
                  return (
                    <div>
                      <DateInput
                        {...field}
                        required
                        saveFormat={YYYY_MM_DD}
                        label="Invoice Date"
                        placeholder={DD_MMM_YYYY}
                      />
                    </div>
                  );
                }}
              </Field>
              <Field name="due_date" placeholder="Select a due date" required>
                {({ field }: FieldProps) => {
                  return (
                    <DateInput
                      {...field}
                      saveFormat={YYYY_MM_DD}
                      label="Due Date"
                      placeholder={DD_MMM_YYYY}
                      required={Boolean(customer?.is_reminders_enabled)}
                    />
                  );
                }}
              </Field>
              <Combobox
                options={[{ label: "USD", value: "USD" }]}
                withForm
                value={{ label: "USD", value: "USD" }}
                isDisabled
                name="currency"
                label="Currency"
              />
            </div>
          </InvoiceItemGroup>

          <InvoiceItemGroup title="Customer Details" required>
            <Combobox
              isDisabled={isSentInvoiceEditFlow}
              options={invoiceCustomers?.map((v) => ({
                label: v.company_name,
                value: v.customer_id,
              }))}
              withForm
              defaultValue={
                invoice?.customer && {
                  label: invoice.customer.company_name,
                  value: invoice.customer.uuid!,
                }
              }
              size="large"
              name="customer_id"
              placeholder="Select your Customer"
              actions={
                <Button
                  onClick={() => setCustomerId("NEW")}
                  customType="secondary"
                >
                  Add customer
                </Button>
              }
              components={{
                Option: SelectOrEditCustomer,
                NoOptionsMessage: () => (
                  <p className="t-m-0 t-text-center t-py-4">
                    No customers added
                  </p>
                ),
              }}
              // @ts-ignore
              onEditCustomer={setCustomerId}
            />
          </InvoiceItemGroup>
        </div>

        <InvoiceItemGroup title="Item Details">
          <InvoiceItems />
        </InvoiceItemGroup>

        <InvoiceItemGroup title="Tax & Discount">
          <div className="t-flex t-gap-4">
            {values.tax_type === "PERCENT" ? (
              <NumericInput
                fieldProps={{ name: "tax" }}
                storeNumeric
                numericProps={{
                  fixedDecimalScale: true,
                  decimalScale: 2,
                  suffix: " %",
                  allowNegative: false,
                  rightComponent: (
                    <ToggleNumbers
                      value={values.tax_type}
                      onValueChange={(value) =>
                        setFieldValue("tax_type", value)
                      }
                    />
                  ),
                }}
                label="Tax"
              />
            ) : (
              <PriceInput
                name="tax"
                rightComponent={
                  <ToggleNumbers
                    value={values.tax_type}
                    onValueChange={(value) => setFieldValue("tax_type", value)}
                  />
                }
                label="Tax"
                allowNegative={false}
              />
            )}

            {values.discount_type === "PERCENT" ? (
              <NumericInput
                label="Discount"
                storeNumeric
                fieldProps={{ name: "discount" }}
                numericProps={{
                  fixedDecimalScale: true,
                  decimalScale: 2,
                  suffix: " %",
                  allowNegative: false,
                  rightComponent: (
                    <ToggleNumbers
                      value={values.discount_type}
                      onValueChange={(value) =>
                        setFieldValue("discount_type", value)
                      }
                    />
                  ),
                }}
              />
            ) : (
              <PriceInput
                name="discount"
                allowNegative={false}
                rightComponent={
                  <ToggleNumbers
                    value={values.discount_type}
                    onValueChange={(value) =>
                      setFieldValue("discount_type", value)
                    }
                  />
                }
                label="Discount"
              />
            )}

            <PriceInput
              allowNegative={false}
              name="paid_amount"
              label="Advance Paid"
            />
          </div>
        </InvoiceItemGroup>

        <InvoiceItemGroup>
          <TextArea name="notes" label="Notes" />
          <TextArea name="terms" label="Terms" />
        </InvoiceItemGroup>
      </div>
      <InvoiceCustomerModal
        customerId={customerId}
        setCustomerId={setCustomerId}
      />
    </Form>
  );
};
