import classNames from "classnames";
import { ConditionalLink } from "components/conditionalLink";
import Loader from "components/design/loader";
import { LoadingToast } from "components/design/LoadingToast";
import { Button } from "components/DesignSystem/Button/Button";
import { HighlightSearchTerm } from "components/DesignSystem/HighlightText";
import TaskDetailArchive from "components/icons/TaskDetailArchive";
import TaskDetailUnarchive from "components/icons/TaskDetailUnarchive";
import { US } from "constants/countryCodes";
import { ONGOING } from "constants/task";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { TASK_LIST_FILTER_TYPES } from "dictionaries";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useQuery } from "hooks/useQuery";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { MouseEvent, ReactNode, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import ArrowBend from "static/images/ArrowBendDownRight.svg";
import Redirect from "static/images/Redirect.svg";
import Search from "static/images/Search.svg";
import VerticalLine from "static/images/VerticalLine.svg";
import {
  useGetTaskListForServiceTeamQuery,
  useGetTaskListForUserQuery,
  useUpdateTaskArchivalMutation,
} from "store/apis/taskList";
import { setTaskCount } from "store/slices/taskList";
import { Task } from "types/Models/task";
import { useDebounce } from "utils/debounce";
import { formatDate } from "utils/formatDate";
import {
  Row,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from "react-table-8.10.7";
import Table from "components/DesignSystem/Table/V2/Table";
import { TaskSlider } from "components/TaskSlider/TaskSlider";
import { TaskStatus } from "components/TaskStatus/TaskStatus";
import { EstimdatedTimeColumn } from "components/tasks/taskForForeignCA/ForeignCATask";
import ToolTip from "components/design/toolTip";

dayjs.extend(relativeTime);

export type TaskTableProps = {
  seasons?: string;
};

const TableContainer = ({
  seasons,
  children,
}: {
  seasons?: string;
  children: ReactNode;
}) => {
  if (seasons) {
    return (
      <div className="t-w-full t-shadow-table-shodow">
        <Table.Container size="small">{children}</Table.Container>
      </div>
    );
  } else {
    return (
      <div className="t-h-full t-w-full t-p-5 t-pt-6">
        <Table.Container size="small">{children}</Table.Container>
      </div>
    );
  }
};

export const TaskTable = ({ seasons }: TaskTableProps) => {
  const [taskId, setTaskId] = useState<string | null>(null);
  const query = useQuery();
  const { alertToast, successToast } = useToast();
  const dispatch = useDispatch();
  const search = query.get("search");
  const searchTerm = useDebounce(search, 500);
  const taskType = (query.get("task") ||
    ONGOING) as keyof typeof TASK_LIST_FILTER_TYPES;
  const filterValues = TASK_LIST_FILTER_TYPES[taskType];

  const { uuid: groupId } = useCurrentGroupContext();
  const { isCpa, isCustomer, isAnyServiceUser } = useRoleBasedView();
  const { entities } = useCurrentGroupContext();

  const usEntities = entities.filter((e) => e.country_code === US);

  const isMultiUsEntity = usEntities.length > 1;

  const { data: userTaskData, isLoading: isUserTaskLoading } =
    useGetTaskListForUserQuery(
      {
        ...filterValues,
        searchTerm,
        seasons,
        taskCategoryType: "TAX,RND",
      },
      { skip: isAnyServiceUser, refetchOnMountOrArgChange: true }
    );

  const { data: cpaTaskList, isLoading: isCPATaskLoading } =
    useGetTaskListForServiceTeamQuery(
      {
        ...filterValues,
        searchTerm,
        groupId,
        seasons,
      },
      { skip: isCustomer || !groupId, refetchOnMountOrArgChange: true }
    );

  const {
    ongoing_count,
    count,
    completed_count,
    archived_count,
    tasks: taskList = [],
  } = (userTaskData || cpaTaskList || {}) as {
    tasks?: Task[];
    ongoing_count?: number;
    count?: number;
    completed_count?: number;
    archived_count?: number;
  };

  const [updateTaskArchival, { isLoading: isArchiveUpdating }] =
    useUpdateTaskArchivalMutation();

  const noTaskFound = Boolean(!taskList.length);

  const isTaskLoading = isCPATaskLoading || isUserTaskLoading;

  const data: (Task & { subRows?: Task[] })[] = useMemo(() => {
    const templateMap = new Map<string, Array<any>>();

    taskList.forEach((task: any) => {
      if (!templateMap.get(task?.template?.uuid)) {
        templateMap.set(task?.template?.uuid, []);
      }

      templateMap.get(task?.template?.uuid)?.push(task);
    });

    const subTaskTemplateMap = taskList.reduce((acc, task) => {
      if (
        task.parent_task_template_id &&
        templateMap.get(task.parent_task_template_id)?.length
      ) {
        return {
          [task.parent_task_template_id]: [
            ...(acc[task.parent_task_template_id] || []),
            task,
          ],
        };
      }

      return acc;
    }, {} as Record<string, Task[]>);

    const tasksAndTheirSubtasks = taskList.map((parent) => {
      const subTasks = subTaskTemplateMap[parent?.template?.uuid]
        ?.filter((subTask) => subTask.entity.uuid === parent.entity.uuid)
        ?.map((task, i, arr) => {
          const isLast = i === arr.length - 1;

          return {
            ...task,
            isSubTask: true,
            isLast,
          };
        });

      return {
        ...parent,
        subRows: subTasks || [],
        toggleAllRowsExpanded: true,
      };
    });

    const subTaskIds = tasksAndTheirSubtasks.flatMap((t) =>
      t.subRows.map((st) => st.uuid)
    );

    return tasksAndTheirSubtasks.filter(
      (task) => !subTaskIds.includes(task.uuid)
    );
  }, [taskList]);

  const updateTaskArchivalStatus = async ({
    e,
    uuid,
  }: {
    e: MouseEvent<HTMLButtonElement>;
    uuid: string;
  }) => {
    try {
      e.stopPropagation();
      const { is_archived } = await updateTaskArchival({
        taskId: uuid,
      }).unwrap();
      successToast({
        message: is_archived ? "Filing Archived" : "Filing Unarchived",
      });
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  const onRowClick = (
    e: MouseEvent<HTMLDivElement>,
    uuid: string,
    step: string,
    status: string,
    display_text: string
  ) => {
    e.stopPropagation();
    setTaskId(uuid);
  };

  const columnHelper = createColumnHelper<
    Task & { isLast?: boolean; subRows?: Task[] }
  >();

  const archive = isCustomer
    ? []
    : [
        columnHelper.accessor("is_archived", {
          header: "",
          size: 5,
          cell: (info) => {
            const value = info.getValue();

            const {
              original: { uuid },
            } = info.row;

            return (
              <span className="t-text-text-100">
                <Button
                  size="small"
                  disabled={isArchiveUpdating}
                  customType="ghost_icon"
                  onClick={(e) => updateTaskArchivalStatus({ e, uuid })}
                >
                  {value ? (
                    <TaskDetailUnarchive color="currentColor" />
                  ) : (
                    <TaskDetailArchive color="currentColor" />
                  )}
                </Button>
              </span>
            );
          },
        }),
      ];

  const entityColumn = isMultiUsEntity
    ? [
        columnHelper.accessor("entity", {
          header: "Entity Name",
          size: 10,
          cell: (info) => {
            const {
              entity: { name = "" },
            } = info.row.original;
            return <div className="t-text-body-sm">{name}</div>;
          },
        }),
      ]
    : [];

  useEffect(() => {
    if (
      ongoing_count !== undefined &&
      count !== undefined &&
      completed_count !== undefined &&
      archived_count !== undefined
    ) {
      dispatch(
        setTaskCount({ ongoing_count, count, completed_count, archived_count })
      );
    }
  }, [ongoing_count, count, completed_count, archived_count, data]);

  const columns = useMemo(() => {
    const estimatedDateColumn = isCpa
      ? [
          columnHelper.accessor("estimated_time_of_delivery", {
            cell: ({ row }) => (
              <EstimdatedTimeColumn
                taskData={row.original}
                isCustomTrigger={true}
              />
            ),
            header: () => (
              <ToolTip text="Estimated time of delivery">
                <span className="t-pl-1">ETD</span>
              </ToolTip>
            ),
            size: 5,
          }),
        ]
      : [];

    return [
      columnHelper.accessor("title", {
        header: "Name",
        size: isMultiUsEntity ? 23 : 27,
        cell: (info) => {
          const { row } = info;
          const value = info.getValue();

          const {
            original: { is_archived, uuid, isLast },
          } = row;

          // Checks the subrow id to see if there is only one subtask
          // Row id would be something like 2.0
          const hasSingleSubtask = row.id.split(".")[1] === "0";

          return (
            <div
              className="t-relative t-flex t-gap-1"
              style={{
                paddingLeft: `${row.depth}rem`,
              }}
            >
              <span>
                {row.depth > 0 ? (
                  <>
                    <img src={ArrowBend} alt="Subtask" />
                    <img
                      className={classNames("t-absolute t-left-[16.8px] ", {
                        "t-top-[-64px]": isLast,
                        "-t-top-[15px]": !isLast,
                        "t-hidden": row?.original?.isLast && hasSingleSubtask,
                      })}
                      src={VerticalLine}
                      alt="Subtask"
                    />
                  </>
                ) : null}
              </span>
              <span className="t-flex t-items-center t-gap-2 t-pr-3 t-text-subtitle-sm t-font-medium t-text-text-100 t-group">
                <span
                  className="t-line-clamp-2 hover:t-underline t-text-purple"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <ConditionalLink
                    to={`/filings/${uuid}`}
                    target="_blank"
                    className="t-text-purple hover:t-text-purple"
                  >
                    <HighlightSearchTerm
                      text={value}
                      searchTerm={search || ""}
                    />
                  </ConditionalLink>
                </span>
                {is_archived && (
                  <span className="t-rounded t-bg-neutral-10 t-px-2 t-py-1 t-text-neutral-90">
                    A
                  </span>
                )}
                <img
                  src={Redirect}
                  alt="redirect"
                  className="group-hover:!t-visible t-invisible t-h-4 t-w-4"
                />
              </span>
            </div>
          );
        },
      }),
      ...entityColumn,

      columnHelper.accessor("display_text", {
        header: "Description",
        size: isMultiUsEntity ? 37 : 33,
        cell: (info) => {
          return <div className="t-text-body-sm">{info.getValue() || "-"}</div>;
        },
      }),

      columnHelper.accessor("status", {
        header: "Status",
        size: 15,
        cell: (info) => (
          <TaskStatus
            status={info.getValue()}
            state={info.row?.original?.state}
            statusType={info.row?.original?.state_details?.type}
          />
        ),
      }),
      columnHelper.accessor("deadline", {
        header: "Deadline",
        size: 15,
        cell: (info) => {
          return <>{info.getValue() ? formatDate(info.getValue()) : "-"}</>;
        },
      }),
      ...estimatedDateColumn,
      ...(archive as []),
    ];
  }, [isTaskLoading, isArchiveUpdating, search]);

  const table = useReactTable({
    // @ts-ignore
    // columns: columns.tableColumns,
    columns,
    autoResetExpanded: false,
    data,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    state: {
      expanded: true,
    },
    enableExpanding: true,
    defaultColumn: {
      size: 10,
      minSize: 5,
    },
  });

  if (isTaskLoading) {
    return <Loader />;
  }

  // Returns styles for rows with subtasks
  const setRowStyles = (
    row: Row<
      Task & {
        isLast?: boolean | undefined;
        isSubTask?: boolean | undefined;
      }
    >
  ) => {
    if (row.original?.isLast) {
      return "";
    }

    if (row.subRows.length || row.original?.isSubTask) {
      return "t-border-b-0";
    }
    return "";
  };

  return (
    <div className="t-h-full t-w-full t-border-y-0 t-border-l-4 t-border-r-0 t-border-solid t-border-purple-30">
      <div className="t-flex t-h-full t-gap-2 t-w-full">
        <TableContainer seasons={seasons}>
          <Table.Content className="t-w-full t-overflow-auto">
            <Table.Head
              className={classNames(
                "t-inline-table t-w-full t-bg-neutral-10 !t-border-b",
                {
                  "!t-rounded-t-none": seasons,
                }
              )}
            >
              {table.getHeaderGroups().map((headerGroup) => (
                <Table.HeadRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Table.HeadCell
                      key={header.id}
                      style={{ width: `${header.getSize()}%` }}
                      className="t-py-4 t-px-3 t-uppercase t-text-subtext-sm"
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </Table.HeadCell>
                  ))}
                </Table.HeadRow>
              ))}
            </Table.Head>

            {noTaskFound ? (
              <div className="t-flex t-h-[calc(100vh-260px)] t-flex-col t-items-center t-justify-center t-gap-4 t-text-subtitle-sm t-font-medium t-text-text-30">
                <img src={Search} alt="No task found" />
                Look's like you don't have any filings
              </div>
            ) : (
              <Table.Body className="all:unset t-inline-table t-w-full">
                {table.getRowModel().rows.map((row) => {
                  const {
                    original: { uuid, state, status, display_text },
                  } = row;

                  return (
                    <Table.Row
                      key={row.id}
                      onClick={(e) =>
                        // @ts-ignore
                        onRowClick(e, uuid, state, status, display_text)
                      }
                      className={classNames(
                        `t-cursor-pointer t-items-center t-justify-center t-gap-6 t-border-b t-border-r-0 t-border-t-0 t-border-solid t-border-i-neutral-10 t-border-l-surface-transparent hover:t-bg-purple-0 t-h-max ${setRowStyles(
                          row
                        )}`,
                        {
                          "t-bg-purple-0": row.original.uuid === taskId,
                        }
                      )}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <Table.Cell
                          key={cell.id}
                          style={{ width: `${cell.column.getSize()}%` }}
                          className="t-align-middle t-px-3 t-py-2"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Table.Cell>
                      ))}
                    </Table.Row>
                  );
                })}
              </Table.Body>
            )}
          </Table.Content>
        </TableContainer>
      </div>
      <LoadingToast loading={isArchiveUpdating}>
        Filing Updating...
      </LoadingToast>

      <TaskSlider
        taskId={taskId}
        onClose={() => setTaskId(null)}
        isOpen={Boolean(taskId)}
      />
    </div>
  );
};
