import classNames from "classnames";
import { motion, Variants } from "framer-motion";
import React from "react";

type AnimatedSlideProps = {
  isVisible: boolean;
  direction: "left" | "right" | "top" | "bottom";
  children: React.ReactNode;
};
export const AnimatedSlide = ({
  isVisible,
  direction,
  children,
}: AnimatedSlideProps) => {
  const variants: Variants = {
    hidden: {
      x: direction === "left" ? "-50%" : direction === "right" ? "50%" : 0,
      y: direction === "top" ? "-50%" : direction === "bottom" ? "50%" : 0,
      opacity: 0,
      pointerEvents: "none",
    },
    visible: {
      x: 0,
      y: 0,
      opacity: 1,
      pointerEvents: "auto",
    },
  };

  return (
    <motion.div
      initial={false}
      animate={isVisible ? "visible" : "hidden"}
      variants={variants}
      transition={{
        x: { type: "tween", duration: 0.6, ease: [0.16, 1, 0.3, 1] },
        y: { type: "tween", duration: 0.6, ease: [0.16, 1, 0.3, 1] },
        opacity: { delay: 0.1 },
      }}
      className={classNames("t-absolute t-inset-0 t-overflow-hidden", {
        "t-w-full t-h-full": isVisible,
        "t-w-0 t-h-0": !isVisible,
      })}
    >
      {children}
    </motion.div>
  );
};
