import classNames from "classnames";

const escapeRegExp = (string: string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

export const HighlightSearchTerm = ({
  text,
  searchTerm,
}: {
  text: string;
  searchTerm?: string;
}) => {
  if (!searchTerm) {
    return <>{text}</>;
  }

  const escapedSearchTerm = escapeRegExp(searchTerm);
  const parts = text?.split(new RegExp(`(${escapedSearchTerm})`, "gi"));

  return (
    <span>
      {parts?.map((part, i) => (
        <span
          key={i}
          className={classNames("t-select-none", {
            "t-bg-orange-30":
              part.toLowerCase() === escapedSearchTerm.toLocaleLowerCase(),
          })}
        >
          <span>{part}</span>
        </span>
      ))}
    </span>
  );
};
