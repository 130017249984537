import { ReviewAndBalancePayment } from "components/chat/ReviewAndBalancePayment/ReviewAndBalancePayment";
import DropDown from "components/DesignSystem/Dropdown/Dropdown";
import { PlusIcon } from "components/icons/PlusIcon";
import { UploadFile } from "components/UploadFile/UploadFile";
import { TASK_REVIEW_DOCUMENT } from "constants/chatType";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  setCustomAttachments,
  setDocumentReviewPayload,
  setTaskReviewBalancePaymentStep,
} from "store/slices/reviewAndBalancePayment";
import { openUploadCSVModal } from "store/slices/openItem";
import { RootState } from "store/store";
import { LocalFileOrInkleDocs } from "types/Models/reviewDocumentAndBalancingPayment";
import { BalancePayment } from "components/BalancePayment/BalancePayment";
import { openRequestDocumentsModal } from "store/slices/requestDocumentsInChat";
import { useChannelStateContext } from "stream-chat-react";
import { useModal } from "hooks/useModal";
import { RequestSignModal } from "components/RequestSign/RequestSignModal";
import { DropFile } from "components/DesignSystem/BaseFileInput/BaseFileInput";
import { SensitiveDataRequestModal } from "components/SensitiveData/SensitiveData";
import { Dropdown } from "react-bootstrap";
import { useGetAllSensitiveDataTypesQuery } from "apis/sensitiveData";
import { useGetTaskFromChannelIdQuery } from "store/apis/task";
import { useRoleBasedView } from "hooks/useRoleBasedView";

export const TaskActionsFromChat = () => {
  const { channel } = useChannelStateContext();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showUploadFileModal, setShowUploadFileModal] = useState(false);
  const [showBalancingReviewModal, setShowBalancingReviewModal] =
    useState(false);
  const dispatch = useDispatch();
  const { channelTaskId } = useSelector((state: RootState) => state.chat);
  const { customAttachment } = useSelector(
    (state: RootState) => state.reviewAndBalancePayment
  );
  const [showBalancePayment, setShowBalancePayment] = useState(false);

  const {
    close: closeRequestSignModal,
    isOpen: isRequestSignModal,
    open: openRequestSignModal,
  } = useModal();
  const {
    close: closeSensitiveDataRequestModal,
    isOpen: isSensitiveDataRequestModal,
    open: openSensitiveDataRequestModal,
  } = useModal();
  const onFileSelect = (v: LocalFileOrInkleDocs[]) => {
    dispatch(setCustomAttachments(v));
    dispatch(
      setDocumentReviewPayload({
        document_operation: TASK_REVIEW_DOCUMENT,
      })
    );
    setShowDropdown(false);
    setShowUploadFileModal(false);
  };

  const { data: currentChannelData } = useGetTaskFromChannelIdQuery(
    {
      channelId: channel.id!,
    },
    {
      skip: !channel.id,
    }
  );

  const closeReviewAndBalancePayment = () => {
    dispatch(setTaskReviewBalancePaymentStep(1));
    setShowBalancingReviewModal(false);
  };
  const [formId, setFormId] = useState("");
  const { data: formTypes } = useGetAllSensitiveDataTypesQuery();
  const { isAdmin } = useRoleBasedView();
  const { entity } = currentChannelData || {};

  return (
    <>
      <DropDown.Root open={showDropdown} onOpenChange={setShowDropdown}>
        <DropDown.Trigger asChild>
          <div className="t-w-6 t-h-6 t-mt-1 t-flex t-justify-center t-text-neutral-50 t-items-center t-rounded">
            <PlusIcon color="currentColor" stroke="1.5" />
          </div>
        </DropDown.Trigger>
        <DropDown.Portal>
          <DropDown.Content
            className="t-w-[270px]"
            sideOffset={8}
            align="start"
          >
            <div>
              <DropDown.Item
                onSelect={() =>
                  channel.id &&
                  dispatch(openUploadCSVModal({ channelId: channel.id }))
                }
              >
                Add open items
              </DropDown.Item>
              <DropDown.Item
                onSelect={() =>
                  dispatch(
                    openRequestDocumentsModal({
                      isModalOpen: true,
                      channelId: channel?.id,
                      groupId: channel.data?.group_uuid as string,
                    })
                  )
                }
              >
                Request document
              </DropDown.Item>
              {channelTaskId && (
                <>
                  <DropDown.Item onSelect={() => setShowUploadFileModal(true)}>
                    Review document
                  </DropDown.Item>
                  <DropDown.Item onSelect={() => setShowBalancePayment(true)}>
                    Ask for payment
                  </DropDown.Item>
                  <DropDown.Item
                    onSelect={() => setShowBalancingReviewModal(true)}
                  >
                    Review and pay
                  </DropDown.Item>
                </>
              )}
              <DropDown.Item onSelect={openRequestSignModal}>
                Request sign
              </DropDown.Item>

              {entity && isAdmin && (
                <DropDown.Sub>
                  <DropDown.SubTrigger>
                    Request Sensitive Data
                  </DropDown.SubTrigger>
                  <DropDown.Portal>
                    <DropDown.SubContent sideOffset={8} alignOffset={-150}>
                      {formTypes?.map(({ uuid, name }, index) => {
                        return (
                          <DropDown.Item
                            key={index}
                            onSelect={() => {
                              setFormId(uuid);
                              openSensitiveDataRequestModal();
                            }}
                          >
                            {name}
                          </DropDown.Item>
                        );
                      })}
                    </DropDown.SubContent>
                  </DropDown.Portal>
                </DropDown.Sub>
              )}
            </div>
          </DropDown.Content>
        </DropDown.Portal>
      </DropDown.Root>
      {showUploadFileModal && (
        <UploadFile
          showModal={showUploadFileModal}
          closeModal={() => setShowUploadFileModal(false)}
          onFileSelect={onFileSelect}
          prevSelectedFiles={customAttachment as DropFile[]}
          title="Upload Documents"
        />
      )}
      {showBalancingReviewModal && (
        <ReviewAndBalancePayment
          show={showBalancingReviewModal}
          closeModal={closeReviewAndBalancePayment}
        />
      )}
      {showBalancePayment && (
        <BalancePayment
          show={showBalancePayment}
          closeModal={() => setShowBalancePayment(false)}
        />
      )}
      <RequestSignModal
        isOpen={isRequestSignModal}
        close={closeRequestSignModal}
      />
      {isSensitiveDataRequestModal && (
        <SensitiveDataRequestModal
          formId={formId}
          isOpen={isSensitiveDataRequestModal}
          close={closeSensitiveDataRequestModal}
          channelUrl={channel.id!}
          entityId={entity?.uuid!}
        />
      )}
    </>
  );
};
