import { Button } from "components/DesignSystem/Button/Button";
import {
  Combobox,
  OptionData,
} from "components/DesignSystem/Combobox/Combobox";
import Modal from "components/DesignSystem/Modal/Modal";
import { DarkFilledWarning } from "components/icons/DarkFilledWarning";
import { US_UUID } from "constants/regAgent";
import { Form, Formik } from "formik";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useQuery } from "hooks/useQuery";
import { useToast } from "hooks/useToast";
import React from "react";
import { MultiValue, SingleValue } from "react-select";
import { useAddStateRegistrationMutation } from "store/apis/StateRegistrations";
import { useGetCountryStatesQuery } from "store/apis/teamSetting";
import { BackendError } from "types/utils/error";

export const AddStateRegistrationModal = ({
  isOpen,
  close,
}: {
  isOpen: boolean;
  close: () => void;
}) => {
  const { alertToast, successToast } = useToast();
  const { data: states, isLoading } = useGetCountryStatesQuery({
    countryId: US_UUID,
  });
  const [addStateRegistration, { isLoading: isAdding }] =
    useAddStateRegistrationMutation();
  const { uuid: groupId } = useCurrentGroupContext();
  const query = useQuery();
  let entityId = query.get("entity");

  const handleSubmit = async ({ stateId }: { stateId: string }) => {
    try {
      if (entityId) {
        await addStateRegistration({ groupId, entityId, stateId }).unwrap();
        successToast({ message: "State Added" });
      }
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
    close();
  };

  const options = states?.map(({ state_id, name }) => ({
    value: state_id,
    label: name,
  }));

  return (
    <Modal.Root open={isOpen} onOpenChange={close} modal>
      <Modal.Content useCustomOverlay>
        <Modal.Header>
          <div>
            <Modal.Title>Add New State</Modal.Title>
            <Modal.Subtitle>Keep track of your registrations</Modal.Subtitle>
          </div>
          <Modal.Close />
        </Modal.Header>
        <Formik
          initialValues={{
            stateId: "",
          }}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, submitForm, setFieldValue }) => (
            <Form className="all:unset">
              <Modal.Body>
                <div className="t-flex t-flex-col t-gap-3">
                  <Combobox
                    options={options}
                    isDisabled={isLoading}
                    isLoading={isLoading}
                    menuPortalTarget={document.body}
                    menuPlacement="auto"
                    withForm
                    name="stateId"
                    placeholder="Select"
                    label="State"
                    onChange={(
                      selectedOption:
                        | MultiValue<OptionData>
                        | SingleValue<OptionData>
                    ) =>
                      setFieldValue(
                        "stateId",
                        (selectedOption as SingleValue<OptionData>)?.value || ""
                      )
                    }
                  />
                </div>
              </Modal.Body>
              <Modal.FooterButtonGroup>
                <Modal.RawClose asChild>
                  <Button>Cancel</Button>
                </Modal.RawClose>
                <Button
                  customType="primary"
                  onClick={submitForm}
                  disabled={isAdding || !values.stateId}
                  isLoading={isAdding}
                >
                  Add state
                </Button>
              </Modal.FooterButtonGroup>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal.Root>
  );
};
