import { Button } from "components/DesignSystem/Button/Button";
import { SubscriptionModal } from "components/SubscriptionModal/SubscriptionModal";
import {
  COMPLETED,
  IN_CLIENT_REVIEW,
  IN_PROGRESS,
} from "constants/bookkeepingMonthlySummaryStatus";
import { BYOA, PAYG, PRO } from "constants/subscriptionType";
import { useModal } from "hooks/useModal";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

export const Paid = ({ viewInvoices }: { viewInvoices: () => void }) => {
  return (
    <div className="t-mt-4 t-flex t-items-center t-justify-end t-gap-3">
      <Button customType="secondary" size="small" onClick={viewInvoices}>
        View Invoices
      </Button>
    </div>
  );
};

export const Completed = ({
  onPay,
  amount,
  isPaid,
  onEdit,
  viewInvoices,
  membership,
}: {
  onPay: () => void;
  amount: string;
  isPaid: boolean;
  onEdit: () => void;
  viewInvoices?: () => void;
  membership?: typeof PRO | typeof PAYG | typeof BYOA;
}) => {
  const dispatch = useDispatch();
  const { isAdmin, isCpa, isForeignCA } = useRoleBasedView();
  const subscriptionModal = useModal();

  if ((isAdmin || isCpa || isForeignCA) && !isPaid) {
    return (
      <div className="t-mt-4 t-flex t-items-center">
        <div className="t-ml-auto t-flex t-gap-2">
          <Button customType="primary" size="small" onClick={onEdit}>
            Edit
          </Button>
        </div>
      </div>
    );
  }

  if (membership === PRO) {
    return (
      <div className="t-mt-4 t-flex t-items-center t-justify-between">
        <Button customType="secondary" size="small" onClick={viewInvoices}>
          View Invoices
        </Button>
        <Button customType="secondary" size="small" onClick={onPay}>
          Billing Details
        </Button>
      </div>
    );
  }

  return (
    <div className="t-mt-4 t-flex t-items-center">
      <div className="t-ml-auto t-flex t-gap-2">
        <Button
          customType="secondary"
          size="small"
          onClick={subscriptionModal.open}
        >
          Get MIS Reports
        </Button>
        <Button customType="primary" size="small" onClick={onPay}>
          Pay ${amount}
        </Button>
      </div>
      <SubscriptionModal
        onPaymentDone={subscriptionModal.close}
        show={subscriptionModal.isOpen}
        onClose={subscriptionModal.close}
      />
    </div>
  );
};

export const InReview = ({
  onPay,
  amount,
  isPaid,
  onEdit,
  viewInvoices,
  membership,
  onDelete,
}: {
  onPay: () => void;
  amount: string;
  isPaid: boolean;
  onEdit: () => void;
  viewInvoices?: () => void;
  membership?: typeof PRO | typeof PAYG | typeof BYOA;
  onDelete: () => void;
}) => {
  const { isAdmin, isCpa, isForeignCA } = useRoleBasedView();

  if (isAdmin || isCpa || isForeignCA) {
    return (
      <div className="t-mt-4 t-flex t-items-center">
        <Button customType="secondary" size="small" onClick={viewInvoices}>
          View Invoices
        </Button>
        <div className="t-ml-auto t-flex t-gap-2">
          <Button customType="danger-outlined" size="small" onClick={onDelete}>
            Delete
          </Button>
          <Button customType="primary" size="small" onClick={onEdit}>
            Edit
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="t-mt-4 t-flex t-items-center">
      <Button customType="secondary" size="small" onClick={viewInvoices}>
        View Invoices
      </Button>
      <div className="t-ml-auto t-flex t-gap-2">
        <Button customType="primary" size="small" onClick={onPay}>
          {membership === PRO ? <>Review</> : <>Pay ${amount}</>}
        </Button>
      </div>
    </div>
  );
};

export const BYOAFooter = ({
  status,
  onMarkReviewed,
  onEdit,
  onAddReport,
}: {
  status: string;
  onMarkReviewed: () => void;
  onEdit: () => void;
  onAddReport: () => void;
}) => {
  const history = useHistory();
  const { isAdmin, isCpa, isForeignCA } = useRoleBasedView();

  const Chat = () => {
    return (
      <Button size="small" onClick={() => history.push("/chat")}>
        Chat
      </Button>
    );
  };

  if (status === IN_PROGRESS) {
    return (
      <div className="t-mt-4 t-flex t-items-center t-gap-2 t-justify-end">
        {isAdmin || isCpa || isForeignCA ? (
          <>
            <Chat />
            <Button customType="primary" size="small" onClick={onAddReport}>
              Add Data & Reports
            </Button>
          </>
        ) : (
          <Button customType="primary" size="small" onClick={onEdit}>
            Edit
          </Button>
        )}
      </div>
    );
  }

  if (status === IN_CLIENT_REVIEW) {
    return (
      <div className="t-mt-4 t-flex t-justify-end">
        {isAdmin || isCpa || isForeignCA ? (
          <Chat />
        ) : (
          <Button customType="primary" size="small" onClick={onMarkReviewed}>
            Mark as Reviewed
          </Button>
        )}
      </div>
    );
  }

  if (status === COMPLETED) {
    return (
      <div className="t-mt-4 t-flex t-justify-end">
        <Chat />
      </div>
    );
  }

  return null;
};
const BookkeepingAccordionFooter = {
  Completed,
  InReview,
  Paid,
  BYOAFooter,
};

export default BookkeepingAccordionFooter;
