export const RedeemBrexPoints = () => (
  <svg
    width="168"
    height="163"
    viewBox="0 0 168 163"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M102.421 64.9374L78.1405 79.1725L70.9413 83.5255L70.0715 84.6607C67.5969 87.8989 63.8319 89.7447 59.9117 89.8467C58.4539 89.8835 56.9798 89.6793 55.5383 89.2179L61.3206 79.1276L63.4072 75.4892L86.8465 57.5708L102.421 64.9374Z"
      fill="#ECC1AF"
    />
    <path
      d="M102.421 64.9374L78.1405 79.1725L70.9413 83.5255L70.0715 84.6607C67.5969 87.8989 63.8319 89.7447 59.9117 89.8467C58.4539 89.8835 56.9798 89.6793 55.5383 89.2179L61.3206 79.1276L63.4072 75.4892L86.8465 57.5708L102.421 64.9374Z"
      fill="#ECC1AF"
    />
    <path
      d="M70.9422 83.5244C71.2987 82.5076 71.1623 81.3299 70.582 80.4221"
      stroke="#9A5C3E"
      strokeWidth="0.360164"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M102.421 64.9374L78.1405 79.1725L70.9413 83.5255L70.0715 84.6607C67.5969 87.8989 63.8319 89.7447 59.9117 89.8467C58.4539 89.8835 56.9798 89.6793 55.5383 89.2179L61.3206 79.1276L63.4072 75.4892L86.8465 57.5708L102.421 64.9374Z"
      stroke="#9A5C3E"
      strokeWidth="0.360164"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M63.689 82.076L59.9117 89.8469C58.4539 89.8836 56.9798 89.6794 55.5383 89.218L61.3206 79.1277L62.713 79.6422C63.6931 80.0056 64.1463 81.1327 63.689 82.076Z"
      fill="#D7ADA3"
      stroke="#9A5C3E"
      strokeWidth="0.306262"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M96.8959 60.9195L69.6019 67.2816L57.384 75.1464C53.7048 77.5107 49.2946 78.356 45.06 77.572C44.1821 77.4127 43.3164 77.18 42.4629 76.8778C41.8014 76.6409 41.438 76.0366 41.438 75.42C41.438 74.9994 41.6095 74.5747 41.9729 74.2603L49.6254 67.6654L65.4163 54.0551L82.4934 46.0188L96.8959 60.9195Z"
      fill="#ECC1AF"
    />
    <path
      d="M96.8959 60.9195L69.6019 67.2816L57.384 75.1464C53.7048 77.5107 49.2946 78.356 45.06 77.572C44.1821 77.4127 43.3164 77.18 42.4629 76.8778C41.8014 76.6409 41.438 76.0366 41.438 75.42C41.438 74.9994 41.6095 74.5747 41.9729 74.2603L49.6254 67.6654L65.4163 54.0551L82.4934 46.0188L96.8959 60.9195Z"
      stroke="#9A5C3E"
      strokeWidth="0.360164"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51.0505 72.149L45.06 77.5718C44.1821 77.4126 43.3164 77.1798 42.4629 76.8776C41.8014 76.6408 41.438 76.0364 41.438 75.4198C41.438 74.9992 41.6095 74.5746 41.9729 74.2601L49.6254 67.6653L51.2057 69.4661C51.8958 70.2542 51.8264 71.4466 51.0505 72.149Z"
      fill="#D7ADA3"
      stroke="#9A5C3E"
      strokeWidth="0.306262"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M96.6059 83.5541L93.7434 96.4457L80.472 94.2529C80.3985 95.6903 79.8799 97.0909 78.9407 98.2425C78.8141 98.4058 78.6753 98.561 78.5283 98.7121C77.6218 99.6431 76.1354 99.688 75.1757 98.8101L72.7787 96.6131C72.1785 96.066 71.6966 95.4126 71.3495 94.7021C70.9167 93.8282 70.688 92.8604 70.688 91.8722C70.688 91.7089 70.6962 91.5455 70.7084 91.3822C70.8187 89.9448 71.4067 88.5891 72.3826 87.5274L76.0414 83.546C76.6703 82.8559 77.5768 82.4883 78.5079 82.5414L96.6059 83.5541Z"
      fill="#ECC1AF"
    />
    <path
      d="M78.6794 88.4788L79.8514 91.0269C80.276 91.9498 80.4802 92.9298 80.4802 93.9058C80.4802 94.0201 80.4761 94.1385 80.472 94.2529M80.472 94.2529C80.3985 95.6903 79.8799 97.0909 78.9407 98.2425C78.8141 98.4058 78.6753 98.561 78.5283 98.7121C77.6218 99.6431 76.1354 99.688 75.1757 98.8101L72.7787 96.6131C72.1785 96.066 71.6966 95.4126 71.3495 94.7021C70.9167 93.8282 70.688 92.8604 70.688 91.8722C70.688 91.7089 70.6962 91.5455 70.7084 91.3822C70.8187 89.9448 71.4067 88.5891 72.3826 87.5274L76.0414 83.546C76.6703 82.8559 77.5768 82.4883 78.5079 82.5414L96.6059 83.5541L93.7434 96.4457L80.472 94.2529ZM80.472 94.2529H80.468"
      stroke="#9A5C3E"
      strokeWidth="0.360164"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M78.9408 98.2421C78.8142 98.4055 78.6754 98.5606 78.5284 98.7117C77.6219 99.6427 76.1355 99.6877 75.1758 98.8097L72.7788 96.6128C72.1786 96.0656 71.6967 95.4122 71.3496 94.7017L74.3673 92.6477C75.2616 92.0393 76.4948 92.4027 76.9113 93.4032L78.9408 98.2421Z"
      fill="#D7ADA3"
      stroke="#9A5C3E"
      strokeWidth="0.306262"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M115.313 48.865L96.5611 64.9376L88.6881 53.8877L76.1314 51.3763L51.8387 53.4017C48.5801 53.6753 45.3868 52.483 43.1123 50.184C42.8346 49.9063 42.5732 49.6163 42.3282 49.306C42.0301 48.9344 41.8872 48.4934 41.8872 48.0605C41.8872 47.2765 42.3486 46.5129 43.1572 46.1903L56.306 40.9716L61.733 38.8196L81.6564 35.9734L115.313 48.865Z"
      fill="#ECC1AF"
    />
    <path
      d="M44.9626 45.4224L33.1123 50.1838C32.8346 49.9061 32.5732 49.6162 32.3282 49.3059C32.0301 48.9343 31.8872 48.4932 31.8872 48.0604C31.8872 47.2764 32.3486 46.5127 33.1572 46.1901L46.306 40.9714L46.4694 41.2777C47.3024 42.8335 46.6 44.7609 44.9626 45.4224Z"
      fill="#D7ADA3"
      stroke="#9A5C3E"
      strokeWidth="0.306262"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M88.7158 53.9056C89.2491 53.0607 89.889 52.2836 90.6212 51.6033C92.7503 49.6256 95.639 48.4868 98.5448 48.4802"
      stroke="#9A5C3E"
      strokeWidth="0.360164"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M66.0051 51.2299C67.1755 49.6966 67.4009 47.498 66.5658 45.7593"
      stroke="#9A5C3E"
      strokeWidth="0.360164"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M43.1559 46.1915L61.7334 38.8196L81.658 35.9734L115.312 48.8658L96.5595 64.9393L88.6902 53.8885L76.1326 51.3771L51.8407 53.4013C48.1864 53.706 44.6207 52.171 42.3298 49.3076C41.4878 48.2553 41.9031 46.6885 43.1559 46.1915Z"
      stroke="#9A5C3E"
      strokeWidth="0.360164"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M65.7427 65.7426C77.8455 53.6397 77.8455 34.017 65.7427 21.9142C53.6398 9.81126 34.0171 9.81126 21.9142 21.9142C9.81133 34.017 9.81133 53.6397 21.9142 65.7426C34.0171 77.8455 53.6398 77.8455 65.7427 65.7426Z"
      fill="#964529"
    />
    <path
      d="M67.0617 65.7426C79.1646 53.6397 79.1646 34.017 67.0617 21.9142C54.9589 9.81126 35.3362 9.81127 23.2333 21.9142C11.1304 34.017 11.1304 53.6397 23.2333 65.7426C35.3362 77.8455 54.9589 77.8455 67.0617 65.7426Z"
      fill="#F39C12"
      stroke="#C67E19"
      strokeWidth="0.247337"
      strokeMiterlimit="10"
    />
    <path
      d="M45.2786 12.8369V74.8203"
      stroke="#C67E19"
      strokeWidth="0.247337"
      strokeMiterlimit="10"
    />
    <path
      d="M76.2703 43.8286H14.2874"
      stroke="#C67E19"
      strokeWidth="0.247337"
      strokeMiterlimit="10"
    />
    <path
      d="M67.1931 21.9141L23.3645 65.743"
      stroke="#C67E19"
      strokeWidth="0.247337"
      strokeMiterlimit="10"
    />
    <path
      d="M67.1931 65.743L23.3645 21.9141"
      stroke="#C67E19"
      strokeWidth="0.247337"
      strokeMiterlimit="10"
    />
    <path
      d="M57.3177 15.2705L33.2402 72.3864"
      stroke="#C67E19"
      strokeWidth="0.247337"
      strokeMiterlimit="10"
    />
    <path
      d="M73.8366 55.8671L16.7212 31.7896"
      stroke="#C67E19"
      strokeWidth="0.247337"
      strokeMiterlimit="10"
    />
    <path
      d="M73.9849 32.1477L16.573 55.5094"
      stroke="#C67E19"
      strokeWidth="0.247337"
      strokeMiterlimit="10"
    />
    <path
      d="M56.9596 72.5346L33.5979 15.1223"
      stroke="#C67E19"
      strokeWidth="0.247337"
      strokeMiterlimit="10"
    />
    <path
      d="M51.2964 13.4265L39.2607 74.2302"
      stroke="#C67E19"
      strokeWidth="0.247337"
      strokeMiterlimit="10"
    />
    <path
      d="M75.6806 49.8465L14.877 37.8108"
      stroke="#C67E19"
      strokeWidth="0.247337"
      strokeMiterlimit="10"
    />
    <path
      d="M71.0312 26.5864L19.5261 61.0703"
      stroke="#C67E19"
      strokeWidth="0.247337"
      strokeMiterlimit="10"
    />
    <path
      d="M62.5208 69.581L28.0369 18.0759"
      stroke="#C67E19"
      strokeWidth="0.247337"
      strokeMiterlimit="10"
    />
    <path
      d="M62.6335 18.1519L27.9238 69.5051"
      stroke="#C67E19"
      strokeWidth="0.247337"
      strokeMiterlimit="10"
    />
    <path
      d="M70.9557 61.1836L19.6021 26.4739"
      stroke="#C67E19"
      strokeWidth="0.247337"
      strokeMiterlimit="10"
    />
    <path
      d="M75.7067 37.9438L14.8508 49.7129"
      stroke="#C67E19"
      strokeWidth="0.247337"
      strokeMiterlimit="10"
    />
    <path
      d="M51.1633 74.2564L39.3943 13.4009"
      stroke="#C67E19"
      strokeWidth="0.247337"
      strokeMiterlimit="10"
    />
    <path
      d="M42.376 12.9731L48.1815 74.6837"
      stroke="#C67E19"
      strokeWidth="0.806817"
      strokeMiterlimit="10"
    />
    <path
      d="M76.1343 40.9255L14.4233 46.731"
      stroke="#C67E19"
      strokeWidth="0.806817"
      strokeMiterlimit="10"
    />
    <path
      d="M65.0445 19.9575L25.5134 67.6989"
      stroke="#C67E19"
      strokeWidth="0.806817"
      strokeMiterlimit="10"
    />
    <path
      d="M69.1496 63.5938L21.4082 24.0627"
      stroke="#C67E19"
      strokeWidth="0.806817"
      strokeMiterlimit="10"
    />
    <path
      d="M54.5897 14.2688L35.9678 73.3888"
      stroke="#C67E19"
      strokeWidth="0.806817"
      strokeMiterlimit="10"
    />
    <path
      d="M74.8386 53.1391L15.719 34.5171"
      stroke="#C67E19"
      strokeWidth="0.806817"
      strokeMiterlimit="10"
    />
    <path
      d="M72.7646 29.5103L17.793 58.1466"
      stroke="#C67E19"
      strokeWidth="0.806817"
      strokeMiterlimit="10"
    />
    <path
      d="M59.597 71.3141L30.9607 16.3425"
      stroke="#C67E19"
      strokeWidth="0.806817"
      strokeMiterlimit="10"
    />
    <path
      d="M48.4225 12.9966L42.1348 74.6602"
      stroke="#C67E19"
      strokeWidth="0.806817"
      strokeMiterlimit="10"
    />
    <path
      d="M76.1107 46.9726L14.447 40.6848"
      stroke="#C67E19"
      strokeWidth="0.806817"
      strokeMiterlimit="10"
    />
    <path
      d="M69.303 24.2502L21.2542 63.4069"
      stroke="#C67E19"
      strokeWidth="0.806817"
      strokeMiterlimit="10"
    />
    <path
      d="M64.8568 67.853L25.7002 19.8042"
      stroke="#C67E19"
      strokeWidth="0.806817"
      strokeMiterlimit="10"
    />
    <path
      d="M60.1526 16.6389L30.4055 71.0176"
      stroke="#C67E19"
      strokeWidth="0.806817"
      strokeMiterlimit="10"
    />
    <path
      d="M72.4683 58.7019L18.0896 28.9548"
      stroke="#C67E19"
      strokeWidth="0.806817"
      strokeMiterlimit="10"
    />
    <path
      d="M75.0216 35.1201L15.5361 52.537"
      stroke="#C67E19"
      strokeWidth="0.806817"
      strokeMiterlimit="10"
    />
    <path
      d="M53.9877 73.5713L36.5703 14.0854"
      stroke="#C67E19"
      strokeWidth="0.806817"
      strokeMiterlimit="10"
    />
    <path
      d="M72.4221 50.0702C75.8705 35.0095 66.4569 20.0048 51.3962 16.5564C36.3355 13.1079 21.3309 22.5215 17.8824 37.5822C14.4339 52.6429 23.8475 67.6476 38.9083 71.096C53.969 74.5445 68.9736 65.1309 72.4221 50.0702Z"
      fill="#FFB600"
    />
    <path
      d="M72.4221 50.0702C75.8705 35.0095 66.4569 20.0048 51.3962 16.5564C36.3355 13.1079 21.3309 22.5215 17.8824 37.5822C14.4339 52.6429 23.8475 67.6476 38.9083 71.096C53.969 74.5445 68.9736 65.1309 72.4221 50.0702Z"
      stroke="#99601F"
      strokeWidth="0.247337"
      strokeMiterlimit="10"
    />
    <path
      d="M27.6052 63.127C22.3604 58.3563 19.0679 51.4768 19.0679 43.8284C19.0679 29.4243 30.7446 17.7476 45.1487 17.7476C59.5529 17.7476 71.2296 29.4243 71.2296 43.8284C71.2296 52.7149 66.7851 60.5634 59.998 65.2721"
      stroke="#99601F"
      strokeWidth="0.605133"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.5119 61.0982C23.9849 56.7362 21.1689 50.611 21.1689 43.8283C21.1689 30.5847 31.9053 19.8484 45.1489 19.8484C58.3924 19.8484 69.1288 30.5847 69.1288 43.8283C69.1288 51.8556 65.1845 58.9617 59.1283 63.3143"
      stroke="#99601F"
      strokeWidth="0.403408"
      strokeMiterlimit="10"
    />
    <path
      d="M69.8066 25.8313L70.9847 27.9853L73.1387 29.1634L70.9847 30.3415L69.8066 32.4952L68.6289 30.3415L66.4749 29.1634L68.6289 27.9853L69.8066 25.8313Z"
      fill="#FCFCFC"
      stroke="#FF9843"
      strokeWidth="0.286008"
      strokeMiterlimit="10"
    />
    <path
      d="M63.3992 31.8008L64.3314 33.506L66.0367 34.4387L64.3314 35.3714L63.3992 37.0762L62.4665 35.3714L60.7612 34.4387L62.4665 33.506L63.3992 31.8008Z"
      fill="#FCFCFC"
      stroke="#FF9843"
      strokeWidth="0.286008"
      strokeMiterlimit="10"
    />
    <path
      d="M62.2685 16.6479L63.9962 19.8065L67.1544 21.5338L63.9962 23.2612L62.2685 26.4198L60.5411 23.2612L57.3826 21.5338L60.5411 19.8065L62.2685 16.6479Z"
      fill="#FCFCFC"
      stroke="#FF9843"
      strokeWidth="0.286008"
      strokeMiterlimit="10"
    />
    <path
      d="M171 188.387L150.259 188.076L125.009 187.692L112.228 136.35C112.228 136.35 107.121 132.406 101.081 124.276C95.0409 116.146 95.7359 108.479 95.7359 108.479C95.7359 108.479 95.9675 106.388 90.3967 99.6477C84.8203 92.9128 82.498 84.0875 82.498 84.0875L83.385 82.4378L103.867 44.3621L115.379 48.5656C117.343 49.2827 118.844 50.8981 119.414 52.9096L136.619 113.587L171 188.387Z"
      fill="#ECC1AF"
    />
    <path
      d="M150.259 188.076L125.009 187.692L112.228 136.35C112.228 136.35 107.121 132.406 101.081 124.276C95.0409 116.146 95.7359 108.478 95.7359 108.478C95.7359 108.478 95.9674 106.388 90.3967 99.6474C84.8203 92.9125 82.498 84.0872 82.498 84.0872L83.385 82.4375C86.713 86.9461 89.9729 91.5114 93.8375 95.5569C103.098 105.264 116.059 112.366 120.738 124.937C123.314 131.864 122.947 139.565 125.071 146.639C128.738 158.849 139.179 167.606 146.259 178.206C148.225 181.138 149.942 184.652 150.259 188.076Z"
      fill="#DEAF9F"
    />
    <path
      d="M93 61.8953C98.1975 62.0109 103.404 59.8095 106.942 56"
      stroke="#9A5C3E"
      strokeWidth="0.360164"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M48.9132 33.3046L48.9138 33.3038C49.7935 32.1204 51.2355 31.4007 52.7163 31.4007H59.4149V51.7351H42.0977C41.7824 51.7351 41.5199 51.7665 41.2849 51.8484C41.0472 51.9312 40.8496 52.0614 40.6582 52.2419C40.4698 52.4195 40.285 52.6484 40.0735 52.9293C39.9761 53.0586 39.8716 53.201 39.7576 53.3563L39.7258 53.3996C39.5995 53.5718 39.4609 53.7602 39.3057 53.9667C38.3686 55.1541 36.9245 55.8725 35.4455 55.8725H28.7886V35.5381H46.0779C46.3857 35.5381 46.6482 35.5026 46.8876 35.4163C47.1283 35.3295 47.3348 35.1955 47.5356 35.013C47.8577 34.7203 48.1824 34.2844 48.6221 33.6941C48.7138 33.571 48.8105 33.4412 48.9132 33.3046ZM33.78 50.6897V50.8939H33.9842H40.1633C40.469 50.8939 40.7283 50.8622 40.9644 50.7807C41.2025 50.6986 41.4058 50.5696 41.6048 50.39C41.9227 50.1031 42.2467 49.6681 42.6975 49.0631C42.7918 48.9365 42.8916 48.8026 42.9981 48.6609C43.9309 47.4382 45.33 46.7981 46.8573 46.7981H54.1775H54.1915H54.3956V46.5939V36.6391V36.4349H54.1915H48.0402C47.7435 36.4349 47.4873 36.4677 47.2504 36.5493C47.0122 36.6312 46.8036 36.7587 46.5975 36.9352C46.2345 37.2461 45.8617 37.7266 45.336 38.4042C45.281 38.4751 45.2243 38.5482 45.1657 38.6235C44.2281 39.8125 42.8249 40.5307 41.3045 40.5307H33.9842H33.78V40.7348V50.6897Z"
      fill="#F39C12"
      stroke="#C67E19"
      strokeWidth="0.408349"
    />
    <path
      d="M36.2051 67.3226L37.1958 62.9794L38.8584 63.3587C39.1723 63.4303 39.4218 63.5393 39.6071 63.6856C39.7941 63.8309 39.9198 64.0001 39.9843 64.1933C40.0502 64.3869 40.0587 64.5911 40.0097 64.806C39.9694 64.9827 39.9009 65.1262 39.8044 65.2366C39.7081 65.3455 39.5939 65.4258 39.4616 65.4774C39.3294 65.5291 39.1887 65.5557 39.0396 65.5574L39.0299 65.5998C39.1821 65.6435 39.319 65.7245 39.4407 65.843C39.5641 65.9604 39.6534 66.1072 39.7084 66.2833C39.7634 66.4595 39.7657 66.6579 39.7154 66.8784C39.6642 67.1032 39.5636 67.293 39.4138 67.4477C39.2643 67.601 39.0682 67.7042 38.8255 67.7575C38.5828 67.8107 38.2954 67.7994 37.9631 67.7236L36.2051 67.3226ZM37.1418 66.8447L37.988 67.0377C38.2736 67.1028 38.4917 67.0953 38.6424 67.0151C38.7947 66.9339 38.8906 66.807 38.93 66.6345C38.9593 66.5059 38.9539 66.3827 38.9139 66.265C38.8741 66.1458 38.8023 66.0432 38.6985 65.9571C38.595 65.8695 38.462 65.8072 38.2994 65.7701L37.4321 65.5722L37.1418 66.8447ZM37.5612 65.006L38.3395 65.1835C38.4752 65.2145 38.6032 65.2177 38.7233 65.193C38.8438 65.1669 38.9456 65.1151 39.0288 65.0374C39.1137 64.9586 39.1707 64.8555 39.1997 64.7283C39.2381 64.5601 39.2103 64.408 39.1164 64.272C39.0238 64.1364 38.8617 64.0421 38.6298 63.9892L37.8345 63.8078L37.5612 65.006Z"
      fill="#99601F"
    />
    <path
      d="M41.0657 68.3662L41.2749 63.9164L42.9435 63.9948C43.2854 64.0109 43.5694 64.0838 43.7956 64.2134C44.0232 64.3432 44.1914 64.518 44.3001 64.738C44.4103 64.9566 44.4587 65.2078 44.4454 65.4917C44.432 65.7771 44.3595 66.0219 44.2279 66.2262C44.0979 66.4292 43.9129 66.5823 43.6728 66.6857C43.4329 66.7877 43.142 66.8306 42.8001 66.8145L41.6116 66.7587L41.6431 66.0895L42.7229 66.1402C42.9228 66.1496 43.0878 66.1298 43.2179 66.0808C43.348 66.0303 43.446 65.9528 43.5119 65.8485C43.5792 65.7428 43.6167 65.611 43.6241 65.4531C43.6315 65.2952 43.6067 65.159 43.5496 65.0446C43.4927 64.9287 43.4018 64.8388 43.2771 64.7748C43.1524 64.7094 42.9894 64.672 42.788 64.6626L42.0493 64.6278L41.8718 68.4041L41.0657 68.3662ZM43.4593 66.4579L44.4661 68.526L43.5665 68.4837L42.5793 66.4166L43.4593 66.4579Z"
      fill="#99601F"
    />
    <path
      d="M46.061 68.5265L45.5043 64.1067L48.3789 63.7447L48.4635 64.4158L46.3895 64.677L46.5406 65.8769L48.4657 65.6345L48.5502 66.3057L46.6252 66.5481L46.7771 67.7545L48.8683 67.4911L48.9529 68.1623L46.061 68.5265Z"
      fill="#99601F"
    />
    <path
      d="M50.0103 63.4513L51.4331 64.716L51.4663 64.7056L51.9204 62.8546L52.7986 62.5803L52.1527 65.1156L54.1477 66.8259L53.2549 67.1048L51.825 65.8537L51.7918 65.8641L51.3282 67.7067L50.4396 67.9842L51.1187 65.4386L49.1279 63.727L50.0103 63.4513Z"
      fill="#99601F"
    />
    <path
      d="M104.765 69.6296L88.8475 89.7367L86.3443 92.9014L70.1083 71.802L57.2739 62.3773L54.9341 60.6581L52.1246 58.5878C51.8714 58.404 51.6346 58.204 51.41 57.9957C50.3851 57.0361 49.6541 55.8233 49.2703 54.5002C48.9068 53.2507 48.8578 51.9072 49.1682 50.5841C49.3764 49.6899 49.8174 48.8854 50.4218 48.2361C51.0302 47.5828 51.802 47.0928 52.6841 46.8192L53.1986 46.6599C53.5702 46.5456 53.9459 46.4598 54.3256 46.4067C54.493 46.3781 54.6564 46.3618 54.8238 46.3496C55.3955 46.3006 55.9713 46.3169 56.5348 46.3945C57.0003 46.4598 57.4577 46.566 57.9028 46.713C58.3765 46.8682 58.8338 47.0683 59.2748 47.3173L80.1496 59.0778L80.1537 59.0819L104.765 69.6296Z"
      fill="#ECC1AF"
    />
    <path
      d="M88.8482 89.7371L86.3454 92.901L70.107 71.8037L55.5808 61.1306C63.005 62.1192 69.7905 66.7128 74.8814 72.007C80.0965 77.4315 84.2094 83.8161 88.8482 89.7371Z"
      fill="#DEAF9F"
    />
    <path
      d="M104.765 69.6255L80.1537 59.0819L80.1496 59.0778L59.2748 47.3173C58.8338 47.0683 58.3765 46.8682 57.9028 46.713C57.4577 46.566 57.0003 46.4598 56.5348 46.3945C55.9713 46.3169 55.3955 46.3006 54.8238 46.3496C54.6564 46.3618 54.489 46.3822 54.3256 46.4067C53.9418 46.4639 53.5661 46.5497 53.1945 46.664L52.6841 46.8192C51.802 47.0928 51.0302 47.5828 50.4218 48.2361C49.8174 48.8854 49.3764 49.6899 49.1682 50.5841C48.8578 51.9072 48.9068 53.2507 49.2703 54.5002C49.6541 55.8233 50.3851 57.0361 51.41 57.9957C51.6346 58.208 51.8714 58.4081 52.1246 58.5919L54.9341 60.6581L57.2739 62.3773L70.1042 71.8061L86.3443 92.9014"
      stroke="#9A5C3E"
      strokeWidth="0.360164"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M61.778 53.569L58.646 58.0894C57.9885 59.0409 56.694 59.2981 55.7263 58.6693L49.2703 54.5C48.9068 53.2505 48.8578 51.907 49.1682 50.5839C49.3764 49.6897 49.8174 48.8852 50.4218 48.2359C51.0302 47.5826 51.802 47.0926 52.6841 46.819L53.1986 46.6597C53.5702 46.5454 53.9459 46.4596 54.3256 46.4065C54.493 46.378 54.6564 46.3616 54.8238 46.3494L61.1981 50.5635C62.2027 51.2251 62.464 52.5808 61.778 53.569Z"
      fill="#D7ADA3"
      stroke="#9A5C3E"
      strokeWidth="0.306262"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
