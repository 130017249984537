import { object, string } from "yup";

export const dataSourceDirectMigration = object({
  customDate: string().when("replaceType", {
    is: "CUSTOM",
    then: string().required("Please select custom date"),
    otherwise: string().notRequired(),
  }),
  replaceType: string().required("Please select replace type"),
});
