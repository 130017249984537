import { Button } from "components/DesignSystem/Button/Button";
import { FileInput, FileType } from "components/FileInput/FileInput";
import { useFormikContext } from "formik";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { useHistory, useLocation } from "react-router-dom";
import { useUploadFileMutation } from "store/apis/documents";
import { useCreateWFormsMutation } from "store/apis/groupWForms";
import { useEditEntityDataAttributesMutation } from "store/apis/entity";
import { Preview } from "components/PreviewModal";
import { useModal } from "hooks/useModal";
import { ConfirmWFormDelete } from "./ConfirmDelete";
import { parse, stringify } from "qs";

export const AttachWform = () => {
  const [uploadFile, { isLoading }] = useUploadFileMutation();
  const { uuid: groupId, unsorted_folder_id } = useCurrentGroupContext();
  const { values, setFieldValue } = useFormikContext<{
    wform: { uuid: string };
  }>();
  const { alertToast, successToast } = useToast();
  const [createWForm] = useCreateWFormsMutation();
  const history = useHistory();
  const entityId = useCurrentEntityId();
  const [editEntityDataAttributes, { isLoading: isUpdating }] =
    useEditEntityDataAttributesMutation();
  const previewModal = useModal();
  const confirmDeleteModal = useModal();
  const location = useLocation();
  const parsedSearch = parse(location.search, { ignoreQueryPrefix: true });
  const search = stringify(
    {
      ...parsedSearch,
      is_send_invoice_open: true,
    },
    { skipNulls: true, addQueryPrefix: true }
  );
  const fullPath = encodeURIComponent(`${location.pathname}${search}`);

  const onDrop = async (files: FileType[]) => {
    try {
      const uploadedFiles = await uploadFile({
        files: [files[0]] as File[],
        groupId,
        parentFolderId: unsorted_folder_id,
      }).unwrap();

      const fileIds = uploadedFiles.map(({ uuid }) => uuid);
      await editEntityDataAttributes({
        groupId,
        entityId,
        payload: { w_form: fileIds },
      }).unwrap();
      setFieldValue("wform", uploadedFiles[0]);
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
    }
  };

  const deleteFile = async () => {
    try {
      await editEntityDataAttributes({
        groupId,
        entityId,
        payload: { w_form: [] },
      }).unwrap();
      setFieldValue("wform", null);
      successToast({
        message: "W-Form is also removed from current Entity",
      });
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
    }
    confirmDeleteModal.close();
  };

  const createCompanyWForm = async () => {
    try {
      const { uuid } = await createWForm({ groupId, entityId }).unwrap();
      history.push(
        `/contractor-form/${uuid}?companywform=true&redirect_to=${fullPath}`
      );
    } catch (e: any) {
      alertToast({ message: e?.response?.data?.error?.message });
    }
  };

  const openPreview = () => {
    if (values.wform?.uuid) {
      previewModal.open();
    }
  };

  return (
    <div>
      <span onClick={openPreview}>
        <FileInput
          name="wform"
          withForm
          label="W-Form"
          multiple={false}
          onDelete={confirmDeleteModal.open}
          onDrop={onDrop}
          isUploading={isLoading || isUpdating}
          isDeleting={isLoading || isUpdating}
          shouldUpdateFieldOnDelete={false}
          size="large"
        />
      </span>
      {!values?.wform?.uuid && (
        <div className="t-flex t-justify-between t-items-center t-bg-purple-0 t-rounded t-p-1 t-pl-3 t-border t-border-solid t-border-purple-10 t-mt-3">
          <div className="t-text-body t-text-purple-100">
            Don’t have a W-form? Inkle can help
          </div>
          <Button size="small" onClick={createCompanyWForm} type="button">
            Generate
          </Button>
        </div>
      )}

      <Preview
        showModal={previewModal.isOpen}
        closeModal={previewModal.close}
        groupId={groupId}
        previewId={values.wform?.uuid}
      />
      <ConfirmWFormDelete
        {...confirmDeleteModal}
        onClick={deleteFile}
        isDeleting={isUpdating}
      />
    </div>
  );
};
