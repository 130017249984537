import React from "react";

export const InkleCommunityLogo = () => {
  return (
    <svg
      width="214"
      height="25"
      viewBox="0 0 214 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 0H10.5C16.0228 0 20.5 4.47715 20.5 10H10.5C4.97715 10 0.5 5.52285 0.5 0Z"
        fill="url(#paint0_linear_8954_2188)"
      />
      <path
        d="M0.5 10H10.5C16.0228 10 20.5 14.4772 20.5 20H10.5C4.97715 20 0.5 15.5228 0.5 10Z"
        fill="url(#paint1_linear_8954_2188)"
      />
      <path
        d="M30.4998 2.06497C30.4998 0.812065 31.2798 0 32.5055 0C33.709 0 34.489 0.812065 34.489 2.06497C34.489 3.31786 33.709 4.15313 32.5055 4.15313C31.2798 4.15313 30.4998 3.31786 30.4998 2.06497ZM34.2884 19.884H30.7226V5.96288H34.2884V19.884Z"
        fill="url(#paint2_linear_8954_2188)"
      />
      <path
        d="M45.2287 12.9002C45.2287 10.8585 44.2035 9.51276 42.6212 9.51276C41.128 9.51276 40.0359 10.9513 40.0359 12.9234V19.884H36.4701V5.96288H40.0359V7.58701C40.504 6.54292 41.8857 5.80046 43.2675 5.80046C46.6773 5.80046 48.7945 8.63109 48.7945 12.9002V19.884H45.2287V12.9002Z"
        fill="url(#paint3_linear_8954_2188)"
      />
      <path
        d="M54.5117 19.884H50.9458V0.162412H54.5117V12.2042L59.0358 5.96288H62.9359L58.3895 12.9234L63.4039 19.884H58.8798L54.5117 13.6427V19.884Z"
        fill="url(#paint4_linear_8954_2188)"
      />
      <path
        d="M68.3046 19.884H64.7388V0.162412H68.3046V19.884Z"
        fill="url(#paint5_linear_8954_2188)"
      />
      <path
        d="M79.1459 15.6148H82.9346C82.0208 18.2135 79.6362 20 76.9173 20C73.0171 20 70.2759 17.0534 70.2759 12.877C70.2759 8.83991 73.1063 5.77726 76.8504 5.77726C80.5945 5.77726 83.4249 8.83991 83.4249 12.877C83.4249 13.3179 83.4026 13.7819 83.3357 14.1995H74.02C74.4212 15.6148 75.5132 16.5197 76.895 16.5197C77.9424 16.5197 78.767 16.1485 79.1459 15.6148ZM74.02 11.5313H79.6808C79.2796 10.1624 78.2322 9.28074 76.8504 9.28074C75.4686 9.28074 74.4212 10.1624 74.02 11.5313Z"
        fill="url(#paint6_linear_8954_2188)"
      />
      <path
        d="M213.815 7.36621L208.387 21.0815C207.454 23.4433 206.874 24.5828 204.388 24.5828H203.621V22.3867H204.181C205.072 22.3867 205.652 22.0138 206.128 20.8121L206.501 19.797L201.57 7.36621H203.953L207.703 16.8136L211.453 7.36621H213.815Z"
        fill="#41395C"
      />
      <path
        d="M195.812 4.73438H197.49V7.36555H200.411V9.47878H197.49V15.487C197.49 16.9579 198.091 17.6002 199.5 17.6002H200.411V19.7963H199.293C196.454 19.7963 195.252 18.5947 195.252 15.6113V9.47878H193.326V7.36555H195.252L195.812 4.73438Z"
        fill="#41395C"
      />
      <path
        d="M189.026 3.63649C189.026 2.84921 189.544 2.31055 190.373 2.31055C191.16 2.31055 191.678 2.84921 191.678 3.63649C191.678 4.44449 191.16 4.98316 190.373 4.98316C189.544 4.98316 189.026 4.44449 189.026 3.63649ZM191.492 19.7965H189.254V7.36572H191.492V19.7965Z"
        fill="#41395C"
      />
      <path
        d="M184.832 13.5614C184.832 11.0545 183.548 9.4385 181.559 9.4385C179.715 9.4385 178.389 11.1788 178.389 13.5821V19.7975H176.151V7.36671H178.389V9.68711C178.865 8.25758 180.274 7.22168 181.932 7.22168C185.081 7.22168 187.07 9.72855 187.07 13.5614V19.7975H184.832V13.5614Z"
        fill="#41395C"
      />
      <path
        d="M165.477 13.6023C165.477 16.1092 166.762 17.7045 168.751 17.7045C170.594 17.7045 171.92 15.9849 171.92 13.5816V7.36621H174.158V19.797H171.92V17.4766C171.444 18.9061 170.035 19.942 168.378 19.942C165.228 19.942 163.24 17.4351 163.24 13.6023V7.36621H165.477V13.6023Z"
        fill="#41395C"
      />
      <path
        d="M150.419 13.5614C150.419 11.0545 149.134 9.4385 147.145 9.4385C145.301 9.4385 143.975 11.1788 143.975 13.5821V19.7975H141.738V7.36671H143.975V9.68711C144.452 8.25758 145.861 7.22168 147.518 7.22168C149.507 7.22168 151.04 8.23686 151.89 9.93573C152.698 8.21614 154.107 7.22168 155.888 7.22168C159.224 7.22168 161.337 9.72855 161.337 13.5614V19.7975H159.1V13.5614C159.1 11.0545 157.815 9.4385 155.805 9.4385C153.982 9.4385 152.656 11.1788 152.656 13.5614V19.7975H150.419V13.5614Z"
        fill="#41395C"
      />
      <path
        d="M128.669 13.5614C128.669 11.0545 127.385 9.4385 125.396 9.4385C123.552 9.4385 122.226 11.1788 122.226 13.5821V19.7975H119.988V7.36671H122.226V9.68711C122.702 8.25758 124.111 7.22168 125.769 7.22168C127.758 7.22168 129.291 8.23686 130.14 9.93573C130.948 8.21614 132.357 7.22168 134.139 7.22168C137.474 7.22168 139.588 9.72855 139.588 13.5614V19.7975H137.35V13.5614C137.35 11.0545 136.066 9.4385 134.056 9.4385C132.233 9.4385 130.907 11.1788 130.907 13.5614V19.7975H128.669V13.5614Z"
        fill="#41395C"
      />
      <path
        d="M105.912 13.5614C105.912 9.97716 108.564 7.22168 112.003 7.22168C115.422 7.22168 118.053 9.97716 118.053 13.5614C118.053 17.1663 115.422 19.9218 112.003 19.9218C108.564 19.9218 105.912 17.1663 105.912 13.5614ZM112.003 9.4385C109.828 9.4385 108.15 11.2202 108.15 13.5614C108.15 15.9232 109.828 17.6842 112.003 17.6842C114.179 17.6842 115.836 15.9232 115.836 13.5614C115.836 11.2202 114.179 9.4385 112.003 9.4385Z"
        fill="#41395C"
      />
      <path
        d="M101.986 8.27767C101.095 5.85367 99.1682 4.40341 96.6406 4.40341C93.2014 4.40341 90.9224 7.03459 90.9224 10.9917C90.9224 14.9281 93.2221 17.5593 96.6406 17.5593C99.2096 17.5593 101.199 16.0883 101.986 13.7058H104.389C103.498 17.5179 100.556 19.9004 96.6613 19.9004C91.8754 19.9004 88.6848 16.3162 88.6848 10.9917C88.6848 5.64649 91.8754 2.08301 96.6613 2.08301C100.577 2.08301 103.415 4.46557 104.389 8.27767H101.986Z"
        fill="#41395C"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8954_2188"
          x1="0.5"
          y1="5"
          x2="20.5"
          y2="5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8954_2188"
          x1="0.5"
          y1="15"
          x2="20.5"
          y2="15"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_8954_2188"
          x1="30.4998"
          y1="10"
          x2="83.4249"
          y2="10"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_8954_2188"
          x1="30.4998"
          y1="10"
          x2="83.4249"
          y2="10"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_8954_2188"
          x1="30.4998"
          y1="10"
          x2="83.4249"
          y2="10"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_8954_2188"
          x1="30.4998"
          y1="10"
          x2="83.4249"
          y2="10"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_8954_2188"
          x1="30.4998"
          y1="10"
          x2="83.4249"
          y2="10"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
