import { Badge } from "components/design/badge";
import ConditionalToolTip from "components/design/conditionalToolTip";
import ToolTip from "components/design/toolTip";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import { Search } from "components/DesignSystem/Search/Search";
import Table from "components/DesignSystem/Table/V2/Table";
import { PREINCORPORATION } from "constants/onBoarding";
import { CRM_SUBSCRIPTION_TYPE } from "dictionaries";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { EmptyScreen } from "pages/Books/EmptyScreen";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import CrossRed from "static/images/CrossRed.svg";
import GreenTick from "static/images/GreenTick.svg";
import {
  useGetAllSalesCRMGroupsQuery,
  useUpdateGroupDataMutation,
} from "store/apis/salesCrm";
import { CompanyGroup } from "types/Models/groups";
import OpsStage from "./OpsStage";
import SalesCrmStage from "./salesCrmStage";
import { usePageTitle } from "hooks/usePageTitle";
import { CrmUnarchiveAction } from "components/CrmTableDropdown/CrmUnarchiveActionDropdown";
import { useDebounce } from "utils/debounce";
import { SolidCheck } from "components/icons/SolidCheck";
import { SolidCross } from "components/icons/SolidCross";
import { Link } from "components/DesignSystem/Link/Link";

export const ArchivedGroupCrm = () => {
  usePageTitle("Archived Groups");
  const query = useQuery();
  const { update } = useUpdateQuery();
  const searchValue = query.get("search");
  const searchTerm = useDebounce(searchValue);
  const pageNumber = Number(query.get("page")) || 1;

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    update({ query: "search", value: e.target.value || null });
  };

  const {
    data: groups,
    isLoading,
    isSuccess,
  } = useGetAllSalesCRMGroupsQuery({
    pageNumber: pageNumber,
    searchTerm,
    viewFilter: "SALES_CRM",
    archived: true,
  });

  const {
    company_groups = [],
    total_pages = 1,
    total_count = 1,
    current_page = 1,
    per_page = 1,
  } = groups || {};

  const paginationData = {
    totalPage: total_pages,
    currentPage: current_page,
    itemsPerPage: per_page,
    totalItemCount: total_count,
  };

  const goToFirstPage = () => {
    update({ query: "page", value: 1 });
  };

  const goToPrevPage = () => {
    const localCurrentPage =
      pageNumber < total_pages ? pageNumber : total_pages;
    update({ query: "page", value: localCurrentPage - 1 });
  };

  const goToNextPage = () => {
    if (pageNumber < total_pages) {
      update({ query: "page", value: pageNumber + 1 });
    }
  };
  const goToLastPage = () => {
    update({ query: "page", value: total_pages });
  };

  const columnHelper = createColumnHelper<CompanyGroup>();
  const [updateGroup] = useUpdateGroupDataMutation();

  const columns = [
    columnHelper.accessor("name", {
      header: "Group Name",
      size: 20,
      cell: ({ row }) => (
        <>
          {row.original?.name ? (
            <span className="t-flex t-gap-2 t-truncate">
              <ToolTip side="top" text={row.original?.name}>
                <span>
                  <Link
                    to={`/admin/crm/${row.original.uuid}`}
                    target="_blank"
                    className="t-truncate"
                  >
                    {row.original?.name}
                  </Link>
                </span>
              </ToolTip>
              {row.original.subscription_name &&
                row.original.subscription_name !== "NOT_STARTED" && (
                  <Badge
                    size="small"
                    color={
                      // @ts-ignore
                      CRM_SUBSCRIPTION_TYPE[row.original.subscription_name]
                    }
                  >
                    {row.original.subscription_name?.[0]}
                  </Badge>
                )}
              {row.original?.is_created_from_crm && (
                <ToolTip text="Group created from Inkle">
                  <div className="t-flex t-h-6 t-w-5 t-items-center t-justify-center t-rounded t-bg-purple-50 t-align-middle t-text-subtitle t-text-white">
                    i
                  </div>
                </ToolTip>
              )}
              {row.original?.founder?.user_type === PREINCORPORATION && (
                <ToolTip text="Pre-incorporation user">
                  <div className="t-rounded t-px-2 t-py-1 t-text-subtext-sm t-text-blue-70 t-bg-blue-10">
                    Pre
                  </div>
                </ToolTip>
              )}
            </span>
          ) : (
            <span aria-hidden={true}>{row?.original?.name}</span>
          )}
        </>
      ),
    }),
    columnHelper.accessor("founder", {
      id: "leadFounder",
      header: "Lead Founder",
      size: 20,
      cell: ({ row }) => {
        const { founder } = row.original;
        const emptyName = !founder?.first_name && !founder?.last_name && "-";
        return (
          <ConditionalToolTip condition={founder?.email}>
            <span className="t-truncate">
              {founder?.first_name || ""} {founder?.last_name || ""}
              {emptyName}
            </span>
          </ConditionalToolTip>
        );
      },
    }),
    columnHelper.display({
      id: "sales",
      header: () => <p className="t-m-0 t-text-center">Sales</p>,
      size: 20,
      cell: ({ row }) => {
        const { sales_stage, uuid } = row.original;

        return (
          <SalesCrmStage
            currentStage={sales_stage!}
            groupId={uuid}
            updateSalesStage={updateGroup}
          />
        );
      },
    }),
    columnHelper.display({
      id: "Ops",
      header: () => <p className="t-m-0 t-text-center">Ops</p>,
      size: 20,
      cell: ({ row }) => {
        const { ops_stage, uuid } = row.original;
        return (
          <OpsStage
            currentStage={ops_stage}
            groupId={uuid}
            updateSalesStage={updateGroup}
          />
        );
      },
    }),
    columnHelper.display({
      id: "status",
      header: () => <p className="t-m-0 t-text-center">Status</p>,
      size: 20,
      cell: ({ row }) => {
        const { is_restricted, is_rejected } = row.original;

        if (is_rejected) {
          return <img src={CrossRed} alt="rejected" />;
        }

        if (is_restricted) {
          return (
            <div className="t-flex t-gap-2">
              <Button customType="icon" onClick={() => {}}>
                <SolidCheck size="24" />
              </Button>
              <Button customType="icon" onClick={() => {}}>
                <SolidCross size="24" />
              </Button>
            </div>
          );
        }

        if (!is_restricted && !is_rejected) {
          return (
            <div className="t-flex t-justify-center">
              <SolidCheck size="24" />
            </div>
          );
        }
      },
    }),
    columnHelper.display({
      id: "threedots",
      header: "",
      size: 5,
      cell: (info) => {
        const { uuid, name } = info.row.original;
        return <CrmUnarchiveAction actionOn={"group"} uuid={uuid} />;
      },
    }),
  ];

  const table = useReactTable({
    data: company_groups,
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 1,
    },
  });

  const isEmpty = company_groups.length === 0;

  return (
    <>
      <div className="t-pb-5 t-flex t-justify-between t-items-center">
        <div className="t-w-1/2">
          <Search
            block
            onChange={onSearch}
            value={searchValue || ""}
            placeholder="Search"
          />
        </div>
        <Pagination
          {...paginationData}
          goToFirstPage={goToFirstPage}
          goToPrevPage={goToPrevPage}
          goToNextPage={goToNextPage}
          goToLastPage={goToLastPage}
        />
      </div>
      <Async.Root {...{ isLoading, isEmpty, isSuccess }}>
        <Async.Empty>
          <EmptyScreen text="No groups found" />
        </Async.Empty>
        <Async.Success>
          <>
            <Table.Container layout="fixed">
              <Table.Content>
                <Table.Head>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <Table.Row key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <Table.HeadCell
                          key={header.id}
                          style={{ width: `${header.getSize()}%` }}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </Table.HeadCell>
                      ))}
                    </Table.Row>
                  ))}
                </Table.Head>
                <Table.Body>
                  {table.getRowModel().rows.map((row) => (
                    <Table.Row key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <Table.Cell
                          key={cell.id}
                          style={{ width: `${cell.column.getSize()}%` }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Table.Cell>
                      ))}
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table.Content>
            </Table.Container>
          </>
        </Async.Success>
      </Async.Root>
    </>
  );
};
