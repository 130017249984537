import {
  Combobox,
  OptionData,
} from "components/DesignSystem/Combobox/Combobox";
import { getIn, useFormikContext } from "formik";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { ReactNode, useMemo } from "react";
import { useGetAllAccountQuery } from "store/apis/transactions";
import { SourceFilter } from "types/Models/books";
import { components, PropsValue } from "react-select";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { formatOrdinals } from "utils/formatOrdinals";

export const SourceValue = ({
  name,
  label,
  index,
}: // defaultValue,
{
  name: string;
  label: string;
  index: number;
  // defaultValue?: PropsValue<OptionData>;
}) => {
  const { setFieldValue, values } = useFormikContext();
  const group = useCurrentGroupContext();
  const { uuid: groupId } = group;
  const entityId = useCurrentEntityId();

  const { data: banks = [] } = useGetAllAccountQuery(
    {
      groupId,
      entityId,
      account_type: "EXCLUDE_STRIPE",
    },
    { skip: !groupId || !entityId }
  );

  const accountsWithBank = useMemo(
    () =>
      banks.reduce((acc, cc) => {
        if (!acc[cc.brand_name]) {
          acc[cc.brand_name] = [];
        }
        acc[cc.brand_name].push(cc);
        return acc;
      }, {} as Record<string, SourceFilter[]>),
    [banks.length]
  );

  const currentValues = getIn(values, name) as string[];

  const onBankClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name: fieldName } = e.target;

    const accounts = accountsWithBank[fieldName].map((v) => v.uuid);

    if (checked) {
      const newValues = new Set([...currentValues, ...accounts]);
      setFieldValue(name, Array.from(newValues));
    } else {
      setFieldValue(
        name,
        Array.from(new Set(currentValues.filter((v) => !accounts.includes(v))))
      );
    }
  };

  return (
    <Combobox
      aria-label={`${formatOrdinals(index + 1)} Value`}
      styles={{
        menu: (provided) => ({
          ...provided,
          width: "300px",
        }),
      }}
      isMulti
      value={
        currentValues.length
          ? currentValues.map((v) => {
              const account = banks.find((b) => b.uuid === v);

              return {
                label: account?.nickname || v,
                value: account?.uuid || v,
              };
            })
          : []
      }
      // defaultValue={finalDefaultValue}
      onChange={(value) => {
        if (value instanceof Array) {
          setFieldValue(
            name,
            value.map((v) => v.value)
          );
        }
      }}
      components={{
        GroupHeading: (props) => (
          <components.GroupHeading {...props}>
            <label
              onClick={(e) => e.preventDefault()}
              htmlFor={props.data.label}
              className="t-px-3 t-py-2.5 t-flex t-gap-2.5"
            >
              <Checkbox
                checked={accountsWithBank[props.data.label!].every((v) =>
                  currentValues.includes(v.uuid)
                )}
                indeterminate={
                  accountsWithBank[props.data.label!].some((v) =>
                    currentValues.includes(v.uuid)
                  ) &&
                  !accountsWithBank[props.data.label!].every((v) =>
                    currentValues.includes(v.uuid)
                  )
                }
                id={props.data.label}
                name={props.data.label}
                onChange={onBankClick}
              />
              {props.children}
            </label>
          </components.GroupHeading>
        ),
      }}
      multiValueShowLimit={1}
      key={name}
      label={label}
      name={name}
      required
      placeholder="Select"
      menuPortalTarget={document.body}
      options={Object.entries(accountsWithBank).map(([key, value]) => ({
        label: key,
        options: value.map((v) => ({
          label: v.nickname,
          value: v.uuid,
          indent: 1,
        })),
      }))}
      block
    />
  );
};
