import Loader from "components/design/loader";
import {
  Combobox,
  OptionData,
} from "components/DesignSystem/Combobox/Combobox";
import { SelectAutofillCombobox } from "components/SelectAutofillCombobox";
import { Form, Formik } from "formik";
import { useBillingtag } from "hooks/useBillingtag";
import { useCurrentGroup } from "hooks/useCurrentGroup";
import { useToast } from "hooks/useToast";
import { MultiValue, SingleValue } from "react-select";
import {
  useGetAutofillQuery,
  useUpdateAutofillMutation,
} from "store/apis/autofill";
import {
  useGetBillingInfoQuery,
  usePatchBillingEmailMutation,
} from "store/apis/billing";
import { AddressAutofill } from "types/Models/addressAutofill";
import { debounce } from "utils/debouncing";
import { BillingAddress } from "./EditBillingAddress";

export const PaymentBillingAddress = ({
  defaultAddress,
  entityId,
}: {
  defaultAddress?: AddressAutofill;
  entityId: string;
}) => {
  const { uuid: tagId } = useBillingtag();
  const group = useCurrentGroup();
  const { successToast, alertToast } = useToast();

  const { data: billingInfo, isLoading: isGettingBilling } =
    useGetBillingInfoQuery(
      {
        group_id: group?.uuid!,
        entityId,
      },
      { skip: !group?.uuid || !entityId }
    );
  const { data: addressAutofills = [], isLoading: isGettingAddress } =
    useGetAutofillQuery(
      {
        groupId: group?.uuid!,
        autofillKey: "addresses",
        entityId,
      },
      { skip: !group?.uuid || !entityId }
    );
  const [editBillingInfo] = usePatchBillingEmailMutation();
  const [updateAutofill] = useUpdateAutofillMutation();

  const autofillAddress: BillingAddress = defaultAddress || {
    country: "",
    street_address: "",
    state: "",
    city: "",
    zipcode: "",
    entity_id: "",
  };

  const editEmail = async ({
    invoice_emails,
  }: {
    invoice_emails: string[];
  }) => {
    try {
      await editBillingInfo({
        invoice_emails: invoice_emails,
        group_id: group.uuid!,
        entityId,
      }).unwrap();
      successToast({ message: "Billing email updated successfully!" });
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
    }
  };

  const editAddress = debounce(async (addressId: string) => {
    try {
      const autofill = addressAutofills.find((a) => a.uuid === addressId);
      if (autofill?.uuid) {
        await updateAutofill({
          entityId,
          fields: {
            ...autofill,
            tag_id: tagId,
            group_entity_id: entityId,
          },
          groupId: group.uuid!,
          uuid: autofill?.uuid,
          autofillKey: "addresses",
        }).unwrap();

        successToast({ message: "Billing address updated successfully!" });
      }
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
    }
  });

  if (isGettingBilling || isGettingAddress) {
    return (
      <div className="t-h-12">
        <Loader />
      </div>
    );
  }

  return (
    <Formik
      validateOnChange={false}
      initialValues={{
        uuid: autofillAddress.uuid,
        invoice_emails: billingInfo?.invoice_emails || [],
      }}
      onSubmit={() => {}}
    >
      {({ values }) => {
        return (
          <Form className="t-m-0">
            <div className="t-flex t-flex-col t-gap-4">
              <Combobox
                placeholder="Add emails"
                formatCreateLabel={(inputText) => `Add ${inputText}`}
                isMulti
                label="Billing emails"
                withForm
                name="invoice_emails"
                creatable
                components={{
                  DropdownIndicator: () => null,
                  ClearIndicator: () => null,
                }}
                onCreateOption={(value) =>
                  editEmail({
                    invoice_emails: [...(values.invoice_emails || []), value],
                  })
                }
                value={billingInfo?.invoice_emails?.map((i) => ({
                  label: i,
                  value: i,
                }))}
                menuPortalTarget={document.body}
                onChange={(
                  selectedOption:
                    | MultiValue<OptionData>
                    | SingleValue<OptionData>
                ) =>
                  editEmail({
                    invoice_emails:
                      (selectedOption as MultiValue<OptionData>).map(
                        (opt) => opt.value
                      ) || [],
                  })
                }
              />

              <SelectAutofillCombobox
                withForm
                type="addresses"
                selected={values.uuid}
                name="uuid"
                entityId={entityId}
                label="Address"
                onChange={(addressId) => editAddress(addressId)}
                hideClearAction
                menuPlacement="top"
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
