import { Button } from "components/DesignSystem/Button/Button";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import { ArrowRight } from "components/icons/ArrowRight";

type SelectAllProps = {
  onSelectAllChange: (value: boolean) => void;
  selectedItemCount: number;
};

export const SelectAll = ({
  onSelectAllChange,
  selectedItemCount,
}: SelectAllProps) => {
  return (
    <Dropdown.Root>
      <Dropdown.Trigger className="t-group" asChild>
        <span>
          <Button size="small">
            {selectedItemCount} selected
            <span className="t-transform t-transition t-duration-300 t-ease-in-out group-data-state-open:-t-rotate-90 t-rotate-90 group-data-state-open:!t-text-text-60 t-text-text-30">
              <ArrowRight color="currentColor" stroke="1.5" />
            </span>
          </Button>
        </span>
      </Dropdown.Trigger>
      <Dropdown.Portal>
        <Dropdown.Content align="start" sideOffset={4} className="!t-min-w-28">
          <Dropdown.Item onSelect={() => onSelectAllChange(true)}>
            Select all
          </Dropdown.Item>
          <Dropdown.Item onSelect={() => onSelectAllChange(false)}>
            Clear all
          </Dropdown.Item>
        </Dropdown.Content>
      </Dropdown.Portal>
    </Dropdown.Root>
  );
};
