import { Filter } from "components/DesignSystem/Filter/Filter";
import { DateFilter } from "components/Filters/DateFilter";
import {
  CapsuleFilters,
  CategoryFilter,
  OtherFilters,
} from "./AssignVendorFilterBody";
import { AssignVendorFilterState } from "./assignVendorFilterData";
import dayjs from "dayjs";
import { FiltersValues } from "store/slices/transactionFilter";
import { Amount } from "components/DesignSystem/Filter/Amount";
import { VendorsFilter } from "components/DesignSystem/Filter/Vendors";
import { Source } from "components/DesignSystem/Filter/Source";

export const AssignVendorFilter = ({
  updateFilter,
  values,
  season,
}: {
  updateFilter: (
    name: keyof AssignVendorFilterState,
    value: FiltersValues
  ) => void;
  values: AssignVendorFilterState;
  season: string;
}) => {
  const dateKeys: {
    START_DATE: keyof AssignVendorFilterState;
    END_DATE: keyof AssignVendorFilterState;
    SELECT_PERIOD: keyof AssignVendorFilterState;
  } = {
    START_DATE: "startDate",
    END_DATE: "endDate",
    SELECT_PERIOD: "selectPeriod",
  };

  const appliedFilterCount =
    Object.values(values).filter((filter) => Boolean(filter.value)).length - 1;

  return (
    <Filter.Root
      defaultValue="transactionDate"
      title={
        <span className="t-text-body t-font-medium t-leading-none">
          Filters {appliedFilterCount ? <>({appliedFilterCount})</> : ""}
        </span>
      }
      capsule={<CapsuleFilters updateFilter={updateFilter} values={values} />}
    >
      <Filter.Portal>
        <Filter.List>
          <Filter.ListItem value="transactionDate">Date</Filter.ListItem>
          <Filter.ListItem value="from">Source</Filter.ListItem>
          <Filter.ListItem value="vendors">Vendors</Filter.ListItem>
          <Filter.ListItem value="category">Category</Filter.ListItem>
          <Filter.ListItem value="amount">Amount</Filter.ListItem>

          <Filter.ListItem value="others">Others</Filter.ListItem>
        </Filter.List>
        <Filter.Body value="transactionDate" block>
          <DateFilter
            updateFilter={(key: keyof typeof dateKeys, val: any) =>
              updateFilter(dateKeys[key], val)
            }
            values={{
              START_DATE:
                (values.startDate.value as string) ||
                dayjs(`${season}-01-01`).startOf("year").format("YYYY-MM-DD"),
              END_DATE:
                (values.endDate.value as string) ||
                dayjs(`${season}-01-01`).endOf("year").format("YYYY-MM-DD"),
              SELECT_PERIOD: (values.selectPeriod?.value as string) || "CUSTOM",
            }}
          />
        </Filter.Body>
        <Filter.Body value="from" block>
          <Source
            updateFilter={updateFilter}
            fromIds={values.fromIds}
            otherIds={Object.values(values).filter(
              ({ name, type }) => type == "from" && name !== "Source"
            )}
          />
        </Filter.Body>
        <Filter.Body value="vendors" block>
          <VendorsFilter updateFilter={updateFilter} vendors={values.vendors} />
        </Filter.Body>
        <Filter.Body value="category" block>
          <CategoryFilter updateFilter={updateFilter} values={values} />
        </Filter.Body>
        <Filter.Body value="amount" block>
          <Amount
            updateFilter={updateFilter}
            maxAmount={values.maxAmount}
            minAmount={values.minAmount}
            cashFlow={values.cashFlow}
          />
        </Filter.Body>
        <Filter.Body value="others">
          <OtherFilters updateFilter={updateFilter} values={values} />
        </Filter.Body>
      </Filter.Portal>
    </Filter.Root>
  );
};
