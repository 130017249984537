import { array, mixed, object, string } from "yup";

const notRequired = {
  field: string().notRequired(),
  conditionFunction: string().notRequired(),
  conditionValue: mixed().oneOf([
    string().notRequired(),
    array().notRequired(),
  ]),
};

const required = {
  field: string().required(" "),
  conditionFunction: string().required(" "),
  conditionValue: mixed().required(" "),
};

export const categorisationRuleSchema = () =>
  object({
    ruleConditionData: array().of(
      object().when({
        is: (value: Record<string, string>) => {
          return Object.entries(value).every(([key, value]) => !Boolean(value));
        },
        then: object(notRequired),
        otherwise: object(required),
      })
    ),
    assigned_category_id: string().required("Please select category"),
    startDate: string().when("transactionsFor", {
      is: "custom",
      then: string().required("Please select date").nullable(),
      otherwise: string().notRequired().nullable(),
    }),
    endDate: string().when("transactionsFor", {
      is: "custom",
      then: string().required("Please select date").nullable(),
      otherwise: string().notRequired().nullable(),
    }),
    transactionsFor: string().required("Please select date"),
    name: string().required("Please enter rule name"),
  });

export const editCategorisationRuleSchema = () =>
  object({
    ruleConditionData: array().of(
      object().when({
        is: (value: Record<string, string>) => {
          return Object.entries(value).every(([key, value]) =>
            key === "uuid" ? true : !Boolean(value)
          );
        },
        then: object(notRequired),
        otherwise: object(required),
      })
    ),
    assigned_category_id: string().required("Please select category"),
    startDate: string().required("Please select date").nullable(),
    endDate: string().notRequired().nullable(),
    name: string().required("Please enter rule name"),
  });
