import DateSelectBox from "components/design/dateSelectBox";
import Modal from "components/DesignSystem/Modal/Modal";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { putDeadline } from "../../apis/editDeadline";

export const Deadline = ({ deadline, modalShow, setModalShow }) => {
  const [deadlineValue, setDeadlineValue] = useState(deadline);
  const { alertToast, successToast } = useToast();

  const handleChange = (e) => {
    const { value } = e.target;
    setDeadlineValue(value);
  };

  const editChange = () => {
    const path = window.location.href;
    const url = path.split("/");
    const task_id = url[url.length - 1];

    const body = {
      operation: "DEADLINE_UPDATE",
      deadline: deadlineValue,
    };
    putDeadline({ task_id, body })
      .then(() => {
        setModalShow(false);
        successToast({ message: "Updated successfully!" });
        window.location.reload();
      })
      .catch(function (error) {
        if (error.response) {
          setModalShow(false);
          alertToast({ message: error?.response?.data?.error?.message });
        } else {
          console.log(error.request);
        }
      });
  };

  return (
    <Modal.Root open={modalShow} onOpenChange={() => setModalShow(false)}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Edit Deadline</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <DateSelectBox
            type="date"
            value={deadlineValue}
            name="transaction_date"
            onChange={handleChange}
          />
        </Modal.Body>
        <Modal.FooterButtonGroup>
          <Modal.RawClose asChild>
            <Button onClick={() => setModalShow(false)}>Cancel</Button>
          </Modal.RawClose>
          <Button customType="primary" onClick={() => editChange()}>
            Save
          </Button>
        </Modal.FooterButtonGroup>
      </Modal.Content>
    </Modal.Root>
  );
};
