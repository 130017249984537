import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import Modal from "components/DesignSystem/Modal/Modal";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { MONTHLY_BOOKKEEPING, ONE_TIME, RECURRING } from "constants/billing";
import { CYMONTHS } from "constants/tax";
import { Form, Formik } from "formik";
import { createInvoiceSchema } from "formValidations/createInvoiceSchema";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { useDispatch } from "react-redux";
import {
  billingInvoiceApis,
  useCreateBillingInvoiceMutation,
  useUpdateBillingInvoiceMutation,
} from "store/apis/billingInvoice";
import { useGetTaskListForServiceTeamQuery } from "store/apis/taskList";
import { BillingInvoice } from "types/Models/billing";
import { BackendError } from "types/utils/error";

export const CreateOrEditInvoice = ({
  isOpen,
  close,
  invoice,
}: {
  isOpen: boolean;
  close: () => void;
  invoice?: BillingInvoice;
}) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const [createInvoice, { isLoading }] = useCreateBillingInvoiceMutation();
  const [updateInvoice, { isLoading: isEditing }] =
    useUpdateBillingInvoiceMutation();
  const entityId = useCurrentEntityId();
  const { successToast, alertToast } = useToast();
  const { isAdmin } = useRoleBasedView();
  const dispatch = useDispatch();

  const { data: { tasks } = {} } = useGetTaskListForServiceTeamQuery(
    {
      groupId,
    },
    {
      skip: !isAdmin || !groupId,
      refetchOnMountOrArgChange: true,
    }
  );

  const onCreateInvoice = async (values: {
    task_id: string;
    invoice_amount: string;
    task_short_name: string;
    invoice_type:
      | typeof ONE_TIME
      | typeof RECURRING
      | typeof MONTHLY_BOOKKEEPING;
    description?: string;
    season: string;
    month?: string;
    expense_amount?: string;
    is_auto_pay_enabled?: boolean;
  }) => {
    try {
      if (values.invoice_type !== "MONTHLY_BOOKKEEPING") {
        delete values.expense_amount;
        delete values.is_auto_pay_enabled;
        delete values.month;
      }

      await createInvoice({ ...values, groupId, entityId }).unwrap();
      successToast({ message: "Invoice created" });
      close();
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const onEditInvoice = async (values: {
    task_id: string;
    invoice_amount: string;
    task_short_name: string;
    invoice_type:
      | typeof ONE_TIME
      | typeof RECURRING
      | typeof MONTHLY_BOOKKEEPING;
    description?: string;
    season: string;
    month?: string;
    expense_amount?: string;
    is_auto_pay_enabled?: boolean;
  }) => {
    try {
      if (invoice?.uuid) {
        await updateInvoice({
          ...values,
          groupId,
          entityId,
          invoice_id: invoice?.uuid,
          is_auto_pay_enabled: invoice?.is_auto_pay_enabled
            ? !values.is_auto_pay_enabled
            : values.is_auto_pay_enabled,
        }).unwrap();
        successToast({
          message: "Invoice updated! Changes may take a moment to reflect.",
        });
      }
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
    close();
  };

  const taskOptions = tasks?.map((task) => ({
    value: task.uuid,
    label: `${task.title} - ${task.season}`,
  }));

  const seasonOptions = [
    { value: "2019", label: 2019 },
    { value: "2020", label: 2020 },
    { value: "2021", label: 2021 },
    { value: "2022", label: 2022 },
    { value: "2023", label: 2023 },
    { value: "2024", label: 2024 },
  ];

  const invoiceTypeOptions = [
    {
      value: ONE_TIME,
      label: "One time",
    },
    {
      value: RECURRING,
      label: "Recurring",
    },
    {
      value: MONTHLY_BOOKKEEPING,
      label: "Monthly Bookeeping",
    },
  ];

  const monthOptions = CYMONTHS.map(({ fullName, monthId }) => ({
    value: monthId.toString(),
    label: fullName,
  }));

  const initialValues = {
    invoice_type: (invoice?.invoice_type || ONE_TIME) as
      | typeof ONE_TIME
      | typeof RECURRING
      | typeof MONTHLY_BOOKKEEPING,
    season: invoice?.season || "",
    description: invoice?.notes || "",
    task_id: invoice?.task?.uuid || "",
    task_short_name: invoice?.short_name || "",
    invoice_amount: invoice?.amount || "",
    month: (invoice?.month || "").toString(),
    expense_amount: invoice?.expense_amount || "",
    is_auto_pay_enabled: false,
  };

  const isEditInvoice = Boolean(invoice?.uuid);

  return (
    <Modal.Root open={isOpen} onOpenChange={close} modal={false}>
      <Formik
        validationSchema={createInvoiceSchema}
        initialValues={initialValues}
        onSubmit={isEditInvoice ? onEditInvoice : onCreateInvoice}
        enableReinitialize
      >
        {({ submitForm, values, setFieldValue }) => {
          const selectedInvoiceType = invoiceTypeOptions.find(
            ({ value }) => value === values.invoice_type
          );

          const selectedTask = taskOptions?.find(
            ({ value }) => value === values.task_id
          );

          const selectedSeason = seasonOptions.find(
            ({ value }) => value === values.season
          );

          const selectedMonth = monthOptions.find(
            ({ value }) => value === values.month
          );

          const AutopayLabel = isEditInvoice
            ? invoice?.is_auto_pay_enabled
              ? "Put on hold"
              : "Resume"
            : "Enable";

          return (
            <Modal.Content useCustomOverlay>
              <Modal.Header>
                <Modal.Title>
                  <div>{isEditInvoice ? "Edit" : "Create"} Invoice</div>
                </Modal.Title>
                <Modal.Close />
              </Modal.Header>
              <Modal.Body>
                <Form className="t-m-0 t-space-y-4">
                  <TextInput
                    required
                    name="invoice_amount"
                    label="Invoice amount"
                  />
                  <TextInput
                    required
                    name="task_short_name"
                    label="Invoice name"
                    disabled={isEditInvoice}
                  />
                  <Combobox
                    menuPortalTarget={document.body}
                    withForm
                    required
                    label="Invoice type"
                    name="invoice_type"
                    options={invoiceTypeOptions}
                    menuPlacement="auto"
                    isDisabled={isEditInvoice}
                    value={selectedInvoiceType}
                  />
                  {values.invoice_type === MONTHLY_BOOKKEEPING && (
                    <div className="t-space-y-3">
                      <div className="t-text-subtitle-sm t-text-text-60">
                        Manage Autopay
                      </div>
                      <Checkbox
                        label={AutopayLabel}
                        name="is_auto_pay_enabled"
                        checked={values.is_auto_pay_enabled}
                        onChange={(e) =>
                          setFieldValue("is_auto_pay_enabled", e.target.checked)
                        }
                      />
                    </div>
                  )}
                  <Combobox
                    menuPortalTarget={document.body}
                    withForm
                    label="Task"
                    name="task_id"
                    options={taskOptions}
                    menuPlacement="auto"
                    isDisabled={isEditInvoice}
                    value={selectedTask}
                  />
                  <Combobox
                    menuPortalTarget={document.body}
                    withForm
                    label="Season"
                    name="season"
                    options={seasonOptions}
                    menuPlacement="top"
                    required
                    isDisabled={isEditInvoice}
                    value={selectedSeason}
                  />
                  {values.invoice_type === MONTHLY_BOOKKEEPING && (
                    <>
                      <Combobox
                        menuPortalTarget={document.body}
                        withForm
                        label="Month"
                        name="month"
                        options={monthOptions}
                        menuPlacement="top"
                        isDisabled={isEditInvoice}
                        value={selectedMonth}
                      />
                      <TextInput
                        name="expense_amount"
                        label="Expense Amount"
                        disabled={isEditInvoice}
                      />
                    </>
                  )}
                  <TextInput
                    name="description"
                    label="Description"
                    disabled={isEditInvoice}
                  />
                </Form>{" "}
              </Modal.Body>
              <Modal.FooterButtonGroup>
                <Button
                  type="submit"
                  isLoading={isLoading || isEditing}
                  disabled={isLoading || isEditing}
                  customType="primary"
                  onClick={submitForm}
                >
                  {isEditInvoice ? "Edit" : "Create"} Invoice
                </Button>
              </Modal.FooterButtonGroup>
            </Modal.Content>
          );
        }}
      </Formik>
    </Modal.Root>
  );
};
