import ConditionalToolTip from "components/design/conditionalToolTip";
import React, { useRef } from "react";

export const TaskName = ({ task_title }: { task_title: string }) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <ConditionalToolTip
      condition={ref.current?.clientWidth === 320 && task_title}
    >
      <div className="t-ml-1 t-max-w-80 t-line-clamp-1" ref={ref}>
        {task_title}
      </div>
    </ConditionalToolTip>
  );
};
