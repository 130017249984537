export const accountTypes = [
  "Checking",
  "Money Market",
  "Treasury",
  "Savings",
  "Credit Card",
  "Analysis Checking",
].map((type) => ({ label: type, value: type }));

export const BANK_CONNECTION_MODAL = "BANK_CONNECTION_MODAL";

export const BANK_CONNECTION_SUCCESS_BROADCAST_CHANNEL =
  "BANK_CONNECTION_SUCCESS_BROADCAST_CHANNEL";

export const BANK_CONNECTED_BROADCAST_MESSAGE =
  "BANK_CONNECTED_BROADCAST_MESSAGE";
