import Async from "components/DesignSystem/AsyncComponents/Async";
import {
  AccountingMethod,
  MetricType,
  PeriodType,
  RevenueBasis,
} from "constants/revenueMetrics";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { usePageTitle } from "hooks/usePageTitle";
import { useGetAllRevenueMetricsQuery } from "store/apis/revenueMetrics";
import { formatRevenueData } from "utils/formatRevenueData";
import { FinalDataTable } from "./FinalDataTable";
import { RevenueChart } from "./RevenueChart";

import { YYYY_MM_DD } from "constants/date";
import dayjs from "dayjs";
import { useFilters } from "hooks/useFilter";
import { getDateRange } from "utils/getDateRange";
import { TotalRevenueFilter } from "./RevenueFilter";

export const TotalRevenue = () => {
  usePageTitle("Total Revenue");
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { startDate, endDate } = getDateRange("last12months");
  const { values: filterValues, updateFilter } = useFilters({
    initialValue: {
      START_DATE: dayjs(startDate).format(YYYY_MM_DD),
      END_DATE: dayjs(endDate).format(YYYY_MM_DD),
      SELECT_PERIOD: "last12months",
      VIEW_TYPE: PeriodType.MONTHLY,
      REVENUE_BASIS: RevenueBasis.CUSTOMER,
      ACCOUNTING_METHOD: AccountingMethod.ACCRUAL,
    },
  });

  const onRevenueBasisChange = (revenueBasis: RevenueBasis) => {
    updateFilter("REVENUE_BASIS", revenueBasis);
  };

  const { data, isLoading, isSuccess } = useGetAllRevenueMetricsQuery(
    {
      groupId,
      entityId,
      revenue_basis: filterValues.REVENUE_BASIS,
      period_type: filterValues.VIEW_TYPE,
      start_date: filterValues.START_DATE,
      end_date: filterValues.END_DATE,
      metric_type: MetricType.TOTAL,
      accounting_method: filterValues.ACCOUNTING_METHOD,
    },
    {
      skip: !groupId || !entityId,
    }
  );

  const { chartData, finalTableData } = formatRevenueData({
    data,
  });

  return (
    <div className="t-flex t-gap-6 t-flex-col t-pb-10 t-relative t-top-0">
      <TotalRevenueFilter
        filterValues={filterValues}
        updateFilter={updateFilter}
      />
      <Async.Root isLoading={isLoading} isSuccess={isSuccess} isEmpty={false}>
        <Async.Empty>
          <></>
        </Async.Empty>
        <Async.Success>
          <RevenueChart title="Total Revenue" chartData={chartData} />
          <FinalDataTable
            finalTableData={finalTableData}
            onRevenueBasisChange={onRevenueBasisChange}
            revenueBasis={filterValues.REVENUE_BASIS}
          />
        </Async.Success>
      </Async.Root>
    </div>
  );
};
