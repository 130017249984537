import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { useGetAssignAgentsQuery } from "store/apis/agents";
import { useUpdateGroupDataMutation } from "store/apis/salesCrm";
import { Agents } from "types/Models/agents";

export const AssignAgent = ({
  assignedAgent,
  groupId,
}: {
  assignedAgent: Agents | null;
  groupId: string;
}) => {
  const { alertToast } = useToast();
  const { isAdmin } = useRoleBasedView();
  const { data } = useGetAssignAgentsQuery(
    { agentType: "ACCOUNT_EXECUTIVE" },
    { skip: !isAdmin }
  );
  const [updateGroup, { isLoading }] = useUpdateGroupDataMutation();

  const assignAgent = async (userId: string) => {
    try {
      await updateGroup({
        groupId,
        payLoad: { assigned_agent_user_id: userId },
      }).unwrap();
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
    }
  };

  const removeAgent = async () => {
    try {
      await updateGroup({
        groupId,
        payLoad: { remove_agent: true },
      }).unwrap();
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
    }
  };

  const handleAgentUpdate = ({ value }: { value: string }) => {
    if (value === "") {
      removeAgent();
      return;
    }

    assignAgent(value);
  };

  const agentOptions = [
    { label: "Select", value: "" },
    ...(data?.map(({ name, profile_id }) => ({
      label: name,
      value: profile_id,
    })) || []),
  ];

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Combobox
        components={{
          ClearIndicator: () => null,
        }}
        size="small"
        menuPortalTarget={document.body}
        value={
          assignedAgent
            ? {
                label: assignedAgent.name || "",
                value: assignedAgent.profile_id || "",
              }
            : {
                label: "Select",
                value: "",
              }
        }
        name="address"
        label=""
        placeholder="address"
        options={agentOptions}
        isLoading={isLoading}
        //@ts-ignore
        onChange={handleAgentUpdate}
      />
    </div>
  );
};
