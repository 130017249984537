import classNames from "classnames";
import { BlockedButton } from "components/BlockedButton/BlockedButton";
import { AddManualBank } from "components/Connections/AddManualBank";
import { ConnectionsModal } from "components/Connections/ConnectionsModal";
import { EditBankAccount } from "components/Connections/EditBankAccount";
import { ReConnect } from "components/Connections/ReConnect";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import ToolTip from "components/design/toolTip";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { Tag } from "components/DesignSystem/Tag/Tag";
import { Info } from "components/icons/Info";
import { LockSecure } from "components/icons/LockSecure";
import { PlusIcon } from "components/icons/PlusIcon";
import { PermissionBasedUI } from "components/PermissionBasedUI/PermissionBasedUI";
import { PlaidToDirectMigration } from "components/PlaidToDirectMigration/PlaidToDirectMigration";
import {
  BANK_CONNECTED_BROADCAST_MESSAGE,
  BANK_CONNECTION_MODAL,
  BANK_CONNECTION_SUCCESS_BROADCAST_CHANNEL,
} from "constants/connections";
import * as RECONCILLATION_STATUS from "constants/reconcilliationStatus";
import { CONNECT_BANK_ACCOUNT } from "constants/subscriptionPermissionFeatures";
import dayjs from "dayjs";
import RelativeTime from "dayjs/plugin/relativeTime";
import { motion } from "framer-motion";
import { useBankConnect } from "hooks/useBankConnect";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useGetAllConnections } from "hooks/useGetAllConnections";
import { useHasSubscriptionPermission } from "hooks/useHasSubscriptionPermission";
import { useModal } from "hooks/useModal";
import { usePageTitle } from "hooks/usePageTitle";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { ReactNode } from "react";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import BankCircular from "static/images/BankCircular.svg";
import EmptyDataSource from "static/images/EmptyDataSource.svg";
import { useGetEntityBanksQuery } from "store/apis/bankConnections";
import { Connection } from "store/apis/booksConnections";
import { Banks, ConnectionProvider, ErrorItem } from "types/Models/banks";
import { pluralize } from "utils/pluralize";
import { stopPropagation } from "utils/stopPropagation";
import { EmptyScreen } from "../EmptyScreen";
import { DataSourceView } from "./DataSourceView/DataSourceView";

dayjs.extend(RelativeTime);

type BankAccount = {
  account_number: string;
  name: string;
  available_balance: number;
  uuid: string;
  last_reconcilation_status: "COMPLETED" | "ONGOING" | "PENDING";
  opening_balance: number | null;
  opening_balance_date: string | null;
  balance_matches: boolean;
};

type BankConnection = {
  connection_provider: ConnectionProvider;
  direct_connection?: {
    uuid: string;
    name: string;
    logo_url: string;
    institution_id: string | null;
  } | null;
  uuid: string;
  name: string;
  direct_base_connection_id: string;
  description: string;
  entity_item_id: string;
  logo_url: string;
  last_successful_transaction_update: string;
  connection_type: "PLAID" | "DIRECT" | "MANUAL";
  is_connection_available: boolean;
  update_available: boolean;
  bank_accounts: BankAccount[];
};

const AccountCardsSection = ({ children }: { children: ReactNode }) => {
  return (
    <motion.div
      layout
      className="t-grid t-grid-cols-[repeat(auto-fill,minmax(300px,1fr))] t-gap-x-6 t-gap-y-14"
    >
      {children}
    </motion.div>
  );
};

const Revenue = ({
  stripeConnectedBank,
  stripeConnection,
}: {
  stripeConnection: Connection;
  stripeConnectedBank?: BankConnection;
}) => {
  const { url } = useRouteMatch();
  const { search } = useLocation();
  const history = useHistory();

  const {
    onConnect,
    isLoading: isConnectBankLoading,
    originalArgs,
  } = useBankConnect();

  if (!stripeConnectedBank) {
    return null;
  }

  const {
    disconnectedStatus,
    hasLongerName,
    isReconciliationRequired,
    reconciliationTag,
    reconcilliationRequiredStatus,
    updatedAt,
  } = getConnectionInfoItem({ connection: stripeConnectedBank });

  return (
    <button
      key={stripeConnectedBank.uuid}
      className="all:unset"
      onClick={() =>
        history.push(`${url}/${stripeConnectedBank.uuid}${search}`)
      }
    >
      <ConnectionCard
        status={reconcilliationRequiredStatus || disconnectedStatus}
        logoUrl={stripeConnectedBank.logo_url}
        disconnected={
          !stripeConnectedBank.is_connection_available ||
          isReconciliationRequired
        }
        name={
          <div
            className={classNames("t-flex t-gap-1 t-items-center", {
              "t-flex-col": hasLongerName,
            })}
          >
            <span
              className={classNames({
                "t-order-2": hasLongerName,
                "t-order-1": !hasLongerName,
              })}
            >
              {stripeConnectedBank.name}
            </span>
          </div>
        }
        tag={
          <div>
            <span
              className={classNames({
                "t-order-1": hasLongerName,
                "t-order-2": !hasLongerName,
                "t-visible":
                  stripeConnectedBank.connection_type === "MANUAL" ||
                  stripeConnectedBank.connection_type === "PLAID",
                "t-invisible":
                  stripeConnectedBank.connection_type !== "MANUAL" &&
                  stripeConnectedBank.connection_type !== "PLAID",
              })}
            >
              <Tag size="small" rounded icon={false} tagType="light_grey">
                {stripeConnectedBank.connection_type === "MANUAL"
                  ? "Manually added"
                  : stripeConnectedBank.connection_type === "PLAID"
                  ? "Via plaid"
                  : "direct"}
              </Tag>
            </span>
          </div>
        }
        description={
          <span
            className={classNames("", {
              "t-tsaext-green-80": stripeConnectedBank.is_connection_available,
              "t-text-red-70": !stripeConnectedBank.is_connection_available,
            })}
          >
            {pluralize(
              stripeConnectedBank.bank_accounts?.length || 0,
              "account",
              "accounts"
            )}{" "}
            {stripeConnectedBank.is_connection_available
              ? "connected"
              : "disconnected"}
          </span>
        }
        updatedAt={reconciliationTag || updatedAt}
        total={stripeConnectedBank.bank_accounts?.reduce(
          (acc, account) => acc + account.available_balance,
          0
        )}
        CTA={
          !stripeConnectedBank.is_connection_available && (
            <ReConnect
              isLoading={
                isConnectBankLoading &&
                originalArgs?.connectionId ===
                  stripeConnectedBank.direct_base_connection_id
              }
              onConnect={onConnect}
              connection={stripeConnectedBank}
            />
          )
        }
      />
    </button>
  );
};

export const AccountCard = ({
  logo,
  description,
  title,
  CTA,
}: {
  logo: ReactNode;
  description: ReactNode;
  title: string;
  CTA: ReactNode;
}) => {
  return (
    <div className="t-border t-border-solid t-border-neutral-0 t-rounded t-p-3 t-flex t-justify-between t-items-center t-w-full t-min-h-20">
      <div className="t-flex t-gap-3 t-items-center">
        {logo}
        <div>
          <p className="t-mb-1 t-text-body-lg">{title}</p>
          <p className="t-m-0 t-text-body-sm t-text-text-30">{description}</p>
        </div>
      </div>

      {CTA}
    </div>
  );
};

const getConnectionInfoItem = ({
  connection,
}: {
  connection: BankConnection;
}) => {
  const isReconciliationRequired = connection.bank_accounts?.some(
    (acc) =>
      !acc.balance_matches &&
      acc.last_reconcilation_status !== RECONCILLATION_STATUS.COMPLETED
  );

  const reconcilliationRequiredStatus = isReconciliationRequired
    ? "Reconciliation required"
    : "";

  const disconnectedStatus = !connection.is_connection_available
    ? "Accounts disconnected"
    : "";

  const reconciliationTag = isReconciliationRequired ? (
    connection.is_connection_available && (
      <div className="t-mt-auto t-pt-4 t-min-w-24">
        <Button customType="danger" size="small">
          Reconcile
        </Button>
      </div>
    )
  ) : (
    <></>
  );

  const hasLongerName = connection.name.length > 12;

  const updatedAt = connection.last_successful_transaction_update ? (
    connection.is_connection_available && (
      <span className="t-text-body-sm t-text-dark_green-50">
        Updated {dayjs(connection.last_successful_transaction_update).fromNow()}
      </span>
    )
  ) : (
    <></>
  );

  return {
    isReconciliationRequired,
    reconcilliationRequiredStatus,
    disconnectedStatus,
    reconciliationTag,
    hasLongerName,
    updatedAt,
  };
};

const ConnectionLogo = ({
  logoUrl,
  disconnected,
  updatedAt,
  status,
  logo,
  isPlaidCard,
  isAddCard,
}: {
  disconnected?: boolean;
  logo?: ReactNode;
  logoUrl?: string;
  updatedAt?: ReactNode;
  status?: string;
  isPlaidCard?: boolean;
  isAddCard?: boolean;
}) => {
  return (
    <div
      className={classNames("t-top-0", {
        "t-shadow-light-100": isPlaidCard,
      })}
    >
      {isPlaidCard || isAddCard ? (
        logo
      ) : (
        <div className=" t-p-4 t-shadow-light-100 t-bg-white t-rounded-md t-border t-border-solid t-border-neutral-10 ">
          <div>
            {logoUrl ? (
              <img
                src={logoUrl}
                className="t-rounded-full  t-w-9 t-h-9"
                alt=""
              />
            ) : (
              logo || (
                <img
                  src={BankCircular}
                  className="t-rounded-full t-w-9 t-h-9"
                  alt=""
                />
              )
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export const ConnectionCard = ({
  logoUrl,
  disconnected,
  tag,
  name,
  description,
  updatedAt,
  total,
  CTA,
  status,
  logo,
  isPlaidCard = false,
  isAddCard = false,
}: {
  disconnected?: boolean;
  logo?: ReactNode;
  logoUrl?: string;
  tag?: ReactNode;
  name: ReactNode;
  description: ReactNode;
  updatedAt?: ReactNode;
  total?: number;
  CTA: ReactNode;
  status?: string;
  isPlaidCard?: boolean;
  isAddCard?: boolean;
}) => {
  return (
    <motion.div
      layout
      className={classNames(
        "t-flex t-flex-col t-flex-1 t-justify-center t-items-center t-p-5 hover:t-shadow-[0px_1px_32px_4px_#1F0C5C14] t-shadow-[0px_1px_8px_-1px_#1F0C5C14] t-rounded-lg t-relative t-overflow-visible t-min-h-[310px]  t-h-full t-transition-shadow",
        {
          " t-bg-i-s t-border t-border-dashed t-border-i-neutral-10 t-bg-surface-lighter-grey":
            isAddCard,
          "t-bg-surface": !isAddCard,
        }
      )}
    >
      <ConnectionLogo
        disconnected={disconnected}
        logo={logo}
        logoUrl={logoUrl}
        updatedAt={updatedAt}
        status={status}
        isPlaidCard={isPlaidCard}
        isAddCard={isAddCard}
      />
      <div className="t-mt-4 t-flex t-flex-col t-gap-2 t-justify-between t-items-center">
        <div
          className={classNames("", {
            "t-visible": tag,
            "t-invisible": !tag,
          })}
        >
          {tag}
        </div>
        <span className="t-text-subtitle-sm t-text-text-60">{name}</span>
        {description && (
          <span className="t-text-body t-text-text-30 t-text-center">
            {description}
          </span>
        )}
      </div>
      {(total || total === 0) && (
        <span className="t-text-h4 t-pt-1">
          <AmountSuperScript amount={total} />
        </span>
      )}

      {updatedAt && <span className="t-pt-2">{updatedAt}</span>}

      <div className="t-mt-auto t-pt-4 t-min-w-24">{CTA}</div>
    </motion.div>
  );
};

const BankConnectionCTA = ({
  connection,
  reconciliationTag,
  onConnect,
  isConnectionLoading,
}: {
  connection: BankConnection;
  reconciliationTag: ReactNode;
  onConnect: ReturnType<typeof useBankConnect>["onConnect"];
  isConnectionLoading: boolean;
}) => {
  const plaidToDirectMigrationStrategyModal = useModal();

  const isMigrationModalOpen = plaidToDirectMigrationStrategyModal.isOpen;
  const closeMigrationModal = plaidToDirectMigrationStrategyModal.close;
  const toggleMigrationModal = plaidToDirectMigrationStrategyModal.toggle;

  const entityId = useCurrentEntityId();

  if (
    (!connection.is_connection_available || connection.update_available) &&
    connection.direct_connection?.uuid
  ) {
    return (
      <Modal.Root
        open={isMigrationModalOpen}
        onOpenChange={toggleMigrationModal}
      >
        <Modal.Trigger asChild onClick={(e) => e.stopPropagation()}>
          <Button size="small">Connect directly</Button>
        </Modal.Trigger>
        <Modal.Content onClick={stopPropagation}>
          <PlaidToDirectMigration
            entityId={entityId}
            connectionId={connection.direct_connection.uuid}
            onClose={closeMigrationModal}
          />
        </Modal.Content>
      </Modal.Root>
    );
  }

  if (
    (!connection.is_connection_available || connection.update_available) &&
    !connection.direct_connection?.uuid
  ) {
    return (
      <div className="t-flex t-flex-col t-gap-4 t-justify-center">
        {connection.update_available && (
          <span className="t-text-red t-text-body-sm">Update required</span>
        )}
        <ReConnect
          type={connection.update_available ? "UPDATE" : "RECONNECT"}
          isLoading={isConnectionLoading}
          onConnect={onConnect}
          connection={connection}
        />
      </div>
    );
  }

  if (connection.direct_connection?.uuid) {
    return (
      <Modal.Root
        open={isMigrationModalOpen}
        onOpenChange={toggleMigrationModal}
      >
        <Modal.Trigger asChild onClick={(e) => e.stopPropagation()}>
          <Button size="small">Connect directly</Button>
        </Modal.Trigger>
        <Modal.Content onClick={stopPropagation}>
          <PlaidToDirectMigration
            connectionId={connection.direct_connection.uuid}
            onClose={closeMigrationModal}
            entityId={entityId}
          />
        </Modal.Content>
      </Modal.Root>
    );
  }

  return reconciliationTag;
};

export const DataSourcesList = () => {
  usePageTitle("Data Sources");
  const { update } = useUpdateQuery();
  const query = useQuery();
  const bankConnectModal = query.get("modal");
  const { url, path } = useRouteMatch();
  const { search } = useLocation();
  const history = useHistory();
  const connectionModal = useModal();

  const closeConnectionModal = () => {
    connectionModal.close();
    update({ query: "modal", value: null });
  };

  const isOpen =
    Boolean(bankConnectModal === BANK_CONNECTION_MODAL) ||
    connectionModal.isOpen;

  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();

  const connectionSuccessBroadcastChannel = new BroadcastChannel(
    BANK_CONNECTION_SUCCESS_BROADCAST_CHANNEL
  );

  const {
    stripeConnection,
    isLoading: connectionsLoading,
    isSuccess: connectionsLoaded,
  } = useGetAllConnections({
    groupId: groupId!,
    entityId: entityId!,
  });

  const {
    data: plaidConnections,
    isLoading: bankAccountsLoading,
    isSuccess: banksAccountsLoaded,
  } = useGetEntityBanksQuery(
    {
      groupId: groupId!,
      entityId: entityId!,
    },
    { skip: !groupId || !entityId }
  );

  const bankAccounts: Record<string, Banks[]> | undefined =
    plaidConnections?.accounts.reduce((acc, obj) => {
      const name = obj.bank_brand.name;
      if (!acc[name]) {
        acc[name] = [];
      }
      acc[name].push(obj);
      return acc;
    }, {} as Record<string, Banks[]>);

  const bankItems: Record<string, ErrorItem[]> | undefined =
    plaidConnections?.items.reduce((acc, obj) => {
      const name = obj.bank_brand.name;

      if (!acc[name]) {
        acc[name] = [];
      }
      acc[name].push(obj);
      return acc;
    }, {} as Record<string, ErrorItem[]>);

  const allBankConnections: BankConnection[] =
    bankAccounts && bankItems
      ? Object.entries(bankAccounts).map(([bank, banks]) => ({
          connection_provider: banks[0].bank_brand.connection_provider,
          direct_connection: banks[0].direct_connection,
          uuid: bankItems[bank][0].uuid,
          name: bank,
          direct_base_connection_id: bankItems[bank][0].bank_brand.uuid,
          description: "",
          entity_item_id: bankItems[bank][0].uuid,
          logo_url: banks[0].bank_brand.logo,
          last_successful_transaction_update:
            banks[0].last_successful_transaction_update,
          connection_type: banks[0].account.connection_type,
          is_connection_available: bankItems[bank].every(
            (a) => a.connection_available && !a.in_error_state
          ),
          update_available: bankItems[bank].some((a) => a.update_required),
          bank_accounts: banks
            .map((bank) => bank.account)
            .map((account) => ({
              account_number: account.mask ? `•••• ${account.mask}` : "",
              name: account.nickname,
              available_balance: account.current_balance,
              uuid: account.uuid,
              last_reconcilation_status: account.last_reconcilation_status,
              opening_balance: account.opening_balance,
              opening_balance_date: account.opening_balance_date,
              balance_matches: account.balance_matches,
            })),
        }))
      : [];

  const stripeConnectedBank = allBankConnections.find(
    ({ connection_provider, bank_accounts }) =>
      connection_provider === "STRIPE" &&
      bank_accounts &&
      bank_accounts?.length > 0
  );

  const bankConnections = allBankConnections.filter(
    ({ name, connection_provider, bank_accounts }) =>
      connection_provider !== "STRIPE" &&
      bank_accounts &&
      bank_accounts?.length > 0
  );

  const { accounts: totalAccounts = 0, current_balance: totalBalance = 0 } =
    plaidConnections?.aggregate || {};

  const onConnectSuccess = () => {
    closeConnectionModal();
    connectionSuccessBroadcastChannel.postMessage(
      BANK_CONNECTED_BROADCAST_MESSAGE
    );
  };

  const {
    onConnect,
    isLoading: isConnectBankLoading,
    originalArgs,
  } = useBankConnect({
    onConnectSuccess,
  });

  const permission = useHasSubscriptionPermission({
    feature: CONNECT_BANK_ACCOUNT,
  });

  const addManualBankModal = useModal();
  const isEmpty = !stripeConnectedBank && bankConnections.length === 0;

  return (
    <>
      <Async.Root
        isSuccess={connectionsLoaded && banksAccountsLoaded}
        isLoading={connectionsLoading || bankAccountsLoading}
        isEmpty={isEmpty}
      >
        <Async.Empty>
          <EmptyScreen
            text={
              <div className="t-text-text-30 t-flex t-flex-col">
                <span className="t-text-subtext">No data sources found</span>
                <span className="t-text-overline  t-tracking-normal">
                  Set up banks,cards and revenue integrations to seamlessly
                  bring in your data
                </span>
              </div>
            }
            cta={
              <PermissionBasedUI
                align="end"
                feature={CONNECT_BANK_ACCOUNT}
                blockedUI={<BlockedButton size="small">Connect</BlockedButton>}
              >
                <div className="t-pt-4 t-min-w-24">
                  <Button
                    onClick={connectionModal.open}
                    customType="primary"
                    size="small"
                  >
                    Connect
                  </Button>
                </div>
              </PermissionBasedUI>
            }
          >
            <img src={EmptyDataSource} alt="No data sources found" />
          </EmptyScreen>
        </Async.Empty>
        <Async.Success>
          <div className="t-flex t-flex-col t-gap-14 t-pb-6">
            <div className="t-flex t-flex-col t-gap-5">
              <div className="t-flex t-items-center t-justify-center t-py-5 t-text-center t-flex-col t-gap-1 t-bg-[url('/src/static/images/TotalbalanceUI.svg')] t-bg-no-repeat t-bg-cover t-rounded-lg t-w-full t-overflow-hidden t-bg-center t-border t-border-solid t-border-blue-10">
                <div className="t-text-h5 t-text-text-black">
                  {<AmountSuperScript amount={totalBalance} />}
                </div>
                <div className="t-text-body t-text-text-60 t-flex t-gap-1">
                  <span>
                    Total balance of{" "}
                    {pluralize(totalAccounts, "account", "accounts")}
                  </span>
                  <ToolTip text="This balance does not include credit card balances.">
                    <span className="t-text-text-30">
                      <Info />
                    </span>
                  </ToolTip>
                </div>
              </div>
            </div>

            <AccountCardsSection>
              <button
                className="all:unset"
                disabled={permission?.blocked}
                onClick={connectionModal.open}
              >
                <ConnectionCard
                  logo={
                    <div className=" t-mt-16 t-text-neutral-20 t-flex t-flex-col t-items-center t-gap-3">
                      <PlusIcon size="58" color="currentColor" />
                      <span className=" t-max-w-36 t-text-center  t-text-subtext t-text-text-30">
                        Connect or add a new data source
                      </span>
                    </div>
                  }
                  isAddCard={true}
                  name=""
                  description=""
                  CTA={
                    <PermissionBasedUI
                      align="end"
                      feature={CONNECT_BANK_ACCOUNT}
                      blockedUI={
                        <BlockedButton size="small">Connect</BlockedButton>
                      }
                    >
                      <Button
                        onClick={connectionModal.open}
                        customType="primary"
                        size="small"
                      >
                        Connect
                      </Button>
                    </PermissionBasedUI>
                  }
                />
              </button>
              {bankConnections?.map((connection) => {
                const {
                  disconnectedStatus,
                  hasLongerName,
                  isReconciliationRequired,
                  reconciliationTag,
                  reconcilliationRequiredStatus,
                  updatedAt,
                } = getConnectionInfoItem({ connection });

                return (
                  <button
                    key={connection.uuid}
                    className="all:unset"
                    onClick={() =>
                      history.push(`${url}/${connection.uuid}${search}`)
                    }
                  >
                    <ConnectionCard
                      status={
                        reconcilliationRequiredStatus || disconnectedStatus
                      }
                      logoUrl={connection.logo_url}
                      disconnected={
                        !connection.is_connection_available ||
                        isReconciliationRequired
                      }
                      name={
                        <div
                          className={classNames(
                            "t-flex t-gap-1 t-items-center",
                            {
                              "t-flex-col": hasLongerName,
                            }
                          )}
                        >
                          <span
                            className={classNames({
                              "t-order-2": hasLongerName,
                              "t-order-1": !hasLongerName,
                            })}
                          >
                            {connection.name}
                          </span>
                        </div>
                      }
                      tag={
                        <div>
                          <span
                            className={classNames({
                              "t-order-1": hasLongerName,
                              "t-order-2": !hasLongerName,
                              "t-visible":
                                connection.connection_type === "MANUAL" ||
                                connection.connection_type === "PLAID",
                              "t-invisible":
                                connection.connection_type !== "MANUAL" &&
                                connection.connection_type !== "PLAID",
                            })}
                          >
                            <Tag
                              size="small"
                              rounded
                              icon={false}
                              tagType="light_grey"
                            >
                              {connection.connection_type === "MANUAL"
                                ? "Manually added"
                                : connection.connection_type === "PLAID"
                                ? "Via plaid"
                                : "direct"}
                            </Tag>
                          </span>
                        </div>
                      }
                      description={
                        <span
                          className={classNames("", {
                            "t-text-green-80":
                              connection.is_connection_available,
                            "t-text-red-70":
                              !connection.is_connection_available,
                          })}
                        >
                          {pluralize(
                            connection.bank_accounts?.length || 0,
                            "account",
                            "accounts"
                          )}{" "}
                          {connection.is_connection_available
                            ? "connected"
                            : "disconnected"}
                        </span>
                      }
                      updatedAt={!reconciliationTag ? updatedAt : ""}
                      total={connection.bank_accounts?.reduce(
                        (acc, account) => acc + account.available_balance,
                        0
                      )}
                      CTA={
                        <div className="t-flex t-flex-col t-gap-1 t-justify-center">
                          <BankConnectionCTA
                            connection={connection}
                            isConnectionLoading={isConnectBankLoading}
                            onConnect={onConnect}
                            reconciliationTag={reconciliationTag}
                          />
                        </div>
                      }
                    />
                  </button>
                );
              })}

              {stripeConnection && (
                <Revenue
                  stripeConnection={stripeConnection}
                  stripeConnectedBank={stripeConnectedBank}
                />
              )}

              <button
                className="all:unset"
                disabled={permission?.blocked}
                onClick={addManualBankModal.open}
              >
                <ConnectionCard
                  name="Add bank/card"
                  description="Manually enter bank account or credit card details."
                  CTA={
                    <PermissionBasedUI
                      feature={CONNECT_BANK_ACCOUNT}
                      blockedUI={
                        <BlockedButton size="small">Add details</BlockedButton>
                      }
                    >
                      <Button size="small">Add details</Button>
                    </PermissionBasedUI>
                  }
                />
              </button>
            </AccountCardsSection>
          </div>

          <EditBankAccount />
          <Switch>
            <Route path={`${path}/:dataSourceId`}>
              {({ match }) => {
                const connection = allBankConnections.find(
                  (bank) => bank.uuid === match?.params.dataSourceId
                );
                if (!connection) {
                  return null;
                }

                return (
                  <DataSourceView
                    connection={connection}
                    onClose={() => history.push(`${url}${search}`)}
                  />
                );
              }}
            </Route>
          </Switch>
        </Async.Success>
        <Modal.Root open={isOpen} onOpenChange={closeConnectionModal}>
          <Modal.Content size="large">
            <Modal.Header>
              <Modal.Title>Connect</Modal.Title>
              <Modal.Close />
            </Modal.Header>
            <Modal.Body>
              <ConnectionsModal
                onConnect={onConnect}
                close={closeConnectionModal}
                onComplete={onConnectSuccess}
                isLoading={isConnectBankLoading}
                loadingConnectionId={originalArgs?.connectionId}
                onManualBankAdd={addManualBankModal.open}
              />
            </Modal.Body>
            <Modal.Footer>
              <div className="t-flex t-items-center t-gap-3">
                <div>
                  <LockSecure />
                </div>
                <p className="t-m-0 t-text-text-30 t-text-body-sm">
                  {/* TODO: Change to soc 2 once we have it  */}
                  Inkle connects your account securely in compliance with
                  industry standards. Inkle will only have read-only access to
                  your transactions.
                </p>
              </div>
            </Modal.Footer>
          </Modal.Content>
        </Modal.Root>
        <AddManualBank
          onSuccess={onConnectSuccess}
          isOpen={addManualBankModal.isOpen}
          close={addManualBankModal.close}
        />
      </Async.Root>
    </>
  );
};
