import { ActionCard } from "components/ActionCard/ActionCard";
import { Button } from "components/DesignSystem/Button/Button";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { COPIED_TEXT, FAILED_COPIED_TEXT } from "constants/documents";
import { useToast } from "hooks/useToast";
import authContext from "jwt_context&axios/authContext";
import React, { useContext } from "react";
import CopyIcon from "static/images/CopyPurple.svg";
import { ConditionalLink } from "components/conditionalLink";
import ReferWhatsApp from "static/images/ReferWhatsApp.svg";
import ReferLinkedIn from "static/images/ReferLinkedIn.svg";
import ReferTwitter from "static/images/ReferTwitter.svg";
import ReferMail from "static/images/ReferMail.svg";
import { Form, Formik } from "formik";
import { LargeCard } from "components/home/Home";

const ReferAction = ({ referralLink }: { referralLink: string }) => {
  const linkedInReferralLink = referralLink.replace("=", "%3D");

  const mailLink = `mailto:?subject=Get%20%24100%20in%20Inkle%20credits%20using%20my%20referral%20link&body=I%20use%20Inkle%20for%20tax%2C%20compliance%20%26%20accounting%20for%20my%20Delaware%20C-Corp.%20Inkle%20replaces%20a%20CPA%20%26%20provides%20a%20dashboard%20with%20chat%2C%20document%20vault%2C%20deadline%20alerts%20%26%20easy%20workflows.%20%0A%0AUse%20my%20referral%20link%20and%20we'll%20both%20get%20%24100%20worth%20of%20Inkle%20credits%0A${referralLink}`;

  const twitterLink = `https://twitter.com/intent/post?text=I%20use%20Inkle%20for%20tax%2C%20compliance%20%26%20accounting%20for%20my%20Delaware%20C-Corp.%20Inkle%20replaces%20a%20CPA%20%26%20provides%20a%20dashboard%20with%20chat%2C%20document%20vault%2C%20deadline%20alerts%20%26%20easy%20workflows.%20Use%20my%20referral%20link%20and%20we%27ll%20both%20get%20%24100%20worth%20of%20Inkle%20credits%3A%20${referralLink}`;

  const linkedInLink = `https://www.linkedin.com/feed/?shareActive=true&text=I%20use%20Inkle%20for%20tax%2C%20compliance%20%26%20accounting%20for%20my%20Delaware%20C-Corp.%20Inkle%20replaces%20a%20CPA%20%26%20provides%20a%20dashboard%20with%20chat%2C%20document%20vault%2C%20deadline%20alerts%20%26%20easy%20workflows.%20Use%20my%20referral%20link%20and%20we%27ll%20both%20get%20%24100%20worth%20of%20Inkle%20credits!%20${linkedInReferralLink}`;

  const whatsAppLink = `https://web.whatsapp.com/send?text=https://www.linkedin.com/feed/?shareActive=true&text=Join%20Inkle%20for%20tax%2C%20compliance%20%26%20accounting%20for%20your%20Delaware%20C-Corp.%20Use%20my%20referral%20link%20and%20we%27ll%20both%20get%20%24100%20worth%20of%20Inkle%20credits%3A%20${referralLink}`;

  return (
    <div
      className="t-flex t-flex-col t-gap-2 t-mt-auto"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="t-flex t-gap-3 t-items-center">
        <ConditionalLink to={mailLink}>
          <Button customType="ghost_icon" size="small">
            <img src={ReferMail} alt="Mail" />
          </Button>
        </ConditionalLink>
        <ConditionalLink to={twitterLink}>
          <Button customType="ghost_icon" size="small">
            <img src={ReferTwitter} alt="Twitter" />
          </Button>
        </ConditionalLink>
        <ConditionalLink to={linkedInLink}>
          <Button customType="ghost_icon" size="small">
            <img src={ReferLinkedIn} alt="LinkedIn" />
          </Button>
        </ConditionalLink>
        <ConditionalLink to={whatsAppLink}>
          <Button customType="ghost_icon" size="small">
            <img src={ReferWhatsApp} alt="WhatsApp" />
          </Button>
        </ConditionalLink>
      </div>
      <div className="t-text-overline t-text-text-30">*T&C apply</div>
    </div>
  );
};

export const ReferHomeCard = () => {
  const {
    authtoken: { referral_link },
  } = useContext(authContext);
  const { alertToast, successToast } = useToast();

  const copyToClipBoard = async () => {
    try {
      await navigator.clipboard.writeText(referral_link);
      successToast({ message: COPIED_TEXT });
    } catch (err) {
      alertToast({ message: FAILED_COPIED_TEXT });
    }
    return false;
  };

  return (
    <LargeCard>
      <div
        className="t-relative t-border-neutral-10 t-border t-border-solid t-p-5 t-text-text-100 t-bg-auto t-row-span-6 t-self-start t-w-full t-h-full t-rounded-lg"
        onClick={copyToClipBoard}
        role="button"
      >
        <div className="t-bg-yellow-40 t-blur-3xl t-bg-opacity-20 t-rounded-full t-w-72 t-h-72 t-absolute t-left-0 -t-translate-y-[90%] -t-translate-x-[20%]" />
        <div className="t-bg-yellow-40 t-blur-3xl t-bg-opacity-20 t-rounded-full t-w-72 t-h-72 t-absolute t-right-0 t-translate-y-[40%] t-translate-x-[20%]" />
        <div className="t-flex t-flex-col t-justify-between t-h-full t-gap-3">
          <div className="t-flex t-gap-1 t-flex-col t-z-10">
            <p className="t-m-0 t-text-h5">
              Refer your friends to Inkle and earn $100*
            </p>
            <div className="t-mt-2">
              <Formik onSubmit={() => {}} initialValues={{}}>
                {() => (
                  <Form className="t-m-0 t-w-full">
                    <TextInput
                      name="referralLink"
                      block
                      value={referral_link}
                      rightComponent={
                        <Button customType="ghost_icon" size="extra-small">
                          <img src={CopyIcon} alt="copyIcon" />
                        </Button>
                      }
                      customSize="small"
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </div>

          <div>
            <ReferAction referralLink={referral_link} />
          </div>
        </div>
      </div>
    </LargeCard>
  );
};
