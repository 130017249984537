import { stateUpdate } from "apis/stateUpdate";
import { CheckoutModal } from "components/CheckoutModal/CheckoutModal";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { FieldBuilder } from "components/Entity/EntityFormEngine/FieldBuilder";
import { getByValue } from "components/fileTax/getIndex";
import { HelpCircle } from "components/icons/HelpCircle";
import { RegisteredStateInfoItem } from "components/Registrations/RegisteredStateInfoItem";
import { RegisteredStateTags } from "components/Registrations/RegisteredStateTags";
import { UnregisteredActions } from "components/Registrations/UnregisteredActions";
import { Form, Formik } from "formik";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useQuery } from "hooks/useQuery";
import { useToast } from "hooks/useToast";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAddProductsToCartAndCheckoutMutation } from "store/apis/billing";
import { useLazyGetTaskByRequestIdQuery } from "store/apis/registrations";
import {
  StateRegistrationNameType,
  stateRegistrationsApis,
  useGetStateRegistrationByIdQuery,
} from "store/apis/StateRegistrations";
import { BillingInvoice } from "types/Models/billing";
import { BackendError } from "types/utils/error";
import { openLink } from "utils/openLink";
import { StateRegistrationForm } from "./StateRegistrationForm";
import { AddEditRA } from "components/RegisteredAgent/AddEditRA";
import { openAddOrEditModal } from "store/slices/registerAgent";
import Loader from "components/design/loader";

type StateRegistrationModalPropType = {
  isOpen: boolean;
  close: () => void;
  registrationId: string;
  title: string;
  StateType: StateRegistrationNameType;
};

export const StateRegistrationModal = ({
  isOpen,
  close,
  registrationId,
  title,
  StateType,
}: StateRegistrationModalPropType) => {
  const { alertToast, successToast } = useToast();
  const { uuid: groupId, secondary_channel_url } = useCurrentGroupContext();
  const query = useQuery();
  let entityId = query.get("entity");
  const {
    location: { search },
  } = useHistory();
  const { data: registrationDetail, isLoading } =
    useGetStateRegistrationByIdQuery(
      {
        groupId: groupId!,
        entityId: entityId!,
        stateId: registrationId,
        stateType: StateType,
      },
      { skip: !groupId || !entityId || !registrationId || !StateType }
    );
  const [getTaskByRequestId] = useLazyGetTaskByRequestIdQuery();
  const isNotRegistered =
    registrationDetail?.status === "NOT_REGISTERED" ||
    registrationDetail?.status === "REQUIRED";
  const isRegistered = registrationDetail?.status === "REGISTERED";
  const isProcessing = registrationDetail?.status === "PROCESSING";
  const registrationFormModal = useModal();

  const [
    createProductInvoice,
    { isLoading: addingProduct, data: productInvoice },
  ] = useAddProductsToCartAndCheckoutMutation();
  const filingPaymentModal = useModal();
  const dispatch = useDispatch();

  const launchFiling = async () => {
    if (groupId && registrationDetail?.filing_data) {
      try {
        const price = registrationDetail?.filing_data.amount;
        const payload = {
          entity_id: entityId!,
          product_content_type_id:
            registrationDetail?.filing_data.product_content_type_id,
          product_id: registrationDetail?.filing_data.base_task_template_id,
          quantity: 1,
          payment_amount: !price || isNaN(Number(price)) ? 0 : Number(price),
        };

        await createProductInvoice({
          groupId,
          entityId: entityId!,
          productData: [payload],
          from_other: true,
        }).unwrap();

        filingPaymentModal.open();
      } catch (error) {
        alertToast({ message: (error as BackendError).data?.error?.message });
      }
    }
  };

  const openChat = () => {
    openLink(`/tax/chat/${secondary_channel_url}${search}`);
  };

  const closeFilingPaymentModal = () => {
    filingPaymentModal.close();
  };

  const getNewTaskDetail = async () => {
    try {
      dispatch(
        stateRegistrationsApis.util.invalidateTags(["STATE_REGISTRATIONS"])
      );
      const taskData = await getTaskByRequestId({
        groupId: groupId,
        requestId: productInvoice?.request_id!,
        entityId: entityId!,
      }).unwrap();

      if (taskData && taskData.length > 0) {
        const task = taskData[0];
        const current = task?.task_states?.[0].uuid;
        const check = new Map();
        const indexing = new Map();

        task?.task_states?.forEach((element, index) => {
          check.set(element.uuid, element.type);
          indexing.set(element.uuid, index);
        });

        const nextStateUuid = getByValue(indexing, indexing.get(current) + 1);
        await stateUpdate({ taskId: task.uuid, id: nextStateUuid });
        successToast({ message: "Filing launched successfully!" });

        if (task?.uuid) {
          openLink(`/tax/filings/${task.uuid}`);
        }
      }
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
    closeFilingPaymentModal();
    close();
  };

  const openShareDetails = () => {
    if (registrationDetail?.action_type === "GET_AGENT") {
      dispatch(openAddOrEditModal());
    } else {
      registrationFormModal.open();
    }
  };

  const onSuccess = () => {
    dispatch(
      stateRegistrationsApis.util.invalidateTags(["STATE_REGISTRATIONS"])
    );
  };

  const initialValues =
    registrationDetail?.data_attributes.reduce(
      (ac, cv) => (ac = { ...ac, [cv.field_key]: cv.value }),
      {}
    ) || {};

  return (
    <>
      <Modal.Root open={isOpen} onOpenChange={close}>
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>
              {title}{" "}
              {registrationDetail ? `(${registrationDetail?.state})` : ""}
            </Modal.Title>
            <Modal.Close onClick={close} />
          </Modal.Header>
          <Modal.Body>
            {isLoading || !registrationDetail ? (
              <Loader />
            ) : (
              <Formik
                onSubmit={() => {}}
                initialValues={initialValues}
                enableReinitialize
              >
                <Form>
                  <div className="t-space-y-5">
                    <RegisteredStateInfoItem
                      label="Department of registration"
                      value={
                        registrationDetail?.department_of_registration || "-"
                      }
                    />
                    <RegisteredStateInfoItem
                      label="Purpose"
                      value={registrationDetail?.purpose || ""}
                    />
                    <RegisteredStateInfoItem
                      label="Relevance"
                      value={registrationDetail?.relevance || ""}
                    />
                    <div className="t-p-4 t-border t-border-solid t-border-neutral-10 t-rounded">
                      {registrationDetail && (
                        <div className="t-flex t-justify-between t-items-center t-pb-3">
                          <div className="t-text-subtitle t-text-text-100">
                            Status
                          </div>
                          <RegisteredStateTags
                            status={registrationDetail.status}
                            statusText={registrationDetail.status_text}
                          />
                        </div>
                      )}

                      {isNotRegistered && (
                        <div className="t-space-y-4">
                          <UnregisteredActions
                            actionType={registrationDetail.action_type}
                            launchFiling={launchFiling}
                            isLoading={addingProduct}
                            actionDescription={
                              registrationDetail.action_description
                            }
                            stateName={registrationDetail.state}
                          />
                          <div className="t-p-3 t-rounded t-flex t-items-center t-justify-between t-bg-surface-purple t-gap-1">
                            <div className="t-text-text-100 t-text-body">
                              {registrationDetail.action_label}
                            </div>
                            <Button size="small" onClick={openShareDetails}>
                              Share details
                            </Button>
                          </div>
                        </div>
                      )}
                      {isRegistered && (
                        <Form className="t-m-0 t-space-y-4">
                          {registrationDetail?.data_attributes
                            .toSorted((a, b) => a.order - b.order)
                            .map((field, i) => (
                              <div key={i}>
                                <FieldBuilder
                                  field={{ ...field, disabled: true }}
                                />
                              </div>
                            ))}
                        </Form>
                      )}
                      {isProcessing && (
                        <div className="t-space-y-3">
                          <div className="t-text-text-100 t-text-body">
                            Inkle is taking care of your{" "}
                            {registrationDetail.title}
                          </div>
                          <Button
                            size="small"
                            onClick={() =>
                              openLink(
                                `/tax/filings/${registrationDetail.filing_data?.task_id}`
                              )
                            }
                          >
                            View status
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </Form>
              </Formik>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className="t-flex t-gap-1 t-w-full t-items-center">
              <HelpCircle />
              <div className="t-text-subtext t-text-text-30">Need help?</div>
              <Button type="button" customType="link" onClick={openChat}>
                Chat with us
              </Button>
            </div>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Root>
      {registrationFormModal.isOpen && registrationDetail && (
        <StateRegistrationForm
          isOpen={registrationFormModal.isOpen}
          close={registrationFormModal.close}
          fields={registrationDetail.data_attributes}
          stateId={registrationId}
          stateType={StateType}
        />
      )}
      {filingPaymentModal.isOpen &&
        Boolean(productInvoice?.invoice) &&
        entityId && (
          <CheckoutModal
            entityId={entityId}
            type="invoices"
            onInvoicePaid={getNewTaskDetail}
            open={filingPaymentModal.isOpen}
            onClose={closeFilingPaymentModal}
            invoices={[productInvoice?.invoice as BillingInvoice]}
            invoiceRequestId={productInvoice?.request_id}
          />
        )}

      {registrationDetail && (
        <AddEditRA onSuccess={onSuccess} stateName={registrationDetail.state} />
      )}
    </>
  );
};
