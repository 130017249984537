export const ForwardIcon = () => {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2189_156097)">
        <path
          d="M1.44979 9.815C2.22135 8.99328 4.24682 7.25 7.12495 7.25V9.5L10.8749 5.75L7.12495 2V4.25C4.64995 4.25 1.47698 6.61484 1.12495 9.66594C1.12007 9.70545 1.1279 9.74548 1.1473 9.78025C1.1667 9.81501 1.19665 9.8427 1.23284 9.85931C1.26902 9.87591 1.30955 9.88056 1.34855 9.8726C1.38756 9.86463 1.42301 9.84446 1.44979 9.815Z"
          stroke="#706A85"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2189_156097">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
