import { stateUpdate } from "apis/stateUpdate";
import { CheckoutModal } from "components/CheckoutModal/CheckoutModal";
import { getByValue } from "components/fileTax/getIndex";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import React from "react";
import { Task } from "types/Models/task";

export const PayNotStartedFiling = ({
  onClose,
  onInvoicePaid,
  task,
}: {
  onClose: () => void;
  onInvoicePaid: () => void;
  task: Task;
}) => {
  const { isForeignCA } = useRoleBasedView();
  const { alertToast } = useToast();
  const current = task?.task_states?.[0].uuid;
  const check = new Map();
  const indexing = new Map();
  task?.task_states?.forEach((element, index) => {
    //@ts-ignore
    check.set(element.uuid, element.type);
    indexing.set(element.uuid, index);
  });
  const nextStateUuid = getByValue(indexing, indexing.get(current) + 1);

  const updateState = async () => {
    try {
      await stateUpdate({ taskId: task.uuid, id: nextStateUuid });
      onInvoicePaid();
    } catch (e: any) {
      alertToast({ message: e?.response?.data?.error?.message });
    }
  };

  if (task) {
    return (
      <CheckoutModal
        payerUserType={isForeignCA ? "FCA" : "CUSTOMER"}
        type="task"
        task={task}
        open
        onInvoicePaid={updateState}
        onClose={onClose}
      />
    );
  }
  return null;
};
