import { postNewTag } from "apis/postNewTag";
import { Button } from "components/DesignSystem/Button/Button";
import { OptionData } from "components/DesignSystem/Combobox/Combobox";
import { TagCombobox } from "components/TagCombobox/TagCombobox";
import { TICKETS_TAG } from "constants/ticket";
import { useToast } from "hooks/useToast";
import { useDispatch } from "react-redux";
import { MultiValue, SingleValue } from "react-select";
import { useGetTagsForCategoryQuery } from "store/apis/billing";
import { useUpdateChatTicketMutation } from "store/apis/chatTicket";
import { setSelectedChatTicket } from "store/slices/chat";
import { ChatTicket } from "types/Models/chatTicket";

const TicketTagsCombobox = ({
  ticketId,
  currentTags,
}: {
  ticketId: string;
  currentTags?: ChatTicket["tags"];
}) => {
  const currentTicketTags = currentTags || [];
  const dispatch = useDispatch();
  const [updateChatTicket, { isLoading: isTicketUpdateLoading }] =
    useUpdateChatTicketMutation();
  const { alertToast } = useToast();
  const {
    data: tags = [],
    refetch: refetchTags,
    isFetching: isFetchingTags,
  } = useGetTagsForCategoryQuery({
    tagType: "TICKETS_TAG",
  });

  const updateTicketTags = async (batch_tag_ids: string, ticket_id: string) => {
    try {
      const currentTagValues = { batch_tag_ids: batch_tag_ids };
      const res = await updateChatTicket({
        ticket_id: ticket_id,
        ...currentTagValues,
      }).unwrap();
      dispatch(setSelectedChatTicket(res));
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
    }
  };

  const onTagsChange = (
    values?: MultiValue<OptionData> | SingleValue<OptionData>
  ) => {
    if (values && values instanceof Array) {
      let newValues = values.map((tags) => tags.value);
      let updatedTagsString = newValues.join(",") || "TAGS_REMOVED";
      updateTicketTags(updatedTagsString, ticketId);
    }
  };

  const addTicketTag = async (tagName: string) => {
    return await postNewTag({
      tagType: TICKETS_TAG,
      payload: { title: tagName, bg_color_hex: null, color_hex: null },
    });
  };

  const onTagCreate = async (tagName: string) => {
    const {
      data: { data },
    } = await addTicketTag(tagName);
    await refetchTags();
    const newTag = data;
    const updatedTags = currentTicketTags.map(
      (currentTicketTag) => currentTicketTag.uuid
    );
    updateTicketTags([...updatedTags, newTag.uuid].join(","), ticketId);
  };

  const handleSelectAll = () => {
    updateTicketTags(tags.map((t) => t.uuid).join(","), ticketId);
  };

  return (
    <TagCombobox
      tags={tags}
      currentTags={
        currentTags as unknown as {
          uuid: string;
          title: string;
          tag_category: number;
          color_hex: string;
          bg_color_hex: string;
        }[]
      }
      onTagsChange={onTagsChange}
      creatable={true}
      onCreateOption={onTagCreate}
      isLoading={isTicketUpdateLoading}
      size={"small"}
      actions={
        <div>
          <Button customType="text" size="small" onClick={handleSelectAll}>
            Select All
          </Button>
        </div>
      }
    />
  );
};

export default TicketTagsCombobox;
