import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type OnboardingStep = {
  openComment: string | null;
};

const initialState: OnboardingStep = {
  openComment: null,
};

const openCommentSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    setOpenComment: (state, action: PayloadAction<string | null>) => {
      state.openComment = action.payload;
    },
  },
});

export const { setOpenComment } = openCommentSlice.actions;

export default openCommentSlice.reducer;
