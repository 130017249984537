import React from "react";

export const InkleAdminLogo = () => {
  return (
    <svg
      width="125"
      height="16"
      viewBox="0 0 125 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1752 4.80383L2.87943 11.9999L0.824676 9.97324C-0.274892 8.8887 -0.274892 7.1303 0.824676 6.04576L6.12951 0.813407C7.22908 -0.271136 9.01183 -0.271135 10.1114 0.813408L10.1752 0.876351C11.2748 1.96089 11.2748 3.71929 10.1752 4.80383Z"
        fill="url(#paint0_linear_2382_449)"
      />
      <path
        d="M5.82419 11.1961L13.12 4L15.1747 6.02668C16.2743 7.11123 16.2743 8.86962 15.1747 9.95416L9.86989 15.1865C8.77032 16.2711 6.98757 16.2711 5.888 15.1865L5.82419 15.1236C4.72462 14.039 4.72462 12.2806 5.82419 11.1961Z"
        fill="url(#paint1_linear_2382_449)"
      />
      <path
        d="M24 1.65196C24 0.649648 24.624 0 25.6046 0C26.5674 0 27.1914 0.649648 27.1914 1.65196C27.1914 2.65427 26.5674 3.32248 25.6046 3.32248C24.624 3.32248 24 2.65427 24 1.65196ZM27.0309 15.9071H24.1783V4.77027H27.0309V15.9071Z"
        fill="url(#paint2_linear_2382_449)"
      />
      <path
        d="M35.783 10.3201C35.783 8.68672 34.9629 7.61016 33.697 7.61016C32.5025 7.61016 31.6289 8.76096 31.6289 10.3387V15.9071H28.7762V4.77027H31.6289V6.06957C32.0033 5.23431 33.1087 4.64034 34.2141 4.64034C36.9419 4.64034 38.6356 6.90483 38.6356 10.3201V15.9071H35.783V10.3201Z"
        fill="url(#paint3_linear_2382_449)"
      />
      <path
        d="M43.2093 15.9071H40.3567V0.129929H43.2093V9.76328L46.8286 4.77027H49.9487L46.3116 10.3387L50.3231 15.9071H46.7038L43.2093 10.9141V15.9071Z"
        fill="url(#paint4_linear_2382_449)"
      />
      <path
        d="M54.2436 15.9071H51.391V0.129929H54.2436V15.9071Z"
        fill="url(#paint5_linear_2382_449)"
      />
      <path
        d="M62.9165 12.4918H65.9474C65.2164 14.5707 63.3087 15.9999 61.1336 15.9999C58.0135 15.9999 55.8206 13.6426 55.8206 10.3016C55.8206 7.07188 58.0849 4.62178 61.0801 4.62178C64.0754 4.62178 66.3397 7.07188 66.3397 10.3016C66.3397 10.6542 66.3218 11.0255 66.2683 11.3596H58.8158C59.1368 12.4918 60.0104 13.2157 61.1158 13.2157C61.9537 13.2157 62.6134 12.9187 62.9165 12.4918ZM58.8158 9.225H63.3444C63.0235 8.12988 62.1855 7.42455 61.0801 7.42455C59.9747 7.42455 59.1368 8.12988 58.8158 9.225Z"
        fill="url(#paint6_linear_2382_449)"
      />
      <path
        d="M123.083 10.9914C123.083 9.01766 122.071 7.74535 120.505 7.74535C119.054 7.74535 118.01 9.11553 118.01 11.0077V15.9012H116.248V6.11418H118.01V7.94109C118.385 6.81558 119.494 6 120.799 6C123.278 6 124.844 7.97371 124.844 10.9914V15.9012H123.083V10.9914Z"
        fill="#41395C"
      />
      <path
        d="M112.586 3.17871C112.586 2.55887 112.994 2.13477 113.646 2.13477C114.266 2.13477 114.674 2.55887 114.674 3.17871C114.674 3.81487 114.266 4.23897 113.646 4.23897C112.994 4.23897 112.586 3.81487 112.586 3.17871ZM114.527 15.9018H112.765V6.11481H114.527V15.9018Z"
        fill="#41395C"
      />
      <path
        d="M102.459 10.9914C102.459 9.01766 101.448 7.74535 99.8818 7.74535C98.4301 7.74535 97.3862 9.11553 97.3862 11.0077V15.9012H95.6245V6.11418H97.3862V7.94109C97.7613 6.81558 98.8705 6 100.175 6C101.741 6 102.948 6.79927 103.617 8.13683C104.253 6.78296 105.363 6 106.765 6C109.391 6 111.055 7.97371 111.055 10.9914V15.9012H109.294V10.9914C109.294 9.01766 108.282 7.74535 106.7 7.74535C105.265 7.74535 104.221 9.11553 104.221 10.9914V15.9012H102.459V10.9914Z"
        fill="#41395C"
      />
      <path
        d="M92.1341 15.902V14.0751C91.6937 15.2169 90.3072 15.9999 88.8391 15.9999C86.0172 15.9999 84.1577 14.0099 84.1577 10.9922C84.1577 7.99086 86.0172 6.00084 88.8391 6.00084C90.2746 6.00084 91.6937 6.7838 92.1341 7.94193V2.03711H93.8957V15.902H92.1341ZM85.9194 10.9922C85.9194 12.9496 87.208 14.2382 89.0349 14.2382C90.8781 14.2382 92.1504 12.9496 92.1341 11.0085C92.1341 9.08374 90.8292 7.74619 89.0349 7.74619C87.1917 7.74619 85.9194 9.03481 85.9194 10.9922Z"
        fill="#41395C"
      />
      <path
        d="M70.3398 15.902L75.951 2.03711H77.8269L83.438 15.902H81.5622L80.0779 12.2482H73.7L72.2157 15.902H70.3398ZM74.3851 10.5355H79.3928L76.8971 4.35336L74.3851 10.5355Z"
        fill="#41395C"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2382_449"
          x1="0"
          y1="5.99996"
          x2="10.9999"
          y2="5.99996"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2382_449"
          x1="4.99951"
          y1="9.99996"
          x2="15.9994"
          y2="9.99996"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2382_449"
          x1="24"
          y1="7.99995"
          x2="66.3397"
          y2="7.99995"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2382_449"
          x1="24"
          y1="7.99995"
          x2="66.3397"
          y2="7.99995"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2382_449"
          x1="24"
          y1="7.99995"
          x2="66.3397"
          y2="7.99995"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_2382_449"
          x1="24"
          y1="7.99995"
          x2="66.3397"
          y2="7.99995"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_2382_449"
          x1="24"
          y1="7.99995"
          x2="66.3397"
          y2="7.99995"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
