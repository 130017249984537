import React from "react";

export const InkleTaxLogo = () => {
  return (
    <svg
      width="126"
      height="20"
      viewBox="0 0 126 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2192 6.00491L4.09943 15L1.53097 12.4667C0.156506 11.111 0.156506 8.913 1.53097 7.55732L8.16204 1.01688C9.53651 -0.338798 11.765 -0.338797 13.1394 1.01688L13.2192 1.09556C14.5937 2.45124 14.5937 4.64923 13.2192 6.00491Z"
        fill="url(#paint0_linear_8954_130)"
      />
      <path
        d="M7.78097 13.9952L16.9007 5.00012L19.4692 7.53348C20.8437 8.88916 20.8437 11.0871 19.4692 12.4428L12.8381 18.9833C11.4637 20.3389 9.2352 20.3389 7.86074 18.9833L7.78097 18.9046C6.40651 17.5489 6.40651 15.3509 7.78097 13.9952Z"
        fill="url(#paint1_linear_8954_130)"
      />
      <path
        d="M30.5001 2.37813C30.5001 1.17467 31.2801 0.394653 32.5058 0.394653C33.7093 0.394653 34.4893 1.17467 34.4893 2.37813C34.4893 3.58159 33.7093 4.3839 32.5058 4.3839C31.2801 4.3839 30.5001 3.58159 30.5001 2.37813ZM34.2887 19.494H30.7229V6.12222H34.2887V19.494Z"
        fill="url(#paint2_linear_8954_130)"
      />
      <path
        d="M45.2289 12.7858C45.2289 10.8246 44.2037 9.53202 42.6214 9.53202C41.1282 9.53202 40.0362 10.9138 40.0362 12.8081V19.494H36.4704V6.12222H40.0362V7.68226C40.5042 6.67938 41.886 5.96622 43.2677 5.96622C46.6775 5.96622 48.7947 8.68515 48.7947 12.7858V19.494H45.2289V12.7858Z"
        fill="url(#paint3_linear_8954_130)"
      />
      <path
        d="M54.5118 19.494H50.946V0.550657H54.5118V12.1172L59.0359 6.12222H62.936L58.3896 12.8081L63.404 19.494H58.8799L54.5118 13.499V19.494Z"
        fill="url(#paint4_linear_8954_130)"
      />
      <path
        d="M68.3047 19.494H64.7389V0.550657H68.3047V19.494Z"
        fill="url(#paint5_linear_8954_130)"
      />
      <path
        d="M79.1459 15.3933H82.9346C82.0208 17.8894 79.6362 19.6054 76.9173 19.6054C73.0172 19.6054 70.276 16.7751 70.276 12.7635C70.276 8.88572 73.1063 5.94393 76.8504 5.94393C80.5945 5.94393 83.4249 8.88572 83.4249 12.7635C83.4249 13.187 83.4026 13.6327 83.3357 14.0339H74.0201C74.4212 15.3933 75.5132 16.2625 76.895 16.2625C77.9424 16.2625 78.767 15.9059 79.1459 15.3933ZM74.0201 11.4709H79.6808C79.2796 10.156 78.2322 9.30916 76.8504 9.30916C75.4687 9.30916 74.4212 10.156 74.0201 11.4709Z"
        fill="url(#paint6_linear_8954_130)"
      />
      <path
        d="M95.8874 19.4831H93.7057V4.33366H88.4248V2.15198H101.168V4.33366H95.8874V19.4831Z"
        fill="#41395C"
      />
      <path
        d="M110.585 19.4831V17.1995C110.034 18.6267 108.281 19.6054 106.446 19.6054C102.939 19.6054 100.614 17.1179 100.614 13.3458C100.614 9.59417 102.918 7.10664 106.446 7.10664C108.26 7.10664 110.034 8.08534 110.585 9.533V7.24937H112.767V19.4831H110.585ZM102.816 13.3458C102.816 15.7926 104.407 17.4034 106.711 17.4034C108.994 17.4034 110.585 15.7926 110.585 13.3662C110.585 10.9603 108.954 9.28833 106.69 9.28833C104.386 9.28833 102.816 10.8991 102.816 13.3458Z"
        fill="#41395C"
      />
      <path
        d="M114.341 19.4831L118.664 13.3662L114.341 7.24937H116.972L119.969 11.4904L122.966 7.24937H125.617L121.294 13.3662L125.617 19.4831H122.966L119.969 15.2421L116.972 19.4831H114.341Z"
        fill="#41395C"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8954_130"
          x1="0.500122"
          y1="7.50007"
          x2="14.25"
          y2="7.50007"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8954_130"
          x1="6.75012"
          y1="12.5001"
          x2="20.5"
          y2="12.5001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_8954_130"
          x1="30.5001"
          y1="10"
          x2="83.4249"
          y2="10"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_8954_130"
          x1="30.5001"
          y1="10"
          x2="83.4249"
          y2="10"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_8954_130"
          x1="30.5001"
          y1="10"
          x2="83.4249"
          y2="10"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_8954_130"
          x1="30.5001"
          y1="10"
          x2="83.4249"
          y2="10"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_8954_130"
          x1="30.5001"
          y1="10"
          x2="83.4249"
          y2="10"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
