import { Button } from "components/DesignSystem/Button/Button";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import ThreeDots from "static/images/ThreeDots.svg";
import "../../static/styles/components/billing.css";
import { BillingInvoice } from "types/Models/billing";
import { useDispatch } from "react-redux";
import { openBillingInvoiceSlider } from "store/slices/billingInvoiceSlider";
import { useModal } from "hooks/useModal";
import { CreateOrEditInvoice } from "./CreateInvoice";
import { RevokeLateFeeModal } from "./billing";

export const BillingInvoiceOptions = ({
  invoice,
  isInvoicePaid,
  setDeleteInvoiceId,
}: {
  invoice: BillingInvoice;
  isInvoicePaid: (status: string) => boolean;
  setDeleteInvoiceId: (uuid: string) => void;
}) => {
  const { isAdmin } = useRoleBasedView();
  const isRefunded = invoice.refund_data;
  const dispatch = useDispatch();
  const editInvoice = useModal();
  const lateFeeRevokeModal = useModal();
  const late_fee = invoice.late_fee;

  const openInvoiceSlider = (invoice: BillingInvoice) => {
    dispatch(openBillingInvoiceSlider(invoice));
  };

  if (!isAdmin && !isRefunded) {
    return null;
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Dropdown.Root>
        <Dropdown.Trigger asChild>
          <div>
            <Button size="small" customType="ghost_icon">
              <img src={ThreeDots} alt="Action" className="t-select-none" />
            </Button>
          </div>
        </Dropdown.Trigger>
        <Dropdown.Portal>
          <Dropdown.Content sideOffset={8} side="bottom" className="t-mr-16">
            {isAdmin && !isRefunded && isInvoicePaid(invoice.status) && (
              <Dropdown.Item onSelect={() => openInvoiceSlider(invoice)}>
                Initiate Refund
              </Dropdown.Item>
            )}
            {isRefunded && (
              <Dropdown.Item onSelect={() => openInvoiceSlider(invoice)}>
                View Refund
              </Dropdown.Item>
            )}
            {isAdmin && (
              <Dropdown.Item
                disabled={isInvoicePaid(invoice.status)}
                onSelect={() => setDeleteInvoiceId(invoice.uuid)}
              >
                Delete
              </Dropdown.Item>
            )}
            {isAdmin && invoice.invoice_type === "MONTHLY_BOOKKEEPING" && (
              <Dropdown.Item onSelect={editInvoice.open}>Edit</Dropdown.Item>
            )}
            {Number(late_fee) > 0 && (
              <Dropdown.Item onSelect={lateFeeRevokeModal.open}>
                Revoke late fee
              </Dropdown.Item>
            )}
          </Dropdown.Content>
        </Dropdown.Portal>
      </Dropdown.Root>
      {editInvoice.isOpen && (
        <CreateOrEditInvoice {...editInvoice} invoice={invoice} />
      )}
      <RevokeLateFeeModal
        isOpen={lateFeeRevokeModal.isOpen}
        close={lateFeeRevokeModal.close}
        invoiceId={invoice.uuid}
      />
    </div>
  );
};
