import { AmountSuperScript } from "components/design/AmountSuperScript";
import Loader from "components/design/loader";
import { TableUI } from "components/design/TableUI";
import { Avatar } from "components/DesignSystem/AvatarGroup/Avatar";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
} from "react-table-8.10.7";
import { useGetInsightsQuery, Insight } from "store/apis/metrics";
import { ArrayElement } from "types/utils/ArrayElement";

export const TopVendorsExpanded = ({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}) => {
  const groupId = useCurrentGroupContext()?.uuid;
  const entityId = useCurrentEntityId();

  const { data: insights, isLoading: isInsightsLoading } = useGetInsightsQuery(
    {
      groupId,
      entityId,
      start_date: startDate || null,
      end_date: endDate || null,
      limit: 10,
    },
    { skip: !groupId || !entityId, refetchOnMountOrArgChange: true }
  );

  const createColumn = createColumnHelper<ArrayElement<Insight["merchants"]>>();

  const columns = [
    createColumn.display({
      header: "NO.",
      cell: (info) => info.row.index + 1,
      size: 10,
    }),
    createColumn.accessor("merchant_name", {
      size: 40,
      header: "Vendor",
      cell: (info) => {
        return (
          <div className="t-flex t-gap-4 t-items-center">
            <Avatar alt={info.getValue()} src={info.row.original.logo} />
            {info.getValue()}
          </div>
        );
      },
    }),
    createColumn.accessor("amount", {
      id: "amount",
      size: 50,
      header: () => <div className="t-flex t-justify-end">Amount</div>,
      cell: (info) => {
        return (
          <p className="t-m-0 t-text-right">
            <AmountSuperScript amount={info.getValue()} />
          </p>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: insights?.merchants || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 1,
      maxSize: 100,
    },
  });

  if (isInsightsLoading) {
    return (
      <div className="t-h-72 t-flex t-justify-center t-items-center">
        <Loader />
      </div>
    );
  }

  return <TableUI size="regular" table={table} />;
};
