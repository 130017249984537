import { ArrayElement } from "types/utils/ArrayElement";

export const viewByOption = [
  { value: "TOTALS" as const, label: "Date" },
  { value: "MONTHLY" as const, label: "Months" },
  { value: "QUARTERLY" as const, label: "Quarters" },
  { value: "YEARLY" as const, label: "Years" },
];

export type ViewOptions = ArrayElement<typeof viewByOption>["value"];
