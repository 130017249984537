import { ConditionalLink } from "components/conditionalLink";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { Warning } from "components/icons/Warning";
import { PRIVACY_POLICY, TERMS_AND_CONDITIONS } from "constants/onBoarding";
import { motion } from "framer-motion";
import { ChangeEvent } from "react";

export const AuthFooter = ({
  onChange,
  isChecked,
  showError,
}: {
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  isChecked?: boolean;
  showError?: boolean;
}) => {
  return (
    <div className="t-w-full t-flex t-flex-col t-gap-1.5">
      <Checkbox
        name="termspolicy"
        label={
          <span>
            I have read and agree to the
            <ConditionalLink to={TERMS_AND_CONDITIONS}>
              &nbsp;Terms of Service&nbsp;
            </ConditionalLink>
            and
            <ConditionalLink to={PRIVACY_POLICY}>
              &nbsp;Privacy Policy
            </ConditionalLink>
            .
          </span>
        }
        checked={isChecked}
        onChange={onChange}
      />
      {showError && (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.2, ease: "easeInOut" }}
        >
          <div className="t-flex t-justify-start t-text-body-sm t-text-red t-gap-1 t-items-center t-ml-0.5">
            <span>Acceptance of terms and conditions is required</span>
          </div>
        </motion.div>
      )}
    </div>
  );
};
