import React, { MouseEvent } from "react";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";

export const ConfirmFiledExternally = ({
  isOpen,
  close,
  onClick,
  isLoading,
}: {
  isOpen: boolean;
  close: () => void;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  isLoading: boolean;
}) => {
  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Filed Externally</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <div className="t-text-body-lg t-text-text-60">
            Are you sure you want to label this as an external filing?
          </div>
        </Modal.Body>
        <Modal.FooterButtonGroup>
          <Modal.RawClose asChild>
            <Button type="button">Cancel</Button>
          </Modal.RawClose>
          <Button
            customType="primary"
            onClick={onClick}
            type="button"
            isLoading={isLoading}
            disabled={isLoading}
          >
            Confirm
          </Button>
        </Modal.FooterButtonGroup>
      </Modal.Content>
    </Modal.Root>
  );
};
