import { RegisteredStateTags } from "components/Registrations/RegisteredStateTags";
import { useModal } from "hooks/useModal";
import React from "react";
import { StateRegistrationModal } from "./StateRegistrationModal";
import {
  StateRegistrationNameType,
  StateRegistrationType,
} from "store/apis/StateRegistrations";
import ArrowBend from "static/images/ArrowBendDownRight.svg";

export const RegisteredStateColumn = ({
  stateDetails,
  stateId,
  stateType,
}: {
  stateDetails: StateRegistrationType;
  stateId: string;
  stateType: StateRegistrationNameType;
}) => {
  const federalRegistrationFlow = useModal();

  return (
    <>
      <div
        className="t-flex t-gap-1 t-items-center t-py-3"
        role="button"
        onClick={federalRegistrationFlow.open}
      >
        <RegisteredStateTags
          status={stateDetails.status}
          statusText={stateDetails.status_text}
          withBackground={false}
        />
        <img
          src={ArrowBend}
          alt="ArrowBend"
          className="t-hidden group-hover:t-block"
          aria-label={`${stateDetails.title}_${stateDetails.state}`}
        />
      </div>
      {federalRegistrationFlow.isOpen && (
        <StateRegistrationModal
          StateType={stateType}
          {...federalRegistrationFlow}
          registrationId={stateId}
          title={stateDetails.title}
        />
      )}
    </>
  );
};
