import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { useEffect, useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { getAllTagChoices } from "../../apis/getAllTagChoices";
import { postNewTag } from "../../apis/postNewTag";
import { tagColors } from "../../constants/tagColors";
import "../../static/styles/components/design/colorDropdown.css";

const TagCreation = ({
  constantTag = "",
  handleClose,
  show,
  needColor = true,
  setNewTag = () => {},
}) => {
  const { alertToast, successToast } = useToast();
  const { isAdmin } = useRoleBasedView();
  const [tagChoices, setTagChoices] = useState([]);
  const [tagType, setTagType] = useState(constantTag);
  const [previewTag, setPreviewTag] = useState({});

  useEffect(() => {
    const getTagChoices = async () => {
      try {
        const res = await getAllTagChoices();
        setTagChoices(res.data.data);
      } catch (e) {
        alertToast({ message: e?.response?.data?.error?.message });
      }
    };
    if (isAdmin && !constantTag) {
      getTagChoices();
    }
  }, []);

  const postTag = () => {
    if (tagType === "") {
      alertToast({ message: "Please select a tag type" });
      return;
    } else {
      postNewTag({ tagType, payload: previewTag })
        .then((res) => {
          if (res.data.success) {
            successToast({ message: "Tag created successfully" });
            if (constantTag) {
              handleClose();
              setNewTag(res.data.data);
            }
          }
        })
        .catch((err) => {
          alertToast({ message: "Tag creation failed" });
        });
    }
  };
  const handleTagTextColor = (e) => {
    setPreviewTag({ ...previewTag, color_hex: e });
  };

  const handleTagBgColor = (e) => {
    setPreviewTag({ ...previewTag, bg_color_hex: e });
  };
  const handleSetTagName = (e) => {
    const { name } = e.target;
    setPreviewTag({ ...previewTag, [name]: e.target.value });
  };

  return (
    <Modal.Root open={show} onOpenChange={handleClose}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Create Tag</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <div className="prevDiv">
            <div
              className="demoTag"
              style={{
                color: previewTag.color_hex,
                backgroundColor: previewTag.bg_color_hex,
              }}
            >
              {previewTag?.title && `#${previewTag.title}`}
            </div>
            <Button
              customType="primary"
              disabled={!previewTag?.title}
              onClick={postTag}
            >
              Create Tag
            </Button>
          </div>
          <div className="autofill-modal-body d-flex flex-column">
            <div className="colorInputDiv">
              <label>Tag Name</label>{" "}
              <input
                type="text"
                placeholder="Tag Name"
                name="title"
                className="tagNameInput"
                onChange={handleSetTagName}
              />
              {!constantTag && (
                <>
                  <label>Tag Type</label>
                  <select
                    className="selectTagType"
                    onChange={(e) => setTagType(e.target.value)}
                  >
                    <option value="">Select Tag Type</option>
                    {tagChoices?.map((tag) => (
                      <option value={tag} key={tag}>
                        {tag}
                      </option>
                    ))}
                  </select>
                </>
              )}
            </div>
            {needColor && (
              <div className="colorDropdownDiv t-pt-0">
                <DropdownButton
                  id="dropdown-item-button"
                  className="colorDropdownMenu"
                  title={previewTag.color_hex || "Select text color"}
                  onSelect={handleTagTextColor}
                >
                  {tagColors.map((color, index) => {
                    return (
                      <Dropdown.Item
                        key={index}
                        className="colorDropdownItem"
                        eventKey={color.textColor}
                        style={{
                          color: color.bgColor,
                          backgroundColor: color.textColor,
                        }}
                      >
                        {color.bgColor}
                      </Dropdown.Item>
                    );
                  })}
                </DropdownButton>
                <DropdownButton
                  id="dropdown-item-button"
                  className="colorDropdownMenu"
                  onSelect={handleTagBgColor}
                  title={previewTag.bg_color_hex || "Select background color"}
                >
                  {tagColors.map((color, index) => {
                    return (
                      <Dropdown.Item
                        key={index}
                        className="colorDropdownItem"
                        eventKey={color.bgColor}
                        style={{
                          color: color.textColor,
                          backgroundColor: color.bgColor,
                        }}
                      >
                        {color.bgColor}
                      </Dropdown.Item>
                    );
                  })}
                </DropdownButton>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};

export default TagCreation;
