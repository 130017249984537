import Loader from "components/design/loader";
import { useQuery } from "hooks/useQuery";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

export const RedirectPage = () => {
  const query = useQuery();

  const url = query.get("url");

  useEffect(() => {
    if (url) {
      window.location.href = url;
    }
  }, [url]);

  return (
    <div className="t-h-screen t-w-screen t-flex t-justify-center t-items-center">
      <Loader />
    </div>
  );
};
