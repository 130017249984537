import classNames from "classnames";
import { ForwardRefComponent, HTMLMotionProps, motion } from "framer-motion";
import { ComponentProps } from "react";

export const SliderAnimation = ({
  children,
  width = 360,
  ...props
}: ComponentProps<
  ForwardRefComponent<HTMLDivElement, HTMLMotionProps<"div">>
> & { width?: number }) => {
  return (
    <motion.div
      {...props}
      layout
      initial={{ opacity: 0, width: 0 }}
      animate={{ opacity: 1, width: 360 }}
      exit={{ opacity: 0, width: 0 }}
      transition={{ duration: 0.4, ease: "easeInOut" }}
      style={{ transformOrigin: "right" }}
    >
      {children}
    </motion.div>
  );
};
