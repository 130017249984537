import React from "react";
import {
  ReactZoomPanPinchProps,
  TransformComponent,
  TransformWrapper,
} from "react-zoom-pan-pinch";

const Root = (props: ReactZoomPanPinchProps) => {
  return <TransformWrapper {...props}>{props.children}</TransformWrapper>;
};

const Content = (props: any) => {
  return <TransformComponent {...props}>{props.children}</TransformComponent>;
};

const ZoomWrapper = { Root, Content };
export default ZoomWrapper;
