import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import { Search } from "components/DesignSystem/Search/Search";
import Table from "components/DesignSystem/Table/V2/Table";
import { MagnifyingGlass } from "components/icons/MagnifyingGlass";
import { AddJournalEntryModal } from "components/JournalEntry/AddJournalEntryModal";
import { DeleteJournalEntryTable } from "components/JournalEntry/DeleteJournalEntryTable";
import { EditJournalEntry } from "components/JournalEntry/EditJournalEntry";
import { ViewJournalEntryModal } from "components/JournalEntry/ViewJournalEntry";
import { DD_MMM_YYYY, YYYY_MM_DD } from "constants/date";
import dayjs from "dayjs";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useFilters } from "hooks/useFilter";
import { useModal } from "hooks/useModal";
import { usePageTitle } from "hooks/usePageTitle";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { EmptyScreen } from "pages/Books/EmptyScreen";
import { MouseEvent, useEffect, useRef, useState } from "react";
import {
  CellContext,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  Row,
  useReactTable,
} from "react-table-8.10.7";
import ThreeDots from "static/images/ThreeDots.svg";
import { useGetAllJournalEntriesQuery } from "store/apis/journalEntry";
import { JournalEntry as JournalEntryType } from "types/Models/journalEntry";
import { debounce } from "utils/debouncing";
import { DuplicateJournalEntryModal } from "./DuplicateJournalEntry";
import { JournalEntryFilter } from "./JournalEntryFilter";
import { Recurring } from "components/icons/Recurring";
import ToolTip from "components/design/toolTip";
import Modal from "components/DesignSystem/Modal/Modal";
import { TakeConfirmationModal } from "components/TakeConfirmationModal/TakeConfirmationModal";
import { CommentsSlider } from "components/CommentsSlider/CommentsSlider";
import { AnimatePresence } from "framer-motion";
import { SliderAnimation } from "components/SliderAnimation/SliderAnimation";
import { ChatTeardropDots } from "components/icons/ChatTeardropDots";
import classNames from "classnames";
import * as Popover from "@radix-ui/react-popover";
import { AddCommentPop } from "components/AddComment/AddComment";
import { useAppSelector } from "hooks/useAppSelector";
import { useDispatch } from "react-redux";
import { setOpenComment } from "store/slices/openComment";
import { NoteResponse } from "store/apis/notes";
import { useHistory, useLocation } from "react-router-dom";
import { parse, stringify } from "qs";
import { AddCommentPopoverRoot } from "components/AddCommentPopoverRoot/AddCommentPopoverRoot";

const Action = (props: CellContext<JournalEntryType, unknown>) => {
  const rowData = props.row.original;
  const { isOpen: isOpenView, open: openView, close: closeView } = useModal();
  const {
    isOpen: isOpenEdit,
    showConfirmation: showEditConfirmation,
    dismissConfirmation: dismissEditConfirmation,
    open: openEdit,
    close: closeEdit,
  } = useModal();
  const {
    isOpen: isOpenDuplicate,
    open: openDuplicate,
    close: closeDuplicate,
  } = useModal();

  const transactionId = rowData.transaction_uuids?.[0] || "";

  const {
    isOpen: isDeleteJournalEntryModalOpen,
    open: openDeleteJournalEntryModal,
    close: closeDeleteJournalEntryModal,
  } = useModal();

  const onEdit = () => {
    openEdit();
  };

  const onDelete = () => {
    openDeleteJournalEntryModal();
  };

  const onDuplicate = () => {
    openDuplicate();
  };

  return (
    <div
      className="t-text-subtext t-text-text-30"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="t-flex t-justify-center t-items-center t-h-full">
        <AddCommentPopoverRoot contentId={rowData.uuid}>
          <Dropdown.Root>
            <Dropdown.Trigger asChild>
              <div>
                <Popover.Anchor asChild>
                  <Button
                    size="small"
                    customType="ghost_icon"
                    title={`Action for ${rowData.entry_number}`}
                  >
                    <img
                      src={ThreeDots}
                      alt="Action"
                      className="t-select-none"
                    />
                  </Button>
                </Popover.Anchor>
              </div>
            </Dropdown.Trigger>
            <Popover.Portal>
              <AddCommentPop
                commentType="LEDGER_ENTRY_COMMENT"
                contentId={rowData.uuid}
              />
            </Popover.Portal>
            <Dropdown.Portal>
              <Dropdown.Content
                sideOffset={8}
                side="bottom"
                className="t-mr-16"
              >
                <Popover.Trigger asChild>
                  <Dropdown.Item>Add comment</Dropdown.Item>
                </Popover.Trigger>
                <Dropdown.Item onSelect={onDuplicate}>Duplicate</Dropdown.Item>
                <Dropdown.Item onSelect={onEdit}>Edit</Dropdown.Item>
                <Dropdown.Item type="danger" onSelect={onDelete}>
                  Delete
                </Dropdown.Item>
              </Dropdown.Content>
            </Dropdown.Portal>
          </Dropdown.Root>
        </AddCommentPopoverRoot>
      </div>
      {isOpenView && (
        <ViewJournalEntryModal
          isOpen={isOpenView}
          close={closeView}
          transactionId={transactionId!}
        />
      )}
      {isOpenEdit && (
        <EditJournalEntry
          showConfirmation={showEditConfirmation}
          dismissConfirmation={dismissEditConfirmation}
          close={closeEdit}
          isOpen={isOpenEdit}
          transactionId={transactionId}
        />
      )}
      {isOpenDuplicate && (
        <DuplicateJournalEntryModal
          close={closeDuplicate}
          isOpen={isOpenDuplicate}
          currentRowData={rowData!}
          transactionId={transactionId}
        />
      )}

      <DeleteJournalEntryTable
        transactionId={transactionId}
        close={closeDeleteJournalEntryModal}
        isOpen={isDeleteJournalEntryModalOpen}
      />
    </div>
  );
};

export const JournalEntry = () => {
  usePageTitle("Journal Entry");
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const query = useQuery();
  const pageNum = Number(query.get("page")) || 1;
  const searchTerm = query.get("search_term") || null;
  const { update, updateMultiple } = useUpdateQuery();
  const [currentRowData, setCurrentRowData] = useState<JournalEntryType>();
  const commentPane = useModal();
  const selectedJournalEntry = query.get("selected_journal_entry");
  const openComment = useAppSelector((state) => state.openComment.openComment);

  const dispatch = useDispatch();

  const setActiveComment = (comment: NoteResponse) => {
    if (comment.ledger_entry?.uuid) {
      update({
        query: "selected_journal_entry",
        value: comment.ledger_entry?.uuid,
      });
    }
    if (comment.ledger_entry) {
      dispatch(setOpenComment(comment.ledger_entry.uuid));
    }
  };

  const [sorting, setSorting] = useState<
    {
      desc: boolean;
      id: "AMOUNT" | "DATE";
    }[]
  >([
    {
      id: "DATE",
      desc: true,
    },
  ]);

  const sortCol = sorting[0]?.id;
  const sortOrder = sorting[0]?.desc;

  const { values, updateFilter, getUpdateFilter } = useFilters({
    initialValue: {
      START_DATE: "",
      END_DATE: "",
      SELECT_PERIOD: "" as string,
    },
  });

  const params = {
    pageNum,
    searchTerm,
    startDate: values.START_DATE
      ? dayjs(values.START_DATE).format(YYYY_MM_DD)
      : null,
    endDate: values.END_DATE ? dayjs(values.END_DATE).format(YYYY_MM_DD) : null,
    sortCol: sortCol,
    sortOrder: sortOrder ? ("DSC" as const) : ("ASC" as const),
  };

  const { data, isLoading, isSuccess } = useGetAllJournalEntriesQuery(
    {
      groupId,
      entityId,
      ...params,
    },
    { skip: !groupId || !entityId }
  );

  const {
    journal_entries = [],
    current_page = 1,
    per_page = 1,
    total_count = 1,
    total_pages = 1,
  } = data || {};

  const {
    isOpen: isAddJournalEntryModalOpen,
    close: closeAddJournalEntryModal,
    open: openAddJournalEntryModal,
    showConfirmation: showAddConfirmation,
    dismissConfirmation: dismissAddConfirmation,
  } = useModal();

  const {
    isOpen: isViewJournalEntryModalOpen,
    close: closeViewJournalEntryModal,
    open: openViewJournalEntryModal,
  } = useModal();

  const columnHelper = createColumnHelper<JournalEntryType>();

  const columns = [
    columnHelper.accessor("date", {
      id: "DATE",
      size: 25,
      header: "DATE",
      cell: (info) => (
        <div className="t-text-subtext t-text-text-60">
          {info.getValue() ? dayjs(info.getValue()).format(DD_MMM_YYYY) : "-"}
        </div>
      ),
    }),

    columnHelper.accessor("entry_name", {
      id: "TITLE",
      size: 40,
      header: "TITLE",
      enableSorting: false,
      cell: (info) => (
        <div className="t-flex t-gap-2">
          <div className="t-text-subtext t-text-text-60">{info.getValue()}</div>
          {info.row.original.settings?.is_recurring_enabled && (
            <span className="t-text-text-30 t-flex t-items-center">
              <ToolTip text="Recurring journal entry" disableHoverableContent>
                <span>
                  <Recurring />
                </span>
              </ToolTip>
            </span>
          )}
        </div>
      ),
    }),

    columnHelper.accessor("entries_count", {
      id: "entries",
      size: 25,
      header: "TOTAL ENTRIES",
      enableSorting: false,
      cell: (info) => (
        <div className="t-flex t-w-full t-justify-between">
          <div className="t-text-subtext t-text-text-60">{info.getValue()}</div>
        </div>
      ),
    }),

    columnHelper.display({
      id: "action",
      size: 10,
      cell: Action,
    }),
  ];

  const table = useReactTable({
    data: journal_entries || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: true,
    manualSorting: true,
    enableMultiSort: false,
    enableSortingRemoval: false,
    // @ts-ignore
    onSortingChange: setSorting,
    state: {
      sorting,
    },
    defaultColumn: {
      size: 10,
      minSize: 1,
      maxSize: 100,
    },
  });

  const paginationData = {
    totalPage: total_pages,
    currentPage: current_page,
    itemsPerPage: per_page,
    totalItemCount: total_count,
  };

  const goToFirstPage = () => {
    update({ query: "page", value: 1 });
  };

  const goToPrevPage = () => {
    const localCurrentPage = pageNum < total_pages ? pageNum : total_pages;
    update({ query: "page", value: localCurrentPage - 1 });
  };

  const goToNextPage = () => {
    if (pageNum < total_pages) {
      update({ query: "page", value: pageNum + 1 });
    }
  };

  const goToLastPage = () => {
    update({ query: "page", value: total_pages });
  };

  const handleChange = debounce((e) => {
    const { value } = e.target;
    updateMultiple([
      { query: "search_term", value: value || null },
      { query: "page", value: 1 },
    ]);
  });

  const onRowClick = (
    e: MouseEvent<HTMLTableRowElement>,
    row: { original: JournalEntryType }
  ) => {
    e.stopPropagation();
    setCurrentRowData(row.original);
    openViewJournalEntryModal();
  };

  const isEmpty = journal_entries.length === 0;

  const checkRef = (
    rowEl: HTMLTableRowElement | null,
    row: Row<JournalEntryType> | null
  ) => {
    if (row && row.original.uuid === selectedJournalEntry && rowEl) {
      rowEl.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    let currentSearch = parse(location.search, { ignoreQueryPrefix: true });
    let timeout: NodeJS.Timeout;
    if (selectedJournalEntry) {
      if (currentSearch) {
        delete currentSearch.selected_journal_entry;
      }

      timeout = setTimeout(() => {
        history.replace(
          `${location.pathname}${stringify(currentSearch, {
            addQueryPrefix: true,
          })}`
        );
      }, 5000);
    }

    return () => clearTimeout(timeout);
  }, [history, location.pathname, location.search, selectedJournalEntry]);

  return (
    <div className="t-flex t-h-full t-pl-10">
      <div className="t-flex t-flex-col t-gap-5 t-flex-1">
        <div
          className={classNames(
            "t-flex t-flex-col t-gap-5 t-pt-5 t-z-header t-bg-white",
            {
              "t-pr-3": commentPane.isOpen,
              "t-pr-10": !commentPane.isOpen,
            }
          )}
        >
          <div className="t-w-full t-flex t-justify-between t-gap-2">
            <div className="t-w-2/5">
              <Search
                placeholder="Search..."
                defaultValue={searchTerm || ""}
                onChange={handleChange}
                block
              />
            </div>
            <div className="t-ml-auto" />
            <Button
              customType="primary"
              size="small"
              onClick={openAddJournalEntryModal}
            >
              Add journal entry
            </Button>
            <Button customType="icon" onClick={commentPane.toggle} size="small">
              <ChatTeardropDots />
            </Button>
          </div>
          <div className="t-w-full t-flex t-justify-between">
            <JournalEntryFilter
              values={values}
              updateFilter={updateFilter}
              getUpdateFilter={getUpdateFilter}
            />

            <Pagination
              {...paginationData}
              goToFirstPage={goToFirstPage}
              goToPrevPage={goToPrevPage}
              goToNextPage={goToNextPage}
              goToLastPage={goToLastPage}
            />
          </div>
        </div>
        <Async.Root {...{ isLoading, isEmpty, isSuccess }}>
          <Async.Empty>
            <EmptyScreen text="No journal entry found">
              <span className="t-text-i-neutral-10">
                <MagnifyingGlass size="149" />
              </span>
            </EmptyScreen>
          </Async.Empty>
          <Async.Success>
            <Table.Container className="t-h-full t-overflow-y-auto">
              <Table.Content
                className={classNames({
                  "t-pr-3": commentPane.isOpen,
                  "t-pr-10": !commentPane.isOpen,
                })}
              >
                <Table.Head>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <Table.Row key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <Table.HeadCell
                          className="t-text-subtext-sm t-uppercase t-px-2 t-py-4 t-group"
                          onClick={header.column.getToggleSortingHandler()}
                          key={header.id}
                          style={{ width: `${header.getSize()}%` }}
                          {...(header.column.getCanSort()
                            ? { role: "button" }
                            : {})}
                        >
                          <span className="t-flex t-gap-1 t-items-center">
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                            {header.column.getCanSort() && (
                              <Table.HeadCellSort
                                nextSortOrder={header.column.getNextSortingOrder()}
                                isSorted={header.column.getIsSorted()}
                              />
                            )}
                          </span>
                        </Table.HeadCell>
                      ))}
                    </Table.Row>
                  ))}
                </Table.Head>
                <Table.Body>
                  {table.getRowModel().rows.map((row) => (
                    <Table.Row
                      ref={(rowEl) => checkRef(rowEl, row)}
                      key={row.id}
                      onClick={(e) => onRowClick(e, row)}
                      className={classNames(
                        "hover:t-bg-surface-lighter-grey t-cursor-pointer t-group",
                        {
                          "animate-transaction-row":
                            row.original.uuid === selectedJournalEntry,
                        }
                      )}
                      data-testid={`journal-entry-row-${row.id}`}
                    >
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <Table.Cell
                            id={cell.id}
                            key={cell.id}
                            style={{
                              width: `${cell.column.getSize()}%`,
                            }}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </Table.Cell>
                        );
                      })}
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table.Content>
            </Table.Container>
          </Async.Success>
        </Async.Root>
        {isAddJournalEntryModalOpen && (
          <>
            <AddJournalEntryModal
              close={closeAddJournalEntryModal}
              isOpen={isAddJournalEntryModalOpen}
            />
            <TakeConfirmationModal
              isOpen={showAddConfirmation}
              dismiss={dismissAddConfirmation}
              close={closeAddJournalEntryModal}
            />
          </>
        )}
        {isViewJournalEntryModalOpen && (
          <ViewJournalEntryModal
            isOpen={isViewJournalEntryModalOpen}
            close={closeViewJournalEntryModal}
            transactionId={currentRowData?.transaction_uuids?.[0]!}
          />
        )}
      </div>
      <AnimatePresence>
        {commentPane.isOpen && (
          <SliderAnimation className="t-h-full t-shrink-0 t-grow-0 t-sticky t-top-0 t-border-0 t-border-l t-border-solid t-border-neutral-0 t-overflow-y-auto t-p-4 t-pt-0">
            <CommentsSlider
              activeComment={openComment}
              setActiveComment={setActiveComment}
              journalEntryFilters={params}
              onClose={commentPane.toggle}
              commentTypes={["LEDGER_ENTRY_COMMENT"]}
            />
          </SliderAnimation>
        )}
      </AnimatePresence>
    </div>
  );
};
