import dayjs from "dayjs";
import * as DATE_PERIOD from "constants/dateFilter";

type DateRange = {
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
};

export type DateRangeValue = (typeof DATE_PERIOD)[keyof typeof DATE_PERIOD];

export const getDateRange = (value: DateRangeValue): DateRange => {
  switch (value) {
    case "lastmonth": {
      return {
        startDate: dayjs().subtract(1, "month").startOf("month"),
        endDate: dayjs().subtract(1, "month").endOf("month"),
      };
    }
    case "currentmonth": {
      return {
        startDate: dayjs().startOf("month"),
        endDate: dayjs().endOf("month"),
      };
    }
    case "last3months": {
      return {
        startDate: dayjs().subtract(3, "month").startOf("month"),
        endDate: dayjs().subtract(1, "month").endOf("month"),
      };
    }
    case "last6months": {
      return {
        startDate: dayjs().subtract(6, "month").startOf("month"),
        endDate: dayjs().subtract(1, "month").endOf("month"),
      };
    }
    case "last12months": {
      return {
        startDate: dayjs().subtract(12, "month").startOf("month"),
        endDate: dayjs().subtract(1, "month").endOf("month"),
      };
    }
    case "currentYear": {
      return {
        startDate: dayjs().startOf("year"),
        endDate: dayjs(),
      };
    }
    case "lastYear": {
      return {
        startDate: dayjs().subtract(1, "year").startOf("year"),
        endDate: dayjs().subtract(1, "year").endOf("year"),
      };
    }
    case "last30days": {
      return {
        startDate: dayjs().subtract(30, "days"),
        endDate: dayjs(),
      };
    }
    case "last90days": {
      return {
        startDate: dayjs().subtract(90, "days"),
        endDate: dayjs(),
      };
    }
    case "last180days": {
      return {
        startDate: dayjs().subtract(180, "days"),
        endDate: dayjs(),
      };
    }
  }

  return {
    startDate: dayjs(),
    endDate: dayjs(),
  };
};
