import { ConditionalLink } from "components/conditionalLink";
import { Badge } from "components/design/badge";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { Link } from "components/icons/Link";
import { Price } from "components/Price";
import { ProductCard } from "components/ProductCard/ProductCard";
import { BOOKS_PLUS_PLAN_MANAGE } from "constants/addons";
import { INKLE_BOOKS_PRICING_URL } from "constants/inklePricing";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";

import { useModal } from "hooks/useModal";

import { resetAllAddonDataPayload } from "store/slices/addons";
import { CancelBookkeepingPlusPlanModal } from "./PlusPlanCancelModal";

const BookkeepingPlusPlanManage = () => {
  const dispatch = useAppDispatch();
  const { addonData, addonType } = useAppSelector((state) => state.addonsSlice);
  const cancelPlanModal = useModal();
  const isOpen = addonType === BOOKS_PLUS_PLAN_MANAGE;

  const onClose = () => {
    dispatch(resetAllAddonDataPayload());
  };

  return (
    <>
      <Modal.Root open={isOpen} onOpenChange={onClose}>
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>Manage Inkle Books (Plus Plan)</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            {addonData && (
              <ProductCard
                action={<></>}
                title={
                  <div className="t-flex t-justify-between">
                    <div className="t-flex t-gap-2">
                      {addonData.subscription_name}
                      {addonData.group_subscription && (
                        <div className="t-text-body">
                          <Badge color="light-green">Active</Badge>
                        </div>
                      )}
                    </div>
                    <Price
                      amount={addonData.amount}
                      size="medium"
                      cycle={addonData.billing_cycle}
                    />
                  </div>
                }
                price={<></>}
                description={
                  <div className="t-flex t-gap-4 t-flex-col t-mt-4 !t-text-white">
                    <div>
                      <h3 className="t-text-subtitle">
                        Here's what you are getting
                      </h3>
                      <ul className="t-m-0 t-text-body">
                        <li>Auto bank transactions data ingestion</li>
                        <li> Cash and accrual financial reports.</li>
                        <li>Metrics, journal entry and automations</li>
                      </ul>
                      <p className="t-mb-0 t-ml-6 t-mt-1.5">...... and more</p>
                    </div>
                    <ConditionalLink
                      to={INKLE_BOOKS_PRICING_URL}
                      className="t-w-max"
                    >
                      <Button customType="link">
                        <Link size="16" color="currentColor" />
                        <span className="t-ml-1">
                          See everything you get with Plus Plan
                        </span>
                      </Button>
                    </ConditionalLink>
                  </div>
                }
              />
            )}
          </Modal.Body>
          <Modal.Footer className="t-flex t-justify-between">
            <Button customType="ghost" onClick={cancelPlanModal.open}>
              Cancel subscription
            </Button>
            <ConditionalLink to={"/chat"}>
              <Button customType="primary">Contact us</Button>
            </ConditionalLink>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Root>
      <CancelBookkeepingPlusPlanModal
        isOpen={cancelPlanModal.isOpen}
        close={cancelPlanModal.close}
      />
    </>
  );
};

export default BookkeepingPlusPlanManage;
