import Async from "components/DesignSystem/AsyncComponents/Async";
import { Search } from "components/DesignSystem/Search/Search";
import GeneralLedgerAccordion from "components/GeneralLedgerAccordion/GeneralLedgerAccordion";
import { TransactionSlider } from "components/Transaction/Slider/TransactionSlider";
import DashboardContainer from "components/dashboard/DashboardContainer";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { usePageTitle } from "hooks/usePageTitle";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { ChangeEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetGeneralLedgerQuery,
  useGetMoneyInTransitQuery,
} from "store/apis/generalLedger";
import { setLedgerFilter } from "store/slices/generalLedger";
import { RootState } from "store/store";
import { debounce } from "utils/debouncing";
import { ExportLedger } from "./ExportLedger";
import { StartBookkeepingDateEmptyScreen } from "./GeneralLedgerEmptyScreen";
import { GeneralLedgerFilters } from "./GeneralLedgerFilters";
import { MoneyInTransitBanner } from "components/MoneyTransitBanner/MoneyInTransitBanner";
import { CommentsSlider } from "components/CommentsSlider/CommentsSlider";
import { useModal } from "hooks/useModal";
import { Button } from "components/DesignSystem/Button/Button";
import { ChatTeardropDots } from "components/icons/ChatTeardropDots";
import { AnimatePresence } from "framer-motion";
import { SliderAnimation } from "components/SliderAnimation/SliderAnimation";
import { motion } from "framer-motion";
import classNames from "classnames";
import { useAppSelector } from "hooks/useAppSelector";
import { NoteResponse } from "store/apis/notes";
import { setOpenComment } from "store/slices/openComment";
import { GeneralLedger as GeneralLedgerType } from "types/Models/books";
import { useHistory, useLocation } from "react-router-dom";
import { parse, stringify } from "qs";

const getTransactionCategory = (
  ledger: GeneralLedgerType[],
  transactionId: string
): string | null => {
  for (const category of ledger) {
    const transaction = category.transactions?.find(
      (transaction) => transaction.uuid === transactionId
    );

    if (transaction) {
      return category.uuid;
    }

    if (category.types) {
      const foundCategory = getTransactionCategory(
        category.types,
        transactionId
      );
      if (foundCategory) {
        return foundCategory;
      }
    }
  }

  return null;
};

export const GeneralLedger = () => {
  usePageTitle("General Ledger");
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { update, updateMultiple } = useUpdateQuery();
  const dispatch = useDispatch();
  const query = useQuery();
  const searchTerm = query.get("search_term");
  const commentPane = useModal();

  const openComment = useAppSelector((state) => state.openComment.openComment);

  const setActiveComment = (comment: NoteResponse) => {
    if (comment.transaction) {
      updateMultiple([
        {
          query: "selected_category_id",
          value: comment.transaction.category_id || null,
        },
        {
          query: "selected_transaction_id",
          value: comment.transaction.uuid || null,
        },
      ]);

      return dispatch(setOpenComment(comment.transaction.uuid));
    }

    if (comment.category) {
      updateMultiple([
        {
          query: "selected_category_id",
          value: comment.category?.uuid,
        },
        {
          query: "selected_transaction_id",
          value: null,
        },
      ]);

      return dispatch(setOpenComment(comment.category.uuid));
    }
  };

  const {
    filters: {
      cashFlow,
      minAmount,
      maxAmount,
      accountingMethod,
      startDate,
      endDate,
      hideZero,
      categoryIds,
    },
  } = useSelector((state: RootState) => state.ledgerFilter);

  const filterValues = {
    cashFlow,
    minAmount: ((minAmount.value || "") as string)
      ?.replaceAll("$", "")
      .replaceAll(",", ""),
    maxAmount: ((maxAmount.value || "") as string)
      ?.replaceAll("$", "")
      .replaceAll(",", ""),
    accountingMethod,
    startDate,
    endDate,
    hideZero,
    categoryIds,
    searchTerm: searchTerm,
  };

  const { data: moneyInTransit = [] } = useGetMoneyInTransitQuery(
    {
      groupId,
      entityId,
      startDate: startDate.value,
      endDate: endDate.value,
    },
    { skip: !groupId || !entityId, refetchOnMountOrArgChange: true }
  );

  const {
    data: generalLedgerData,
    isLoading,
    isSuccess,
  } = useGetGeneralLedgerQuery(
    {
      groupId,
      entityId,
      ...filterValues,
    },
    { skip: !groupId || !entityId, refetchOnMountOrArgChange: true }
  );

  const handleChange = debounce((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    update({ query: "search_term", value: value || null });
    dispatch(setLedgerFilter({ search_term: value }));
  });

  const isEmpty = !generalLedgerData || generalLedgerData?.length === 0;

  const selectedTransaction = query.get("selected_transaction_id");
  const selectedCategory =
    selectedTransaction && generalLedgerData
      ? getTransactionCategory(generalLedgerData, selectedTransaction)
      : query.get("category") || query.get("selected_category_id");

  const selectedCategoryFromQuery = query.get("selected_category_id");

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    let currentSearch = parse(location.search, { ignoreQueryPrefix: true });

    let timeout: NodeJS.Timeout;
    if (selectedCategoryFromQuery || selectedTransaction) {
      if (currentSearch.selected_transaction_id) {
        delete currentSearch.selected_transaction_id;
      }

      if (selectedCategoryFromQuery) {
        delete currentSearch.selected_category_id;
      }

      timeout = setTimeout(() => {
        history.replace(
          `${location.pathname}${stringify(currentSearch, {
            addQueryPrefix: true,
          })}`
        );
      }, 3000);
    }

    return () => clearTimeout(timeout);
  }, [
    history,
    location.pathname,
    location.search,
    selectedCategoryFromQuery,
    selectedTransaction,
  ]);

  return (
    <motion.div layout className="t-flex t-h-full t-pl-10">
      <div className="t-relative t-gap-5 t-flex t-flex-col t-mt-5 t-flex-1">
        <div
          className={classNames(
            "t-top-0 t-z-header t-flex t-flex-col t-gap-4",
            {
              "t-pr-10": !commentPane.isOpen,
              "t-pr-3": commentPane.isOpen,
            }
          )}
        >
          {moneyInTransit.length > 0 && (
            <MoneyInTransitBanner transactions={moneyInTransit} />
          )}
          <div className="t-bg-surface t-flex t-flex-col t-gap-4">
            <div className="t-flex t-justify-between t-gap-3">
              <div className="t-w-2/5">
                <Search block onChange={handleChange} placeholder="Search" />
              </div>

              <div className="t-ml-auto">
                <ExportLedger />
              </div>
              <Button
                customType="icon"
                onClick={commentPane.toggle}
                size="small"
              >
                <ChatTeardropDots />
              </Button>
            </div>
            <div>
              <GeneralLedgerFilters />
            </div>
          </div>
        </div>
        <div
          className={classNames("t-h-full t-overflow-y-auto", {
            "t-pr-10": !commentPane.isOpen,
            "t-pr-3": commentPane.isOpen,
          })}
        >
          <Async.Root {...{ isLoading: isLoading, isEmpty, isSuccess }}>
            <Async.Empty>
              <StartBookkeepingDateEmptyScreen />
            </Async.Empty>
            <Async.Success>
              <GeneralLedgerAccordion.Root>
                <GeneralLedgerAccordion.Trigger
                  data={generalLedgerData}
                  openCategory={selectedCategory}
                />
              </GeneralLedgerAccordion.Root>
              <TransactionSlider />
            </Async.Success>
          </Async.Root>
        </div>
      </div>
      <AnimatePresence>
        {commentPane.isOpen && (
          <SliderAnimation className="t-h-full t-shrink-0 t-grow-0 t-sticky t-top-0 t-border-0 t-border-l t-border-solid t-border-neutral-0 t-overflow-y-auto t-p-4 t-pt-0">
            <CommentsSlider
              activeComment={openComment}
              setActiveComment={setActiveComment}
              onClose={commentPane.toggle}
              commentTypes={[
                "TRANSACTION_CATEGORY_COMMENT",
                "TRANSACTION_COMMENT",
              ]}
              generalLedgerFilters={filterValues}
            />
          </SliderAnimation>
        )}
      </AnimatePresence>
    </motion.div>
  );
};
