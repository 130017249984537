import { getFileDownloadUrl } from "apis/documents";
import CommonTable from "components/design/commonTable";
import { DeleteModal } from "components/design/deleteModal";
import Loader from "components/design/loader";
import NoDataAvailable from "components/design/noDataAvailable";
import { Button } from "components/DesignSystem/Button/Button";
import { DeleteIcon } from "components/icons/delete";
import { DownloadIcon } from "components/icons/Download";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { usePageTitle } from "hooks/usePageTitle";
import { useToast } from "hooks/useToast";
import { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "static/styles/components/companyWForm.css";
import {
  GroupWForm,
  useCreateWFormsMutation,
  useDeleteWFormsMutation,
  useGetWFormsQuery,
} from "store/apis/groupWForms";
import { fileAndFolder } from "types/Models/documents";
import { openLink } from "utils/openLink";
import WFormHeader from "./WFormHeader";
import { useModal } from "hooks/useModal";
import { FormikValues } from "formik";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import { ArrayElement } from "types/utils/ArrayElement";
import { TableUI } from "components/design/TableUI";
import { formatDate } from "utils/formatDate";
import { EntitySelectForWform } from "components/EntitySelectForWform/EntitySelectForWform";

const WForms = () => {
  usePageTitle("W Forms");
  const history = useHistory();
  const { alertToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteModal, setDeleteModal] = useState(false);
  const [companyWFormId, setCompanyFormId] = useState("");
  const { data, isLoading, isSuccess } = useGetWFormsQuery(
    { groupId, currentPage },
    { skip: !groupId }
  );
  const [createWForm] = useCreateWFormsMutation();
  const [deleteWForm] = useDeleteWFormsMutation();
  const {
    company_group_forms: companyGroupWForms = [],
    current_page = 1,
    total_pages = 1,
  } = data || {};
  const noWForms = companyGroupWForms.length === 0;
  const selectEntityModal = useModal();
  const location = useLocation();
  const fullPath = encodeURIComponent(`${location.pathname}${location.search}`);

  useEffect(() => {
    setCurrentPage(current_page);
  }, [current_page]);

  const createCompanyWForm = async (values: FormikValues) => {
    try {
      const { entity } = values;
      const { uuid } = await createWForm({
        groupId,
        entityId: entity,
      }).unwrap();
      selectEntityModal.close();
      history.push(
        `/contractor-form/${uuid}?companywform=true&redirect_to=${fullPath}`
      );
    } catch (e: any) {
      alertToast({ message: e?.response?.data?.error?.message });
    }
  };

  const deleteCompanyWForm = async () => {
    try {
      await deleteWForm({
        companyWFormId,
        groupId,
      }).unwrap();
      setDeleteModal(false);
      setCompanyFormId("");
    } catch (e: any) {
      alertToast({ message: e?.response?.data?.error?.message });
    }
  };

  const openPreview = async ({
    fileId,
    groupId,
  }: {
    fileId: string;
    groupId: string;
  }) => {
    try {
      const response = await getFileDownloadUrl({
        groupId,
        fileId,
      });
      openLink(response.data.data.download_url);
    } catch (e: any) {
      alertToast({ message: e?.response?.data?.error?.message });
    }
  };

  const createColumn = createColumnHelper<ArrayElement<GroupWForm[]>>();

  const columns = useMemo(
    () => [
      createColumn.accessor("entity_name", {
        cell: (info) => (
          <span className="t-text-body t-font-medium t-text-purple-50">
            {info.getValue() || "-"}
          </span>
        ),
        header: "Entity Name",
        size: 40,
      }),
      createColumn.accessor("created_at", {
        cell: (info) => (
          <span className="t-text-body t-font-light t-text-text-60">
            {formatDate(info.getValue())}
          </span>
        ),
        header: "Date Created",
        size: 20,
      }),
      createColumn.accessor("w_form_type", {
        header: "Form Type",
        size: 40,
        cell: (info) => {
          const { w_form_type, uuid, form_document } = info.row.original;
          return (
            <div className="t-flex t-items-center t-justify-between t-pr-8">
              <span className="t-w-[100px] t-text-body t-font-semibold t-text-[black]">
                {w_form_type || "-"}
              </span>
              <div className="t-flex t-items-center">
                {form_document?.is_previewable ? (
                  <Button
                    customType="transparent"
                    onClick={() =>
                      openPreview({
                        fileId: form_document.uuid,
                        groupId,
                      })
                    }
                  >
                    <DownloadIcon color="#110733" size={20} />
                  </Button>
                ) : (
                  "-"
                )}
              </div>
              <Button
                customType="transparent"
                onClick={() => {
                  setDeleteModal(true);
                  setCompanyFormId(uuid);
                }}
              >
                <DeleteIcon color="#110733" size="20" />
              </Button>
            </div>
          );
        },
      }),
    ],
    [groupId]
  );

  const table = useReactTable({
    data: companyGroupWForms,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const closeDeleteModal = () => {
    setDeleteModal(false);
    setCompanyFormId("");
  };

  if (isLoading || !isSuccess) {
    return (
      <div className="t-w-full">
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <div>
        <WFormHeader createCompanyWForm={selectEntityModal.open} />
        {noWForms ? <NoDataAvailable /> : <TableUI table={table} />}
      </div>
      <DeleteModal
        show={deleteModal}
        closeModal={closeDeleteModal}
        onClick={deleteCompanyWForm}
        text="Delete w-form"
      />
      <EntitySelectForWform
        {...selectEntityModal}
        onSubmit={createCompanyWForm}
      />
    </div>
  );
};

export default WForms;
