export const CartMedium = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1682_622)">
        <path
          d="M14.0625 14.375H6.49766C6.20492 14.375 5.92149 14.2722 5.69677 14.0846C5.47205 13.897 5.32029 13.6365 5.26797 13.3484L3.275 2.38828C3.24884 2.24427 3.17296 2.11401 3.0606 2.02021C2.94824 1.92641 2.80652 1.87502 2.66016 1.875H1.25"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.5625 17.5C7.42544 17.5 8.125 16.8004 8.125 15.9375C8.125 15.0746 7.42544 14.375 6.5625 14.375C5.69956 14.375 5 15.0746 5 15.9375C5 16.8004 5.69956 17.5 6.5625 17.5Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.0625 17.5C14.9254 17.5 15.625 16.8004 15.625 15.9375C15.625 15.0746 14.9254 14.375 14.0625 14.375C13.1996 14.375 12.5 15.0746 12.5 15.9375C12.5 16.8004 13.1996 17.5 14.0625 17.5Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.88672 11.25H14.6953C14.988 11.25 15.2715 11.1472 15.4962 10.9596C15.7209 10.772 15.8727 10.5115 15.925 10.2234L16.875 5H3.75"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1682_622">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
