import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { Form, Formik } from "formik";
import { ModalProps } from "types/utils/modal";
import { object, string } from "yup";

export const AddNewFolder = ({
  isOpen,
  close,
  createFolder,
}: {
  createFolder: ({ folder_name }: { folder_name: string }) => Promise<void>;
} & ModalProps) => {
  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Formik
        onSubmit={createFolder}
        initialValues={{ folder_name: "" }}
        validationSchema={object().shape({
          folder_name: string()
            .required("Name is required")
            .matches(/^(?!\s*$).+/, "Invalid name"),
        })}
      >
        {({ submitForm, isSubmitting, isValid }) => (
          <Form>
            <Modal.Content>
              <Modal.Header>
                <Modal.Title>New Folder</Modal.Title>
                <Modal.Close />
              </Modal.Header>
              <Modal.Body>
                <TextInput
                  autoFocus
                  label="Name"
                  name="folder_name"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      submitForm();
                    }
                  }}
                />
              </Modal.Body>
              <Modal.FooterButtonGroup>
                <Button onClick={close} type="reset" disabled={isSubmitting}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  customType="primary"
                  onClick={submitForm}
                  disabled={!Boolean(isValid) || isSubmitting}
                  isLoading={isSubmitting}
                >
                  Save
                </Button>
              </Modal.FooterButtonGroup>
            </Modal.Content>
          </Form>
        )}
      </Formik>
    </Modal.Root>
  );
};
