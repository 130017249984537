import React from "react";
import EntityRelationShipOption from "./entityRelationShipOption";
import "../../../static/styles/components/entity.css";
import Modal from "components/DesignSystem/Modal/Modal";
import { Button } from "components/DesignSystem/Button/Button";

const DefinedRelationShip = ({
  defineModalShow,
  setShowDefineRelationshipModal,
}) => {
  const handleClose = () => {
    setShowDefineRelationshipModal(false);
  };

  return (
    <Modal.Root open={defineModalShow} onOpenChange={handleClose}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Define Relationship and IP Location</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body className="defineRelationShipModal">
          <div className="titleRow">
            <div className="entityTitle">Entity</div>
            <div className="typeTitle">Type</div>
            <div className="relationShipTitle">Relationship</div>
          </div>
          <div className="titleRow">
            <div className="entityTitle">Podd inc.</div>
            <div className="typeTitle">
              <EntityRelationShipOption /> <span className="ofText">of</span>
            </div>
            <div className="relationShipTitle">
              <EntityRelationShipOption />
            </div>
          </div>
          <div className="titleRow">
            <div className="entityTitle">Podd India Pvt. Ltd.</div>
            <div className="typeTitle">
              <EntityRelationShipOption /> <span className="ofText">of</span>
            </div>
            <div className="relationShipTitle">
              <EntityRelationShipOption />
            </div>
          </div>
        </Modal.Body>
        <Modal.FooterButtonGroup>
          <Button customType="primary" onClick={handleClose}>
            Save
          </Button>
        </Modal.FooterButtonGroup>
      </Modal.Content>
    </Modal.Root>
  );
};

export default DefinedRelationShip;
