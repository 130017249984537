import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { CREDIT_CARD_DELETED } from "constants/analyticsEvents";
import { useAnalytics } from "hooks/useAnalytics";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import authContext from "jwt_context&axios/authContext";
import { useContext } from "react";
import { useDeleteCardMutation } from "store/apis/billing";

const DeleteCardModal = ({
  show,
  closeModal,
  paymentMethodId,
  entityId,
}: {
  show: boolean;
  closeModal: () => void;
  paymentMethodId: string;
  entityId: string;
}) => {
  const { alertToast, successToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const {
    authtoken: { email },
  } = useContext(authContext);
  const [deleteCard, { isLoading }] = useDeleteCardMutation();
  const { trackEvent } = useAnalytics();

  const deleteSavedCard = async () => {
    try {
      await deleteCard({ groupId, paymentMethodId, entityId }).unwrap();
      trackEvent(CREDIT_CARD_DELETED, {
        group_id: groupId,
        email: email,
      });
      successToast({ message: "Card deleted successfully" });
      closeModal();
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
      closeModal();
    }
  };

  return (
    <Modal.Root open={show} onOpenChange={closeModal}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Delete this card?</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <div className="t-text-body-lg t-text-text-black">
            Are you sure you want to delete this card?
          </div>
        </Modal.Body>
        <Modal.FooterButtonGroup>
          <Modal.RawClose asChild>
            <Button onClick={closeModal}>Cancel</Button>
          </Modal.RawClose>
          <Button
            disabled={isLoading}
            customType="danger"
            isLoading={isLoading}
            onClick={deleteSavedCard}
          >
            Confirm
          </Button>
        </Modal.FooterButtonGroup>
      </Modal.Content>
    </Modal.Root>
  );
};

export default DeleteCardModal;
