import React from "react";

export const InkleSalesTaxLogo = () => {
  return (
    <svg
      width="191"
      height="21"
      viewBox="0 0 191 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.375 14.375C17.7103 13.5721 20 11.9145 20 10C20 8.08546 17.7103 6.42788 14.375 5.625C14.7181 6.94143 14.9112 8.42737 14.9112 10C14.9112 11.5726 14.7181 13.0586 14.375 14.375Z"
        fill="url(#paint0_linear_8954_2094)"
      />
      <path
        d="M5.625 14.375C2.28966 13.5721 0 11.9145 0 10C0 8.08546 2.28966 6.42788 5.625 5.625C5.28187 6.94143 5.08876 8.42737 5.08876 10C5.08876 11.5726 5.28187 13.0586 5.625 14.375Z"
        fill="url(#paint1_linear_8954_2094)"
      />
      <path
        d="M5.625 14.375C6.42788 17.7103 8.08546 20 10 20C11.9145 20 13.5721 17.7103 14.375 14.375C13.0586 14.7181 11.5726 14.9112 10 14.9112C8.42737 14.9112 6.94143 14.7181 5.625 14.375Z"
        fill="url(#paint2_linear_8954_2094)"
      />
      <path
        d="M5.625 5.625C6.42788 2.28966 8.08546 -8.70829e-08 10 0C11.9145 8.70829e-08 13.5721 2.28966 14.375 5.625C13.0586 5.28187 11.5726 5.08876 10 5.08876C8.42737 5.08876 6.94143 5.28187 5.625 5.625Z"
        fill="url(#paint3_linear_8954_2094)"
      />
      <path
        d="M29.9999 2.06497C29.9999 0.812065 30.7799 0 32.0057 0C33.2091 0 33.9891 0.812065 33.9891 2.06497C33.9891 3.31786 33.2091 4.15313 32.0057 4.15313C30.7799 4.15313 29.9999 3.31786 29.9999 2.06497ZM33.7886 19.884H30.2227V5.96288H33.7886V19.884Z"
        fill="url(#paint4_linear_8954_2094)"
      />
      <path
        d="M44.7288 12.9002C44.7288 10.8585 43.7036 9.51276 42.1213 9.51276C40.6281 9.51276 39.5361 10.9513 39.5361 12.9234V19.884H35.9702V5.96288H39.5361V7.58701C40.0041 6.54292 41.3858 5.80046 42.7676 5.80046C46.1774 5.80046 48.2946 8.63109 48.2946 12.9002V19.884H44.7288V12.9002Z"
        fill="url(#paint5_linear_8954_2094)"
      />
      <path
        d="M54.0118 19.884H50.446V0.162412H54.0118V12.2042L58.5359 5.96288H62.436L57.8896 12.9234L62.9041 19.884H58.3799L54.0118 13.6427V19.884Z"
        fill="url(#paint6_linear_8954_2094)"
      />
      <path
        d="M67.8048 19.884H64.2389V0.162412H67.8048V19.884Z"
        fill="url(#paint7_linear_8954_2094)"
      />
      <path
        d="M78.646 15.6148H82.4347C81.521 18.2135 79.1363 20 76.4174 20C72.5173 20 69.776 17.0534 69.776 12.877C69.776 8.83991 72.6064 5.77726 76.3505 5.77726C80.0946 5.77726 82.925 8.83991 82.925 12.877C82.925 13.3179 82.9027 13.7819 82.8359 14.1995H73.5201C73.9213 15.6148 75.0133 16.5197 76.3951 16.5197C77.4425 16.5197 78.2671 16.1485 78.646 15.6148ZM73.5201 11.5313H79.1809C78.7797 10.1624 77.7323 9.28074 76.3505 9.28074C74.9688 9.28074 73.9213 10.1624 73.5201 11.5313Z"
        fill="url(#paint8_linear_8954_2094)"
      />
      <path
        d="M134.706 15.8816H136.838C136.901 17.0942 138.113 18.0558 139.556 18.0767C140.894 18.0976 141.856 17.4705 141.856 16.467C141.856 15.2753 140.894 14.7736 138.971 14.251C137.026 13.7074 135.124 12.9757 135.124 10.6761C135.124 8.52278 137.047 7.18481 139.514 7.18481C141.939 7.18481 143.653 8.66912 143.863 10.9269H141.835C141.688 9.88166 140.727 9.10814 139.493 9.10814C138.302 9.08724 137.382 9.73532 137.361 10.6343C137.361 11.8677 138.887 12.2231 139.744 12.4531C141.647 12.9339 144.093 13.4984 144.093 16.4461C144.093 18.5157 142.127 20.0001 139.493 20.0001C137.131 20.0001 134.769 18.4321 134.706 15.8816Z"
        fill="#41395C"
      />
      <path
        d="M130.507 16.0277H132.89C132.033 18.3692 129.838 19.9789 127.329 19.9789C123.733 19.9789 121.204 17.3239 121.204 13.5609C121.204 9.92326 123.817 7.1637 127.266 7.1637C130.695 7.1637 133.35 9.90235 133.35 13.4563C133.35 13.7699 133.329 14.0835 133.266 14.3762H123.503C123.817 16.4668 125.301 17.8675 127.308 17.8675C128.772 17.8675 129.942 17.1148 130.507 16.0277ZM123.524 12.7246H131.05C130.737 10.7177 129.231 9.29608 127.266 9.29608C125.322 9.29608 123.838 10.6968 123.524 12.7246Z"
        fill="#41395C"
      />
      <path
        d="M119.144 19.8739H116.886V2.104H119.144V19.8739Z"
        fill="#41395C"
      />
      <path
        d="M112.199 19.8746V17.5332C111.634 18.9966 109.836 20.0001 107.955 20.0001C104.359 20.0001 101.976 17.4495 101.976 13.582C101.976 9.73532 104.338 7.18481 107.955 7.18481C109.815 7.18481 111.634 8.18829 112.199 9.6726V7.33115H114.436V19.8746H112.199ZM104.234 13.582C104.234 16.0907 105.864 17.7422 108.227 17.7422C110.568 17.7422 112.199 16.0907 112.199 13.6029C112.199 11.136 110.526 9.42173 108.206 9.42173C105.843 9.42173 104.234 11.0733 104.234 13.582Z"
        fill="#41395C"
      />
      <path
        d="M88 14.5435H90.2369C90.3833 16.5086 92.223 17.8466 94.3554 17.8466C96.5505 17.8466 98.2438 16.6759 98.2647 14.9198C98.2856 12.871 96.4459 12.2438 94.4599 11.763C91.7212 11.094 88.5436 10.655 88.5436 6.78742C88.5645 3.52612 91.1568 2 94.3135 2C97.3658 2 100.021 3.65156 100.104 6.89195H97.8675C97.7212 5.11496 96.1742 4.15329 94.3135 4.15329C92.3693 4.15329 90.8014 5.05224 90.7805 6.74561C90.7596 8.77347 92.662 9.10796 94.8571 9.63061C97.533 10.2578 100.543 11.2822 100.523 14.8989C100.502 17.8466 97.8675 19.979 94.3763 19.9999C91.0104 19.9999 88.1254 17.972 88 14.5435Z"
        fill="#41395C"
      />
      <path
        d="M174.267 20.0169L178.725 13.7086L174.267 7.40039H176.98L180.071 11.7741L183.162 7.40039H185.895L181.437 13.7086L185.895 20.0169H183.162L180.071 15.6432L176.98 20.0169H174.267Z"
        fill="#41395C"
      />
      <path
        d="M170.393 20.0176V17.6625C169.825 19.1344 168.017 20.1438 166.124 20.1438C162.507 20.1438 160.11 17.5784 160.11 13.6883C160.11 9.81926 162.486 7.25391 166.124 7.25391C167.996 7.25391 169.825 8.26323 170.393 9.75618V7.4011H172.643V20.0176H170.393ZM162.381 13.6883C162.381 16.2116 164.021 17.8728 166.398 17.8728C168.753 17.8728 170.393 16.2116 170.393 13.7094C170.393 11.2281 168.711 9.50385 166.377 9.50385C164 9.50385 162.381 11.165 162.381 13.6883Z"
        fill="#41395C"
      />
      <path
        d="M155.236 20.0169H152.986V4.3935H147.54V2.14355H160.682V4.3935H155.236V20.0169Z"
        fill="#41395C"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8954_2094"
          x1="14.375"
          y1="10"
          x2="20"
          y2="10"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8954_2094"
          x1="0"
          y1="10"
          x2="5.625"
          y2="10"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_8954_2094"
          x1="5.625"
          y1="17.1875"
          x2="14.375"
          y2="17.1875"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_8954_2094"
          x1="5.625"
          y1="2.8125"
          x2="14.375"
          y2="2.8125"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_8954_2094"
          x1="29.9999"
          y1="10"
          x2="82.925"
          y2="10"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_8954_2094"
          x1="29.9999"
          y1="10"
          x2="82.925"
          y2="10"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_8954_2094"
          x1="29.9999"
          y1="10"
          x2="82.925"
          y2="10"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_8954_2094"
          x1="29.9999"
          y1="10"
          x2="82.925"
          y2="10"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_8954_2094"
          x1="29.9999"
          y1="10"
          x2="82.925"
          y2="10"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
