import { TooltipContentProps } from "@radix-ui/react-tooltip";
import ConditionalToolTip from "components/design/conditionalToolTip";
import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import { ENTITY_SWITCHER_CLICKED } from "constants/analyticsEvents";
import { useAnalytics } from "hooks/useAnalytics";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { useEffect } from "react";
import ReactCountryFlag from "react-country-flag";
import { useHistory } from "react-router-dom";
import { useGetGroupDataQuery } from "store/apis/group";
import { entitiesSortedByCountry } from "utils/entitiesSortedByCountry";

type EntitySelectorProps = {
  showOnlyUSEntities?: boolean;
  handleEntitySelect?: (entityId: string) => void;
  toolTipPlacement?: TooltipContentProps["side"];
  right?: number;
  disableNonUsEntities?: boolean;
  showGoToEntity?: boolean;
  size?: "small" | "regular" | "large";
};

const Label = ({
  country,
  country_code,
  name,
}: {
  name: string;
  country_code: string;
  country: string;
}) => (
  <div className="t-flex t-items-center t-gap-2 group-[[data-disabled]]:t-text-neutral-30">
    <ReactCountryFlag countryCode={country_code} svg title={country} />
    <span className="t-truncate t-max-w-36">{name}</span>
  </div>
);

export const EntitySelector = ({
  showOnlyUSEntities = false,
  toolTipPlacement = "left",
  handleEntitySelect,
  right,
  disableNonUsEntities = false,
  showGoToEntity = false,
  size = "regular",
}: EntitySelectorProps) => {
  const { update } = useUpdateQuery();
  const query = useQuery();
  let selectedEntityId = query.get("entity");
  const groupId = query.get("company") || "";
  const { entities } = useCurrentGroupContext();
  const { sortedEntities, usEntities } = entitiesSortedByCountry(entities);
  const displayedEntities = showOnlyUSEntities ? usEntities : sortedEntities;
  selectedEntityId ??= displayedEntities?.[0]?.uuid;
  const history = useHistory();
  const { trackEvent } = useAnalytics();

  const { isFetching } = useGetGroupDataQuery({ groupId }, { skip: !groupId });

  const {
    name: selectedEntityName,
    country: selectedEntityCountry,
    country_code: selectedEntityCountryCode,
    uuid: currentSelectedEntityId,
  } = sortedEntities.find(({ uuid }) => uuid === selectedEntityId) ||
  displayedEntities?.[0] ||
  {};

  useEffect(() => {
    if (currentSelectedEntityId) {
      update({
        query: "entity",
        value: currentSelectedEntityId,
      });
    }
  }, [currentSelectedEntityId]);

  const onEntitySelect = ({ uuid }: { uuid: string }) => {
    trackEvent(ENTITY_SWITCHER_CLICKED, {
      url: window.location.href,
    });
    if (handleEntitySelect) {
      handleEntitySelect(uuid);
      return;
    }
    update({ query: "entity", value: uuid });
  };

  const entityOptions = displayedEntities.map(
    ({ name, uuid, country, country_code }) => {
      const disableNonUsEntity = disableNonUsEntities && country_code !== "US";
      return {
        isDisabled: disableNonUsEntity,
        label: (
          <ConditionalToolTip
            condition={disableNonUsEntity && "We only support US entities."}
          >
            <span>
              <Label
                country={country}
                country_code={country_code}
                name={name}
              />
            </span>
          </ConditionalToolTip>
        ),
        value: uuid,
      };
    }
  );

  const actionComponent = showGoToEntity ? (
    <Button customType="link" onClick={() => history.push("/tax/entities")}>
      Go to entities
    </Button>
  ) : (
    <></>
  );

  const isEntitySelected =
    selectedEntityId &&
    selectedEntityCountry &&
    selectedEntityName &&
    selectedEntityCountryCode;

  const selectedEnity = isEntitySelected
    ? {
        label: (
          <Label
            country={selectedEntityCountry}
            name={selectedEntityName}
            country_code={selectedEntityCountryCode}
          />
        ),
        value: selectedEntityId,
      }
    : null;

  return (
    <ConditionalToolTip
      condition={isEntitySelected && selectedEntityName}
      side={toolTipPlacement}
    >
      <span>
        <Combobox
          actions={actionComponent}
          menuPlacement="auto"
          menuPortalTarget={document.body}
          size={size}
          tooltip
          creatable={false}
          isClearable={false}
          options={entityOptions}
          isDisabled={!isEntitySelected || isFetching}
          isLoading={!isEntitySelected || isFetching}
          onChange={(value: any) => {
            onEntitySelect({ uuid: value.value });
          }}
          styles={{
            menu: (base) => ({
              ...base,
              width: "230px",
              zIndex: 1210,
              right: right,
            }),
          }}
          value={selectedEnity}
        />
      </span>
    </ConditionalToolTip>
  );
};
