import React from "react";

export const AICategorised = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
    >
      <circle
        cx="16.5541"
        cy="16.5541"
        r="15.5608"
        fill="#F3F3F5"
        stroke="#BBA7FF"
        strokeWidth="1.98649"
        strokeDasharray="3.97 3.97"
      />
      <path
        d="M24.2283 17.9323C24.2302 18.1749 24.1565 18.4121 24.0174 18.6109C23.8783 18.8097 23.6807 18.9602 23.4521 19.0415L18.7624 20.7475L17.0577 25.4379C16.9737 25.6643 16.8224 25.8596 16.6242 25.9975C16.4259 26.1354 16.1903 26.2093 15.9488 26.2093C15.7074 26.2093 15.4717 26.1354 15.2735 25.9975C15.0753 25.8596 14.924 25.6643 14.84 25.4379L13.1353 20.7467L8.44551 19.0415C8.21915 18.9575 8.02393 18.8061 7.88607 18.6078C7.74821 18.4096 7.67432 18.1738 7.67432 17.9323C7.67432 17.6908 7.74821 17.455 7.88607 17.2568C8.02393 17.0585 8.21915 16.9071 8.44551 16.8231L13.1353 15.1179L14.84 10.4267C14.924 10.2003 15.0753 10.005 15.2735 9.86709C15.4717 9.72919 15.7074 9.65527 15.9488 9.65527C16.1903 9.65527 16.4259 9.72919 16.6242 9.86709C16.8224 10.005 16.9737 10.2003 17.0577 10.4267L18.7631 15.1179L23.4521 16.8231C23.6807 16.9044 23.8783 17.0549 24.0174 17.2537C24.1565 17.4525 24.2302 17.6897 24.2283 17.9323Z"
        fill="#BBA7FF"
      />
      <path
        d="M25.8838 11.3108C25.8846 11.4079 25.8551 11.5027 25.7994 11.5823C25.7438 11.6618 25.6648 11.722 25.5733 11.7545L23.6974 12.4369L23.0156 14.313C22.9819 14.4036 22.9214 14.4817 22.8421 14.5369C22.7629 14.5921 22.6686 14.6216 22.572 14.6216C22.4754 14.6216 22.3812 14.5921 22.3019 14.5369C22.2226 14.4817 22.1621 14.4036 22.1285 14.313L21.4466 12.4366L19.5707 11.7545C19.4801 11.7209 19.4021 11.6603 19.3469 11.581C19.2918 11.5017 19.2622 11.4074 19.2622 11.3108C19.2622 11.2142 19.2918 11.1199 19.3469 11.0406C19.4021 10.9613 19.4801 10.9007 19.5707 10.8671L21.4466 10.185L22.1285 8.30857C22.1621 8.218 22.2226 8.13989 22.3019 8.08473C22.3812 8.02957 22.4754 8 22.572 8C22.6686 8 22.7629 8.02957 22.8421 8.08473C22.9214 8.13989 22.9819 8.218 23.0156 8.30857L23.6977 10.185L25.5733 10.8671C25.6648 10.8996 25.7438 10.9598 25.7994 11.0394C25.8551 11.1189 25.8846 11.2138 25.8838 11.3108Z"
        fill="#BBA7FF"
      />
    </svg>
  );
};
