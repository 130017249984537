import axios from "axios";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

//components
import { AuthFooter } from "../design/authFooter";
import { Button } from "components/DesignSystem/Button/Button";

//constants
const UpdatePassword_url =
  process.env.REACT_APP_BASE_URL + "api/inkle/users/set-new-password/";

const Password = ({ prev, handleChange, values }) => {
  const history = useHistory();

  const [error, setError] = useState({
    password: false,
    cnf_password: false,
  });

  const Continue = () => {
    if (values.password.length < 8 || values.password !== values.cnf_password) {
      if (values.password.length < 8) {
        setError((prevState) => ({
          ...prevState,
          password: "Password must be at least 8 characters long",
        }));
      } else {
        setError((prevState) => ({
          ...prevState,
          password: false,
        }));
        if (values.cnf_password === values.password) {
          setError((prevState) => ({
            ...prevState,
            cnf_password: false,
          }));
        } else
          setError((prevState) => ({
            ...prevState,
            cnf_password: "Password does not match",
          }));
      }
    } else {
      send();
    }
  };

  const send = () => {
    axios
      .post(UpdatePassword_url, {
        token: values.token,
        password: values.password,
        confirm_password: values.cnf_password,
      })
      .then((res) => {
        if (res.data.success) {
          history.push("/signIn");
        } else {
          history.push("/forgotPassword");
        }
      })
      .catch(() => {
        history.push("/forgotPassword");
      });
  };

  const handChange = (p, event) => {
    let value;
    if (event.target) {
      value = event.target.value;
    } else {
      value = event;
    }

    handleChange(p, value);
    setError((prevState) => ({
      ...prevState,
      [p]: false,
    }));
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      Continue();
    }
  };

  const Previous = (e) => {
    e.preventDefault();
    prev();
  };

  return (
    <div className="rightBox">
      <div className="navigation">
        <div className="backBtn" onClick={Previous} aria-hidden="true">
          <i className="fas fa-arrow-left"></i>
        </div>
        <div className="credentials">
          <span className="helpMessage">
            {values.email_id || values.phone_no}
          </span>
        </div>
      </div>
      <h1 className="accountHead">Create your password</h1>
      {/* <span className="accountSubhead">Start your journey</span> */}
      <form className="homeForm">
        <input
          type="password"
          className={error.password !== false ? "errorbox" : ""}
          name="password"
          placeholder="Password"
          onChange={(e) => handChange("password", e)}
          value={values.password}
        />{" "}
        <br />
        {error.password !== false ? (
          <span className="errorMessage">
            {error.password}
            <br />
          </span>
        ) : (
          ""
        )}
        <input
          type="password"
          className={error.cnf_password !== false ? "errorbox" : ""}
          name="cnf_password"
          placeholder="Confirm Password"
          onChange={(e) => handChange("cnf_password", e)}
          onKeyPress={handleEnter}
          value={values.cnf_password}
        />{" "}
        <br />
        {error.cnf_password !== false ? (
          <span className="errorMessage">
            {error.cnf_password}
            <br />
          </span>
        ) : (
          ""
        )}
      </form>
      <Button onClick={Continue}>Submit</Button>
      <AuthFooter />
    </div>
  );
};

export default Password;
