import classNames from "classnames";
import React, { HTMLAttributes } from "react";
import Placeholder from "static/images/Imageplaceholder.svg";

import { useHistory, useRouteMatch } from "react-router-dom";
import { useQuery, useUpdateQuery } from "hooks/useQuery";

const PerkCard = (props: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className={`t-rounded-lg t-border t-border-solid t-border-neutral-10 t-bg-surface ${
        props.className || ""
      }`}
    >
      {props.children}
    </div>
  );
};

export const PerkCards = ({ perk, isPreview }: any) => {
  const {
    name = "Company Name",
    icon = null,
    offer_description = "Your Offer",
    is_redeemed,
    icon_bg_color,
  } = perk;
  const { url } = useRouteMatch();
  const queryParam = useQuery();
  const currentSort = queryParam.get("sort") || "";

  const history = useHistory();
  const { update } = useUpdateQuery();

  const openModal = (id: string) => {
    history.push(`${url}/${id}`);
    update({ query: "sort", value: currentSort });
  };
  const imageIcon = icon && isPreview ? icon.preview : icon;
  return (
    <span
      className={classNames({
        redeemedCard: is_redeemed,
      })}
      onClick={() => !isPreview && openModal(perk.slug)}
    >
      <PerkCard className="t-overflow-hidden t-group t-rounded-lg t-h-[212px] t-min-w-[225px]">
        <div
          style={{ backgroundColor: icon_bg_color }}
          className="t-h-[100px] t-overflow-hidden t-bg-neutral-0 t-w-full bottom-border t-border t-px-[15%] t-flex t-items-center t-justify-center t-flex-col !t-border-neutral-10"
        >
          <img
            src={icon ? imageIcon : Placeholder}
            alt="perks logo"
            className={classNames(
              "t-transform t-transition-transform group-hover:t-scale-[0.90]",
              {
                "t-object-contain t-self-stretch": Boolean(icon),
                "t-w-16": !Boolean(icon),
              }
            )}
          />
        </div>
        <div className="t-px-6 t-py-4 t-transform t-transition-transform">
          <div className="t-mb-2 t-text-subtitle t-text-text-100 t-truncate">
            {name}
          </div>
          {offer_description && (
            <div
              className={classNames(
                "t-text-subtext-sm t-leading-[16px] t-flex t-items-center t-justify-center t-h-[56px] t-bg-surface-lighter-grey t-rounded t-self-stretch t-leading-6 t-text-center t-tracking-tighter t-p-2 group-hover:t-bg-purple-0 big-dashed-border",
                {
                  disabledDetailsContainer: is_redeemed,
                }
              )}
            >
              <div className="t-line-clamp-2 t-text-text-30 group-hover:t-text-purple">
                {offer_description || ""}
              </div>
            </div>
          )}
        </div>
      </PerkCard>
    </span>
  );
};
