import { ConditionalLink } from "components/conditionalLink";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import { Button } from "components/DesignSystem/Button/Button";
import { HighlightSearchTerm } from "components/DesignSystem/HighlightText";
import { Cart } from "components/icons/Cart";
import { ProductCard } from "components/ProductCard/ProductCard";
import { useConstructInternalLink } from "hooks/useConstructInternalLink";
import { useQuery } from "hooks/useQuery";
import { useLocation, useRouteMatch } from "react-router-dom";
import { Product } from "store/apis/products";
import { parse, stringify } from "qs";

export const ProductTypeCard = ({ product }: { product: Product }) => {
  const query = useQuery();
  const search = query.get("search") || "";
  const { link } = useConstructInternalLink();
  const { url } = useRouteMatch();
  const location = useLocation();
  const parsedSearch = parse(location.search, { ignoreQueryPrefix: true });
  const queryParams = stringify(
    {
      ...parsedSearch,
      is_send_invoice_open: true,
    },
    { skipNulls: true, addQueryPrefix: true }
  );

  return (
    <ProductCard
      key={product.uuid}
      title={<HighlightSearchTerm text={product.title} searchTerm={search} />}
      description={product.display_text}
      price={
        !isNaN(Number(product.approx_price)) ? (
          <p className="t-m-0 t-text-subtext t-text-text-100">
            <AmountSuperScript amount={Number(product.approx_price)} />
            {product.billing_period && (
              <span className="t-text-body-sm t-text-text-30">
                /{product.billing_period}
              </span>
            )}
          </p>
        ) : (
          <span />
        )
      }
      action={
        <ConditionalLink
          to={link(`${url}/add-to-cart/${product.uuid}${queryParams}`)}
        >
          <Button size="small">
            <div className="t-flex t-gap-2 t-items-center">
              <Cart /> Add to cart
            </div>
          </Button>
        </ConditionalLink>
      }
    />
  );
};
