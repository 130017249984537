import { TagCombobox } from "components/TagCombobox/TagCombobox";
import { useToast } from "hooks/useToast";
import { useContext, useState } from "react";
import authContext from "../../jwt_context&axios/authContext";
import "../../static/styles/components/taskAssignee.css";
import Tag from "../design/Tag";

const CustomerTags = ({ tags }) => {
  if (!tags || tags?.length === 0) {
    return "-";
  }

  return (
    <div className="badgeGroup">
      {tags?.map((tag) => (
        <Tag key={tag.uuid} {...tag} />
      ))}
    </div>
  );
};

export default function Tags({ taskId, currentTags, tags, updateTags }) {
  const { alertToast } = useToast();
  const { authtoken } = useContext(authContext);

  const [isLoading, setLoading] = useState(false);

  const onTagsChange = async (selectedTags) => {
    const addedTag = selectedTags.filter(
      ({ value }) => !currentTags?.some(({ uuid }) => uuid === value)
    )?.[0];

    const removedTag = currentTags?.filter(
      ({ uuid }) => !selectedTags?.some(({ value }) => uuid === value)
    )?.[0];

    try {
      if (removedTag) {
        setLoading(true);
        await updateTags({
          groupId: taskId,
          payLoad: { remove_tag_id: removedTag.uuid },
        });
        setLoading(false);
      } else {
        setLoading(true);
        await updateTags({
          groupId: taskId,
          payLoad: { tag_id: addedTag.value },
        });

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alertToast({ message: error.data?.error?.message });
    }
  };

  if (!authtoken.is_service_user) {
    return <CustomerTags tags={currentTags} />;
  }

  return (
    <div className="t-w-min">
      <TagCombobox
        tags={tags}
        currentTags={currentTags}
        onTagsChange={onTagsChange}
        isLoading={isLoading}
      />
    </div>
  );
}
