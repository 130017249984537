export const DataSources = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_6445_1866)">
      <g clip-path="url(#clip1_6445_1866)">
        <path
          d="M2.125 5.33331H11.875L7 2.33331L2.125 5.33331Z"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.625 5.33331V9.08331"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.875 5.33331V9.08331"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.125 5.33331V9.08331"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.3441 5.6333L10.3441 9.16666"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.20001 9.16663H10.175"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.99402 10.9763L8.33992 10.9763"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g clip-path="url(#clip2_6445_1866)">
        <path
          d="M10.8678 12.6667H10.2247C9.82688 12.6667 9.44535 12.5087 9.16404 12.2273C8.88274 11.946 8.7247 11.5645 8.7247 11.1667C8.7247 10.7689 8.88274 10.3873 9.16404 10.106C9.44535 9.82472 9.82688 9.66669 10.2247 9.66669H10.8678"
          fill="white"
        />
        <path
          d="M10.8678 12.6667H10.2247C9.82688 12.6667 9.44535 12.5087 9.16404 12.2273C8.88274 11.946 8.7247 11.5645 8.7247 11.1667C8.7247 10.7689 8.88274 10.3873 9.16404 10.106C9.44535 9.82472 9.82688 9.66669 10.2247 9.66669H10.8678"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.1148 9.66669H13.5132C13.911 9.66669 14.2925 9.82472 14.5738 10.106C14.8551 10.3873 15.0132 10.7689 15.0132 11.1667C15.0132 11.5645 14.8551 11.946 14.5738 12.2273C14.2925 12.5087 13.911 12.6667 13.5132 12.6667H13.1148"
          fill="white"
        />
        <path
          d="M13.1148 9.66669H13.5132C13.911 9.66669 14.2925 9.82472 14.5738 10.106C14.8551 10.3873 15.0132 10.7689 15.0132 11.1667C15.0132 11.5645 14.8551 11.946 14.5738 12.2273C14.2925 12.5087 13.911 12.6667 13.5132 12.6667H13.1148"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.5606 11.1667H12.447"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_6445_1866">
        <rect width="16" height="16" fill="white" />
      </clipPath>
      <clipPath id="clip1_6445_1866">
        <rect
          width="12"
          height="12"
          fill="white"
          transform="translate(1 0.833313)"
        />
      </clipPath>
      <clipPath id="clip2_6445_1866">
        <rect
          width="8"
          height="8"
          fill="white"
          transform="translate(8 7.16669)"
        />
      </clipPath>
    </defs>
  </svg>
);
