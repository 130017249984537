import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import Modal from "components/DesignSystem/Modal/Modal";
import { Label, TextInput } from "components/DesignSystem/TextInput/TextInput";
import { FileInput } from "components/FileInput/FileInput";
import { SERVICE_TEAM_LABEL } from "constants/bookkeeping";
import { INKLE_TEAM_AND_CPA_TEAM } from "constants/chatType";
import { PRO } from "constants/subscriptionType";
import dayjs from "dayjs";
import { Form, Formik, FormikValues, useFormikContext } from "formik";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { ReactNode, useMemo } from "react";
import { useBookkeepingCRMFilterOptionsQuery } from "store/apis/bookkeepingTracker";
import { useGetServiceTeamQuery } from "store/apis/serviceTeam";
import { openDeleteMonthCardModal } from "store/slices/financialClosing";
import { MonthlySummary, SeasonResponse } from "types/Models/overview";
export type Reports =
  | "quarterly_observation_overview"
  | "profit_loss_statement"
  | "cash_flow_statement"
  | "balance_sheet"
  | "monthly_observation_overview";

const FileUpload = ({
  fileType,
  children,
  onReportDelete,
}: {
  fileType: Reports;
  children: ReactNode;
  onReportDelete: ({ field }: { field: Reports }) => Promise<void>;
}) => {
  const { values, setFieldValue } = useFormikContext<FormikValues>();

  const onDelete = async () => {
    try {
      await onReportDelete({ field: fileType });
      setFieldValue(fileType, null);
    } catch (error) {}
  };

  return (
    <FileInput
      withForm
      label={children}
      name={fileType}
      file={values[fileType]}
      withInkleDocs
      onDelete={onDelete}
    />
  );
};
const AssigneeFields = ({ summary }: { summary: MonthlySummary }) => {
  const {
    values: { team, assignee },
    setFieldValue,
  } = useFormikContext<{
    team: typeof INKLE_TEAM_AND_CPA_TEAM;
    assignee: string;
  }>();
  const { isCustomer } = useRoleBasedView();
  const { data: serviceTeamData, isLoading: isTeamsLoading } =
    useBookkeepingCRMFilterOptionsQuery();
  const { teams = [] } = serviceTeamData || {};
  const { data: assignees = [], isLoading: isAssigneesLoading } =
    useGetServiceTeamQuery(
      {
        accessible_teams: team,
      },
      { skip: isCustomer }
    );
  const selectedAssignee = assignees.find(({ uuid }) => uuid === assignee);
  const selectedTeam = teams.find((currTeam) => currTeam == team);
  return (
    <div className="t-flex t-w-full t-items-center t-gap-4">
      <Combobox
        required
        withForm
        block
        name="team"
        label="Team"
        options={teams.map((team) => ({
          label: SERVICE_TEAM_LABEL[team],
          value: team,
        }))}
        onInputChange={() => {
          setFieldValue("assignee", null);
        }}
        value={
          selectedTeam
            ? {
                label: SERVICE_TEAM_LABEL[selectedTeam],
                value: selectedTeam,
              }
            : null
        }
        menuPortalTarget={document.body}
        backspaceRemovesValue={false}
        escapeClearsValue={false}
        isClearable={false}
      />
      <Combobox
        required
        withForm
        block
        name="assignee"
        label="Assignee"
        options={assignees.map(({ name, uuid }) => ({
          label: name,
          value: uuid,
        }))}
        value={
          selectedAssignee
            ? {
                label: selectedAssignee?.name,
                value: selectedAssignee?.uuid,
              }
            : null
        }
        menuPortalTarget={document.body}
        isClearable={false}
        isDisabled={!team}
      />
    </div>
  );
};
export const AddSummaryModal = ({
  onSubmit,
  summary,
  summaryFor,
  month,
  onReportDelete,
  isDeleteAllowed = false,
  onClose,
}: {
  onSubmit: (values: any) => void;
  summary: MonthlySummary | SeasonResponse;
  summaryFor: "year" | "month";
  month?: string;
  onReportDelete: ({ field }: { field: Reports }) => Promise<void>;
  isDeleteAllowed?: boolean;
  onClose?: () => void;
}) => {
  const dispatch = useAppDispatch();
  const { isCpa, isAdmin, isForeignCA } = useRoleBasedView();

  const {
    expenses,
    number_of_transactions,
    cash_flow_statement,
    profit_loss_statement,
    balance_sheet,
    quarterly_observation_overview,
    membership,
    uuid,
  } = summary || {};
  const isPro = membership === PRO;
  const { current_assigned_agent } = summary as MonthlySummary;
  const { profile_id: assignee, team } = current_assigned_agent || {};
  const isQuarterReportNeeded =
    ["March", "June", "September", "December"].includes(
      dayjs(month).format("MMMM")
    ) && summaryFor === "month";

  const initialValues = useMemo(() => {
    return summaryFor === "month"
      ? Object.entries({
          expenses,
          number_of_transactions,
          cash_flow_statement,
          profit_loss_statement,
          balance_sheet,
          quarterly_observation_overview,
          assignee,
          team,
        })
          .map(([key, value]) => ({
            [key]: value || "",
          }))
          .reduce((acc, item) => Object.assign(acc, item), {})
      : Object.entries({
          expenses,
          number_of_transactions,
          cash_flow_statement,
          profit_loss_statement,
          balance_sheet,
        })
          .map(([key, value]) => ({
            [key]: value || "",
          }))
          .reduce((acc, item) => Object.assign(acc, item), {});
  }, [summaryFor, summary]);

  const onDelete = () => {
    dispatch(openDeleteMonthCardModal(uuid));
    onClose?.();
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ isSubmitting }) => {
        return (
          <Form className="all:unset">
            <Modal.Body className="t-flex t-flex-col t-gap-4">
              <TextInput
                block
                label="Expense Excluding TP Volume"
                name="expenses"
                type="number"
                placeholder="Enter amount"
                required={isPro}
              />
              <TextInput
                label="Number of Transactions"
                name="number_of_transactions"
                type="number"
                placeholder="Enter number of transactions"
              />
              <div>
                <Label>Reports</Label>
                <div className="t-flex t-flex-col t-gap-3">
                  {isQuarterReportNeeded && (
                    <FileUpload
                      onReportDelete={onReportDelete}
                      fileType="quarterly_observation_overview"
                    >
                      Quarterly Observation Overview
                    </FileUpload>
                  )}
                  <FileUpload
                    onReportDelete={onReportDelete}
                    fileType="profit_loss_statement"
                  >
                    Profit/Loss Statement
                  </FileUpload>
                  <FileUpload
                    onReportDelete={onReportDelete}
                    fileType="cash_flow_statement"
                  >
                    Cash Flow Statement
                  </FileUpload>
                  <FileUpload
                    onReportDelete={onReportDelete}
                    fileType="balance_sheet"
                  >
                    Balance sheet
                  </FileUpload>
                </div>
              </div>
              {(isCpa || isAdmin || isForeignCA) && (
                <AssigneeFields summary={summary as MonthlySummary} />
              )}
            </Modal.Body>
            <Modal.FooterButtonGroup>
              {isDeleteAllowed && (isCpa || isAdmin || isForeignCA) && (
                <Button
                  type="button"
                  customType="danger-outlined"
                  size="small"
                  onClick={onDelete}
                >
                  Delete
                </Button>
              )}
              <Button
                customType="primary"
                size="small"
                disabled={isSubmitting}
                isLoading={isSubmitting}
              >
                Submit
              </Button>
            </Modal.FooterButtonGroup>
          </Form>
        );
      }}
    </Formik>
  );
};
