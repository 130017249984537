export const Shareholder = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
  >
    <g clipPath="url(#clip0_3395_10335)">
      <path
        d="M8.30151 14.0815C11.6152 14.0815 14.3015 11.3953 14.3015 8.08154C14.3015 4.76783 11.6152 2.08154 8.30151 2.08154C4.98781 2.08154 2.30151 4.76783 2.30151 8.08154C2.30151 11.3953 4.98781 14.0815 8.30151 14.0815Z"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.30151 8.08154V2.08154"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4977 5.08154L3.10522 11.0815"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3395_10335">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0.301514 0.081543)"
        />
      </clipPath>
    </defs>
  </svg>
);
