import { ChannelForwardChannelSelector } from "components/ChannelForwardChannelSelector/ChannelForwardChannelSelector";
import { ChannelHeader } from "components/ChannelHeader/ChannelHeader";
import { ChannelHeaderWithOnlySwitch } from "components/ChannelHeaderWithOnlySwitch/ChannelHeaderWithOnlySwitch";
import { ChannelMediaSlider } from "components/ChannelMediaSlider/ChannelMediaSlider";
import { ChannelMessageSearch } from "components/ChannelMessageSearch/ChannelMessageSearch";
import { ChannelPendingActions } from "components/ChannelinfoSlider/ChannelPendingActions";
import { ChannelPinnedItem } from "components/ChannelinfoSlider/ChannelPinnedItem";
import { ChannelInfoSlider } from "components/ChannelinfoSlider/ChannelinfoSlider";
import { Button } from "components/DesignSystem/Button/Button";
import { MessageInfoSlider } from "components/WhatsappSharedSlider/MessageInfoSlider";
import { WhatsappForwardedSlider } from "components/WhatsappSharedSlider/WhatsappForwardedSlider";
import { Channel } from "components/chat/Channel";
import { ChannelList } from "components/chat/ChannelList";
import DashboardContainer from "components/dashboard/DashboardContainer";
import { AnimatedSlide } from "components/design/AnimatedSlide";
import { ArrowLeft } from "components/icons/ArrowLeft";
import React from "react";

export const ChatPane = ({
  channelId,
  setChannelId,
  messageId,
  header,
  headerWithOnlySwitch,
  actionsAvailable = false,
}: {
  channelId?: string | null;
  setChannelId(value: string | null): void;
  messageId?: string;
  header?: React.ReactNode;
  headerWithOnlySwitch?: boolean;
  actionsAvailable?: boolean;
}) => {
  return (
    <DashboardContainer className="t-border-0 t-border-l t-border-solid t-border-neutral-0 t-w-full t-h-full t-overflow-hidden">
      {header && (
        <DashboardContainer.Header>{header}</DashboardContainer.Header>
      )}
      <DashboardContainer.Content className="t-w-full t-overflow-y-auto t-bg-white t-h-full t-relative t-overflow-x-hidden">
        <AnimatedSlide isVisible={!channelId} direction="left">
          <ChannelList onSelect={(channelId) => setChannelId(channelId)} />
        </AnimatedSlide>

        <AnimatedSlide isVisible={Boolean(channelId)} direction="right">
          {channelId && (
            <Channel
              Header={(props) =>
                headerWithOnlySwitch ? (
                  <ChannelHeaderWithOnlySwitch {...props} />
                ) : (
                  <ChannelHeader
                    {...props}
                    actionsAvailable={actionsAvailable}
                    backButton={
                      <Button
                        size="small"
                        customType="ghost_icon"
                        onClick={() => setChannelId(null)}
                      >
                        <ArrowLeft size="16px" />
                      </Button>
                    }
                  />
                )
              }
              channelId={channelId}
              messageId={messageId}
              messageMaxWidth="360px"
            >
              <ChannelMessageSearch
                block
                onSelect={(channelId) => setChannelId(channelId)}
              />
              <ChannelInfoSlider block />
              <ChannelPendingActions block />
              <ChannelPinnedItem block />
              <ChannelMediaSlider block />
              <WhatsappForwardedSlider block />
              <MessageInfoSlider block />
              <ChannelForwardChannelSelector />
            </Channel>
          )}
        </AnimatedSlide>
      </DashboardContainer.Content>
    </DashboardContainer>
  );
};
