export const ProfitLossGraph = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="17" height="17" rx="1.5" stroke="#F3F3F5" />
    <path
      d="M9.33333 9C9.33333 10.4728 10.5272 11.6667 12 11.6667C13.4728 11.6667 14.6667 10.4728 14.6667 9C14.6667 7.52724 13.4728 6.33333 12 6.33333C10.5272 6.33333 9.33333 7.52724 9.33333 9ZM1 9.5H12V8.5H1V9.5Z"
      fill="#9A7AFF"
    />
  </svg>
);
