export const Events = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_6503_4211)">
      <path
        d="M5.05249 10.6544L1.82374 9.46687C1.7288 9.43183 1.64689 9.36853 1.58903 9.2855C1.53117 9.20247 1.50015 9.1037 1.50015 9.0025C1.50015 8.9013 1.53117 8.80253 1.58903 8.7195C1.64689 8.63647 1.7288 8.57317 1.82374 8.53812L5.05249 7.35062C5.11972 7.32601 5.18078 7.28703 5.2314 7.2364C5.28202 7.18578 5.321 7.12473 5.34562 7.0575L6.53312 3.82875C6.56816 3.73381 6.63146 3.65189 6.71449 3.59403C6.79752 3.53618 6.89629 3.50516 6.99749 3.50516C7.09869 3.50516 7.19746 3.53618 7.28049 3.59403C7.36352 3.65189 7.42683 3.73381 7.46187 3.82875L8.64937 7.0575C8.67399 7.12473 8.71296 7.18578 8.76359 7.2364C8.81421 7.28703 8.87527 7.32601 8.94249 7.35062L12.1712 8.53812C12.2662 8.57317 12.3481 8.63647 12.406 8.7195C12.4638 8.80253 12.4948 8.9013 12.4948 9.0025C12.4948 9.1037 12.4638 9.20247 12.406 9.2855C12.3481 9.36853 12.2662 9.43183 12.1712 9.46687L8.94249 10.6544C8.87527 10.679 8.81421 10.718 8.76359 10.7686C8.71296 10.8192 8.67399 10.8803 8.64937 10.9475L7.46187 14.1762C7.42683 14.2712 7.36352 14.3531 7.28049 14.411C7.19746 14.4688 7.09869 14.4998 6.99749 14.4998C6.89629 14.4998 6.79752 14.4688 6.71449 14.411C6.63146 14.3531 6.56816 14.2712 6.53312 14.1762L5.34562 10.9475C5.321 10.8803 5.28202 10.8192 5.2314 10.7686C5.18078 10.718 5.11972 10.679 5.05249 10.6544Z"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 1V4"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 4.5V6.5"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 2.5H12.5"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 5.5H15"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6503_4211">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
