import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";

export const TakeConfirmationModal = ({
  isOpen,
  dismiss,
  close,
}: {
  isOpen: boolean;
  dismiss: () => void;
  close: () => void;
}) => (
  <Modal.Root open={isOpen}>
    <Modal.Content>
      <Modal.Header>
        <Modal.Title>Your progress will be lost</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to exit? Your progress will not be saved.
      </Modal.Body>
      <Modal.FooterButtonGroup>
        <Button onClick={dismiss}>Cancel</Button>
        <Button onClick={close} customType="danger">
          Exit
        </Button>
      </Modal.FooterButtonGroup>
    </Modal.Content>
  </Modal.Root>
);
