import { Button } from "components/DesignSystem/Button/Button";
import ToolTip from "components/design/toolTip";
import { DocumentPreviewModal, Preview } from "components/PreviewModal";
import { DownloadIcon } from "components/icons/Download";
import { useDocPreview } from "hooks/useDocPreview";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { ReactNode } from "react";
import { useLazyGetPreviewUrlQuery } from "store/apis/previewUrl";
import { FileObject } from "types/Models/fileObject";
import { FileIcon } from "utils/fileTypeIcon";
import { openLink } from "utils/openLink";
import classNames from "classnames";

const sizeTypeMap = {
  small: 20,
  medium: 24,
  large: 28,
};

export const FilePreviewTag = ({
  file,
  groupId,
  children,
  block,
  size = "medium",
}: {
  file: Pick<FileObject, "name" | "uuid" | "file_type" | "is_previewable">;
  groupId: string;
  children?: ReactNode;
  block?: boolean;
  size?: "small" | "medium" | "large";
}) => {
  const { open, close, isOpen } = useModal();

  return (
    <>
      <div
        role="button"
        className={classNames(
          "t-inline-flex t-items-center t-gap-2 t-rounded-md t-border t-border-solid t-border-neutral-10 t-p-1.5 t-justify-between",
          {
            "t-w-full": block,
            "t-text-body": size === "small",
          }
        )}
        onClick={open}
      >
        <ToolTip text={file.name}>
          <div className="t-flex t-items-center t-gap-1 t-max-w-full">
            <FileIcon
              fileType={file.file_type}
              width={sizeTypeMap[size]}
              height={sizeTypeMap[size]}
            />
            <div className="t-max-w-full t-line-clamp-1 t-word-break">
              {file.name}
            </div>
          </div>
        </ToolTip>
        {children}
      </div>
      <Preview showModal={isOpen} closeModal={close} previewId={file.uuid} />
    </>
  );
};
