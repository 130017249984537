import { Divider } from "@mui/material";
import {
  OptionData,
  Combobox,
} from "components/DesignSystem/Combobox/Combobox";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import { datePeriod as defaultDatePeriod } from "constants/bookkeeping";
import {
  DD_MMM_YYYY,
  FILTER_DATE_INPUT_FORMAT,
  MONTH_FORMAT,
  QUARTER_FORMAT,
  YEAR_FORMAT,
  YYYY_MM_DD,
} from "constants/date";
import dayjs, { Dayjs } from "dayjs";
import { Formik, Form, Field, FieldProps } from "formik";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import { MultiValue, SingleValue } from "react-select";
import { getDateRange, DateRangeValue } from "utils/getDateRange";

const pickerTypeProps = {
  date: {},
  month: { showMonthYearPicker: true },
  quarter: { showQuarterYearPicker: true },
  year: { showYearPicker: true },
};

export const dateFormats = {
  date: FILTER_DATE_INPUT_FORMAT,
  month: MONTH_FORMAT,
  quarter: QUARTER_FORMAT,
  year: YEAR_FORMAT,
};

type DateFilterProps = {
  updateFilter: <S extends "START_DATE" | "END_DATE" | "SELECT_PERIOD">(
    name: S,
    newValue: {
      START_DATE: string;
      END_DATE: string;
      SELECT_PERIOD: string;
    }[S]
  ) => void;
  values: {
    START_DATE: string;
    END_DATE: string;
    SELECT_PERIOD: string;
  };
  datePeriod?: OptionData[];
  pickerType?: "date" | "month" | "quarter" | "year";
  dateFormat?: string;
  showPeriodSelector?: boolean;
  skipDateUpdateOnPeriodChange?: boolean;
};

export const DateFilter = ({
  updateFilter,
  values,
  datePeriod: datePeriodFromProps,
  pickerType = "date",
  dateFormat: dateFormatFromProps,
  showPeriodSelector = true,
  skipDateUpdateOnPeriodChange = false,
}: DateFilterProps) => {
  let datePeriod = datePeriodFromProps || defaultDatePeriod;
  datePeriod = [...datePeriod, { label: "Custom", value: "CUSTOM" }];

  const defaultSelectedPeriod =
    datePeriod.find(({ value }) => value === values?.SELECT_PERIOD)?.value ||
    "CUSTOM";

  const initialDates = {
    START_DATE: values?.START_DATE
      ? dayjs(values.START_DATE as string).toString()
      : "",
    END_DATE: values?.END_DATE
      ? dayjs(values.END_DATE as string).toString()
      : "",
    SELECT_PERIOD: defaultDatePeriod,
  };

  const [selectedPeriod, setSelectedPeriod] = useState<string>(
    defaultSelectedPeriod
  );

  const { alertToast } = useToast();

  const picker = pickerTypeProps[pickerType];
  const dateFormat = dateFormatFromProps || dateFormats[pickerType];

  return (
    <Formik enableReinitialize initialValues={initialDates} onSubmit={() => {}}>
      {({ setFieldValue, values: { START_DATE, END_DATE } }) => {
        const handleDateChange = ({
          date,
          name,
        }: {
          date: Date;
          name: "START_DATE" | "END_DATE";
        }) => {
          setSelectedPeriod("CUSTOM");
          // If pickerType is "quarter || month", set the END_DATE to the end of the month
          if (
            (pickerType === "quarter" || pickerType === "month") &&
            name === "END_DATE"
          ) {
            updateFilter(
              name,
              date ? dayjs(date).endOf("month").format(YYYY_MM_DD) : ""
            );
            return;
          }
          updateFilter(name, date ? dayjs(date).format(YYYY_MM_DD) : "");
        };

        const updateDates = (START_DATE?: Dayjs, END_DATE?: Dayjs) => {
          setFieldValue("START_DATE", START_DATE);
          setFieldValue("END_DATE", END_DATE);

          if (START_DATE) {
            updateFilter("START_DATE", dayjs(START_DATE).format(YYYY_MM_DD));
          }
          if (END_DATE) {
            updateFilter("END_DATE", dayjs(END_DATE).format(YYYY_MM_DD));
          }
        };

        const handleChange = (
          values: MultiValue<OptionData> | SingleValue<OptionData>
        ) => {
          if (values instanceof Array) {
            return;
          }

          if (values?.value) {
            setSelectedPeriod(values?.value);

            if (values?.value === "CUSTOM") {
              return;
            }
          }

          updateFilter(
            "SELECT_PERIOD",
            (values as SingleValue<OptionData>)?.value || ""
          );

          if (skipDateUpdateOnPeriodChange) {
            return;
          }

          const { startDate, endDate } = getDateRange(
            (values as SingleValue<OptionData>)?.value as DateRangeValue
          );

          updateDates(startDate, endDate);
        };

        return (
          <Form className="all:unset t-flex t-flex-col t-gap-4">
            {showPeriodSelector && (
              <Combobox
                name="SELECT_PERIOD"
                label="Period"
                placeholder="Select period"
                onChange={handleChange}
                components={{ ClearIndicator: () => null }}
                options={datePeriod}
                block
                value={datePeriod.find((d) => d.value === selectedPeriod)}
              />
            )}
            <Divider />
            <Field name="START_DATE">
              {({ field }: FieldProps) => {
                return (
                  <DateInput
                    {...field}
                    {...picker}
                    dateFormat={dateFormat}
                    maxDate={END_DATE ? new Date(END_DATE) : new Date()}
                    label="From"
                    placeholder={DD_MMM_YYYY}
                    onDateChange={(date) => {
                      if (new Date(END_DATE) < new Date(date)) {
                        alertToast({
                          message: "Start date cannot be greater than end date",
                        });
                        setFieldValue("END_DATE", "");
                        return;
                      }
                      handleDateChange({ date, name: "START_DATE" });
                    }}
                    portalId="callStart"
                  />
                );
              }}
            </Field>
            <Field name="END_DATE">
              {({ field }: FieldProps) => {
                return (
                  <DateInput
                    {...field}
                    {...picker}
                    dateFormat={dateFormat}
                    maxDate={new Date()}
                    minDate={START_DATE ? new Date(START_DATE) : undefined}
                    label="To"
                    placeholder={DD_MMM_YYYY}
                    onDateChange={(date) => {
                      if (new Date(START_DATE) > new Date(date)) {
                        alertToast({
                          message: "End date cannot be less than start date",
                        });
                        setFieldValue("START_DATE", "");
                        return;
                      }
                      handleDateChange({ date, name: "END_DATE" });
                    }}
                    portalId="callEnd"
                  />
                );
              }}
            </Field>
          </Form>
        );
      }}
    </Formik>
  );
};
