import * as Accordion from "@radix-ui/react-accordion";
import { Button } from "components/DesignSystem/Button/Button";
import { CaretDown } from "components/icons/CaretDown";
import { Cart } from "components/icons/Cart";
import { Price } from "components/Price";
import { ProductCard } from "components/ProductCard/ProductCard";
import { _MANAGE } from "constants/addons";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useGetSubscriptionsToShow } from "hooks/useGetSubscriptionsToShow";
import { useQuery } from "hooks/useQuery";
import authContext from "jwt_context&axios/authContext";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import BigBooksIcon from "static/images/BigBooksIcon.svg";
import { ProductGroup } from "store/apis/products";
import { useGetSubscriptionsQuery } from "store/apis/subscriptions";
import { AddonData, setAddonData, setAddonType } from "store/slices/addons";
import { toggleCartSubscription } from "store/slices/cartSubscription";
import { Subscription, SubscriptionInProfile } from "types/Models/subscription";
import { ProductTypeCard } from "./ProductTypeCard";
import { SubscriptionTypeCard } from "./SubscriptionTypeCard";

export const BooksProduct = ({
  booksProduct,
}: {
  booksProduct: ProductGroup;
}) => {
  const { authtoken } = useContext(authContext);
  const { groupId: currentGroup } = useParams<{ groupId?: string }>();
  const { uuid } = useCurrentGroupContext();
  const groupId = currentGroup || uuid;
  const { platform_subscription: groupPlatformSubscription } =
    useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const dispatch = useDispatch();

  const { data: allSubscriptions = [] } = useGetSubscriptionsQuery(
    {
      groupId,
      entityId,
    },
    { skip: !groupId || !entityId }
  );

  const { booksPlusPlanToshow } = useGetSubscriptionsToShow({
    allSubscriptions,
  });

  let {
    platform_subscription: currentSubscription,
  }: {
    platform_subscription: SubscriptionInProfile;
  } = authtoken || {};

  if (currentGroup) {
    currentSubscription = groupPlatformSubscription;
  }

  const onSubscribeHandler = (addon: AddonData, useCart: boolean = false) => {
    dispatch(toggleCartSubscription(useCart));
    dispatch(setAddonData(addon));
    dispatch(setAddonType(addon?.subscription_type!));
  };

  const onManageHandler = (addon: AddonData) => {
    dispatch(setAddonData(addon));
    dispatch(setAddonType(addon?.subscription_type + _MANAGE));
  };

  if (!currentSubscription || !booksPlusPlanToshow) {
    return null;
  }

  const allProductKeys = Object.keys(booksProduct);
  const categoriesToShow = allProductKeys.filter(
    (key) => key !== "subscription" && booksProduct[key]?.length > 0
  );

  return (
    <div className="!t-border-none t-space-y-8">
      <img src={BigBooksIcon} alt="BigBooksIcon" />
      <div className="t-grid md:t-grid-cols-2 lg:t-grid-cols-3 t-gap-5">
        <ProductCard
          title={booksPlusPlanToshow.subscription_name}
          planStatus={booksPlusPlanToshow.group_subscription && "ACTIVE"}
          isPrimarySubscription
          price={
            <Price
              amount={booksPlusPlanToshow.amount}
              size="small"
              cycle={booksPlusPlanToshow.billing_cycle}
            />
          }
          description={
            <div className="t-text-body-sm t-text-white">
              Take your accounting to the next level with premium features and
              automation.
            </div>
          }
          action={
            booksPlusPlanToshow.group_subscription ? (
              <Button
                size="small"
                onClick={() => onManageHandler(booksPlusPlanToshow)}
              >
                Manage plan
              </Button>
            ) : (
              <div className="t-flex t-gap-1">
                <Button
                  size="small"
                  onClick={() => onSubscribeHandler(booksPlusPlanToshow, false)}
                >
                  Subscribe
                </Button>
                <Button
                  customType="icon"
                  size="small"
                  onClick={() => onSubscribeHandler(booksPlusPlanToshow, true)}
                >
                  <Cart />
                </Button>
              </div>
            )
          }
          isSubscription
        />
      </div>
      {categoriesToShow.length > 0 && (
        <Accordion.Root
          type="multiple"
          defaultValue={categoriesToShow}
          className="t-space-y-8"
        >
          {categoriesToShow.map((category) => (
            <Accordion.Item value={category} key={category}>
              <Accordion.Trigger className="all:unset t-text-subtitle-sm t-text-text-60 t-flex t-gap-2 t-group t-w-full">
                <span className="group-data-state-open:t-rotate-0 group-data-state-closed:-t-rotate-90 t-text-neutral t-transform t-transition t-duration-300 t-ease-in-out">
                  <CaretDown />
                </span>
                {category}
              </Accordion.Trigger>
              <Accordion.Content
                className="t-text-body-sm t-text-text-60 t-mt-3 t-grid t-gap-4"
                style={{
                  gridTemplateColumns:
                    "repeat(auto-fill, minmax(360px, max-content))",
                }}
              >
                {booksProduct[category].map((product) => {
                  if ("subscription_name" in product) {
                    return (
                      <SubscriptionTypeCard
                        key={product.uuid}
                        subscription={product as Subscription}
                      />
                    );
                  }
                  return (
                    <ProductTypeCard key={product.uuid} product={product} />
                  );
                })}
              </Accordion.Content>
            </Accordion.Item>
          ))}
        </Accordion.Root>
      )}
    </div>
  );
};
