import { ContractorPageLayout } from "ContractorPageLayout";
import { useQuery } from "hooks/useQuery";

import "static/styles/components/contractorForm.css";

export const ContractorForm = () => {
  const query = useQuery();
  const formUrl = query.get("form_url");

  return (
    <ContractorPageLayout>
      {formUrl && (
        <iframe className="contractor-form-iframe" src={formUrl}></iframe>
      )}
    </ContractorPageLayout>
  );
};
