import React from "react";

export const ManualCategorised = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
    >
      <circle cx="16.6743" cy="16" r="16" fill="#BBA7FF" />
      <path
        d="M11.6743 16.9441L14.8743 19.9015L22.341 13"
        stroke="white"
        strokeWidth="2.72002"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
