export const BrexPointLogo = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.696 4.31169C11.9937 5.24581 11.8242 5.55986 11.1462 5.55986H1V17.5906H4.97937C5.87534 17.5906 6.74709 17.1558 7.31211 16.4391C8.03856 15.4727 8.13543 15.1909 8.83767 15.1909H19V3.16016H14.9964C14.1004 3.16016 13.2287 3.595 12.696 4.31169ZM15.8439 12.0906H11.5982C10.678 12.0906 9.83049 12.4771 9.26547 13.218C8.53901 14.1843 8.39372 14.4662 7.71569 14.4662H4.13184V8.69237H8.37758C9.29776 8.69237 10.1453 8.25752 10.7103 7.54083C11.4368 6.60671 11.6305 6.31682 12.2843 6.31682H15.852V12.0906H15.8439Z"
      fill="#F46A35"
    />
  </svg>
);
