import { emptyApi } from "./emptyApi";
import qs from "qs";

type Txn = {
  groupId: string;
  page_num?: number;
  type?: string;
  search_term?: "CONTRACTOR" | null;
  season: string;
};

type Merchant = {
  name: string;
  logo: string | null;
  uuid: string;
};

export const merchantsApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getAllTransactionMerchants: build.query<
      { merchants: Merchant[]; total_pages: number },
      {
        groupId: string;
        entityId: string;
        searchTerm?: string;
        pageNum?: number;
        merchantIds?: string;
      }
    >({
      query: ({ groupId, entityId, searchTerm, pageNum, merchantIds }) => {
        let queryUrl = qs.stringify(
          {
            search_term: searchTerm,
            page_num: pageNum,
            merchant_uuids: merchantIds,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/merchants/all/${queryUrl}`,
        };
      },
      providesTags: (result) => (result ? ["TRANSACTION_MERCHANTS"] : []),
    }),

    getMerchantById: build.query<
      {
        logo?: string | null;
        name: string;
        uuid: string;
      },
      {
        groupId: string;
        merchantId: string;
      }
    >({
      query: ({ groupId, merchantId }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/merchant/${merchantId}/`,
        };
      },
      providesTags: (result) => (result ? ["TRANSACTION_MERCHANTS"] : []),
    }),

    assignExistingMerchant: build.mutation<
      Merchant,
      {
        groupId: string;
        transactionId: string;
        payload: { merchant_id: string };
      }
    >({
      query: ({ groupId, transactionId, payload }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/txn-merchant/${transactionId}/`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: (result) =>
        result
          ? [
              "TRANSACTION_MERCHANTS",
              "BOOKS_SINGLE_TRANSACTION",
              "BOOKS_TRANSACTIONS",
              "VENDORS",
            ]
          : [],
    }),

    createMerchant: build.mutation({
      query: ({ groupId, entityId, payload }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/merchant/`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: (result) =>
        result ? ["BOOKS_TRANSACTIONS", "TRANSACTION_MERCHANTS"] : [],
    }),

    deleteMerchantFromTxn: build.mutation({
      query: ({ groupId, transactionId }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/txn-merchant/${transactionId}/`,
          method: "DELETE",
        };
      },
      invalidatesTags: [
        "TRANSACTION_MERCHANTS",
        "BOOKS_SINGLE_TRANSACTION",
        "BOOKS_TRANSACTIONS",
      ],
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetAllTransactionMerchantsQuery,
  useAssignExistingMerchantMutation,
  useCreateMerchantMutation,
  useDeleteMerchantFromTxnMutation,
  useGetMerchantByIdQuery,
  useLazyGetMerchantByIdQuery,
} = merchantsApi;
