import classNames from "classnames";
import { ChatUserName } from "components/ChatUserName/ChatUserName";
import Loader from "components/design/loader";
import { Button } from "components/DesignSystem/Button/Button";
import { Cross } from "components/icons/Cross";
import { Cards } from "components/MessageBubble/Cards";
import { Mention } from "components/MessageBubble/MessageBubble";
import {
  CHAT_CARD_TYPE,
  OPEN_ITEM_CUSTOM_TYPE,
  REQUESTED_DOCUMENTS_CUSTOM_TYPE,
  REQUESTED_INFO_CUSTOM_TYPE,
} from "constants/chatType";
import dayjs from "dayjs";
import { motion } from "framer-motion";
import { usePaginatedMessages } from "hooks/usePaginatedMessages";
import { FC, useMemo } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import PendingActionEmpty from "static/images/PendingActionEmpty.svg";
import { setChannelPendingActionsActive } from "store/slices/channelPendingActions";
import { setScrollToMessageId } from "store/slices/messageToScrollTo";
import { RootState } from "store/store";
import { MessageResponse } from "stream-chat";
import {
  renderText,
  useChannelActionContext,
  useChannelStateContext,
  useChatContext,
} from "stream-chat-react";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";

const NoPendingActions: FC = () => {
  return (
    <div className="t-flex t-items-center t-justify-center t-gap-4 t-flex-col t-h-full">
      <img src={PendingActionEmpty} alt="PendingActionEmpty" />
      <span className="t-text-body t-text-text-30">No pending items</span>
    </div>
  );
};

const PendingAction: FC<{
  message: MessageResponse<DefaultStreamChatGenerics>;
}> = ({ message }) => {
  const dispatch = useDispatch();
  const { client } = useChatContext();
  const sentByTheUser = message.user?.id === client.userID;

  const { jumpToMessage } =
    useChannelActionContext<DefaultStreamChatGenerics>("MessageActionsBox");

  const messageText: JSX.Element | null = useMemo(
    () =>
      renderText(message?.text, message?.mentioned_users, {
        customMarkDownRenderers: {
          mention: Mention,
        },
      }),
    [message?.mentioned_users, message?.text]
  );

  const jumpToMsg = () => {
    dispatch(setScrollToMessageId(message.id));
    jumpToMessage(message?.id!);
  };

  return (
    <motion.div
      initial={{ y: 20 }}
      animate={{ y: -0 }}
      className="group-[&]/middle:t-my-1.5 group-[&]/top:t-mb-1.5 group-[&]/bottom:t-mt-1.5"
    >
      <button
        className="all:unset t-flex t-gap-3 t-group"
        onClickCapture={jumpToMsg}
      >
        {message?.user && (
          <img
            src={message?.user?.image}
            alt={message?.user?.name}
            className="t-h-7 t-w-7 t-rounded-full"
          />
        )}
        <div
          data-sent={sentByTheUser}
          className={classNames(
            "t-hyphens-auto t-relative t-order-1 t-flex-col t-gap-2 t-break-words t-text-body group-data-[sent=true]:t-order-2 t-p-2 t-pt-1.5 t-flex t-rounded-lg",
            {
              "t-bg-purple-10 t-text-purple-100 group-hover:t-bg-purple-20":
                sentByTheUser,
              "t-text-text-60 t-bg-surface-grey group-hover:t-bg-neutral-10":
                !sentByTheUser,
            }
          )}
        >
          <p className="t-m-0 t-text-subtext-sm t-text-text-100 t-flex">
            <ChatUserName name={sentByTheUser ? "You" : message?.user?.name} />
          </p>
          <div className="t-mb-1">
            <Cards
              type={message?.custom_type as string}
              customData={message?.custom_data}
              messageId={message?.id!}
            />
          </div>
          {message?.text && (
            <span
              className={classNames(
                "t-m-0 [&>p:last-child]:t-m-0 [&_p:has(a)]:!t-inline [&_p]:t-whitespace-pre-line [&_ul]:t-mb-4 [&_ol]:t-mb-4 [&>p:last-child]:t-inline-block [&_p]:!t-leading-none",
                {
                  "[&_a]:t-text-text-100": sentByTheUser,
                }
              )}
            >
              {messageText}
            </span>
          )}
          <span
            className={classNames(
              "t-whitespace-nowrap t-text-right t-text-overline t-text-text-30 t-h-0 t-ml-2 t-overflow-hidden t-inline-flex",
              {
                "t-justify-end": sentByTheUser,
              }
            )}
          >
            {dayjs(message?.created_at).format("hh:mm a")}
          </span>
          <span
            className={classNames(
              "t-ml-auto t-whitespace-nowrap t-text-right t-text-overline t-text-text-30 t-absolute t-right-2 t-bottom-1",
              {
                "t-justify-end": sentByTheUser,
              }
            )}
          >
            {dayjs(message?.created_at).format("hh:mm a")}
          </span>
        </div>
      </button>
    </motion.div>
  );
};

const SliderBody: FC = () => {
  const { client } = useChatContext();
  const { channel } = useChannelStateContext();

  const {
    messages: actionMessageItems,
    loadNextPage,
    next,
    loading,
  } = usePaginatedMessages(
    client,
    {
      id: channel.id,
    },
    {},
    { limit: 5 },
    {
      custom_type: {
        $in: [
          CHAT_CARD_TYPE,
          OPEN_ITEM_CUSTOM_TYPE,
          REQUESTED_DOCUMENTS_CUSTOM_TYPE,
          REQUESTED_INFO_CUSTOM_TYPE,
        ],
      },
      card_status: { $eq: "REQUESTED" },
    }
  );

  const noActionFound = actionMessageItems.length === 0 && !loading;

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {noActionFound ? (
        <NoPendingActions />
      ) : (
        <InfiniteScroll
          dataLength={actionMessageItems.length}
          next={loadNextPage}
          hasMore={Boolean(next)}
          scrollThreshold={0.6}
          scrollableTarget="pending-actions"
          loader={
            <div className="t-w-full t-pb-2 t-text-center t-text-subtitle-sm">
              Loading...
            </div>
          }
        >
          <div className="t-flex t-flex-col t-gap-4 t-px-5 t-pb-5 t-pt-2">
            {actionMessageItems.map(({ message }) => (
              <PendingAction message={message} key={message.id} />
            ))}
          </div>
        </InfiniteScroll>
      )}
    </>
  );
};

export const ChannelPendingActions = ({
  block = false,
}: {
  block?: boolean;
}) => {
  const isActive = useSelector(
    (state: RootState) => state.channelPendingActions.isActive
  );
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(
      setChannelPendingActionsActive({
        isActive: false,
      })
    );
  };

  return (
    <div
      className={classNames(
        "t-transition-all t-grow t-shrink-0 t-bg-surface t-overflow-x-hidden t-z-10",
        {
          "t-border-0 t-border-l t-border-solid t-border-neutral-10": isActive,
          "!t-w-0": !isActive,
          "t-w-full": block && isActive,
          "!t-w-[360px]": !block && isActive,
        }
      )}
      id="pending-actions"
    >
      <div className="t-py-3 t-pr-4 t-pl-5 t-flex t-justify-between t-items-center">
        <span className="t-text-subtitle">Pending Actions</span>
        <Button customType="ghost_icon" size="small" onClick={onClose}>
          <Cross />
        </Button>
      </div>
      {isActive && <SliderBody />}
    </div>
  );
};
