import { AmountSuperScript } from "components/design/AmountSuperScript";
import { CategoryLabel } from "components/design/CategoryLabel";
import ConditionalToolTip from "components/design/conditionalToolTip";
import { Divider } from "components/design/Divider";
import { TableUI } from "components/design/TableUI";
import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import Modal from "components/DesignSystem/Modal/Modal";
import { Stepper } from "components/DesignSystem/Stepper/Stepper";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { ArrowRight } from "components/icons/ArrowRight";
import { InfoSolid } from "components/InfoSolid";
import { NumericInput } from "components/NumericInput/NumericInput";
import { PriceInput } from "components/PriceInput/PriceInput";
import {
  CategoryIndent,
  coaOptions,
} from "components/Transaction/TransactionColumn";
import { DD_MMM_YYYY } from "constants/date";
import dayjs from "dayjs";
import {
  Field,
  FieldProps,
  Form,
  Formik,
  FormikHelpers,
  useFormikContext,
} from "formik";
import { fixedAssetSchema } from "formValidations/fixedAssetSchema";
import { useChartOfAccounts } from "hooks/useChartOfAccounts";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import React, { ReactNode, useState } from "react";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import {
  useCreateFixedAssetMutation,
  useSchedulePreviewMutation,
} from "store/apis/fixedAsset";
import {
  DepreciationSchedule,
  DepreciationSchedulePreview,
} from "types/Models/fixedAsset";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";
import { flattenTypes } from "utils/flattenCOA";
import { pluralize } from "utils/pluralize";

type InitialValues = {
  asset_name: string;
  purchase_price: number;
  frequency: string;
  depreciation_start_date: string;
  salvage_amount: number;
  description: string;
  useful_life: number;
  method: string;
  asset_account_id: string;
  depreciation_account_id: string;
  accumulated_depreciation_account_id: string;
  accumulated_depreciation_amount: number;
};

export const RuleStepper = ({
  currentStep,
  onStepperClick,
}: {
  onStepperClick: (step: number) => void;
  currentStep: number;
}) => {
  const breadcrumbs = [
    { name: "Add details", step: 1 },
    { name: "Schedule", step: 2 },
  ];

  return (
    <Stepper size="small" direction="horizontal">
      {breadcrumbs?.map(({ name, step }) => (
        <Stepper.Step
          step={step}
          key={step}
          isActive={currentStep >= step}
          clickable={step === 1}
          onClick={() => onStepperClick(step)}
        >
          <div className="t-flex t-items-center">
            {name}
            {step === 1 && <ArrowRight color="currentColor" />}
          </div>
        </Stepper.Step>
      ))}
    </Stepper>
  );
};

const Schedule = ({
  close,
  onSubmit,
  currentStep,
  setCurrentStep,
  scheduleData,
}: {
  close: () => void;
  onSubmit: (
    { method, ...values }: InitialValues,
    { resetForm }: FormikHelpers<InitialValues>
  ) => void | Promise<any>;
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  scheduleData: DepreciationSchedulePreview | undefined;
}) => {
  const createColumn = createColumnHelper<DepreciationSchedule>();
  const { fixed_asset, depreciation_schedule = [] } = scheduleData || {};
  const {
    asset_name = "",
    purchase_price = 0,
    description = "",
    useful_life = 0,
    frequency = "",
    depreciation_start_date = "",
    salvage_value = 0,
    asset_account_id = "",
    depreciation_account_id = "",
    accumulated_depreciation_account_id = "",
    accumulated_depreciation_amount = 0,
  } = fixed_asset || {};

  const columns = [
    createColumn.accessor("depreciation_date", {
      size: 25,
      header: "DATE",
      cell: (info) => {
        const { depreciation_date } = info.row.original;

        return (
          <div className="t-py-3">
            {dayjs(depreciation_date).format(DD_MMM_YYYY)}
          </div>
        );
      },
    }),

    createColumn.accessor("current_asset_value", {
      size: 25,
      header: () => (
        <div className="t-flex t-justify-end t-whitespace-nowrap">
          ASSET VALUE
        </div>
      ),
      cell: (info) => {
        const current_asset_value = info.getValue();

        return (
          <div className="t-flex t-justify-end t-whitespace-nowrap">
            <AmountSuperScript amount={current_asset_value} />
          </div>
        );
      },
    }),

    createColumn.accessor("depreciation_amount", {
      size: 25,
      header: () => (
        <div className="t-flex t-justify-end t-whitespace-nowrap">
          DEPRECIATION
        </div>
      ),
      cell: (info) => {
        const depreciation_amount = info.getValue();

        return (
          <div className="t-flex t-justify-end t-whitespace-nowrap">
            <AmountSuperScript amount={depreciation_amount} />
          </div>
        );
      },
    }),

    createColumn.accessor("remaining_value", {
      size: 25,
      header: () => (
        <div className="t-flex t-justify-end t-whitespace-nowrap">
          REMAINING VALUE
        </div>
      ),
      cell: (info) => {
        const remaining_value = info.getValue();

        return (
          <div className="t-flex t-justify-end t-whitespace-nowrap">
            <AmountSuperScript amount={remaining_value} />
          </div>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: depreciation_schedule || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      size: 10,
      minSize: 1,
      maxSize: 100,
    },
  });

  return (
    <Formik
      initialValues={{
        asset_name,
        description,
        purchase_price,
        frequency,
        depreciation_start_date,
        salvage_amount: salvage_value,
        useful_life,
        method: "Straight line",
        asset_account_id,
        depreciation_account_id,
        accumulated_depreciation_account_id,
        accumulated_depreciation_amount,
      }}
      onSubmit={onSubmit}
    >
      {({ submitForm, isSubmitting }) => (
        <Modal.Content size="large" useCustomOverlay>
          <Modal.Header>
            <div>
              <Modal.Title>Add Fixed Asset</Modal.Title>
              <Modal.Subtitle>
                <RuleStepper
                  currentStep={currentStep}
                  onStepperClick={setCurrentStep}
                />
              </Modal.Subtitle>
            </div>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            <Form className="t-flex t-flex-col t-gap-6 t-m-0">
              <div className="t-flex t-gap-2 t-flex-col">
                <span className="t-text-subtext">Asset details</span>
                <div className="t-flex t-gap-1 t-text-body t-text-text-30 t-flex-col">
                  <span>Asset name: {asset_name}</span>
                  <span>
                    Useful life: {pluralize(useful_life, "year", "years")}
                  </span>
                </div>
              </div>
              <TableUI table={table} />
            </Form>
          </Modal.Body>
          <Modal.FooterButtonGroup>
            <Button
              onClick={() => setCurrentStep(1)}
              disabled={isSubmitting}
              type="button"
            >
              Back
            </Button>
            <Button
              customType="primary"
              onClick={submitForm}
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              Confirm
            </Button>
          </Modal.FooterButtonGroup>
        </Modal.Content>
      )}
    </Formik>
  );
};

export const AssetConsoleItems = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) => {
  return (
    <div className="t-flex t-flex-col t-gap-4">
      <span className="t-text-subtext t-text-text-100">{title}</span>
      {children}
    </div>
  );
};

const AssetDetails = () => {
  return (
    <AssetConsoleItems title="Asset details">
      <TextInput
        label="Asset name"
        required
        name="asset_name"
        placeholder="Input asset name"
      />
      <TextInput
        label="Description"
        name="description"
        placeholder="Input description"
      />
    </AssetConsoleItems>
  );
};

const Depreciation = () => {
  const { values } = useFormikContext<InitialValues>();

  const { frequency } = values || {};

  const frequencyOptions = [
    { label: "Monthly", value: "MONTHLY" },
    { label: "Quarterly", value: "QUARTERLY" },
    { label: "Annually", value: "ANNUALLY" },
  ];

  const defaultFrequency = frequencyOptions.find(
    ({ value }) => value === frequency
  );

  return (
    <AssetConsoleItems title="Depreciation">
      <div className="t-grid t-grid-cols-2 t-gap-x-6 t-gap-y-3">
        <PriceInput
          label="Purchase price"
          required
          name="purchase_price"
          allowNegative={false}
        />
        <Field name="depreciation_start_date">
          {({ field }: FieldProps) => {
            return (
              <DateInput
                label={
                  <>
                    Depreciation start date
                    <ConditionalToolTip condition="This can be the purchase date.">
                      <span className="t-ml-1">
                        <InfoSolid size="14" />
                      </span>
                    </ConditionalToolTip>
                  </>
                }
                {...field}
                portalId="transaction-start"
                block
                required
                maxDate={new Date()}
              />
            );
          }}
        </Field>

        <PriceInput
          label={
            <>
              Salvage value
              <ConditionalToolTip condition="Estimated selling price.">
                <span className="t-ml-1">
                  <InfoSolid size="14" />
                </span>
              </ConditionalToolTip>
            </>
          }
          required
          name="salvage_amount"
          allowNegative={false}
        />
        <TextInput
          label="Depreciation method"
          required
          name="method"
          disabled
        />

        <NumericInput
          label="Useful life (in years)"
          storeNumeric
          fieldProps={{ name: "useful_life" }}
          required
        />
        <Combobox
          withForm
          name="frequency"
          label="Frequency of depreciation"
          placeholder="Select"
          isClearable={false}
          options={frequencyOptions}
          menuPortalTarget={document.body}
          block
          required
          value={
            frequency
              ? {
                  label: defaultFrequency?.label || "",
                  value: defaultFrequency?.value || "",
                }
              : null
          }
        />
      </div>
    </AssetConsoleItems>
  );
};

const Categories = ({
  name,
  label,
}: {
  name:
    | "asset_account_id"
    | "depreciation_account_id"
    | "accumulated_depreciation_account_id";
  label: string;
}) => {
  const { values } = useFormikContext<InitialValues>();
  const { chartOfAccounts = [], isLoading } = useChartOfAccounts({
    hiddenCategoryTypes: ["BANK_ACCOUNT"],
  });

  const category_id = values[name];

  const defaultAssignedCategory = flattenTypes({
    accounts: chartOfAccounts,
  }).find(({ uuid }) => uuid === category_id);

  return (
    <Combobox
      label={label}
      menuPlacement="top"
      isDisabled={isLoading}
      placeholder="Select account"
      menuPortalTarget={document.body}
      withForm
      name={name}
      required
      options={coaOptions(chartOfAccounts)}
      filterOption={(v, i) =>
        v.data.data?.toLocaleLowerCase().includes(i.toLocaleLowerCase()) ||
        false
      }
      value={
        category_id
          ? {
              label: (
                <CategoryIndent
                  label={<CategoryLabel category={defaultAssignedCategory} />}
                  indentLevel={0}
                />
              ),
              value: defaultAssignedCategory?.uuid || "",
            }
          : null
      }
      styles={{
        menuPortal: (base) => ({
          ...base,
          width: 450,
        }),
      }}
    />
  );
};

const Categorization = () => {
  return (
    <AssetConsoleItems title="Assign Categories">
      <div className="t-grid t-grid-cols-2 t-gap-x-6 t-gap-y-3">
        <Categories label="Asset account" name="asset_account_id" />
        <Categories
          label="Depreciation expense account"
          name="depreciation_account_id"
        />
        <Categories
          label="Accumulated depreciation account"
          name="accumulated_depreciation_account_id"
        />
        <PriceInput
          allowNegative={false}
          label="Accumulated depreciation amount"
          name="accumulated_depreciation_amount"
        />
      </div>
    </AssetConsoleItems>
  );
};

const AssetConsole = ({
  onSubmit,
  close,
  currentStep,
  setCurrentStep,
  scheduleData,
}: {
  onSubmit: (
    values: InitialValues,
    formikHelpers: FormikHelpers<InitialValues>
  ) => void | Promise<any>;
  close: () => void;
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  scheduleData: DepreciationSchedulePreview | undefined;
}) => {
  const { fixed_asset } = scheduleData || {};
  const {
    asset_name = "",
    purchase_price = 0,
    description = "",
    useful_life = 0,
    frequency = "",
    depreciation_start_date = "",
    salvage_value = 0,
    asset_account_id = "",
    depreciation_account_id = "",
    accumulated_depreciation_account_id = "",
    accumulated_depreciation_amount = 0,
  } = fixed_asset || {};

  return (
    <Formik
      initialValues={{
        asset_name,
        description,
        purchase_price,
        frequency,
        depreciation_start_date: depreciation_start_date
          ? dayjs(depreciation_start_date).format(DD_MMM_YYYY)
          : "",
        salvage_amount: salvage_value,
        useful_life,
        method: "Straight line",
        asset_account_id,
        depreciation_account_id,
        accumulated_depreciation_account_id,
        accumulated_depreciation_amount,
      }}
      validateOnChange
      validationSchema={fixedAssetSchema}
      onSubmit={onSubmit}
    >
      {({ submitForm, isSubmitting }) => (
        <Modal.Content size="large" useCustomOverlay>
          <Modal.Header>
            <div>
              <Modal.Title>Add Fixed Asset</Modal.Title>
              <Modal.Subtitle>
                <RuleStepper
                  currentStep={currentStep}
                  onStepperClick={setCurrentStep}
                />
              </Modal.Subtitle>
            </div>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            <Form className="t-flex t-flex-col t-gap-6 t-m-0">
              <AssetDetails />
              <Divider />
              <Depreciation />
              <Divider />
              <Categorization />
            </Form>
          </Modal.Body>
          <Modal.FooterButtonGroup>
            <Button onClick={close} disabled={isSubmitting} type="button">
              Cancel
            </Button>
            <Button
              customType="primary"
              onClick={submitForm}
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              Check schedule
            </Button>
          </Modal.FooterButtonGroup>
        </Modal.Content>
      )}
    </Formik>
  );
};

export const AddAsset = ({ close, isOpen }: ModalProps) => {
  const { alertToast, successToast } = useToast();
  const [currentStep, setCurrentStep] = useState(1);
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const [schedulePreview, { data: scheduleData }] =
    useSchedulePreviewMutation();
  const [createFixedAsset] = useCreateFixedAssetMutation();

  const onClose = () => {
    setCurrentStep(1);
    close();
  };

  const onAdd = async ({ method, ...values }: InitialValues) => {
    try {
      await schedulePreview({
        entityId,
        groupId,
        method: "STRAIGHT_LINE",
        ...values,
      }).unwrap();
      setCurrentStep(2);
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const onConfirm = async (
    { method, ...values }: InitialValues,
    { resetForm }: FormikHelpers<InitialValues>
  ) => {
    try {
      await createFixedAsset({
        entityId,
        groupId,
        method: "STRAIGHT_LINE",
        ...values,
      }).unwrap();
      resetForm();
      successToast({ message: "Fixed asset added" });
      onClose();
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={onClose} modal={false}>
      {currentStep === 1 ? (
        <AssetConsole
          onSubmit={onAdd}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          close={close}
          scheduleData={scheduleData}
        />
      ) : (
        <Schedule
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          close={close}
          onSubmit={onConfirm}
          scheduleData={scheduleData}
        />
      )}
    </Modal.Root>
  );
};
