import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import Modal from "components/DesignSystem/Modal/Modal";
import { InfoFilledSmall } from "components/icons/InfoFilledSmall";
import { datePeriod } from "constants/bookkeeping";
import { DD_MMM_YYYY } from "constants/date";
import dayjs from "dayjs";
import { Field, FieldProps, Form, Formik, useFormikContext } from "formik";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { useRefreshReceiptsMutation } from "store/apis/transactions";
import { BackendError } from "types/utils/error";
import { DateRangeValue, getDateRange } from "utils/getDateRange";

const ReceiptForm = () => {
  const dateOptions = [
    ...datePeriod,
    {
      label: "Custom",
      value: "custom",
    },
  ];

  const { values } = useFormikContext<{
    duration: string;
    fromDate: string;
    toDate: string;
  }>();

  const { duration, fromDate, toDate } = values || {};
  const selectedOption = dateOptions.find(({ value }) => value === duration);

  return (
    <div className="t-flex t-flex-col t-gap-4">
      <Combobox
        withForm
        name="duration"
        label="Duration"
        menuPortalTarget={document.body}
        options={dateOptions}
        value={selectedOption ? selectedOption : null}
      />
      {selectedOption?.value === "custom" && (
        <div className="t-flex  t-gap-4">
          <div className="t-w-full">
            <Field name="from_date">
              {({ field }: FieldProps) => {
                return (
                  <DateInput
                    {...field}
                    label="From"
                    placeholder={DD_MMM_YYYY}
                    required
                    portalId="from"
                  />
                );
              }}
            </Field>
          </div>
          <div className="t-w-full">
            <Field name="to_date">
              {({ field }: FieldProps) => {
                return (
                  <DateInput
                    {...field}
                    label="To"
                    placeholder={DD_MMM_YYYY}
                    required
                    portalId="to"
                  />
                );
              }}
            </Field>
          </div>
        </div>
      )}

      <span className="t-flex t-items-start t-text-body t-text-neutral t-gap-1">
        <InfoFilledSmall color="currentColor" />
        Only transactions fetched from Brex and Mercury direct connection will
        refresh
      </span>
    </div>
  );
};

export const FetchReceiptsModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [refreshReceipts] = useRefreshReceiptsMutation();
  const { infoToast, alertToast } = useToast();
  const entityId = useCurrentEntityId();
  const { uuid: groupId } = useCurrentGroupContext();

  const handleSubmit = async (values: {
    duration: string;
    from_date: string;
    to_date: string;
  }) => {
    const dateFromDuration =
      values.duration !== "custom"
        ? getDateRange(values?.duration as DateRangeValue)
        : null;
    const final_from_date =
      values.duration === "custom"
        ? dayjs(values?.from_date).format("YYYY-MM-DD")
        : dayjs(dateFromDuration?.startDate!).format("YYYY-MM-DD");
    const final_to_date =
      values.duration === "custom"
        ? dayjs(values?.to_date).format("YYYY-MM-DD")
        : dayjs(dateFromDuration?.endDate!).format("YYYY-MM-DD");

    try {
      const payload = {
        from_date: final_from_date,
        to_date: final_to_date,
      };
      await refreshReceipts({
        entityId,
        groupId,
        payload,
      }).unwrap();

      infoToast({
        title: "Refreshing",
        message: "We'll send you an in-app notification when done",
      });
      onClose();
    } catch (error) {
      alertToast(
        {
          message: (error as BackendError).data?.error?.message,
        },
        error as {}
      );
    }
  };
  return (
    <Formik
      initialValues={{
        duration: "lastMonth",
        from_date: "",
        to_date: "",
      }}
      onSubmit={handleSubmit}
    >
      {({ submitForm }) => (
        <Form>
          <Modal.Root open={isOpen} onOpenChange={onClose}>
            <Modal.Content useCustomOverlay>
              <Modal.Header>
                <Modal.Title>Refresh Invoices and Memos</Modal.Title>
                <Modal.Close />
              </Modal.Header>
              <Modal.Body className=" t-flex t-flex-col t-gap-2">
                <ReceiptForm />
              </Modal.Body>

              <Modal.FooterButtonGroup>
                <Button onClick={onClose}>Cancel</Button>
                <Button customType="primary" type="submit" onClick={submitForm}>
                  Submit
                </Button>
              </Modal.FooterButtonGroup>
            </Modal.Content>
          </Modal.Root>
        </Form>
      )}
    </Formik>
  );
};
