import { BlockedButton } from "components/BlockedButton/BlockedButton";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { Tag } from "components/DesignSystem/Tag/Tag";
import { ArrowRight } from "components/icons/ArrowRight";
import { LinkBankAccount } from "components/LinkBankAccount";
import { PermissionBasedUI } from "components/PermissionBasedUI/PermissionBasedUI";
import { PlaidToDirectMigration } from "components/PlaidToDirectMigration/PlaidToDirectMigration";
import {
  CONNECT_BANK_ACCOUNT,
  CONNECT_REVENUE_SOURCE,
} from "constants/subscriptionPermissionFeatures";
import { onConnectArgs } from "hooks/useBankConnect";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { AccountCard } from "pages/Books/DataSources/DataSourcesList";
import { useState } from "react";
import { PlaidLinkOnSuccessMetadata } from "react-plaid-link";
import { useDispatch } from "react-redux";
import BankCircular from "static/images/BankCircular.svg";
import PlaidMultipleBankLogoCircle from "static/images/PlaidMultipleBankLogoCircle.svg";
import { useGetAllConnectionsQuery } from "store/apis/booksConnections";

export const ConnectionsModal = ({
  onConnect,
  close,
  entityIdFromParent,
  showAddManualBank = true,
  onComplete,
  isLoading,
  loadingConnectionId,
  onManualBankAdd,
}: {
  onConnect: (args: onConnectArgs) => void;
  close: () => void;
  entityIdFromParent?: string;
  showAddManualBank?: boolean;
  isLoading?: boolean;
  loadingConnectionId?: string | null;
  onComplete?: (connectedBanks: PlaidLinkOnSuccessMetadata) => void;
  onManualBankAdd: () => void;
}) => {
  const { isCpa } = useRoleBasedView();
  const dispatch = useDispatch();
  const { uuid: groupId, entities } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const entity = entities.find((entity) => entity.uuid === entityId);
  const { data: connections } = useGetAllConnectionsQuery(
    {
      groupId: groupId!,
      entityId: entityId! || entityIdFromParent!,
    },
    { skip: !groupId || (!entityId && !entityIdFromParent) }
  );
  const [currentConnectionId, setCurrentConnectionId] = useState<string | null>(
    null
  );

  const currentConnection = connections?.find(
    (connection) => connection.uuid === currentConnectionId
  );

  const plaidToDirectMigrationStrategyModal = useModal();

  const onConnectClick = (connectionId: string) => {
    const connection = connections?.find(
      (connection) => connection.uuid === connectionId
    );

    if (connection?.is_plaid_account_connected) {
      plaidToDirectMigrationStrategyModal.open();
      setCurrentConnectionId(connectionId);

      return;
    }

    if (connection) {
      onConnect({
        connectionId: connection.uuid,
        invokedFrom: isCpa
          ? `/books/data-sources?company=${groupId}&entity=${entityId}`
          : `/books/data-sources?entity=${entityId}`,
      });
    }
  };

  const onPlaidDirectMigrationModalClose = () => {
    plaidToDirectMigrationStrategyModal.close();
    setCurrentConnectionId(null);
  };

  const revenueConnections = connections?.filter(
    ({ connection_provider }) => connection_provider === "STRIPE"
  );
  const bankingConnections = connections?.filter(
    ({ connection_provider }) => connection_provider !== "STRIPE"
  );

  return (
    <>
      <div>
        <p className="t-mb-2 t-text-body-lg">Data Sources</p>
        <div className="t-flex t-flex-col t-gap-3">
          {bankingConnections?.map((connection) => (
            <AccountCard
              key={connection.uuid}
              logo={
                <img
                  className="t-w-8 t-h-8 t-rounded-full"
                  src={connection.logo_url}
                  alt=""
                />
              }
              title={connection.name}
              description="Direct API integration for fetching transactions data"
              CTA={
                <>
                  {!connection.is_connection_available ? (
                    <Button
                      size="small"
                      isLoading={
                        isLoading && loadingConnectionId === connection.uuid
                      }
                      onClick={() => onConnectClick(connection.uuid)}
                    >
                      Connect <ArrowRight color="currentColor" />
                    </Button>
                  ) : (
                    <Tag tagType="green">
                      <span className="t-text-body-sm">Connected</span>
                    </Tag>
                  )}
                </>
              }
            />
          ))}
          <AccountCard
            logo={
              <img
                src={PlaidMultipleBankLogoCircle}
                className="t-rounded-full t-w-6 t-h-6"
                alt=""
              />
            }
            title="Other banking institutions"
            description="Connect other banks securely via Plaid"
            CTA={
              groupId && (
                <LinkBankAccount
                  groupId={groupId}
                  onComplete={onComplete}
                  entityId={entityId}
                >
                  {({ ready, connectPlaid }) => (
                    <Button
                      size="small"
                      onClick={(e) => {
                        if (document.body) {
                          document.body.style.pointerEvents = "auto";
                        }
                        connectPlaid(e);
                      }}
                      disabled={!ready}
                    >
                      Connect <ArrowRight color="currentColor" />
                    </Button>
                  )}
                </LinkBankAccount>
              )
            }
          />
          {revenueConnections?.map((connection) => (
            <AccountCard
              key={connection.uuid}
              logo={
                <img
                  className="t-w-8 t-h-8 t-rounded-full"
                  src={connection.logo_url}
                  alt=""
                />
              }
              title={connection.name}
              description="Direct API integration for fetching revenue data"
              CTA={
                <PermissionBasedUI
                  errorMessage="You need to upgrade to Plus Plan to connect revenue data sources."
                  feature={CONNECT_REVENUE_SOURCE}
                  blockedUI={
                    <BlockedButton size="small">Connect</BlockedButton>
                  }
                >
                  <Button
                    size="small"
                    onClick={() =>
                      onConnect({
                        connectionId: connection.uuid,
                        invokedFrom: isCpa
                          ? `/books/data-sources?company=${groupId}&entity=${entityId}`
                          : `/books/data-sources?entity=${entityId}`,
                      })
                    }
                  >
                    Connect
                  </Button>
                </PermissionBasedUI>
              }
            />
          ))}
          {showAddManualBank && (
            <AccountCard
              logo={
                <img
                  src={BankCircular}
                  className="t-rounded-full t-w-8 t-h-8 "
                  alt=""
                />
              }
              title="Add bank/card"
              description={
                <>
                  Manually enter bank account or
                  <br />
                  credit card details.
                </>
              }
              CTA={
                groupId && (
                  <PermissionBasedUI
                    errorMessage="You need to upgrade to Plus Plan to connect revenue data sources."
                    feature={CONNECT_BANK_ACCOUNT}
                    blockedUI={
                      <BlockedButton size="small">Add details</BlockedButton>
                    }
                  >
                    <Button
                      size="small"
                      onClick={() => {
                        close();
                        onManualBankAdd();
                      }}
                    >
                      Add details <ArrowRight color="currentColor" />
                    </Button>
                  </PermissionBasedUI>
                )
              }
            />
          )}
        </div>
      </div>

      <Modal.Root
        open={plaidToDirectMigrationStrategyModal.isOpen}
        onOpenChange={plaidToDirectMigrationStrategyModal.close}
      >
        <Modal.Content>
          {currentConnectionId && (
            <PlaidToDirectMigration
              connectionId={currentConnectionId}
              onClose={plaidToDirectMigrationStrategyModal.close}
              entityId={entityIdFromParent || entityId}
            />
          )}
        </Modal.Content>
      </Modal.Root>
    </>
  );
};
