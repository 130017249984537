import React from "react";

export const InkleBooksLogo = () => {
  return (
    <svg
      width="149"
      height="20"
      viewBox="0 0 149 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1036 11.4121H10.5683C10.5118 11.4121 10.5244 11.5266 10.703 11.6182C10.8458 11.6915 10.8662 11.9469 10.8585 12.0654V20.0009H14.1262V12.154C14.0064 11.6237 13.0676 11.4385 12.6131 11.4121H12.1036Z"
        fill="url(#paint0_linear_8954_3631)"
      />
      <path
        d="M6.78918 8.5625H5.25389C5.19739 8.5625 5.20995 8.71502 5.38852 8.83696C5.53137 8.93451 5.5517 9.27461 5.54401 9.43248V19.9996H8.81175V9.5504C8.6919 8.84427 7.7531 8.59758 7.29869 8.5625H6.78918Z"
        fill="url(#paint1_linear_8954_3631)"
      />
      <path
        d="M1.56494 5.33411H0.0296475C-0.0268534 5.33411 -0.0142976 5.52915 0.164273 5.68508C0.30713 5.80982 0.327461 6.24475 0.319769 6.44662V19.9598H3.58751V6.59742C3.46766 5.69443 2.52886 5.37896 2.07444 5.33411H1.56494Z"
        fill="url(#paint2_linear_8954_3631)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.4284 2.85129H-0.00012207V0H11.4284C12.8475 0 14.9443 0.413125 16.7274 1.69433C18.5833 3.02788 19.9998 5.23794 19.9998 8.55388V19.959H17.1427V8.55388C17.1427 6.16723 16.1782 4.81318 15.058 4.00825C13.8649 3.15099 12.3903 2.85129 11.4284 2.85129Z"
        fill="url(#paint3_linear_8954_3631)"
      />
      <path
        d="M29.9999 2.37814C29.9999 1.17467 30.78 0.394653 32.0057 0.394653C33.2092 0.394653 33.9892 1.17467 33.9892 2.37814C33.9892 3.5816 33.2092 4.38391 32.0057 4.38391C30.78 4.38391 29.9999 3.5816 29.9999 2.37814ZM33.7886 19.494H30.2228V6.12224H33.7886V19.494Z"
        fill="url(#paint4_linear_8954_3631)"
      />
      <path
        d="M44.7288 12.7859C44.7288 10.8247 43.7036 9.53205 42.1213 9.53205C40.6281 9.53205 39.5361 10.9138 39.5361 12.8081V19.494H35.9703V6.12224H39.5361V7.68228C40.0041 6.6794 41.3859 5.96623 42.7676 5.96623C46.1774 5.96623 48.2946 8.68517 48.2946 12.7859V19.494H44.7288V12.7859Z"
        fill="url(#paint5_linear_8954_3631)"
      />
      <path
        d="M54.0118 19.494H50.4459V0.550657H54.0118V12.1173L58.5359 6.12224H62.436L57.8896 12.8081L62.904 19.494H58.3799L54.0118 13.499V19.494Z"
        fill="url(#paint6_linear_8954_3631)"
      />
      <path
        d="M67.8047 19.494H64.2389V0.550657H67.8047V19.494Z"
        fill="url(#paint7_linear_8954_3631)"
      />
      <path
        d="M78.6459 15.3934H82.4346C81.5208 17.8894 79.1362 19.6055 76.4173 19.6055C72.5172 19.6055 69.7759 16.7751 69.7759 12.7636C69.7759 8.88574 72.6063 5.94395 76.3504 5.94395C80.0945 5.94395 82.9249 8.88574 82.9249 12.7636C82.9249 13.187 82.9026 13.6327 82.8357 14.0339H73.52C73.9212 15.3934 75.0132 16.2625 76.395 16.2625C77.4424 16.2625 78.267 15.9059 78.6459 15.3934ZM73.52 11.471H79.1808C78.7796 10.1561 77.7322 9.30918 76.3504 9.30918C74.9687 9.30918 73.9212 10.1561 73.52 11.471Z"
        fill="url(#paint8_linear_8954_3631)"
      />
      <path
        d="M87.9249 19.4832V2.15198H94.4495C97.4468 2.15198 99.3838 3.90549 99.3838 6.8212C99.3838 8.53393 98.6702 9.83886 97.406 10.5525C99.1391 11.2661 100.159 12.7138 100.159 14.5693C100.159 17.4238 98.0993 19.4832 95.2243 19.4832H87.9249ZM94.4495 4.33367H90.1269V9.69614H94.633C96.1826 9.69614 97.1817 8.61549 97.1817 6.96393C97.1817 5.37354 96.1215 4.33367 94.4495 4.33367ZM95.2243 17.2811C96.8759 17.2811 97.9565 16.1189 97.9565 14.4877C97.9565 12.9177 96.6924 11.7555 94.633 11.7555H90.1269V17.2811H95.2243Z"
        fill="#41395C"
      />
      <path
        d="M101.759 13.3459C101.759 9.81847 104.369 7.10665 107.753 7.10665C111.118 7.10665 113.707 9.81847 113.707 13.3459C113.707 16.8937 111.118 19.6055 107.753 19.6055C104.369 19.6055 101.759 16.8937 101.759 13.3459ZM107.753 9.28834C105.612 9.28834 103.961 11.0419 103.961 13.3459C103.961 15.6703 105.612 17.4034 107.753 17.4034C109.894 17.4034 111.525 15.6703 111.525 13.3459C111.525 11.0419 109.894 9.28834 107.753 9.28834Z"
        fill="#41395C"
      />
      <path
        d="M115.224 13.3459C115.224 9.81847 117.834 7.10665 121.219 7.10665C124.583 7.10665 127.172 9.81847 127.172 13.3459C127.172 16.8937 124.583 19.6055 121.219 19.6055C117.834 19.6055 115.224 16.8937 115.224 13.3459ZM121.219 9.28834C119.078 9.28834 117.426 11.0419 117.426 13.3459C117.426 15.6703 119.078 17.4034 121.219 17.4034C123.359 17.4034 124.991 15.6703 124.991 13.3459C124.991 11.0419 123.359 9.28834 121.219 9.28834Z"
        fill="#41395C"
      />
      <path
        d="M131.197 19.4832H128.995V2.15198H131.197V13.0196L136.356 7.24938H138.803L133.624 13.3663L139.251 19.4832H136.417L131.197 13.7129V19.4832Z"
        fill="#41395C"
      />
      <path
        d="M139.537 15.5887H141.616C141.678 16.7713 142.86 17.7093 144.267 17.7296C145.572 17.75 146.51 17.1383 146.51 16.1596C146.51 14.9974 145.572 14.5081 143.696 13.9983C141.8 13.4682 139.945 12.7546 139.945 10.5117C139.945 8.41159 141.82 7.10665 144.226 7.10665C146.592 7.10665 148.264 8.55432 148.467 10.7564H146.49C146.347 9.73692 145.409 8.9825 144.206 8.9825C143.044 8.96211 142.147 9.59419 142.126 10.4709C142.126 11.6739 143.615 12.0206 144.451 12.2448C146.306 12.7138 148.692 13.2643 148.692 16.1393C148.692 18.1578 146.775 19.6055 144.206 19.6055C141.902 19.6055 139.598 18.0763 139.537 15.5887Z"
        fill="#41395C"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8954_3631"
          x1="10.5385"
          y1="15.7065"
          x2="14.1262"
          y2="15.7065"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8954_3631"
          x1="5.224"
          y1="14.2811"
          x2="8.81175"
          y2="14.2811"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_8954_3631"
          x1="-0.000244141"
          y1="12.6469"
          x2="3.58751"
          y2="12.6469"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_8954_3631"
          x1="-0.00012207"
          y1="9.97952"
          x2="19.9998"
          y2="9.97952"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_8954_3631"
          x1="29.9999"
          y1="10.0001"
          x2="82.9249"
          y2="10.0001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_8954_3631"
          x1="29.9999"
          y1="10.0001"
          x2="82.9249"
          y2="10.0001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_8954_3631"
          x1="29.9999"
          y1="10.0001"
          x2="82.9249"
          y2="10.0001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_8954_3631"
          x1="29.9999"
          y1="10.0001"
          x2="82.9249"
          y2="10.0001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_8954_3631"
          x1="29.9999"
          y1="10.0001"
          x2="82.9249"
          y2="10.0001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
