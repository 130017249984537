import { useModal } from "hooks/useModal";
import { useState, useEffect, MouseEvent } from "react";
import ReactCountryFlag from "react-country-flag";
import { useGetTaskQuery } from "store/apis/task";
import { formatDate, formatTime } from "utils/formatDate";
import { ChatPane } from "components/ChatPane/ChatPane";
import { InfoItem } from "components/DesignSystem/InfoItem/InfoItem";
import { SliderAccordion } from "components/DesignSystem/SliderAccordion/SliderAccordion";
import { Preview } from "components/PreviewModal";
import DashboardContainer from "components/dashboard/DashboardContainer";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import Tab from "components/DesignSystem/Tab/Tab";
import Slider from "components/DesignSystem/Slider/Slider";
import { Button } from "components/DesignSystem/Button/Button";
import { Field, Form, Formik, useFormikContext } from "formik";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import { useGetAllInkleCPATeamsQuery } from "store/apis/internalTeamSetting";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import {
  useGetServiceTeamQuery,
  useUpdateTaskAssigneeAdminMutation,
  useUpdateTaskAssigneeProviderMutation,
} from "store/apis/serviceTeam";
import { FormikListener } from "components/FormikListener/FormikListener";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import { FieldProps } from "formik";
import { YYYY_MM_DD } from "constants/date";
import { Loader } from "components/DesignSystem/Loader/Loader";
import { ConditionalLink } from "components/conditionalLink";
import { Link } from "components/DesignSystem/Link/Link";
import { TaskStatus } from "components/TaskStatus/TaskStatus";
import ConditionalToolTip from "components/design/conditionalToolTip";
import { ETDUpdateReason } from "./ETDUpdateReason/ETDUpdateReason";
import dayjs from "dayjs";
import { BackendError } from "types/utils/error";
import { useToast } from "hooks/useToast";
import { EtdUpdateHistoryCard } from "./EtdUpdateHistoryCard";
import { TaskStateType } from "types/Models/task";
import { taskAlarmApis, useGetTaskAlarmByIdQuery } from "store/apis/taskList";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import {
  calendarApis,
  useUpdateDeadlineFilingMutation,
} from "store/apis/calendar";
import { BillingInvoice } from "types/Models/billing";
import { useAddProductsToCartAndCheckoutMutation } from "store/apis/billing";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useDispatch } from "react-redux";
import { CheckoutModal } from "components/CheckoutModal/CheckoutModal";
import { setPaymentTitle, setUsableCredit } from "store/slices/credit";
import { useLazyGetTaskDataQuery } from "store/apis/taskTags";
import { PayNotStartedFiling } from "pages/TaskAlarm/PayNotStartedFiling";

type FormData = {
  serviceTeamId: string;
  preparerId: string;
};

const ServiceTeamMemberSelector = () => {
  const { values } = useFormikContext<FormData>();
  const { isCustomer } = useRoleBasedView();

  const { data: serviceTeam = [], isLoading: serviceTeamLoading } =
    useGetServiceTeamQuery(
      {
        serviceTeamId: values.serviceTeamId!,
      },
      { skip: !values.serviceTeamId || isCustomer }
    );
  const selectedServiceTeamMember = serviceTeam.find(
    (m) => m.uuid === values.preparerId
  );

  return (
    <ConditionalToolTip
      condition={
        !values.serviceTeamId &&
        "Please select a service team to add a preparer"
      }
    >
      <div>
        <Combobox
          size="small"
          withForm
          isDisabled={!values.serviceTeamId}
          isLoading={serviceTeamLoading}
          name="preparerId"
          label="Preparer"
          options={serviceTeam.map(({ name, uuid }) => ({
            label: name,
            value: uuid,
            data: name,
          }))}
          value={
            selectedServiceTeamMember
              ? {
                  label: selectedServiceTeamMember.name,
                  value: selectedServiceTeamMember.uuid,
                }
              : null
          }
        />
      </div>
    </ConditionalToolTip>
  );
};

export const TaskAlarmSliderContent = ({
  taskId,
  defaultTab,
  dataType,
  taskInNotStartedState,
  closeSlider,
}: {
  taskId: string;
  defaultTab: "DETAILS" | "CHAT";
  dataType: "TASK" | "ALARM";
  taskInNotStartedState?: boolean;
  closeSlider: () => void;
}) => {
  const previewModal = useModal();
  const [tab, setTab] = useState(defaultTab);
  const { isCpa, isAdmin, isServiceSuperAdmin } = useRoleBasedView();
  const [previewId, setPreviewId] = useState<string | null>(null);
  const etdUpdateReasonModal = useModal();
  const { uuid: groupId } = useCurrentGroupContext();
  const { data: serviceTeams = [] } = useGetAllInkleCPATeamsQuery(
    {
      accessibleTeams: "CPA_TEAM",
    },
    { skip: !isAdmin }
  );
  const isAlarmSlider = dataType === "ALARM";

  const onPreviewOpen = (fileId: string) => {
    previewModal.open();
    setPreviewId(fileId);
  };

  const onPreviewClose = () => {
    previewModal.close();
    setPreviewId(null);
  };

  const { data: taskAlarm, isLoading: isTaskAlarmLoading } =
    useGetTaskAlarmByIdQuery(
      {
        taskAlarmId: taskId!,
        groupId: groupId,
        data_type: dataType,
      },
      { skip: !taskId || !groupId || !dataType }
    );
  const task = taskAlarm?.task_info;
  const alarm = taskAlarm?.alarm_info;

  const [channelId, setChannelId] = useState<string | null>(null);
  const [updateTaskAssigneeForAdmin] = useUpdateTaskAssigneeAdminMutation();
  const [updateTaskAssigneeForProvider] =
    useUpdateTaskAssigneeProviderMutation();
  const { alertToast, successToast } = useToast();
  const [updateDeadline, { isLoading: isUpdating }] =
    useUpdateDeadlineFilingMutation();
  const [alarmInvoice, setAlarmInvoice] = useState<BillingInvoice | null>();
  const alarmInvoiceModal = useModal();
  const [createProductInvoice, { isLoading: addingProduct }] =
    useAddProductsToCartAndCheckoutMutation();
  const entityId = useCurrentEntityId();
  const dispatch = useDispatch();
  const paymentModal = useModal();
  const [getTaskById, { data: taskData, isLoading }] =
    useLazyGetTaskDataQuery();

  let updateTaskAssignee = isAdmin
    ? updateTaskAssigneeForAdmin
    : updateTaskAssigneeForProvider;

  useEffect(() => {
    if (!isAlarmSlider && task?.channel.channel_url) {
      setChannelId(task?.channel.channel_url);
    }
  }, [task?.channel.channel_url, isAlarmSlider]);

  const isTaskInFinalState =
    typeof task?.state === "object" ? task?.state?.is_final : false;

  const onFormChange = (values: FormData) => {
    if (task) {
      let payload = {
        taskId: task?.uuid,
        preparerId: values.preparerId,
        serviceTeamId: values.serviceTeamId,
        removePreparer: !values.preparerId,
        removeServiceTeam: !values.serviceTeamId,
      };

      updateTaskAssignee(payload);
    }
  };

  const updateETD = async (date: Date) => {
    try {
      const estimatedDeliveryTime = dayjs(date).format(YYYY_MM_DD);
      if (task?.uuid) {
        await updateTaskAssignee({
          estimatedDeliveryTime,
          taskId: task?.uuid,
        }).unwrap();
        successToast({ message: "ETD set successfully" });
      }
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const handleEtdChange = (date: Date) => {
    if (task?.estimated_time_of_delivery?.date) {
      etdUpdateReasonModal.open();
    } else {
      updateETD(date);
    }
  };

  const openPaymentModal = async () => {
    try {
      if (task && task.entity) {
        await getTaskById({ uuid: task.uuid }).unwrap();
        dispatch(setUsableCredit(true));
        dispatch(setPaymentTitle(task.title));
        paymentModal.open();
      }
    } catch (error) {
      alertToast({ message: (error as BackendError)?.data?.error?.message });
    }
  };

  const entityLink = isCpa
    ? `/crm/${task?.group_uuid}/entity/${task?.entity?.uuid}`
    : `/entity/${task?.entity.uuid}`;

  if (isAlarmSlider) {
    const updateDeadlineFile = async () => {
      try {
        if (alarm?.uuid) {
          await updateDeadline({ alarmId: alarm?.uuid });
          successToast({ title: "Filing marked successfully" });
        }
      } catch (error) {
        alertToast({
          message: (error as BackendError)?.data?.error?.message,
        });
      }
    };

    const { product_content_type_id, approx_price, base_task_template_id } =
      alarm?.base_task_template_info || {};

    const payInkleToLaunchFiling = async (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (groupId && product_content_type_id && base_task_template_id) {
        try {
          const payload = {
            entity_id: entityId as string,
            product_content_type_id,
            product_id: base_task_template_id,
            quantity: 1,
            payment_amount:
              !approx_price || isNaN(Number(approx_price))
                ? 0
                : Number(approx_price),
            season: alarm?.season,
          };

          const invoiceData = await createProductInvoice({
            groupId: groupId,
            entityId,
            productData: [payload],
          }).unwrap();
          setAlarmInvoice(invoiceData?.invoice);
          alarmInvoiceModal.open();
        } catch (error) {
          alertToast({
            message: (error as BackendError).data?.error?.message,
          });
        }
      }
    };

    const closeAlarmInvoiceModal = () => {
      setAlarmInvoice(null);
      alarmInvoiceModal.close();
    };

    const onPaymentSuccess = () => {
      setAlarmInvoice(null);
      alarmInvoiceModal.close();
      dispatch(taskAlarmApis.util.invalidateTags([{ type: "TASK_ALARM" }]));
    };

    return (
      <Tab.Root
        value={tab}
        asChild
        onValueChange={(v) => setTab(v as typeof tab)}
      >
        <DashboardContainer className="t-h-full">
          <DashboardContainer.Header>
            <Slider.Header
              bottom={
                <Tab.List className="!t-pt-0">
                  <Tab.Trigger value="DETAILS">Details</Tab.Trigger>
                </Tab.List>
              }
            >
              <Slider.Title>
                {alarm?.base_task_template_info?.title}
              </Slider.Title>
              <Slider.Close />
            </Slider.Header>
          </DashboardContainer.Header>
          <DashboardContainer.Content className="t-h-full">
            <Tab.Content value="DETAILS" asChild>
              {isTaskAlarmLoading || !alarm ? (
                <div className="t-flex t-h-full t-w-full t-justify-center t-items-center">
                  <Loader />
                </div>
              ) : (
                <>
                  <Slider.Body>
                    <SliderAccordion.Root
                      type="multiple"
                      defaultValue={["ABOUT", "FILING_DETAILS"]}
                      className="t-flex t-gap-4 t-flex-col t-mb-16"
                    >
                      <SliderAccordion.Item value="ABOUT">
                        <SliderAccordion.Trigger disabled>
                          About
                        </SliderAccordion.Trigger>
                        <SliderAccordion.Content forceMount>
                          <div className="t-grid t-gap-6 t-grid-cols-2">
                            <div className="t-col-span-2">
                              <InfoItem label="Filing name">
                                {alarm?.base_task_template_info?.title}
                              </InfoItem>
                            </div>

                            <InfoItem label="Pricing">
                              {isNaN(
                                Number(
                                  alarm?.base_task_template_info?.approx_price
                                )
                              ) ? (
                                alarm?.base_task_template_info?.approx_price
                              ) : (
                                <AmountSuperScript
                                  amount={Number(
                                    alarm?.base_task_template_info?.approx_price
                                  )}
                                />
                              )}
                            </InfoItem>

                            <InfoItem label="Deadline">
                              {formatDate(
                                alarm?.base_task_template_info?.approx_deadline
                              )}
                            </InfoItem>
                          </div>
                        </SliderAccordion.Content>
                      </SliderAccordion.Item>
                      <SliderAccordion.Item value="FILING_DETAILS">
                        <SliderAccordion.Trigger>
                          Filing details
                        </SliderAccordion.Trigger>
                        <SliderAccordion.Content>
                          <SliderAccordion.ItemGrid>
                            {alarm?.base_task_template_info?.description && (
                              <div className="t-col-span-2 [&_ul]:t-m-0">
                                <InfoItem label="Description">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        alarm?.base_task_template_info
                                          ?.description || "",
                                    }}
                                  />
                                </InfoItem>
                                {alarm?.base_task_template_info?.extension && (
                                  <InfoItem label="Extension">
                                    <div
                                      className="t-text-body-sm t-text-red-50"
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          alarm?.base_task_template_info
                                            ?.extension || "",
                                      }}
                                    />
                                  </InfoItem>
                                )}
                              </div>
                            )}
                          </SliderAccordion.ItemGrid>
                        </SliderAccordion.Content>
                      </SliderAccordion.Item>
                    </SliderAccordion.Root>
                  </Slider.Body>
                  <Slider.Footer className="t-flex t-gap-2">
                    {alarm.filing_state === "TASK_CREATION_IN_PROGRESS" ? (
                      <Button customType="primary" size="small" block disabled>
                        Go to filing
                      </Button>
                    ) : (
                      <>
                        <Button
                          size="small"
                          onClick={updateDeadlineFile}
                          isLoading={isUpdating}
                          disabled={isUpdating}
                        >
                          Filled externally
                        </Button>
                        <Button
                          customType="primary"
                          size="small"
                          block
                          onClick={payInkleToLaunchFiling}
                          isLoading={addingProduct}
                          disabled={addingProduct}
                        >
                          Start filing
                        </Button>
                      </>
                    )}
                  </Slider.Footer>
                </>
              )}
            </Tab.Content>
          </DashboardContainer.Content>
          {alarmInvoiceModal.isOpen && Boolean(alarmInvoice) && (
            <CheckoutModal
              entityId={entityId}
              type="invoices"
              onInvoicePaid={onPaymentSuccess}
              open={alarmInvoiceModal.isOpen}
              onClose={closeAlarmInvoiceModal}
              invoices={[alarmInvoice as BillingInvoice]}
            />
          )}
        </DashboardContainer>
      </Tab.Root>
    );
  }

  return (
    <>
      <Tab.Root
        value={tab}
        asChild
        onValueChange={(v) => setTab(v as typeof tab)}
      >
        <DashboardContainer className="t-h-full">
          <DashboardContainer.Header>
            <Slider.Header
              bottom={
                taskInNotStartedState ? (
                  <></>
                ) : (
                  <Tab.List className="!t-pt-0">
                    <Tab.Trigger value="DETAILS">Details</Tab.Trigger>
                    <Tab.Trigger value="CHAT">Chat</Tab.Trigger>
                  </Tab.List>
                )
              }
            >
              <Slider.Title>{task?.title}</Slider.Title>
              <Slider.Close />
            </Slider.Header>
          </DashboardContainer.Header>
          <DashboardContainer.Content className="t-h-full">
            <Tab.Content value="DETAILS" asChild>
              {isTaskAlarmLoading || !task ? (
                <div className="t-flex t-h-full t-w-full t-justify-center t-items-center">
                  <Loader />
                </div>
              ) : (
                <>
                  <Slider.Body>
                    <SliderAccordion.Root
                      type="multiple"
                      defaultValue={[
                        "ABOUT",
                        "FILING_DETAILS",
                        "ETD_DETAILS",
                        "PAYMENT_DETAILS",
                      ]}
                      className="t-flex t-gap-4 t-flex-col t-mb-16"
                    >
                      <SliderAccordion.Item value="ABOUT">
                        <SliderAccordion.Trigger disabled>
                          About
                        </SliderAccordion.Trigger>
                        <SliderAccordion.Content forceMount>
                          <Formik
                            enableReinitialize
                            initialValues={{
                              serviceTeamId: task.assigned_team?.uuid || "",
                              preparerId: task.assigned_to?.uuid || "",
                            }}
                            onSubmit={() => {}}
                          >
                            {({ values }) => {
                              const selectedServiceTeam = serviceTeams.find(
                                (t) => t.uuid === values.serviceTeamId
                              );

                              return (
                                <Form className="t-m-0">
                                  <FormikListener
                                    values={values}
                                    callback={onFormChange}
                                  />
                                  <div className="t-grid t-gap-6 t-grid-cols-2">
                                    <div className="t-col-span-2">
                                      <InfoItem label="Filing name">
                                        {taskInNotStartedState ? (
                                          task.title
                                        ) : (
                                          <Link
                                            to={`/tax/filings/${task?.uuid}`}
                                          >
                                            {task.title}
                                          </Link>
                                        )}
                                      </InfoItem>
                                    </div>

                                    <InfoItem label="Pricing">
                                      {isNaN(Number(task?.payment_amount)) ? (
                                        task?.payment_amount
                                      ) : (
                                        <AmountSuperScript
                                          amount={Number(task?.payment_amount)}
                                        />
                                      )}
                                    </InfoItem>

                                    <InfoItem label="Step">
                                      <TaskStatus
                                        state={task.state}
                                        status={
                                          typeof task?.state === "string"
                                            ? task.status
                                            : task.state?.name
                                        }
                                        statusType={
                                          typeof task?.state === "string"
                                            ? task.state_details?.type
                                            : task.state?.type
                                        }
                                      />
                                    </InfoItem>

                                    <InfoItem label="Entity">
                                      <Link
                                        to={entityLink}
                                        className="t-flex t-gap-1 t-items-center"
                                      >
                                        {task.entity.code_alpha_2 && (
                                          <ReactCountryFlag
                                            countryCode={
                                              task.entity.code_alpha_2
                                            }
                                            svg
                                          />
                                        )}
                                        <span>{task.entity.name}</span>
                                      </Link>
                                    </InfoItem>

                                    <InfoItem label="Deadline">
                                      {formatDate(task.deadline)}
                                    </InfoItem>

                                    {isAdmin && (
                                      <Combobox
                                        size="small"
                                        withForm
                                        name="serviceTeamId"
                                        label="Service team"
                                        options={serviceTeams.map((t) => ({
                                          label: t.name,
                                          value: t.uuid,
                                        }))}
                                        value={
                                          selectedServiceTeam
                                            ? {
                                                label: selectedServiceTeam.name,
                                                value: selectedServiceTeam.uuid,
                                              }
                                            : null
                                        }
                                      />
                                    )}

                                    {(isServiceSuperAdmin || isAdmin) && (
                                      <ServiceTeamMemberSelector />
                                    )}

                                    {isCpa && task.assigned_at && (
                                      <InfoItem label="Asigned on">
                                        {formatDate(task.assigned_at)}
                                      </InfoItem>
                                    )}
                                  </div>
                                </Form>
                              );
                            }}
                          </Formik>
                        </SliderAccordion.Content>
                      </SliderAccordion.Item>

                      <SliderAccordion.Item value="FILING_DETAILS">
                        <SliderAccordion.Trigger>
                          Filing details
                        </SliderAccordion.Trigger>
                        <SliderAccordion.Content>
                          <SliderAccordion.ItemGrid>
                            {task.description && (
                              <div className="t-col-span-2 [&_ul]:t-m-0">
                                <InfoItem label="Description">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: task.description || "",
                                    }}
                                  />
                                </InfoItem>
                              </div>
                            )}

                            {task.doc_data?.data &&
                              task.doc_data?.data.length > 0 && (
                                <div className="t-col-span-2">
                                  <InfoItem label="Documents required">
                                    <ul className="t-m-0">
                                      {task.doc_data?.data
                                        ?.map((d) => d.documents_needed)
                                        .flat()
                                        .map((d) => (
                                          <li key={d.field_key}>{d.label}</li>
                                        ))}
                                    </ul>
                                  </InfoItem>
                                </div>
                              )}

                            {task.eligibility && (
                              <div className="t-col-span-2">
                                <InfoItem label="Eligibility">
                                  {task.eligibility}
                                </InfoItem>
                              </div>
                            )}

                            {task.penalty_fee && (
                              <div className="t-col-span-2">
                                <InfoItem label="Penalty">
                                  {isNaN(Number(task.penalty_fee)) ? (
                                    task.penalty_fee
                                  ) : (
                                    <AmountSuperScript
                                      amount={Number(task.penalty_fee)}
                                    />
                                  )}
                                </InfoItem>
                              </div>
                            )}
                          </SliderAccordion.ItemGrid>
                        </SliderAccordion.Content>
                      </SliderAccordion.Item>

                      {isCpa && (
                        <SliderAccordion.Item value="ETD_DETAILS">
                          <SliderAccordion.Trigger>
                            Estimated time of delivery
                          </SliderAccordion.Trigger>
                          <SliderAccordion.Content>
                            <Formik
                              enableReinitialize
                              initialValues={{
                                estimatedDeliveryTime:
                                  task.estimated_time_of_delivery?.date || "",
                              }}
                              onSubmit={() => {}}
                            >
                              {({ values, setFieldValue }) => {
                                return (
                                  <Form className="t-m-0">
                                    <ConditionalToolTip
                                      condition={
                                        (!task.assigned_team?.uuid &&
                                          "Please select a service team to add a ETD") ||
                                        (isTaskInFinalState &&
                                          "This filing is completed and delivered.")
                                      }
                                    >
                                      <div>
                                        <Field name="estimatedDeliveryTime">
                                          {({ field }: FieldProps) => {
                                            return (
                                              <>
                                                <DateInput
                                                  customSize="small"
                                                  disabled={
                                                    !task.assigned_team?.uuid ||
                                                    isTaskInFinalState
                                                  }
                                                  {...field}
                                                  saveFormat={YYYY_MM_DD}
                                                  portalId="add-manual-bank-date"
                                                  label="Estimated time of delivery"
                                                  block
                                                  onDateChange={(date) => {
                                                    setFieldValue(
                                                      "estimatedDeliveryTime",
                                                      date
                                                    );
                                                    handleEtdChange(date);
                                                  }}
                                                />
                                              </>
                                            );
                                          }}
                                        </Field>
                                      </div>
                                    </ConditionalToolTip>
                                    {task.etd_history?.length > 0 && (
                                      <div className="t-mt-6">
                                        <InfoItem label="History">
                                          <div className="t-flex t-flex-col t-w-full t-space-y-2 t-ml-2 t-mt-1">
                                            {task.etd_history?.map(
                                              (
                                                {
                                                  profile_name,
                                                  event,
                                                  event_time,
                                                  event_description,
                                                  uuid,
                                                },
                                                index
                                              ) => (
                                                <EtdUpdateHistoryCard
                                                  key={uuid}
                                                  isLastStep={
                                                    index ===
                                                    task.etd_history.length - 1
                                                  }
                                                  description={
                                                    event_description
                                                  }
                                                  eventDate={event_time}
                                                  profileName={profile_name}
                                                  event={event}
                                                />
                                              )
                                            )}
                                          </div>
                                        </InfoItem>
                                      </div>
                                    )}
                                    {task?.uuid &&
                                      etdUpdateReasonModal.isOpen && (
                                        <ETDUpdateReason
                                          isOpen={etdUpdateReasonModal.isOpen}
                                          close={etdUpdateReasonModal.close}
                                          taskId={task.uuid}
                                          selectedDate={
                                            values.estimatedDeliveryTime
                                          }
                                        />
                                      )}
                                  </Form>
                                );
                              }}
                            </Formik>
                          </SliderAccordion.Content>
                        </SliderAccordion.Item>
                      )}

                      <SliderAccordion.Item value="PAYMENT_DETAILS">
                        <SliderAccordion.Trigger>
                          Payment details
                        </SliderAccordion.Trigger>
                        <SliderAccordion.Content>
                          <SliderAccordion.ItemGrid>
                            <InfoItem label="Government fees">
                              <AmountSuperScript
                                amount={Number(task?.government_fee)}
                              />
                            </InfoItem>

                            {task.invoices && task.invoices.length > 0 && (
                              <InfoItem label="Invoice">
                                <div className="t-flex t-flex-wrap t-gap-1">
                                  {task.invoices?.map((i) => (
                                    <Button
                                      key={i.uuid}
                                      customType="link"
                                      onClick={() => onPreviewOpen(i.uuid)}
                                    >
                                      #invoice{i.name}
                                    </Button>
                                  ))}
                                </div>
                                <Preview
                                  showModal={previewModal.isOpen}
                                  closeModal={onPreviewClose}
                                  groupId={task.group_uuid}
                                  previewId={previewId}
                                />
                              </InfoItem>
                            )}
                          </SliderAccordion.ItemGrid>
                        </SliderAccordion.Content>
                      </SliderAccordion.Item>
                    </SliderAccordion.Root>
                  </Slider.Body>
                  <Slider.Footer>
                    {taskInNotStartedState ? (
                      <Button
                        block
                        size="small"
                        customType="primary"
                        onClick={openPaymentModal}
                        isLoading={isLoading}
                        disabled={isLoading}
                      >
                        Start filing
                      </Button>
                    ) : (
                      <ConditionalLink to={`/tax/filings/${task?.uuid}`}>
                        <Button
                          customType="primary"
                          size="small"
                          block
                          disabled={isTaskAlarmLoading || !task}
                        >
                          Go to Filing
                        </Button>
                      </ConditionalLink>
                    )}
                  </Slider.Footer>
                  {paymentModal.isOpen && task && (
                    <PayNotStartedFiling
                      onClose={paymentModal.close}
                      onInvoicePaid={() => {
                        dispatch(
                          taskAlarmApis.util.invalidateTags([
                            { type: "TASK_ALARM" },
                          ])
                        );
                        paymentModal.close();
                        closeSlider();
                      }}
                      task={taskData}
                    />
                  )}
                </>
              )}
            </Tab.Content>

            <Tab.Content value="CHAT" asChild>
              {isTaskAlarmLoading || !task ? (
                <div className="t-flex t-h-full t-w-full t-justify-center t-items-center">
                  <Loader />
                </div>
              ) : (
                <ChatPane channelId={channelId} setChannelId={setChannelId} />
              )}
            </Tab.Content>
          </DashboardContainer.Content>
        </DashboardContainer>
      </Tab.Root>
    </>
  );
};

export const TaskAlarmSlider = ({
  taskId,
  isOpen,
  onClose,
  defaultTab = "DETAILS",
  dataType,
  taskInNotStartedState,
}: {
  taskId?: string | null;
  isOpen: boolean;
  onClose: () => void;
  defaultTab?: "DETAILS" | "CHAT";
  dataType: "TASK" | "ALARM";
  taskInNotStartedState?: boolean;
}) => {
  return (
    <Slider.Root open={isOpen} onOpenChange={onClose}>
      <Slider.Content open={isOpen}>
        {taskId && (
          <TaskAlarmSliderContent
            defaultTab={defaultTab}
            taskId={taskId}
            dataType={dataType}
            taskInNotStartedState={taskInNotStartedState}
            closeSlider={onClose}
          />
        )}
      </Slider.Content>
    </Slider.Root>
  );
};
