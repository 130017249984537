export const Paperclip = ({ size = "20" }: { size?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clipPath="url(#clip0_1957_24598)">
      <path
        d="M12.5 6.24998L5.99142 12.8664C5.76271 13.102 5.63588 13.4181 5.63835 13.7465C5.64083 14.0748 5.77239 14.389 6.00463 14.6211C6.23686 14.8533 6.55111 14.9847 6.87946 14.987C7.20781 14.9894 7.52389 14.8624 7.75939 14.6336L15.518 6.76795C15.9869 6.29905 16.2503 5.6631 16.2503 4.99998C16.2503 4.33686 15.9869 3.70091 15.518 3.23201C15.0491 2.76312 14.4131 2.49969 13.75 2.49969C13.0869 2.49969 12.4509 2.76312 11.982 3.23201L4.22345 11.0984C3.52965 11.8036 3.14261 12.7544 3.14664 13.7437C3.15067 14.7329 3.54544 15.6805 4.24496 16.38C4.94448 17.0796 5.89207 17.4743 6.88134 17.4784C7.8706 17.4824 8.82138 17.0953 9.52658 16.4015L15.9375 9.99998"
        stroke="currentColor"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1957_24598">
        <rect width={size} height={size} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
