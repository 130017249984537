import classNames from "classnames";
import { LoadingIcon } from "components/icons/LoadingIcon";
import { motion } from "framer-motion";
import "../../static/styles/components/loader.css";

const Loader = ({
  addClassName = "",
  size = "regular",
  customType = "primary",
}: {
  addClassName?: string;
  size?: "regular" | "small";
  customType?: "primary" | "secondary";
}) => {
  if (customType === "secondary") {
    return (
      <span
        data-testid="loader-v0"
        className="t-flex t-origin-center t-animate-spin t-text-neutral-70"
      >
        <LoadingIcon />
      </span>
    );
  }

  if (customType === "primary") {
    return (
      <motion.div
        data-testid="loader-v0"
        id="loader-v0"
        layout
        className={classNames(`loaderContainer ${addClassName}`, {
          "t-h-[580px]": size === "regular",
          "t-h-4": size === "small",
        })}
      >
        <div
          className={classNames("loaderStyle", {
            "t-h-4 t-w-4": size === "small",
            "t-h-11 t-w-11": size === "regular",
          })}
        ></div>
      </motion.div>
    );
  }
  return null;
};

export default Loader;
