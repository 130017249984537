export const UsersThree = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 11.25C9.38071 11.25 10.5 10.1307 10.5 8.75C10.5 7.36929 9.38071 6.25 8 6.25C6.61929 6.25 5.5 7.36929 5.5 8.75C5.5 10.1307 6.61929 11.25 8 11.25Z"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.25 7.25C12.8323 7.24902 13.4069 7.38414 13.9277 7.64457C14.4486 7.905 14.9014 8.28354 15.25 8.75"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.75 8.75C1.09862 8.28354 1.55142 7.905 2.07229 7.64457C2.59315 7.38414 3.16766 7.24902 3.75 7.25"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.3999 13.5C4.72917 12.8257 5.2412 12.2574 5.87768 11.8599C6.51416 11.4624 7.24949 11.2516 7.9999 11.2516C8.75032 11.2516 9.48565 11.4624 10.1221 11.8599C10.7586 12.2574 11.2706 12.8257 11.5999 13.5"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.75008 7.25C3.37048 7.25038 2.99861 7.14273 2.67792 6.93962C2.35723 6.73651 2.10097 6.44633 1.93907 6.10299C1.77717 5.75965 1.71633 5.37732 1.76365 5.00068C1.81097 4.62404 1.9645 4.26865 2.2063 3.97603C2.4481 3.6834 2.76818 3.46564 3.12915 3.34817C3.49011 3.2307 3.87705 3.21839 4.24476 3.31266C4.61247 3.40694 4.94575 3.60391 5.20567 3.88057C5.46558 4.15722 5.6414 4.50213 5.71258 4.875"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.2876 4.875C10.3588 4.50213 10.5346 4.15722 10.7945 3.88057C11.0544 3.60391 11.3877 3.40694 11.7554 3.31266C12.1231 3.21839 12.5101 3.2307 12.871 3.34817C13.232 3.46564 13.5521 3.6834 13.7939 3.97603C14.0357 4.26865 14.1892 4.62404 14.2365 5.00068C14.2838 5.37732 14.223 5.75965 14.0611 6.10299C13.8992 6.44633 13.6429 6.73651 13.3223 6.93962C13.0016 7.14273 12.6297 7.25038 12.2501 7.25"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
