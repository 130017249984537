import classNames from "classnames";
import { ConditionalLink } from "components/conditionalLink";
import { Button } from "components/DesignSystem/Button/Button";
import { Link } from "components/DesignSystem/Link/Link";
import { ArrowRight } from "components/icons/ArrowRight";
import { DeadlineClock } from "components/Illustrations/DeadlineClock";
import { DeadlineClockBig } from "components/Illustrations/DeadlineClockBig";
import { DD_MMM_YYYY, YYYY_MM_DD } from "constants/date";
import dayjs from "dayjs";
import { AnimatePresence, motion } from "framer-motion";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useEffect, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { useHistory } from "react-router-dom";
import { useLazyGetAllCompliancesQuery } from "store/apis/calendar";
import { AllCompliances } from "types/Models/calendar";

export const DeadlinesList = ({
  deadlines,
  entityId,
}: {
  deadlines: AllCompliances;
  entityId: string;
}) => {
  const { entities } = useCurrentGroupContext();
  const entity = entities.find((e) => e.uuid === entityId);

  return (
    <div className="t-flex t-flex-col t-gap-3 t-py-3">
      {entity && (
        <div className="t-flex t-gap-1 t-items-center">
          <ReactCountryFlag countryCode={entity?.country_code} svg />
          <p className="t-text-body-sm t-text-text-60 t-m-0">{entity?.name}</p>
        </div>
      )}
      {deadlines.current_alarms.map((deadline) => {
        const taskLink = deadline.task_info?.task_id
          ? `/filings/${deadline.task_info?.task_id}`
          : `/filings/addtask/${deadline.base_task_template_info?.base_task_template_id}?entity=${deadline.entity_id}`;

        return (
          <div key={deadline.uuid} className="t-w-full">
            <div className="t-group t-relative t-flex t-items-start t-gap-3 md:t-items-center md:t-gap-5 t-rounded-t-lg">
              {deadline.deadline && (
                <>
                  <div className="t-hidden t-h-[64px] t-shrink-0 t-grow-0 t-basis-[64px] md:t-block" />
                  <AnimatePresence>
                    <motion.div
                      key={deadline.deadline}
                      transition={{ duration: 0.45, delay: -0.1 }}
                      initial={{ x: 30, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      exit={{ x: -30, opacity: 0 }}
                      className={classNames(
                        "t-absolute t-hidden t-h-[64px] t-w-[64px] t-shrink-0 t-grow-0 t-flex-col t-items-center t-justify-center t-rounded-lg t-bg-surface-lighter-grey t-border-0 t-border-r t-border-solid t-border-neutral-10 md:t-flex"
                      )}
                    >
                      <span className="t-block t-whitespace-nowrap t-text-body t-font-bold">
                        {dayjs(deadline.deadline).format("DD MMM")}
                      </span>
                      <span className="t-text-body-sm t-uppercase">
                        {dayjs(deadline.deadline).format("YYYY")}
                      </span>
                    </motion.div>
                  </AnimatePresence>
                </>
              )}

              <div className="t-flex md:t-items-center md:t-gap-5 t-justify-between t-grow">
                <div className="t-w-2/3 t-flex-col t-gap-1 t-space-y-2 md:t-flex md:t-space-y-0">
                  <div className="t-text-subtitle-sm t-text-text-60">
                    {deadline.base_task_template_info?.title}
                  </div>
                </div>
                <div className="t-flex t-gap-2 t-ml-auto">
                  {deadline.task_info?.task_id ? (
                    <Link to={taskLink}>
                      <Button size="small">Go to filing</Button>
                    </Link>
                  ) : (
                    <>
                      {deadline.base_task_template_info && (
                        <Link to={taskLink}>
                          <Button size="small">Start filing</Button>
                        </Link>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const DeadlineCard = () => {
  const history = useHistory();
  const { entities } = useCurrentGroupContext();

  const [expanded, setExpanded] = useState(false);
  const [entityDeadlinesMap, setEntityDeadlinesMap] = useState<
    Record<string, AllCompliances>
  >({});

  const [getComplianceForEntity] = useLazyGetAllCompliancesQuery();

  useEffect(() => {
    const getAllEntitiesCompliance = async () => {
      for (const entity of entities) {
        const { data: entityDeadlines } = await getComplianceForEntity(
          {
            entityId: entity.uuid,
            startDate: dayjs().format(YYYY_MM_DD),
            endDate: dayjs().add(60, "day").format(YYYY_MM_DD),
          },
          true
        );

        if (entityDeadlines) {
          setEntityDeadlinesMap((prev) => ({
            ...prev,
            [entity.uuid]: entityDeadlines,
          }));
        }
      }
    };

    getAllEntitiesCompliance();
  }, [entities, getComplianceForEntity]);

  const allDeadlines = Object.values(entityDeadlinesMap).reduce((acc, curr) => {
    return [...acc, ...curr.current_alarms];
  }, [] as AllCompliances["current_alarms"]);

  if (allDeadlines.length === 0) {
    return null;
  }

  if (allDeadlines.length === 1) {
    const deadline = allDeadlines[0];

    const taskLink = deadline.task_info?.task_id
      ? `/filings/${deadline.task_info?.task_id}`
      : `/filings/addtask/${deadline.base_task_template_info?.base_task_template_id}?entity=${deadline.entity_id}`;

    const entity = entities.find((e) => e.uuid === deadline.entity_id);

    return (
      <ConditionalLink to={taskLink}>
        <div className="t-border t-relative t-border-solid t-rounded-lg t-p-5 t-border-neutral-10 md:t-h-[200px] t-text-text-100 t-overflow-hidden hover:t-shadow-light-30">
          <div className="t-bg-pink-40 t-blur-3xl t-bg-opacity-20 t-rounded-full t-w-72 t-h-72 t-absolute t-left-0 -t-translate-y-[80%] -t-translate-x-[40%]" />
          <div className="t-bg-pink-40 t-blur-3xl t-bg-opacity-20 t-rounded-full t-w-72 t-h-72 t-absolute t-right-0 -t-translate-y-[80%] t-translate-x-[40%]" />

          <div className="t-flex t-gap-5 t-justify-between t-h-full">
            <div className="t-flex t-flex-col t-justify-between">
              <p className="t-flex t-flex-col t-gap-1">
                <span className="t-text-subtext">Upcoming Deadline</span>
                <span className="t-text-h5 t-w-[250px] t-line-clamp-1">
                  {deadline.base_task_template_info?.title}
                </span>

                {entity && (
                  <div className="t-flex t-gap-1 t-items-center">
                    <ReactCountryFlag
                      countryCode={entity?.country_code}
                      svg
                      className="t-rounded-[1px] !t-w-3.5 !t-h-auto"
                    />
                    <p className="t-text-body-sm t-text-text-60 t-m-0">
                      {entity?.name}
                    </p>
                  </div>
                )}

                {deadline.deadline && (
                  <span className="t-text-subtext t-text-red">
                    Due on {dayjs(deadline.deadline).format(DD_MMM_YYYY)}
                  </span>
                )}
              </p>
              <div>
                <Button customType="danger" size="small">
                  {deadline.task_info?.task_id ? "Continue" : "Start now"}
                </Button>
              </div>
            </div>
            <div className="t-z-10">
              <DeadlineClockBig />
            </div>
          </div>
        </div>
      </ConditionalLink>
    );
  }

  const [firstThreeDeadlines] = Object.entries(entityDeadlinesMap).reduce(
    (acc, [entityId, deadlines]) => {
      const [currentEntityDeadlinesMap, count] = acc;

      if (count && count >= 3) {
        return acc;
      }

      const currentAlarms = deadlines.current_alarms;
      const alarms = currentAlarms.slice(0, 3 - (count || 0));

      const deadlineMap = {
        ...(currentEntityDeadlinesMap || {}),

        [entityId]: {
          ...deadlines,
          current_alarms: alarms,
        } as AllCompliances,
      } as Record<string, AllCompliances>;

      const nextCount = (count || 0) + alarms.length;

      const a = [deadlineMap, nextCount] as [
        typeof currentEntityDeadlinesMap,
        number
      ];

      return a;
    },
    [undefined, 0] as [typeof entityDeadlinesMap | undefined, number] | []
  );

  return (
    <div className="t-border t-relative t-border-solid t-flex t-flex-col t-rounded-lg t-p-5 t-border-neutral-0 t-text-text-100 t-bg-auto t-row-span-6 t-transition-all t-overflow-hidden hover:t-shadow-light-30 t-self-start">
      <div className="t-bg-pink-40 t-blur-3xl t-bg-opacity-20 t-rounded-full t-w-72 t-h-72 t-absolute t-left-0 -t-translate-y-[80%] -t-translate-x-[40%]" />
      <div className="t-bg-pink-40 t-blur-3xl t-bg-opacity-20 t-rounded-full t-w-72 t-h-72 t-absolute t-right-0 -t-translate-y-[80%] t-translate-x-[40%]" />

      <div className="t-flex t-mb-1.5 t-justify-between">
        <div className="t-flex t-flex-col t-gap-1.5">
          <p className="t-m-0 t-text-subtext t-text-red">Stay compliant!</p>
          <p className="t-m-0 t-text-h4 t-text-i-red">Upcoming Deadlines</p>
          <div className="t-text-body-sm t-text-text-30 t-flex t-items-center">
            <p>View all deadlines </p>
            <p>
              <Button
                customType="text"
                size="extra-small"
                onClick={() => history.push(`/deadlines`)}
              >
                &nbsp;here
              </Button>
            </p>
          </div>
        </div>

        <div className="t-z-10">
          <DeadlineClock />
        </div>
      </div>
      <div className="t-w-full t-flex t-flex-col">
        {Object.entries(
          expanded ? entityDeadlinesMap : firstThreeDeadlines || {}
        ).map(
          ([entityId, deadlines], index) =>
            deadlines.current_alarms.length > 0 && (
              <div
                key={entityId}
                className="t-border-solid t-border-0 first:t-border-t-0 t-border-t t-border-neutral-0"
              >
                <DeadlinesList
                  key={entityId}
                  deadlines={deadlines}
                  entityId={entityId}
                />
              </div>
            )
        )}
      </div>

      {allDeadlines.length > 3 && (
        <div className="t-bg-white t-flex t-justify-center t-mt-auto">
          <Button
            customType="ghost"
            block
            onClick={() => setExpanded((v) => !v)}
          >
            <span className="t-text-text-60">
              View {expanded ? "less" : "for next 60 days"}
            </span>
            <span
              className={classNames("t-text-text-30 t-transition-transform", {
                "-t-rotate-90": expanded,
                "t-rotate-90": !expanded,
              })}
            >
              <ArrowRight />
            </span>
          </Button>
        </div>
      )}
    </div>
  );
};
