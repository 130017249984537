import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { Filter } from "components/DesignSystem/Filter/Filter";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import { Search } from "components/DesignSystem/Search/Search";
import { Capsule } from "components/Transaction/Filter";
import { billingFilterNameMapping } from "constants/billing";
import { Form, Formik, Field } from "formik";
import { useModal } from "hooks/useModal";
import { useUpdateQuery } from "hooks/useQuery";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useDispatch, useSelector } from "react-redux";
import { setBillingFilters } from "store/slices/billingFilters";
import { debounce } from "utils/debouncing";
import { CreateOrEditInvoice } from "./CreateInvoice";
import { ExportBilling } from "./ExportBilling";
import { DD_MMM_YYYY, YYYY_MM_DD } from "constants/date";
import { DateFilter } from "components/Filters/DateFilter";
import { useFilters } from "hooks/useFilter";
import { formatDate } from "utils/formatDate";
import dayjs from "dayjs";
import { Chip } from "components/DesignSystem/Chips/Chips";
export default function GlobalFilter({
  setSearchTerm,
  paginationData,
  setPageNumber,
  groupId,
  searchTerm,
  values,
  updateFilter,
}) {
  const dispatch = useDispatch();
  const { filters } = useSelector((state) => state.billingFilters);

  let selectedFilter = [];

  for (const key in filters) {
    if (filters[key]) {
      for (const filterElement of Object.entries(filters[key])) {
        const name = filterElement?.[0];
        const value = filterElement?.[1];
        selectedFilter.push({
          type: key,
          name,
          value,
        });
      }
    }
  }

  const goToFirstPage = () => {
    setPageNumber(1);
  };

  const { currentPage, totalPage } = paginationData;

  const goToPrevPage = () => {
    const localCurrentPage = currentPage < totalPage ? currentPage : totalPage;
    setPageNumber(localCurrentPage - 1);
  };

  const goToNextPage = () => {
    if (currentPage < totalPage) {
      setPageNumber(currentPage + 1);
    }
  };
  const goToLastPage = () => {
    setPageNumber(totalPage);
  };

  const setFilterData = debounce((e) => {
    setSearchTerm(e.target.value);
  });
  const { isOpen, open, close } = useModal();

  const { isAdmin } = useRoleBasedView();
  const { update } = useUpdateQuery();

  const onHandleChange = ({ e, type }) => {
    const { id, checked, name } = e.target;
    update({ query: `${type}_${name}`, value: checked ? true : null });
    dispatch(setBillingFilters({ type, name: id, checked }));
  };

  const removeFilter = (type, name) => {
    update({ query: `${type}_${name}`, value: null });
    dispatch(setBillingFilters({ type, name, checked: false }));
  };

  return (
    <div className="t-w-full t-flex t-flex-col t-gap-3 t-sticky t-top-0 t-bg-white t-z-20 t-pb-3">
      <div className="t-flex t-justify-between">
        <div className="t-flex t-gap-3 t-items-center">
          <div className="t-text-subtitle">Invoices</div>
          <div className="t-ml-3">
            <Search onChange={setFilterData} placeholder="Search..." />
          </div>
        </div>
        <Pagination
          {...paginationData}
          goToFirstPage={goToFirstPage}
          goToPrevPage={goToPrevPage}
          goToNextPage={goToNextPage}
          goToLastPage={goToLastPage}
        />
      </div>
      <div className="t-flex t-justify-between">
        <div className="t-flex t-gap-2">
          <Filter.Root
            defaultValue="productType"
            capsule={
              <>
                {selectedFilter?.map(({ type, value, name }) => {
                  let updatedName = billingFilterNameMapping[name] || name;

                  return (
                    value && (
                      <Capsule
                        value={updatedName}
                        onCapsuleClick={() => removeFilter(type, name)}
                        key={updatedName}
                        type={type}
                      >
                        <></>
                      </Capsule>
                    )
                  );
                })}
                {values.START_DATE && (
                  <Chip
                    onClose={() => {
                      updateFilter("START_DATE", "");
                      updateFilter("END_DATE", "");
                    }}
                    isActive
                    filterType="journalEntryDate"
                  >
                    From: {formatDate(values.START_DATE)} To:{" "}
                    {formatDate(values.END_DATE)}
                  </Chip>
                )}
              </>
            }
          >
            <Filter.Portal>
              <Filter.List>
                <Filter.ListItem value="productType">Type</Filter.ListItem>
                <Filter.ListItem value="selectedSeason">Season</Filter.ListItem>
                <Filter.ListItem value="paymentStatus">Status</Filter.ListItem>
                <Filter.ListItem value="dateRange">Date range</Filter.ListItem>
              </Filter.List>
              <Filter.Body value="productType">
                <Formik initialValues={{}} onSubmit={() => {}}>
                  {({ isSubmitting, values, setFieldValue }) => (
                    <Form
                      className="all:unset t-flex t-flex-col t-gap-3 t-py-0"
                      onChange={(e) =>
                        onHandleChange({ e, type: "productType" })
                      }
                    >
                      <Checkbox
                        name="filings"
                        label="Filings"
                        checked={selectedFilter?.find(
                          ({ value, name }) => name === "filings" && value
                        )}
                      />
                      <Checkbox
                        name="bookkeeping"
                        label="Bookkeeping"
                        checked={selectedFilter?.find(
                          ({ value, name }) => name === "bookkeeping" && value
                        )}
                      />
                      <Checkbox
                        name="subscriptions"
                        label="Subscriptions"
                        checked={selectedFilter?.find(
                          ({ value, name }) => name === "subscriptions" && value
                        )}
                      />
                    </Form>
                  )}
                </Formik>
              </Filter.Body>
              <Filter.Body value="selectedSeason">
                <Formik initialValues={{}} onSubmit={() => {}}>
                  {({ isSubmitting, values }) => (
                    <Form
                      className="all:unset t-flex t-flex-col t-gap-3 t-py-0"
                      onChange={(e) =>
                        onHandleChange({ e, type: "selectedSeason" })
                      }
                    >
                      <Checkbox
                        name="2023"
                        label="2023"
                        checked={selectedFilter?.find(
                          ({ value, name }) => name === "2023" && value
                        )}
                      />
                      <Checkbox
                        name="2022"
                        label="2022"
                        checked={selectedFilter?.find(
                          ({ value, name }) => name === "2022" && value
                        )}
                      />
                      <Checkbox
                        name="2021"
                        label="2021"
                        checked={selectedFilter?.find(
                          ({ value, name }) => name === "2021" && value
                        )}
                      />
                      <Checkbox
                        name="2020"
                        label="2020"
                        checked={selectedFilter?.find(
                          ({ value, name }) => name === "2020" && value
                        )}
                      />
                    </Form>
                  )}
                </Formik>
              </Filter.Body>
              <Filter.Body value="paymentStatus">
                <Formik initialValues={{}} onSubmit={() => {}}>
                  {({ isSubmitting, values }) => (
                    <Form
                      className="all:unset t-flex t-flex-col t-gap-3 t-py-0"
                      onChange={(e) =>
                        onHandleChange({ e, type: "paymentStatus" })
                      }
                    >
                      <Checkbox
                        name="unpaid"
                        label="Unpaid invoices"
                        checked={
                          selectedFilter?.find(
                            ({ value, name }) => name === "unpaid" && value
                          )?.value
                        }
                      />
                      <Checkbox
                        name="paid"
                        label="Paid invoices"
                        checked={
                          selectedFilter?.find(
                            ({ value, name }) => name === "paid" && value
                          )?.value
                        }
                      />
                      <Checkbox
                        name="fullrefund"
                        label="Full refund invoices"
                        checked={
                          selectedFilter?.find(
                            ({ value, name }) => name === "fullrefund" && value
                          )?.value
                        }
                      />
                      <Checkbox
                        name="partialrefund"
                        label="Partial refund invoices"
                        checked={
                          selectedFilter?.find(
                            ({ value, name }) =>
                              name === "partialrefund" && value
                          )?.value
                        }
                      />
                      <Checkbox
                        name="latefee"
                        label="Invoices with late fees"
                        checked={
                          selectedFilter?.find(
                            ({ value, name }) => name === "latefee" && value
                          )?.value
                        }
                      />
                      <Checkbox
                        name="autopay"
                        label="Invoices with autopay"
                        checked={
                          selectedFilter?.find(
                            ({ value, name }) => name === "autopay" && value
                          )?.value
                        }
                      />
                      <Checkbox
                        name="onhold"
                        label="On hold invoices"
                        checked={
                          selectedFilter?.find(
                            ({ value, name }) => name === "onhold" && value
                          )?.value
                        }
                      />
                    </Form>
                  )}
                </Formik>
              </Filter.Body>
              <Filter.Body value="dateRange" block>
                <DateFilter values={values} updateFilter={updateFilter} />
              </Filter.Body>
            </Filter.Portal>
          </Filter.Root>
        </div>
        {isAdmin && (
          <div className="t-flex t-gap-3">
            <ExportBilling groupId={groupId} searchTerm={searchTerm} />
            <Button customType="primary" onClick={open} size="small">
              Create
            </Button>
          </div>
        )}
      </div>
      {isOpen && <CreateOrEditInvoice isOpen={isOpen} close={close} />}
    </div>
  );
}
