import { object, string } from "yup";

export const nameValidation = (isNameUnavailable: boolean) =>
  object().shape({
    first_name: string().when({
      is: () => isNameUnavailable,
      then: string().required("First name is required"),
      otherwise: string().notRequired(),
    }),
    last_name: string().when({
      is: () => isNameUnavailable,
      then: string().required("Last name is required"),
      otherwise: string().notRequired(),
    }),
  });
