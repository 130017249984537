import { CloudUploadSmall } from "components/icons/CloudUploadSmall";
import { useToast } from "hooks/useToast";
import React, { ReactNode } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { Label } from "../TextInput/TextInput";
import classNames from "classnames";
import { Field, FieldProps, getIn, useFormikContext } from "formik";
import { DeleteIcon } from "components/icons/delete";
import { FileIcon } from "utils/fileTypeIcon";
import * as FILE_TYPE from "constants/fileTypes";
import { Divider } from "components/design/Divider";
import { Button } from "../Button/Button";
import { SolidCross } from "components/icons/SolidCross";
import { LoadingIcon } from "components/icons/LoadingIcon";

export type DropFile = {
  name: string;
  file_type: keyof typeof FILE_TYPE;
  is_previewable: boolean;
  uuid: string;
};

type WithFormPros = {
  withForm?: true;
  name: string;
  hideError?: boolean;
};

type WithoutFormPros = {
  withForm?: false;
  name?: never;
  hideError?: never;
};

type UploadType = {
  multiple?: boolean;
  file?: DropFile | null;
  files?: DropFile[];
  fileDeletingUuid?: string;
};

type PropsWithOrWithoutForm = WithFormPros | WithoutFormPros;

export type BaseFileInputProps = {
  onDrop?: (files: File[]) => void;
  onDelete?: (e: React.MouseEvent<HTMLButtonElement>, i: number) => void;
  label?: ReactNode;
  discription?: ReactNode;
  block?: boolean;
  disabled?: boolean;
  required?: boolean;
  accept?: { [key: string]: string[] };
  type?: "error" | "success" | "default";
  size?: "regular" | "large" | "small";
  variant?: "default" | "icon";
  isDeleting?: boolean;
  isUploading?: boolean;
  isDragAccept?: boolean;
  onDropAreaClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onFileClick?: ({
    e,
    uuid,
  }: {
    e: React.MouseEvent<HTMLButtonElement>;
    uuid: string;
  }) => void;
  shouldUpdateFieldOnDelete?: boolean;
} & PropsWithOrWithoutForm &
  UploadType;

const FILE_INPUT_TYPE_CLASSES = {
  /* @tw */
  BASE: "t-px-3 t-py-2 t-rounded t-border t-text-body hover:t-border-purple-30",
  /* @tw */
  DISABLED:
    "t-cursor-not-allowed t-pointer-events-none !t-text-neutral-30 !t-bg-surface-lighter-grey",
  /* @tw */
  HEIGHT:
    "data-[size=regular]:t-h-10 data-[size=small]:t-h-8 data-[size=large]:t-h-12",
  /* @tw */
  SUCCESS: "t-border-purple-40",
  /* @tw */
  DEFAULT: "t-border-neutral-10",
  /* @tw */
  ERROR: "t-border-red",
  /* @tw */
  DROP_REJECT: "!t-border-red-10",
  /* @tw */
  DROP_ACCEPT: "!t-border-purple-30 !t-bg-surface-purple",
};

const sizeTypeMap = {
  small: 20,
  regular: 24,
  large: 28,
};

export const DeleteFile = ({
  isDeleting,
  onDelete,
  size,
  index,
}: Pick<BaseFileInputProps, "onDelete" | "isDeleting" | "size"> & {
  index: number;
}) => {
  return (
    <Button
      type="button"
      customType="ghost_icon"
      isLoading={isDeleting}
      disabled={isDeleting}
      size={size === "small" ? "extra-small" : "small"}
      onClick={(e) => {
        e.stopPropagation();
        onDelete?.(e, index);
      }}
    >
      <span className="t-text-text-30">
        <DeleteIcon size={size === "small" ? "16" : "20"} />
      </span>
    </Button>
  );
};

const Upload = ({
  isUploading,
  disabled,
  size,
  onClick,
}: Pick<BaseFileInputProps, "isUploading" | "disabled" | "size"> & {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}) => {
  return (
    <Button
      disabled={disabled || isUploading}
      onClick={onClick}
      isLoading={isUploading}
      type="button"
      customType="ghost_icon"
      size={size === "small" ? "extra-small" : "small"}
    >
      <span
        className={classNames({
          "t-text-text-30": !disabled,
          "t-text-neutral-30": disabled,
        })}
      >
        <CloudUploadSmall
          color="currentColor"
          size={size === "small" ? "16" : "20"}
        />
      </span>
    </Button>
  );
};

const FileInputAndDropArea = ({
  type = "default",
  size = "regular",
  isDragAccept: isDragAcceptFromProps,
  variant = "default",
  ...props
}: BaseFileInputProps) => {
  const { alertToast } = useToast();
  const { name, disabled } = props;

  const onDropRejected = (fileRejections: FileRejection[]) => {
    fileRejections[0].errors.forEach((e) => {
      if (e.code === "file-too-large") {
        alertToast({ message: "File exceeded the limit of 100MB" });
      }

      if (e.code === "file-invalid-type") {
        const supportedFileTypes = Object.values(props.accept ?? {}).flatMap(
          (v) => v
        );

        alertToast({
          message: `Unsupported file uploaded. We only support ${supportedFileTypes.join(
            ","
          )}`,
        });
      }
    });
  };

  const { getRootProps, getInputProps, isDragAccept, isDragReject, open } =
    useDropzone({
      multiple: props.multiple,
      noClick: true,
      noKeyboard: true,
      onDropRejected,
      maxSize: 100000000,
      onDrop: props.onDrop,
      accept: props.accept,
    });

  const onDropAreaClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (props.onDropAreaClick) {
      props.onDropAreaClick?.(e);
      return;
    }
    open();
  };

  const FileAndIcon = ({
    name,
    file_type,
  }: Pick<DropFile, "file_type" | "name">) => {
    return (
      <div className="t-flex t-gap-2 t-items-center">
        <FileIcon
          fileType={file_type}
          width={sizeTypeMap[size]}
          height={sizeTypeMap[size]}
        />
        <div className="t-line-clamp-1">{name}</div>
      </div>
    );
  };

  // Icon variant
  if (variant === "icon") {
    if (!props.multiple && props.file) {
      return (
        <div className="t-relative">
          <button
            title={props.file.name}
            className="all:unset"
            onClick={(e) => {
              props.onFileClick?.({ e, uuid: props.file?.uuid! });
            }}
          >
            <FileIcon
              fileType={props.file.file_type}
              width={sizeTypeMap[size]}
              height={sizeTypeMap[size]}
            />
          </button>
          {props.onDelete && (
            <button
              className="all:unset"
              disabled={props.isDeleting}
              onClick={(e) => {
                e.stopPropagation();
                props.onDelete?.(e, 1);
              }}
            >
              <button className="all:unset t-absolute -t-top-3 t-left-6">
                {props.isDeleting ? (
                  <div className="t-animate-spin">
                    <LoadingIcon />
                  </div>
                ) : (
                  <SolidCross />
                )}
              </button>
            </button>
          )}
        </div>
      );
    }

    return (
      <button
        disabled={disabled}
        type="button"
        {...getRootProps()}
        className="all:unset"
      >
        <Upload
          onClick={onDropAreaClick}
          isUploading={props.isUploading}
          disabled={props.disabled}
          size={size}
        />
        <input name={name} data-testid="dropzone" {...getInputProps()} />
      </button>
    );
  }

  // Single file uploaded
  if (!props.multiple && props.file) {
    return (
      <button
        disabled={disabled}
        type="button"
        data-size={size}
        className={classNames(
          "all:unset t-border-solid t-flex t-justify-between t-items-center t-bg-surface hover:t-bg-surface-lighter-grey t-gap-2",
          FILE_INPUT_TYPE_CLASSES.BASE,
          FILE_INPUT_TYPE_CLASSES.HEIGHT,
          {
            [FILE_INPUT_TYPE_CLASSES.DISABLED]: disabled,
            [FILE_INPUT_TYPE_CLASSES.ERROR]: type === "error",
            [FILE_INPUT_TYPE_CLASSES.DEFAULT]: type === "default",
            [FILE_INPUT_TYPE_CLASSES.SUCCESS]: type === "success",
          }
        )}
        onClick={(e) => {
          props.onFileClick?.({ e, uuid: props.file?.uuid! });
        }}
      >
        <input
          name={name}
          data-testid="dropzone"
          disabled={true}
          {...getInputProps()}
        />
        <FileAndIcon file_type={props.file.file_type} name={props.file.name} />
        {props.onDelete && (
          <DeleteFile
            size={size}
            isDeleting={props.isDeleting}
            onDelete={props.onDelete}
            index={1}
          />
        )}
      </button>
    );
  }

  // Multiple files uploaded
  if (props.multiple && props?.files?.length! > 0) {
    return (
      <div
        className={classNames(
          "all:unset t-border-solid t-flex t-flex-col t-justify-between t-bg-surface t-gap-2",
          FILE_INPUT_TYPE_CLASSES.BASE,
          {
            [FILE_INPUT_TYPE_CLASSES.DROP_REJECT]: isDragReject,
            [FILE_INPUT_TYPE_CLASSES.DROP_ACCEPT]:
              isDragAccept || isDragAcceptFromProps,
            [FILE_INPUT_TYPE_CLASSES.DISABLED]: disabled,
            [FILE_INPUT_TYPE_CLASSES.ERROR]: type === "error",
            [FILE_INPUT_TYPE_CLASSES.DEFAULT]: type === "default",
            [FILE_INPUT_TYPE_CLASSES.SUCCESS]: type === "success",
          }
        )}
        {...getRootProps()}
      >
        <input name={name} data-testid="dropzone" {...getInputProps()} />
        <button
          disabled={disabled}
          type="button"
          id={props.name || "file-input"}
          className={classNames(
            "all:unset t-flex t-justify-between t-items-center hover:t-bg-surface-lighter-grey t-p-1 t-rounded"
          )}
          onClick={onDropAreaClick}
        >
          <span className="t-text-text-30">Click or drop files here</span>
          <Upload
            onClick={onDropAreaClick}
            isUploading={props.isUploading}
            disabled={props.disabled}
            size={size}
          />
        </button>
        <Divider />
        <div className="t-flex t-flex-col t-gap-0.5 t-max-h-52 t-overflow-auto">
          {props?.files?.map(({ file_type, name, uuid }, i) => {
            return (
              <button
                type="button"
                key={uuid}
                className="all:unset t-flex t-justify-between t-items-center hover:t-bg-surface-lighter-grey t-p-1 t-rounded t-gap-2 t-h-10"
                onClick={(e) => {
                  e.stopPropagation();
                  props.onFileClick?.({ e, uuid });
                }}
              >
                <div
                  className={classNames({
                    "t-w-10/12": props.onDelete,
                    "t-w-full": !props.onDelete,
                  })}
                >
                  <FileAndIcon file_type={file_type} name={name} />
                </div>
                {props.onDelete && !disabled && (
                  <DeleteFile
                    isDeleting={
                      props.isDeleting && uuid === props.fileDeletingUuid
                    }
                    onDelete={props.onDelete}
                    index={i}
                    size={size}
                  />
                )}
              </button>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <button
      disabled={disabled}
      type="button"
      id={props.name || "file-input"}
      name={name}
      data-size={size}
      className={classNames(
        "all:unset t-flex t-justify-between t-items-center t-border-dashed t-bg-surface-lighter-grey hover:t-bg-surface-grey",
        FILE_INPUT_TYPE_CLASSES.BASE,
        FILE_INPUT_TYPE_CLASSES.HEIGHT,
        {
          [FILE_INPUT_TYPE_CLASSES.DROP_REJECT]: isDragReject,
          [FILE_INPUT_TYPE_CLASSES.DROP_ACCEPT]:
            isDragAccept || isDragAcceptFromProps,
          [FILE_INPUT_TYPE_CLASSES.DISABLED]: disabled,
          [FILE_INPUT_TYPE_CLASSES.ERROR]: type === "error",
          [FILE_INPUT_TYPE_CLASSES.DEFAULT]: type === "default",
          [FILE_INPUT_TYPE_CLASSES.SUCCESS]: type === "success",
        }
      )}
      {...getRootProps()}
      onClick={onDropAreaClick}
    >
      <input
        name={name}
        data-testid="dropzone"
        style={{
          display: "block",
        }}
        {...getInputProps()}
      />
      <span className="t-text-text-30">Click or drop files here</span>
      <Upload
        onClick={onDropAreaClick}
        isUploading={props.isUploading}
        disabled={props.disabled}
        size={size}
      />
    </button>
  );
};

const BareBaseFileInput = ({ ...props }: BaseFileInputProps) => {
  const { label, discription, block } = props;

  return (
    <div className={classNames("t-flex t-flex-col", { "t-w-full": block })}>
      {Boolean(label) && (
        <Label htmlFor={props.name || "file-input"} required={props.required}>
          {props.label}
        </Label>
      )}
      <FileInputAndDropArea {...props} />
      {Boolean(discription) && (
        <span className="t-text-body-sm t-text-text-30 t-mt-1.5">
          {discription}
        </span>
      )}
    </div>
  );
};

const BaseFileInputWithForm = ({
  shouldUpdateFieldOnDelete = true,
  ...props
}: BaseFileInputProps) => {
  const { setFieldValue } = useFormikContext();

  const onDrop = (acceptedFiles: File[]) => {
    if (props.name) {
      if (props.multiple) {
        setFieldValue(props.name, [...props?.files!, ...acceptedFiles]);
      } else {
        setFieldValue(props.name, acceptedFiles[0]);
      }
    }
    props.onDrop?.(acceptedFiles);
  };

  const handleDelete = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number
  ) => {
    if (props.name && shouldUpdateFieldOnDelete) {
      setFieldValue(props.name, null);
    }
    props.onDelete?.(e, index);
  };

  return (
    <Field {...props}>
      {({ field, form: { errors, touched } }: FieldProps) => {
        const error = getIn(errors, field.name);

        return (
          <div className="t-flex t-flex-col">
            <BareBaseFileInput
              {...props}
              file={props.file || field.value}
              files={props.files || field.value}
              onDrop={onDrop}
              onDelete={handleDelete}
              type={error ? "error" : "default"}
            />
            {!props.hideError && error && (
              <div className="t-mt-1.5 t-text-caption t-text-red">{error}</div>
            )}
          </div>
        );
      }}
    </Field>
  );
};

export const BaseFileInput = ({ ...props }: BaseFileInputProps) => {
  if (props.withForm) {
    return <BaseFileInputWithForm {...props} />;
  }

  return <BareBaseFileInput {...props} />;
};
