import classNames from "classnames";
import { Button } from "components/DesignSystem/Button/Button";
import Lightning from "static/images/Lightning.svg";
import { FILINGS_STATE_CHANGE } from "constants/subscriptionPermissionFeatures";
import { useHasSubscriptionPermission } from "hooks/useHasSubscriptionPermission";
import { useUpgradePlan } from "hooks/useUpgradePlan";
import { ReactNode, useEffect, useRef } from "react";
import { Subscription } from "types/Models/subscription";
import { useRoleBasedView } from "hooks/useRoleBasedView";

const UpgradeButton = ({ subscription }: { subscription: Subscription }) => {
  const { upgrade } = useUpgradePlan();
  return (
    <Button
      customType="primary"
      type="button"
      onClick={() => {
        upgrade({ addon: subscription });
      }}
    >
      <img src={Lightning} alt="Lightning" />
      &nbsp; Upgrade to unlock
    </Button>
  );
};
const TaskButtonBar = ({
  parentRef,
  children,
  justifyContent,
  addMarginLeft = true,
}: {
  parentRef: any;
  children: ReactNode;
  justifyContent: "between" | "end" | "start";
  addMarginLeft?: boolean;
}) => {
  const childRef = useRef<HTMLDivElement>(null);
  const { isCustomer } = useRoleBasedView();
  useEffect(() => {
    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver?.observe?.(parentRef.current);

    return () => {
      if (resizeObserver && parentRef.current) {
        resizeObserver.unobserve(parentRef.current);
      }
    };
  }, []);

  const handleResize = (entries: any) => {
    const parentWidth = entries[0].contentRect.width;
    if (childRef.current) {
      childRef.current.style.width = `${parentWidth}px`;
    }
  };

  const permission = useHasSubscriptionPermission({
    feature: FILINGS_STATE_CHANGE,
    product_type: "TAX",
  });

  return (
    <div
      ref={childRef}
      className={classNames(
        "t-fixed t-bottom-0 t-z-10 t-flex t-border-solid t-border t-border-surface-transparent t-border-t-neutral-10 t-bg-surface t-py-3 t-pl-4 t-pr-6 t-shadow-top-shadow",
        {
          "t-justify-end": justifyContent === "end",
          "t-justify-between": justifyContent === "between",
          "t-justify-start": justifyContent === "start",
          "-t-ml-4": addMarginLeft,
        }
      )}
    >
      {permission && permission.blocked && isCustomer ? (
        <div className="t-w-full t-relative">
          <fieldset disabled={true} className="t-flex t-justify-end">
            {children}
          </fieldset>
          <div className="t-absolute t-top-0 t-left-1/2 t-transform -t-translate-x-1/2">
            {permission.subscription && (
              <UpgradeButton subscription={permission?.subscription} />
            )}
          </div>
        </div>
      ) : (
        children
      )}
    </div>
  );
};

export default TaskButtonBar;
