import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { getGroupUsers } from "../../apis/groups";
import cx from "classnames";
import classNames from "classnames";
import Toggle from "components/design/toggle";
import { Chat } from "components/chat/Chat";
import { ChannelFilters } from "stream-chat";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import { useChatContext } from "stream-chat-react";

export const Messages = ({ path }: { path: string }) => {
  const { groupId } = useParams<{ groupId: string }>();
  const { channelId } = useParams<{ channelId: string }>();
  const { messageId } = useParams<{ messageId: string }>();
  const [groupUserIds, setGroupUserIds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [checked, setChecked] = useState(true);
  const [isPrivateChannel, setPrivateChannel] = useState(false);
  const [showToggle, setShowToggle] = useState(false);
  const [currentTask, setCurrentTask] = useState({ other_channel_url: "" });
  const { client } = useChatContext();

  const setChatType = (toggleValue: any) => {
    setChecked(toggleValue);
    setPrivateChannel(toggleValue);
  };

  useEffect(() => {
    const getAndsetGroupIds = async () => {
      setLoading(true);
      const { data: groupUsers } = await getGroupUsers({ groupId });
      const ids = groupUsers.map((g: any) => g.member_id);
      setGroupUserIds(ids);
      setLoading(false);
    };

    getAndsetGroupIds();
  }, []);

  const filters: ChannelFilters<DefaultStreamChatGenerics> = useMemo(() => {
    const filters: ChannelFilters<DefaultStreamChatGenerics> = {
      type: "messaging",
      $and: [
        { members: { $in: [client.userID!] } },
        {
          group_uuid: { $eq: groupId },
        },
      ],
    };

    return filters;
  }, [client.userID, groupUserIds]);

  return (
    <>
      {loading && <strong>Loading...</strong>}
      {!loading && groupUserIds.length === 0 && (
        <strong>No member exists in the group!</strong>
      )}
      {!loading && groupUserIds.length > 0 && (
        <Chat filters={filters} channelId={channelId} messageId={messageId} />
      )}
    </>
  );
};
