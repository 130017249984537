import { ChatPane } from "components/ChatPane/ChatPane";
import { Header } from "components/DesignSystem/Header/Header";
import Tab from "components/DesignSystem/Tab/Tab";
import { RuleEngine } from "components/RuleEngine/RuleEngine";
import DashboardContainer from "components/dashboard/DashboardContainer";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useState } from "react";
import { useDrop } from "react-dnd";
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import uploadCloud from "static/images/uploadCloud.svg";
import { setTransactionsInChat } from "store/slices/transactionsInChat";
import { Transaction, TxnAccountType } from "types/Models/books";
import { TransactionTable } from "../../../components/Transaction/TransactionTable";
import { ActionRequiredPopOver } from "components/ActionRequiredPopover/ActionRequiredPopover";
import { useActionItems } from "hooks/useActionItems";
import { useAppDispatch } from "hooks/useAppDispatch";
import { resetTxnFilters } from "store/slices/transactionFilter";
import { parse, stringify } from "qs";
import { EmptyScreen } from "../EmptyScreen";
import { Button } from "components/DesignSystem/Button/Button";
import { Stripe } from "components/icons/Logos/Stripe";
import { GradientArrow } from "components/icons/GradientArrow";
import { Inkle } from "components/icons/Logos/Inkle";
import { useGetAllConnections } from "hooks/useGetAllConnections";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { useBankConnect } from "hooks/useBankConnect";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { NoteResponse } from "store/apis/notes";
import { useAppSelector } from "hooks/useAppSelector";
import { CommentsSlider } from "components/CommentsSlider/CommentsSlider";
import { useUpdateQuery } from "hooks/useQuery";
import { setOpenComment } from "store/slices/openComment";
import { useAnalytics } from "hooks/useAnalytics";
import { COMMENT_TAB_OPENED } from "constants/analyticsEvents";

const TxnTable = ({ txnAccountType }: { txnAccountType?: TxnAccountType }) => {
  const dispatch = useAppDispatch();
  const [channelId, setChannelId] = useState<string | null>();
  const entityId = useCurrentEntityId();
  const openComment = useAppSelector((state) => state.openComment.openComment);

  const { update } = useUpdateQuery();

  const allFilters = useAppSelector(
    (state) => state.transactionCardPreview.filtersApplied
  );

  const setActiveComment = (comment: NoteResponse) => {
    if (comment.transaction?.uuid) {
      update({
        query: "selected_transaction_id",
        value: comment.transaction?.uuid,
      });
    }

    if (comment.transaction) {
      dispatch(setOpenComment(comment.transaction.uuid));
    }
  };

  const [{ isOver }, drop] = useDrop(() => ({
    accept: "transaction",
    drop: ({ transaction }: { transaction: string }) => onDrop(transaction),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  const onDrop = async (transaction: string) => {
    const txn: Transaction = JSON.parse(transaction);
    const transactionsToSendtoChat = {
      id: txn.uuid,
      amount: txn.amount,
      source: txn.source,
      date: txn.date,
      vendor: {
        logo: txn.logo,
        name: txn.merchant,
      },
      isCreditCard: txn.is_credit_card,
    };
    dispatch(
      setTransactionsInChat({
        transactionsInChat: [transactionsToSendtoChat],
        entityId: entityId,
      })
    );
  };

  const { trackEvent } = useAnalytics();

  return (
    <div className="t-flex t-overflow-y-auto t-h-full t-gap-5">
      <div className="t-h-full t-grow t-pb-5">
        <TransactionTable
          setChannelId={setChannelId}
          txnAccountType={txnAccountType}
        />
      </div>
      <Tab.Root defaultValue="CHAT" asChild>
        <DashboardContainer className="t-h-full !t-w-[360px] t-shrink-0 t-grow-0 t-sticky t-top-0">
          <DashboardContainer.Header>
            <Tab.List className="t-px-4 t-border-0 t-border-l t-border-solid t-border-neutral-0">
              <Tab.Trigger value="CHAT">Chat</Tab.Trigger>
              <Tab.Trigger
                onClick={() =>
                  trackEvent(COMMENT_TAB_OPENED, {
                    type: "TRANSACTION_COMMENT",
                  })
                }
                value="COMMENTS"
              >
                Comments
              </Tab.Trigger>
            </Tab.List>
          </DashboardContainer.Header>
          <DashboardContainer.Content>
            <Tab.Content value="CHAT" asChild>
              <div
                ref={drop}
                className="t-h-full  t-border-0 t-border-l t-border-t t-border-solid t-border-neutral-0"
              >
                {isOver && (
                  <div className="t-absolute t-z-10 t-flex t-h-[-webkit-fill-available] t-w-[-webkit-fill-available] t-flex-col t-items-center t-justify-center t-gap-3 t-border t-border-dashed t-border-neutral-70 t-bg-[#f3f3f580] t-h-full t-w-[-moz-available]">
                    <img
                      src={uploadCloud}
                      alt="upload"
                      className="t-animate-drag-and-drop"
                    />
                    <div>Drag and drop here</div>
                  </div>
                )}
                <ChatPane setChannelId={setChannelId} channelId={channelId} />
              </div>
            </Tab.Content>
            <Tab.Content value="COMMENTS" asChild>
              <div className="t-h-full t-flex t-flex-col t-border-0 t-border-l t-border-t t-border-solid t-border-neutral-0 t-gap-3 t-overflow-y-auto t-p-4 t-pt-0 t-relative">
                <CommentsSlider
                  activeComment={openComment}
                  setActiveComment={setActiveComment}
                  commentTypes={["TRANSACTION_COMMENT"]}
                  transactionFilters={allFilters}
                />
              </div>
            </Tab.Content>
          </DashboardContainer.Content>
        </DashboardContainer>
      </Tab.Root>
    </div>
  );
};

const StripeTable = () => {
  const entityId = useCurrentEntityId();
  const { uuid: groupId } = useCurrentGroupContext();
  const { isCpa, isForeignCA } = useRoleBasedView();

  const { onConnect, isLoading: isConnectBankLoading } = useBankConnect();

  const { stripeConnection, isLoading, isSuccess } = useGetAllConnections({
    groupId,
    entityId,
  });

  const isEmpty = stripeConnection?.bank_accounts?.length === 0;

  return (
    <Async.Root isLoading={isLoading} isSuccess={isSuccess} isEmpty={isEmpty}>
      <Async.Empty>
        <EmptyScreen
          className="t-h-[70dvh]"
          text="Inkle Books now natively integrates with Stripe"
          cta={
            <Button
              size="small"
              customType="primary"
              isLoading={isConnectBankLoading}
              disabled={isConnectBankLoading}
              onClick={() =>
                onConnect({
                  connectionId: stripeConnection?.uuid!,
                  invokedFrom:
                    isCpa || isForeignCA
                      ? `/books/transactions/stripe?company=${groupId}&entity=${entityId}`
                      : `/books/transactions/stripe?entity=${entityId}`,
                })
              }
            >
              Connect
            </Button>
          }
        >
          <div className="t-flex t-gap-5 t-items-center">
            <Stripe />
            <GradientArrow />
            <Inkle />
          </div>
        </EmptyScreen>
      </Async.Empty>
      <Async.Success>
        <TxnTable txnAccountType="STRIPE" />
      </Async.Success>
    </Async.Root>
  );
};

export const Transactions = () => {
  const dispatch = useAppDispatch();
  const { path, url } = useRouteMatch();
  const { pathname, search } = useLocation();
  let query = parse(search, { ignoreQueryPrefix: true });

  const searchQuery = stringify(
    {
      company: query.company || null,
      entity: query.entity || null,
    },
    { skipNulls: true, addQueryPrefix: true }
  );

  const {
    action_items_count,
    isBankReconnectRequired,
    isReconciliationRequired,
  } = useActionItems();

  const isRuleEnginePage = pathname.includes("/rules");
  const isStripePage = pathname.includes("/stripe");

  const resetFilters = () => {
    dispatch(resetTxnFilters());
  };

  return (
    <div className="t-flex t-h-full">
      <DashboardContainer className="t-h-full t-grow-0 t-shrink">
        <DashboardContainer.Header className="t-z-header">
          <Header
            title="Transactions"
            bottom={
              <Tab.Root defaultValue={pathname} value="MATCH">
                <Tab.List>
                  <Tab.NavTrigger
                    exact
                    to={`${url}${searchQuery}`}
                    value={!isRuleEnginePage && !isStripePage ? "MATCH" : ""}
                    className="t-flex t-items-center"
                    onClick={resetFilters}
                  >
                    Banks & Cards
                    {action_items_count > 0 &&
                      (isBankReconnectRequired || isReconciliationRequired) && (
                        <ActionRequiredPopOver type="ALL" />
                      )}
                  </Tab.NavTrigger>

                  <Tab.NavTrigger
                    exact
                    to={`${url}/stripe${searchQuery}`}
                    value={isStripePage ? "MATCH" : ""}
                    onClick={resetFilters}
                  >
                    Stripe
                  </Tab.NavTrigger>

                  <Tab.NavTrigger
                    exact
                    to={`${url}/rules${searchQuery}`}
                    value={isRuleEnginePage ? "MATCH" : ""}
                  >
                    Rules
                  </Tab.NavTrigger>
                </Tab.List>
              </Tab.Root>
            }
          />
        </DashboardContainer.Header>
        <DashboardContainer.Content>
          <div className="t-h-full t-w-full">
            <Switch>
              <Route exact path={path}>
                <TxnTable txnAccountType="EXCLUDE_STRIPE" />
              </Route>
              <Route exact path={`${path}/stripe`}>
                <StripeTable />
              </Route>
              <Route exact path={`${path}/rules`}>
                <div className="t-my-5 t-pl-6 t-h-full t-w-full">
                  <RuleEngine />
                </div>
              </Route>
            </Switch>
          </div>
        </DashboardContainer.Content>
      </DashboardContainer>
    </div>
  );
};
