import { Subscription } from "types/Models/subscription";
import { RASubscribedState } from "./RASubscribedState";
import { SubscriptionTypeCard } from "./SubscriptionTypeCard";

export const RASubscriptionTypeCard = ({
  subscription,
}: {
  subscription: Subscription;
}) => {
  return (
    <>
      <SubscriptionTypeCard subscription={subscription} isPrimarySubscription />
      {subscription.group_subscription?.map((subscribedState) => (
        <RASubscribedState
          key={subscribedState.group_subscription_id}
          subscribedState={subscribedState}
          statePriceMap={subscription.ra_state_amount_maps}
          otherAmount={subscription.amount}
        />
      ))}
    </>
  );
};
