import classNames from "classnames";
import ConditionalToolTip from "components/design/conditionalToolTip";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { HighlightSearchTerm } from "components/DesignSystem/HighlightText";
import Table from "components/DesignSystem/Table/V2/Table";
import { LoadingIcon } from "components/icons/LoadingIcon";
import { Docs } from "components/icons/Navbar/Docs";
import { FOLDER_TYPE, LOCKED_FOLDER } from "constants/documents";
import { useQuery } from "hooks/useQuery";
import React, { useContext } from "react";
import { useDrag, useDrop } from "react-dnd";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import { FileIcon } from "utils/fileTypeIcon";
import { formatDate } from "utils/formatDate";
import ToolTip from "../../design/toolTip";
import { DocumentContext } from "../documentContext";
import FileFolderActionDropDown from "../fileFolderActionDropdown";

const DraggableRow = ({
  name,
  type,
  onDrop,
  uuid,
  isChecked,
  isFileAlreadySelected,
  onClick,
  ...rest
}) => {
  const handleDrop = ({ fileId }) => {
    onDrop({
      folderId: uuid,
      fileId,
    });
  };

  const [{ isOver }, drop] = useDrop(() => ({
    accept: "file",
    drop: handleDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  const [_, drag] = useDrag(
    () => ({
      type: "file",
      item: { fileId: uuid },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [uuid]
  );

  const ref = type === "FILE" ? drag : drop;

  return (
    <ToolTip text={<div className="t-max-w-32 t-text-center">{name}</div>}>
      <tr
        onClick={onClick}
        ref={ref}
        className={classNames(
          "t-cursor-pointer t-border-neutral-0 t-border-b t-border-0 t-text-body t-px-3 t-border-solid",
          {
            "t-bg-surface-purple": isChecked,
            "hover:t-bg-surface-lighter-grey": !isChecked,
            "t-border-purple !t-bg-purple-0": isOver,
            "!t-cursor-no-drop": isFileAlreadySelected,
          }
        )}
        {...rest}
      />
    </ToolTip>
  );
};

export const ListView = ({
  userGroupId,
  entityFileAndFolder,
  groupId,
  setLoad,
  setNewFolderName,
  doubleClick,
  setMovedFolderName,
  groupDocID,
  ParentId,
  moveFileOnDrop,
  handleCheckBox,
}) => {
  const query = useQuery();
  const searchTerm = query.get("search");
  const {
    fetchMoreData,
    handleFolderClick,
    selectedRecentDocs,
    isSearchLoading,
  } = useContext(DocumentContext);
  const folderAndFileData = React.useMemo(
    () => entityFileAndFolder,
    [entityFileAndFolder]
  );

  const createColumn = createColumnHelper();

  const location = searchTerm
    ? [
        createColumn.accessor("location", {
          size: 15,
          header: "Location",
          cell: (info) => {
            const { parent_name, parent_uuid } = info.row.original;

            return (
              <div
                className="t-flex t-items-center t-gap-1"
                onClick={(e) => {
                  e.stopPropagation();
                  handleFolderClick(parent_uuid);
                }}
              >
                <Docs size="16" />
                <span className="t-max-w-20 t-truncate t-text-caption t-text-purple hover:!t-text-purple hover:!t-underline">
                  {parent_name}
                </span>
              </div>
            );
          },
        }),
      ]
    : [];

  const columns = React.useMemo(
    () => [
      createColumn.display({
        id: "select",
        size: 1,
        header: "",
        cell: ({ row }) => {
          const { isChecked, uuid } = row.original || {};
          const isFileAlreadySelected = selectedRecentDocs.some(
            ({ uuid: selectedFileId }) => selectedFileId === uuid
          );

          return (
            <ConditionalToolTip
              condition={
                isFileAlreadySelected && "This file has selected already"
              }
            >
              <span>
                <Checkbox
                  checked={isChecked}
                  onChange={(e) => handleCheckBox(uuid, e)}
                  onClick={(e) => e.stopPropagation()}
                  disabled={isFileAlreadySelected}
                />
              </span>
            </ConditionalToolTip>
          );
        },
      }),

      createColumn.accessor("name", {
        id: "name",
        size: 40,
        header: "Name",
        cell: (info) => {
          const { name, file_type, is_locked, type } = info.row.original;

          const fileType =
            is_locked && type === FOLDER_TYPE ? LOCKED_FOLDER : file_type;

          return (
            <span className="listNameIconContainer nameContainer">
              <span className="fileFolderIcon">
                <FileIcon fileType={fileType} width="40px" height="40px" />
              </span>
              <span className="eleContent nameContainer">
                <HighlightSearchTerm text={name} searchTerm={searchTerm} />
              </span>
            </span>
          );
        },
      }),
      createColumn.accessor("modified", {
        size: 15,
        header: "Created On",
        cell: (info) => {
          const { created_at } = info.row.original;

          return (
            <span
              className="eleContent listNameIconContainer"
              content={created_at ? formatDate(created_at) : "-"}
            />
          );
        },
      }),
      createColumn.accessor("owner", {
        size: 20,
        header: "Owner",
        cell: (info) => {
          const { owner } = info.row.original;

          return (
            <span className="eleContent listNameIconContainer">
              <HighlightSearchTerm
                text={owner || "-"}
                searchTerm={searchTerm}
              />
            </span>
          );
        },
      }),

      createColumn.accessor("size", {
        size: 10,
        header: "Size",
        cell: (info) => {
          const { file_size } = info.row.original;

          return (
            <span
              className="eleContent listNameIconContainer"
              content={file_size ? file_size + "MB" : "-"}
            />
          );
        },
      }),

      ...location,
      createColumn.accessor("action", {
        size: 5,
        header: "",
        cell: (info) => {
          const {
            file_type,
            type,
            is_locked,
            is_previewable,
            uuid,
            name,
            parent_uuid,
          } = info.row.original;
          const fileType =
            is_locked && type === FOLDER_TYPE ? LOCKED_FOLDER : file_type;

          return (
            <FileFolderActionDropDown
              fileType={fileType}
              userGroupId={userGroupId}
              itemType={type}
              isPreviewable={is_previewable}
              folderId={uuid}
              groupId={groupId}
              setLoad={setLoad}
              folderName={name}
              setNewFolderName={setNewFolderName}
              folderDrowdowns={false}
              setMovedFolderName={setMovedFolderName}
              groupDocID={groupDocID}
              ParentId={ParentId}
              parentUuid={parent_uuid}
              isLocked={is_locked}
              listView
            />
          );
        },
      }),
    ],
    [entityFileAndFolder, selectedRecentDocs]
  );

  const folderFileClick = (
    { file_type, uuid, name, type, parent_uuid, is_locked, is_previewable },
    e
  ) => {
    const isFileAlreadySelected = selectedRecentDocs.some(
      ({ uuid: selectedFileId }) => selectedFileId === uuid
    );
    if (isFileAlreadySelected) {
      e.stopPropagation();
    } else {
      doubleClick({
        e,
        fileType: file_type,
        uuid,
        name,
        type,
        parent_uuid,
        is_locked,
        isRecentDocument: false,
      });
    }
  };

  const table = useReactTable({
    data: folderAndFileData || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      size: 10,
      minSize: 1,
      maxSize: 100,
    },
  });

  const Wrapper = searchTerm ? "div" : React.Fragment;

  return (
    <Wrapper
      className="t-overflow-auto t-sticky t-top-0 t-h-[calc(100vh-260px)]"
      id="scrollableList"
    >
      <InfiniteScroll
        dataLength={entityFileAndFolder.length}
        next={fetchMoreData}
        hasMore={true}
        scrollableTarget="scrollableList"
        loader={
          isSearchLoading ? (
            <div className="t-flex t-justify-center t-items-center t-w-full t-gap-2 t-p-2">
              <span className="t-flex t-origin-center t-animate-spin">
                <LoadingIcon />
              </span>
              Loading...
            </div>
          ) : null
        }
      >
        <Table.Container onContextMenu={(e) => e.preventDefault()}>
          <Table.Content>
            <Table.Head>
              {table.getHeaderGroups().map((headerGroup) => (
                <Table.Row key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Table.HeadCell
                      key={header.id}
                      style={{ width: `${header.getSize()}%` }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </Table.HeadCell>
                  ))}
                </Table.Row>
              ))}
            </Table.Head>
            <Table.Body>
              {table.getRowModel().rows.map((row) => {
                const { type, uuid, name, isChecked } = row.original;
                const isFileAlreadySelected = selectedRecentDocs.some(
                  ({ uuid: selectedFileId }) => selectedFileId === uuid
                );

                return (
                  <DraggableRow
                    name={name}
                    onDrop={moveFileOnDrop}
                    isChecked={isChecked}
                    isFileAlreadySelected={isFileAlreadySelected}
                    type={type}
                    uuid={uuid}
                    key={row.id}
                    onClick={(e) => {
                      folderFileClick(row.original, e);
                    }}
                  >
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Table.Cell
                          key={cell.id}
                          style={{
                            width: `${cell.column.getSize()}%`,
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Table.Cell>
                      );
                    })}
                  </DraggableRow>
                );
              })}
            </Table.Body>
          </Table.Content>
        </Table.Container>
      </InfiniteScroll>
    </Wrapper>
  );
};
