import classNames from "classnames";
import { AppNavigation } from "components/AppNavigation/AppNavigation";
import HoverCard from "components/DesignSystem/HoverCard/HoverCard";
import { PermissionBasedUI } from "components/PermissionBasedUI/PermissionBasedUI";
import { ProfileDropdown } from "components/ProfileDropdown/ProfileDropdown";
import { ConditionalLink } from "components/conditionalLink";
import DashboardContainer from "components/dashboard/DashboardContainer";
import ToolTip from "components/design/toolTip";
import { ArrowRight } from "components/icons/ArrowRight";
import { DoubleArrow } from "components/icons/DoubleArrow";
import { InkleAdminBooksLogo } from "components/icons/Logos/InkleAdminBooksLogo";
import { InkleAdminBooksSymbol } from "components/icons/Logos/InkleAdminBooksSymbol";
import { InkleAdminBooksSymbolSmall } from "components/icons/Logos/InkleAdminBooksSymbolSmall";
import { InkleAdminLogo } from "components/icons/Logos/InkleAdminLogo";
import { InklePracticeLogo } from "components/icons/Logos/InklePracticeLogo";
import { InklePracticeProLogo } from "components/icons/Logos/InklePracticeProLogo";
import { InklePracticeProSymbol } from "components/icons/Logos/InklePracticeProSymbol";
import { InklePracticeSymbol } from "components/icons/Logos/InklePracticeSymbol";
import { InkleTaxSymbol } from "components/icons/Logos/InkleTaxSymbol";
import { Padlock } from "components/icons/Padlock";
import { NAVBAR_COLLAPSER_CLICKED } from "constants/analyticsEvents";
import { useCurrentAppContext } from "contexts/CurrentAppContext";
import { AnimatePresence, LayoutGroup, motion } from "framer-motion";
import { useAnalytics } from "hooks/useAnalytics";
import { useConstructInternalLink } from "hooks/useConstructInternalLink";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import {
  ComponentProps,
  ComponentType,
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useId,
  useRef,
  useState,
} from "react";
import { Timeout } from "react-number-format/types/types";
import { NavLink, useLocation, useRouteMatch } from "react-router-dom";

const LeftBarOpenContext = createContext<{
  open: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
}>({ open: true });

export const LeftBarLogo = ({
  logo: LogoFromProps,
  symbol: SymbolFromProps,
  url: urlFromprops,
}: {
  logo: ComponentType;
  symbol: ComponentType;
  url?: string;
}) => {
  const { open, setOpen } = useContext(LeftBarOpenContext);
  const { isAdmin, isCpa, isForeignCA } = useRoleBasedView();
  const { currentApp } = useCurrentAppContext();

  let Logo = LogoFromProps;
  let Symbol = SymbolFromProps;

  if (isForeignCA && !isAdmin && !isCpa) {
    Logo = InklePracticeLogo;
    Symbol = InklePracticeSymbol;
  }

  if (isCpa && !isAdmin && !isForeignCA) {
    Logo = InklePracticeProLogo;
    Symbol = InklePracticeProSymbol;
  }

  if (isAdmin) {
    Logo = InkleAdminLogo;
    Symbol = InkleTaxSymbol;
  }

  if (isAdmin && currentApp.name === "Books Admin") {
    Logo = InkleAdminBooksLogo;
    Symbol = InkleAdminBooksSymbol;
  }

  return (
    <div className="t-z-10">
      {open ? (
        <AppNavigation>
          <div className="t-p-2.5">
            <div className="t-flex t-justify-between t-items-center t-mr-2 t-group t-w-full t-cursor-pointer t-truncate !t-border-none t-px-3 t-py-2 hover:t-bg-surface-grey focus-visible:t-outline-none data-[highlighted]:t-bg-surface-lighter-grey t-gap-1.5 group-data-state-open:t-text-purple t-rounded">
              <Logo />
              <DoubleArrow />
            </div>
          </div>
        </AppNavigation>
      ) : (
        <AppNavigation>
          <span>
            <Symbol />
          </span>
        </AppNavigation>
      )}
    </div>
  );
};

export const LeftBarProfileDropdownItem = ({
  icon: Icon,
  children,
  suffix,
  to,
  useParentPath = true,
  type = "regular",
  onClick,
  ...props
}: {
  icon: ComponentType;
  children: ReactNode;
  suffix?: ReactNode;
  to?: string;
  useParentPath?: boolean;
  type?: "danger" | "regular";
  onClick?: () => void;
} & ComponentProps<typeof ConditionalLink>) => {
  const { path } = useRouteMatch();

  if (to) {
    return (
      <ConditionalLink
        {...props}
        to={useParentPath ? `${path}${to}` : to}
        className={classNames(
          "t-relative t-py-2 t-px-4 t-flex t-gap-2 t-items-center t-border-solid t-border-0 t-transition-all t-no-underline hover:t-bg-neutral-0 t-rounded",
          {
            "t-group/regular": type === "regular",
            "t-group/danger hover:t-bg-red-10": type === "danger",
          }
        )}
      >
        <span className="t-flex group-[&]/regular:t-text-text-30 group-[&]/danger:t-text-red">
          <Icon />
        </span>
        <span className="t-text-body  group-[&]/danger:t-text-red group-[&]/regular:t-text-text-60">
          {children}
        </span>
        {suffix}
      </ConditionalLink>
    );
  }

  return (
    <button
      onClick={onClick}
      className={classNames(
        "t-relative t-px-4 py-2 t-flex t-gap-2 t-items-center t-border-solid t-border-0 t-transition-all t-no-underline hover:t-bg-neutral-0 t-rounded all:unset",
        {
          "t-group/regular": type === "regular",
          "t-group/danger hover:t-bg-red-10": type === "danger",
        }
      )}
    >
      <span className="t-flex group-[&]/regular:t-text-text-100 group-[&]/danger:t-text-red t-text-text-30">
        <Icon />
      </span>
      <span className="t-text-body group-[&]/active-link:t-text-text-100 group-[&]/danger:t-text-red group-[&]/active-link:t-font-medium t-text-text-60">
        {children}
      </span>
      {suffix}
    </button>
  );
};

export const LeftBarProfileDropdown = ({
  name,
  org,
  email,
  imageUrl,
  children,
}: {
  name: string;
  org?: string | null;
  imageUrl: string;
  // userType: "PRO" | "STANDARD" | "CONSULTANT" | "ADMIN" | "FCPA" | "CPA";
  email: string;
  children: ReactNode;
}) => {
  const { open } = useContext(LeftBarOpenContext);
  const [pop, setPop] = useState(false);
  const closeRef = useRef<Timeout>();

  const onClose = () => {
    if (closeRef.current) {
      clearTimeout(closeRef.current);
    }

    closeRef.current = setTimeout(() => {
      setPop(false);
    }, 200);
  };

  const onOpen = () => {
    closeRef.current && clearTimeout(closeRef.current);
    setPop(true);
  };

  return (
    <HoverCard.Root open={pop} openDelay={300}>
      <HoverCard.Trigger
        asChild
        onClick={(e) => e.preventDefault()}
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
      >
        <button className="t-flex t-gap-3 all:unset t-p-4 t-w-full t-border-t t-border-0 t-border-neutral-10 t-border-solid t-bg-surface-lighter-grey">
          <img
            src={imageUrl}
            alt="profile"
            className="t-w-8 t-h-8 t-rounded-full"
          />
          {open && (
            <>
              <div className="t-flex t-flex-col t-justify-center">
                <span className="t-text-text-100 t-font-medium t-truncate t-max-w-36 t-block t-text-subtitle-sm">
                  {name}
                </span>
                {org && (
                  <span className="t-text-body-sm t-text-text-30 t-truncate t-max-w-36 t-block">
                    {org}
                  </span>
                )}
              </div>
              <span className="t-ml-auto">
                <ArrowRight />
              </span>
            </>
          )}
        </button>
      </HoverCard.Trigger>
      <HoverCard.Portal>
        <HoverCard.Content
          className="t-min-w-[256px]"
          align="end"
          onMouseEnter={onOpen}
          onMouseLeave={onClose}
          side="right"
          sideOffset={10}
          alignOffset={16}
        >
          <div className="t-w-full t-p-2 t-font-medium">
            <div className="t-flex t-gap-3 all:unset t-w-full t-py-2 t-px-3">
              <img
                src={imageUrl}
                alt="profile"
                className="t-w-8 t-h-8 t-rounded-full"
              />
              <div className="t-flex t-flex-col">
                <span className="t-text-text-100 t-font-medium t-truncate t-max-w-36 t-block t-text-subtitle-sm">
                  {name}
                </span>
                <span className="t-text-body-sm t-text-text-30 t-truncate t-block">
                  {email}
                </span>
              </div>
              {/* Make props standup that they are pro */}
              {/* <span className="t-ml-auto">
                <Tag tagType="green" icon={false}>
                  {userType}
                </Tag>
              </span> */}
            </div>

            <div className="t-py-2">
              <LeftBarSeparator />
            </div>

            <div className="t-flex t-gap-2 t-flex-col">{children}</div>
          </div>
        </HoverCard.Content>
      </HoverCard.Portal>
    </HoverCard.Root>
  );
};

export const LeftBarSeparator = ({ name }: { name?: string }) => {
  const { open } = useContext(LeftBarOpenContext);

  return (
    <div className="t-flex t-items-center">
      {name && open && (
        <span className="t-text-body-xs t-whitespace-nowrap t-px-4 t-text-text-30">
          {name}
        </span>
      )}
      <span className="t-h-px t-bg-neutral-10 t-w-full" />
    </div>
  );
};

const Anchor = (props: ComponentProps<typeof NavLink>) => (
  <a
    {...props}
    className={
      typeof props.className === "function"
        ? props.className?.(false)
        : props.className
    }
    href={props.to.toString()}
    style={
      typeof props.style === "function" ? props.style?.(false) : props.style
    }
  />
);

const LeftBarItemBase = ({
  icon: Icon,
  children,
  suffix,
  to,
  useParentPath = true,
  disabled,
  ...props
}: {
  icon: ComponentType;
  children: ReactNode;
  suffix?: ReactNode;
  to: string;
  useParentPath?: boolean;
  disabled?: boolean;
} & ComponentProps<typeof NavLink>) => {
  const { path } = useRouteMatch();
  const { open } = useContext(LeftBarOpenContext);
  const id = useId();

  const isExternal =
    to?.startsWith("https://") ||
    to?.startsWith("http://") ||
    to?.startsWith("mailto") ||
    to?.startsWith("tel");

  let LinkComponent = isExternal ? Anchor : NavLink;

  return (
    <LinkComponent
      {...props}
      to={useParentPath ? `${path}${to}` : to}
      target={to.includes("http") ? "_blank" : undefined}
      // @ts-ignore
      className={(isActive) =>
        classNames(
          "t-relative t-py-2 t-px-4 t-flex t-gap-2 t-items-center t-border-solid t-border-0 before:t-content-[''] before:t-w-[3px] before:t-h-full before:t-absolute before:t-left-0 t-transition-all t-no-underline",
          {
            "t-bg-surface-lighter-grey hover:t-bg-neutral-0 hover:before:t-bg-purple-30 t-group/non-active-link":
              !isActive,
            "t-bg-neutral-0 hover:before:t-border-l-[3px] before:t-bg-purple-50 t-group/active-link":
              isActive,
            "t-justify-center": !open,
            "t-text-text-30 t-pointer-events-none": disabled,
            // "py-2": open,
          }
        )
      }
    >
      <LayoutGroup id={id}>
        <AnimatePresence>
          {!open && (
            <motion.span
              animate={{ opacity: 1, position: "static" }}
              exit={{ opacity: 0, position: "absolute" }}
              initial={{ opacity: 0, position: "absolute" }}
              transition={{ delay: 0, duration: 0.1 }}
              className="t-flex group-[&]/active-link:t-text-text-100 t-text-text-30"
            >
              <Icon />
            </motion.span>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {open && (
            <motion.div
              animate={{ opacity: 1, position: "static" }}
              exit={{ opacity: 0, position: "absolute" }}
              initial={{ opacity: 0, position: "absolute" }}
              transition={{ delay: 0, duration: 0.1 }}
              className="t-flex t-gap-2 t-items-center t-justify-between t-w-full"
            >
              <div className="t-flex t-gap-2 t-items-center">
                <span
                  className={classNames(
                    "t-flex group-[&]/active-link:t-text-text-100",
                    {
                      "t-text-text-30": !disabled,
                      "t-text-neutral-30": disabled,
                    }
                  )}
                >
                  <Icon />
                </span>
                <span
                  className={classNames(
                    "t-text-body group-[&]/active-link:t-text-text-100 group-[&]/active-link:t-font-medium  t-whitespace-nowrap t-overflow-hidden",
                    {
                      "t-text-text-30": disabled,
                      "!t-text-text-60 group-hover/non-active-link:t-text-text-100":
                        !disabled,
                    }
                  )}
                >
                  {children}
                </span>
              </div>

              {suffix}
            </motion.div>
          )}
        </AnimatePresence>
      </LayoutGroup>
    </LinkComponent>
  );
};

export const LeftBarItem = (props: ComponentProps<typeof LeftBarItemBase>) => {
  const { path } = useRouteMatch();
  const currentPath =
    props.useParentPath === false ? props.to : `${path}${props.to}`;

  return (
    <PermissionBasedUI
      side="right"
      route={currentPath.split("?")[0]}
      blockedUI={<LeftBarItemBase disabled suffix={<Padlock />} {...props} />}
    >
      <LeftBarItemBase {...props} />
    </PermissionBasedUI>
  );
};

export const LeftBarTag = ({
  type,
  children,
}: {
  type: "red" | "blue";
  children: ReactNode;
}) => {
  return (
    <div
      className={classNames(
        "t-p-1 t-text-body-sm t-rounded-full t-leading-[10px] t-font-medium",
        {
          "t-bg-red-20 t-text-red-80": type === "red",
          "t-bg-blue-20 t-text-blue-80": type === "blue",
        }
      )}
    >
      {children}
    </div>
  );
};

export const LeftBar = ({
  children,
  logo,
  profile,
  useInternalLink,
  showCollapse,
}: {
  children: ReactNode;
  logo?: ReactNode;
  profile?: ReactNode;
  useInternalLink?: boolean;
  showCollapse?: boolean;
}) => {
  const [open, setOpen] = useState(true);
  const [isCollapseIconHovered, setCollapseIconHovered] = useState(false);
  const { trackEvent } = useAnalytics();

  const handleNavbarCollapse = () => {
    setOpen && setOpen((o) => !o);
    trackEvent(NAVBAR_COLLAPSER_CLICKED);
  };

  return (
    <LeftBarOpenContext.Provider value={{ open, setOpen }}>
      <motion.div
        transition={{ duration: 0.3 }}
        className={classNames(
          "t-bg-surface-lighter-grey t-h-full t-flex t-flex-col t-transition-all t-duration-30 t-border t-border-solid t-border-b-0 t-border-t-0 t-border-l-0 t-relative",
          {
            "md:t-w-[240px]": open,
            "md:t-w-[64px]": !open,
            "t-border-neutral-20": isCollapseIconHovered,
            "t-border-surface-transparent": !isCollapseIconHovered,
          }
        )}
      >
        <DashboardContainer className="t-h-full">
          {logo && (
            <DashboardContainer.Header className="t-border-solid t-border-b t-border-0 t-border-neutral-0 t-mb-6 ">
              {logo}
              {showCollapse && (
                <div
                  className="t-hidden md:t-block"
                  onMouseEnter={() => setCollapseIconHovered(true)}
                  onMouseLeave={() => setCollapseIconHovered(false)}
                >
                  <ToolTip text={open ? "Collapse" : "Expand"} side="right">
                    <button
                      className={classNames(
                        "t-absolute t-top-1/2 t-h-8 t-flex t-justify-center t-items-center all:unset t-text-text-60  t-translate-x-1/2 -t-translate-y-1/2 t-z-sidebar t-transition-all",
                        {
                          "-t-right-2 t-w-10 t-pl-2": isCollapseIconHovered,
                          "-t-right-2 t-w-8": !isCollapseIconHovered,
                        }
                      )}
                      onClick={handleNavbarCollapse}
                    >
                      <motion.div
                        initial={{ rotate: 0, y: -4 }}
                        animate={{
                          rotate: !open
                            ? isCollapseIconHovered
                              ? -45
                              : 0
                            : isCollapseIconHovered
                            ? 45
                            : 0,
                        }}
                        transition={{ duration: 0.1 }}
                        className="t-w-[2px] t-h-4 t-bg-text-30 t-absolute"
                      />

                      <motion.div
                        initial={{ rotate: 0, y: 4 }}
                        animate={{
                          rotate: !open
                            ? isCollapseIconHovered
                              ? 45
                              : 0
                            : isCollapseIconHovered
                            ? -45
                            : 0,
                        }}
                        transition={{ duration: 0.1 }}
                        className="t-w-[2px] t-mt-[4px] t-h-4 t-bg-text-30 t-absolute"
                      />
                    </button>
                  </ToolTip>
                </div>
              )}
            </DashboardContainer.Header>
          )}
          <DashboardContainer.Content className="t-flex t-flex-col ">
            {children}
            <div className="t-sticky t-bottom-0">
              {profile || <ProfileDropdown useInternalLink={useInternalLink} />}
            </div>
          </DashboardContainer.Content>
        </DashboardContainer>
      </motion.div>
    </LeftBarOpenContext.Provider>
  );
};
