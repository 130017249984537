export const EnterIcon = () => {
  return (
    <>
      <svg
        width="14"
        height="10"
        viewBox="0 0 14 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.3773 2.73976H2.08723L3.78333 1.04365C4.02209 0.804895 4.02209 0.417854 3.78333 0.179095C3.54451 -0.0597322 3.15753 -0.0596643 2.91871 0.179095L0.179054 2.91888C-0.0597051 3.15764 -0.0597051 3.54468 0.179054 3.78344L2.91871 6.52309C3.03812 6.64251 3.19455 6.70215 3.35099 6.70215C3.50742 6.70215 3.66385 6.64244 3.78327 6.52309C4.02202 6.28433 4.02202 5.89729 3.78327 5.65853L2.08716 3.96243H10.3772C11.7006 3.96243 12.7773 5.03912 12.7773 6.36252C12.7773 7.68591 11.7006 8.76261 10.3772 8.76261H5.33428C4.99662 8.76261 4.72295 9.03628 4.72295 9.37394C4.72295 9.7116 4.99662 9.98527 5.33428 9.98527H10.3772C12.3748 9.98527 14 8.36008 14 6.36252C14 4.36495 12.3749 2.73976 10.3773 2.73976Z"
          fill="#706A85"
        />
      </svg>
    </>
  );
};
