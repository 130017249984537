export const LinkIcon = ({ color = "currentColor" }: { color?: string }) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.14377 4.46248L9.38127 3.22498C9.90522 2.74743 10.593 2.49013 11.3017 2.50654C12.0105 2.52295 12.6856 2.8118 13.1869 3.31309C13.6882 3.81437 13.9771 4.48953 13.9935 5.19826C14.0099 5.90699 13.7526 6.59479 13.275 7.11873L11.5063 8.88123C11.2514 9.1371 10.9484 9.34012 10.6149 9.47865C10.2813 9.61718 9.9237 9.68849 9.56252 9.68849C9.20135 9.68849 8.84372 9.61718 8.51017 9.47865C8.17661 9.34012 7.87369 9.1371 7.61877 8.88123"
      stroke={color}
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.85629 11.5375L7.61879 12.775C7.09485 13.2525 6.40705 13.5098 5.69832 13.4934C4.98959 13.477 4.31443 13.1881 3.81315 12.6869C3.31186 12.1856 3.02301 11.5104 3.0066 10.8017C2.99019 10.093 3.24749 9.40516 3.72504 8.88122L5.49379 7.11872C5.74871 6.86285 6.05163 6.65983 6.38518 6.5213C6.71874 6.38277 7.07636 6.31146 7.43754 6.31146C7.79872 6.31146 8.15634 6.38277 8.4899 6.5213C8.82346 6.65983 9.12638 6.86285 9.38129 7.11872"
      stroke={color}
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
