import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import Modal from "components/DesignSystem/Modal/Modal";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { FileInput } from "components/FileInput/FileInput";
import { Field, FieldProps, Form, Formik } from "formik";

export const SendQuoteModal = ({
  members,
  isOpen,
  setIsOpen,
  onSendQuote,
  isSubmiting,
}: {
  members: { name: string; email: string }[];
  isOpen: boolean;
  setIsOpen: (v: boolean) => void;
  onSendQuote: (v: { file: File | any; recipients: string }) => void;
  isSubmiting: boolean;
}) => {
  const onSubmit = ({
    file,
    other_emails,
    ...memberEmails
  }: {
    file: File | any;
    other_emails: string;
  } & { [key: string]: boolean | string }) => {
    const concatinatedEmails = Object.entries(memberEmails)
      .reduce(
        (acc, [email, do_include]) =>
          do_include ? `${acc},${email.replaceAll("_", ".")}` : acc,
        ""
      )
      .replace(",", "");

    onSendQuote({
      file,
      recipients: `${concatinatedEmails}${
        other_emails ? "," : ""
      }${other_emails}`,
    });
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={setIsOpen}>
      <Modal.Content size="regular">
        <Modal.Header>
          <div className="t-w-full">
            <Modal.Title titleIcon={<Modal.Close />}>Send quote</Modal.Title>
            <Modal.Subtitle>
              Existing team members can expect to receive their quotes in both
              their dashboard and email.
            </Modal.Subtitle>
          </div>
        </Modal.Header>
        <Formik
          initialValues={{
            file: "",
            other_emails: "",
          }}
          onSubmit={onSubmit}
        >
          <Form className="t-m-0 t-flex t-w-full t-flex-col">
            <Modal.Body className="t-h-[360px]">
              <div className="t-flex t-flex-col t-gap-4">
                <div>
                  <FileInput name="file" withForm label="Upload quote" />
                </div>

                <div className="t-flex t-flex-col">
                  <span className="t-mb-2 t-text-body-sm">Team members</span>
                  <div className="t-flex t-flex-col t-gap-5">
                    {members.map(({ name, email }) => {
                      const formattedEmail = email.replaceAll(".", "_");

                      return (
                        <Field
                          name={formattedEmail}
                          id={formattedEmail}
                          key={formattedEmail}
                        >
                          {({ field }: FieldProps) => (
                            <div className="t-flex t-items-start t-gap-4">
                              <Checkbox {...field} />
                              <label htmlFor={formattedEmail}>
                                <span className="t-block t-text-body t-leading-none">
                                  {name}
                                </span>
                                <span className="t-text-body-sm t-text-neutral">
                                  {email}
                                </span>
                              </label>
                            </div>
                          )}
                        </Field>
                      );
                    })}
                  </div>
                </div>

                <TextInput
                  label="Other emails"
                  name="other_emails"
                  placeholder="john@acme.com, doe@acme.io"
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="t-flex t-justify-end">
                <Button
                  customType="primary"
                  isLoading={isSubmiting}
                  disabled={isSubmiting}
                >
                  <span className="t-block t-px-5">Send</span>
                </Button>
              </div>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal.Content>
    </Modal.Root>
  );
};
