import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { TransferDetails } from "components/TransferPricingInfo";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import StepCheckMark from "static/images/StepCheckMark.svg";
import { closeFloatingChat } from "store/slices/chat";
import { resetAllTPStates } from "store/slices/transferpricing";
import { RootState } from "store/store";
import { TransferPricingTransfer } from "types/Models/TransferPricingTransfer";
import { currency as currencyFormatter } from "utils/Currency";
import { PaymentInitiateModal } from "../TransferTable/PaymentInitiateModal";

export const TransferMoneyStep = ({
  setCurrentStep,
}: {
  setCurrentStep: (v: number) => void;
}) => {
  const history = useHistory();
  const { search } = useLocation();
  const { isAdmin } = useRoleBasedView();
  const [isCheckBoxSelected, setIsCheckBoxSelected] = useState(false);

  const [currentTransferDetails, setCurrentTransferDetails] =
    useState<TransferPricingTransfer>();

  const { accountInfo, amount, purpose_code, tpPaymentId, currency } =
    useSelector((state: RootState) => state.tpPaymentsSlice);
  const dispatch = useDispatch();

  const { receiver, sender_bank_account, receiver_bank_account } =
    accountInfo || {};

  const [openInitiatPaymentModal, setOpenInitiatePaymentModal] =
    useState(false);

  const handleCheckBoxChange = () => {
    setOpenInitiatePaymentModal(true);
    setIsCheckBoxSelected((prev) => !prev);
  };

  const handleGoToDashboard = () => {
    dispatch(resetAllTPStates());
    if (isAdmin) {
      history.push(`/books/transfers/${search}`);
    } else {
      history.push(`/intragroup/transfers/${search}`);
    }
    setCurrentStep(1);
    dispatch(closeFloatingChat());
  };

  return (
    <div className="t-flex t-flex-1 t-w-full t-mt-16 t-justify-center t-items-center t-mx-14 t-gap-6">
      <div className="t-flex t-flex-col t-items-start t-w-1/2">
        <div className="t-flex t-items-center t-justify-center t-pb-6">
          <img src={StepCheckMark} width="78px" height="78px" alt="check" />
        </div>
        <div className="t-flex t-flex-col t-gap-4">
          <div className="t-flex t-text-h4">It’s almost done!</div>
          <div className="t-flex t-flex-col t-gap-2">
            <div className="t-text-subtitle-sm">Here are you next steps</div>
            <ol className="t-text-body-lg">
              <li>
                Please initiate a{" "}
                <span className="t-font-semibold">{`${currencyFormatter({
                  amount,
                  currencyCode: "USD",
                })}`}</span>{" "}
                payment to your Virtual Account (VAC) to receive funds in{" "}
                <span className="t-font-semibold">
                  {receiver_bank_account?.domestic_credit?.slice(0, 4)} - &nbsp;
                  {receiver_bank_account?.account_number.slice(-4)}
                </span>{" "}
                from online banking.
              </li>
              <li>
                Once done let us know below if you have initiated the payment.
              </li>
            </ol>
            {!currentTransferDetails?.initiated_date && (
              <div className="t-w-[280px] t-rounded t-border t-border-solid t-border-purple-40 t-p-2 t-bg-surface-purple">
                <Checkbox
                  label="Yes, I have initiated the payment"
                  name="ispaymentinitiated"
                  onChange={handleCheckBoxChange}
                  checked={isCheckBoxSelected}
                />
              </div>
            )}
          </div>
          <span>
            <Button customType="primary" onClick={handleGoToDashboard}>
              Go to dashboard
            </Button>
          </span>
        </div>
      </div>
      <div className="t-p-8 t-rounded-lg t-border t-border-solid t-border-neutral-10 t-w-1/2 t-flex t-flex-col t-gap-8">
        <div className="t-text-h5">Transfer Instructions</div>
        <TransferDetails
          receiver={receiver}
          senderBankAccount={sender_bank_account}
        />
      </div>
      <PaymentInitiateModal
        isOpen={openInitiatPaymentModal}
        close={() => setOpenInitiatePaymentModal(false)}
        tpPaymentId={tpPaymentId}
        amount={amount}
        currency={currency}
        purposeCodeUuid={purpose_code.uuid}
        isCheckBoxSelected={isCheckBoxSelected}
        setIsCheckBoxSelected={setIsCheckBoxSelected}
        setCurrentTransferDetails={setCurrentTransferDetails}
      />
    </div>
  );
};
