import { AmountSuperScript } from "components/design/AmountSuperScript";
import ConditionalToolTip from "components/design/conditionalToolTip";
import { Divider } from "components/design/Divider";
import { TableUI } from "components/design/TableUI";
import { Button } from "components/DesignSystem/Button/Button";
import { Chip } from "components/DesignSystem/Chips/Chips";
import { Filter } from "components/DesignSystem/Filter/Filter";
import { Loader } from "components/DesignSystem/Loader/Loader";
import Modal from "components/DesignSystem/Modal/Modal";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import { Search } from "components/DesignSystem/Search/Search";
import { DateFilter } from "components/Filters/DateFilter";
import { MagnifyingGlass } from "components/icons/MagnifyingGlass";
import { NumericInput } from "components/NumericInput/NumericInput";
import {
  Capsule,
  CategoryFilter,
  FromFilter,
  VendorsFilter,
} from "components/Transaction/Filter";
import {
  DiscountTransactions,
  initialTransaction,
} from "components/Transaction/LinkInkleInvoiceModal";
import { MerchantComponent } from "components/Transaction/MerchantSelector";
import TransactionColumn from "components/Transaction/TransactionColumn";
import { DD_MMM_YYYY, YYYY_MM_DD } from "constants/date";
import dayjs from "dayjs";
import { Form, Formik, useFormikContext } from "formik";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useFilters } from "hooks/useFilter";
import { useToast } from "hooks/useToast";
import randomBytes from "randombytes";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  createColumnHelper,
  getCoreRowModel,
  Row,
  useReactTable,
} from "react-table-8.10.7";
import { useMarkInvoicePaidMutation } from "store/apis/invoices";
import { useGetAllTransactionsDataQuery } from "store/apis/transactions";
import { getFilterStatus } from "store/selector/transactionFilter";
import { FilterName } from "store/slices/transactionFilter";
import { RootState } from "store/store";
import { Transactions } from "types/Models/books";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";
import { currency } from "utils/Currency";
import { debounce } from "utils/debouncing";
import { formatDate } from "utils/formatDate";
import { getDateRange } from "utils/getDateRange";
import { roundToFixedDecimal } from "utils/roundtoFixedDecimal";

const AmountFilter = () => {
  return (
    <div className="t-flex t-flex-col t-gap-5">
      <div className="t-flex t-flex-col t-gap-3">
        <div className="all:unset t-flex t-flex-col t-gap-4">
          <NumericInput
            label="Min. Amount"
            storeNumeric
            fieldProps={{ name: "min_amount" }}
            numericProps={{
              thousandSeparator: true,
              prefix: "$",
            }}
          />
          <NumericInput
            label="Max. Amount"
            storeNumeric
            fieldProps={{ name: "max_amount" }}
            numericProps={{
              thousandSeparator: true,
              prefix: "$",
            }}
          />
        </div>
      </div>
    </div>
  );
};

const TransactionsFilter = ({
  currentPage,
  setCurrentPage,
  filterValues,
  updateFilter,
  totalPages,
  paginationData,
}: {
  currentPage: number;
  setCurrentPage: (v: number) => void;
  filterValues: {
    START_DATE: string;
    END_DATE: string;
    SELECT_PERIOD: string;
  };
  updateFilter: <S extends "START_DATE" | "END_DATE" | "SELECT_PERIOD">(
    name: S,
    newValue: {
      START_DATE: string;
      END_DATE: string;
      SELECT_PERIOD: string;
    }[S]
  ) => void;
  paginationData: {
    totalPage: number;
    currentPage: number;
    itemsPerPage: number;
    totalItemCount: number;
  };
  totalPages: number;
}) => {
  const { values: formikValues, setFieldValue } = useFormikContext<{
    min_amount?: string;
    max_amount?: string;
    search?: string;
    transaction_amount?: number;
    remaining_amount?: number;
  }>();

  const { capsuleFilters, getFilterName, fixedFilters } =
    useSelector(getFilterStatus);

  const goToFirstPage = () => {
    setCurrentPage(1);
  };

  const goToPrevPage = () => {
    const localCurrentPage =
      currentPage < totalPages! ? currentPage : totalPages!;
    setCurrentPage(localCurrentPage - 1);
  };

  const goToNextPage = () => {
    if (currentPage < totalPages!) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToLastPage = () => {
    setCurrentPage(totalPages);
  };

  return (
    <div className="t-flex t-justify-between">
      <Filter.Root
        defaultValue="DATE"
        capsule={
          <>
            {filterValues.START_DATE && (
              <Chip
                onClose={() => {}}
                isRemovable={false}
                isActive
                filterType="DATE"
              >
                From: {formatDate(filterValues.START_DATE)} To:{" "}
                {formatDate(filterValues.END_DATE)}
              </Chip>
            )}
            {formikValues.max_amount && (
              <Chip
                onClose={() => {
                  setFieldValue("max_amount", null);
                }}
                isActive
                filterType="AMOUNT"
              >
                Max. Amount {currency({ amount: formikValues.max_amount })}
              </Chip>
            )}
            {formikValues.min_amount && (
              <Chip
                onClose={() => {
                  setFieldValue("min_amount", null);
                }}
                isActive
                filterType="AMOUNT"
              >
                Min. Amount {currency({ amount: formikValues.min_amount })}
              </Chip>
            )}
            {capsuleFilters.map(({ name, value, type }) => {
              let filterValue = value;
              if (Array.isArray(value)) {
                filterValue = `(${value.length})`;
              }

              return (
                <Capsule
                  key={name}
                  value={filterValue}
                  onCapsuleClick={() =>
                    setFieldValue(`${getFilterName(name)! as FilterName}`, null)
                  }
                  type={type}
                >
                  {name}
                </Capsule>
              );
            })}
          </>
        }
      >
        <Filter.Portal>
          <Filter.List>
            <Filter.ListItem value="DATE">Date</Filter.ListItem>
            <Filter.ListItem value="FROM">Source</Filter.ListItem>
            <Filter.ListItem value="CATEGORY">Category</Filter.ListItem>
            <Filter.ListItem value="VENDOR">Vendor</Filter.ListItem>
            <Filter.ListItem value="AMOUNT">Amount</Filter.ListItem>
          </Filter.List>

          <Filter.Body value="DATE" block>
            <DateFilter values={filterValues} updateFilter={updateFilter} />
          </Filter.Body>
          <Filter.Body value="FROM" block>
            <FromFilter />
          </Filter.Body>
          <Filter.Body value="CATEGORY" block>
            <CategoryFilter />
          </Filter.Body>
          <Filter.Body value="VENDOR" block>
            <VendorsFilter />
          </Filter.Body>
          <Filter.Body value="AMOUNT" block>
            <AmountFilter />
          </Filter.Body>
        </Filter.Portal>
      </Filter.Root>
      <Pagination
        {...paginationData}
        goToFirstPage={goToFirstPage}
        goToPrevPage={goToPrevPage}
        goToNextPage={goToNextPage}
        goToLastPage={goToLastPage}
      />
    </div>
  );
};

const LinkTransactionBody = () => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const [rowSelection, setRowSelection] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  const {
    filters: {
      uncategorised,
      minAmount,
      maxAmount,
      vendors,
      categoryIds,
      ...restFilter
    },
  } = useSelector((state: RootState) => state.transactionFilter);

  const [sorting, setSorting] = useState<
    {
      desc: boolean;
      id: "AMOUNT" | "DATE" | "VENDOR";
    }[]
  >([
    {
      id: "DATE",
      desc: true,
    },
  ]);

  const sortCol = sorting[0]?.id;
  const sortOrder = sorting[0]?.desc;

  const { values: formikValues, setFieldValue } = useFormikContext<{
    min_amount?: string;
    max_amount?: string;
    search?: string;
    invoice_number?: number;
    due_balance?: number;
    transaction_id: string;
  }>();

  const defaultDateRange = getDateRange("currentYear");
  const { values: filterValues, updateFilter } = useFilters({
    initialValue: {
      START_DATE: dayjs(defaultDateRange.startDate).format(DD_MMM_YYYY),
      END_DATE: dayjs(defaultDateRange.endDate).format(DD_MMM_YYYY),
      SELECT_PERIOD: "currentYear",
    },
  });

  const {
    data: transactionData,
    isLoading,
    isSuccess,
    isFetching,
  } = useGetAllTransactionsDataQuery(
    {
      groupId,
      entityId,
      pageSize: "5",
      page_num: currentPage,
      searchTerm: formikValues?.search || null,
      startDate: { value: dayjs(filterValues.START_DATE).format(YYYY_MM_DD) },
      endDate: { value: dayjs(filterValues.END_DATE).format(YYYY_MM_DD) },
      categoryIds: categoryIds,
      vendors: vendors,
      minAmount: formikValues?.min_amount || "0",
      maxAmount: formikValues?.max_amount,
      sortCol,
      sortOrder: sortOrder ? ("DSC" as const) : ("ASC" as const),
      cashFlow: { value: "CREDIT" },
      bankTransactions: { value: true },
    },
    {
      skip: !groupId || !entityId,
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    transactions = [],
    channel_url = "",
    current_page = 0,
    per_page = 0,
    total_count = 0,
    total_pages = 0,
  } = transactionData || {};

  const paginationData = {
    totalPage: transactionData?.total_pages!,
    currentPage: transactionData?.current_page!,
    itemsPerPage: transactionData?.per_page!,
    totalItemCount: transactionData?.total_count!,
  };

  const columnHelper = createColumnHelper<Transactions>();

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "select",
        size: 4,
        enableSorting: false,
        header: () => <></>,

        cell: ({ row }) => (
          <input
            type="radio"
            className="t-accent-purple t-h-4 t-w-4 t-flex t-items-center"
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
              key: row.id,
            }}
            onClick={(e) => e.stopPropagation()}
          />
        ),
      }),

      columnHelper.accessor("transaction.date", {
        id: "DATE",
        size: 17,
        header: "Date",
        cell: (info) => (
          <div className="t-text-subtext t-text-text-30">
            {dayjs(info.getValue()).format(DD_MMM_YYYY)}
          </div>
        ),
      }),

      columnHelper.accessor("transaction.from", {
        id: "logo",
        size: 5,
        enableSorting: false,
        header: () => (
          <div className="t-flex t-justify-center t-w-full">Source</div>
        ),
        cell: (info) => {
          const transaction = info.row.original.transaction;

          return <TransactionColumn.Source transaction={transaction} />;
        },
      }),

      columnHelper.accessor("transaction.category", {
        id: "category",
        size: 28,
        header: "Category",
        enableSorting: false,
        cell: (info) => {
          const {
            row: {
              original: {
                transaction: { category },
              },
            },
          } = info;

          if (!category) {
            return "-";
          }

          return <>{category?.name}</>;
        },
      }),

      columnHelper.accessor("transaction.merchant", {
        id: "VENDOR",
        size: 33,
        header: "Vendor",
        cell: (info) => {
          const {
            row: {
              original: {
                transaction: { logo, merchant },
              },
            },
          } = info;

          if (!merchant) {
            return "-";
          }

          return <MerchantComponent name={merchant} logo={logo} />;
        },
      }),

      columnHelper.accessor("transaction.amount", {
        id: "AMOUNT",
        size: 12,
        header: () => (
          <span className="t-flex t-justify-end t-ml-auto">Amount</span>
        ),
        cell: (info) => {
          const amount = info.getValue();
          const {
            transaction: { is_credit_card },
          } = info.row.original || {};

          return (
            <TransactionColumn.Amount
              amount={amount}
              isCreditCard={is_credit_card}
            />
          );
        },
      }),
    ],
    []
  );

  const table = useReactTable({
    data: transactions || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: true,
    enableMultiRowSelection: false,
    manualSorting: true,
    state: {
      rowSelection,
      sorting,
    },
    //@ts-ignore
    onSortingChange: setSorting,
    getRowId: (row) => row.transaction.uuid,
    onRowSelectionChange: setRowSelection,
    enableMultiSort: false,
    enableSortingRemoval: false,
    defaultColumn: {
      size: 10,
      minSize: 1,
      maxSize: 100,
    },
  });

  useEffect(() => {
    if (rowSelection) {
      const id = Object.keys(rowSelection)?.[0];
      setFieldValue("transaction_id", id);
    }
  }, [rowSelection]);

  const handleSearch = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFieldValue("search", value);
  });

  const onRowClick = (row: Row<Transactions>) => {
    row.toggleSelected();
    const isUnSelect =
      formikValues?.transaction_id === row.original.transaction.uuid;

    const transactionAmount = isUnSelect ? 0 : row.original.transaction.amount;

    setFieldValue(
      "remaining_amount",
      roundToFixedDecimal({ numberToRound: formikValues.due_balance! }) -
        transactionAmount
    );
  };

  if (isLoading && !isSuccess) {
    return (
      <div className="t-w-full t-h-full t-flex t-justify-center t-align-middle">
        <Loader />
      </div>
    );
  }

  return (
    <div className="t-flex t-flex-col t-gap-4">
      <div className="t-w-full t-h-full t-flex t-gap-4">
        <Search block placeholder="Search..." onChange={handleSearch} />
        <div className="t-w-1/2">
          <div className="t-flex t-justify-end t-gap-3">
            <div className="t-flex t-flex-col">
              <div className="t-text-body-sm t-text-text-30 t-flex t-justify-end">
                Invoice Number:
              </div>
              <div className="t-text-subtitle-sm t-flex t-justify-end">
                {formikValues.invoice_number}
              </div>
            </div>
            <div className="t-h-[40px] t-border t-border-solid t-border-top-0 t-border-l t-border-r-0 t-border-bottom-0 t-border-neutral-0"></div>

            <div className="t-flex t-flex-col">
              <div className="t-text-body-sm t-text-text-30  t-flex t-justify-end">
                Invoice Amount:
              </div>
              <div className="t-text-subtitle-sm t-flex t-justify-end">
                <AmountSuperScript amount={formikValues?.due_balance!} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <TransactionsFilter
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={total_pages}
        filterValues={filterValues}
        updateFilter={updateFilter}
        paginationData={paginationData}
      />
      <Divider />
      {transactions.length === 0 ? (
        <div className="t-w-full t-h-full t-flex t-flex-col t-justify-center t-items-center t-py-12 t-gap-2 t-text-i-neutral-10">
          <MagnifyingGlass size="149" />
          <div className="t-w-full t-h-full t-flex t-flex-col t-justify-center t-items-center t-gap-3">
            <div className="t-text-body-sm t-text-text-30">
              No transactions found
            </div>
          </div>
        </div>
      ) : (
        <TableUI
          table={table}
          enableSort
          size="regular"
          onRowClick={onRowClick}
        />
      )}
      <DiscountTransactions />
    </div>
  );
};

export const LinkInvoiceToTransactionModal = ({
  isOpen,
  close,
  invoiceDueAmount,
  invoiceNumber,
  invoiceId,
}: ModalProps & {
  invoiceNumber: string;
  invoiceDueAmount: number;
  invoiceId: string;
}) => {
  const { alertToast, successToast } = useToast();
  const entityId = useCurrentEntityId();
  const [markInvoicePaid, { isLoading }] = useMarkInvoicePaidMutation();

  const initialTransactions = useMemo(
    () =>
      Array.from({ length: 1 }).map((arr, i) => ({
        id: randomBytes(10).toString("hex"),
        ...initialTransaction,
      })),
    []
  );

  const initialValues = {
    transaction_id: "",
    transactions: initialTransactions,
    resolve_difference: false,
    invoice_number: invoiceNumber,
    due_balance: invoiceDueAmount,
    remaining_amount: invoiceDueAmount,
  };

  const onSubmit = async (values: {
    transaction_id: string;
    transactions: typeof initialTransactions;
    resolve_difference: Boolean;
    invoice_number: string;
    due_balance: number;
  }) => {
    try {
      const transactions = values.resolve_difference
        ? values.transactions.map(
            ({ amount, description, category, merchant }) => {
              return {
                amount: amount,
                description: description,
                category_id: category,
                merchant_id: merchant,
              };
            }
          )
        : null;
      await markInvoicePaid({
        entityId,
        invoiceId,
        transactionId: values.transaction_id,
        ...(transactions && { addedTransactions: transactions }),
      }).unwrap();
      successToast({ message: "Marked Paid Successsfully" });
      close();
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues}>
      {({ isSubmitting, submitForm, values }) => {
        const addedTransactionsAmount = values?.transactions.reduce(
          (acc, { amount }) => {
            return (acc = acc + Number(amount));
          },
          0
        );

        const finalRemainingAmount =
          roundToFixedDecimal({ numberToRound: values.remaining_amount }) -
          addedTransactionsAmount;

        const isDisabled = finalRemainingAmount !== 0;

        return (
          <Form>
            <Modal.Root open={isOpen} onOpenChange={close} modal={false}>
              <Modal.Content size="xxl" useCustomOverlay>
                <Modal.Header>
                  <Modal.Title>Link to transaction</Modal.Title>
                  <Modal.Close />
                </Modal.Header>
                <Modal.Body>
                  <LinkTransactionBody />
                </Modal.Body>
                <Modal.FooterButtonGroup>
                  <Modal.RawClose asChild>
                    <Button>Cancel</Button>
                  </Modal.RawClose>
                  <ConditionalToolTip
                    condition={
                      isDisabled &&
                      `${currency({
                        amount: finalRemainingAmount,
                      })} of ${currency({
                        amount: invoiceDueAmount,
                      })} remaining to be linked with invoice`
                    }
                  >
                    <span>
                      <Button
                        customType="primary"
                        isLoading={isSubmitting || isLoading}
                        disabled={isSubmitting || isLoading || isDisabled}
                        onClick={submitForm}
                      >
                        Link
                      </Button>
                    </span>
                  </ConditionalToolTip>
                </Modal.FooterButtonGroup>
              </Modal.Content>
            </Modal.Root>
          </Form>
        );
      }}
    </Formik>
  );
};
