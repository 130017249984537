import React from "react";
import BigSalesTaxIcon from "static/images/BigSalesTaxIcon.svg";
import { ProductInfo } from "./ProductInfo";
import * as Accordion from "@radix-ui/react-accordion";
import { CaretDown } from "components/icons/CaretDown";
import { Subscription } from "types/Models/subscription";
import { ProductTypeCard } from "./ProductTypeCard";
import { SubscriptionTypeCard } from "./SubscriptionTypeCard";
import { ProductGroup } from "store/apis/products";

export const SalesTaxProduct = ({
  salesTaxProduct,
}: {
  salesTaxProduct: ProductGroup;
}) => {
  const allProductKeys = Object.keys(salesTaxProduct);
  const categoriesToShow = allProductKeys.filter(
    (key) => salesTaxProduct[key]?.length > 0
  );

  return (
    <div className="t-space-y-8">
      <img src={BigSalesTaxIcon} alt="BigSalesTaxIcon" />
      <ProductInfo information="Sales Tax services are available with subscription for either Inkle Books or Inkle Tax" />
      <Accordion.Root
        type="multiple"
        defaultValue={categoriesToShow}
        className="t-space-y-8"
      >
        {categoriesToShow.map((category) => (
          <Accordion.Item value={category} key={category}>
            <Accordion.Trigger className="all:unset t-text-subtitle-sm t-text-text-60 t-flex t-gap-2 t-group t-w-full">
              <span className="group-data-state-open:t-rotate-0 group-data-state-closed:-t-rotate-90 t-text-neutral t-transform t-transition t-duration-300 t-ease-in-out">
                <CaretDown />
              </span>
              {category}
            </Accordion.Trigger>
            <Accordion.Content
              className="t-text-body-sm t-text-text-60 t-mt-3 t-grid t-gap-4"
              style={{
                gridTemplateColumns:
                  "repeat(auto-fill, minmax(360px, max-content))",
              }}
            >
              {salesTaxProduct[category].map((product) => {
                if ("subscription_name" in product) {
                  return (
                    <SubscriptionTypeCard
                      key={product.uuid}
                      subscription={product as Subscription}
                    />
                  );
                }
                return <ProductTypeCard key={product.uuid} product={product} />;
              })}
            </Accordion.Content>
          </Accordion.Item>
        ))}
      </Accordion.Root>
    </div>
  );
};
