import { FileObject } from "types/Models/fileObject";
import { fileAndFolder } from "../../types/Models/documents";
import { emptyApi } from "./emptyApi";

type CompanyWFormDataResponse = {
  created_at: string;
  entity_name: string | null;
  form_document: fileAndFolder | null;
  form_status: string;
  group_id: string;
  uuid: string;
  w_form_type: string;
  entity_id: string;
};

export type GroupWForm = {
  entity_name: string;
  created_at: string;
  w_form_type: string;
  uuid: string;
  form_document: fileAndFolder;
  entity_id: string;
  form_status: string;
  group_id: string;
};

const extendedApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getWForms: build.query<
      {
        company_group_forms: GroupWForm[];
        current_page: number;
        total_pages: number;
      },
      {
        groupId: string;
        currentPage: number;
      }
    >({
      query: ({ groupId, currentPage }) =>
        `api/inkle/bookkeeping/group/${groupId}/w-form?page_num=${currentPage}`,
      providesTags: (result) =>
        result
          ? [
              ...result.company_group_forms.map(
                ({ uuid }) => ({ type: "CompanyWForm", id: uuid } as const)
              ),
            ]
          : [],
    }),
    createWForms: build.mutation<
      CompanyWFormDataResponse,
      { groupId: string; entityId: string }
    >({
      query: ({ groupId, entityId }) => {
        return {
          url: `api/inkle/bookkeeping/group/${groupId}/w-form/`,
          method: "post",
          body: { entity_id: entityId },
        };
      },
    }),
    deleteWForms: build.mutation<
      CompanyWFormDataResponse,
      { groupId: string; companyWFormId: string }
    >({
      query: ({ groupId, companyWFormId }) => {
        return {
          url: `api/inkle/bookkeeping/group/${groupId}/w-form/${companyWFormId}/`,
          method: "delete",
        };
      },
      invalidatesTags: (result) => {
        return [{ type: "CompanyWForm", id: result?.uuid }];
      },
    }),
    updateWForm: build.mutation<
      { form_url: string },
      { group_id: string; company_w_form_id: string; w_form_type: string }
    >({
      query: ({ group_id, company_w_form_id, w_form_type }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${group_id}/w-form/${company_w_form_id}/`,
          method: "put",
          body: {
            w_form_type,
          },
        };
      },
    }),
    uploadPublicWFormFile: build.mutation<
      FileObject,
      { file: File; form_type: string; merchantId: string }
    >({
      query: ({ file, form_type, merchantId }) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("form_type", form_type);

        return {
          url: `/api/inkle/bookkeeping/merchant/${merchantId}/upload-form/`,
          method: "put",
          body: formData,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetWFormsQuery,
  useCreateWFormsMutation,
  useDeleteWFormsMutation,
  useUpdateWFormMutation,
  useUploadPublicWFormFileMutation,
} = extendedApi;
