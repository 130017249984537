import { Button } from "components/DesignSystem/Button/Button";
import ProgressBar from "components/DesignSystem/ProgressBar/ProgressBar";
import React from "react";

export const OnboardingHomeCard = ({
  progress,
  onClick,
}: {
  progress?: number;
  onClick: React.DOMAttributes<HTMLDivElement>["onClick"];
}) => {
  return (
    <div
      role="button"
      onClick={onClick}
      className="t-border t-border-solid t-rounded-lg t-p-5 t-border-neutral-10 t-h-full t-w-full t-relative t-bg-[url('static/images/OnboardingBackground.svg')] t-bg-cover"
    >
      <div className="t-flex t-flex-col t-justify-between t-h-full">
        <div className="t-w-9/12 t-gap-4 t-space-y-4">
          <p className="t-text-h5">Complete Onboarding</p>

          <div>
            <div className="t-w-8/12">
              <ProgressBar.Root className="t-bg-white">
                <ProgressBar.Indicator progress={progress || 0} />
              </ProgressBar.Root>
            </div>

            <span className="t-text-body-sm">{progress}% Complete</span>
          </div>
        </div>
        <div>
          <Button size="small">Continue</Button>
        </div>
      </div>
    </div>
  );
};
