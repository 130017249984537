import { Search } from "components/DesignSystem/Search/Search";
import { ProductBody } from "components/product/ProductBody";
import {
  PRODUCT_BOOKS,
  PRODUCT_INTRAGROUP,
  PRODUCT_MAILROOM,
  PRODUCT_SALESTAX,
  PRODUCT_TAX,
} from "constants/billing";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import SmallTax from "static/images/SmallTax.svg";
import SmallBooks from "static/images/SmallBooks.svg";
import SmalMailroom from "static/images/SmallMailroom.svg";
import SmallSalesTax from "static/images/SmallSalesTax.svg";
import SmallIntragroup from "static/images/SmallIntragroup.svg";
import { ProductChip } from "components/product/ProductChip";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { AddProductToCart } from "components/AddProductToCart/AddProductToCart";
import { CartModal } from "components/CartModal/CartModal";
import { PayDebt } from "components/PayDebt/PayDebt";
import { useConstructInternalLink } from "hooks/useConstructInternalLink";
import { parse, stringify } from "qs";

type productQueryType =
  | typeof PRODUCT_BOOKS
  | typeof PRODUCT_TAX
  | typeof PRODUCT_INTRAGROUP
  | typeof PRODUCT_SALESTAX
  | typeof PRODUCT_MAILROOM;

export const Product = () => {
  const { update } = useUpdateQuery();
  const query = useQuery();
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const { link } = useConstructInternalLink();
  const isBooksActive = query.get(PRODUCT_BOOKS);
  const isTaxActive = query.get(PRODUCT_TAX);
  const isMailroomActive = query.get(PRODUCT_MAILROOM);
  const isSalesTaxActive = query.get(PRODUCT_SALESTAX);
  const isIntragroupActive = query.get(PRODUCT_INTRAGROUP);
  const search = query.get("search") || "";
  const location = useLocation();
  const parsedSearch = parse(location.search, { ignoreQueryPrefix: true });
  const queryParams = stringify(
    {
      ...parsedSearch,
      is_send_invoice_open: true,
    },
    { skipNulls: true, addQueryPrefix: true }
  );

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    update({ query: "search", value: e.target.value || null });
  };

  const updateProductType = (
    query: productQueryType,
    value: boolean | null
  ) => {
    update({ query, value: value || null });
  };

  return (
    <div>
      <div className="t-w-1/2">
        <Search
          block
          onChange={onSearch}
          defaultValue={search}
          placeholder="Search..."
        />
      </div>
      <div className="t-flex t-gap-2 t-items-center t-wrap t-mt-4 t-mb-10">
        <ProductChip
          onClick={() => updateProductType(PRODUCT_BOOKS, true)}
          isActive={Boolean(isBooksActive)}
          onClose={() => updateProductType(PRODUCT_BOOKS, null)}
          icon={<img src={SmallBooks} alt="SmallBooks" />}
        >
          Books
        </ProductChip>
        <ProductChip
          onClick={() => updateProductType(PRODUCT_TAX, true)}
          isActive={Boolean(isTaxActive)}
          onClose={() => updateProductType(PRODUCT_TAX, null)}
          icon={<img src={SmallTax} alt="SmallTax" />}
        >
          Tax
        </ProductChip>
        <ProductChip
          onClick={() => updateProductType(PRODUCT_MAILROOM, true)}
          isActive={Boolean(isMailroomActive)}
          onClose={() => updateProductType(PRODUCT_MAILROOM, null)}
          icon={<img src={SmalMailroom} alt="SmalMailroom" />}
        >
          Mailroom
        </ProductChip>
        <ProductChip
          onClick={() => updateProductType(PRODUCT_SALESTAX, true)}
          isActive={Boolean(isSalesTaxActive)}
          onClose={() => updateProductType(PRODUCT_SALESTAX, null)}
          icon={<img src={SmallSalesTax} alt="SmallSalesTax" />}
        >
          Sales Tax
        </ProductChip>
        <ProductChip
          onClick={() => updateProductType(PRODUCT_INTRAGROUP, true)}
          isActive={Boolean(isIntragroupActive)}
          onClose={() => updateProductType(PRODUCT_INTRAGROUP, null)}
          icon={<img src={SmallIntragroup} alt="SmallIntragroup" />}
        >
          Intragroup
        </ProductChip>
      </div>

      <ProductBody />
      <Switch>
        <Route exact path={`${path}/add-to-cart/:productId`}>
          <AddProductToCart
            onClose={() => history.push(link(url + queryParams))}
          />
        </Route>
        <Route path={`${path}/cart`}>
          <CartModal
            open
            onClose={() => history.push(link(url + queryParams))}
          />
        </Route>
        <Route exact path={`${path}/payment-pending`}>
          <PayDebt
            isOpen={true}
            onClose={() => history.push(link(url + queryParams))}
          />
        </Route>
      </Switch>
    </div>
  );
};
