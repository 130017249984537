import { Avatar } from "components/DesignSystem/AvatarGroup/Avatar";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import Radio from "components/DesignSystem/RadioGroup/RadioGroup";
import Table from "components/DesignSystem/Table/V2/Table";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import ConditionalToolTip from "components/design/conditionalToolTip";
import Loader from "components/design/loader";
import { BankLogo } from "components/icons/BankLogo";
import { DD_MMM_YYYY, YYYY_MM_DD } from "constants/date";
import dayjs from "dayjs";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { MerchantComponent } from "components/Transaction/MerchantSelector";
import TransactionColumn, {
  SourceTransaction,
} from "components/Transaction/TransactionColumn";
import { useMemo, useState } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import {
  useGetAllAccountQuery,
  useGetAllTransactionsDataQuery,
} from "store/apis/transactions";
import { Transaction, Transactions } from "types/Models/books";
import { ModalProps } from "types/utils/modal";
import classNames from "classnames";
import { Search } from "components/DesignSystem/Search/Search";

export const SelectedRow = ({
  from,
  logo,
  merchant,
  amount,
  date,
  category,
}: Pick<
  Transactions["transaction"],
  "amount" | "from" | "logo" | "merchant" | "date"
> & { category?: string }) => {
  return (
    <Table.Container className="t-h-full t-mt-3">
      <Table.Content>
        <Table.Body>
          <Table.Row className="all:unset t-flex !t-border  !t-border-neutral-0 !t-rounded !t-w-full !t-px-3 t-items-center">
            <Table.Cell style={{ width: "15%" }}>
              <div className="t-text-subtext t-text-text-30">
                {dayjs(date).format(DD_MMM_YYYY)}
              </div>
            </Table.Cell>
            <Table.Cell style={{ width: "15%" }}>
              <ConditionalToolTip
                condition={
                  from &&
                  `${from?.bank_account.nickname} ${from.bank_account.mask}`
                }
              >
                <div className="t-w-full t-flex t-justify-center">
                  {from?.bank_account.bank_brand?.logo_url ? (
                    <Avatar
                      src={from.bank_account.bank_brand?.logo_url}
                      alt={"Bank"}
                    />
                  ) : (
                    <BankLogo />
                  )}
                </div>
              </ConditionalToolTip>
            </Table.Cell>
            <Table.Cell style={{ width: "25%" }}>
              {logo || merchant ? (
                <MerchantComponent logo={logo} name={merchant || ""} />
              ) : (
                "-"
              )}
            </Table.Cell>
            <Table.Cell style={{ width: "25%" }}>
              <div>{category || "-"}</div>
            </Table.Cell>
            <Table.Cell style={{ width: "20%" }}>
              <div className="t-flex t-justify-end">
                {amount ? <AmountSuperScript amount={amount} /> : "-"}
              </div>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table.Content>
    </Table.Container>
  );
};

export const LinkTransaction = ({
  isLinkTransactionOpen,
  closeLinkTransaction,
  sourceTransaction,
  setSourceTransaction,
  onSelect,
  isUpdating,
  excludeTransactionIds,
}: {
  isLinkTransactionOpen: boolean;
  closeLinkTransaction: () => void;
  sourceTransaction: SourceTransaction;
  setSourceTransaction: (
    newState: SourceTransaction | undefined | null
  ) => void;
  onSelect: (transaction: Transaction) => Promise<void>;
  isUpdating?: boolean;
  excludeTransactionIds?: string[];
}) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const [toLinkTransactionId, setToLinkTransactionId] = useState<string>();
  const [toLinkRowData, setToLinkRowData] = useState<Transactions>();
  const { isOpen, close, open } = useModal();
  const [search, setSearch] = useState("");

  const { transactionInfo, category } = sourceTransaction || {};

  const { data: froms = [] } = useGetAllAccountQuery(
    {
      groupId,
      entityId,
    },
    {
      skip: !groupId || !entityId,
    }
  );

  const fromIds = froms
    .filter(({ uuid }) => uuid !== transactionInfo.from?.bank_account.uuid!)
    .map(({ uuid }) => uuid);

  let cashFlowValue = "";
  const amount = Math.abs(transactionInfo.amount!).toString();

  const isSuggested = category?.categoryType === "SUGGESTED";

  if (
    category?.categoryType === "BANK_TRANSFER" ||
    (isSuggested && category.data.includes("Bank Transfer"))
  ) {
    if (transactionInfo && transactionInfo.amount > 0) {
      cashFlowValue = "DEBIT";
    } else {
      cashFlowValue = "CREDIT";
    }
  } else if (
    category?.categoryType === "PAY_DOWN_CREDIT" ||
    (isSuggested && category.data.includes("Pay Down Credit"))
  ) {
    if (transactionInfo && transactionInfo.amount >= 0) {
      cashFlowValue = "CREDIT";
    } else {
      cashFlowValue = "DEBIT";
    }
  }

  const filters = {
    startDate: {
      name: "Since",
      value:
        dayjs(transactionInfo.date.toString())
          .subtract(10, "days")
          .format(YYYY_MM_DD)
          .toString() || "",
      type: "startDate",
    },
    endDate: {
      name: "Till",
      value:
        dayjs(transactionInfo.date.toString())
          .add(10, "days")
          .format(YYYY_MM_DD)
          .toString() || "",
      type: "endDate",
    },
    fromIds: { name: "Source", type: "fromIds", value: fromIds },
    cashFlow: {
      name: "Cash Flow",
      value: cashFlowValue,
      type: "amount",
    },
  };

  const { data: transactionData, isLoading } = useGetAllTransactionsDataQuery(
    {
      groupId,
      entityId,
      searchTerm: search,
      minAmount: amount,
      maxAmount: amount,
      ...filters,
    },
    { skip: !groupId || !entityId, refetchOnMountOrArgChange: true }
  );

  const { transactions: transactionResult = [] } = transactionData || {};

  const transactions = useMemo(() => {
    return transactionResult.filter(
      ({ transaction }) =>
        !excludeTransactionIds?.includes(transaction.uuid) &&
        transaction.uuid !== transactionInfo.uuid
    );
  }, [
    transactionResult,
    JSON.stringify(excludeTransactionIds),
    transactionInfo.uuid,
  ]);

  const isEmpty = transactions.length === 0;

  const columnHelper = createColumnHelper<Transactions>();

  const columns = [
    columnHelper.accessor("transaction.date", {
      id: "date",
      size: 10,
      header: "Date",
      cell: (info) => (
        <div className="t-text-subtext t-text-text-30">
          {dayjs(info.getValue()).format(DD_MMM_YYYY)}
        </div>
      ),
    }),

    columnHelper.accessor("transaction.from", {
      id: "logo",
      size: 12,
      header: () => (
        <div className="t-flex t-justify-center t-w-full">Source</div>
      ),
      cell: (info) => {
        const transaction = info.row.original.transaction;

        return <TransactionColumn.Source transaction={transaction} />;
      },
    }),

    columnHelper.accessor("transaction.merchant", {
      id: "merchant",
      size: 30,
      header: "Vendor",
      cell: ({
        row: {
          original: {
            transaction: { merchant, logo },
          },
        },
      }) => (
        <>
          {merchant || logo ? (
            <MerchantComponent logo={logo} name={merchant || ""} />
          ) : (
            "-"
          )}
        </>
      ),
    }),

    columnHelper.accessor("transaction.category", {
      id: "category",
      size: 35,
      header: "Category",
      cell: ({
        row: {
          original: {
            transaction: { category },
          },
        },
      }) => {
        return <div>{category?.name ? category?.name : "-"}</div>;
      },
    }),

    columnHelper.accessor("transaction.amount", {
      id: "amount",
      size: 12,
      header: () => <div className="t-flex t-justify-end t-mr-10">Amount</div>,
      cell: (info) => {
        const amount = info.getValue();
        const {
          transaction: { is_credit_card },
        } = info.row.original || {};

        return (
          <TransactionColumn.Amount
            amount={amount}
            isCreditCard={is_credit_card}
          />
        );
      },
    }),
  ];

  const onUpdateCategory = async () => {
    close();

    if (toLinkRowData?.transaction.uuid) {
      try {
        await onSelect(toLinkRowData?.transaction);
        setSourceTransaction(null);
      } catch (error) {}
    }
  };

  const onClickHandle = () => {
    if (toLinkRowData?.transaction?.category?.name) {
      return open();
    }
    return onUpdateCategory();
  };

  const onValueChange = (id: string) => {
    setToLinkTransactionId(id);
    setToLinkRowData(() =>
      transactions.find(({ transaction }) => id === transaction?.uuid)
    );
  };

  const table = useReactTable({
    data: transactions,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div>
      <Modal.Root
        open={isLinkTransactionOpen}
        onOpenChange={closeLinkTransaction}
      >
        <Modal.Content size="xxl">
          <Modal.Header>
            <div className="t-flex t-flex-col t-gap-1">
              <Modal.Title>Link Transaction</Modal.Title>
              <Modal.Subtitle>Choose a Transaction to link with</Modal.Subtitle>
            </div>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            <div className="t-flex t-flex-col t-gap-8">
              <div className="t-w-full">
                <div className="t-text-subtext t-text-text-30">
                  Selected transaction:
                </div>

                <SelectedRow
                  {...sourceTransaction.transactionInfo}
                  category={sourceTransaction.category.categoryName}
                />
              </div>
              <div className="t-flex t-flex-col t-gap-3">
                <div className="t-text-h5 t-text-text-30">
                  Choose a transaction
                </div>
                <Search onChange={(e) => setSearch(e.target.value)} />
                {isLoading ? (
                  <Loader />
                ) : (
                  <>
                    {isEmpty ? (
                      <div className="t-w-full t-h-[200px] t-flex t-flex-col t-justify-center t-items-center">
                        No transactions found
                      </div>
                    ) : (
                      <Table.Container layout="flex">
                        <Table.Content>
                          <Table.Head>
                            {table.getHeaderGroups().map((headerGroup) => (
                              <Table.Row
                                key={headerGroup.id}
                                className="t-pl-10"
                              >
                                {headerGroup.headers.map((header) => (
                                  <Table.HeadCell
                                    key={header.id}
                                    style={{ width: `${header.getSize()}%` }}
                                  >
                                    {header.isPlaceholder
                                      ? null
                                      : flexRender(
                                          header.column.columnDef.header,
                                          header.getContext()
                                        )}
                                  </Table.HeadCell>
                                ))}
                              </Table.Row>
                            ))}
                          </Table.Head>
                          <Table.Body>
                            <Radio.Root onValueChange={onValueChange} asChild>
                              <Radio.Content className="!t-w-full t-flex t-flex-col t-h-full">
                                {table.getRowModel().rows.map((row) => (
                                  <Radio.Item
                                    asChild
                                    value={row.original.transaction.uuid}
                                    className="!t-h-12"
                                    key={row.id}
                                  >
                                    <Table.Row
                                      key={row.id}
                                      className={classNames(
                                        "hover:t-bg-surface-lighter-grey t-cursor-pointer t-w-full t-h-full",
                                        {
                                          "t-bg-surface-lighter-grey":
                                            toLinkTransactionId ===
                                            row?.original?.transaction?.uuid,
                                        }
                                      )}
                                      onClick={() =>
                                        setToLinkRowData(row.original)
                                      }
                                    >
                                      {row.getVisibleCells().map((cell) => {
                                        return (
                                          <Table.Cell
                                            key={cell.id}
                                            style={{
                                              width: `${cell.column.getSize()}%`,
                                            }}
                                          >
                                            {flexRender(
                                              cell.column.columnDef.cell,
                                              cell.getContext()
                                            )}
                                          </Table.Cell>
                                        );
                                      })}
                                    </Table.Row>
                                  </Radio.Item>
                                ))}
                              </Radio.Content>
                            </Radio.Root>
                          </Table.Body>
                        </Table.Content>
                      </Table.Container>
                    )}
                  </>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="t-flex t-justify-end t-gap-3">
            <Button onClick={closeLinkTransaction}>Cancel</Button>
            <Button
              customType="primary"
              onClick={onClickHandle}
              disabled={!toLinkTransactionId || isUpdating}
              isLoading={isUpdating}
            >
              Next
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Root>
      {isOpen && toLinkRowData?.transaction?.category?.name && (
        <ConfirmationModal
          isOpen={isOpen}
          close={close}
          toLinkTransactionId={toLinkTransactionId!}
          toCategory={sourceTransaction?.category.data!}
          onUpdateCategory={onUpdateCategory}
        />
      )}
    </div>
  );
};

const ConfirmationModal = ({
  isOpen,
  close,
  toLinkTransactionId,
  toCategory,
  onUpdateCategory,
}: {
  toLinkTransactionId: string;
  toCategory: string;
  onUpdateCategory: () => {};
} & ModalProps) => {
  return (
    <div>
      <Modal.Root open={isOpen} onOpenChange={close}>
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>Are you sure?</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            This will change the existing category to “{toCategory}” for the
            selected transaction.
          </Modal.Body>
          <Modal.Footer className="t-flex t-justify-end t-gap-3">
            <Button onClick={close}>Go back</Button>
            <Button customType="success" onClick={onUpdateCategory}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Root>
    </div>
  );
};
