import Loader from "components/design/loader";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useQuery } from "hooks/useQuery";
import React from "react";
import { useGetFederalRegistrationsQuery } from "store/apis/registrations";
import { FederalRegistration } from "./FederalRegistration";

export const FederalRegistrations = () => {
  const { uuid: groupId } = useCurrentGroupContext();
  const query = useQuery();
  let entityId = query.get("entity");
  const {
    data: federalRegistrations,
    isLoading,
    isSuccess,
  } = useGetFederalRegistrationsQuery(
    { groupId: groupId!, entityId: entityId! },
    { skip: !entityId || !groupId, refetchOnMountOrArgChange: true }
  );

  if (isLoading || !isSuccess) {
    return <Loader />;
  }

  return (
    <div>
      <div className="t-text-subtitle t-text-text-100 t-mb-5">
        Federal Registrations
      </div>
      <div className="t-flex t-gap-3 t-flex-wrap">
        {federalRegistrations?.map((registrationDetail) => (
          <FederalRegistration
            key={registrationDetail.uuid}
            registrationDetail={registrationDetail}
          />
        ))}
      </div>
    </div>
  );
};
