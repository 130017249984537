import * as Yup from "yup";

interface EntityDataAttributeWithFieldKey {
  field_key: string;
  label: string;
  type: string;
}

const mapFieldType = (
  entityFieldType: string
): "input" | "fileinput" | null => {
  switch (entityFieldType) {
    case "INPUT":
    case "STRING":
      return "input";
    case "MULTI_FILE_INPUT":
    case "FILE":
      return "fileinput";
    default:
      return null;
  }
};

export const buildValidationSchema = (
  fields: EntityDataAttributeWithFieldKey[]
) => {
  const schemaShape: Record<string, Yup.AnySchema> = {};

  fields.forEach((field) => {
    const mappedType = mapFieldType(field.type);

    if (mappedType === "input") {
      schemaShape[field.field_key] = Yup.string()
        .nullable()
        .required(`${field.label} is required`);
    } else if (mappedType === "fileinput") {
      schemaShape[field.field_key] = Yup.array()
        .nullable()
        .min(1, `${field.label} requires at least one file`)
        .required(`${field.label} is required`);
    }
  });

  return Yup.object().shape(schemaShape);
};
