import { ChatPane } from "components/ChatPane/ChatPane";
import { CheckoutModal } from "components/CheckoutModal/CheckoutModal";
import { ConditionalLink } from "components/conditionalLink";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import Loader from "components/design/loader";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import { Slider } from "components/DesignSystem/Slider/Slider";
import { SliderAccordion } from "components/DesignSystem/SliderAccordion/SliderAccordion";
import Tab from "components/DesignSystem/Tab/Tab";
import { Label } from "components/DesignSystem/TextInput/TextInput";
import { Cross } from "components/icons/Cross";
import { LoadingIcon } from "components/icons/LoadingIcon";
import { TicketUnresolved } from "components/icons/TicketUnresolved";
import { MessageBubbleUI } from "components/MessageBubble/MessageBubble";
import { Preview } from "components/PreviewModal";
import { DD_MMM_YYYY } from "constants/date";
import { PAYMENT_PAID } from "constants/ticket";
import dayjs from "dayjs";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useGetMessage } from "hooks/useGetMessage";
import { useModal } from "hooks/useModal";
import { usePaginatedQuery } from "hooks/usePaginatedQuery";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { Children, ReactNode, useEffect, useId, useRef, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ClockUpdate from "static/images/ClockUpdate.svg";
import FilePdf from "static/images/FilePdf.svg";
import TicketResolvedIcon from "static/images/TicketResolved.svg";
import VerticalLine from "static/images/VerticalLine.svg";
import {
  ticketApis,
  useGetTicketByIdQuery,
  useLazyGetTicketNotesQuery,
} from "store/apis/chatTicket";
import {
  setSliderAccordionValue,
  setTicketSliderTab,
} from "store/slices/ticket";
import { RootState } from "store/store";
import { BillingInvoice } from "types/Models/billing";
import { TicketNotes, TicketPayment } from "types/Models/chatTicket";
import { formatDate, formatTime } from "utils/formatDate";

export const useSliderTicket = () => {
  const { uuid: groupId } = useCurrentGroupContext();
  const query = useQuery();
  const previewTicketId = query.get("ticketUuid") || "";

  return useGetTicketByIdQuery(
    { groupId, ticketId: previewTicketId! },
    { skip: !groupId || !previewTicketId }
  );
};

// TODO: Use InfoItem.tsx instead
export const InfoItem = ({
  label,
  children,
}: {
  label: string | ReactNode;
  children: ReactNode;
}) => {
  return (
    <div className="t-flex t-flex-col t-gap-1">
      <Label className="!t-pb-0">{label}</Label>
      <span className="t-text-subtext">{children}</span>
    </div>
  );
};

const Header = () => {
  const { data } = useSliderTicket();
  const { name } = data || {};
  const { update } = useUpdateQuery();
  const dispatch = useDispatch();

  const close = () => {
    update({ query: "ticketUuid", value: null });
    dispatch(setSliderAccordionValue(""));
    dispatch(setTicketSliderTab(""));
  };

  return (
    <div className="t-w-full t-px-5 t-pt-3 t-border t-border-solid t-border-b t-border-l-0 t-border-t-0 t-border-r-0 t-border-neutral-0 t-bg-surface t-flex t-flex-col t-sticky t-top-0 t-z-header">
      <div className="t-flex t-justify-between">
        <div className="t-flex t-gap-2 t-items-center t-text-subtitle-sm">
          {name}
        </div>
        <Button customType="ghost_icon" size="small" onClick={close}>
          <Cross color="currentColor" />
        </Button>
      </div>
      <Tab.List>
        <Tab.Trigger value="DETAILS">Details</Tab.Trigger>
        <Tab.Trigger value="CHAT">Chat</Tab.Trigger>
      </Tab.List>
    </div>
  );
};

const Invoices = ({ invoice }: { invoice: BillingInvoice }) => {
  const { amount, status, document, name } = invoice;
  const { open, close, isOpen } = useModal();

  return (
    <div className="t-flex t-justify-between t-items-center">
      <span className="t-flex t-gap-2 t-items-center">
        {status === PAYMENT_PAID ? (
          <div>
            <img src={TicketResolvedIcon} alt="TicketResolvedIcon" />
          </div>
        ) : (
          <div className="t-text-yellow-50">
            <TicketUnresolved />
          </div>
        )}
        <span className="t-flex t-flex-col">
          <span className="t-text-body-sm t-text-text-60">{name}</span>
          <span className="t-text-body-sm t-text-text-30">
            {status === PAYMENT_PAID ? "Payment done: " : "Payment pending: "}
            <AmountSuperScript amount={Number(amount)} />
          </span>
        </span>
      </span>
      <Button customType="ghost_icon" size="small" onClick={open}>
        <img src={FilePdf} alt="PdfIcon" />
      </Button>

      {document?.uuid && (
        <Preview
          showModal={isOpen}
          closeModal={close}
          previewId={document.uuid}
        />
      )}
    </div>
  );
};

const Note = ({ note }: { note: TicketNotes }) => {
  const { created_at, created_by, description } = note;

  return (
    <span className="t-flex t-flex-col t-gap-1">
      <span className="t-text-body-sm t-text-text-60">{description}</span>
      <span className="t-text-body-sm t-text-text-30">
        {created_by.name}
        {formatDate(created_at)} at {formatTime(created_at)}
      </span>
    </span>
  );
};

const UpdatedWrapper = ({ children }: { children: ReactNode }) => {
  const childrenLength = Children.count(children);

  return (
    <span className="t-flex t-flex-col t-gap-2.5 t-pt-2">
      {Children.map(children, (child, index) => {
        if (index !== childrenLength - 1) {
          return (
            <span className="t-flex t-gap-2 t-h-full t-self-start">
              <span className="t-flex t-flex-col t-h-full t-gap-1">
                <img src={ClockUpdate} alt="Clock" className="t-w-5 t-h-5" />
                <img
                  src={VerticalLine}
                  alt="line"
                  className="t-ml-2 t-w-0.5  t-h-full"
                />
              </span>

              {child}
            </span>
          );
        } else {
          return (
            <span className="t-flex t-gap-2 t-h-full t-self-start">
              <img
                src={ClockUpdate}
                alt="Clock Update Icon"
                className="t-w-5 t-h-5"
              />

              {child}
            </span>
          );
        }
      })}
    </span>
  );
};

const Updates = () => {
  const updates = useRef<HTMLDivElement>(null);
  const infiniteScrollId = useId();
  const { uuid: groupId } = useCurrentGroupContext();
  const query = useQuery();
  const previewTicketId = query.get("ticketUuid") || "";

  const {
    data: tickets,
    loadNext,
    isLoading,
  } = usePaginatedQuery<{
    notes: TicketNotes[];
  }>(useLazyGetTicketNotesQuery, "notes", {
    groupId,
    ticketId: previewTicketId || "",
  });

  const { notes = [], total_pages = 1, current_page = 1 } = tickets || {};

  const sliderAccordionValue = useSelector(
    (state: RootState) => state.ticket.sliderAccordionValue
  );

  useEffect(() => {
    if (sliderAccordionValue === "UPDATES") {
      updates?.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [sliderAccordionValue]);

  if (isLoading) {
    return <Loader size="small" />;
  }

  return (
    <div
      className="t-overflow-y-auto t-max-h-80"
      id={infiniteScrollId}
      ref={updates}
    >
      <InfiniteScroll
        dataLength={notes?.length || 0}
        next={loadNext}
        hasMore={total_pages > current_page}
        scrollableTarget={infiniteScrollId}
        className="t-space-y-5"
        loader={
          <div className="t-flex t-justify-center t-items-center t-w-full t-gap-2 t-p-2">
            <span className="t-flex t-origin-center t-animate-spin">
              <LoadingIcon />
            </span>
            Loading...
          </div>
        }
      >
        <UpdatedWrapper>
          {notes.map((note) => (
            <Note note={note} key={note.uuid} />
          ))}
        </UpdatedWrapper>
      </InfiniteScroll>
    </div>
  );
};

const RelatedChat = () => {
  const { search } = useLocation();
  const { secondary_channel_url } = useCurrentGroupContext();
  const { data } = useSliderTicket();
  const { channel_url, message_id, channel_name } = data || {};
  const message = useGetMessage(message_id!);
  const channelUrl = channel_url || secondary_channel_url;
  const channelName = channel_name || "General Help";

  return (
    <InfoItem label="Channel">
      <span className="t-grid t-gap-4 t-grid-cols-1 t-grid-flow-row">
        <ConditionalLink to={`/chat/${channelUrl}${search}`}>
          <Button customType="link" size="small">
            {channelName}
          </Button>
        </ConditionalLink>
        {message_id && Boolean(message) && (
          <>
            <div className="t-bg-i-surface-grey t-rounded t-p-1">
              <MessageBubbleUI
                hideOptions
                message={message!}
                otherData={{
                  sentByTheUser: false,
                }}
                jumpToMessage={() => {}}
              />
            </div>
            <div>
              <ConditionalLink to={`chat/${channelUrl}/${message_id}${search}`}>
                <Button size="small">Go to chat</Button>
              </ConditionalLink>
            </div>
          </>
        )}
      </span>
    </InfoItem>
  );
};

const DetailsTab = () => {
  const { data } = useSliderTicket();

  const sliderAccordionValue = useSelector(
    (state: RootState) => state.ticket.sliderAccordionValue
  );

  const {
    created_on,
    resolved_at,
    resolved,
    ticket_title,
    entity,
    ticket_payments,
    does_contain_notes,
  } = data || {};

  return (
    <div className="t-p-5 t-flex t-gap-4 t-flex-col t-h-full">
      <SliderAccordion.Root
        type="multiple"
        defaultValue={["TICKET_DETAILS", "RELATED_CHAT", "INVOICES", "UPDATES"]}
        className="t-flex t-gap-4 t-flex-col"
      >
        <SliderAccordion.Item value="TICKET_DETAILS">
          <SliderAccordion.Trigger>Ticket Details</SliderAccordion.Trigger>
          <SliderAccordion.Content>
            <span className="t-grid t-gap-6 t-grid-cols-1 t-grid-flow-row">
              <InfoItem label="Title">
                <div className="t-break-words">{ticket_title}</div>
              </InfoItem>
              <span className="t-grid t-gap-6 t-grid-cols-2">
                {resolved ? (
                  <>
                    <InfoItem label="Created on">
                      {dayjs(created_on).format(DD_MMM_YYYY)}
                    </InfoItem>
                    <InfoItem label="Resolved on">
                      {dayjs(resolved_at).format(DD_MMM_YYYY)}
                    </InfoItem>
                  </>
                ) : (
                  <InfoItem label="Created on">
                    {dayjs(created_on).format(DD_MMM_YYYY)}
                  </InfoItem>
                )}

                {entity && (
                  <InfoItem label="Entity">
                    <ConditionalLink
                      target="_blank"
                      to={`/entities/entity/${entity.uuid}`}
                    >
                      <div className="t-flex t-items-center t-gap-1">
                        <ReactCountryFlag
                          style={{
                            width: "14px",
                            height: "10px",
                            marginBottom: "3px",
                          }}
                          countryCode={entity?.code_alpha_2}
                          svg
                        />
                        <span className="t-break-words t-max-w-40 t-ml-1">
                          {entity?.name}
                        </span>
                      </div>
                    </ConditionalLink>
                  </InfoItem>
                )}
              </span>
            </span>
          </SliderAccordion.Content>
        </SliderAccordion.Item>

        <SliderAccordion.Item value="RELATED_CHAT">
          <SliderAccordion.Trigger>Related Chat</SliderAccordion.Trigger>
          <SliderAccordion.Content>
            <RelatedChat />
          </SliderAccordion.Content>
        </SliderAccordion.Item>

        {ticket_payments && ticket_payments.length > 0 && (
          <SliderAccordion.Item value="INVOICES">
            <SliderAccordion.Trigger>Invoices</SliderAccordion.Trigger>
            <SliderAccordion.Content className="t-overflow-auto t-max-h-[280px]">
              <span className="t-gap-4 t-flex t-flex-col">
                {ticket_payments
                  .filter(
                    (ticket_payment: TicketPayment) =>
                      ticket_payment.is_price_finalized === true &&
                      ticket_payment.ticket_invoice
                  )
                  .map((ticket_payment: TicketPayment) => {
                    return (
                      <Invoices
                        invoice={ticket_payment.ticket_invoice!}
                        key={ticket_payment.ticket_invoice!.uuid}
                      />
                    );
                  })}
              </span>
            </SliderAccordion.Content>
          </SliderAccordion.Item>
        )}

        <div className="t-mb-20">
          {does_contain_notes && (
            <SliderAccordion.Item
              value="UPDATES"
              isActive={sliderAccordionValue === "UPDATES"}
            >
              <SliderAccordion.Trigger>Updates</SliderAccordion.Trigger>
              <SliderAccordion.Content>
                <Updates />
              </SliderAccordion.Content>
            </SliderAccordion.Item>
          )}
        </div>
      </SliderAccordion.Root>
    </div>
  );
};

export const ChatTab = ({
  channelId: channelIdFromProps,
  messageId,
}: { channelId?: string | null; messageId?: string } = {}) => {
  const { data } = useSliderTicket();
  const { channel_url, message_id } = data || {};
  const { secondary_channel_url } = useCurrentGroupContext();
  const [channelId, setChannelId] = useState<string | null>(
    channelIdFromProps || channel_url || secondary_channel_url
  );

  return (
    <div className="t-h-full t-shrink-0 t-grow-0 t-sticky t-top-0">
      <ChatPane
        channelId={channelId}
        setChannelId={setChannelId}
        messageId={messageId || message_id || undefined}
      />
    </div>
  );
};

const SliderBody = () => {
  const { close, isOpen, open: onPay } = useModal();
  const { isLoading, isSuccess, data } = useSliderTicket();
  const { ticket_payments, total_unpaid_invoice_amount, entity } = data || {};
  const defaultTab = useSelector((store: RootState) => store.ticket.sliderTab);
  const dispatch = useAppDispatch();

  const unpaid_invoices =
    ticket_payments && ticket_payments.length > 0
      ? ticket_payments
          .filter(
            (ticket_payment) =>
              !ticket_payment.is_ticket_paid &&
              ticket_payment.is_price_finalized &&
              ticket_payment.ticket_invoice
          )
          .map(({ ticket_invoice }) => {
            return ticket_invoice;
          })
      : [];

  const onInvoicePaid = () => {
    dispatch(ticketApis.util.invalidateTags(["CustomerChatTickets"]));
  };

  return (
    <Async.Root {...{ isEmpty: false, isLoading, isSuccess }}>
      <Async.Empty>
        <></>
      </Async.Empty>
      <Async.Success>
        <Tab.Root defaultValue={defaultTab || "DETAILS"} className="t-h-full">
          <div className="t-flex t-flex-col t-h-full">
            <Header />
            <Tab.Content value="DETAILS" className="t-h-full">
              <DetailsTab />
              {Number(total_unpaid_invoice_amount) > 0 && (
                <>
                  <div className="t-fixed t-bottom-0 t-px-5 t-py-3 t-bg-surface t-border t-border-solid t-border-b-0 t-border-l-0 t-border-t t-border-r-0 t-border-neutral-0 t-w-[480px]">
                    <Button customType="primary" block onClick={onPay}>
                      <span className="t-mr-1">Pay</span>
                      <AmountSuperScript
                        amount={Number(total_unpaid_invoice_amount)}
                      />
                    </Button>
                  </div>
                  {unpaid_invoices!.length > 0 && (
                    <CheckoutModal
                      entityId={entity?.uuid}
                      type="invoices"
                      onClose={close}
                      open={isOpen}
                      // @ts-ignore
                      invoices={unpaid_invoices}
                      onInvoicePaid={onInvoicePaid}
                    />
                  )}
                </>
              )}
            </Tab.Content>
            <Tab.Content value="CHAT" className="t-h-full t-relative">
              <ChatTab />
            </Tab.Content>
          </div>
        </Tab.Root>
      </Async.Success>
    </Async.Root>
  );
};

export const TicketSlider = () => {
  const query = useQuery();
  const previewTicketId = query.get("ticketUuid");
  const { update } = useUpdateQuery();
  const dispatch = useDispatch();

  const close = () => {
    update({ query: "ticketUuid", value: null });
    dispatch(setSliderAccordionValue(""));
    dispatch(setTicketSliderTab(""));
  };

  return (
    <Slider
      open={Boolean(previewTicketId)}
      onClose={close}
      withOverlay
      type="fixed"
    >
      <SliderBody key={previewTicketId} />
    </Slider>
  );
};
