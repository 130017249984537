import React, { useMemo } from "react";
import CommonTable from "../design/commonTable";
import AcceptedChecks from "../icons/AcceptedChecks";
import { getDate, getTime } from "../../utils/formatDayJs";
import Modal from "components/DesignSystem/Modal/Modal";

const TaskLogModal = ({ closeModal, taskLogs }) => {
  const columns = useMemo(
    () => [
      {
        Header: "SCOPE OF WORK",
        accessor: "scopeOfWork",
        width: "50%",
        Cell: ({
          row: {
            original: { scope_of_work, created_at },
          },
        }) => (
          <>
            <span className="logScopeOfWork">{scope_of_work}</span>
            <br />
            <span className="logCreatedAt">
              {getDate(created_at)} | {getTime(created_at)}
            </span>
          </>
        ),
      },
      {
        Header: "PRICE",
        accessor: "price",
        width: "20%",
        textAlign: "right",
        Cell: ({
          row: {
            original: { price },
          },
        }) => <span className="logScopeOfWork">${price}</span>,
      },
      {
        Header: "Acceptance",
        accessor: "accepted",
        width: "30%",
        textAlign: "center",
        Cell: ({
          row: {
            original: { has_customer_approved },
          },
        }) => (
          <span className="logScopeOfWork">
            {has_customer_approved ? (
              <>
                <AcceptedChecks />
                &nbsp; Accepted
              </>
            ) : (
              "- -"
            )}
          </span>
        ),
      },
    ],
    []
  );

  const data = useMemo(() => taskLogs, [taskLogs]);

  return (
    <Modal.Root open onOpenChange={closeModal}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Log</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <CommonTable
            tableData={data}
            tableDataDependencies={[data]}
            tableColumns={columns}
            tableColumnsDependencies={[columns]}
            headerBg="transparent"
          />
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};

export default TaskLogModal;
