import { DashboardLayout } from "components/DashboardLayout";
import { Header } from "components/DesignSystem/Header/Header";
import Tab from "components/DesignSystem/Tab/Tab";
import { parse, stringify } from "qs";
import React from "react";
import {
  matchPath,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { Customers } from "../Customers/Customers";
import { Vendors } from "../Vendors/Vendors";

export const CustomersAndVendors = () => {
  const { path, url } = useRouteMatch();
  const { pathname } = useLocation();
  const location = useLocation();
  const parsedSearch = parse(location.search, { ignoreQueryPrefix: true });

  const search = stringify(
    {
      entity: parsedSearch.entity,
      company: parsedSearch.company,
    },
    { skipNulls: true, addQueryPrefix: true }
  );

  return (
    <Tab.Root asChild defaultValue={pathname} value="MATCH">
      <DashboardLayout
        header={
          <div className="t-sticky t-top-0">
            <Header
              v2
              title="Vendors & Customers"
              bottom={
                <Tab.List>
                  <Tab.NavTrigger
                    exact
                    to={`${url}${search}`}
                    value={
                      matchPath(pathname, { path: url, exact: true })
                        ? "MATCH"
                        : ""
                    }
                  >
                    Vendors
                  </Tab.NavTrigger>
                  <Tab.NavTrigger
                    exact
                    to={`${url}/customers${search}`}
                    value={
                      matchPath(pathname, { path: `${url}/customers` })
                        ? "MATCH"
                        : ""
                    }
                  >
                    Customers
                  </Tab.NavTrigger>
                </Tab.List>
              }
            />
          </div>
        }
      >
        <Switch>
          <Route exact path={`${path}/customers`}>
            <Customers />
          </Route>
          <Route path={path}>
            <Vendors />
          </Route>
        </Switch>
      </DashboardLayout>
    </Tab.Root>
  );
};
