import { createSlice, PayloadAction } from "@reduxjs/toolkit";
const initialState = {
  manualTxnInvoiceId: "",
  manualInvoiceDueAmount: 0,
};

export const LinkInkleInvoice = createSlice({
  name: "linkInkleInvoice",
  initialState: initialState,
  reducers: {
    setManualTransactionAdditionInvoice: (
      state,
      action: PayloadAction<{ invoiceId: string; invoiceDueAmount: number }>
    ) => {
      state.manualTxnInvoiceId = action.payload.invoiceId;
      state.manualInvoiceDueAmount = action.payload.invoiceDueAmount;
    },
  },
});

export const { setManualTransactionAdditionInvoice } = LinkInkleInvoice.actions;

export default LinkInkleInvoice.reducer;
