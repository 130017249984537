import { useEffect } from "react";

export const useBeforeunload = () => {
  useEffect(() => {
    const beforeUnloadListener = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      return false;
    };
    window.addEventListener("beforeunload", beforeUnloadListener, {
      capture: true,
    });
    return () =>
      window.removeEventListener("beforeunload", beforeUnloadListener, {
        capture: true,
      });
  }, []);
};
