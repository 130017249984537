import classNames from "classnames";
import { ParentSelector } from "components/ChartOfAccounts/ParentSelector";
import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import Modal from "components/DesignSystem/Modal/Modal";
import Table from "components/DesignSystem/Table/V2/Table";
import { Label, TextInput } from "components/DesignSystem/TextInput/TextInput";
import {
  MerchantComponent,
  MerchantSelector,
} from "components/Transaction/MerchantSelector";
import TransactionColumn, {
  DropdownIndicator,
  TransactionComboboxClearIndicator,
  TransactionComboboxControl,
  coaOptions,
  filterOption,
} from "components/Transaction/TransactionColumn";
import { DD_MMM_YYYY } from "constants/date";
import dayjs from "dayjs";
import { Form, Formik, useFormikContext } from "formik";
import { AnimatePresence } from "framer-motion";
import { useChartOfAccounts } from "hooks/useChartOfAccounts";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { useMemo, useState } from "react";
import {
  CellContext,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import {
  useCreateMerchantMutation,
  useGetMerchantByIdQuery,
} from "store/apis/merchant";
import { useUpdateMultipleTransactionsDataMutation } from "store/apis/transactions";
import { Transactions } from "types/Models/books";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";
import { flattenTypes } from "utils/flattenCOA";

const DescriptionColumn = (info: CellContext<Transactions, string>) => {
  const id = info.row.id;
  return (
    <TextInput
      name={`transactions[${id}].transaction.description`}
      placeholder="Enter description"
    />
  );
};

const VendorColumn = (info: CellContext<Transactions, string>) => {
  const { setFieldValue } = useFormikContext<{
    transactions: Transactions[];
  }>();

  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { alertToast } = useToast();
  const id = info.row.id;
  const merchantId = info.row.original.transaction.merchant_uuid;

  const [createMerchant] = useCreateMerchantMutation();

  const { data, isLoading } = useGetMerchantByIdQuery(
    { groupId, merchantId: merchantId || "" },
    { skip: !groupId || !merchantId }
  );
  const { logo, name = "" } = data || {};

  const selected = merchantId
    ? {
        value: name,
        label: <MerchantComponent logo={logo} name={name} />,
        merchantId: merchantId,
        data: name,
      }
    : null;

  const merchantChange = async (values: any) => {
    setFieldValue(
      `transactions[${id}].transaction.merchant_uuid`,
      values?.merchantId
    );
  };

  const onCreateMerchant = async (name: string) => {
    try {
      const payload = { name, txn_uuid: info.row.original.transaction.uuid };

      await createMerchant({
        groupId,
        entityId,
        payload,
      }).unwrap();
    } catch (error) {
      alertToast({ message: (error as BackendError)?.data?.error?.message });
    }
  };

  return (
    <div className="t-group t-select-none" onClick={(e) => e.stopPropagation()}>
      <MerchantSelector
        merchantChange={merchantChange}
        isLoading={isLoading}
        isDisabled={isLoading}
        withForm
        name={`transactions[${id}].transaction.merchant_uuid`}
        value={selected}
        creatable={true}
        onCreateMerchant={onCreateMerchant}
        customComponent={true}
      />
    </div>
  );
};

const CategoryColumn = ({
  transaction,
  id,
}: {
  transaction: Transactions;
  id: string;
}) => {
  const { values, setFieldValue } = useFormikContext<{
    transactions: Transactions[];
  }>();
  const { isOpen, close, open } = useModal();
  const [categoryName, setCategoryName] = useState<string>("");

  const {
    chartOfAccounts = [],
    refetch,
    isLoading,
  } = useChartOfAccounts({
    hiddenCategoryTypes: ["BANK_ACCOUNT"],
  });

  const selectedCategoryUuid =
    values?.transactions?.[Number(id)]?.transaction?.category?.uuid;

  const assignedCategory = flattenTypes({
    accounts: chartOfAccounts,
  }).find(({ uuid }) => uuid === selectedCategoryUuid);

  const onCreation = async (name: string) => {
    setCategoryName(name);
  };

  const onParentClose = () => {
    setCategoryName("");
    close();
  };

  return (
    <div className="t-group t-select-none" onClick={(e) => e.stopPropagation()}>
      <Combobox
        withForm
        name={`transactions[${id}].transaction.category.uuid`}
        isLoading={isLoading}
        creatable
        onCreateOption={onCreation}
        components={{
          Control: TransactionComboboxControl,
          DropdownIndicator: DropdownIndicator,
          ClearIndicator: TransactionComboboxClearIndicator,
        }}
        size="small"
        placeholder="Select category"
        menuPlacement="auto"
        options={coaOptions([...chartOfAccounts])}
        filterOption={(option, searchedTerm) =>
          filterOption(option, searchedTerm, chartOfAccounts)
        }
        {...{
          value: assignedCategory
            ? {
                label: (
                  <div className="t-flex t-gap-0.5">
                    <div className={classNames("t-truncate t-max-w-[150px]")}>
                      {assignedCategory?.name}
                    </div>
                  </div>
                ),
                value: assignedCategory?.uuid || "",
              }
            : null,
        }}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base) => ({
            ...base,
            width: 400,
          }),
        }}
      />
      <ParentSelector
        categoryName={categoryName}
        isOpen={isOpen || Boolean(categoryName)}
        close={onParentClose}
        open={open}
        onSuccess={({ uuid }) => {
          refetch();
          setFieldValue(`transactions[${id}].transaction.category.uuid`, uuid);
        }}
      />
    </div>
  );
};

const LinkTransactionTable = () => {
  const { values } = useFormikContext<{
    transactions: Transactions[];
  }>();

  const createColumn = createColumnHelper<Transactions>();
  const columns = useMemo(
    () => [
      createColumn.accessor("transaction.date", {
        id: "date",
        size: 15,
        header: "Date",
        cell: (info) => {
          const transaction = info.row.original.transaction;

          return <div>{dayjs(transaction.date).format(DD_MMM_YYYY)}</div>;
        },
      }),

      createColumn.accessor("transaction.from", {
        id: "logo",
        size: 10,
        header: "SOURCE",
        cell: (info) => {
          const transaction = info.row.original.transaction;

          return <TransactionColumn.Source transaction={transaction} />;
        },
      }),

      createColumn.accessor("transaction.merchant", {
        id: "merchant",
        size: 25,
        header: "VENDOR",
        cell: VendorColumn,
      }),

      createColumn.accessor("transaction.category", {
        id: "category",
        size: 35,
        header: () => <Label required>Category</Label>,
        cell: (info) => {
          const transaction: Transactions = info.row.original;
          return <CategoryColumn transaction={transaction} id={info.row.id} />;
        },
      }),

      createColumn.accessor("transaction.description", {
        size: 20,
        header: "DESCRIPTION",
        cell: DescriptionColumn,
      }),

      createColumn.accessor("transaction.amount", {
        size: 15,
        header: () => <div className="t-flex t-justify-end">AMOUNT</div>,
        cell: ({
          row: {
            original: {
              transaction: { amount, is_credit_card },
            },
          },
        }) => (
          <TransactionColumn.Amount
            amount={amount}
            isCreditCard={is_credit_card}
          />
        ),
      }),
    ],
    [values.transactions]
  );

  const table = useReactTable({
    data: values.transactions!,
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 1,
    },
  });

  return (
    <>
      <Table.Container>
        <Table.Content>
          <Table.Head>
            {table.getHeaderGroups().map((headerGroup) => (
              <Table.Row key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Table.HeadCell
                    key={header.id}
                    style={{ width: `${header.getSize()}%` }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </Table.HeadCell>
                ))}
              </Table.Row>
            ))}
          </Table.Head>
          <Table.Body>
            <AnimatePresence>
              {table.getRowModel().rows.map((row) => (
                <Table.Row
                  className="t-px-3 t-border-solid t-border-neutral-0 t-border-b t-border-0 t-text-body"
                  key={row.id}
                >
                  {row.getVisibleCells().map((cell) => (
                    <Table.Cell
                      key={cell.id}
                      style={{ width: `${cell.column.getSize()}%` }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Table.Cell>
                  ))}
                </Table.Row>
              ))}
            </AnimatePresence>
          </Table.Body>
        </Table.Content>
      </Table.Container>
    </>
  );
};

export const LinkTransactionModal = ({
  isOpen,
  close,
  toggle,
  transactions,
}: ModalProps & {
  toggle: () => void;
  transactions?: Transactions[];
}) => {
  const { alertToast, successToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const [updateMultipleTransactionsData, { isLoading }] =
    useUpdateMultipleTransactionsDataMutation();

  const onSubmit = async (values: {
    transactions: Transactions[] | undefined;
  }) => {
    const transactions = values?.transactions?.map(
      ({ transaction: { uuid, category, merchant_uuid, description } }) => {
        return {
          uuid: uuid!,
          category_id: category?.uuid!,
          merchant_id: merchant_uuid,
          description: description,
        };
      }
    );

    try {
      await updateMultipleTransactionsData({
        groupId,
        entityId,
        transactions,
      }).unwrap();
      close();
      successToast({ message: "Transactions updated" });
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={toggle}>
      <Formik
        initialValues={{
          transactions: transactions,
        }}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ submitForm, isSubmitting, isValid, touched, resetForm, dirty }) => {
          return (
            <Form className="t-m-0 t-w-full">
              <Modal.Content size="xxl" useCustomOverlay>
                <Modal.Header>
                  <div>
                    <Modal.Title>Link Transactions</Modal.Title>
                    <Modal.Subtitle>
                      There are some pending transfers as on selected date.
                      Please re-categorize (such as money in transit) to
                      resolve.
                    </Modal.Subtitle>
                  </div>
                  <Modal.Close />
                </Modal.Header>
                <Modal.Body>
                  <LinkTransactionTable />
                </Modal.Body>
                <Modal.FooterButtonGroup>
                  <Button onClick={close} size="small" type="button">
                    Cancel
                  </Button>
                  <Button
                    size="small"
                    customType="primary"
                    type="submit"
                    disabled={!dirty}
                    isLoading={isSubmitting}
                    onClick={submitForm}
                  >
                    Re-categorise
                  </Button>
                </Modal.FooterButtonGroup>
              </Modal.Content>
            </Form>
          );
        }}
      </Formik>
    </Modal.Root>
  );
};
