import React from "react";

export const InkleIntragroupLogo = () => {
  return (
    <svg
      width="196"
      height="25"
      viewBox="0 0 196 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0001 0.00012207H14.0911V13.9536L5.90938 20.0001H9.5457L20.0001 13.9536V0.00012207Z"
        fill="url(#paint0_linear_8954_2035)"
      />
      <path
        d="M-0.00020504 20.0001L5.90881 20.0001L5.90881 6.04664L14.0905 0.000125885L10.4542 0.00012621L-0.000206232 6.04664L-0.00020504 20.0001Z"
        fill="url(#paint1_linear_8954_2035)"
      />
      <path
        d="M29.9998 1.98349C29.9998 0.780024 30.7799 0 32.0056 0C33.2091 0 33.9891 0.780024 33.9891 1.98349C33.9891 3.18696 33.2091 3.98927 32.0056 3.98927C30.7799 3.98927 29.9998 3.18696 29.9998 1.98349ZM33.7885 19.0995H30.2227V5.72761H33.7885V19.0995Z"
        fill="url(#paint2_linear_8954_2035)"
      />
      <path
        d="M44.7288 12.3912C44.7288 10.43 43.7036 9.13743 42.1213 9.13743C40.6281 9.13743 39.536 10.5192 39.536 12.4135V19.0995H35.9702V5.72761H39.536V7.28766C40.0041 6.28477 41.3858 5.5716 42.7676 5.5716C46.1774 5.5716 48.2946 8.29055 48.2946 12.3912V19.0995H44.7288V12.3912Z"
        fill="url(#paint3_linear_8954_2035)"
      />
      <path
        d="M54.0118 19.0995H50.4459V0.156004H54.0118V11.7227L58.5359 5.72761H62.436L57.8896 12.4135L62.904 19.0995H58.3799L54.0118 13.1044V19.0995Z"
        fill="url(#paint4_linear_8954_2035)"
      />
      <path
        d="M67.8047 19.0995H64.2389V0.156004H67.8047V19.0995Z"
        fill="url(#paint5_linear_8954_2035)"
      />
      <path
        d="M78.646 14.9988H82.4347C81.5209 17.4948 79.1363 19.2109 76.4173 19.2109C72.5172 19.2109 69.776 16.3805 69.776 12.369C69.776 8.49112 72.6064 5.54932 76.3505 5.54932C80.0946 5.54932 82.925 8.49112 82.925 12.369C82.925 12.7924 82.9027 13.2381 82.8358 13.6393H73.5201C73.9213 14.9988 75.0133 15.8679 76.3951 15.8679C77.4425 15.8679 78.2671 15.5113 78.646 14.9988ZM73.5201 11.0763H79.1809C78.7797 9.76145 77.7322 8.91456 76.3505 8.91456C74.9687 8.91456 73.9213 9.76145 73.5201 11.0763Z"
        fill="url(#paint6_linear_8954_2035)"
      />
      <path
        d="M185.169 6.93331V9.21327C185.699 7.78829 187.449 6.81117 189.281 6.81117C192.783 6.81117 195.103 9.29469 195.103 13.0607C195.103 16.8063 192.803 19.2899 189.281 19.2899C187.47 19.2899 185.719 18.3127 185.169 16.8674V24.2366H182.971V6.93331H185.169ZM192.925 13.0607C192.925 10.6179 191.317 8.98934 189.017 8.98934C186.737 8.98934 185.149 10.6179 185.169 13.0403C185.169 15.4424 186.778 17.0913 189.037 17.0913C191.337 17.0913 192.925 15.4831 192.925 13.0607Z"
        fill="#41395C"
      />
      <path
        d="M172.564 13.0611C172.564 15.5243 173.826 17.0918 175.78 17.0918C177.592 17.0918 178.895 15.4022 178.895 13.0408V6.93375H181.093V19.1478H178.895V16.8678C178.426 18.2725 177.042 19.2903 175.414 19.2903C172.319 19.2903 170.365 16.8271 170.365 13.0611V6.93375H172.564V13.0611Z"
        fill="#41395C"
      />
      <path
        d="M156.779 13.0206C156.779 9.49888 159.385 6.79143 162.764 6.79143C166.123 6.79143 168.708 9.49888 168.708 13.0206C168.708 16.5627 166.123 19.2701 162.764 19.2701C159.385 19.2701 156.779 16.5627 156.779 13.0206ZM162.764 8.96961C160.626 8.96961 158.977 10.7203 158.977 13.0206C158.977 15.3413 160.626 17.0716 162.764 17.0716C164.901 17.0716 166.53 15.3413 166.53 13.0206C166.53 10.7203 164.901 8.96961 162.764 8.96961Z"
        fill="#41395C"
      />
      <path
        d="M155.819 6.79143V8.96961C152.806 8.94925 151.076 10.5167 151.076 13.2649V19.148H148.898V6.93393H151.076V9.43781C152.053 7.36142 154.129 6.79143 155.819 6.79143Z"
        fill="#41395C"
      />
      <path
        d="M144.73 18.4966V16.868C144.181 18.293 142.43 19.2701 140.679 19.2701C137.28 19.2701 134.898 16.7866 134.898 13.0206C134.898 9.27496 137.28 6.79143 140.679 6.79143C142.45 6.79143 144.201 7.82963 144.73 9.21389V6.93393H146.908V18.4966C146.908 22.2626 144.588 24.7461 141.147 24.7461C138.318 24.7461 136.119 23.1176 135.447 20.1862H137.748C138.318 21.774 139.641 22.5679 141.147 22.5679C143.264 22.5679 144.73 20.9597 144.73 18.4966ZM137.096 13.0206C137.096 15.4634 138.745 17.0716 140.923 17.0716C143.122 17.0716 144.73 15.4634 144.73 13.041C144.73 10.6999 143.122 8.96961 140.923 8.96961C138.745 8.96961 137.096 10.5778 137.096 13.0206Z"
        fill="#41395C"
      />
      <path
        d="M130.659 19.148V16.868C130.109 18.293 128.358 19.2701 126.526 19.2701C123.025 19.2701 120.704 16.7866 120.704 13.0206C120.704 9.27496 123.005 6.79143 126.526 6.79143C128.338 6.79143 130.109 7.76856 130.659 9.21389V6.93393H132.837V19.148H130.659ZM122.903 13.0206C122.903 15.4634 124.491 17.0716 126.791 17.0716C129.071 17.0716 130.659 15.4634 130.659 13.041C130.659 10.6389 129.03 8.96961 126.771 8.96961C124.47 8.96961 122.903 10.5778 122.903 13.0206Z"
        fill="#41395C"
      />
      <path
        d="M119.663 6.79143V8.96961C116.65 8.94925 114.92 10.5167 114.92 13.2649V19.148H112.742V6.93393H114.92V9.43781C115.897 7.36142 117.973 6.79143 119.663 6.79143Z"
        fill="#41395C"
      />
      <path
        d="M106.401 4.3482H108.05V6.93351H110.92V9.0099H108.05V14.9134C108.05 16.3587 108.64 16.9898 110.024 16.9898H110.92V19.1476H109.821C107.032 19.1476 105.851 17.9669 105.851 15.0355V9.0099H103.958V6.93351H105.851L106.401 4.3482Z"
        fill="#41395C"
      />
      <path
        d="M100.061 13.0206C100.061 10.5574 98.7984 8.96961 96.8442 8.96961C95.0324 8.96961 93.7296 10.6796 93.7296 13.041V19.148H91.531V6.93393H93.7296V9.21389C94.1978 7.80927 95.582 6.79143 97.2106 6.79143C100.305 6.79143 102.259 9.2546 102.259 13.0206V19.148H100.061V13.0206Z"
        fill="#41395C"
      />
      <path
        d="M89.3968 19.148H87.1983V1.84473H89.3968V19.148Z"
        fill="#41395C"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8954_2035"
          x1="5.90938"
          y1="10.0001"
          x2="20.0001"
          y2="10.0001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8954_2035"
          x1="14.0905"
          y1="10.0001"
          x2="-0.000205894"
          y2="10.0001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_8954_2035"
          x1="29.9998"
          y1="9.60544"
          x2="82.925"
          y2="9.60544"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_8954_2035"
          x1="29.9998"
          y1="9.60544"
          x2="82.925"
          y2="9.60544"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_8954_2035"
          x1="29.9998"
          y1="9.60544"
          x2="82.925"
          y2="9.60544"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_8954_2035"
          x1="29.9998"
          y1="9.60544"
          x2="82.925"
          y2="9.60544"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_8954_2035"
          x1="29.9998"
          y1="9.60544"
          x2="82.925"
          y2="9.60544"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8B08FD" />
          <stop offset="1" stop-color="#5622FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
