import classNames from "classnames";
import Loader from "components/design/loader";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { HighlightSearchTerm } from "components/DesignSystem/HighlightText";
import { FileFolderDnDWrapper } from "components/documents/FileDnDWrapper";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useDocPreview } from "hooks/useDocPreview";
import { useQuery } from "hooks/useQuery";
import { useToast } from "hooks/useToast";
import React, { useContext, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import {
  documentApi,
  useLazyGetSearchContentQuery,
} from "store/apis/documents";
import { useGetPreviewUrlQuery } from "store/apis/previewUrl";
import { moveFileLocation } from "../../apis/documents";
import { FOLDER_TYPE, LOCKED_FOLDER } from "../../constants/documents";
import { FileIcon } from "../../utils/fileTypeIcon";
import { DocumentContext } from "./documentContext";
import FileFolderActionDropDown from "./fileFolderActionDropdown";
import { ListView } from "./views/listView";
import { useInView } from "react-intersection-observer";
import ConditionalToolTip from "components/design/conditionalToolTip";
import { RecentDocument } from "./RecentDocument";
import { Preview } from "components/PreviewModal";
import { useModal } from "hooks/useModal";

export const FileFolderCard = ({
  selected,
  dropActive,
  fileType,
  name,
  checkBox = <></>,
  actionDropDown,
  onClick,
  thumbnailFileId,
  isChecked,
  isAlreadySelected,
  isSelectionAllowed = true,
  isItemSelected,
}) => {
  const { ref, inView } = useInView({ rootMargin: "0px 0px -50px 0px" });
  const { uuid: groupId } = useCurrentGroupContext();
  const { data } = useGetPreviewUrlQuery(
    {
      groupId: groupId,
      fileId: thumbnailFileId,
    },
    {
      skip: !thumbnailFileId || !groupId || !inView,
    }
  );

  const handleCardClick = (e) => {
    if (isAlreadySelected) {
      e.stopPropagation();
    } else {
      onClick(e);
    }
  };

  return (
    <button
      onContextMenu={(e) => e.preventDefault()}
      onClick={handleCardClick}
      className={classNames(
        "all:unset t-group t-flex t-h-44 t-w-60 t-cursor-pointer t-flex-col t-overflow-hidden t-break-words t-rounded-lg t-border t-border-solid t-border-neutral-10 t-bg-white t-text-start t-text-caption focus-visible:t-border-purple",
        {
          "!t-border-purple-40": selected,
          "t-border-purple !t-bg-purple-0": dropActive,
          "!t-cursor-no-drop": isAlreadySelected,
        }
      )}
      ref={ref}
    >
      <>
        {Boolean(data?.preview_url) ? (
          <div className="t-flex t-w-full t-items-center t-justify-center">
            <img
              draggable="false"
              src={data.preview_url}
              alt="file"
              className="t-h-[136px] t-w-full t-rounded-t-sm t-object-cover"
            />
          </div>
        ) : (
          <div className="t-flex t-h-36 t-w-full t-items-center t-justify-center t-py-5">
            <FileIcon fileType={fileType} width={64} height={64} />
          </div>
        )}
      </>
      <div
        className={classNames(
          "t-flex t-items-center t-justify-between t-gap-2 t-bg-surface-lighter-grey t-p-2",
          {
            "!t-bg-surface-purple": selected,
            "group-hover:t-bg-surface-grey": !selected,
            "t-border-purple !t-bg-purple-10": dropActive,
          }
        )}
      >
        <ConditionalToolTip
          condition={isAlreadySelected && "This file has selected already"}
        >
          <span className="t-flex t-max-w-48 t-items-center t-justify-start t-gap-2 t-text-green-100">
            <span className="t-w-6">
              {isSelectionAllowed ? (
                <>
                  <span
                    className={classNames({
                      "t-block group-hover:t-hidden":
                        !isChecked && !isItemSelected,
                      "t-hidden": isChecked || isItemSelected,
                    })}
                  >
                    <FileIcon fileType={fileType} width={24} height={24} />
                  </span>
                  <span
                    className={classNames("t-w-6", {
                      "t-hidden group-hover:t-block":
                        !isChecked && !isItemSelected,
                      "t-block": isChecked || isItemSelected,
                    })}
                  >
                    {checkBox}
                  </span>
                </>
              ) : (
                <FileIcon fileType={fileType} width={24} height={24} />
              )}
            </span>

            {name}
          </span>
        </ConditionalToolTip>
        {actionDropDown}
      </div>
    </button>
  );
};

const FolderAndFileSection = ({
  userGroupId,
  groupId,
  setNewFolderName,
  setLoad,
  entityFileAndFolder,
  listView,
  setMovedFolderName,
  groupDocID,
  ParentId,
  showRecentDocument,
  recentDocuments,
}) => {
  const {
    setSelectedFiles,
    selectedFiles,
    fetchMoreData,
    setEntityFileAndFolder,
    isSearchLoading,
    setCurrentPageNo,
    handleFolderClick,
    setRecentDocuments,
    selectedRecentDocs,
    setSelectedRecentDocs,
  } = useContext(DocumentContext);
  const { alertToast, successToast } = useToast();
  const query = useQuery();
  const searchTerm = query.get("search");
  const [getSearchContent] = useLazyGetSearchContentQuery();
  const dispatch = useDispatch();
  const [previewId, setPreviewId] = useState(null);

  const handleCheckBox = (uuid, e) => {
    e.stopPropagation();
    const { checked } = e.target;
    const updatedMapData = entityFileAndFolder.map((ele) =>
      ele.uuid === uuid ? { ...ele, isChecked: checked } : ele
    );

    setEntityFileAndFolder(updatedMapData);
    const selectedFile = updatedMapData
      .filter((ele) => ele.isChecked === true)
      .map(({ name, uuid, type, parent_uuid, is_locked }) => {
        return {
          uuid,
          name,
          type,
          parent_uuid,
          is_locked,
        };
      });

    setSelectedFiles(selectedFile);
  };

  const {
    isOpen: isPreviewModalOpen,
    close: closePreviewModal,
    open: openPreviewModal,
  } = useModal();

  const doubleClick = ({
    e,
    fileType,
    uuid,
    name,
    type,
    parent_uuid,
    is_locked,
    isRecentDocument,
  }) => {
    e.stopPropagation();
    if (e.detail === 2) {
      if (fileType === FOLDER_TYPE || fileType === LOCKED_FOLDER) {
        handleFolderClick(uuid);
        setSelectedFiles([]);
        setSelectedRecentDocs([]);
      } else {
        openPreviewModal();
        setPreviewId(uuid);
      }
    } else if (e.detail === 1) {
      if (isRecentDocument) {
        return;
      }

      let updatedMapData = entityFileAndFolder.map((ele) =>
        ele.uuid === uuid
          ? { ...ele, isChecked: true }
          : { ...ele, isChecked: false }
      );
      setEntityFileAndFolder(updatedMapData);
      setSelectedFiles([{ uuid, name, type, parent_uuid, is_locked }]);
    }
    setTimeout(() => {
      dispatch(documentApi.util.invalidateTags(["RECENT_DOCUMENT"]));
    }, 2000);
  };

  const moveFileOnDrop = async ({ folderId, fileId }) => {
    try {
      const { data = {} } = await moveFileLocation({
        groupId,
        payload: { destination_folder_id: folderId },
        fileId,
      });
      const { name, parent_name } = data.data;
      successToast({ message: `Moved to ${parent_name}` });

      setNewFolderName(name);
      setMovedFolderName(parent_name);
      if (searchTerm) {
        const {
          files = [],
          folders = [],
          current_page,
        } = await getSearchContent({
          searchTerm,
          groupId,
          pageNum: 1,
        }).unwrap();
        setCurrentPageNo(current_page);

        let updatedMapData = [...folders, ...files].map((ele) => {
          return {
            ...ele,
            isChecked: false,
          };
        });
        setEntityFileAndFolder(updatedMapData);
      } else {
        setLoad((prev) => !prev);
      }
    } catch (error) {
      alertToast({ message: error?.response?.data?.error?.message });
    }
  };

  const isItemSelected = selectedFiles.length > 0;

  return (
    <div>
      {showRecentDocument && (
        <RecentDocument
          searchTerm={searchTerm}
          doubleClick={doubleClick}
          handleCheckBox={handleCheckBox}
          userGroupId={userGroupId}
          groupId={groupId}
          setLoad={setLoad}
          setNewFolderName={setNewFolderName}
          setMovedFolderName={setMovedFolderName}
          groupDocID={groupDocID}
          ParentId={ParentId}
          selectedRecentDocs={selectedRecentDocs}
          listView={listView}
          recentDocuments={recentDocuments}
        />
      )}

      <div className="t-text-subtext t-mb-4">Folders and Files</div>
      {listView ? (
        <ListView
          userGroupId={userGroupId}
          entityFileAndFolder={entityFileAndFolder}
          groupId={groupId}
          setLoad={setLoad}
          setNewFolderName={setNewFolderName}
          checkFileType={FileIcon}
          doubleClick={doubleClick}
          setMovedFolderName={setMovedFolderName}
          groupDocID={groupDocID}
          ParentId={ParentId}
          moveFileOnDrop={moveFileOnDrop}
          handleCheckBox={handleCheckBox}
        />
      ) : (
        <InfiniteScroll
          dataLength={entityFileAndFolder.length}
          next={fetchMoreData}
          hasMore={true}
          inverse={false}
          scrollableTarget="scrollableFileFolderTable"
        >
          <div
            className={classNames("t-overflow-y-auto", {
              "t-sticky t-top-0 t-h-[calc(100vh-260px)]": searchTerm,
            })}
            id="scrollableFileFolderTable"
          >
            <div className="fileContainer">
              {entityFileAndFolder.map(
                ({
                  uuid,
                  name,
                  is_previewable,
                  file_type,
                  type,
                  isChecked,
                  parent_uuid,
                  is_locked,
                  thumbnail_file_id,
                }) => {
                  const fileType =
                    is_locked && type === FOLDER_TYPE
                      ? LOCKED_FOLDER
                      : file_type;
                  const disableCheckBox = selectedRecentDocs.some(
                    ({ uuid: selectedFileId }) => selectedFileId === uuid
                  );

                  return (
                    <div key={uuid}>
                      <FileFolderDnDWrapper
                        type={type}
                        uuid={uuid}
                        onDrop={moveFileOnDrop}
                      >
                        {({ isOver, isDragging }) => (
                          <ConditionalToolTip
                            condition={
                              !isDragging && (
                                <div className="t-max-w-32 t-text-center">
                                  {name}
                                </div>
                              )
                            }
                          >
                            <FileFolderCard
                              isChecked={isChecked}
                              thumbnailFileId={thumbnail_file_id}
                              isAlreadySelected={disableCheckBox}
                              name={
                                <span className="t-line-clamp-1">
                                  <HighlightSearchTerm
                                    text={name}
                                    searchTerm={searchTerm}
                                  />
                                </span>
                              }
                              onClick={(e) =>
                                doubleClick({
                                  e,
                                  fileType,
                                  uuid,
                                  name,
                                  type,
                                  parent_uuid,
                                  is_locked,
                                  isRecentDocument: false,
                                })
                              }
                              checkBox={
                                <Checkbox
                                  title={name}
                                  onChange={(e) => handleCheckBox(uuid, e)}
                                  onClick={(e) => e.stopPropagation()}
                                  checked={isChecked}
                                  disabled={disableCheckBox}
                                />
                              }
                              actionDropDown={
                                <FileFolderActionDropDown
                                  fileType={fileType}
                                  userGroupId={userGroupId}
                                  itemType={type}
                                  isPreviewable={is_previewable}
                                  folderId={uuid}
                                  groupId={groupId}
                                  setLoad={setLoad}
                                  folderName={name}
                                  setNewFolderName={setNewFolderName}
                                  folderDrowdowns={false}
                                  setMovedFolderName={setMovedFolderName}
                                  groupDocID={groupDocID}
                                  ParentId={ParentId}
                                  parentUuid={parent_uuid}
                                  isLocked={is_locked}
                                />
                              }
                              fileType={fileType}
                              selected={
                                isChecked || uuid === selectedFiles[0]?.uuid
                              }
                              dropActive={isOver}
                              isItemSelected={isItemSelected}
                            />
                          </ConditionalToolTip>
                        )}
                      </FileFolderDnDWrapper>
                    </div>
                  );
                }
              )}
            </div>
            {isSearchLoading && <Loader />}
          </div>
        </InfiniteScroll>
      )}
      {isPreviewModalOpen && (
        <Preview
          showModal={isPreviewModalOpen}
          closeModal={closePreviewModal}
          previewId={previewId}
        />
      )}
    </div>
  );
};

export default FolderAndFileSection;
