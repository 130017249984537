import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { Chip } from "components/DesignSystem/Chips/Chips";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import { Search } from "components/DesignSystem/Search/Search";
import { DropDownItem } from "components/GeneralLedger/GeneralLedgerFilters";
import { DD_MMM_YYYY } from "constants/date";
import { viewByOption, ViewOptions } from "constants/reports";
import dayjs from "dayjs";
import { ACCOUNTING_METHOD } from "dictionaries";
import { useChartOfAccounts } from "hooks/useChartOfAccounts";
import { ChangeEvent, ReactNode, useState } from "react";
import { useSelector } from "react-redux";
import { getFilterStatus } from "store/selector/transactionFilter";
import { FilterName, FiltersValues } from "store/slices/transactionFilter";
import { TxnCategories } from "types/Models/books";
import { debounce } from "utils/debouncing";
import { flattenTypes } from "utils/flattenCOA";
import { AssignVendorFilterState } from "./assignVendorFilterData";

export const Capsule = ({
  children,
  value,
  onCapsuleClick,
  isRemovable = true,
  type = "",
  isFixedFilter = false,
}: {
  children: ReactNode;
  value: boolean | string | null | string[];
  onCapsuleClick: () => void;
  isRemovable?: boolean;
  isFixedFilter?: boolean;
  type?: string;
}) => {
  let displayValue = value;
  if (Array.isArray(value)) {
    displayValue = true;
  }

  if (displayValue === "CASH" || displayValue === "ACCRUAL") {
    displayValue = ACCOUNTING_METHOD[displayValue];
  }

  if (
    viewByOption.map(({ value }) => value).includes(displayValue as ViewOptions)
  ) {
    displayValue =
      viewByOption.find(({ value }) => value === displayValue)?.label || "";
  }

  return (
    <Chip
      onClose={onCapsuleClick}
      isActive={Boolean(displayValue)}
      onFixedFilterClick={onCapsuleClick}
      isFixedFilter={isFixedFilter}
      filterType={type}
      isRemovable={isRemovable}
    >
      {children} {displayValue}
    </Chip>
  );
};

export const CapsuleFilters = ({
  updateFilter,
  values,
}: {
  updateFilter: (v: any, a: any) => void;
  values: AssignVendorFilterState;
}) => {
  const fixedFilters = Object.values(values).filter(({ fixed }) => fixed);
  const capsuleFilters = Object.values(values)
    .filter(({ name }) => Boolean(name))
    .filter(({ value, fixed }) => value && !fixed);

  const handleClick = ({ name }: { name: FilterName }) => {
    updateFilter(name, null);
  };

  const getFilterName = (name: string) => {
    for (let key in values) {
      //@ts-ignore
      if (values.hasOwnProperty(key) && values[key].name === name) {
        return key as FilterName;
      }
    }
  };

  const fixedFilterClicked = ({ name }: { name: FilterName }) => {
    const currentValue = !fixedFilters.filter(
      ({ name: filterName }) => getFilterName(filterName) === name
    )[0]?.value;

    if (Boolean(currentValue)) {
      updateFilter(name, currentValue);
    } else {
      updateFilter(name, null);
    }
  };

  return (
    <>
      {capsuleFilters
        .filter(({ name }) => name !== "Select period")
        .map(({ name, value, type }) => {
          let filterValue = value;
          if (type === "transactionDate" && typeof value === "string") {
            filterValue = dayjs(value).format(DD_MMM_YYYY);
          } else if (Array.isArray(value)) {
            filterValue = `(${value.length})`;
          }

          return (
            <Capsule
              key={name}
              value={filterValue}
              onCapsuleClick={() =>
                handleClick({ name: getFilterName(name)! as FilterName })
              }
              type={type}
              isRemovable={name !== "Unknown Vendors"}
            >
              {name}
            </Capsule>
          );
        })}
      {fixedFilters.map(({ name, value, type }) => {
        return (
          <Capsule
            isFixedFilter
            key={name}
            value={value}
            onCapsuleClick={() =>
              fixedFilterClicked({ name: getFilterName(name)! as FilterName })
            }
            type={type}
            isRemovable={name !== "Unknown Vendors"}
          >
            {name}
          </Capsule>
        );
      })}
    </>
  );
};

export const CategoryFilter = ({
  updateFilter,
  values,
}: {
  updateFilter: (v: any, a: any) => void;
  values: AssignVendorFilterState;
}) => {
  const [search, setSearch] = useState("");

  const categoryIds = values.categoryIds?.value || [];

  const { chartOfAccounts } = useChartOfAccounts({
    search,
    hiddenCategoryTypes: ["BANK_ACCOUNT"],
  });

  const onHandleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    types?: TxnCategories[]
  ) => {
    const { value, checked } = e.target;

    const selectedChilds =
      flattenTypes({ accounts: types! })?.map(({ uuid }) => uuid) || [];

    if (Array.isArray(categoryIds)) {
      let newCategories: Set<string> = new Set();
      if (checked) {
        newCategories = new Set([
          ...categoryIds,
          ...selectedChilds,
          value as string,
        ]);
      } else {
        newCategories = new Set(
          [...categoryIds]?.filter(
            (categoryId) =>
              !selectedChilds.includes(categoryId) && categoryId !== value
          )
        );
      }
      updateFilter(
        "categoryIds",
        newCategories.size > 0 ? [...newCategories] : undefined
      );
    }
  };

  const handleSearch = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearch(value.toLowerCase() || "");
  });

  const noCOAFound = chartOfAccounts.length === 0;

  return (
    <Dropdown.Root onOpenChange={() => setSearch("")}>
      <Dropdown.Trigger asChild>
        <div className="all:unset form-input w-full t-box-border t-flex t-h-10 t-w-[100%] t-items-center t-rounded t-border t-border-solid t-border-neutral-10 t-bg-surface-lighter-grey t-px-4 t-pr-5 t-font-sans t-text-body !t-font-medium t-text-text-100 t-transition-all">
          <div className="t-overflow-hidden t-text-ellipsis t-whitespace-nowrap t-text-text-30">
            {categoryIds && (categoryIds as [])?.length > 0
              ? (categoryIds as [])?.length + " categories selected"
              : "Select an account"}
          </div>
        </div>
      </Dropdown.Trigger>
      <Dropdown.Portal>
        <Dropdown.Content
          sideOffset={6}
          align="start"
          className="t-relative t-w-[430px]"
        >
          <Search
            placeholder="Search..."
            onChange={handleSearch}
            autoFocus
            customSize="regular"
            block
          />
          {noCOAFound ? (
            <div className="t-my-1.5 t-flex t-h-48 t-items-center t-justify-center">
              No categories found
            </div>
          ) : (
            <div className="t-max-h-72 t-overflow-auto">
              <DropDownItem
                types={chartOfAccounts}
                level={0}
                onHandleChange={onHandleChange}
                categoryIds={categoryIds as string[]}
              />
            </div>
          )}
        </Dropdown.Content>
      </Dropdown.Portal>
    </Dropdown.Root>
  );
};

export const OtherFilters = ({
  updateFilter,
  values,
}: {
  updateFilter: (v: any, a: any) => void;
  values: AssignVendorFilterState;
}) => {
  const {
    filters: { others },
    getFilterName,
  } = useSelector(getFilterStatus);

  const handleCheckBox = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = e.target;

    if (checked) {
      updateFilter(name, checked);
    } else {
      updateFilter(name, null);
    }
  };

  return (
    <div className="t-flex t-flex-col t-gap-3">
      {others.map(({ name }) => {
        const isSelected = Object.values(values).find(
          (filter) => name === filter.name
        ).value;

        return (
          <Checkbox
            key={name}
            name={getFilterName(name)}
            checked={isSelected}
            label={name}
            onChange={handleCheckBox}
          />
        );
      })}
    </div>
  );
};
