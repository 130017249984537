import { Button } from "components/DesignSystem/Button/Button";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import { CaretDown } from "components/icons/CaretDown";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useToast } from "hooks/useToast";
import React from "react";
import { useSelector } from "react-redux";
import { useLazyGetBillingInvoicesQuery } from "store/apis/billingInvoice";
import { useLazyGetPreviewUrlQuery } from "store/apis/previewUrl";
import { RootState } from "store/store";
import { BackendError } from "types/utils/error";
import { openLink } from "utils/openLink";
import { billingFilteredFilters } from "./billing";

export const ExportBilling = ({
  groupId,
  searchTerm,
}: {
  groupId: string;
  searchTerm?: string;
}) => {
  const {
    filters: { selectedSeason, paymentStatus, productType },
  } = useSelector((state: RootState) => state.billingFilters);
  const entityId = useCurrentEntityId();
  const [getPreviewUrl] = useLazyGetPreviewUrlQuery();

  const [getBillingInvoice, { isLoading }] = useLazyGetBillingInvoicesQuery();
  const productTypes: string = billingFilteredFilters(productType);
  const paymentStatuses: string = billingFilteredFilters(paymentStatus);
  const selectedSeasons: string = billingFilteredFilters(selectedSeason);
  const { alertToast } = useToast();
  const payload = {
    groupId: groupId!,
    searchTerm,
    selectedSeasons: selectedSeasons,
    productTypes: productTypes,
    paymentStatuses: paymentStatuses,
    entityId,
  };

  const onExport = async (
    exportAction: "EXPORT_INVOICE_DOCUMENTS" | "EXPORT_INVOICES"
  ) => {
    try {
      const data = await getBillingInvoice({
        ...payload,
        exportAction: exportAction,
        pageNumber: 1,
      }).unwrap();
      const { download_url } = await getPreviewUrl({
        groupId,
        fileId: data.export_file.uuid,
      }).unwrap();
      openLink(download_url);
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  return (
    <Dropdown.Root>
      <Dropdown.Trigger asChild className="t-group" disabled={isLoading}>
        <div>
          <Button
            size="small"
            onClick={(e) => e.stopPropagation()}
            isLoading={isLoading}
            disabled={isLoading}
          >
            <div className="t-flex t-items-center t-gap-1.5">
              Export
              <div className="group-data-state-open:t-rotate-180">
                <CaretDown />
              </div>
            </div>
          </Button>
        </div>
      </Dropdown.Trigger>
      <Dropdown.Portal>
        <Dropdown.Content sideOffset={4} align="end" side="bottom">
          <Dropdown.Item onSelect={() => onExport("EXPORT_INVOICES")}>
            CSV
          </Dropdown.Item>
          <Dropdown.Item onSelect={() => onExport("EXPORT_INVOICE_DOCUMENTS")}>
            ZIP
          </Dropdown.Item>
        </Dropdown.Content>
      </Dropdown.Portal>
    </Dropdown.Root>
  );
};
