import classNames from "classnames";
import { Button } from "components/DesignSystem/Button/Button";
import { HighlightSearchTerm } from "components/DesignSystem/HighlightText";
import Modal from "components/DesignSystem/Modal/Modal";
import { ProductCard } from "components/ProductCard/ProductCard";
import { AddonPricing } from "components/SubscriptionSummary";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroup } from "hooks/useCurrentGroup";
import { useModal } from "hooks/useModal";
import { useQuery } from "hooks/useQuery";
import { useToast } from "hooks/useToast";
import { useCancelSubscriptionMutation } from "store/apis/subscriptions";
import {
  GroupSubscriptionState,
  RAStateAmountType,
} from "types/Models/subscription";
import { BackendError } from "types/utils/error";
import { formatDate } from "utils/formatDate";

export const RASubscribedState = ({
  subscribedState,
  statePriceMap,
  otherAmount,
}: {
  subscribedState: GroupSubscriptionState;
  statePriceMap?: RAStateAmountType[];
  otherAmount: number;
}) => {
  const query = useQuery();
  const search = query.get("search") || "";
  const { alertToast, successToast } = useToast();
  const [cancelSubscription, { isLoading }] = useCancelSubscriptionMutation();
  const entityId = useCurrentEntityId();
  const { isOpen, close, open } = useModal();
  const { uuid: groupId } = useCurrentGroup();

  const handleCancelSubscription = async () => {
    try {
      if (groupId) {
        await cancelSubscription({
          groupId: groupId!,
          entityId,
          subscriptionId: subscribedState.group_subscription_id,
        }).unwrap();
        successToast({ message: "Subscription cancelled successfully" });
      }
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
    close();
  };
  let subscriptionName = subscribedState.name;
  if (subscribedState.ra_state_name) {
    subscriptionName += `(${subscribedState.ra_state_name})`;
  }
  let amount =
    statePriceMap?.find(
      (state) => state.ra_state === subscribedState.ra_state_name
    )?.amount || otherAmount;

  return (
    <>
      <ProductCard
        key={subscribedState.group_subscription_id}
        title={
          <HighlightSearchTerm
            text={subscriptionName || ""}
            searchTerm={search}
          />
        }
        price={
          <AddonPricing
            addon={{
              ...subscribedState,
              amount: Number(amount),
              description: "",
              subscription_name: "",
              subscription_type: "",
              uuid: "",
            }}
          />
        }
        action={
          <div
            className={classNames("t-flex t-w-full t-items-center", {
              "t-justify-between": subscribedState.ends_on,
              "t-justify-end": !subscribedState.ends_on,
            })}
          >
            {subscribedState.ends_on && (
              <div className="t-text-body-sm t-text-text-30">
                Renews on{" "}
                <span className="t-font-bold">
                  {formatDate(subscribedState.ends_on)}
                </span>
              </div>
            )}
            <Button size="small" onClick={open}>
              Cancel
            </Button>
          </div>
        }
        planStatus="ACTIVE"
        customAction
        isSubscription
      />
      <Modal.Root open={isOpen} onOpenChange={close}>
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>Cancel subscription</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            {/* //copy needs to be checked */}
            <div className="t-text-body">
              Are you sure you want to cancel the subscription?
            </div>
          </Modal.Body>
          <Modal.FooterButtonGroup>
            <Modal.RawClose asChild>
              <Button type="button">Cancel</Button>
            </Modal.RawClose>
            <Button
              customType="danger"
              onClick={handleCancelSubscription}
              isLoading={isLoading}
              disabled={isLoading}
            >
              Cancel subscription
            </Button>
          </Modal.FooterButtonGroup>
        </Modal.Content>
      </Modal.Root>
    </>
  );
};
