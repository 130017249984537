import { ActionRequiredPopOver } from "components/ActionRequiredPopover/ActionRequiredPopover";
import { DashboardLayout } from "components/DashboardLayout";
import { Header } from "components/DesignSystem/Header/Header";
import Tab from "components/DesignSystem/Tab/Tab";
import { Reconciliation } from "components/Reconciliation/Reconciliation";
import { useActionItems } from "hooks/useActionItems";
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import { DataSourcesList } from "./DataSourcesList";

export const DataSources = () => {
  const { path, url } = useRouteMatch();
  const { pathname, search } = useLocation();

  const { isReconciliationRequired, isBankReconnectRequired } =
    useActionItems();

  const isReconciliationPage = pathname.includes("/reconciliation");

  return (
    <DashboardLayout
      header={
        <Header
          v2
          title={
            <div className="t-flex t-gap-2 t-items-center">Data Sources</div>
          }
          bottom={
            <Tab.Root defaultValue={pathname} value="MATCH">
              <Tab.List>
                <Tab.NavTrigger
                  exact
                  to={`${url}${search}`}
                  value={!isReconciliationPage ? "MATCH" : ""}
                  className="t-flex t-items-center"
                >
                  Data Sources
                  {(isBankReconnectRequired || isReconciliationRequired) && (
                    <ActionRequiredPopOver type="RECONCILLATION_OR_ACCOUNT" />
                  )}
                </Tab.NavTrigger>
                <Tab.NavTrigger
                  exact
                  to={`${url}/reconciliation${search}`}
                  value={isReconciliationPage ? "MATCH" : ""}
                >
                  Reconciliation
                </Tab.NavTrigger>
              </Tab.List>
            </Tab.Root>
          }
        />
      }
    >
      <Switch>
        <Route path={`${path}/reconciliation`}>
          <Reconciliation />
        </Route>
        <Route path={path}>
          <DataSourcesList />
        </Route>
      </Switch>
    </DashboardLayout>
  );
};
