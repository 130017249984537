import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { SelectDropDown } from "components/DesignSystem/SelectDropDown/SelectDropDown";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { PriceInput } from "components/PriceInput/PriceInput";
import { Form, Formik } from "formik";
import {
  merchantAddSchema,
  merchantEditSchema,
} from "formValidations/merchantSchema";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useToast } from "hooks/useToast";
import {
  useAddVendorsMutation,
  useEditVendorsMutation,
} from "store/apis/vendors";

const yearOfPaymentOptions = [
  { name: "2024", valueKey: "2024" },
  { name: "2023", valueKey: "2023" },
  { name: "2022", valueKey: "2022" },
];

export const AddOrEditMerchant = ({
  show,
  closeModal,
  groupId,
  editMerchantData = {},
}) => {
  const { alertToast, successToast } = useToast();
  const [addVendor, { isLoading: isAdding }] = useAddVendorsMutation();
  let selectedEntityId = useCurrentEntityId();
  const [editVendor, { isLoading: isEditing }] = useEditVendorsMutation();
  const { uuid, name, email, w_form_season, transaction_amount } =
    editMerchantData || {};

  const handleSubmit = async (payload) => {
    try {
      await addVendor({
        payload,
        groupId,
        entityId: selectedEntityId,
      }).unwrap();
      successToast({ message: "New vendor has been added" });
      closeModal();
    } catch (error) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  const editMerchant = async (payload) => {
    try {
      await editVendor({
        groupId,
        uuid,
        payload: { ...payload, season: w_form_season },
      }).unwrap();
      successToast({ message: "Vendor has been updated" });
      closeModal();
    } catch (error) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  return (
    <Modal.Root open={show} onOpenChange={closeModal}>
      <Modal.Content size="regular">
        <Modal.Header>
          {uuid ? "Edit Vendor" : "Add Vendor"}
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              name: name || "",
              email: email || "",
              amount_paid_in_season: w_form_season || "",
              total_amount: transaction_amount || 0,
            }}
            onSubmit={(values) =>
              uuid ? editMerchant(values) : handleSubmit(values)
            }
            validationSchema={uuid ? merchantEditSchema : merchantAddSchema}
            validateOnMount={false}
            validateOnChange={false}
          >
            <Form className="t-m-0">
              <div className="t-flex t-w-full t-flex-col t-gap-5">
                <TextInput
                  block
                  name="name"
                  label="Name"
                  placeholder="Vendor name"
                  required
                />
                <TextInput
                  block
                  type="email"
                  name="email"
                  label="Email"
                  placeholder="Vendor email"
                />
                <PriceInput
                  disabled={uuid}
                  name="total_amount"
                  label="Amount Paid"
                  required
                />
                <SelectDropDown
                  label="Year of Payment"
                  name="amount_paid_in_season"
                  required
                  disabled={uuid}
                >
                  <option value="">Select Year</option>
                  {yearOfPaymentOptions.map(({ name, valueKey }) => (
                    <option value={valueKey} key={valueKey}>
                      {name}
                    </option>
                  ))}
                </SelectDropDown>
              </div>
              <div className="t-flex t-gap-3 t-justify-end t-mt-6">
                <Button
                  customType="secondary"
                  size="regular"
                  type="reset"
                  onClick={closeModal}
                >
                  Cancel
                </Button>
                <Button
                  customType="primary"
                  size="regular"
                  type="submit"
                  disabled={isAdding || isEditing}
                  isLoading={isAdding || isEditing}
                >
                  Save
                </Button>
              </div>
            </Form>
          </Formik>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
