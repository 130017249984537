import { BillingInvoice } from "types/Models/billing";
import { Task } from "types/Models/task";

export const getCreditUsageForCart = (
  cartInvoice: BillingInvoice,
  total_credits: number,
  brexPoints: number = 0
) => {
  const discount = Number(cartInvoice.discount || "0");

  const amountToConsider = Number(cartInvoice.amount) - discount;

  const usableCredits =
    total_credits < amountToConsider ? total_credits : amountToConsider;

  const usableBrexPoints =
    brexPoints < amountToConsider ? brexPoints : amountToConsider;

  const amountToConsiderAfterCredits = amountToConsider - usableCredits;

  const usableBrexPointsAfterCredits =
    brexPoints < amountToConsiderAfterCredits
      ? brexPoints
      : amountToConsiderAfterCredits;

  return { usableCredits, usableBrexPoints, usableBrexPointsAfterCredits };
};

export const getCreditUsageForBulkInvoices = (
  invoices: BillingInvoice[],
  total_credits: number,
  brexPoints: number = 0,
  total_due?: number
) => {
  let totalAmount =
    total_due ||
    invoices.reduce((total, invoice) => total + Number(invoice.amount), 0);

  const usableCredits =
    total_credits < totalAmount ? total_credits : totalAmount;

  const usableBrexPoints = brexPoints < totalAmount ? brexPoints : totalAmount;

  const amountToConsiderAfterCredits = totalAmount - usableCredits;

  const usableBrexPointsAfterCredits =
    brexPoints < amountToConsiderAfterCredits
      ? brexPoints
      : amountToConsiderAfterCredits;

  return {
    usableCredits,
    usableBrexPoints,
    usableBrexPointsAfterCredits,
  };
};

export const getCreditUsageForTask = (
  task: Task,
  total_credits: number,
  brexPoints: number = 0
) => {
  const amountToConsider = Number(
    task.coupon?.discounted_price || task.payment_amount
  );

  const usableCredits =
    total_credits < amountToConsider ? total_credits : amountToConsider;

  const usableBrexPoints =
    brexPoints < amountToConsider ? brexPoints : amountToConsider;

  const amountToConsiderAfterCredits = amountToConsider - usableCredits;

  const usableBrexPointsAfterCredits =
    brexPoints < amountToConsiderAfterCredits
      ? brexPoints
      : amountToConsiderAfterCredits;

  return { usableCredits, usableBrexPoints, usableBrexPointsAfterCredits };
};
