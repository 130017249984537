import { DashboardLayout } from "components/DashboardLayout";
import { Header } from "components/DesignSystem/Header/Header";
import { EntitySelector } from "components/EntitySelector/EntitySelector";
import React from "react";
import { FederalRegistrations } from "../../components/FederalRegistration/FederalRegistrations";
import { StateRegistrations } from "components/StateRegistrations/StateRegistrations";
import { usePageTitle } from "hooks/usePageTitle";

export const Registrations = () => {
  usePageTitle("Registrations");
  return (
    <DashboardLayout
      header={
        <Header
          title="Registrations"
          v2
          right={<EntitySelector size="small" showOnlyUSEntities />}
        />
      }
    >
      <section className="t-mt-4 t-space-y-8">
        <FederalRegistrations />
        <StateRegistrations />
      </section>
    </DashboardLayout>
  );
};
