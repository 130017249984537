import { Button } from "components/DesignSystem/Button/Button";
import React from "react";
import { RegistrationActionType } from "store/apis/registrations";
import { openLink } from "utils/openLink";
import { SubscribeRA } from "./SubscribeRA";

export const UnregisteredActions = ({
  actionType,
  launchFiling,
  isLoading,
  actionDescription,
  stateName,
}: {
  actionType: RegistrationActionType;
  launchFiling?: () => void;
  isLoading: boolean;
  actionDescription?: string;
  stateName?: string;
}) => {
  switch (actionType) {
    case "VISIT_IRS":
      return (
        <div className="t-flex t-gap-1 t-w-full t-items-center">
          <div className="t-text-subtext t-text-text-30">Want to register?</div>
          <Button
            type="button"
            customType="link"
            onClick={() =>
              openLink(
                "https://www.irs.gov/businesses/small-businesses-self-employed/apply-for-an-employer-identification-number-ein-online"
              )
            }
          >
            Visit IRS
          </Button>
        </div>
      );

    case "LAUNCH_FILING":
      return (
        <div className="t-space-y-3">
          <div className="t-text-body t-text-text-60">
            {actionDescription ||
              "Want to do your EFTPS Registration with Inkle at just $75"}
          </div>
          <Button
            type="button"
            customType="primary"
            onClick={launchFiling}
            isLoading={isLoading}
            disabled={isLoading}
            size="small"
          >
            Pay to proceed
          </Button>
        </div>
      );

    case "GET_AGENT":
      return <SubscribeRA stateName={stateName} />;

    default:
      return <></>;
  }
};
