import { object, string, mixed } from "yup";

export const createInvoiceSchema = () =>
  object({
    invoice_amount: string().required("Invoice amount is required"),
    task_short_name: string().required("Please enter name"),
    invoice_type: string().required("Please select a type"),
    season: string().required("Please select a season"),
    month: mixed().when("invoice_type", {
      is: "MONTHLY_BOOKKEEPING",
      then: string().required("Please select a month"),
      otherwise: string().notRequired(),
    }),
  });
