import { GlobalModals } from "components/GlobalModals/GlobalModals";
import { GENERAL_HELP } from "constants/chatType";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { ReactNode, useCallback, useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useGetUserProfileQuery } from "store/apis/profile";
import { setCreditApplicable, setCredits } from "store/slices/credit";
import * as Sentry from "@sentry/browser";
import { identify } from "utils/analytics";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import DashboardContainer from "components/dashboard/DashboardContainer";
import authContext from "jwt_context&axios/authContext";
import { useToast } from "hooks/useToast";
import { useLazyGetEntitiesQuery } from "store/apis/group";
import { BackendError } from "types/utils/error";
import { FloatingChat } from "components/floatingChat/FloatingChat";

type LayoutProps = {
  topbar: ReactNode;
  sidebar: ReactNode;
  banner?: ReactNode;
  children: ReactNode;
};

export const AppLayout = ({
  topbar,
  sidebar,
  banner,
  children,
}: LayoutProps) => {
  const {
    isCpa,
    isForeignCA,
    isServiceSuperAdmin,
    isAdmin,
    isInvestor,
    isPreIncorporated,
    isCustomer,
  } = useRoleBasedView();
  const history = useHistory();
  const location = useLocation();
  const { data: user, error } = useGetUserProfileQuery();
  const dispatch = useAppDispatch();
  const { dispatch: dispatchGroup } = useCurrentGroupContext();
  const group = useCurrentGroupContext();
  const { authtoken, setGroupEntities } = useContext(authContext);
  const { alertToast } = useToast();
  const [getEntities] = useLazyGetEntitiesQuery();

  useEffect(() => {
    localStorage.removeItem("magicLinkData");
    if (error) {
      history.push("/signin");
    } else {
      if (isCpa && location.pathname === GENERAL_HELP) history.push("/chat");
      if (user?.credits_available) {
        dispatch(setCredits(user?.credits_available));
      }
      dispatch(setCreditApplicable(Boolean(user?.credits_applicable)));
    }
  }, [user?.credits_applicable, user?.credits_available]);

  const {
    email,
    name,
    mobile,
    is_service_user,
    is_any_service_user,
    is_inkle_admin,
  } = user || {};

  let role = "CUSTOMER";

  if (isCpa) role = "CPA";
  if (isForeignCA) role = "FOREIGN_CA";
  if (isServiceSuperAdmin) role = "SERVICE_SUPER_ADMIN";
  if (isAdmin) role = "ADMIN";
  if (isInvestor) role = "INVESTOR";
  if (isPreIncorporated) role = "PREINCORPORATION";

  useEffect(() => {
    if (email) {
      try {
        identify({
          name,
          email,
          mobile,
          is_service_user,
          is_any_service_user,
          is_inkle_admin,
          groupName: group?.name,
          role: role,
        });
      } catch (error) {}
    }
  }, [
    name,
    email,
    mobile,
    is_service_user,
    is_any_service_user,
    is_inkle_admin,
    group.name,
    role,
  ]);

  useEffect(() => {
    if (email) {
      Sentry.setUser({ email: email, username: name, id: email });
    }
  }, [email, name]);

  const getAndSetGroup = useCallback(async () => {
    try {
      const { data } = await getEntities({});
      setGroupEntities({ data });
      if (data?.groups[0]) {
        dispatchGroup({ type: "SET_GROUP", payload: data?.groups[0] });
        localStorage.setItem("docId", data?.groups[0].documents_group_id);
      }
    } catch (error) {
      if (!authtoken.is_public_user) {
        alertToast({ message: (error as BackendError).data?.error?.message });
      }
    }
  }, [authtoken.is_public_user]);

  useEffect(() => {
    getAndSetGroup();
  }, [getAndSetGroup]);

  const checkChatPath = () => {
    let isChat =
      location.pathname.includes("/filings") ||
      location.pathname.includes("/tickets") ||
      location.pathname.includes("/action-items") ||
      location.pathname.includes("/mymessages") ||
      location.pathname === "/books/tp-payments/transfer" ||
      location.pathname === "/tax" ||
      location.pathname.includes("/chat") ||
      (location.pathname.includes("/books/transactions") &&
        !location.pathname.includes("/reconciliation") &&
        !location.pathname.includes("/rules")) ||
      location.pathname === "/raise/investor";

    if (isChat) {
      return true;
    } else {
      return false;
    }
  };

  // if (!group.uuid) {
  //   return <Loader />;
  // }

  return (
    <>
      <GlobalModals />
      <div className="t-flex t-h-screen">
        <div className="t-hidden md:t-block">{sidebar}</div>
        <div className="t-flex t-flex-col t-flex-1 t-relative t-overflow-x-auto">
          <DashboardContainer className="t-h-full t-gap-4">
            <DashboardContainer.Header>
              <div>{topbar}</div>
              {banner && banner}
            </DashboardContainer.Header>
            <DashboardContainer.Content className="t-transition-all">
              {children}
              {!checkChatPath() && <FloatingChat />}
            </DashboardContainer.Content>
          </DashboardContainer>
        </div>
      </div>
    </>
  );
};
