import cx from "classnames";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import { ArrowBendDownLeft } from "components/icons/ArrowBendDownLeft";
import dayjs from "dayjs";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import authContext from "jwt_context&axios/authContext";
import { ReactNode, useContext, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { openCreateChatTicketModal } from "store/slices/ticket";
import {
  getWholeChar,
  MentionProps,
  MessageUIComponentProps,
  renderText,
  StreamMessage,
  useChannelActionContext,
  useChannelStateContext,
  useChatContext,
  useMessageContext,
  useMessageListContext,
} from "stream-chat-react";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import { Attachment } from "./Attachment";

import classNames from "classnames";
import { ConditionalLink } from "components/conditionalLink";
import { useSelector } from "react-redux";
import CheckGreen from "static/images/CheckGreen.svg";
import MessageHighlight from "static/images/MessageHighlight.svg";
import { useGetUnresolvedTicketMessageIdsQuery } from "store/apis/chat";
import { RootState } from "store/store";

import { ReactionSelector } from "components/chat/ReactionSelector";
import { ReactionsList } from "components/chat/ReactionsList";
import { ChatUserName } from "components/ChatUserName/ChatUserName";
import ConditionalToolTip from "components/design/conditionalToolTip";
import DropDown from "components/DesignSystem/Dropdown/Dropdown";
import { NotAllowed } from "components/icons/Chat/NotAllowed";
import { Pin } from "components/icons/Chat/Pin";
import { Smiley } from "components/icons/Chat/Smiley";
import { DividerLine } from "components/icons/DividerLine";
import { FilledArrow } from "components/icons/FilledArrow";
import { ForwardIcon } from "components/icons/ForwardIcon";
import { MessageDateFormat } from "components/MessageDateFormat/MessageDateFormat";
import {
  ANNOUNCEMENTS_CHANNEL,
  CHAT_CARD_TYPE,
  DELETED_MESSAGE_TEXT,
  MESSAGE_TYPE_DELETED,
  TRANSACTIONS,
} from "constants/chatType";
import { motion } from "framer-motion";
import { useAppSelector } from "hooks/useAppSelector";
import { useGetMessage } from "hooks/useGetMessage";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { JSX } from "react/jsx-runtime";
import SmallWhatsappIcon from "static/images/SmallWhatsappIcon.svg";
import { openMessageInfoSlider } from "store/slices/channelInfoSlider";
import { setDeleteMessageData } from "store/slices/chat";
import { setMessageToForward } from "store/slices/messageForward";
import { MessageCustomData } from "types/Models/Chat";
import { Cards } from "./Cards";
import { SendToWhatsapp } from "./SendToWhatsapp";
import { useLazyGetTaskFromChannelIdQuery } from "store/apis/task";
import { BackendError } from "types/utils/error";
import { useCreateChatTicketMutation } from "store/apis/chatTicket";
import { Button } from "components/DesignSystem/Button/Button";
import { openLink } from "utils/openLink";
import { removeToast } from "store/slices/toast";
import { Files } from "components/icons/Files";
import { setScrollToMessageId } from "store/slices/messageToScrollTo";

const SharedOnWhatsapp = () => {
  return (
    <span className="t-flex t-gap-1 t-items-center t-mr-1 t-ml-10 t-tracking-[0.25px]">
      <span>Shared on</span>
      <img src={SmallWhatsappIcon} alt="SmallWhatsappIcon" />
      <span className="t-text-neutral-20">|</span>
    </span>
  );
};

const MessageText = ({
  message,
  sentByTheUser,
}: {
  message: MessageUIComponentProps["message"] & {
    forwarded_message?: MessageUIComponentProps["message"];
  };
  sentByTheUser: boolean;
}) => {
  const isDeletedMessage = message?.type === MESSAGE_TYPE_DELETED;

  const messageText: JSX.Element | null = useMemo(
    () =>
      renderText(message?.text, message?.mentioned_users, {
        customMarkDownRenderers: {
          mention: Mention,
        },
      }),
    [message?.mentioned_users, message?.text]
  );

  return (
    <span
      className={cx(
        "t-m-0 [&>p:last-child]:t-m-0 [&_p:has(a)]:!t-block [&_p]:t-whitespace-pre-line [&_ul]:t-mb-4 [&_ol]:t-mb-4 [&>p:last-child]:t-inline-block [&_code]:t-whitespace-pre-wrap",
        {
          "[&_a]:t-text-text-100": sentByTheUser,
          "t-text-text-30 t-italic": isDeletedMessage,
        }
      )}
    >
      {isDeletedMessage && (
        <span className="t-mr-1">
          <NotAllowed />
        </span>
      )}
      {isDeletedMessage ? DELETED_MESSAGE_TEXT : messageText}
    </span>
  );
};

type MessageComponentProps = {
  message: MessageUIComponentProps["message"] & {
    forwarded_message?: MessageUIComponentProps["message"];
  };
  otherData: { sentByTheUser: boolean };
  setQuotedMessage?: any;
  jumpToMessage?: (messageId?: string) => void;
  openTicketCreate?: (messageId: string) => void;
  mappedTicket?: Record<string, any>;
  onMessageDelete?: (message: StreamMessage) => void;
  hideOptions?: boolean;
  theme?: "light" | "dark";
  children?: ReactNode;
};

export const MessageDate = ({
  sentByTheUser,
  createdAt,
  isSharedOnWhatsapp,
}: {
  sentByTheUser?: boolean;
  createdAt?: string | Date | undefined;
  isSharedOnWhatsapp?: boolean;
}) => {
  const { isAdmin } = useRoleBasedView();
  const showSharedOnWhatsapp = isAdmin && isSharedOnWhatsapp;
  return (
    <>
      <span
        className={cx(
          "t-whitespace-nowrap t-text-right t-text-overline t-select-none t-text-text-30 t-h-0 t-ml-2 t-overflow-hidden t-inline-flex t-items-center",
          {
            "t-justify-end": sentByTheUser,
          }
        )}
      >
        {showSharedOnWhatsapp && <SharedOnWhatsapp />}
        {dayjs(createdAt).format("hh:mm a")}
      </span>
      <span
        className={cx(
          "t-ml-auto t-whitespace-nowrap t-text-right t-text-overline t-text-text-30 t-absolute t-right-2 t-bottom-2 t-flex t-items-center",
          {
            "t-justify-end": sentByTheUser,
          }
        )}
      >
        {showSharedOnWhatsapp && <SharedOnWhatsapp />}
        {dayjs(createdAt).format("hh:mm a")}
      </span>
    </>
  );
};

export const Options = (props: MessageComponentProps) => {
  const { client } = useChatContext();
  const { channel } = useChannelStateContext();
  const { message, otherData, setQuotedMessage } = props;
  const isAnnouncementChannel =
    channel?.data?.custom_type === ANNOUNCEMENTS_CHANNEL;
  const isPinned = message.pinned;
  const dispatch = useDispatch();
  const { alertToast, successToast } = useToast();
  const { isAdmin, isInkleSuperAdmin } = useRoleBasedView();
  const { sentByTheUser } = otherData;
  const {
    open: openSendToWhatsappModal,
    close: closeSendToWhatsappModal,
    isOpen: showSendToWhatsappModal,
  } = useModal();
  const [messageToSendOnWhatsapp, setMessageToSendOnWhatsapp] = useState<
    MessageUIComponentProps["message"] & {
      forwarded_message?: MessageUIComponentProps["message"];
    }
  >();
  const [getChannelDetails] = useLazyGetTaskFromChannelIdQuery();
  const [createNonChatTicket] = useCreateChatTicketMutation();
  const { authtoken } = useContext(authContext);
  const isSharedOnWhatsapp = (message?.custom_data as MessageCustomData)
    ?.is_sent_on_whats_app;
  const history = useHistory();

  const isMessageSentBefore10Minute =
    dayjs().diff(message?.created_at, "minutes") > 10;

  const isMessageDeletableByAdmin =
    isAdmin && !isMessageSentBefore10Minute && sentByTheUser;

  const isMessageDeletableByInkleSuperAdmin =
    isInkleSuperAdmin && !isMessageSentBefore10Minute;

  const isMessageDeletable =
    isMessageDeletableByAdmin || isMessageDeletableByInkleSuperAdmin;

  const disableWhatsappShare = Boolean(
    message.attachments && message.attachments?.length > 0 && !message.text
  );

  const onQuoteClick = () => {
    const textArea = document.getElementById("message-textarea");
    if (textArea) {
      textArea?.focus();
    }
    setQuotedMessage(message);
  };

  const pinMesaageToggle = async () => {
    try {
      if (isPinned) {
        await client.unpinMessage(message!);
        successToast({ title: "Message was unpinned" });
      } else {
        await client.pinMessage(message!, null);
        successToast({ title: "Message was pinned" });
      }
    } catch (error) {
      //@ts-ignore
      alertToast({ message: error?.messages });
    }
  };

  const onMessageDelete = (message: StreamMessage) => {
    dispatch(
      setDeleteMessageData({
        message,
        showDeleteMessageModal: true,
        channelId: channel.id || "",
      })
    );
  };

  const openManualTicketCreate = (messageId: string) => {
    dispatch(
      openCreateChatTicketModal({
        messageId,
        showCreateTicketModal: true,
        channelId: channel.id || "",
      })
    );
  };

  const openTicketCreate = async (messageId: string) => {
    try {
      if (channel.id) {
        const channelTask = await getChannelDetails({
          channelId: channel.id!,
        }).unwrap();

        if (channelTask.company_group) {
          const ticket = await createNonChatTicket({
            ticket_title:
              message.text ||
              `Ticket Created for ${channel?.data?.group_name || ""}`,
            company_group_id: channelTask.company_group.uuid,
            group_entity_id: channelTask.entity?.uuid,
            is_priced_ticket: false,
            assignee_profile_ids:
              channelTask.company_group.assigned_agent?.profile_id ||
              authtoken.uuid,
            is_non_chat_ticket: false,
            channel_url: channel.id || "",
            message_id: messageId,
          }).unwrap();

          successToast({
            title: (
              <div className="t-flex t-justify-between">
                <div>{ticket.name} created</div>
                <span className="t-ml-10">
                  <div
                    onClick={() => {
                      history.push(`/tickets?ticketUuid=${ticket.uuid}`);
                      setTimeout(() => dispatch(removeToast(ticket.uuid)), 500);
                    }}
                    className="t-text-text-60"
                    role="button"
                  >
                    View
                  </div>
                </span>
              </div>
            ),
            id: ticket.uuid,
          });
        }
      }
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  const openSendToWhatsapp = (
    message: MessageUIComponentProps["message"] & {
      forwarded_message?: MessageUIComponentProps["message"];
    }
  ) => {
    setMessageToSendOnWhatsapp(message);
    openSendToWhatsappModal();
  };

  const openMessageInfo = (
    message: MessageUIComponentProps["message"] & {
      forwarded_message?: MessageUIComponentProps["message"];
    }
  ) => {
    dispatch(openMessageInfoSlider(message.id));
  };

  return (
    <>
      <Dropdown.Root modal={false}>
        <Dropdown.Trigger asChild>
          <div className="t-absolute t-right-1 t-top-0.5 t-translate-x-1 t-transform t-opacity-0 t-transition-transform group-hover:t-translate-x-0 group-hover:t-opacity-100 data-state-open:t-translate-x-0 data-state-open:t-opacity-100">
            <button
              className={classNames(
                "all:unset t-text-surface-grey group-data-[sent=true]:t-text-purple-20",
                { "!t-text-orange-30": isPinned }
              )}
            >
              <FilledArrow />
            </button>
          </div>
        </Dropdown.Trigger>
        <Dropdown.Portal>
          <Dropdown.Content align="end" className="t-w-44">
            {!isAnnouncementChannel && (
              <Dropdown.Item onSelect={onQuoteClick}>
                <span className="t-flex t-items-center t-gap-2 t-text-body">
                  Reply
                </span>
              </Dropdown.Item>
            )}
            {isAdmin && (
              <Dropdown.Item
                onSelect={() => openManualTicketCreate(message?.id || "")}
              >
                <span className="t-flex t-items-center t-gap-2 t-text-body">
                  Create Ticket Manually
                </span>
              </Dropdown.Item>
            )}
            {isAdmin && (
              <Dropdown.Item
                onSelect={() => openTicketCreate?.(message?.id || "")}
              >
                <span className="t-flex t-items-center t-gap-2 t-text-body">
                  Create Ticket
                </span>
              </Dropdown.Item>
            )}
            {!isAnnouncementChannel && isAdmin && (
              <Dropdown.Item onSelect={pinMesaageToggle}>
                <span className="t-flex t-items-center t-gap-2 t-text-body">
                  {isPinned ? "Unpin" : "Pin"}
                </span>
              </Dropdown.Item>
            )}
            {!message?.forwarded_message && isAdmin && (
              <Dropdown.Item
                onSelect={() =>
                  message?.id &&
                  dispatch(
                    setMessageToForward({
                      messageId: message?.id,
                    })
                  )
                }
              >
                <span className="t-flex t-items-center t-gap-2 t-text-body">
                  Forward
                </span>
              </Dropdown.Item>
            )}
            {isMessageDeletable && (
              <Dropdown.Item onSelect={() => onMessageDelete?.(message!)}>
                <span className="t-flex t-items-center t-gap-2 t-text-body">
                  Delete
                </span>
              </Dropdown.Item>
            )}
            {isAdmin && (
              <Dropdown.Item
                onSelect={() => openSendToWhatsapp(message)}
                disabled={disableWhatsappShare}
              >
                <ConditionalToolTip
                  condition={
                    disableWhatsappShare &&
                    "Currently, attachment forwarding is not supported for WhatsApp. 📎"
                  }
                >
                  <span className="t-flex t-items-center t-gap-2 t-text-body">
                    Send to Whatsapp
                  </span>
                </ConditionalToolTip>
              </Dropdown.Item>
            )}
            {isAdmin && (
              <Dropdown.Item onSelect={() => openMessageInfo(message)}>
                <span className="t-flex t-items-center t-gap-2 t-text-body">
                  Message info
                </span>
              </Dropdown.Item>
            )}
          </Dropdown.Content>
        </Dropdown.Portal>
      </Dropdown.Root>
      {showSendToWhatsappModal && (
        <SendToWhatsapp
          isOpen={showSendToWhatsappModal}
          close={closeSendToWhatsappModal}
          message={
            messageToSendOnWhatsapp as MessageUIComponentProps["message"] & {
              forwarded_message?: MessageUIComponentProps["message"];
            }
          }
        />
      )}
    </>
  );
};

export const MessageLayout = (props: MessageComponentProps) => {
  const {
    message,
    otherData,
    setQuotedMessage,
    mappedTicket,
    hideOptions = false,
    children,
  } = props;

  const { search } = useLocation();
  const { sentByTheUser } = otherData || {};
  const { isAdmin, isCustomer, isCpa } = useRoleBasedView();
  const { client } = useChatContext();
  const [openReactionSelector, setOpenReactionSelector] = useState(false);
  const isDeletedMessage = message?.type === MESSAGE_TYPE_DELETED;
  const pinnedByUser = client.userID === message?.pinned_by?.id;
  const isPinned = message?.pinned;
  const { channelId } = useParams<{ channelId: string }>();
  const { message: messageContext } = useMessageContext();

  const onQuoteClick = () => {
    const textArea = document.getElementById("message-textarea");
    if (textArea) {
      textArea?.focus();
    }
    setQuotedMessage(message);
  };

  const url = channelId ? "/action-items" : "action-items";

  const customerTicketUrl = search
    ? `${url}${search}&ticketUuid=${mappedTicket?.ticket_uuid}`
    : `${url}?ticketUuid=${mappedTicket?.ticket_uuid}`;

  return (
    <div
      data-sent={sentByTheUser}
      onDoubleClick={() => !isDeletedMessage && !hideOptions && onQuoteClick()}
      className={cx("t-group t-flex t-flex-col t-leading-[normal] t-relative", {
        "t-items-end": sentByTheUser,
        "t-mt-4": isPinned,
      })}
    >
      <div className="t-flex t-gap-3">
        {!sentByTheUser && message?.user && !hideOptions && (
          <Avatar image={message?.user?.image} user={message?.user} />
        )}

        <div
          onDoubleClick={(e) => e.stopPropagation()}
          data-sent={sentByTheUser}
          className={classNames("t-group t-flex t-flex-col", {
            "t-gap-1": isPinned,
            "t-gap-2": !isPinned,
          })}
        >
          {isPinned && (
            <div
              className={classNames(
                "t-flex t-items-center t-text-text-30 t-text-body-sm t-gap-1",
                {
                  "t-justify-end": sentByTheUser,
                }
              )}
            >
              <span className="t-text-neutral-40">
                <Pin size="14" />
              </span>
              Pinned by {pinnedByUser ? "you" : <>{message?.pinned_by?.name}</>}
            </div>
          )}
          <div className="t-flex t-items-center t-gap-4 group-data-[sent=true]:t-justify-end">
            {!isDeletedMessage && !hideOptions && (
              <div
                className={classNames(
                  "t-pointer-events-none  t-order-2 t-flex t-gap-2 t-opacity-0 t-transition-opacity group-hover:t-pointer-events-auto group-hover:t-opacity-100 group-data-[sent=true]:t-order-1",
                  {
                    "t-pointer-events-auto t-opacity-100": openReactionSelector,
                  }
                )}
              >
                <span className="t-rotate-x-180">
                  <button className="all:unset" onClick={onQuoteClick}>
                    <ArrowBendDownLeft />
                  </button>
                </span>
              </div>
            )}
            {!isDeletedMessage && !hideOptions && (
              <DropDown.Root
                modal={false}
                onOpenChange={setOpenReactionSelector}
              >
                <DropDown.Trigger
                  asChild
                  className="all:unset t-pointer-events-none t-order-2 t-flex t-gap-2 t-opacity-0 t-transition-opacity group-hover:t-pointer-events-auto group-hover:t-opacity-100 data-[state=open]:t-pointer-events-auto data-[state=open]:t-opacity-100"
                >
                  <span>
                    <Smiley />
                  </span>
                </DropDown.Trigger>
                <DropDown.Portal>
                  <DropDown.Content
                    sideOffset={10}
                    side="top"
                    className={classNames(
                      "t-rounded-lg t-bg-surface t-drop-shadow-sidebar"
                    )}
                    align="center"
                  >
                    <ReactionSelector />
                  </DropDown.Content>
                </DropDown.Portal>
              </DropDown.Root>
            )}
            {children}
          </div>
          {messageContext?.latest_reactions &&
            messageContext?.latest_reactions?.length > 0 && (
              <div className="t-flex group-data-[sent=true]:t-justify-end">
                <ReactionsList sentByTheUser={sentByTheUser} />
              </div>
            )}

          {Boolean(mappedTicket) && (isAdmin || isCustomer || isCpa) && (
            <div
              className={classNames(
                "t-mb-2 t-text-overline t-font-medium t-tracking-normal t-text-neutral",
                {
                  "t-ml-9": sentByTheUser,
                  " t-text-left": !sentByTheUser,
                }
              )}
            >
              <ConditionalLink
                to={
                  isAdmin
                    ? `/tickets?ticketUuid=${mappedTicket?.ticket_uuid}`
                    : customerTicketUrl
                }
                className={classNames("t-text-text-100 ", {
                  "hover:t-text-purple": !sentByTheUser,
                })}
              >
                {mappedTicket?.resolved ? (
                  <div
                    className={classNames("t-text-black-30 t-flex t-gap-1", {
                      "t-justify-end": sentByTheUser,
                    })}
                  >
                    <img
                      src={CheckGreen}
                      alt="MessageHighlight"
                      className="t-select-none"
                    />
                    Ticket resolved
                  </div>
                ) : (
                  <div
                    className={classNames("t-text-black-30 t-flex t-gap-1", {
                      "t-justify-end": sentByTheUser,
                    })}
                  >
                    <img
                      src={MessageHighlight}
                      alt="MessageHighlight"
                      className="t-select-none"
                    />
                    Ticket created
                  </div>
                )}
              </ConditionalLink>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const MessageBody = (
  props: MessageComponentProps & { Date?: ReactNode }
) => {
  const {
    message,
    otherData,
    jumpToMessage,
    theme = "dark",
    children,
    Date,
  } = props;

  const { client } = useChatContext();
  const { sentByTheUser } = otherData;
  const { attachments, quoted_message } = message || {};
  const messageToScrollTo = useSelector(
    (state: RootState) => state.messageToScrollTo.messageId
  );
  const { channel } = useChannelStateContext();
  const data = useMessageListContext();

  const scrolledToThisMessage = messageToScrollTo === message?.id;
  const isPinned = message?.pinned;
  const isAnnouncementChannel =
    channel?.data?.custom_type === ANNOUNCEMENTS_CHANNEL;

  return (
    <div
      className={cx(
        "t-hyphens-auto t-relative t-order-1 t-flex-col t-gap-2.5 t-word-break t-text-body group-data-[sent=true]:t-order-2",
        {
          "!t-bg-orange-10": isPinned,
          "t-rounded-lg t-rounded-tr-sm t-bg-purple-10 t-text-purple-100":
            sentByTheUser,
          "t-rounded-lg t-rounded-tl-sm t-text-text-60": !sentByTheUser,
          "t-p-3 t-px-2.5": (attachments?.length || 0) > 0,
          "t-p-2 t-pt-1.5": (attachments?.length || 0) === 0,
          "t-animate-highlightReceivedChatMessage":
            scrolledToThisMessage && !sentByTheUser,
          "t-animate-highlightSentChatMessage":
            scrolledToThisMessage && sentByTheUser,
          "t-bg-yellow-10": isAnnouncementChannel,
          "t-max-w-[var(--message-max-width)]":
            message?.custom_type !== CHAT_CARD_TYPE,
          "t-max-w-[306px]": message?.custom_type === CHAT_CARD_TYPE,
          "t-bg-white": !sentByTheUser && theme === "dark" && !isPinned,
          "t-bg-surface-grey": !sentByTheUser && theme === "light" && !isPinned,
        }
      )}
    >
      <div className="t-flex">
        <div className="">
          {!sentByTheUser && (
            <p className="t-m-0 t-mb-1 t-text-subtext-sm t-text-text-100 t-flex">
              <ChatUserName
                role={message?.user?.role_name}
                name={message?.user?.name}
              />
            </p>
          )}
          <div className="t-flex t-flex-col t-gap-2.5">
            {quoted_message?.id && (
              <button
                onClickCapture={() => jumpToMessage?.(quoted_message.id)}
                className={classNames(
                  "all:unset t-flex t-flex-col t-gap-1 t-rounded t-border-solid t-border-l-4 t-border-0 t-bg-surface-lighter-grey t-p-2 group-data-[sent=true]:t-text-text-60",
                  {
                    "t-border-neutral-40":
                      quoted_message.user?.id !== client.userID,
                    "t-border-purple-30":
                      quoted_message.user?.id === client.userID,
                  }
                )}
              >
                <span className="t-text-subtext-sm t-text-text-60 t-flex t-items-center">
                  {quoted_message?.user?.id === client?.user?.id ? (
                    "You "
                  ) : (
                    <ChatUserName
                      role={quoted_message?.user?.role_name}
                      name={quoted_message?.user?.name}
                    />
                  )}
                </span>

                <span className="t-text-body-sm t-text-text-30 t-line-clamp-3 t-flex t-items-center t-gap-1">
                  {quoted_message?.text && quoted_message?.text?.length > 0 && (
                    <span>{quoted_message?.text}</span>
                  )}

                  {quoted_message.attachments &&
                    quoted_message.attachments?.length > 0 &&
                    (quoted_message.text?.length === 0 ? (
                      <span className="t-flex t-gap-1 t-items-center">
                        <span>Go to attachment</span>
                        <Files />
                      </span>
                    ) : (
                      <span className="t-flex">
                        <Files />
                      </span>
                    ))}
                </span>
              </button>
            )}

            {attachments?.length ? (
              <Attachment attachments={attachments} sent={sentByTheUser} />
            ) : null}

            {(message?.custom_data || message.text || Date || children) && (
              <div className="">
                {Boolean(message?.custom_data) && (
                  <Cards
                    type={message?.custom_type as string}
                    customData={message?.custom_data}
                    messageId={message?.id!}
                  >
                    {message.custom_type === TRANSACTIONS &&
                    Boolean(message.text?.trim()) ? (
                      <MessageText
                        message={message}
                        sentByTheUser={sentByTheUser}
                      />
                    ) : null}
                  </Cards>
                )}
                {(message.custom_type !== TRANSACTIONS ||
                  message?.type === MESSAGE_TYPE_DELETED) && (
                  <MessageText
                    message={message}
                    sentByTheUser={sentByTheUser}
                  />
                )}
                {Date ? Date : null}
                {children}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const MessageBubble = <
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
>() => {
  const { message } = useMessageContext();
  const { sendbirdCreds: chatCreds, authtoken } = useContext(authContext);
  const { setQuotedMessage, jumpToMessage } =
    useChannelActionContext<StreamChatGenerics>("MessageActionsBox");
  const dispatch = useDispatch();
  const { channel } = useChannelStateContext();
  const { isAdmin, isCustomer, isCpa } = useRoleBasedView();

  const chatMemberId = chatCreds?.member_id || authtoken?.member?.member_id;

  const sentByUser = message.user?.id === chatMemberId;
  const { data: unresolvedTickets } = useGetUnresolvedTicketMessageIdsQuery(
    {
      channelUrl: channel?.id!,
    },
    {
      skip: (!isAdmin && !isCustomer && !isCpa) || !channel?.id,
    }
  );

  const ticketMap: Record<string, any> = unresolvedTickets
    ? unresolvedTickets.ticket_messages?.reduce((acc, e) => {
        return {
          ...acc,

          [e?.message_id]: e,
        };
      }, {})
    : {};

  const openTicketCreate = (messageId: string) => {
    dispatch(
      openCreateChatTicketModal({
        messageId,
        showCreateTicketModal: true,
        channelId: channel.id || "",
      })
    );
  };

  const onMessageDelete = (message: StreamMessage) => {
    dispatch(
      setDeleteMessageData({
        message,
        showDeleteMessageModal: true,
        channelId: channel.id || "",
      })
    );
  };

  return (
    <MessageBubbleUI
      setQuotedMessage={setQuotedMessage}
      otherData={{ sentByTheUser: sentByUser }}
      message={message}
      jumpToMessage={jumpToMessage}
      openTicketCreate={openTicketCreate}
      mappedTicket={ticketMap[message?.id]}
      onMessageDelete={onMessageDelete}
    />
  );
};

export const Mention = <
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
>({
  children,
  node: { mentionedUser },
}: MentionProps<StreamChatGenerics>) => (
  <span
    className="t-rounded-sm t-p-0.5 t-text-purple group-data-[sent=true]:t-bg-white"
    data-user-id={mentionedUser.id}
  >
    {children}
  </span>
);

export const Avatar = ({
  image,
  user,
}: {
  image?: string;
  user?: { name?: string } | null;
}) => {
  if (image) {
    return (
      <img
        src={image}
        alt="Pic"
        className="t-h-7 t-w-7 t-rounded-full t-opacity-0 group-[&]/top:t-opacity-100 group-[&]/single:t-opacity-100 t-select-none"
      />
    );
  }

  if (user?.name) {
    return (
      <div className="t-flex t-h-7 t-w-7 t-items-center t-opacity-0 group-[&]/top:t-opacity-100 t-justify-center t-rounded-full t-bg-purple-20 t-text-text-60 group-[&]/single:t-opacity-100 t-select-none t-flex-shrink-0">
        <span>{getWholeChar(user.name?.toString(), 0)}</span>
      </div>
    );
  }

  return null;
};

const ForwardMessage = (props: {
  message: MessageUIComponentProps["message"] & {
    forwarded_message?: MessageUIComponentProps["message"];
  };
  otherData: any;
  setQuotedMessage?: any;
  jumpToMessage?: any;
  openTicketCreate?: (messageId: string) => void;
  mappedTicket?: Record<string, any>;
  onMessageDelete?: (message: StreamMessage) => void;
  hideOptions?: boolean;
  theme?: "light" | "dark";
}) => {
  const {
    message,
    otherData: { sentByTheUser },
    theme = "dark",
    hideOptions,
  } = props;

  const { channel } = useChannelStateContext();
  const messageToScrollTo = useAppSelector(
    (store) => store.messageToScrollTo.messageId
  );

  const dispatch = useDispatch();

  const channelUrl = channel?.id as string;

  const forwardedMessage = useGetMessage(
    message?.forwarded_message?.id!,
    message.id,
    channelUrl
  );

  const scrolledToThisMessage = messageToScrollTo === message?.id;
  const isDeletedMessage = message?.type === MESSAGE_TYPE_DELETED;
  const isPinned = message?.pinned;
  const isSharedOnWhatsapp = (message?.custom_data as MessageCustomData)
    ?.is_sent_on_whats_app;

  const isAnnouncementChannel =
    channel?.data?.custom_type === ANNOUNCEMENTS_CHANNEL;

  if (message.forwarded_message && forwardedMessage) {
    return (
      <motion.div
        initial={{ y: 20 }}
        animate={{ y: -0 }}
        className="t-my-5 group-[&]/middle:t-my-1.5 group-[&]/top:t-mb-1.5 group-[&]/bottom:t-mt-1.5"
      >
        <MessageLayout {...props}>
          <div
            className={cx(
              "t-hyphens-auto t-relative t-order-1 t-flex-col t-gap-2.5 t-word-break t-text-body group-data-[sent=true]:t-order-2",
              {
                "!t-bg-orange-10": isPinned,
                "t-rounded-lg t-rounded-tr-sm t-bg-purple-10 t-text-purple-100":
                  sentByTheUser,
                "t-rounded-lg t-rounded-tl-sm t-text-text-60": !sentByTheUser,
                "t-p-3 t-px-2.5": (message.attachments?.length || 0) > 0,
                "t-p-2 t-pt-1.5": (message.attachments?.length || 0) === 0,
                "t-animate-activate": scrolledToThisMessage,
                "t-bg-yellow-10": isAnnouncementChannel,
                "t-max-w-[var(--message-max-width)]":
                  message?.custom_type !== CHAT_CARD_TYPE,
                "t-max-w-[306px]": message?.custom_type === CHAT_CARD_TYPE,
                "t-bg-white": !sentByTheUser && theme === "dark" && !isPinned,
                "t-bg-surface-grey":
                  !sentByTheUser && theme === "light" && !isPinned,
              }
            )}
          >
            {!isDeletedMessage && !hideOptions && <Options {...props} />}
            <div className="t-flex t-mb-2 t-gap-2 t-mr-5 t-items-center">
              <p className="all:unset t-flex t-mb-0 t-gap-1 t-text-body-sm t-text-text-60 t-items-center">
                <ForwardIcon />
                <span className="t-text-subtext-sm">
                  {sentByTheUser ? "You" : message.user?.name}
                </span>{" "}
                <span className="t-text-text-30">forwarded this message</span>
              </p>
            </div>
            <div className="t-flex">
              <div className="t-border-l-4 t-border-0 t-border-solid t-rounded t-border-l-purple-30 t-border-neutral-10">
                {/* @ts-ignore */}
                <MessageBody
                  {...props}
                  jumpToMessage={(id) => dispatch(setScrollToMessageId(id))}
                  message={forwardedMessage}
                />
                <ConditionalLink
                  to={`/chat/${forwardedMessage?.cid?.replace(
                    "messaging:",
                    ""
                  )}/${forwardedMessage?.id}`}
                  className="t-px-2 t-pb-1.5 t-text-[10px] t-text-text-30 hover:!t-underline t-flex t-gap-1 t-items-center t-mt-1.5"
                >
                  <MessageDateFormat createdAt={forwardedMessage?.created_at} />
                  <span className="t-text-neutral-20">
                    <DividerLine />
                  </span>
                  <span>View message</span>
                </ConditionalLink>
              </div>
            </div>
            <span className="t-mt-2 t-inline-block">
              <MessageText message={message} sentByTheUser={sentByTheUser} />
            </span>
            <MessageDate
              sentByTheUser={sentByTheUser}
              createdAt={message.created_at}
              isSharedOnWhatsapp={isSharedOnWhatsapp}
            />
          </div>
        </MessageLayout>
      </motion.div>
    );
  }

  return null;
};

export const MessageBubbleUI = (props: {
  message: MessageUIComponentProps["message"] & {
    forwarded_message?: MessageUIComponentProps["message"];
  };
  otherData: any;
  setQuotedMessage?: any;
  jumpToMessage?: any;
  openTicketCreate?: (messageId: string) => void;
  mappedTicket?: Record<string, any>;
  onMessageDelete?: (message: StreamMessage) => void;
  hideOptions?: boolean;
  theme?: "light" | "dark";
}) => {
  const {
    message,
    otherData: { sentByTheUser },
    hideOptions,
  } = props;

  const dispatch = useDispatch();

  const isDeletedMessage = message?.type === MESSAGE_TYPE_DELETED;
  const isSharedOnWhatsapp = (message?.custom_data as MessageCustomData)
    ?.is_sent_on_whats_app;

  if (message.forwarded_message && !isDeletedMessage) {
    return <ForwardMessage {...props} />;
  }

  return (
    <motion.div
      initial={{ y: 20 }}
      animate={{ y: -0 }}
      className="t-my-5 group-[&]/middle:t-my-1.5 group-[&]/top:t-mb-1.5 group-[&]/bottom:t-mt-1.5"
    >
      <MessageLayout {...props}>
        <MessageBody
          {...props}
          jumpToMessage={(id) => dispatch(setScrollToMessageId(id))}
          Date={
            <MessageDate
              sentByTheUser={sentByTheUser}
              createdAt={message.created_at}
              isSharedOnWhatsapp={isSharedOnWhatsapp}
            />
          }
        >
          {!isDeletedMessage && !hideOptions && <Options {...props} />}
        </MessageBody>
      </MessageLayout>
    </motion.div>
  );
};
