import { emptyApi } from "./emptyApi";
import qs from "qs";
import { Partner } from "types/Models/partners";

type GetPartnersProps = {
  pageNumber?: number | number;
  searchTerm?: string | null;
  viewFilter: string | null;
  subscriptionFilter?: string | null;
  agentFilter?: string | null;
  selectedTag?: string | null;
};

type PartnersResponse = {
  perks: Partner[];
  current_page: number;
  total_count: number;
  total_pages: number;
};

export type PerkTag = {
  bg_color_hex: string;
  color_hex: string;
  tag_category: number;
  title: string;
  uuid: string;
};

const extendedApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getAllPartners: build.query<PartnersResponse, GetPartnersProps>({
      query: ({ pageNumber }) => {
        let queryUrl = qs.stringify(
          {
            page_num: pageNumber,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `api/inkle/company/v2/perks/${queryUrl}`,
        };
      },
    }),
    getAllPerkTags: build.query<PerkTag[], void>({
      query: () => "/api/inkle/tags/PERK_TAG/",
    }),

    suggestPerk: build.mutation<
      void,
      { groupId: string; name: string; website: string; notes: string }
    >({
      query: ({ groupId, name, website, notes }) => {
        return {
          url: `api/inkle/reference/group/${groupId}/suggest-perk/`,
          method: "POST",
          body: {
            name,
            website,
            notes,
          },
        };
      },
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetAllPartnersQuery,
  useGetAllPerkTagsQuery,
  useSuggestPerkMutation,
} = extendedApi;
