import classNames from "classnames";
import { ConditionalLink } from "components/conditionalLink";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import Async from "components/DesignSystem/AsyncComponents/Async";
import Table from "components/DesignSystem/Table/V2/Table";
import { StartBookkeepingDateEmptyScreen } from "components/GeneralLedger/GeneralLedgerEmptyScreen";
import { ArrowRight } from "components/icons/ArrowRight";
import { Redirect } from "components/icons/Redirect";
import { motion } from "framer-motion";
import { useAppSelector } from "hooks/useAppSelector";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { ReportsType } from "pages/Books/Reports/Reports";
import { useEffect, useMemo, useState } from "react";
import {
  createColumnHelper,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from "react-table-8.10.7";
import { getFilterStatus } from "store/selector/reportFilter";
import { Account } from "types/Models/report";
import { redirectToCategories } from "utils/redirectToCategories";
import { useViewByReportDataFormat } from "./useViewByReportDataFormat";
import { getCommonPinningStyles } from "utils/getCommonPinningStyles";
import * as Popover from "@radix-ui/react-popover";
import ThreeDots from "static/images/ThreeDots.svg";
import { AddCommentPop } from "components/AddComment/AddComment";
import { stopPropagation } from "utils/stopPropagation";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import { Button } from "components/DesignSystem/Button/Button";
import { AddCommentPopoverRoot } from "components/AddCommentPopoverRoot/AddCommentPopoverRoot";
import { AddCommentAnchor } from "components/AddCommentPopoverAnchor/AddCommentPopoverAnchor";

export const ComparisonReport = ({
  reportType,
  reportId,
}: {
  reportType: ReportsType;
  reportId?: string;
}) => {
  const openComment = useAppSelector((store) => store.openComment.openComment);
  const { formatViewReportData, originalData, isFetching, isSuccess } =
    useViewByReportDataFormat({
      reportType,
      reportId,
    });

  const { getFilterName, capsuleFilters } = useAppSelector(getFilterStatus);
  const { isAdmin } = useRoleBasedView();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();

  const { report_data = {} } = originalData || {};

  const reportDatabyView = useMemo(() => formatViewReportData, [isFetching]);

  const columnHelper = createColumnHelper<Account>();

  const viewByColumns = Object.keys(report_data).map((name) =>
    columnHelper.accessor("summary_amount_by_view", {
      id: name,
      size: 114,
      cell: ({ getValue }) => {
        const summary_amount_by_view = getValue() || {};

        const amount = summary_amount_by_view[name!];
        if (!Boolean(amount)) {
          return <div className="t-flex t-justify-end t-ml-4">-</div>;
        }

        return (
          <div className="t-flex t-justify-end t-ml-4">
            <AmountSuperScript amount={Number(amount)} />
          </div>
        );
      },
      header: () => <div className="t-flex t-justify-end t-ml-4">{name}</div>,
    })
  );

  const columns = [
    columnHelper.accessor("name", {
      header: "",
      size: 300,
      cell: ({ row, getValue }) => {
        const name = getValue();

        return (
          <div
            style={{
              paddingLeft: `${row.depth * 1}rem`,
            }}
            className={classNames("t-flex t-items-center")}
          >
            <div
              className={classNames({
                "t-rotate-90 t-transform t-transition t-duration-300 t-ease-in-out":
                  row.getIsExpanded(),
                "t-invisible": !row.getCanExpand(),
              })}
            >
              <ArrowRight stroke="1.5" color="currentColor" />
            </div>
            <div className="t-flex t-justify-end t-h-full t-items-center t-gap-3">
              {name}
              {row.depth > 0 && (
                <span className="group-hover:t-visible t-invisible">
                  <ConditionalLink
                    to={redirectToCategories({
                      uuid: row.original.uuid,
                      groupId,
                      entityId,
                      capsuleFilters,
                      getFilterName,
                      isAdmin,
                    })}
                  >
                    <Redirect size={16} />
                  </ConditionalLink>
                </span>
              )}
            </div>
          </div>
        );
      },
    }),
    ...viewByColumns,
    columnHelper.display({
      header: "",
      id: "actions",
      cell: ({ row }) => {
        return (
          <AddCommentPopoverRoot contentId={row.original.uuid}>
            <Dropdown.Root>
              <Dropdown.Trigger asChild onClick={stopPropagation}>
                <div>
                  <AddCommentAnchor contentId={row.original.uuid}>
                    <Button customType="ghost_icon" size="small">
                      <img src={ThreeDots} alt="Action" />
                    </Button>
                  </AddCommentAnchor>
                </div>
              </Dropdown.Trigger>
              <Dropdown.Portal>
                <Dropdown.Content onClick={stopPropagation} align="end">
                  <Popover.Trigger asChild>
                    <Dropdown.Item>Add comment</Dropdown.Item>
                  </Popover.Trigger>
                </Dropdown.Content>
              </Dropdown.Portal>
              <Popover.Portal>
                <AddCommentPop
                  commentType="TRANSACTION_COMMENT"
                  contentId={row.original.uuid}
                />
              </Popover.Portal>
            </Dropdown.Root>
          </AddCommentPopoverRoot>
        );
      },
      minSize: 60,
      size: 60,
    }),
  ];

  const [expanded, setExpanded] = useState<ExpandedState>({});

  useEffect(() => {
    const getParentTree = (
      ledger: typeof reportDatabyView,
      selectedCategoryId: string | null,
      currentParents: string[] = []
    ): string[] | undefined => {
      for (const category of ledger) {
        if (category.uuid === selectedCategoryId) {
          return currentParents;
        }

        if (category.types) {
          const parent = getParentTree(category.types, selectedCategoryId, [
            category.uuid,
            ...currentParents,
          ]);
          if (parent?.length) {
            return parent;
          }
        }
      }
    };

    if (openComment) {
      const parents = getParentTree(reportDatabyView, openComment);
      if (parents && parents.length > 0) {
        setExpanded((expanded) =>
          typeof expanded === "object"
            ? parents.reduce(
                (acc, id) => ({ ...acc, [id]: true }),
                expanded || {}
              )
            : expanded
        );
      }
    }
  }, [openComment, reportDatabyView]);

  const table = useReactTable({
    data: reportDatabyView,
    columns: columns,
    state: {
      expanded,
      columnPinning: {
        left: ["name"],
        right: ["actions"],
      },
    },
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.types,
    getRowId: (row) => row.uuid,
    getExpandedRowModel: getExpandedRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  const [scrolling, setScrolling] = useState(false);

  return (
    <Async.Root {...{ isEmpty: false, isLoading: isFetching, isSuccess }}>
      <Async.Empty>
        <StartBookkeepingDateEmptyScreen />
      </Async.Empty>
      <Async.Success>
        <div className="t-pb-20 t-relative">
          <Table.Container
            className="t-overflow-scroll scrollbar-visible t-scroll-auto"
            onScroll={(e: React.UIEvent<HTMLDivElement>) => {
              setScrolling(Boolean(e.currentTarget.scrollLeft));
            }}
          >
            <Table.Content className="t-w-full">
              <Table.Head>
                {table.getHeaderGroups().map((headerGroup) => (
                  <Table.HeadRow key={headerGroup.id}>
                    {headerGroup.headers.map((header, index) => {
                      const isPinned = header.column.getIsPinned();
                      const styles = getCommonPinningStyles(header.column);
                      return (
                        <Table.HeadCell
                          key={header.id}
                          style={{
                            ...styles,
                            minWidth: header.column.getSize(),
                          }}
                          className={classNames({
                            "t-shadow-pinned-column": isPinned === "left",
                            "t-shadow-pinned-column-right":
                              isPinned === "right",
                          })}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </Table.HeadCell>
                      );
                    })}
                  </Table.HeadRow>
                ))}
              </Table.Head>
              <Table.Body>
                {table.getRowModel().rows.map((row) => {
                  return (
                    <motion.tr
                      key={row.id}
                      aria-label={row.original.name}
                      aria-disabled={!row.getCanExpand()}
                      aria-expanded={row.getIsExpanded()}
                      layout
                      onClick={() => {
                        row.toggleExpanded();
                      }}
                      className={classNames(
                        "t-select-none t-px-3 t-text-body t-group t-border t-border-b t-border-solid t-border-t-0 t-border-x-0 t-border-neutral-0",
                        {
                          "t-text-text-100": row.depth === 0,
                          "t-text-text-60": row.depth === 1,
                          "t-text-text-30": row.depth >= 2,
                          "t-cursor-pointer hover:t-bg-surface-lighter-grey":
                            row.getCanExpand(),
                        }
                      )}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.4, ease: "easeInOut" }}
                    >
                      {row.getVisibleCells().map((cell) => {
                        const isPinned = cell.column.getIsPinned();
                        const styles = getCommonPinningStyles(cell.column);

                        return (
                          <Table.Cell
                            key={cell.id}
                            style={{
                              ...styles,
                              minWidth: cell.column.getSize(),
                            }}
                            className={classNames({
                              "t-shadow-pinned-column": isPinned === "left",
                              "t-shadow-pinned-column-right":
                                isPinned === "right",
                            })}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </Table.Cell>
                        );
                      })}
                    </motion.tr>
                  );
                })}
              </Table.Body>
            </Table.Content>
          </Table.Container>
        </div>
      </Async.Success>
    </Async.Root>
  );
};
