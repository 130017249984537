import { Button } from "components/DesignSystem/Button/Button";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import { CaretDown } from "components/icons/CaretDown";
import {
  DOWNLOAD_EXCEL,
  DOWNLOAD_PDF,
  GENERAL_LEDGER,
  TRIAL_BALANCE,
} from "constants/bookkeeping";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { GeneralLedgerReportType } from "pages/Books/Reports/Reports";
import { useSelector } from "react-redux";
import { useExportLedgerMutation } from "store/apis/generalLedger";
import { useLazyGetPreviewUrlQuery } from "store/apis/previewUrl";
import { RootState } from "store/store";
import { BackendError } from "types/utils/error";
import { openLink } from "utils/openLink";

export const ExportLedger = () => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { alertToast } = useToast();
  const [getPreviewUrl] = useLazyGetPreviewUrlQuery();

  const [exportGeneralLedger, { isLoading: isExporting }] =
    useExportLedgerMutation();

  const {
    filters: {
      cashFlow,
      minAmount,
      maxAmount,
      accountingMethod,
      startDate,
      endDate,
      hideZero,
      categoryIds,
    },
  } = useSelector((state: RootState) => state.ledgerFilter);

  const filterValues = {
    cashFlow,
    minAmount: ((minAmount.value || "") as string)
      ?.replaceAll("$", "")
      .replaceAll(",", ""),
    maxAmount: ((maxAmount.value || "") as string)
      ?.replaceAll("$", "")
      .replaceAll(",", ""),
    accountingMethod,
    startDate,
    endDate,
    hideZero,
    categoryIds,
  };

  const onExport = async (
    reportType: GeneralLedgerReportType,
    downloadType: "DOWNLOAD_EXCEL" | "DOWNLOAD_PDF"
  ) => {
    try {
      const { general_ledger_document_id } = await exportGeneralLedger({
        groupId,
        entityId,
        ...filterValues,
        reportType,
        downloadType,
      }).unwrap();
      const { download_url } = await getPreviewUrl({
        groupId,
        fileId: general_ledger_document_id,
      }).unwrap();
      openLink(download_url);
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  return (
    <Dropdown.Root>
      <Dropdown.Trigger asChild className="t-group">
        <div>
          <Button
            customType="primary"
            size="small"
            onClick={(e) => e.stopPropagation()}
            isLoading={isExporting}
            disabled={isExporting}
          >
            <div className="t-flex t-items-center t-gap-1.5">
              Export
              <div className="group-data-state-open:t-rotate-180">
                <CaretDown />
              </div>
            </div>
          </Button>
        </div>
      </Dropdown.Trigger>
      <Dropdown.Portal>
        <Dropdown.Content sideOffset={4} align="end" side="bottom">
          <Dropdown.Sub>
            <Dropdown.SubTrigger asChild>General Ledger</Dropdown.SubTrigger>
            <Dropdown.Portal>
              <Dropdown.SubContent sideOffset={4} alignOffset={40}>
                <Dropdown.Item
                  onSelect={() => onExport(GENERAL_LEDGER, DOWNLOAD_PDF)}
                >
                  PDF
                </Dropdown.Item>
                <Dropdown.Item
                  onSelect={() => onExport(GENERAL_LEDGER, DOWNLOAD_EXCEL)}
                >
                  Excel
                </Dropdown.Item>
              </Dropdown.SubContent>
            </Dropdown.Portal>
          </Dropdown.Sub>
          <Dropdown.Sub>
            <Dropdown.SubTrigger asChild>Trial Balance</Dropdown.SubTrigger>
            <Dropdown.Portal>
              <Dropdown.SubContent sideOffset={4}>
                <Dropdown.Item
                  onSelect={() => onExport(TRIAL_BALANCE, DOWNLOAD_PDF)}
                >
                  PDF
                </Dropdown.Item>
                <Dropdown.Item
                  onSelect={() => onExport(TRIAL_BALANCE, DOWNLOAD_EXCEL)}
                >
                  Excel
                </Dropdown.Item>
              </Dropdown.SubContent>
            </Dropdown.Portal>
          </Dropdown.Sub>
        </Dropdown.Content>
      </Dropdown.Portal>
    </Dropdown.Root>
  );
};
