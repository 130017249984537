export const EmptyVendor = () => (
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="21" cy="21" r="20.5" fill="#FAFAFB" stroke="#F3F3F5" />
    <g clip-path="url(#clip0_1053_1530)">
      <path
        d="M30.0754 17.4674H10.9253V29.9022H30.0754V17.4674Z"
        fill="#E7E6EB"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.6769 12.892H26.8496C26.9225 13.711 27.2018 14.53 27.7173 15.3497C28.2321 16.168 28.5121 16.9877 28.5828 17.8059H30.9052C30.8112 16.987 30.4539 16.168 29.7911 15.3497C29.1283 14.5307 28.771 13.7117 28.6769 12.892Z"
        fill="#CFCDD6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.0201 12.892H26.8496C26.9225 13.711 27.2018 14.53 27.7173 15.3497C28.2321 16.168 28.5121 16.9877 28.5828 17.8059H26.2605C26.2101 16.987 26.0103 16.168 25.6414 15.3497C25.2724 14.5307 25.0726 13.7117 25.0209 12.892H25.0201Z"
        fill="#FFFFFE"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.0202 12.892H23.1929C23.222 13.711 23.3431 14.5307 23.5647 15.3497C23.7864 16.1687 23.9075 16.9877 23.9366 17.8059H26.259C26.2087 16.987 26.0089 16.168 25.64 15.3497C25.271 14.5307 25.0719 13.7117 25.0202 12.892Z"
        fill="#CFCDD6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.364 12.892H23.1935C23.2227 13.711 23.3437 14.5307 23.5654 15.3497C23.787 16.1687 23.9081 16.9877 23.9372 17.8059H21.6148C21.6068 16.987 21.5645 16.168 21.4894 15.3497C21.4143 14.5315 21.3742 13.7117 21.364 12.892Z"
        fill="#FFFFFE"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.364 12.892H19.5368C19.5244 13.711 19.4857 14.5307 19.4135 15.3497C19.3413 16.168 19.3049 16.9877 19.2925 17.8059H21.6149C21.6069 16.987 21.5646 16.168 21.4895 15.3497C21.4144 14.5315 21.3743 13.7117 21.364 12.892Z"
        fill="#CFCDD6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.708 12.892H19.5375C19.5251 13.711 19.4865 14.5307 19.4143 15.3497C19.3421 16.168 19.3056 16.9877 19.2932 17.8059H16.9708C17.0044 16.987 17.1203 16.168 17.3391 15.3497C17.5578 14.5307 17.6759 13.7117 17.7087 12.892H17.708Z"
        fill="#FFFFFE"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.708 12.892H15.8785C15.826 13.711 15.627 14.5307 15.2617 15.3497C14.8971 16.168 14.7017 16.9877 14.647 17.8059H16.9694C17.0029 16.987 17.1188 16.168 17.3376 15.3497C17.5563 14.5307 17.6752 13.7117 17.708 12.892Z"
        fill="#CFCDD6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.0513 12.892H15.8785C15.826 13.711 15.627 14.5307 15.2617 15.3497C14.8971 16.168 14.7017 16.9877 14.647 17.8059H12.3246C12.3997 16.987 12.6746 16.1672 13.1857 15.3497C13.6983 14.53 13.9761 13.7117 14.0513 12.892Z"
        fill="#FFFFFE"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.0512 12.892H12.2203C12.1241 13.711 11.7682 14.53 11.1083 15.3497C10.4492 16.1672 10.0963 16.987 10 17.8059H12.3246C12.3997 16.987 12.6746 16.1672 13.1857 15.3497C13.6983 14.53 13.9761 13.7117 14.0512 12.892Z"
        fill="#CFCDD6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.6738 29.8703H23.4616V22.4454C23.4616 22.3897 23.4005 22.344 23.3249 22.344H17.8097C17.7349 22.344 17.6731 22.3897 17.6731 22.4454L17.6738 29.8703Z"
        fill="#CFCDD6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M30.8987 17.7332H28.577V18.6868C28.577 19.3646 29.0991 19.9193 29.7379 19.9193C30.3766 19.9193 30.8994 19.3646 30.8994 18.6868V17.7332H30.8987Z"
        fill="#B8B5C2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.2546 17.7332H28.5763V18.6868C28.5763 19.3646 28.0542 19.9193 27.4155 19.9193C26.7767 19.9193 26.2539 19.3646 26.2539 18.6868V17.7332H26.2546Z"
        fill="#E8E8E7"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.6105 17.7332H23.9321V18.6868C23.9321 19.3646 23.4101 19.9193 22.7713 19.9193C22.1326 19.9193 21.6097 19.3646 21.6097 18.6868V17.7332H21.6105Z"
        fill="#E8E8E7"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.6105 17.7332H19.2888V18.6868C19.2888 19.3646 19.8109 19.9193 20.4497 19.9193C21.0884 19.9193 21.6112 19.3646 21.6112 18.6868V17.7332H21.6105Z"
        fill="#B8B5C2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.9672 17.7332H19.2888V18.6868C19.2888 19.3646 18.7667 19.9193 18.128 19.9193C17.4892 19.9193 16.9664 19.3646 16.9664 18.6868V17.7332H16.9672Z"
        fill="#E8E8E7"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.9672 17.7332H14.6455V18.6868C14.6455 19.3646 15.1676 19.9193 15.8063 19.9193C16.4451 19.9193 16.9679 19.3646 16.9679 18.6868V17.7332H16.9672Z"
        fill="#B8B5C2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.3231 17.7332H14.6448V18.6868C14.6448 19.3646 14.1227 19.9193 13.4839 19.9193C12.8452 19.9193 12.3224 19.3646 12.3224 18.6868V17.7332H12.3231Z"
        fill="#E8E8E7"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.3231 17.7332H10.0007V18.6868C10.0007 19.3646 10.5228 19.9193 11.1616 19.9193C11.8003 19.9193 12.3231 19.3646 12.3231 18.6868V17.7332Z"
        fill="#B8B5C2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.7115 13.1504H12.1539C12.1488 12.591 12.4707 12.0752 13.4693 12.0752H27.391C28.3867 12.0752 28.7027 12.5954 28.7115 13.1504Z"
        fill="#B8B5C2"
      />
      <path d="M30.9993 29.8105H10V31.0007H30.9993V29.8105Z" fill="#B8B5C2" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.2545 17.7332H23.9329V18.6868C23.9329 19.3646 24.4549 19.9193 25.0937 19.9193C25.7324 19.9193 26.2553 19.3646 26.2553 18.6868V17.7332H26.2545Z"
        fill="#B8B5C2"
      />
    </g>
    <defs>
      <clipPath id="clip0_1053_1530">
        <rect
          width="21"
          height="20"
          fill="white"
          transform="translate(10 11)"
        />
      </clipPath>
    </defs>
  </svg>
);
