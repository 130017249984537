import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { Filter } from "components/DesignSystem/Filter/Filter";
import { Capsule } from "components/Transaction/Filter";
import { CASH_FLOW_STATEMENT, monthPeriod } from "constants/bookkeeping";
import {
  DD_MMM_YYYY,
  MMM_YYYY,
  Q_YYYY,
  YYYY,
  YYYY_MM_DD,
} from "constants/date";
import dayjs from "dayjs";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { parse } from "qs";
import { ChangeEvent, ComponentProps, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getFilterStatus } from "store/selector/reportFilter";
import { FilterName, setReportFilters } from "store/slices/reportFilter";
import Radio from "components/DesignSystem/RadioGroup/RadioGroup";
import { ReportsType } from "pages/Books/Reports/Reports";
import { viewByOption, ViewOptions } from "constants/reports";
import { DateFilter } from "components/Filters/DateFilter";
import { DateRangeValue, getDateRange } from "utils/getDateRange";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import { Chip } from "components/DesignSystem/Chips/Chips";
import { ViewByOption } from "store/apis/reports";

dayjs.extend(quarterOfYear);

const CapsuleFilters = ({ reportType }: { reportType: ReportsType }) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const { update, updateMultiple } = useUpdateQuery();
  const { capsuleFilters, getFilterName } = useSelector(getFilterStatus);
  const startDate = query.get("startDate");
  const endDate = query.get("endDate");
  const view = query.get("view") as ViewByOption;
  const hideZero = query.get("hideZero");

  const prevReportTypeRef = useRef<string | null>(null);
  const prevReportType = prevReportTypeRef.current;

  const handleClick = ({ name }: { name: FilterName }) => {
    dispatch(setReportFilters({ [name]: undefined }));
    update({ query: name, value: null });
  };

  useEffect(() => {
    if (!endDate && !startDate && !hideZero) {
      updateMultiple([
        {
          query: "startDate",
          value: dayjs().startOf("year").format(YYYY_MM_DD),
        },
        {
          query: "endDate",
          value: dayjs().format(YYYY_MM_DD),
        },
        { query: "hideZero", value: true },
        {
          query: "accountingMethod",
          value: reportType === CASH_FLOW_STATEMENT ? "ACCRUAL" : "CASH",
        },
      ]);
    } else if (prevReportType === null || reportType !== prevReportType) {
      updateMultiple([
        {
          query: "accountingMethod",
          value: reportType === CASH_FLOW_STATEMENT ? "ACCRUAL" : "CASH",
        },
      ]);
    }
    prevReportTypeRef.current = reportType;
  }, [endDate, startDate, hideZero, reportType]);

  const dateFormat = {
    MONTHLY: MMM_YYYY,
    YEARLY: YYYY,
    QUARTERLY: Q_YYYY,
    TOTALS: DD_MMM_YYYY,
  };

  return (
    <>
      {(startDate || endDate) && (
        <Chip onClose={() => {}} isRemovable={false} isActive filterType="view">
          {view === "QUARTERLY" ? (
            <span>
              Q{dayjs(startDate).format(dateFormat[view])}
              <>
                {" to "}Q{dayjs(endDate).format(dateFormat[view])}
              </>
            </span>
          ) : (
            <span>
              {dayjs(startDate).format(dateFormat[view] || DD_MMM_YYYY)}
              {view === "YEARLY" ? "-" : " to "}
              {dayjs(endDate).format(dateFormat[view] || DD_MMM_YYYY)}
            </span>
          )}
        </Chip>
      )}

      {capsuleFilters
        .filter(
          ({ type }) => !["view", "reportDate", "reportDate"].includes(type)
        )
        .map(({ name, value, type }) => {
          let filterValue = value;
          if (type === "reportDate" && typeof value === "string") {
            filterValue = dayjs(value).format(DD_MMM_YYYY);
          } else if (Array.isArray(value)) {
            filterValue = `(${value.length})`;
          }

          return (
            <Capsule
              key={name}
              value={filterValue}
              onCapsuleClick={() => {
                if (
                  !(
                    (type === "reportDate" || type === "accounting") &&
                    typeof value === "string"
                  )
                ) {
                  handleClick({ name: getFilterName(name)! as FilterName });
                }
              }}
              isRemovable={
                type === "reportDate" || type === "accounting" ? false : true
              }
              type={type}
            >
              {name}
            </Capsule>
          );
        })}
    </>
  );
};

const ReportDateFilter = ({
  type = "date",
}: {
  type?: ComponentProps<typeof DateFilter>["pickerType"];
}) => {
  const { filters } = useSelector(getFilterStatus);
  const [startDate, endDate] = filters.reportDate;
  const { updateMultiple } = useUpdateQuery();

  const initialValues = {
    START_DATE: startDate.value ? new Date(startDate.value as string) : "",
    END_DATE: endDate.value ? new Date(endDate.value as string) : "",
  };

  const updateFilter: ComponentProps<typeof DateFilter>["updateFilter"] = (
    name,
    value
  ) => {
    if (name === "SELECT_PERIOD") {
      const { startDate, endDate } = getDateRange(value as DateRangeValue);
      return updateMultiple([
        { query: "startDate", value: startDate.format(YYYY_MM_DD) },
        { query: "endDate", value: endDate.format(YYYY_MM_DD) },
      ]);
    }

    const updateQuery = [
      name === "START_DATE" && {
        query: "startDate",
        value: value,
      },
      name === "END_DATE" && {
        query: "endDate",
        value: value,
      },
    ].filter(Boolean);

    updateMultiple(updateQuery);
  };

  return (
    <div className="t-flex t-flex-col t-gap-3 t-w-full">
      {type === "date" && (
        <DateFilter
          skipDateUpdateOnPeriodChange
          pickerType="date"
          values={{
            SELECT_PERIOD: "",
            START_DATE: initialValues.START_DATE.toString(),
            END_DATE: initialValues.END_DATE.toString(),
          }}
          updateFilter={updateFilter}
        />
      )}

      {type === "year" && (
        <DateFilter
          skipDateUpdateOnPeriodChange
          pickerType="year"
          values={{
            SELECT_PERIOD: "",
            START_DATE: initialValues.START_DATE.toString(),
            END_DATE: initialValues.END_DATE.toString(),
          }}
          updateFilter={updateFilter}
          showPeriodSelector={false}
        />
      )}

      {type === "quarter" && (
        <DateFilter
          skipDateUpdateOnPeriodChange
          pickerType="quarter"
          values={{
            SELECT_PERIOD: "",
            START_DATE: initialValues.START_DATE.toString(),
            END_DATE: initialValues.END_DATE.toString(),
          }}
          updateFilter={updateFilter}
          showPeriodSelector={false}
        />
      )}

      {type === "month" && (
        <DateFilter
          skipDateUpdateOnPeriodChange
          pickerType="month"
          values={{
            SELECT_PERIOD: "",
            START_DATE: initialValues.START_DATE.toString(),
            END_DATE: initialValues.END_DATE.toString(),
          }}
          updateFilter={updateFilter}
          datePeriod={monthPeriod}
        />
      )}
    </div>
  );
};

const AccountingMethod = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const query = parse(search, { ignoreQueryPrefix: true });
  const { update } = useUpdateQuery();
  const { filters } = useSelector(getFilterStatus);
  const [accountingMethod] = filters.accountingMethod;

  const option = [
    { value: "CASH", label: "Cash method" },
    { value: "ACCRUAL", label: "Accrual method" },
  ];

  const onChange = (value: string) => {
    dispatch(setReportFilters({ accountingMethod: value }));
    update({
      query: "accountingMethod",
      value: value,
    });
  };

  return (
    <Radio.Root
      disabled={query.reportType === CASH_FLOW_STATEMENT ? true : false}
      defaultValue={accountingMethod?.value?.toString().toUpperCase() as string}
      onValueChange={onChange}
    >
      <Radio.Content className="t-gap-3 t-flex t-flex-col">
        {option.map((item) => (
          <Radio.Item key={item.value} value={item.value}>
            {item.label}
          </Radio.Item>
        ))}
      </Radio.Content>
    </Radio.Root>
  );
};

const Others = () => {
  const { filters } = useSelector(getFilterStatus);
  const { update } = useUpdateQuery();
  const dispatch = useDispatch();
  const [hideZero] = filters.others;

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    if (checked) {
      update({ query: "hideZero", value: true });
    } else {
      update({ query: "hideZero", value: null });
      dispatch(setReportFilters({ hideZero: undefined }));
    }
  };

  return (
    <>
      <Checkbox
        name="hideZero"
        label="Hide zero balance accounts"
        onChange={onChange}
        checked={Boolean(hideZero?.value)}
      />
    </>
  );
};

const dateFilterMap: {
  [P in ViewOptions]: ComponentProps<typeof DateFilter>["pickerType"];
} = {
  TOTALS: "date",
  MONTHLY: "month",
  QUARTERLY: "quarter",
  YEARLY: "year",
};

const ViewBy = () => {
  const dispatch = useDispatch();
  const { updateMultiple } = useUpdateQuery();
  const { filters } = useSelector(getFilterStatus);
  const [view] = filters.view;
  const [startDate, endDate] = filters.reportDate;

  const onChange = (value: string) => {
    const selectedView = value as ViewOptions;
    let updatedStartDate = dayjs(startDate.value as string);
    let updatedEndDate = dayjs(endDate.value as string);

    if (selectedView === "MONTHLY") {
      updatedStartDate = updatedStartDate.startOf("month");
      updatedEndDate = updatedEndDate.endOf("month");
    }

    if (selectedView === "QUARTERLY") {
      updatedStartDate = updatedStartDate.startOf("quarter");
      updatedEndDate = updatedEndDate.endOf("quarter");
    }

    if (selectedView === "YEARLY") {
      updatedStartDate = updatedStartDate.startOf("year");
      updatedEndDate = updatedEndDate.endOf("year");
    }

    if (updatedEndDate.isAfter(dayjs())) {
      updatedEndDate = dayjs();
    }

    dispatch(setReportFilters({ view: value }));
    updateMultiple([
      {
        query: "view",
        value: value,
      },
      {
        query: "startDate",
        value: updatedStartDate.format(YYYY_MM_DD),
      },
      {
        query: "endDate",
        value: updatedEndDate.format(YYYY_MM_DD),
      },
    ]);
  };

  const filterType = dateFilterMap[view.value as keyof typeof dateFilterMap];

  return (
    <Radio.Root
      defaultValue={view?.value?.toString().toUpperCase() as string}
      onValueChange={onChange}
    >
      <Radio.Content className="t-gap-3 t-flex t-flex-col">
        {viewByOption.map((item) => (
          <div className="t-flex t-flex-col" key={item.value}>
            <Radio.Item key={item.value} value={item.value}>
              {item.label}
            </Radio.Item>

            {item.value === view.value && (
              <div className="t-py-5">
                <ReportDateFilter type={filterType} />
              </div>
            )}
          </div>
        ))}
      </Radio.Content>
    </Radio.Root>
  );
};

export const ReportFilter = ({ reportType }: { reportType: ReportsType }) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  let query = parse(search, { ignoreQueryPrefix: true });
  const { appliedFilterCount } = useSelector(getFilterStatus);

  useEffect(() => {
    const editQuery = { ...query };
    delete editQuery.company;
    delete editQuery.entity;
    delete editQuery.page;
    delete editQuery.reportType;
    delete editQuery.category;
    dispatch(setReportFilters(editQuery));
  }, [search]);

  return (
    <div className="t-flex t-flex-wrap t-gap-2">
      <Filter.Root
        defaultValue="view"
        title={
          <span className="t-text-body t-font-medium t-leading-none">
            Filters {appliedFilterCount ? <>({appliedFilterCount})</> : ""}
          </span>
        }
        capsule={<CapsuleFilters reportType={reportType} />}
      >
        <Filter.Portal>
          <Filter.List>
            <Filter.ListItem value="view">View by</Filter.ListItem>
            <Filter.ListItem value="accounting">
              Accounting method
            </Filter.ListItem>
            <Filter.ListItem value="others">Others</Filter.ListItem>
          </Filter.List>
          <Filter.Body value="view" block>
            <ViewBy />
          </Filter.Body>
          <Filter.Body value="accounting" block>
            <AccountingMethod />
          </Filter.Body>
          <Filter.Body value="others" block>
            <Others />
          </Filter.Body>
        </Filter.Portal>
      </Filter.Root>
    </div>
  );
};
