import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { useToast } from "hooks/useToast";
import { useEditVendorsMutation } from "store/apis/vendors";

type MarkMerchantAsVendorProps = {
  show: boolean;
  closeModal: () => void;
  groupId: string;
  merchantId: string;
  season: string;
};

export const MarkMerchantAsVendor = ({
  show,
  closeModal,
  groupId,
  merchantId,
  season,
}: MarkMerchantAsVendorProps) => {
  const { alertToast, successToast } = useToast();
  const [editVendor, { isLoading: isEditing }] = useEditVendorsMutation();

  const markMerchantAsVendor = async () => {
    try {
      await editVendor({
        groupId,
        uuid: merchantId,
        payload: { merchant_type: "SYSTEM_REVIEWED", season },
      }).unwrap();
      successToast({ message: "Vendor has been updated" });
      closeModal();
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  return (
    <Modal.Root open={show} onOpenChange={closeModal}>
      <Modal.Portal>
        <Modal.Overlay />
        <Modal.Content className="!t-w-[440px]">
          <Modal.Header>
            <Modal.Title>Mark as a vendor</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            <div className="t-text-body-lg t-text-text-100">
              Are you sure you want to mark this merchant as a vendor?
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="t-flex t-justify-end t-gap-3">
              <Button onClick={closeModal}>Cancel</Button>
              <Button
                disabled={isEditing}
                customType="primary"
                isLoading={isEditing}
                onClick={markMerchantAsVendor}
              >
                Confirm
              </Button>
            </div>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Portal>
    </Modal.Root>
  );
};
