import Loader from "components/design/loader";
import { Button } from "components/DesignSystem/Button/Button";
import { Link } from "components/DesignSystem/Link/Link";
import { Tag } from "components/DesignSystem/Tag/Tag";
import {
  BILLING_INFO_ADDRESS_EDIT_MODAL_OPENED,
  BILLING_INFO_CONTACT_EDIT_MODAL_OPENED,
} from "constants/analyticsEvents";
import { AUTOFILL_ADDRESS_TYPE_TAG, BILLING } from "constants/billing";
import { useAnalytics } from "hooks/useAnalytics";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import authContext from "jwt_context&axios/authContext";
import { ReactNode, useContext, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { useGetAutofillQuery } from "store/apis/autofill";
import {
  useGetBillingInfoQuery,
  useGetTagsForCategoryQuery,
} from "store/apis/billing";
import { useGetTeamMembersQuery } from "store/apis/teamSetting";
import EditBillingAddress from "./EditBillingAddress";
import EditBillingContact from "./EditBillingContact";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";

export const LabelValues = ({
  label,
  value,
}: {
  label: ReactNode;
  value: ReactNode;
}) => (
  <div className="t-mt-4 t-flex-col t-flex t-items-start t-justify-between t-gap-1 t-text-body">
    <div className="t-text-text-30 t-flex-shrink-0 t-text-body-sm">{label}</div>
    <div>
      {value || (
        <span className="t-text-neutral-20">
          Edit to add <span className="t-lowercase">{label}</span>
        </span>
      )}
    </div>
  </div>
);

const InvoiceeDetails = () => {
  const { uuid: groupId, entities } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();

  const { isAdmin } = useRoleBasedView();
  const [showBillingContactEdit, setShowBillingContactEdit] = useState(false);
  const [showBillingAddressEdit, setShowBillingAddressEdit] = useState(false);

  const { data: tagsData = [], isLoading: isTagsLoading } =
    useGetTagsForCategoryQuery({
      tagType: AUTOFILL_ADDRESS_TYPE_TAG,
    });

  const tagId = tagsData.find(({ title }) => title === BILLING)?.uuid;

  const { data = [], isLoading: isAutofillLoading } = useGetAutofillQuery(
    { groupId, autofillKey: "addresses", tagId, entityId },
    { skip: !groupId || !tagId || !entityId }
  );

  const { authtoken } = useContext(authContext);
  const { trackEvent } = useAnalytics();

  const { country, street_address, state, city, zipcode, entity_uuid, uuid } =
    data[0] || {};

  const { data: billingInfo } = useGetBillingInfoQuery(
    {
      group_id: groupId!,
      entityId,
    },
    { skip: !groupId || !entityId }
  );

  const { data: teamMembers } = useGetTeamMembersQuery(
    { groupId },
    { skip: !groupId }
  );

  const billingEntity = entities.find((e) => e.uuid === entity_uuid);

  const openBillingContactEdit = () => {
    setShowBillingContactEdit(true);
    trackEvent(BILLING_INFO_CONTACT_EDIT_MODAL_OPENED, {
      group_id: groupId,
      email: authtoken.email,
    });
  };

  const openBillingAddressEdit = () => {
    setShowBillingAddressEdit(true);
    trackEvent(BILLING_INFO_ADDRESS_EDIT_MODAL_OPENED, {
      group_id: groupId,
      email: authtoken.email,
    });
  };

  const billingContact = teamMembers?.group_users.find(
    (u) => u.email === billingInfo?.billing_email
  );

  const entityLink = isAdmin
    ? `/crm/${groupId}/entity/${billingEntity?.uuid}`
    : `/entity/${billingEntity?.uuid}`;

  if (!data || !teamMembers) {
    return <Loader />;
  }

  return (
    <div className="t-pb-16">
      <div className="t-mt-1">
        <div className="t-text-subtitle t-text-text-100">Billing Details</div>
        <div className="t-text-body t-text-text-30 t-mt-1">
          Details which you want printed on your invoices from Inkle.
        </div>
        <div className="t-mt-5 t-w-[480px] t-rounded-lg t-border-solid t-border t-border-neutral-10 t-px-6 t-py-4 sm:t-w-full">
          <div className="t-flex t-items-center t-justify-between">
            <div className="t-text-subtitle-sm t-text-text-100">
              Billing Contact
            </div>
            <Button size="small" onClick={openBillingContactEdit}>
              Edit
            </Button>
          </div>
          <div className="t-mt-3 t-border-solid t-border-surface-transparent t-border t-border-t-neutral-10">
            <LabelValues label="Name" value={billingContact?.name} />
            <LabelValues label="Email" value={billingContact?.email} />
          </div>
        </div>
        <div className="t-mt-5 t-w-[480px] t-rounded-lg t-border-solid t-border-neutral-10 t-px-6 t-py-4 sm:t-w-full t-border">
          <div className="t-flex t-items-center t-justify-between">
            <div className="t-text-subtitle-sm t-text-text-100">Billed to</div>
            <Button size="small" onClick={openBillingAddressEdit}>
              Edit
            </Button>
          </div>
          <div className="t-mt-3 t-border-solid t-border-surface-transparent t-border-t-neutral-10 t-border">
            <LabelValues
              label="Billing emails"
              value={
                billingInfo?.invoice_emails &&
                billingInfo?.invoice_emails.length > 0 && (
                  <div className="t-flex t-flex-wrap t-gap-2">
                    {billingInfo?.invoice_emails.map((e) => (
                      <Tag tagType="light_grey" icon={false} key={e}>
                        {e}
                      </Tag>
                    ))}
                  </div>
                )
              }
            />

            <LabelValues
              label="Billing entity"
              value={
                billingEntity && (
                  <div className="t-flex t-gap-1 t-items-center">
                    <ReactCountryFlag
                      countryCode={billingEntity.country_code}
                      svg
                    />
                    <Link to={entityLink}>{billingEntity.name}</Link>
                  </div>
                )
              }
            />

            <LabelValues
              label="Billing address"
              value={
                <>
                  {street_address}
                  <br />
                  {country}
                  <br />
                  {state}
                  <br />
                  {city}
                  <br />
                  {zipcode}
                </>
              }
            />
          </div>
        </div>
      </div>

      {showBillingContactEdit && (
        <EditBillingContact
          entityId={entityId}
          show={showBillingContactEdit}
          closeModal={() => setShowBillingContactEdit(false)}
        />
      )}

      {showBillingAddressEdit && (
        <EditBillingAddress
          entityId={entityId}
          show={showBillingAddressEdit}
          closeModal={() => setShowBillingAddressEdit(false)}
          currentBillingAddress={{
            country,
            street_address,
            state,
            city,
            zipcode,
            entity_id: entity_uuid,
            uuid,
          }}
          tagId={tagId!}
        />
      )}
    </div>
  );
};

export default InvoiceeDetails;
