import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import { Tag, TagTypeProps } from "components/DesignSystem/Tag/Tag";
import { Label } from "components/DesignSystem/TextInput/TextInput";
import { DeleteIcon } from "components/icons/delete";
import Pencil from "components/icons/pencil";
import { Preview } from "components/PreviewModal";
import { DD_MMM_YYYY } from "constants/date";
import dayjs from "dayjs";
import { REGISTRATION_TYPE, REGISTRATION_TYPE_BADGE } from "dictionaries";
import { useCopyToClipboard } from "hooks/useCopyToClipboard";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useMultipleFileDetails } from "hooks/useMultipleFileDetails";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useParams } from "react-router-dom";
import CopyIcon from "static/images/CopyPurple.svg";
import { useGetEntityAllStateDataQuery } from "store/apis/entity";
import {
  EntityDataAttribute,
  EntityStateData,
  state_registration_type,
} from "types/Models/entity";
import { FileObject } from "types/Models/fileObject";
import { FileIcon } from "utils/fileTypeIcon";
import AddJurisdiction from "./AddJurisdiction";
import DeleteJurisdictions from "./DeleteJurisdictions";
import EditJurisdiction from "./EditJurisdiction";
import ConditionalToolTip from "components/design/conditionalToolTip";
import classNames from "classnames";

export const FilePrview = ({ file }: { file: FileObject }) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const { file_type, name } = file;

  const {
    close: closePreviewModal,
    isOpen: isPreviewModalOpen,
    open: openPreviewModal,
  } = useModal();

  return (
    <>
      <ConditionalToolTip condition={name}>
        <button
          className="all:unset t-flex t-items-start t-gap-1 t-w-10/12"
          onClick={openPreviewModal}
          type="button"
        >
          <FileIcon fileType={file_type} width={24} height={24} />
          <div className="t-line-clamp-1 t-ml-1 t-text-body t-text-text-black">
            {name}
          </div>
        </button>
      </ConditionalToolTip>
      <Preview
        showModal={isPreviewModalOpen}
        closeModal={closePreviewModal}
        groupId={groupId}
        previewId={file.uuid}
      />
    </>
  );
};

const String = ({
  field,
  showFieldWithEmptyValue,
}: {
  field: EntityDataAttribute;
  showFieldWithEmptyValue?: boolean;
}) => {
  const { can_copy, value } = field;
  const { copyToClipboard } = useCopyToClipboard();

  if (!value && !showFieldWithEmptyValue) return null;

  return (
    <div className="t-flex t-flex-col t-gap-0">
      <Label>{field.label}</Label>
      <div className="t-flex t-items-center t-text-body t-text-text-black t-gap-1">
        <span className="t-line-clamp-1"> {(value as string) || "-"}</span>
        {can_copy && (
          <button
            onClick={() => copyToClipboard(value as string)}
            className="all:unset"
          >
            <img src={CopyIcon} alt="copyIcon" />
          </button>
        )}
      </div>
    </div>
  );
};

const DateField = ({
  field,
  showFieldWithEmptyValue,
}: {
  field: EntityDataAttribute;
  showFieldWithEmptyValue?: boolean;
}) => {
  const { value } = field;

  if (!value && !showFieldWithEmptyValue) return null;

  return (
    <div className="t-flex t-flex-col t-gap-0">
      <Label>{field.label}</Label>
      <div className="t-flex t-items-center t-text-body t-text-text-black">
        {value ? dayjs(value as string).format(DD_MMM_YYYY) : "-"}
      </div>
    </div>
  );
};

const FileField = ({
  field,
  showFieldWithEmptyValue,
}: {
  field: EntityDataAttribute;
  showFieldWithEmptyValue?: boolean;
}) => {
  const { value = [] } = field;
  const files = useMultipleFileDetails(value as string[]);

  if ((value as string[])?.length === 0 && !showFieldWithEmptyValue)
    return null;

  return (
    <div className="t-flex t-flex-col t-gap-0">
      <Label>{field.label}</Label>
      <div className="t-flex t-flex-col t-items-start t-text-body t-text-text-black">
        {files.length > 0
          ? files.map((file) => <FilePrview file={file} key={file.uuid} />)
          : "-"}
      </div>
    </div>
  );
};

const FieldRenderer = ({
  field,
  showFieldWithEmptyValue,
}: {
  field: EntityDataAttribute;
  showFieldWithEmptyValue?: boolean;
}) => {
  const { type } = field;
  switch (type) {
    case "STRING":
      return (
        <String
          field={field}
          showFieldWithEmptyValue={showFieldWithEmptyValue}
        />
      );
    case "NUMBER":
      return (
        <String
          field={field}
          showFieldWithEmptyValue={showFieldWithEmptyValue}
        />
      );
    case "INPUT":
      return (
        <String
          field={field}
          showFieldWithEmptyValue={showFieldWithEmptyValue}
        />
      );
    case "TEXTAREA":
      return (
        <String
          field={field}
          showFieldWithEmptyValue={showFieldWithEmptyValue}
        />
      );
    case "DATE":
      return (
        <DateField
          field={field}
          showFieldWithEmptyValue={showFieldWithEmptyValue}
        />
      );
    case "FILE":
      return (
        <FileField
          field={field}
          showFieldWithEmptyValue={showFieldWithEmptyValue}
        />
      );
    default:
      return null;
  }
};

export const JurisdictionStateCard = ({
  stateData,
  entityId,
  showFieldWithEmptyValue,
  rowGap = "t-gap-y-5",
  stateRegistrationType,
}: {
  stateData: EntityStateData;
  entityId?: string;
  showFieldWithEmptyValue?: boolean;
  rowGap?: string;
  stateRegistrationType?: state_registration_type;
}) => {
  const { isAdmin } = useRoleBasedView();

  const {
    state,
    registration_type,
    foreign_qualified_data_attributes = [],
    state_id,
    uuid,
  } = stateData;

  const isNotHomeState = registration_type !== "HOME_STATE";

  const editModal = useModal();
  const deleteModal = useModal();

  return (
    <div
      className="t-p-5 t-border t-border-solid t-rounded-lg t-border-neutral-10  t-gap-5 t-flex t-flex-col"
      aria-label={`state-card-${uuid}`}
    >
      <div className="t-flex t-items-center t-justify-between">
        <span className="t-text-text-100 t-text-subtitle t-flex t-gap-2 t-items-center">
          {state}
          <Tag
            tagType={
              REGISTRATION_TYPE_BADGE[
                registration_type
              ] as TagTypeProps["tagType"]
            }
            icon={false}
          >
            {REGISTRATION_TYPE[registration_type]}
          </Tag>
        </span>
        <div className="t-flex t-items-center t-gap-2">
          <Button
            size="small"
            type="button"
            customType="ghost_icon"
            onClick={editModal.open}
          >
            <span className="t-text-text-30">
              <Pencil color="currentColor" size="16" />
            </span>
          </Button>
          {isNotHomeState && isAdmin && (
            <Button
              size="small"
              type="button"
              customType="ghost_icon"
              onClick={deleteModal.open}
            >
              <span className="t-text-text-30">
                <DeleteIcon color="currentColor" size="16" />
              </span>
            </Button>
          )}
        </div>
      </div>

      <div className={`t-grid t-grid-cols-2 t-gap-x-5 ${rowGap}`}>
        {foreign_qualified_data_attributes?.map((field) => {
          if (
            stateRegistrationType === "HOME_STATE" &&
            field.name.includes("foreign_qualification_date")
          ) {
            return null;
          }

          return (
            <FieldRenderer
              field={field}
              key={field.uuid}
              showFieldWithEmptyValue={showFieldWithEmptyValue}
            />
          );
        })}
      </div>
      <EditJurisdiction
        isOpen={editModal.isOpen}
        close={editModal.close}
        stateId={state_id}
        entityId={entityId}
      />
      <DeleteJurisdictions
        isOpen={deleteModal.isOpen}
        close={deleteModal.close}
        stateId={state_id}
        entityId={entityId}
      />
    </div>
  );
};

const Jurisdictions = () => {
  const { entityId } = useParams<{ entityId: string }>();
  const { uuid: groupId, entities } = useCurrentGroupContext();

  const entity = entities.find((entity) => entity.uuid === entityId);
  const { country_code } = entity || {};
  const isUSEntity = country_code === "US";

  const {
    data = [],
    isLoading,
    isSuccess,
  } = useGetEntityAllStateDataQuery(
    { entityId, groupId },
    { skip: !groupId || !entityId }
  );

  const { close, isOpen, open } = useModal();

  const isEmpty = data?.length === 0;

  if (isLoading) {
    return null;
  }

  return (
    <Async.Root isEmpty={isEmpty} isLoading={isLoading} isSuccess={isSuccess}>
      <Async.Empty>
        <div className="t-flex t-gap-3 t-flex-col">
          <div className="t-flex t-items-center t-justify-between t-text-subtitle t-text-text-100">
            Jurisdictions
            {isUSEntity && (
              <Button size="small" onClick={open}>
                Add
              </Button>
            )}
          </div>
        </div>
      </Async.Empty>
      <Async.Success>
        <div className="t-flex t-gap-3 t-flex-col">
          <div className="t-flex t-items-center t-justify-between t-text-subtitle t-text-text-100">
            Jurisdictions
            {isUSEntity && (
              <Button size="small" onClick={open}>
                Add
              </Button>
            )}
          </div>
          {data?.map((stateData) => (
            <JurisdictionStateCard stateData={stateData} key={stateData.uuid} />
          ))}
        </div>
      </Async.Success>
      {isOpen && <AddJurisdiction isOpen={isOpen} close={close} />}
    </Async.Root>
  );
};

export default Jurisdictions;
