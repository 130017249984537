export const HidePinIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clip-path="url(#clip0_655_70115)">
      <path
        d="M2 6.5C3.05062 7.80062 4.97688 9.5 8 9.5C11.0231 9.5 12.9494 7.80062 14 6.5"
        stroke="#706A85"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9998 10.5L12.5386 7.94312"
        stroke="#706A85"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99977 12L9.55664 9.34058"
        stroke="#706A85"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 12L6.44312 9.34058"
        stroke="#706A85"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 10.5L3.46125 7.94312"
        stroke="#706A85"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_655_70115">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
