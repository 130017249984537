import Tag from "components/design/Tag";
import { Button } from "components/DesignSystem/Button/Button";
import { PlusIcon } from "components/icons/PlusIcon";
import { ReactNode } from "react";
import {
  ControlProps,
  GroupBase,
  MultiValue,
  MultiValueGenericProps,
  SingleValue,
  components,
} from "react-select";
import {
  Combobox,
  MultiValueContainer,
  OptionData,
} from "../DesignSystem/Combobox/Combobox";
import { Loader } from "../DesignSystem/Loader/Loader";
import classNames from "classnames";

type TagType = {
  uuid: string;
  title: string;
  tag_category: string | number;
  bg_color_hex: string;
  color_hex: string;
};

type TagComboboxProps = {
  tags: TagType[];
  currentTags?: TagType[];
  onTagsChange: (
    selectedTags: MultiValue<OptionData> | SingleValue<OptionData>
  ) => void;
  creatable?: boolean;
  isLoading: boolean;
  size?: "small" | "regular" | "large";
  actions?: ReactNode;
  onCreateOption?: (inputValue: string) => void;
};

const TagComboboxControl = ({
  children,
  ...props
}: ControlProps<OptionData>) => (
  <components.Control
    {...props}
    className={(props.className, "t-flex t-flex-col t-gap-1")}
  >
    {children}
    <span className="t-flex t-justify-start t-w-full t-text-text-30">
      <Button customType="ghost_icon" size="extra-small">
        <PlusIcon color={"#706A85"} />
      </Button>
    </span>
  </components.Control>
);

const TagMultiValueContainer = ({
  children,
  ...props
}: MultiValueGenericProps<OptionData, boolean, GroupBase<OptionData>> & {
  isDisabled?: boolean;
}) => {
  return (
    <components.MultiValueContainer {...props}>
      <button
        type="button"
        disabled={props.data.isDisabled}
        className={classNames(
          `all:unset t-flex t-gap-1.5 t-items-center t-text-body-sm t-bg-neutral-0 t-rounded t-text-text-60 t-px-2 t-select-none t-h-6 t-w-max`,
          {
            "t-cursor-not-allowed": props.data.isDisabled,
            "t-cursor-pointer": !props.data.isDisabled,
          }
        )}
        style={{
          ...(props.data.label?.props?.bg_color_hex
            ? { backgroundColor: props.data.label.props.bg_color_hex }
            : {}),
        }}
      >
        {children}
      </button>
    </components.MultiValueContainer>
  );
};

export const TagCombobox = ({
  tags = [],
  currentTags = [],
  creatable = false,
  onCreateOption,
  onTagsChange,
  isLoading,
  size = "small",
  actions: Actions,
}: TagComboboxProps) => {
  return (
    <div className=" t-w-full! t-flex">
      <div onClick={(e) => e.stopPropagation()} className="t-flex t-gap-1">
        <Combobox
          components={{
            Control: TagComboboxControl,
            DropdownIndicator: () => null,
            ClearIndicator: () => null,
            Placeholder: () => null,
            LoadingIndicator: () => <Loader size="small" />,
            MultiValueContainer: TagMultiValueContainer,
          }}
          menuPortalTarget={document.body}
          isMulti
          styles={{
            menu: () => ({ width: "400px" }),
          }}
          name=""
          options={tags?.map((t: { title: string; uuid: string }) => ({
            label: t.title,
            value: t.uuid,
          }))}
          value={currentTags?.map(
            (tag: {
              uuid: string;
              title: string;
              bg_color_hex: string;
              color_hex: string;
            }) => ({
              value: tag.uuid,
              label: (
                <Tag
                  size="small"
                  key={tag.uuid}
                  title={tag.title}
                  bg_color_hex={tag.bg_color_hex}
                  color_hex={tag.color_hex}
                />
              ),
            })
          )}
          {...(creatable ? { creatable: true, onCreateOption } : {})}
          size={size}
          onChange={(
            selectedTags: SingleValue<OptionData> | MultiValue<OptionData>
          ) => {
            if (selectedTags && selectedTags instanceof Array) {
              onTagsChange?.(
                (selectedTags as { label: string; value: string }[]) || []
              );
            }
          }}
          isLoading={isLoading}
          backspaceRemovesValue={false}
          {...(Actions ? { actions: Actions } : {})}
        />
      </div>
    </div>
  );
};
