import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { Chip } from "components/DesignSystem/Chips/Chips";
import { Filter } from "components/DesignSystem/Filter/Filter";
import Radio from "components/DesignSystem/RadioGroup/RadioGroup";
import { Capsule } from "components/Transaction/Filter";
import { Formik } from "formik";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { parse } from "qs";
import { ChangeEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getVendorsFilterStatus } from "store/selector/vendorsFilterStatus";
import { FiltersValues } from "store/slices/transactionFilter";
import {
  setVendorsFilter,
  VendorsFilterName,
  vendorsInitialState,
  vendorsSeasonOptions,
  vendorsWFormOptions,
} from "store/slices/vendorsFilter";
import { capitalize } from "utils/capitalize";

const CapsuleFilters = () => {
  const { update } = useUpdateQuery();
  const dispatch = useDispatch();
  const { capsuleFilters, fixedFilters, getFilterName } = useSelector(
    getVendorsFilterStatus
  );

  const handleClick = ({ name }: { name: VendorsFilterName }) => {
    dispatch(setVendorsFilter({ [name]: undefined }));
    update({ query: name, value: null });
  };

  const fixedFilterClicked = ({ name }: { name: VendorsFilterName }) => {
    const currentValue = !fixedFilters.filter(
      ({ name: filterName }) => getFilterName(filterName) === name
    )[0]?.value;

    if (name === "wForm") {
      if (Boolean(currentValue)) {
        update({ query: name, value: "wFormRequired" });
        dispatch(setVendorsFilter({ [name]: "wFormRequired" }));
      } else {
        dispatch(setVendorsFilter({ [name]: undefined }));
        update({ query: name, value: null });
      }
      return;
    }

    if (Boolean(currentValue)) {
      update({ query: name, value: currentValue });
    } else {
      dispatch(setVendorsFilter({ [name]: undefined }));
      update({ query: name, value: null });
    }
  };

  const getFilterCapsuleName = (name: string, value: string) => {
    if (name === "W-Form") {
      switch (value) {
        case "wFormRequired":
          return "W-Form Required";
        case "wFormNotRequired":
          return "W-Form Not Required";
        default:
          return "W-Form Required";
      }
    } else if (name === "season") {
      return capitalize(name) + " " + value;
    }
    return capitalize(name);
  };

  return (
    <>
      {fixedFilters.map(({ name, value, type, removable }) => {
        return (
          <Capsule
            isFixedFilter
            key={name}
            value={value ? true : false}
            onCapsuleClick={() =>
              fixedFilterClicked({
                name: getFilterName(name)! as VendorsFilterName,
              })
            }
            type={type}
            isRemovable={removable}
          >
            {getFilterCapsuleName(name, value)}
          </Capsule>
        );
      })}
      {capsuleFilters.map(({ name, value, type, removable }) => {
        return (
          <Capsule
            key={name}
            value={name}
            onCapsuleClick={() =>
              handleClick({ name: getFilterName(name)! as VendorsFilterName })
            }
            type={type}
            isRemovable={removable}
          >
            <></>
          </Capsule>
        );
      })}
    </>
  );
};

const SeasonFilter = () => {
  const { update } = useUpdateQuery();
  const { filters } = useSelector(getVendorsFilterStatus);
  const [season] = filters.season;
  const query = useQuery();
  const seasonFromQuery = query.get("season");
  const dispatch = useDispatch();

  const handleRadioCheck = (value: string) => {
    update({
      query: "season",
      value,
    });
    dispatch(setVendorsFilter({ season: value }));
  };

  return (
    <Formik
      initialValues={{
        season: seasonFromQuery || season.value,
      }}
      onSubmit={() => {}}
    >
      {({ values: { season } }) => (
        <div className="t-flex t-flex-col t-gap-3">
          <div className="t-text-caption t-text-neutral-80 t-w-full">
            Season
          </div>
          <Radio.Root
            onValueChange={handleRadioCheck}
            /* @ts-ignore */
            defaultValue={season || ""}
          >
            <Radio.Content>
              {vendorsSeasonOptions.map((season: string) => (
                <Radio.Item asChild value={season} key={season}>
                  {season}
                </Radio.Item>
              ))}
            </Radio.Content>
          </Radio.Root>
        </div>
      )}
    </Formik>
  );
};

export const isChecked = (array: FiltersValues["value"], uuid: string) => {
  if (Array.isArray(array)) {
    return array?.find((id: string) => id === uuid);
  }
  return false;
};

export const WFormFilters = () => {
  const { update } = useUpdateQuery();
  const dispatch = useDispatch();
  const {
    filters: { wForm },
    getFilterName,
  } = useSelector(getVendorsFilterStatus);
  const query = useQuery();
  const wFormFromQuery = query.get("wForm");

  const handleRadioCheck = (value: string) => {
    update({
      query: "wForm",
      value,
    });
    dispatch(setVendorsFilter({ wForm: value }));
  };

  const resetWForm = () => {
    update({
      query: "wForm",
      value: null,
    });
    dispatch(setVendorsFilter({ wForm: undefined }));
  };

  const wFormValue = wFormFromQuery || wForm[0]?.value;

  return (
    <div className="t-flex t-flex-col t-gap-3">
      <div className="t-text-caption t-text-neutral-80 t-w-full">W-Form</div>
      <Radio.Root onValueChange={handleRadioCheck} value={wFormValue}>
        <Radio.Content>
          {vendorsWFormOptions.map(({ label, value }) => (
            <Radio.Item
              asChild
              value={value}
              key={value}
              checked={value == wFormValue}
            >
              {label}
            </Radio.Item>
          ))}
        </Radio.Content>
      </Radio.Root>
      <button
        className="all:unset hover:t-underline t-text-purple t-text-subtext"
        onClick={resetWForm}
      >
        Reset
      </button>
    </div>
  );
};

export const VendorsFilter = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { appliedFiltersCount } = useSelector(getVendorsFilterStatus);

  useEffect(() => {
    let query = parse(search, { ignoreQueryPrefix: true });
    delete query.company;
    delete query.entity;
    delete query.page;
    delete query.search_term;
    dispatch(setVendorsFilter(query));
  }, [search]);

  return (
    <div className="t-pt-2">
      <Filter.Root
        defaultValue="season"
        title={
          <span className="t-text-body t-font-medium t-leading-none">
            Filters {appliedFiltersCount ? <>({appliedFiltersCount})</> : ""}
          </span>
        }
        capsule={<CapsuleFilters />}
      >
        <Filter.Portal>
          <Filter.List>
            <Filter.ListItem value="season">Season</Filter.ListItem>
            <Filter.ListItem value="wForm">W-Form</Filter.ListItem>
          </Filter.List>
          <Filter.Body value="season">
            <SeasonFilter />
          </Filter.Body>
          <Filter.Body value="wForm">
            <WFormFilters />
          </Filter.Body>
        </Filter.Portal>
      </Filter.Root>
    </div>
  );
};
