import { timezones } from "data/timezones";
import dayjs from "dayjs";

export const currentTimeZoneTime = (timeZone?: string) => {
  const tz = timezones.find((t) => t.value === timeZone);
  const tzName = tz?.utc?.[0];
  let time = dayjs().format("hh:mm A");
  if (tzName) {
    time = dayjs().tz(tzName).format("hh:mm A");
  }
  return { time, zone: tz?.abbr };
};
