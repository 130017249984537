import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import {
  Combobox,
  OptionData,
} from "components/DesignSystem/Combobox/Combobox";
import Modal from "components/DesignSystem/Modal/Modal";
import { TextArea } from "components/DesignSystem/TextArea/TextArea";
import { Label, TextInput } from "components/DesignSystem/TextInput/TextInput";
import { CrossIcon } from "components/icons/CrossIcon";
import { Padlock } from "components/icons/Padlock";
import { PermissionBasedUI } from "components/PermissionBasedUI/PermissionBasedUI";
import { INVOICE_REMINDER } from "constants/subscriptionPermissionFeatures";
import { Form, Formik } from "formik";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { MultiValue, SingleValue } from "react-select";
import {
  InvoiceSettings,
  ReminderSetting,
  useCreateInvoicingEmailConfigurationMutation,
  useUpdateInvoicingEmailConfigurationMutation,
} from "store/apis/invoices";

export const emailReminderTimes = {
  "-7": { value: "-7", label: "7 days before due date" },
  "-3": { value: "-3", label: "3 days before due date" },
  "0": { value: "0", label: "On due date" },
  "3": { value: "3", label: "3 days after due date" },
  "7": { value: "7", label: "7 days after due date" },
};

export type EmailReminderTimesType = keyof typeof emailReminderTimes;

type ConfigureEmailPayload = {
  subject: string;
  greeting: string;
  mainBody: string;
  paymentInstructions: string;
  signature: string;
  emailReminderTimes: EmailReminderTimesType[];
  ccRequire: boolean;
  ccEmails: string[];
};

const emailReminderTimeOptions = Object.values(emailReminderTimes);

const TimeOptionKeys = ["-7", "-3", "0", "3", "7"];

const defaultEmailReminderTimes: EmailReminderTimesType[] = ["-3"];

export const ConfigureReminderEmail = ({
  isOpen,
  close,
  invoiceSetting,
}: {
  isOpen: boolean;
  close: () => void;
  invoiceSetting?: InvoiceSettings | null;
}) => {
  const [createEmailConfiguration, { isLoading }] =
    useCreateInvoicingEmailConfigurationMutation();
  const [updateEmailConfiguration, { isLoading: isUpdating }] =
    useUpdateInvoicingEmailConfigurationMutation();

  const { successToast, alertToast } = useToast();
  const group = useCurrentGroupContext();
  const entityId = invoiceSetting?.entity_id;

  const entityName = group.entities.find(
    (entity) => entity.uuid === entityId
  )?.name;
  const reminderSettings = invoiceSetting?.reminder_settings;

  const addedEmailReminderTimes =
    reminderSettings?.reminder_setting_types?.split(
      ","
    ) as EmailReminderTimesType[];

  const initialValue = {
    subject:
      reminderSettings?.subject ||
      `Reminder: Your payment to ${entityName} is due`,
    ccRequire:
      (reminderSettings && reminderSettings?.cc_emails.length > 0) || false,
    ccEmails: reminderSettings?.cc_emails || [],
    greeting: reminderSettings?.greeting || "Hi,",
    mainBody:
      reminderSettings?.body ||
      "We’re sending a reminder to let you know that invoice has not been paid. If you already paid this invoice or have any questions, let us know.",
    paymentInstructions: invoiceSetting?.payment_info || "",
    signature: reminderSettings?.signature || `Best Regards,\n${entityName}`,
    emailReminderTimes: Array.isArray(addedEmailReminderTimes)
      ? addedEmailReminderTimes
      : defaultEmailReminderTimes,
  };

  const onEmailConfigureCreate = async (values: ConfigureEmailPayload) => {
    if (entityId) {
      try {
        await createEmailConfiguration({
          payload: {
            subject: values.subject,
            cc_emails: values.ccEmails.join(","),
            greeting: values.greeting,
            body: values.mainBody,
            signature: values.signature,
            payment_info: values.paymentInstructions,
            reminder_setting_types: values.emailReminderTimes.join(","),
          },
          groupId: group.uuid,
          entityId: entityId,
        }).unwrap();
        successToast({
          message: "Reminder setup successfully",
        });
        close();
      } catch (error: any) {
        alertToast({ message: error?.data?.error?.message });
      }
    }
  };

  const onEmailConfigureUpdate = async (values: ConfigureEmailPayload) => {
    if (entityId && Boolean(reminderSettings?.uuid)) {
      try {
        await updateEmailConfiguration({
          payload: {
            subject: values.subject,
            cc_emails: values.ccEmails.join(","),
            greeting: values.greeting,
            body: values.mainBody,
            signature: values.signature,
            payment_info: values.paymentInstructions,
            reminder_setting_types: values.emailReminderTimes.join(","),
          },
          reminderId: (reminderSettings as ReminderSetting).uuid,
          groupId: group.uuid,
          entityId: entityId,
        }).unwrap();
        successToast({
          message: "Reminder updated successfully",
        });
        close();
      } catch (error: any) {
        alertToast({ message: error?.data?.error?.message });
      }
    }
  };

  const handleChange = (
    selected: SingleValue<OptionData> | MultiValue<OptionData>,
    setFieldValue: (name: string, value: any) => void,
    index: number,
    values: ConfigureEmailPayload
  ) => {
    if (selected && "value" in selected && selected.value) {
      values.emailReminderTimes[index] =
        selected.value as EmailReminderTimesType;
      setFieldValue(
        "emailReminderTimes",
        values.emailReminderTimes.sort((a, b) => Number(a) - Number(b))
      );
    }
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={close} modal={false}>
      <Formik
        initialValues={initialValue}
        onSubmit={
          Boolean(reminderSettings)
            ? onEmailConfigureUpdate
            : onEmailConfigureCreate
        }
      >
        {({ submitForm, values, setFieldValue }) => {
          const timeOptions = [...emailReminderTimeOptions].filter(
            ({ value }) =>
              !values.emailReminderTimes.includes(
                value as EmailReminderTimesType
              )
          );
          const nextDefaultTime = TimeOptionKeys.find(
            (time) =>
              !values.emailReminderTimes.includes(
                time as EmailReminderTimesType
              )
          );
          const selectedKeys = values.emailReminderTimes;

          return (
            <Modal.Content useCustomOverlay>
              <Modal.Header>
                <Modal.Title>Reminders</Modal.Title>
                <Modal.Close />
              </Modal.Header>
              <Modal.Body>
                <Form className="t-m-0 t-space-y-4">
                  <TextInput required name="subject" label="Subject" />
                  <Checkbox
                    name="ccRequire"
                    label="CC email"
                    onChange={(e) =>
                      setFieldValue("ccRequire", e.target.checked)
                    }
                    checked={values.ccRequire}
                  />
                  {values.ccRequire && (
                    <Combobox
                      placeholder="Emails"
                      formatCreateLabel={(inputText) => `Add ${inputText}`}
                      isMulti
                      label="Emails"
                      withForm
                      name="ccEmails"
                      creatable
                      components={{
                        DropdownIndicator: () => null,
                        NoOptionsMessage: () => null,
                      }}
                      onCreateOption={(value) =>
                        setFieldValue("ccEmails", [
                          ...(values.ccEmails || []),
                          value,
                        ])
                      }
                      value={values.ccEmails?.map((i) => ({
                        label: i,
                        value: i,
                      }))}
                    />
                  )}
                  <TextInput required name="greeting" label="Greeting" />
                  <TextArea name="mainBody" label="Main body" />
                  <TextArea
                    name="paymentInstructions"
                    label="Payment Instructions"
                    disabled={Boolean(invoiceSetting?.payment_info)}
                  />
                  <TextArea name="signature" label="Signature" />
                  <PermissionBasedUI
                    feature={INVOICE_REMINDER}
                    blockedUI={
                      <div>
                        <Label>
                          <span className="t-flex t-gap-1 t-justify-center">
                            <span>
                              Set email reminders for unpaid invoices around due
                              date
                            </span>
                            <span>
                              <Padlock />
                            </span>
                          </span>
                        </Label>
                        <div className="t-space-y-3 t-mt-1">
                          {selectedKeys.map((reminderTime, index) => (
                            <div
                              key={index}
                              className="t-flex t-gap-4 t-items-center t-w-full"
                            >
                              <div className="t-pointer-events-none t-w-full">
                                <Combobox
                                  menuPortalTarget={document.body}
                                  components={{
                                    ClearIndicator: () => null,
                                  }}
                                  isDisabled
                                  withForm
                                  name={`emailReminderTimes${reminderTime}`}
                                  options={timeOptions}
                                  block
                                  value={emailReminderTimes[reminderTime]}
                                />
                              </div>
                              {selectedKeys.length > 1 && (
                                <Button
                                  type="button"
                                  customType="transparent"
                                  aria-label="Remove reminder"
                                  onClick={() => {
                                    const remainingTime =
                                      values.emailReminderTimes;
                                    remainingTime
                                      .splice(index, 1)
                                      .sort(
                                        (
                                          a: EmailReminderTimesType,
                                          b: EmailReminderTimesType
                                        ) => Number(a) - Number(b)
                                      );

                                    setFieldValue(
                                      "emailReminderTimes",
                                      remainingTime
                                    );
                                  }}
                                >
                                  <CrossIcon />
                                </Button>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    }
                  >
                    <div>
                      <Label>
                        Set email reminders for unpaid invoices around due date
                      </Label>
                      <div className="t-space-y-3 t-mt-1">
                        {selectedKeys.map((reminderTime, index) => (
                          <div
                            key={index}
                            className="t-flex t-gap-4 t-items-center t-w-full"
                          >
                            <Combobox
                              menuPortalTarget={document.body}
                              components={{
                                ClearIndicator: () => null,
                              }}
                              withForm
                              name={`emailReminderTimes${reminderTime}`}
                              options={timeOptions}
                              block
                              onChange={(
                                selected:
                                  | SingleValue<OptionData>
                                  | MultiValue<OptionData>
                              ) =>
                                handleChange(
                                  selected,
                                  setFieldValue,
                                  index,
                                  values
                                )
                              }
                              value={emailReminderTimes[reminderTime]}
                            />
                            {selectedKeys.length > 1 && (
                              <Button
                                type="button"
                                customType="transparent"
                                aria-label="Remove reminder"
                                onClick={() => {
                                  const remainingTime =
                                    values.emailReminderTimes;
                                  remainingTime
                                    .splice(index, 1)
                                    .sort(
                                      (
                                        a: EmailReminderTimesType,
                                        b: EmailReminderTimesType
                                      ) => Number(a) - Number(b)
                                    );

                                  setFieldValue(
                                    "emailReminderTimes",
                                    remainingTime
                                  );
                                }}
                              >
                                <CrossIcon />
                              </Button>
                            )}
                          </div>
                        ))}
                      </div>
                      {nextDefaultTime && (
                        <div className="t-mt-3">
                          <Button
                            type="button"
                            customType="link"
                            onClick={() =>
                              setFieldValue(
                                "emailReminderTimes",
                                [
                                  ...values.emailReminderTimes,
                                  nextDefaultTime,
                                ].sort((a, b) => Number(a) - Number(b))
                              )
                            }
                          >
                            Add reminder
                          </Button>
                        </div>
                      )}
                    </div>
                  </PermissionBasedUI>
                </Form>
              </Modal.Body>
              <Modal.FooterButtonGroup>
                <Button type="button" onClick={close}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  isLoading={isLoading || isUpdating}
                  disabled={isLoading || isUpdating}
                  customType="primary"
                  onClick={submitForm}
                >
                  Save
                </Button>
              </Modal.FooterButtonGroup>
            </Modal.Content>
          );
        }}
      </Formik>
    </Modal.Root>
  );
};
