import React from "react";

export const SavedCardItem = ({
  card: {
    payment_method_id,
    card: { brand, last_four, exp_month, exp_year },
  },
  isDefault = false,
}) => (
  <div key={payment_method_id} className="t-flex t-justify-between t-w-full">
    <div className="t-flex t-gap-4 t-items-center t-text-body t-text-text-100">
      <div className="t-capitalize">{brand}</div>
      <div>
        &#8226;&#8226;&#8226;&#8226; &#8226;&#8226;&#8226;&#8226;
        &#8226;&#8226;&#8226;&#8226; {last_four}
      </div>
      <div>
        {exp_month}/{exp_year}
      </div>
    </div>
    {isDefault && (
      <div className="t-rounded-3xl t-px-3 t-py-1 t-text-purple t-bg-purple-0 t-text-body-sm">
        Default
      </div>
    )}
  </div>
);
