import React from "react";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";

export const ConfirmWFormDelete = ({
  isOpen,
  close,
  onClick,
  isDeleting,
}: {
  isOpen: boolean;
  close: () => void;
  onClick: () => void;
  isDeleting: boolean;
}) => {
  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Are You Sure?</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <div className="t-text-body-lg t-text-text-black">
            Deleting the W-Form here will also remove it from ‘My Entities’.
            This action cannot be undone.
          </div>
        </Modal.Body>
        <Modal.FooterButtonGroup>
          <Modal.RawClose asChild>
            <Button type="button">Cancel</Button>
          </Modal.RawClose>
          <Button
            customType="danger"
            onClick={onClick}
            type="button"
            isLoading={isDeleting}
            disabled={isDeleting}
          >
            Delete
          </Button>
        </Modal.FooterButtonGroup>
      </Modal.Content>
    </Modal.Root>
  );
};
