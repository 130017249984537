import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface RuleEngineState {
  isSliderOpen: boolean;
  previewRuleId: string;
  allRuleIds: string[];
  isEditModalOpen: boolean;
  editRuleId: string;
  isDeleteModalOpen: boolean;
  deleteRuleId: string;
  categoryId: string;
  merchantIds: string;
}

const initialState: RuleEngineState = {
  isSliderOpen: false,
  previewRuleId: "",
  allRuleIds: [],
  isEditModalOpen: false,
  editRuleId: "",
  isDeleteModalOpen: false,
  deleteRuleId: "",
  categoryId: "",
  merchantIds: "",
};

export const ruleEngine = createSlice({
  name: "ruleEngine",
  initialState,
  reducers: {
    openSlider: (state, action: PayloadAction<string>) => {
      state.previewRuleId = action.payload;
      state.isSliderOpen = true;
    },

    closeSlider: (state) => {
      state.isSliderOpen = false;
      state.previewRuleId = "";
    },

    setAllRuleIds: (state, action: PayloadAction<string[]>) => {
      state.allRuleIds = action.payload;
    },

    openEditModal: (state, action: PayloadAction<string>) => {
      state.editRuleId = action.payload;
      state.isEditModalOpen = true;
    },

    closeEditModal: (state) => {
      state.editRuleId = "";
      state.isEditModalOpen = false;
    },

    openDeleteModal: (state, action: PayloadAction<string>) => {
      state.deleteRuleId = action.payload;
      state.isDeleteModalOpen = true;
    },

    closeDeleteModal: (state) => {
      state.deleteRuleId = "";
      state.isDeleteModalOpen = false;
    },

    setRuleInfo: (
      state,
      action: PayloadAction<{ categoryId: string; merchantIds: string }>
    ) => {
      state.categoryId = action.payload.categoryId;
      state.merchantIds = action.payload.merchantIds;
    },
  },
});

export const {
  openSlider,
  closeSlider,
  setAllRuleIds,
  openEditModal,
  closeEditModal,
  openDeleteModal,
  closeDeleteModal,
  setRuleInfo,
} = ruleEngine.actions;

export default ruleEngine.reducer;
