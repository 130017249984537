import { Button } from "components/DesignSystem/Button/Button";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import Modal from "components/DesignSystem/Modal/Modal";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import ThreeDots from "static/images/ThreeDots.svg";
import {
  useEscalateTicketMutation,
  useUpdateChatTicketMutation,
} from "store/apis/chatTicket";

import { BackendError } from "types/utils/error";

export const TicketActions = ({
  ticketId,
  isEscalated,
  resolved,
}: {
  ticketId: string;
  isEscalated?: boolean;
  resolved?: boolean;
}) => {
  const { successToast, alertToast } = useToast();
  const escalateConfirmationModal = useModal();
  const [updateChatTicket] = useUpdateChatTicketMutation();
  const [escalateTicket, { isLoading }] = useEscalateTicketMutation();

  const markTicketStatus = async (resolvedStatus: boolean) => {
    try {
      if (ticketId) {
        const res = await updateChatTicket({
          ticket_id: ticketId,
          resolved_status: resolvedStatus,
        }).unwrap();
        if (res.resolved) {
          successToast({ message: "Ticket has been marked as resolved" });
        } else {
          successToast({ message: "Ticket has been marked as unresolved" });
        }
      }
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const onEscalate = async () => {
    try {
      if (ticketId) {
        await escalateTicket({ ticketId }).unwrap();
        successToast({ message: "Ticket has been escalated" });
      }
      escalateConfirmationModal.close();
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Dropdown.Root>
        <Dropdown.Trigger asChild>
          <Button customType="ghost_icon" size="small">
            <img src={ThreeDots} alt="Show more options" />
          </Button>
        </Dropdown.Trigger>
        <Dropdown.Portal>
          <Dropdown.Content sideOffset={6} align="end">
            {resolved ? (
              <Dropdown.Item
                onSelect={() => markTicketStatus(false)}
                key="markResolved"
              >
                Mark as unresolved
              </Dropdown.Item>
            ) : (
              <>
                <Dropdown.Item
                  onSelect={escalateConfirmationModal.open}
                  key="openConfirmation"
                  disabled={isEscalated}
                >
                  {isEscalated ? "Already escalated" : "Escalate ticket"}
                </Dropdown.Item>
                <Dropdown.Item
                  onSelect={() => markTicketStatus(true)}
                  key="markResolved"
                >
                  Mark as resolved
                </Dropdown.Item>
              </>
            )}
          </Dropdown.Content>
        </Dropdown.Portal>
      </Dropdown.Root>
      <Modal.Root
        open={escalateConfirmationModal.isOpen}
        onOpenChange={escalateConfirmationModal.close}
      >
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>Escalate Ticket</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body className="t-text-body-lg t-text-text-60">
            You’re about to escalate this ticket, are you sure you want to
            proceed?
          </Modal.Body>
          <Modal.FooterButtonGroup>
            <Modal.RawClose asChild>
              <Button disabled={isLoading}>Cancel</Button>
            </Modal.RawClose>
            <Button
              customType="primary"
              onClick={onEscalate}
              isLoading={isLoading}
              disabled={isLoading}
            >
              Escalate
            </Button>
          </Modal.FooterButtonGroup>
        </Modal.Content>
      </Modal.Root>
    </div>
  );
};
