import ToolTip from "components/design/toolTip";
import { Button } from "components/DesignSystem/Button/Button";
import { Loader } from "components/DesignSystem/Loader/Loader";
import { useHasSubscriptionPermission } from "hooks/useHasSubscriptionPermission";
import { useUpgradePlan } from "hooks/useUpgradePlan";
import { ComponentProps, ReactNode } from "react";
import { SUBSCRIPTION_PERMISSION_FEATURES } from "types/contants/subscriptionPermissionFeaturesTypes";

export const PermissionBasedUI = ({
  children,
  blockedUI,
  feature,
  errorMessage,
  route,
  product_type = "BOOKS",
  ...props
}: {
  children: ReactNode;
  feature?: SUBSCRIPTION_PERMISSION_FEATURES;
  route?: string;
  blockedUI?: ReactNode;
  errorMessage?: string;
  product_type?: "TAX" | "BOOKS";
} & ComponentProps<typeof ToolTip>) => {
  const permission = useHasSubscriptionPermission({
    feature: feature,
    route,
    product_type,
  });

  const { upgrade } = useUpgradePlan();

  const blocked = permission?.blocked;

  if (!blocked) {
    return children;
  }

  if (!permission) {
    return children;
  }

  return (
    <>
      <ToolTip
        keepOpenOnActivation={true}
        {...props}
        text={
          <div className="t-flex t-flex-col t-gap-4 t-text-left t-w-[264px]">
            <div className="t-flex t-flex-col t-gap-2">
              <p className="t-m-0 t-text-subtitle-sm">Upgrade to unlock</p>
              <p className="t-m-0 t-text-body-sm t-break-keep">
                {errorMessage || permission.message}
              </p>
            </div>
            <Button
              onClick={() =>
                permission.subscription &&
                upgrade({ addon: permission.subscription })
              }
              size="small"
            >
              Upgrade
            </Button>
          </div>
        }
      >
        <span role="button">{blockedUI || children}</span>
      </ToolTip>
    </>
  );
};
