import { Avatar } from "components/DesignSystem/AvatarGroup/Avatar";
import {
  Combobox,
  ComboboxProps,
} from "components/DesignSystem/Combobox/Combobox";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { ReactNode, useEffect, useState } from "react";
import { CSSObjectWithLabel, SingleValue } from "react-select";
import { useGetAllTransactionMerchantsQuery } from "store/apis/merchant";
import { debounce } from "utils/debouncing";
import {
  DropdownIndicator,
  TransactionComboboxClearIndicator,
  TransactionComboboxControl,
} from "./TransactionColumn";
import ConditionalToolTip from "components/design/conditionalToolTip";
import { SplitMerchantOnPopover } from "./SplitMerchantOnPopover";
import { Transaction, Transactions } from "types/Models/books";

type Option = {
  value: string;
  label: ReactNode;
  merchantId: string;
};

export const MerchantComponent = ({
  logo,
  name,
}: {
  logo?: string | null;
  name: string;
}) => {
  return (
    <div className="t-flex t-gap-2 t-items-center">
      <Avatar src={logo || ""} alt={name} />
      <div className="t-text-subtext t-text-text-60 t-max-w-[300px] t-truncate">
        {name}
      </div>
    </div>
  );
};

export const MerchantSelector = ({
  isLoading,
  defaultValue,
  onCreateMerchant,
  merchantChange,
  customComponent = false,
  size = "regular",
  value,
  isClearable = true,
  creatable,
  selected,
  showMerchantDetails,
  transactions,
  ...restProps
}: {
  onCreateMerchant?: (name: string) => Promise<void>;
  merchantChange: (values: SingleValue<any>) => Promise<void>;
  customComponent?: boolean;
  selected?: string;
  showMerchantDetails?: boolean;
  transactions?: Transactions;
} & ComboboxProps) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [options, setOptions] = useState<Option[]>([]);
  const [pageNum, setPageNum] = useState(1);
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();

  const { data: allMerchants, isFetching } = useGetAllTransactionMerchantsQuery(
    {
      groupId,
      entityId,
      searchTerm,
      pageNum: pageNum,
    },
    { skip: !groupId || !entityId }
  );

  const { merchants = [], total_pages = 1 } = allMerchants || {};

  useEffect(() => {
    setOptions((prevOptions) => {
      if (pageNum === 1) {
        prevOptions = [];
      }
      return [
        ...prevOptions,
        ...merchants?.map((merchant) => {
          return {
            value: restProps.withForm ? merchant.uuid : merchant.name,
            label: (
              <MerchantComponent logo={merchant.logo} name={merchant.name} />
            ),
            merchantId: merchant.uuid,
            data: merchant.name,
          };
        }),
      ];
    });
  }, [isFetching, restProps.withForm]);

  useEffect(() => {
    setOptions(
      merchants?.map((merchant) => {
        return {
          value: restProps.withForm ? merchant.uuid : merchant.name,
          label: (
            <MerchantComponent logo={merchant?.logo} name={merchant.name} />
          ),
          merchantId: merchant.uuid,
          data: merchant.name,
        };
      })
    );
  }, [searchTerm, restProps.withForm]);

  const handleMoreData = () => {
    if (pageNum < total_pages) {
      setPageNum((pageNum) => pageNum + 1);
    }
  };

  const handleMerchantSearch = debounce((value) => {
    setSearchTerm(value);
  });

  const components = customComponent
    ? {
        Control: TransactionComboboxControl,
        DropdownIndicator: DropdownIndicator,
        ClearIndicator: TransactionComboboxClearIndicator,
      }
    : {};

  const props = {
    placeholder: "Select",
    isLoading,
    onChange: merchantChange,
    onInputChange: handleMerchantSearch,
    backspaceRemovesValue: false,
    onMenuScrollToBottom: handleMoreData,
    menuPortalTarget: document.body,
    components: { ...components },
    size,
    options,
    isClearable,
    ...(Boolean(defaultValue) && {
      defaultValue,
    }),
    value,
    styles: {
      menuPortal: (base: CSSObjectWithLabel) => ({
        ...base,
        width: 300,
      }),
    },
    ...restProps,
  };

  if (creatable) {
    if (showMerchantDetails) {
      return (
        <SplitMerchantOnPopover
          props={props}
          onCreateMerchant={onCreateMerchant}
          transactions={transactions}
        />
      );
    }

    return (
      <ConditionalToolTip condition={selected}>
        <div>
          <Combobox
            aria-label="Merchant selector"
            creatable
            onCreateOption={(name) => onCreateMerchant?.(name)}
            {...props}
          />
        </div>
      </ConditionalToolTip>
    );
  }

  return <Combobox aria-label="Merchant selector" {...props} />;
};
