import { send1099File } from "apis/merchants";
import { ClockWiseWait } from "components/icons/ClockWiseWait";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import Check from "static/images/Checks.svg";
import "static/styles/components/wSeriesFormModal.css";
import { Merchant } from "types/Models/merchant";
import { DocumentPreviewModal } from "./PreviewModal";
import { Form, Formik } from "formik";
import { emailSchema } from "../formValidations/emailSchema";
import { MERCHANT_FORM_STATUS_TYPES } from "types/Models/merchant";
import { TextInput } from "./DesignSystem/TextInput/TextInput";
import { Button } from "./DesignSystem/Button/Button";
import Modal from "./DesignSystem/Modal/Modal";

type FileUpload1099Props = {
  taskId: string;
  groupId: string;
  merchant: Merchant;
  updateMerchant: (merchant: Merchant) => void;
  editable?: boolean;
};

export const FileSendUpload1099 = ({
  merchant,
  groupId,
  taskId,
  updateMerchant,
  editable = true,
}: FileUpload1099Props): JSX.Element => {
  const { alertToast, successToast } = useToast();
  const { isCustomer } = useRoleBasedView();
  const { form_1099_document, form_status, email } = merchant;
  const [showModal, setModal] = useState(false);

  let icon = null;
  const hasSentWSeriesForm = form_status === "SENT";

  if (hasSentWSeriesForm) {
    icon = <ClockWiseWait />;
  }

  if (form_1099_document) {
    icon = <img src={Check} alt="check" />;
  }

  const onEmailSubmit = async ({ email }: { email: string }) => {
    try {
      const { data } = await send1099File({
        merchantDocMapId:
          merchant.form_1099_document?.recipients_document?.uuid!,
        groupId,
        email,
      });
      updateMerchant(data);
      setModal(false);
      successToast({ message: "Email has been sent" });
    } catch (error: any) {
      alertToast({ message: error?.response?.data?.error?.message });
    }
  };

  const buttonText = form_1099_document ? "1099" : "Send copy";
  const close = () => setModal(false);
  return (
    <>
      <Button
        onClick={() => setModal(true)}
        disabled={Boolean(!form_1099_document) && isCustomer}
      >
        <span className="d-inline-block me-1">{icon}</span>
        {buttonText}
      </Button>
      <Modal.Root open={showModal} onOpenChange={close}>
        <Modal.Content useCustomOverlay>
          <Formik
            validateOnChange
            validationSchema={emailSchema}
            onSubmit={onEmailSubmit}
            initialValues={{
              email: email || "",
            }}
          >
            {({ submitForm, isSubmitting, isValid }) => {
              return (
                <>
                  <Modal.Header>
                    <Modal.Title>1099 Form</Modal.Title>
                    <Modal.Close onClick={close} />
                  </Modal.Header>
                  <Modal.Body>
                    <Form className="w-100 m-0">
                      <div className="d-flex w-100 align-items-center gap-4">
                        <TextInput
                          label={
                            <span className="w-series-form-modal-input-label t-mb-3">
                              label="Send a copy of 1099 form"
                            </span>
                          }
                          block
                          placeholder="Enter email"
                          name="email"
                          required
                          customSize="regular"
                        />
                      </div>
                    </Form>
                  </Modal.Body>
                  <Modal.FooterButtonGroup>
                    <Modal.RawClose asChild>
                      <Button type="button">Cancel</Button>
                    </Modal.RawClose>
                    <Button
                      customType="primary"
                      onClick={submitForm}
                      isLoading={isSubmitting}
                      disabled={
                        !isValid || !Boolean(form_1099_document) || isSubmitting
                      }
                    >
                      {form_status === "SENT" ? "Resend email" : "Send email"}
                    </Button>
                  </Modal.FooterButtonGroup>
                </>
              );
            }}
          </Formik>
        </Modal.Content>
      </Modal.Root>

      <DocumentPreviewModal />
    </>
  );
};
