import { JurisdictionStateCard } from "components/Entity/EntityFormEngine/Jurisdictions";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import React from "react";
import { useGetEntityAllStateDataQuery } from "store/apis/entity";

export const RegisteredStates = ({ entityId }: { entityId: string }) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const { data = [] } = useGetEntityAllStateDataQuery(
    { entityId, groupId },
    { skip: !groupId || !entityId }
  );

  return (
    <div className="t-space-y-3">
      {data?.map((stateData) => (
        <JurisdictionStateCard
          stateData={stateData}
          key={stateData.uuid}
          entityId={entityId}
          showFieldWithEmptyValue
          stateRegistrationType={stateData.registration_type}
          rowGap="t-gap-y-2"
        />
      ))}
    </div>
  );
};
