import React from "react";
import { Card } from "react-bootstrap";

import "static/styles/components/mailbox.css";

import CheckCircle from "static/images/CheckCircle.svg";
import { useHistory } from "react-router-dom";
import { Button } from "components/DesignSystem/Button/Button";
import mailroomPlan from "static/images/mailroomPlan.svg";

export const MailRoomNotSubscribe = () => {
  const history = useHistory();
  const goToChat = () => {
    history.push({
      pathname: "/chat",
      state: {
        channelName: "General Help",
      },
    });
  };

  const proSubscriptionData = [
    "Accepted by IRS, banks etc.",
    "Search, scan, forward or shred your mail, all online",
    "24/7 instant access",
    "Not a CMRA mailroom, hence no USPS notarised form needed",
  ];

  return (
    <span className="d-flex flex-column align-items-center gap-4 t-p-8">
      <Card className="subscription-plan-card">
        <img src={mailroomPlan} alt="plan-img" />
        <Card.Title className="plan-title">
          <span className="plan-pricing">
            Inkle Mailroom for just{" "}
            <span className="plan-price">$15/month</span>
          </span>
          <div className="plan-description">
            Get a virtual mailroom with a permanent U.S. address accessible
            through our platform anytime, anywhere.
          </div>
        </Card.Title>
        <Card.Body className="d-flex flex-column gap-2 mb-3">
          {proSubscriptionData.map((data) => (
            <div className="plan-details" key={data}>
              <img src={CheckCircle} alt="icon" />
              <div>{data}</div>
            </div>
          ))}
        </Card.Body>
        <Button
          size="regular"
          customType="primary"
          onClick={goToChat}
          color="purple"
        >
          Chat with us
        </Button>
      </Card>
    </span>
  );
};
