import { useGetEntityBanksQuery } from "store/apis/bankConnections";
import {
  useGetSubscriptionPermissionQuery,
  useGetSubscriptionsQuery,
} from "store/apis/subscriptions";
import { useCurrentEntityId } from "./useCurrentEntityId";
import { useCurrentGroupContext } from "./useCurrentGroupContext";
import { CONNECT_BANK_ACCOUNT } from "constants/subscriptionPermissionFeatures";
import { entitiesSortedByCountry } from "utils/entitiesSortedByCountry";

export const useHasSubscriptionPermission = ({
  feature,
  route,
  product_type = "BOOKS",
}: {
  feature?: string;
  route?: string;
  product_type?: "TAX" | "BOOKS";
}) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const { entities } = useCurrentGroupContext();
  const { usEntities } = entitiesSortedByCountry(entities);
  const entityId = useCurrentEntityId() || usEntities[0]?.uuid;
  const { data: permissions, isSuccess } = useGetSubscriptionPermissionQuery(
    {
      entityId,
      product_type,
    },
    {
      skip: !entityId,
    }
  );

  const { data: entityBanks } = useGetEntityBanksQuery(
    {
      groupId,
      entityId,
    },
    {
      skip: feature !== CONNECT_BANK_ACCOUNT || !entityId || !groupId,
    }
  );

  const { data: subscriptions } = useGetSubscriptionsQuery(
    {
      groupId: groupId,
    },
    { skip: !groupId }
  );

  if (feature) {
    const message =
      entityBanks && entityBanks?.items.length >= 2
        ? "You need to upgrade to Plus Plan to connect or add more than 2 data sources."
        : permissions?.feature_map?.[feature]?.message;

    if (
      feature === CONNECT_BANK_ACCOUNT &&
      entityBanks &&
      entityBanks?.items.length < 2
    ) {
      return null;
    }

    const permission = permissions?.feature_map?.[feature];
    return {
      ...permission,
      message,
      isLoading: !isSuccess,
      subscription: subscriptions?.find(
        (sub) => sub.uuid === permission?.subscription_required.uuid
      ),
    };
  }

  if (route) {
    const permission = permissions?.route_map?.[route];
    return {
      ...permission,
      isLoading: !isSuccess,

      subscription: subscriptions?.find(
        (sub) => sub.uuid === permission?.subscription_required.uuid
      ),
    };
  }

  return null;
};
