import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ToastCustomType,
  ToastSizeType,
} from "components/DesignSystem/Toast/Toast";
import randomBytes from "randombytes";
import { ReactNode } from "react";

export type ToastOptions = {
  message?: string;
  title?: string | ReactNode;
  size?: ToastSizeType;
  type: ToastCustomType;
  id: string;
};

export type TransactionState = {
  toast: ToastOptions[];
};

const initialState: TransactionState = {
  toast: [],
};

export const toast = createSlice({
  name: "toast",
  initialState,
  reducers: {
    showToast: (state, action: PayloadAction<ToastOptions>) => {
      state.toast = [{ ...action.payload }, ...state.toast];
    },

    removeToast: (state, action: PayloadAction<string>) => {
      state.toast = state.toast.filter((t) => t.id !== action.payload);
    },

    removeAllToasts: (state) => {
      state.toast = [];
    },
  },
});

export const { showToast, removeToast, removeAllToasts } = toast.actions;

export default toast.reducer;
