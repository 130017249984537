import { Button } from "components/DesignSystem/Button/Button";
import {
  Combobox,
  OptionData,
} from "components/DesignSystem/Combobox/Combobox";
import Modal from "components/DesignSystem/Modal/Modal";
import { Form, Formik } from "formik";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useReducer,
} from "react";
import { useParams } from "react-router-dom";
import { MultiValue, SingleValue } from "react-select";
import {
  useEditEntityStateDataMutation,
  useGetEntityStateDataQuery,
} from "store/apis/entity";
import { useGetCountryStatesQuery } from "store/apis/teamSetting";
import { EntityDataAttribute } from "types/Models/entity";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";
import AddNewField from "./AddNewField";
import { FieldBuilder } from "./FieldBuilder";

export type ValueType = Record<string, EntityDataAttribute["value"]>;

type CurrentStateAction = {
  type: "SET_STATE";
  payload: { state_id: string };
};

export type CurrentStateContextT = { state_id: string } & {
  dispatch: Dispatch<CurrentStateAction>;
};
const initialState = { state_id: "" };

const WrapperContext = createContext<CurrentStateContextT>(
  initialState as CurrentStateContextT
);

const WrapperContextProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const reducer = (state: { state_id: string }, action: CurrentStateAction) => {
    switch (action.type) {
      case "SET_STATE":
        return { ...state, ...action.payload };

      default:
        return { ...state };
    }
  };
  const [state, dispatch] = useReducer(reducer, { state_id: "" });

  return (
    <WrapperContext.Provider value={{ ...state, dispatch }}>
      {children}
    </WrapperContext.Provider>
  );
};

const useCurrentEntityStates = (entityFromProps?: string) => {
  const { entityId: entityIdFromParams } = useParams<{ entityId: string }>();
  const entityId = entityIdFromParams || entityFromProps;
  const { entities } = useCurrentGroupContext();
  const { country_id = "" } =
    entities.find((entity) => entity.uuid === entityId) || {};

  return useGetCountryStatesQuery(
    {
      countryId: country_id,
    },
    { skip: !country_id }
  );
};

const useCurrentStateEntityData = (entityUuid?: string) => {
  const { entityId: entityIdFromParams } = useParams<{ entityId: string }>();
  const { uuid: groupId } = useCurrentGroupContext();
  const { state_id } = useContext(WrapperContext);
  const entityId = entityIdFromParams || entityUuid;

  return useGetEntityStateDataQuery(
    {
      groupId,
      entityId: entityId!,
      stateId: state_id,
    },
    { skip: !groupId || !entityId || !state_id }
  );
};

const Wrapper = ({
  children,
  entityId,
}: {
  children: ({
    initialValues,
  }: {
    initialValues: ValueType;
  }) => React.ReactNode;
  entityId?: string;
}) => {
  const { state_id } = useContext(WrapperContext);
  const { data = [] } = useCurrentStateEntityData(entityId);

  const initialValues = data.reduce(
    (acc, field) => {
      acc[field.name] = field.value;
      return acc;
    },
    { state_id: state_id } as ValueType
  );

  return <>{children({ initialValues })}</>;
};

const FormRender = ({ entityId }: { entityId?: string }) => {
  const { data = [] } = useCurrentStateEntityData(entityId);

  return (
    <>
      {data.map((field) => (
        <FieldBuilder field={field} key={field.uuid} />
      ))}
    </>
  );
};

const StateSelector = ({ entityId }: { entityId?: string }) => {
  const { data = [], isLoading } = useCurrentEntityStates(entityId);
  const { dispatch } = useContext(WrapperContext);

  const options = data.map(({ state_id, name }) => ({
    value: state_id,
    label: name,
  }));

  const handleChange = (
    option: SingleValue<OptionData> | MultiValue<OptionData>
  ) => {
    if (option instanceof Array) {
      return null;
    }
    dispatch({ type: "SET_STATE", payload: { state_id: option?.value || "" } });
  };

  return (
    <Combobox
      options={options}
      isDisabled={isLoading}
      isLoading={isLoading}
      menuPortalTarget={document.body}
      menuPlacement="auto"
      withForm
      name="state_id"
      placeholder="Select State"
      label="State"
      onChange={handleChange}
    />
  );
};

const AddJurisdiction = ({
  close,
  isOpen,
  entityId: entityIdFromProps,
}: ModalProps & { entityId?: string }) => {
  const { isAdmin } = useRoleBasedView();
  const { successToast, alertToast } = useToast();
  const { entityId: entityIdFromParams } = useParams<{ entityId: string }>();
  const entityId = entityIdFromParams || entityIdFromProps || "";
  const { uuid: groupId } = useCurrentGroupContext();
  const [addEntityState] = useEditEntityStateDataMutation();

  const onSubmit = async (values: ValueType) => {
    const { state_id, ...rest } = values;
    try {
      await addEntityState({
        groupId,
        entityId,
        stateId: state_id as string,
        payload: rest,
      }).unwrap();
      successToast({ message: "Entity updated successfully" });
      close();
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  return (
    <Modal.Root onOpenChange={close} open={isOpen} modal={false}>
      <WrapperContextProvider>
        <Wrapper entityId={entityId}>
          {({ initialValues }) => {
            return (
              <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                enableReinitialize
              >
                {({ submitForm, isSubmitting, values }) => (
                  <Modal.Content useCustomOverlay>
                    <Modal.Header>
                      <Modal.Title>Add State</Modal.Title>
                      <Modal.Close />
                    </Modal.Header>
                    <Modal.Body>
                      <Form className="t-flex t-flex-col t-gap-5 t-m-0">
                        <StateSelector entityId={entityId} />
                        <FormRender entityId={entityId} />
                        {isAdmin && values?.state_id && (
                          <AddNewField
                            stateId={values?.state_id as string}
                            entityId={entityId}
                          />
                        )}
                      </Form>
                    </Modal.Body>
                    <Modal.FooterButtonGroup>
                      <Button onClick={close} disabled={isSubmitting}>
                        Cancel
                      </Button>
                      <Button
                        customType="primary"
                        disabled={isSubmitting}
                        isLoading={isSubmitting}
                        onClick={submitForm}
                      >
                        Save
                      </Button>
                    </Modal.FooterButtonGroup>
                  </Modal.Content>
                )}
              </Formik>
            );
          }}
        </Wrapper>
      </WrapperContextProvider>
    </Modal.Root>
  );
};

export default AddJurisdiction;
