import classNames from "classnames";
import ConditionalToolTip from "components/design/conditionalToolTip";
import ToolTip from "components/design/toolTip";
import { Button } from "components/DesignSystem/Button/Button";
import {
  Combobox,
  OptionData,
} from "components/DesignSystem/Combobox/Combobox";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import Table from "components/DesignSystem/Table/V2/Table";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { ArrowDown } from "components/icons/ArrowDown";
import { DeleteIcon } from "components/icons/delete";
import { companyFormationTypes } from "constants/companyFormationTypes";
import { Field, FieldProps, getIn, useFormikContext } from "formik";
import { AnimatePresence, motion } from "framer-motion";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import React, { memo, useCallback, useMemo, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { components, GroupBase, MultiValue, SingleValue } from "react-select";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import MapPin from "static/images/MapPin.svg";
import ThreeDots from "static/images/ThreeDots.svg";
import { useGetCountriesDataQuery } from "store/apis/onboarding";

type defaultData = {
  country: string;
  state: string;
  formationType: string;
  entityName: string;
  suffix: string;
};

const EntityName = memo(({ id }: { id: number }) => {
  const { values, setFieldValue } = useFormikContext<{
    entityData: defaultData[];
  }>();

  return (
    <TextInput
      hideError
      placeholder="Coca Cola"
      customSize="small"
      showErrorOnceTouched
      name={`entityData[${id}].entityName`}
      rightComponent={
        <Dropdown.Root>
          <Dropdown.Trigger asChild>
            <div className="t-flex t-gap-1 t-ml-1">
              <span className="t-whitespace-nowrap">
                {values.entityData[id]?.suffix}{" "}
              </span>
              <ArrowDown />
            </div>
          </Dropdown.Trigger>
          <Dropdown.Portal container={document.body}>
            <Dropdown.Content
              className="t-h-64"
              side="bottom"
              sideOffset={10}
              align="end"
            >
              {companyFormationTypes.map((f) => (
                <Dropdown.Item
                  key={f}
                  onSelect={() => setFieldValue(`entityData[${id}].suffix`, f)}
                >
                  {f}
                </Dropdown.Item>
              ))}
            </Dropdown.Content>
          </Dropdown.Portal>
        </Dropdown.Root>
      }
    />
  );
});

export const EntityCreationTable = () => {
  const { values, setFieldValue } = useFormikContext<{
    entityData: defaultData[];
  }>();
  const createColumn = createColumnHelper<defaultData>();
  const { data: countries = [] } = useGetCountriesDataQuery();

  const deleteEntityRow = useCallback(
    (id: number) => {
      const newValues = [...values.entityData];
      newValues.splice(id, 1);
      setFieldValue("entityData", newValues);
    },
    [values.entityData.length]
  );

  const resetEntityRow = useCallback(
    (id: number) => {
      const newValues = [...values.entityData];
      newValues[id] = {
        country: "",
        state: "",
        formationType: "",
        entityName: "",
        suffix: "Inc.",
      };

      setFieldValue("entityData", newValues);
    },
    [values.entityData.length]
  );

  const columns = useMemo(
    () => [
      createColumn.accessor("country", {
        size: 12,
        header: "Country",
        cell: (info) => {
          return (
            <Field>
              {({ form: { values, setFieldValue } }: FieldProps) => {
                const name = `entityData[${info.row.id}].country`;
                const value = getIn(
                  values,
                  `entityData[${info.row.id}].country`
                );

                const selectedCountry = countries.find(
                  ({ uuid }) => uuid === value
                );

                const resetOnChange = () => {
                  setFieldValue(`entityData[${info.row.id}].state`, "");
                  setFieldValue(`entityData[${info.row.id}].formationType`, "");
                };

                return (
                  <Combobox
                    hideError
                    size="small"
                    components={{ ClearIndicator: () => <></> }}
                    menuPortalTarget={document.body}
                    styles={{
                      menu: () => ({ width: "400px" }),
                    }}
                    value={{
                      value: getIn(values, name),
                      label: selectedCountry ? (
                        <div className="t-flex t-items-center t-gap-2">
                          <ReactCountryFlag
                            countryCode={selectedCountry?.code_alpha_2!}
                            svg
                          />
                        </div>
                      ) : (
                        <img
                          src={MapPin}
                          alt="countryFlag"
                          className="t-h-[1.375rem] t-w-[1.375rem]"
                        />
                      ),
                    }}
                    onChange={resetOnChange}
                    name={`entityData[${info.row.id}].country`}
                    withForm
                    filterOption={(v, i) =>
                      v.data.data
                        ?.toLocaleLowerCase()
                        .includes(i.toLocaleLowerCase()) || false
                    }
                    options={countries.map((country) => ({
                      label: (
                        <div className="t-flex t-items-center t-gap-2">
                          <ReactCountryFlag
                            countryCode={country.code_alpha_2!}
                            svg
                          />
                          <div>{country.name}</div>
                        </div>
                      ),
                      value: country.uuid,
                      data: country.name,
                    }))}
                  />
                );
              }}
            </Field>
          );
        },
      }),

      createColumn.accessor("state", {
        size: 20,
        header: "State",
        cell: (info) => {
          return (
            <Field>
              {({ form: { values } }: FieldProps) => {
                const selectedCountry = getIn(
                  values,
                  `entityData[${info.row.id}].country`
                );

                const selectedStateId = getIn(
                  values,
                  `entityData[${info.row.id}].state`
                );

                const selectedState = countries
                  .find(({ uuid }) => uuid === selectedCountry)
                  ?.states.find((s) => s.uuid === selectedStateId);

                return (
                  <Combobox
                    hideError
                    menuPortalTarget={document.body}
                    styles={{
                      menu: () => ({ width: "220px" }),
                    }}
                    size="small"
                    name={`entityData[${info.row.id}].state`}
                    withForm
                    value={
                      selectedState
                        ? {
                            value: selectedState?.uuid,
                            label: selectedState?.name,
                          }
                        : null
                    }
                    options={
                      countries
                        .find(
                          (c) =>
                            c.uuid ===
                            getIn(values, `entityData[${info.row.id}].country`)
                        )
                        ?.states?.map(({ name, uuid }) => ({
                          label: name,
                          value: uuid,
                        })) || []
                    }
                  />
                );
              }}
            </Field>
          );
        },
      }),

      createColumn.accessor("formationType", {
        size: 20,
        header: "Formation",
        cell: (info) => {
          return (
            <Field>
              {({ form: { values } }: FieldProps) => {
                const selectedCountry = getIn(
                  values,
                  `entityData[${info.row.id}].country`
                );

                const selectedFormationTypeId = getIn(
                  values,
                  `entityData[${info.row.id}].formationType`
                );

                const selectedFormationType = countries
                  .find(({ uuid }) => uuid === selectedCountry)
                  ?.formation_type.find(
                    (s) => s.uuid === selectedFormationTypeId
                  );
                return (
                  <>
                    <Combobox
                      hideError
                      menuPortalTarget={document.body}
                      styles={{
                        menu: () => ({ width: "220px" }),
                      }}
                      size="small"
                      name={`entityData[${info.row.id}].formationType`}
                      withForm
                      value={
                        selectedFormationType
                          ? {
                              value: selectedFormationType?.uuid,
                              label: selectedFormationType?.name,
                            }
                          : null
                      }
                      options={
                        countries
                          .find(
                            (c) =>
                              c.uuid ===
                              getIn(
                                values,
                                `entityData[${info.row.id}].country`
                              )
                          )
                          ?.formation_type?.map(({ name, uuid }) => ({
                            label: name,
                            value: uuid,
                          })) || []
                      }
                    />
                  </>
                );
              }}
            </Field>
          );
        },
      }),

      createColumn.accessor("entityName", {
        size: 43,
        header: "Entity name",
        cell: (info) => {
          return (
            <div className="t-w-full">
              <EntityName id={Number(info.row.id)} />
            </div>
          );
        },
      }),

      createColumn.display({
        size: 5,
        header: "",
        id: "ACTIONS",
        cell: (info) => {
          const disableDelete = values.entityData.length <= 1;
          return (
            <ConditionalToolTip
              condition={disableDelete && "One US entity is required"}
            >
              <div
                className={classNames("t-flex t-justify-end", {
                  "t-opacity-30": disableDelete,
                })}
              >
                <Button
                  customType="transparent"
                  onClick={() => deleteEntityRow(Number(info.row.id))}
                  disabled={disableDelete}
                  size="small"
                >
                  <DeleteIcon />
                </Button>
              </div>
            </ConditionalToolTip>
          );
        },
      }),
    ],
    [
      deleteEntityRow,
      resetEntityRow,
      values.entityData.length,
      countries.length,
    ]
  );

  const table = useReactTable({
    data: values.entityData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 1,
    },
  });

  return (
    <Table.Container className="t-h-full t-overflow-x-auto" size="regular">
      <Table.Content className="md:t-table-fixed">
        <Table.Head className="!t-static">
          {table.getHeaderGroups().map((headerGroup) => (
            <Table.Row key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Table.HeadCell
                  key={header.id}
                  style={{ width: `${header.getSize()}%` }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </Table.HeadCell>
              ))}
            </Table.Row>
          ))}
        </Table.Head>
        <Table.Body>
          {table.getRowModel().rows.map((row) => (
            <Table.Row key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <Table.Cell
                  key={cell.id}
                  style={{ width: `${cell.column.getSize()}%` }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Content>
    </Table.Container>
  );
};

export const EntityCreation = ({
  notPreincorporated,
  setNotPreincorporated,
  isFromAdmin,
}: {
  notPreincorporated: boolean;
  setNotPreincorporated: (a: any) => void;
  isFromAdmin: boolean;
}) => {
  const [hasUsEntity, setHasUsEntity] = useState(false);
  const { isForeignCA } = useRoleBasedView();

  const { data: countries = [], isLoading } = useGetCountriesDataQuery();
  const usCountryId = countries.find(
    ({ code_alpha_2 }) => code_alpha_2 === "US"
  )?.uuid;

  const delawareStateId = countries
    .find(({ code_alpha_2 }) => code_alpha_2 === "US")
    ?.states.find((s) => s.name === "Delaware")?.uuid;

  const cCorpFormationId = countries
    .find(({ code_alpha_2 }) => code_alpha_2 === "US")
    ?.formation_type.find((s) => s.name === "C Corp")?.uuid;

  const { setFieldValue, values } = useFormikContext<{
    entityData: defaultData[];
    brandName: string;
    hasUsEntity: string;
  }>();

  const onChange = (e: SingleValue<OptionData> | MultiValue<OptionData>) => {
    if (e instanceof Array) {
      return;
    }

    if (e?.value === "YES") {
      setHasUsEntity(true);
      setFieldValue("entityData", [
        {
          country: usCountryId,
          state: delawareStateId,
          formationType: cCorpFormationId,
          entityName: "",
          suffix: "Inc.",
        },
        {
          country: "",
          state: "",
          formationType: "",
          entityName: "",
          suffix: "Inc.",
        },
      ]);
      return;
    }

    setHasUsEntity(false);
    setFieldValue("entityData", [
      {
        country: "",
        state: "",
        formationType: "",
        entityName: "",
        suffix: "Inc.",
      },
    ]);
  };

  const addAnotherEntity = () => {
    setFieldValue("entityData", [
      ...values.entityData,
      {
        country: "",
        state: "",
        formationType: "",
        entityName: "",
        suffix: "Inc.",
      },
    ]);
  };

  const withUsEntityInfo = isForeignCA
    ? "Add information for all business entities in the table below"
    : "Add information for all your business entities in the table below";

  const withoutUsEntityInfo = isForeignCA
    ? "Add information for non-US business entities below (optional)"
    : "Add information for your non-US business entities below (optional)";

  const hasUsEntityOptions = [
    {
      value: "YES",
      label: isForeignCA ? "Yes" : "I have a US entity",
    },
    {
      value: "NO",
      label: isForeignCA ? "No" : "I am not yet incorporated in the US",
    },
  ];

  return (
    <div>
      <span className="t-text-subtitle">
        {isForeignCA
          ? "Is this client incorporated in the US?"
          : "Do you have a US entity?"}
      </span>
      <div className="t-mb-6 t-mt-3 t-w-96">
        <Combobox
          withForm
          onChange={onChange}
          components={{
            ClearIndicator: () => null,
          }}
          name="hasUsEntity"
          size="regular"
          options={hasUsEntityOptions}
          value={
            hasUsEntityOptions.find(
              (option) => option.value === values.hasUsEntity
            ) || null
          }
        />
      </div>

      <p className="t-mb-2 t-text-text-60 t-text-body">
        {hasUsEntity ? withUsEntityInfo : withoutUsEntityInfo}
      </p>

      <>
        <EntityCreationTable />
        <div className="t-mt-2 t-w-fit">
          <ConditionalToolTip
            condition={
              values.entityData.length >= 3 &&
              "You can add more entities later from the dashboard"
            }
          >
            <span>
              <Button
                size="small"
                onClick={addAnotherEntity}
                type="button"
                disabled={values.entityData.length >= 3}
              >
                Add another entity
              </Button>
            </span>
          </ConditionalToolTip>
        </div>
      </>
    </div>
  );
};
