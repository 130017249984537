import { Switch } from "components/DesignSystem/Switch/Switch";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import React from "react";
import PrivateChatHeaderIcon from "static/images/PrivateChatHeaderLock.svg";

export const ChannelHeaderWithOnlySwitch = ({
  isPrivateChannel,
  setPrivateChannel,
}: {
  isPrivateChannel: boolean;
  setPrivateChannel: () => void;
}) => {
  const { isCustomer } = useRoleBasedView();

  return (
    <>
      <div className="t-flex t-bg-white t-px-4 t-py-3  t-justify-between t-items-center">
        <div className="t-text-subtitle t-text-text-100">Chat</div>
        {!isCustomer && (
          <Switch
            checked={isPrivateChannel}
            onCheckedChange={setPrivateChannel}
            size="small"
          />
        )}
      </div>
      {isPrivateChannel && (
        <div className="t-flex t-items-center t-justify-center t-bg-dark_green-30 t-p-1.5">
          <img
            className="t-h-3.5"
            src={PrivateChatHeaderIcon}
            alt="private chat icon"
          />
          <span className="t-text-subtext-sm t-font-semibold t-text-dark_green-90">
            &nbsp; This is a private chat
          </span>
        </div>
      )}
    </>
  );
};
