import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { Form, Formik } from "formik";
import { object, string } from "yup";

export const RenameFileFolderModal = ({
  renameFolder,
  closeRenameModal,
  renameModalShow,
  folderName,
  itemType = "file",
}) => {
  return (
    <Modal.Root open={renameModalShow} onOpenChange={closeRenameModal}>
      <Formik
        onSubmit={renameFolder}
        initialValues={{ file_name: folderName }}
        validationSchema={object().shape({
          file_name: string()
            .required("Name is required")
            .matches(/^(?!\s*$).+/, "Invalid name"),
        })}
      >
        {({ submitForm, isSubmitting, isValid }) => (
          <Form>
            <Modal.Content>
              <Modal.Header>
                <Modal.Title>
                  Rename <span className="t-lowercase t-ml-1">{itemType}</span>
                </Modal.Title>
                <Modal.Close />
              </Modal.Header>
              <Modal.Body>
                <TextInput autoFocus label="Name" name="file_name" />
              </Modal.Body>
              <Modal.FooterButtonGroup>
                <Button
                  onClick={closeRenameModal}
                  type="reset"
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  customType="primary"
                  onClick={submitForm}
                  disabled={!Boolean(isValid) || isSubmitting}
                  isLoading={isSubmitting}
                >
                  Save
                </Button>
              </Modal.FooterButtonGroup>
            </Modal.Content>
          </Form>
        )}
      </Formik>
    </Modal.Root>
  );
};
