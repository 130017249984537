import { AccordionAnimation } from "components/AccordionAnimation";
import { CategorySelector } from "components/CategorySelector/CategorySelector";
import { TableUI } from "components/design/TableUI";
import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import { Loader } from "components/DesignSystem/Loader/Loader";
import Modal from "components/DesignSystem/Modal/Modal";
import { PatternInput } from "components/PatternInput/PatternInput";
import { Form, Formik, getIn, useFormikContext } from "formik";
import { moveCategoriesSchema } from "formValidations/moveCategoriesSchema";
import { AnimatePresence } from "framer-motion";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { useEffect } from "react";
import {
  CellContext,
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import {
  useGetAutoGenerateNextAvailableIdentiferQuery,
  useGetTransactionCategoryQuery,
  useMoveCategoriesMutation,
} from "store/apis/chartOfAccounts";
import { TxnCategories } from "types/Models/books";
import { BackendError } from "types/utils/error";

const NewIdField = (props: CellContext<TxnCategories, void>) => {
  const group = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { values, setFieldValue } = useFormikContext<{
    move_to_category_id: string;
  }>();

  const {
    data: { identifier: availableIdentifier } = { identifier: 0 },
    isFetching: isFetchingIdentifier,
  } = useGetAutoGenerateNextAvailableIdentiferQuery(
    {
      groupId: group.uuid,
      entityId,
      parentId: values.move_to_category_id,
      offset: Number(props.row.id),
    },
    {
      skip: !values.move_to_category_id || !entityId || !group.uuid,
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: selectedParentCategoryDetails, isFetching } =
    useGetTransactionCategoryQuery(
      { categoryId: values.move_to_category_id, entityId, groupId: group.uuid },
      {
        skip: !values.move_to_category_id || !entityId || !group.uuid,
      }
    );

  const name = `moveCategories[${props.row.id}].newId`;
  const value: number | null | undefined = getIn(values, name);

  useEffect(() => {
    if (availableIdentifier) {
      setFieldValue(name, availableIdentifier);
    }
  }, [availableIdentifier, isFetching]);

  if (isFetchingIdentifier && isFetching) {
    return <Loader size="small" />;
  }

  return (
    <PatternInput
      fieldProps={{
        name: name,
        value: value,
      }}
      patternProps={{
        format: `${selectedParentCategoryDetails?.identifier_prefix}####`,
        mask: "_",
        allowEmptyFormatting: true,
        value: value?.toString().split("").toSpliced(0, 1).join(""),
      }}
    />
  );
};

export const MoveCategories = ({
  categories,
  onSuccess,
}: {
  categories: TxnCategories[];
  onSuccess: () => void;
}) => {
  const [moveCategories, { isLoading: isMoving }] = useMoveCategoriesMutation();
  const { successToast, alertToast } = useToast();
  const entityId = useCurrentEntityId();

  const onSubmit = async (values: {
    move_to_category_id: string;
    moveCategories: {
      uuid: string;
      name: string;
      newId: string;
    }[];
  }) => {
    try {
      await moveCategories({
        entityId,
        payload: {
          move_to_category_id: values.move_to_category_id,
          child_categories: values.moveCategories.map((category) => ({
            uuid: category.uuid,
            new_identifier: category.newId,
          })),
        },
      }).unwrap();
      successToast({ message: "Categories moved successfully" });
      onSuccess();
    } catch (error: any) {
      alertToast({ message: (error as BackendError)?.data?.error?.message });
    }
  };

  const createCategoryColumn = createColumnHelper<TxnCategories>();

  const columns = [
    createCategoryColumn.accessor("name", {
      header: "Category",
      size: 50,
    }),
    createCategoryColumn.accessor("identifier", {
      header: "Previous ID",
      size: 25,
    }),
    createCategoryColumn.display({
      header: "New ID",
      size: 25,
      cell: NewIdField,
    }),
  ];

  const table = useReactTable({
    data: categories || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Formik
      initialValues={{
        moveCategories: categories.map((category) => ({
          uuid: category.uuid,
          name: category.name,
          newId: "",
        })),
        move_to_category_id: "",
      }}
      onSubmit={onSubmit}
      validationSchema={moveCategoriesSchema}
    >
      {({ values, submitForm }) => {
        return (
          <>
            <Modal.Header>
              <Modal.Title>Move Categories</Modal.Title>
              <Modal.Close />
            </Modal.Header>
            <Modal.Body>
              <Form className="t-flex t-gap-4 t-flex-col">
                <CategorySelector
                  hideError={false}
                  category={values.move_to_category_id}
                  name="move_to_category_id"
                  label="Move to"
                />

                <AnimatePresence>
                  {values.move_to_category_id && <TableUI table={table} />}
                  <AccordionAnimation></AccordionAnimation>
                </AnimatePresence>
              </Form>
            </Modal.Body>
            <Modal.FooterButtonGroup>
              <Modal.RawClose asChild>
                <Button>Cancel</Button>
              </Modal.RawClose>
              <Button
                onClick={submitForm}
                customType="primary"
                isLoading={isMoving}
                disabled={isMoving}
              >
                Move
              </Button>
            </Modal.FooterButtonGroup>
          </>
        );
      }}
    </Formik>
  );
};
