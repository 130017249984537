import React from "react";

export const NoProductAndServices = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="203"
      height="154"
      viewBox="0 0 203 154"
      fill="none"
    >
      <g opacity="0.5">
        <path
          d="M99.7906 150.96V70.2857C99.7906 69.5692 98.9629 69.1696 98.4018 69.6154L69.0193 92.9604C68.7597 93.1667 68.4043 93.2045 68.1071 93.0574L29.2315 73.8243C28.6625 73.5427 27.9957 73.9568 27.9957 74.5916V120.09C27.9957 120.43 28.1962 120.737 28.5068 120.874L98.5893 151.744C99.1552 151.993 99.7906 151.578 99.7906 150.96Z"
          fill="#E7E6EB"
        />
        <path
          d="M99.3633 151.37V69.926C99.3633 69.1979 100.214 68.8021 100.771 69.2713L128.872 92.9563C129.121 93.1658 129.466 93.2165 129.765 93.0871L173.38 74.1597C173.945 73.9143 174.577 74.3287 174.577 74.9451V117.961C174.577 118.296 174.381 118.601 174.075 118.74L100.574 152.15C100.007 152.408 99.3633 151.993 99.3633 151.37Z"
          fill="#CFCDD6"
        />
        <path
          d="M129.278 93.7257L99.7906 67.23L175 34L201.5 60.8197L129.278 93.7257Z"
          fill="#F3F3F5"
        />
        <path
          d="M1.92731 61.247L28.423 34.324L99.3632 67.23L69.0213 93.7257L1.92731 61.247Z"
          fill="#F3F3F5"
        />
        <path
          d="M99.3633 68.0847L99.0083 2.98397C99.0044 2.26387 99.7399 1.7758 100.402 2.05924L175 34L99.3633 68.0847Z"
          fill="#E7E6EB"
        />
        <path
          d="M29 33.459L97.5931 2.14232C98.2532 1.84094 99.0045 2.3209 99.0084 3.04654L99.3632 68.0846L29 33.459Z"
          fill="#CFCDD6"
        />
        <path
          d="M28.5001 33.459L99.3633 67.6573M28.5001 33.459L99.0001 1.45901M28.5001 33.459L1.50006 61.247L27.9958 73.9117M99.3633 67.6573V152.7M99.3633 67.6573L175 33.959M99.3633 67.6573L128.85 93.7257L174.15 72.8184M99.3633 67.6573L69.4488 93.7257L27.9958 73.9117M99.3633 67.6573L99.0001 1.45901M99.3633 152.7L27.9958 120.649V73.9117M99.3633 152.7L174.15 118.512V72.8184M99.0001 1.45901L175 33.959M175 33.959L201.073 60.3923L174.15 72.8184"
          stroke="#E7E6EB"
          strokeWidth="1.71233"
        />
      </g>
    </svg>
  );
};
